export const fb = () => {};

export const signUp = () => {
  try {
    if (typeof fbq == 'function') {
      fbq('track', 'CompleteRegistration');
    }
  } catch (e) {}
};

export const addToCart = (track_add_to_cart) => {
  try {
    if (typeof fbq == 'function') {
      fbq('track', 'AddToCart', track_add_to_cart);
    }
  } catch (e) {}
};

export const purchase = (track_purchase) => {
  try {
    if (typeof fbq == 'function') {
      fbq('track', 'Purchase', track_purchase);
    }
  } catch (e) {}
};

export const lead = () => {
  try {
    if (typeof fbq == 'function') {
      fbq('track', 'Lead');
    }
  } catch (e) {}
};
