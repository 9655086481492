import { html, on, View } from 'rune-ts';
import c from './HeaderLeft.module.scss';
import { htmlIf } from '../../../../shared/util';
import { MarppleShopLogo_shop, MenuIcon } from '../../atoms/Icon';

export class HeaderHamburgerClickEvent extends CustomEvent<{}> {}

export class HeaderLeft_MshopLogoWithMenu extends View<{ transparent: boolean }> {
  @on('click', `.${c.menu_icon}`)
  onClick(e) {
    this.dispatchEvent(HeaderHamburgerClickEvent, {
      bubbles: true,
    });
  }

  override template() {
    return html`
      <span class="${c.mshop_logo_with_menu} ${htmlIf(c.transparent, this.data.transparent)}">
        <span class="${c.menu_icon}">${MenuIcon}</span>
        <a href="/${ET.lang}/@/products" class="${c.shop_icon}">${MarppleShopLogo_shop}</a>
      </span>
    `;
  }
}
