import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const needNessPrice = () => {
  try {
    // return MShopUtilConstantS.NBOX_CREW_ID == box.sel('crew_id') || UtilS.isNessApp();
    return UtilS.isNessApp();
  } catch (e) {
    return false;
  }
};
