import { $find, $qs } from 'fxdom/es';
import { MShopCoreConstantS } from '../../S/Constant/module/MShopCoreConstantS.js';
import { go, tap } from 'fxjs/es';

export const getDefaultHeaderEl = () => {
  const component = 'mshop-default-header';

  return $qs(`.${component}`);
};

/**
 * @param {'dark' | 'light'} theme
 * @param {HTMLElement} header_el
 */
export const changeTheme = (header_el, theme) => {
  header_el.dataset.theme = theme;
};

/**
 * @dev header 가 light 상태일때, 스크롤 내려오면 theme 을 dark 로 변경, (layering = true, theme = light 인 곳만 사용하기)
 * header 는 항상 위쪽에 있으니까, scroll 된 거리로 진행 여부 판단
 * @param {HTMLElement} header_el
 */
export const initHeaderAnimation = (header_el) => {
  header_el.classList.add(`${MShopCoreConstantS.core_klass.default_header}--transition-ready`);

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        entry.target.dataset.theme = 'transparent';
      } else {
        entry.target.dataset.theme = 'white';
      }
    },
    {
      rootMargin: '0px 0px 0px 0px',
    },
  );

  observer.observe(header_el);
};

/**
 * @dev header 의 item color (로고, 텍스트, 아이콘 색상) 변경
 * @param header_el
 * @param item_color
 */
export const changeHeaderItemColor = (header_el, item_color) => {
  header_el.dataset.item_color = item_color;
};

/**
 * @type { (e: any) => boolean }
 * */
export const toggleIconShape = (e) => {
  const target_el = e.currentTarget || e;

  const is_true = $find('.view-when-true', target_el).dataset.view_state === 'true';

  go(
    target_el,
    tap($find('.view-when-true'), (true_el) => {
      true_el.dataset.view_state = String(!is_true);
    }),
    tap($find('.view-when-false'), (false_el) => {
      false_el.dataset.view_state = String(is_true);
    }),
  );

  return !is_true;
};
