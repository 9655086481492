import { entries, go, map, reject } from 'fxjs/es';
import differenceInYears from 'date-fns/differenceInYears/index.js';
import parse from 'date-fns/parse/index.js';

export const isPaypal = (s = '') => ['paypal', '페이팔'].includes(s.toLowerCase());

export const makeColorText = (a) =>
  `${a[`name${_en}`]}${a[`is_public${_collabo}`] ? '' : '&nbsp<i>(품절)</i>'}`;

export const rejectDocIndex = (indexs, arr) =>
  go(
    arr,
    map(entries),
    map((row) => {
      return reject(([k]) => {
        return indexs.includes(Number(k));
      }, row);
    }),
    map(map(([, v]) => v)),
  );

/*
 * 주민등록번호 관련 유틸 함수들
 */

// 주민등록번호 뒷자리 첫번째 숫자 = 100년 단위 구분하기 위한 구분자
const year_prefix = {
  // 내국인
  1: 19,
  2: 19,
  3: 20,
  4: 20,
  // 외국인
  5: 19,
  6: 19,
  7: 20,
  8: 20,
};

export const isUnderAgeN = (birth_code, private_code, age) => {
  const birth_prefix = getBirthPartYearPrefix(private_code);
  const full_age = differenceInYears(new Date(), parse(birth_prefix + birth_code, 'yyyyMMdd', new Date()));

  return !!full_age && full_age < age;
};

const registration_error_messages = {
  invalid_private_part: 'invalid private part',
};

export const getBirthPartYearPrefix = (private_part) => {
  const num = +private_part[0];

  if (Number.isNaN(num) || !year_prefix[num]) {
    throw new Error(registration_error_messages.invalid_private_part);
  }

  return year_prefix[num];
};

/**
 * 주민번호 앞자리 유효성 체크
 * @dev 앞에 붙여야 하는 birth prefix 위해서 뒷자리도 받아야함
 */
export const isValidBirthPart = (birth_part, private_part) => {
  try {
    const birth_prefix = getBirthPartYearPrefix(private_part);
    const birth_date = parse(birth_prefix + birth_part, 'yyyyMMdd', new Date());

    return !!birth_date;
  } catch (err) {
    if (err.message === registration_error_messages.invalid_private_part) {
      return false;
    }

    throw err;
  }
};

/**
 * @dev 주민번호 뒷자리 첫번째 유효한지만 체크
 * @param {number | string} private_part
 */
export const isValidPrivatePartSimple = (private_part) => {
  return !!year_prefix[private_part[0]];
};
