import { html, View } from 'rune-ts';
import klass from './GnbPc.module.scss';
import { typo } from '../../../../shared/typography/typo';
import { ChevronRightIcon } from '../../atoms/Icon';
import { makeMainUrl } from '../../../../shared/util/url';

export interface GnbPcMenu {
  name: string;
  link: string;
  target?: HTMLAnchorElement['target'];
}

export interface GnbPcMenuGroupData {
  title: string;
  title_link: string;
  menus?: GnbPcMenu[];
}

interface GnbPcMenuGroupState {
  klass?: string;
  menu_size?: 'medium' | 'small';
}

export type GnbPcMenuGroupOption = GnbPcMenuGroupState;

export class GnbPcMenuGroup extends View<GnbPcMenuGroupData> {
  state: GnbPcMenuGroupState;

  constructor(data: GnbPcMenuGroupData, option: GnbPcMenuGroupOption = {}) {
    super(data);

    this.state = {
      klass: option.klass,
      menu_size: option.menu_size ?? 'small',
    };
  }

  override template() {
    const { title, title_link, menus } = this.data;
    const { klass: custom_klass, menu_size } = this.state;

    const menu_typo = typo(menu_size == 'small' ? '12_medium' : '14_medium');

    return html`<div class="${custom_klass} ${klass.gnb_pc_menu_group} ${typo('14_medium')}">
      <a href="${makeMainUrl(title_link)}" class="${typo('16_bold')} ${klass.menu_group_title}">
        ${title} <span class="${klass.menu_group_icon}">${ChevronRightIcon}</span>
      </a>
      ${!menus || menus.length == 0
        ? ''
        : html`<div class="${klass.menu_container}">
            ${menus.map(
              (menu) =>
                html`
                  <a
                    class="${klass.menu_item} ${menu_typo}"
                    href="${makeMainUrl(menu.link)}"
                    target="${menu.target || '_self'}"
                    >${menu.name}</a
                  >
                `,
            )}
          </div>`}
    </div> `;
  }
}
