export const ProductCategoryIds = {
  의류: 9,
  스포츠: 113,
  굿즈: 188,
  스티커: 84,
  패션잡화: 10,
  '토이/취미': 146,
  '쿠션/패브릭': 189,
  '폰 악세서리': 12,
  '문구/오피스': 14,
  키즈: 13,
  애견: 190,
  리빙: 11,
  '디지털 상품': 47,
  액자: 82,
  '도서/음반': 147,
  핸드메이드: 148,
  식품: 179,
};

export const getProductCategoryOrderMap = (current_viewport_width: number) => {
  if (current_viewport_width >= 1280) {
    return [
      [ProductCategoryIds['의류'], ProductCategoryIds['스포츠']],
      [ProductCategoryIds['굿즈'], ProductCategoryIds['스티커']],
      [ProductCategoryIds['패션잡화'], ProductCategoryIds['토이/취미']],
      [ProductCategoryIds['쿠션/패브릭'], ProductCategoryIds['폰 악세서리']],
      [ProductCategoryIds['문구/오피스'], ProductCategoryIds['키즈'], ProductCategoryIds['애견']],
      [ProductCategoryIds['리빙'], ProductCategoryIds['디지털 상품'], ProductCategoryIds['액자']],
      [ProductCategoryIds['도서/음반'], ProductCategoryIds['핸드메이드'], ProductCategoryIds['식품']],
    ];
  } else {
    return [
      [ProductCategoryIds['의류'], ProductCategoryIds['스포츠'], ProductCategoryIds['리빙']],
      [ProductCategoryIds['굿즈'], ProductCategoryIds['스티커'], ProductCategoryIds['디지털 상품']],
      [ProductCategoryIds['패션잡화'], ProductCategoryIds['토이/취미'], ProductCategoryIds['액자']],
      [
        ProductCategoryIds['쿠션/패브릭'],
        ProductCategoryIds['폰 악세서리'],
        ProductCategoryIds['핸드메이드'],
      ],
      [
        ProductCategoryIds['문구/오피스'],
        ProductCategoryIds['키즈'],
        ProductCategoryIds['애견'],
        ProductCategoryIds['도서/음반'],
        ProductCategoryIds['식품'],
      ],
    ];
  }
};

export type GnbProductCategory = {
  id: number;
  title: string;
  menus?: { name: string; id: number }[];
};

export const product_category_layout = [
  {
    id: ProductCategoryIds['의류'],
    menus: [43, 473, 44, 474, 475, 45, 46, 365, 387, 366, 367],
  },
  {
    id: ProductCategoryIds['스포츠'],
    menus: [297, 296, 298, 299, 368],
  },
  {
    id: ProductCategoryIds['굿즈'],
    menus: [101, 332, 263, 290, 260, 292, 261, 291, 431, 302, 482],
  },
  {
    id: ProductCategoryIds['스티커'],
    menus: [269, 294, 135, 136, 283, 284, 293],
  },
  {
    id: ProductCategoryIds['패션잡화'],
    menus: [47, 255, 49, 48, 259, 102, 266, 304, 264, 371, 68, 372],
  },
  {
    id: ProductCategoryIds['토이/취미'],
    menus: [379, 380, 381, 382, 383, 384],
  },
  {
    id: ProductCategoryIds['쿠션/패브릭'],
    menus: [51, 254, 64, 276, 257, 54, 476],
  },
  {
    id: ProductCategoryIds['폰 악세서리'],
    menus: [55, 56, 57, 279, 252, 253, 58, 300, 369, 370],
  },
  {
    id: ProductCategoryIds['문구/오피스'],
    menus: [103, 200, 256, 377, 378],
  },
  {
    id: ProductCategoryIds['키즈'],
    menus: [59, 60, 385, 386, 388, 61, 389],
  },
  {
    id: ProductCategoryIds['애견'],
    menus: [62, 477],
  },
  {
    id: ProductCategoryIds['리빙'],
    menus: [258, 275, 276, 52, 265, 104, 373, 374, 375],
  },
  {
    id: ProductCategoryIds['디지털 상품'],
    menus: [464, 465, 466, 467, 530],
  },
  {
    id: ProductCategoryIds['액자'],
    menus: [490, 295, 289, 251, 376],
  },
  {
    id: ProductCategoryIds['도서/음반'],
    menus: [390, 391],
  },
  {
    id: ProductCategoryIds['핸드메이드'],
    menus: [392, 393, 394, 395, 396, 397, 398],
  },
  {
    id: ProductCategoryIds['식품'],
    menus: [],
  },
];

export const mock_creator_categories = [
  { name: '유튜버', id: 1 },
  { name: '일러스트레이터', id: 2 },
  { name: 'NFT', id: 3 },
  { name: '틱톡커', id: 4 },
  { name: '디자이너', id: 5 },
  { name: '뮤지션', id: 6 },
  { name: '스트리머', id: 7 },
  { name: '웹툰/만화', id: 8 },
  { name: '아티스트', id: 9 },
  { name: '크리에이터', id: 10 },
  { name: '포토그래퍼', id: 11 },
  { name: '브랜드', id: 12 },
  { name: '버추얼', id: 13 },
  { name: '캐릭터 디자이너', id: 14 },
  { name: '인플루언서', id: 15 },
  { name: '타투이스트', id: 16 },
];
