import { go, sumBy } from 'fxjs/es';
import { DeliveryTypeS } from '../../../../DeliveryType/S/Function/module/DeliveryTypeS.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';

export const makePayableShippingPrice = ({
  user_products,
  stores_delivery_company,
  is_free_shipping_product,
  is_far,
  mps_i,
  _en,
}) => {
  if (DeliveryTypeS.isMarpple(stores_delivery_company.delivery_type_id) && mps_i > 0)
    return {
      shipping_price: 0,
      additional_shipping_price: 0,
      take_back_price: 0,
      free_shipping_min_price: 0,
    };
  /* PB 상품 배송비는 원화로 들어가있음, 환율 때려야함 */
  const prices = (function () {
    if (
      DeliveryTypeS.isSeller(stores_delivery_company.delivery_type_id) &&
      stores_delivery_company.lang != 'kr'
    ) {
      const { lang, shipping_price, additional_shipping_price, free_shipping_min_price, take_back_price } =
        stores_delivery_company;

      return {
        shipping_price: PriceS.exchangeFromKr(shipping_price, lang),
        additional_shipping_price: PriceS.exchangeFromKr(additional_shipping_price, lang),
        free_shipping_min_price: PriceS.exchangeFromKr(free_shipping_min_price, lang),
        take_back_price: PriceS.exchangeFromKr(take_back_price, lang),
      };
    }
    return stores_delivery_company;
  })();

  return go(
    user_products,
    sumBy((up_c_s) => up_c_s['discounted_price' + _en] * up_c_s.quantity),
    (products_price) => {
      const is_free_shipping =
        is_free_shipping_product ||
        (prices?.free_shipping_min_price && prices?.free_shipping_min_price <= products_price);
      const shipping_price = is_free_shipping ? 0 : prices.shipping_price;
      const additional_shipping_price = is_far ? prices.additional_shipping_price || 0 : 0;
      return {
        shipping_price,
        additional_shipping_price,
        take_back_price: prices.take_back_price,
        free_shipping_min_price: prices.free_shipping_min_price,
      };
    },
  );
};
