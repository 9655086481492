import { html } from 'fxjs/es';

export const tooltipIcon2El = html`
  <span class="tooltip-icon2--question-default">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_3748_455778)">
        <path
          d="M10.0003 1.66406C5.40033 1.66406 1.66699 5.3974 1.66699 9.9974C1.66699 14.5974 5.40033 18.3307 10.0003 18.3307C14.6003 18.3307 18.3337 14.5974 18.3337 9.9974C18.3337 5.3974 14.6003 1.66406 10.0003 1.66406ZM11.042 14.0641C11.042 14.6391 10.5753 15.1057 10.0003 15.1057C9.42533 15.1057 8.95866 14.6391 8.95866 14.0641V9.68906C8.95866 9.11406 9.42533 8.6474 10.0003 8.6474C10.5753 8.6474 11.042 9.11406 11.042 9.68906V14.0641ZM10.0003 6.98073C9.42533 6.98073 8.95866 6.51406 8.95866 5.93906C8.95866 5.36406 9.42533 4.8974 10.0003 4.8974C10.5753 4.8974 11.042 5.36406 11.042 5.93906C11.042 6.51406 10.5753 6.98073 10.0003 6.98073Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3748_455778">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
`;
