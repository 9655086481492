import { html, rune, View } from 'rune-ts';
import quickMenuClass from './QuickMenu.module.scss';
import { dataStr } from '../../../../shared/util/dataStr';
import { typoPcMo } from '../../../../shared/typography/typo';

export interface QuickMenuData {
  title_pc: string;
  title_mo: string;
  image: string;
  navigate?: object;
  href: string;
  target: '_self' | '_blank';
  is_hidden?: boolean;
}

class QuickMenuView extends View<QuickMenuData> {
  constructor(data: QuickMenuData, public option?: { is_mobile?: boolean }) {
    super(data, option);
  }

  protected override template() {
    const { href, target, image, title_mo, title_pc, navigate } = this.data;
    const is_mobile = !!rune.getSharedData(this)?.is_mobile || !!this.option?.is_mobile;

    return html`
      <a
        class="${quickMenuClass.quick_menu}"
        ${navigate ? html`data-post-message="${dataStr(navigate)}"` : ''}
        href="${html.preventEscape(href)}"
        target="${target}"
      >
        <div class="${quickMenuClass.image_container}">
          <img class="${quickMenuClass.image}" src="${image}" alt="" />
        </div>
        <span class="${quickMenuClass.title} ${typoPcMo({ is_mobile, pc: '16_medium', mo: '12_medium' })}"
          >${is_mobile ? title_mo : title_pc}</span
        >
      </a>
    `;
  }
}

export class QuickMenuListView extends View<QuickMenuData[]> {
  constructor(data: QuickMenuData[], public option?: { is_fit?: boolean; is_mobile?: boolean }) {
    super(data, option);
  }

  protected override template() {
    return html`
      <div class="${quickMenuClass.quick_menu_list}" data-fit="${!!this.option?.is_fit}">
        ${this.data.filter((menu) => !menu.is_hidden).map((menu) => new QuickMenuView(menu, this.option))}
      </div>
    `;
  }
}
