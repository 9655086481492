import axios from 'axios';
import {
  defaults,
  each,
  every,
  extend,
  filter,
  find,
  flatMap,
  go,
  identity,
  map,
  mapC,
  omit,
  pick,
  pluck,
  sel,
  some,
  take,
  tap,
  uniq,
} from 'fxjs/es';
import { createCanvasElement, makeCanvasByUrl, makeImageCanvasByRatio } from '../../../Canvas/S/util.js';
import { makeCanvasCut, makeTrimCanvas2 } from '../../../Maker/F/canvas_trim.js';
import { phonecase_strap_bp_ids } from '../../../Maker/F/categorize.js';
import {
  drawProductFace,
  makeProductColorByPcAndBpcId,
  makeProductColorByPcAndBpcIdAndBpId,
} from '../../../Maker/F/draw_product_faces.js';
import { getProperProductFaces } from '../../../Maker/F/getSth.js';
import { isS3Url } from '../../../Maker/F/util.js';
import { NewMakerProductStyleS } from '../../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import {
  makeDesignReadyUrlAfterSetMockupUrl,
  setCompositeTemplateMockupUrl,
} from '../../Core/F/mockup_fns.js';
import { render_canvas } from '../../Core/S/composite_template_canvas.js';
import { selectAssoCompositeTemplate } from '../../Maker/F/fs.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

async function uploadThumbnail({ thumbnail }) {
  const url = await go(
    $.uploadFileToOnlyOriginalUrl({
      url: thumbnail.url,
      image_type: 'png',
      original_name: 'pc_thumbnail_url',
    }),
    sel('url'),
  );
  if (!url) {
    throw Error('ERROR:Image Upload');
  }
  return defaults({ url }, thumbnail);
}
export async function uploadCompositeThumbnail({ selected_base_product_colors, thumbnail }) {
  const { url, updated_at } = await go(
    axios.post('/@fileUpload/composite/make_composite_thumbnail', {
      thumbnail: pick(['composite_template_id', 'design_ready_url'], thumbnail),
      selected_base_product_colors,
    }),
    sel('data'),
  );
  return defaults({ url, updated_at }, thumbnail);
}

export function makeCompositeThumbnailsWrapperWithDesignReady(assoc_composite_templates, product) {
  return go(
    assoc_composite_templates,
    map((assoc_composite_template) => {
      return {
        design_ready_url: assoc_composite_template._.composite_result.design_ready_url,
        url: null,
        is_thumb: false,
        composite_template_id: assoc_composite_template.id,
      };
    }),
    tap(function (thumbnails) {
      const original_thumb = find((thumb) => thumb.is_thumb)(sel('value', product.thumbnails));
      const new_thumb = find(
        (thumb) => thumb.composite_template_id === original_thumb?.composite_template_id,
      )(thumbnails);
      if (new_thumb) new_thumb.is_thumb = true;
      else setThumbFromOrignThumb(thumbnails, sel('value', product.thumbnails));
    }),
  );
}

export async function setAndUploadThumbnailOgImage(product_color, need_og) {
  product_color.thumbnails.value = await go(
    product_color.thumbnails.value,
    mapC((_thumbnail) => {
      if (isS3Url(_thumbnail.url)) return _thumbnail;
      if (_thumbnail.composite_template_id) {
        return uploadCompositeThumbnail({
          thumbnail: omit('_', _thumbnail),
          selected_base_product_colors: [
            {
              id: product_color.base_product_color_id,
              base_product_id: product_color.base_product_id,
            },
          ],
          pc_id: product_color.id,
        });
      } else {
        return uploadThumbnail({
          thumbnail: omit('_', _thumbnail),
          pc_id: product_color.id,
        });
      }
    }),
  );
  if (product_color.thumbnails.value[0].composite_template_id) {
    product_color.thumbnails.type = 'composite_template';
  }
  if (need_og) {
    product_color.og_image_url = await makeOgImageUrlFromPc(product_color);
  }
}

async function makeToDataUrlCompositeThumbnail(pc, thumbnail) {
  const assoc_composite_template = thumbnail._.assoc_composite_template;
  const canvas = await go(
    createCanvasElement({ width: assoc_composite_template.width, height: assoc_composite_template.height }),
    async (canvas_el) =>
      render_canvas({
        assoc_composite_template,
        canvas_el,
        design_ready_url: thumbnail.design_ready_url,
        selected_base_product_colors: go(
          [pc],
          map((pc) => {
            return {
              id: pc.base_product_color_id,
              base_product_id: pc.base_product_id,
            };
          }),
        ),
        thumbnail,
      }),
  );
  const url = canvas.toDataURL('image/png', 1.0);
  return defaults(
    {
      url,
    },
    thumbnail,
  );
}

export const makeOgImageUrlFromPc = async (pc) => {
  const thumbnail = find((thumbnail) => thumbnail.is_thumb, pc.thumbnails.value);
  let pf = find((pf) => pf.bpf_id === thumbnail.bpf_id, pc.product_faces2.value);
  if (!pf) pf = pc.product_faces2.value[0];
  const { width, height } = { width: 860 * 1.5, height: 860 * 1.5 };
  return go(
    undefined,
    () => {
      if (pc.thumbnails.type === 'composite_template') {
        return go(
          pc.thumbnails.value,
          find((thumb) => thumb.is_thumb),
          sel('url'),
          makeCanvasByUrl,
          (c) => makeImageCanvasByRatio(c, 1080, '#f6f6f6', 1 / 2, 1, 1),
        );
      } else {
        return go(makeTrimedDrawFace({ width, height }, pf, pc.base_product_id), (c) =>
          makeImageCanvasByRatio(c, 1080, '#f6f6f6', 1 / 2, 1 / 3, 4 / 5),
        );
      }
    },
    (c) =>
      $.uploadFileToOnlyOriginalUrl({
        url: c.toDataURL(),
        original_name: 'product_color_og',
        image_type: 'JPEG',
      }),
    sel('url'),
  );
};

function makeTrimedDrawFace({ width, height }, pf, base_product_id) {
  return go(
    createCanvasElement({ width, height }),
    (c) => drawProductFace(c, pf, undefined, false, true),
    (canvas) => makeTrimCanvas2(canvas),
    (c) => {
      if (phonecase_strap_bp_ids.includes(base_product_id)) {
        return makeCanvasCut(c);
      } else return c;
    },
  );
}

function makeDrawFace({ width, height }, pf) {
  return go(createCanvasElement({ width, height }), (c) => drawProductFace(c, pf, undefined, false, true));
}

export async function makeToDataUrlThumbnail(pc, thumbnail) {
  const pf = find((pf) => pf.bpf_id === thumbnail.bpf_id, getProperProductFaces(pc));
  if ([6501, 6503, 6504].includes(pc.base_product_id)) {
    if (UtilS.isNessApp()) {
      const c = await makeTrimedDrawFace({ width: 1300, height: 1300 }, pf, pc.base_product_id);
      const canvas = createCanvasElement({
        width: 1000,
        height: 1000,
      });
      const ctx = canvas.getContext('2d');
      const plus_margin = 0.1 - (c.height / 1300) * 0.1;
      const margin = 0.1 + plus_margin;
      if (c.width > c.height) {
        const width = canvas.width * (1 - margin * 2);
        const height = c.height * (width / c.width);
        ctx.drawImage(
          c,
          0,
          0,
          c.width,
          c.height,
          (canvas.width - width) / 2,
          (canvas.height - height) / 2,
          width,
          height,
        );
      } else {
        const height = canvas.height * (1 - margin * 2);
        const width = c.width * (height / c.height);
        ctx.drawImage(
          c,
          0,
          0,
          c.width,
          c.height,
          (canvas.width - width) / 2,
          (canvas.height - height) / 2,
          width,
          height,
        );
      }

      const url = canvas.toDataURL('image/png', 1);
      return defaults(
        {
          url,
        },
        thumbnail,
      );
    } else {
      const c = await makeDrawFace({ width: 1300, height: 1300 }, pf);
      const url = c.toDataURL('image/png', 1);
      return defaults(
        {
          url,
        },
        thumbnail,
      );
    }
  }

  const keyring_cv_obj = find((cv_obj) => cv_obj.cid == 'c2323')(pf.designs);
  const ratio = keyring_cv_obj ? 3 : 1.5;
  const { width, height } = { width: 860 * ratio, height: 860 * ratio };
  return go(
    makeTrimedDrawFace({ width, height }, pf, pc.base_product_id),
    (c) => {
      if (keyring_cv_obj) {
        const canvas = createCanvasElement({
          width: 1000,
          height: 1000,
        });
        let v_width = 300;
        if (some((cv_obj) => cv_obj.src.includes('ee53a6642d872e227c1e83ba2f855efa677825811'))(pf.designs)) {
          v_width = 800;
        }
        if (c.width > c.height) {
          const width =
            Math.min(keyring_cv_obj._data.real_width_cm / 10, 1) * v_width + canvas.width - v_width;
          const height = c.height * (width / c.width);
          const ctx = canvas.getContext('2d');
          ctx.drawImage(
            c,
            0,
            0,
            c.width,
            c.height,
            (canvas.width - width) / 2,
            (canvas.height - height) / 2,
            width,
            height,
          );
          return canvas;
        }
        const height =
          Math.min(keyring_cv_obj._data.real_height_cm / 10, 1) * v_width + canvas.width - v_width;
        const width = c.width * (height / c.height);
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          c,
          0,
          0,
          c.width,
          c.height,
          (canvas.width - width) / 2,
          (canvas.height - height) / 2,
          width,
          height,
        );
        return canvas;
      }
      return makeImageCanvasByRatio(c, Math.max(c.width, c.height));
    },
    (c) => {
      const url = c.toDataURL('image/png', 1);
      return defaults(
        {
          url,
        },
        thumbnail,
      );
    },
  );
}

export async function makeToDataUrlAllThumbnails(pc) {
  return go(
    pc?.thumbnails?.value ?? [],
    mapC(async (thumbnail) => {
      if (thumbnail.composite_template_id) {
        if (!sel('_.assoc_composite_template', thumbnail)) {
          const assoc_composite_template = await $.get('/@api/composite/assoc_composite_template_only', {
            id: thumbnail.composite_template_id,
          });
          thumbnail._ = thumbnail._ || {};
          thumbnail._.assoc_composite_template = assoc_composite_template;
        }
        return makeToDataUrlCompositeThumbnail(pc, thumbnail);
      } else {
        return makeToDataUrlThumbnail(pc, thumbnail);
      }
    }),
  );
}
export async function makeProductColorWithThumbnailTodataurlByBpcOrBpId({ bpc_id, bp_id, product_color }) {
  bpc_id = parseInt(bpc_id);
  product_color = JSON.parse(JSON.stringify(omit(['_'], product_color)));

  if (
    !product_color.product_faces2.value ||
    (product_color.thumbnails?.type === 'composite_template' &&
      !product_color.thumbnails?.value[0]?.design_ready_url)
  ) {
    const {
      data: { product_faces2, thumbnails },
    } = await axios.get(`/${T.lang}/@api/product_color/p2`, { params: { id: product_color.id } });
    product_color.product_faces2 = product_faces2;
    if (product_color.thumbnails?.type === 'composite_template') {
      product_color.thumbnails = thumbnails;
    }
  }

  if (bp_id) {
    const { new_pc } = await makeProductColorByPcAndBpcIdAndBpId(
      product_color,
      product_color.base_product_color_id,
      bp_id,
    );
    extend(product_color, omit(['_', 'thumbnails'], new_pc));
    if (sel('value', product_color.thumbnails))
      product_color.thumbnails.value = makeThumbnailsWrapper(product_color);
  }

  if (bpc_id) {
    const { new_pc } = await makeProductColorByPcAndBpcId(product_color, bpc_id);
    extend(product_color, omit(['_', 'thumbnails'], new_pc));
  }

  if (sel('value', product_color.thumbnails))
    product_color.thumbnails.value = await makeToDataUrlAllThumbnails(product_color);

  return product_color;
}

export function makeThumbnailsWrapper(pc) {
  const updated_at = new Date();
  return go(
    getProperProductFaces(pc),
    map((pf) => {
      return extend(
        {
          url: null,
          updated_at,
          is_thumb: false,
        },
        pick(['bpf_id'], pf),
      );
    }),
    tap(function (thumbnails) {
      setThumbFromOrignThumb(thumbnails, sel('value', pc.thumbnails));
    }),
  );
}
export function setThumbFromOrignThumb(thumbnails, origin_thumbnails) {
  const idx = (origin_thumbnails && origin_thumbnails.findIndex((thumbnail) => thumbnail.is_thumb)) || 0;
  if (thumbnails[idx]) thumbnails[idx].is_thumb = true;
  else thumbnails[0].is_thumb = true;
}

export async function changeThumbnailCompositeTemplate(pc, { src_ct_id, dest_ct_id }, is_with_og) {
  const assoc_composite_templates = [await selectAssoCompositeTemplate(src_ct_id)];
  pc = JSON.parse(JSON.stringify(omit(['_'], pc)));
  await setUpDesignReadyUrlInCompositeTemplate(pc, assoc_composite_templates);
  await go(
    pc.thumbnails.value,
    each(async (thumb) => {
      if (thumb.composite_template_id === dest_ct_id) {
        thumb.composite_template_id = src_ct_id;
        thumb.design_ready_url = assoc_composite_templates[0]._.composite_result.design_ready_url;
        extend(
          thumb,
          await uploadCompositeThumbnail({
            thumbnail: omit('_', thumb),
            selected_base_product_colors: [
              {
                id: pc.base_product_color_id,
                base_product_id: pc.base_product_id,
              },
            ],
            pc_id: pc.id,
          }),
        );
      }
    }),
  );
  if (is_with_og) {
    pc.og_image_url = await makeOgImageUrlFromPc(pc);
  }
  return pc;
}
async function setUpDesignReadyUrlInCompositeTemplate(pc, assoc_composite_templates) {
  await setCompositeTemplateMockupUrl(assoc_composite_templates, pc);
  await go(
    assoc_composite_templates,
    mapC(async (assoc_composite_template) => {
      assoc_composite_template._.composite_result = {
        design_ready_url: await makeDesignReadyUrlAfterSetMockupUrl(assoc_composite_template),
      };
    }),
  );
}

let i = 0;
function updateThumbnailNewThumb(assoc_composite_templates, product) {
  const bpf_ids = go(product.product_faces2.value, pluck('bpf_id'));
  const bbs = go(
    assoc_composite_templates,
    map((ct) =>
      go(
        ct._.composite_masks,
        flatMap((cm) => go(cm._.composite_faces, pluck('base_product_face_id'))),
        (base_product_face_ids) => ({
          composite_template_id: ct.id,
          bpf_ids: base_product_face_ids,
        }),
      ),
    ),
  );

  return go(
    bbs,
    filter((bb) => JSON.stringify(bb.bpf_ids) === JSON.stringify(bpf_ids)),
    (arr) => {
      return arr[i++ % arr.length];
    },
    sel('composite_template_id'),
    (ct_id) => {
      if (!ct_id) return bbs[0].composite_template_id;
      return ct_id;
    },
  );
}

export async function getThumbnailAssocCompositeTemplates(
  { product_faces2, base_product_id },
  is_for_worker,
) {
  const base_product_face_ids = product_faces2?.value?.length
    ? go(getProperProductFaces({ product_faces2 }), pluck('bpf_id'))
    : null;
  return go(
    axios.get(
      G.collabo_type === 'creator'
        ? UtilS.makeApiUrl('/:lang/@api/stores/:store_id/composite/assoc_composite_templates', {
            lang: T.lang,
            store_id: box.sel('store_id'),
          })
        : '/@api/composite/assoc_composite_templates',
      {
        params: {
          base_product_id,
          composite_template_type: 'thumbnail',
          base_product_face_ids,
          is_for_worker,
        },
      },
    ),
    sel('data'),
    filter((ct) =>
      go(
        ct._.composite_masks,
        flatMap((cm) => go(cm._.composite_faces, pluck('base_product_face_id'))),
        uniq,
        (bpf_ids) => {
          if (base_product_id === 4386) return true;
          if (NewMakerProductStyleS.GALAXY_ZFLIP_BP_IDS.includes(base_product_id)) return true;
          if (ct.is_cond_or) return true;
          return every((bpf_id) => base_product_face_ids?.includes(bpf_id), bpf_ids);
        },
      ),
    ),
  );
}

export async function makeFullNewProductWithCompositeThumbnails(
  pc,
  with_og,
  is_for_worker,
  select_new_thumb,
) {
  pc = JSON.parse(JSON.stringify(omit(['_'], pc)));
  const ct_ids = go(pc.thumbnails.value, pluck('composite_template_id'));
  const assoc_composite_templates = await go(
    getThumbnailAssocCompositeTemplates(pc, is_for_worker),
    ct_ids?.length ? filter((ct) => ct_ids.includes(ct.id)) : identity,
    take(6),
  );

  return updateProductThumbnailsAndOgImage({ pc, assoc_composite_templates, select_new_thumb, with_og });
}

export async function updateProductThumbnailsAndOgImage({
  pc,
  assoc_composite_templates,
  select_new_thumb,
  with_og,
}) {
  await setUpDesignReadyUrlInCompositeTemplate(pc, assoc_composite_templates);
  pc.thumbnails.value = await go(
    makeCompositeThumbnailsWrapperWithDesignReady(assoc_composite_templates, pc),
    mapC((_thumbnail) => {
      return uploadCompositeThumbnail({
        thumbnail: omit('_', _thumbnail),
        selected_base_product_colors: [
          {
            id: pc.base_product_color_id,
            base_product_id: pc.base_product_id,
          },
        ],
        pc_id: pc.id,
      });
    }),
  );

  if (select_new_thumb) {
    const ct_id = updateThumbnailNewThumb(assoc_composite_templates, pc);
    go(
      pc.thumbnails.value,
      each((thumbnail) => (thumbnail.is_thumb = false)),
      find((thumbnail) => thumbnail.composite_template_id === ct_id),
      (thumbnail) => {
        thumbnail.is_thumb = true;
      },
    );
  }

  pc.thumbnails.type = 'composite_template';
  if (with_og) {
    pc.og_image_url = await makeOgImageUrlFromPc(pc);
  }
  return pc;
}

export async function makeFullNewProductWithNormalThumbnails(pc, with_og) {
  const { new_pc } = await makeProductColorByPcAndBpcId(pc, pc.base_product_color_id);
  new_pc.thumbnails.value = await go(
    makeThumbnailsWrapper(new_pc),
    mapC((thumbnail) => makeToDataUrlThumbnail(new_pc, thumbnail)),
    mapC((thumbnail) => uploadThumbnail({ thumbnail, pc_id: pc.id })),
  );
  if (with_og) {
    new_pc.og_image_url = await makeOgImageUrlFromPc(new_pc);
  }
  return new_pc;
}

export async function makeFullNewProductWithNormalThumbnails2(new_pc) {
  if (!new_pc.thumbnails?.value?.length) {
    return {};
  }
  return {
    thumbnails: {
      value: await go(
        makeThumbnailsWrapper(new_pc),
        mapC((thumbnail) => makeToDataUrlThumbnail(new_pc, thumbnail)),
        mapC((thumbnail) => uploadThumbnail({ thumbnail })),
      ),
    },
    og_image_url: await makeOgImageUrlFromPc(new_pc),
  };
}

export async function makeFullNewProductWithBothThumbnails(
  pc,
  is_with_og,
  is_composite,
  is_for_worker,
  is_only_og,
  change_composite,
  select_new_thumb,
) {
  if (is_only_og) {
    const new_pc = JSON.parse(JSON.stringify(omit(['_'], pc)));
    new_pc.og_image_url = await makeOgImageUrlFromPc(new_pc);
    return new_pc;
  }
  if (change_composite.src_ct_id && change_composite.dest_ct_id) {
    return changeThumbnailCompositeTemplate(pc, change_composite, is_with_og);
  }
  if (is_composite)
    return makeFullNewProductWithCompositeThumbnails(pc, is_with_og, is_for_worker, select_new_thumb);
  else return makeFullNewProductWithNormalThumbnails(pc, is_with_og);
}
