// TODO(nbox): 메뉴명 바꿀 수 있는 기능 만들기 전에 임시로 엔박스용으로만 사용
export const plus_main_menus = {
  kr: [
    { name: '홈', url: '/kr' },
    { name: '공식 상품', url: '/kr/@/products' },
    { name: '리뷰', url: '/kr/@/communities' },
  ],
  en: [
    { name: 'Home', url: '/en' },
    { name: 'Product', url: '/en/@/products' },
    { name: 'Review', url: '/en/@/communities' },
  ],
  jp: [
    { name: 'ホーム', url: '/jp' },
    { name: '公式商品', url: '/jp/@/products' },
    { name: 'レビュー', url: '/jp/@/communities' },
  ],
};
