import { html } from 'rune-ts';
import { GnbPcMenuGroup, GnbPcMenuGroupData } from './GnbPcMenuGroup';
import klass from './GnbPc.module.scss';
import { GnbProductCategory } from './constant';
import { MarppleShopLogo, MShopDefaultIcon } from '../../atoms/Icon';
import { typo } from '../../../../shared/typography/typo';
import { generateProductListUrl } from '../../../../features/ProductList/util';
import { makeMainUrl, makePlusUrl } from '../../../../shared/util/url';

export const makeShopTemplate = (product_category_groups: GnbProductCategory[][], show: boolean) => {
  const transform = (product_category: GnbProductCategory): GnbPcMenuGroupData => {
    return {
      title: product_category.title,
      title_link: generateProductListUrl({ cate_list_id: product_category.id }),
      menus: product_category.menus?.map((menu) => ({
        name: menu.name,
        link: generateProductListUrl({ cate_list_id: product_category.id, cate_item_id: menu.id }),
      })),
    };
  };

  return html`
    <div class="${klass.template} ${klass.shop_template} ${show ? '' : klass.hide}">
      <div class="${klass.shop_left}">
        ${new GnbPcMenuGroup({
          title: ET('mps2::product::all_products'),
          title_link: generateProductListUrl(),
        })}
        ${new GnbPcMenuGroup({ title: ET('mps2::search::curation'), title_link: '/@/curations' })}
        ${new GnbPcMenuGroup({
          title: ET('limit_product::limit_product_01'),
          title_link: generateProductListUrl({ is_limited: true }),
        })}
      </div>
      <div class="${klass.shop_right}">
        ${product_category_groups.map((product_category_group) => {
          return html`
            <div class="${klass.shop_right_column}">
              ${product_category_group.map(
                (product_category) => new GnbPcMenuGroup(transform(product_category)),
              )}
            </div>
          `;
        })}
      </div>
    </div>
  `;
};

export type CreatorTypeFilter = { name: string; id: number; icon?: string };

export type CreatorGnbProps = {
  creator_types: CreatorTypeFilter[];
  popular_creator?: { name: string; thumbnail_img: string; domain_name: string };
  new_plus: { name: string; logo: string; domain_name: string };
};

export const makeCreatorTemplate = (
  { creator_types, new_plus, popular_creator }: CreatorGnbProps,
  show: boolean,
) => {
  const top_creator_link = popular_creator ? makeMainUrl(`/${ET.lang}/${popular_creator.domain_name}`) : '';
  const recent_plus_link = makePlusUrl(new_plus.domain_name, `/${ET.lang}`);

  const month_top_creator_thumbnail_img = !popular_creator
    ? ''
    : popular_creator.thumbnail_img
    ? html`<a href="${top_creator_link}"
        ><img
          class="${klass.creator_banner_best_creator_image}"
          src="${popular_creator.thumbnail_img}"
          alt=""
      /></a>`
    : html`<a href="${top_creator_link}"
        ><div class="${klass.creator_banner_best_creator_default_image}">
          <span class="${klass.creator_banner_default_icon}">${MarppleShopLogo}</span>
        </div></a
      > `;

  return html` <div class="${klass.template} ${show ? '' : klass.hide}">
    <div class="${klass.creator_left}">
      ${new GnbPcMenuGroup(
        {
          title: ET('mps2::gnb::creator_ranking'),
          title_link: `/${ET.lang}/@/creator/ranking/weekly`,
          menus: [
            { name: ET('mps2::gnb::creator_ranking_weekly'), link: `/${ET.lang}/@/creator/ranking/weekly` },
            { name: ET('mps2::gnb::creator_ranking_monthly'), link: `/${ET.lang}/@/creator/ranking/monthly` },
          ],
        },
        { menu_size: 'medium' },
      )}
      ${new GnbPcMenuGroup(
        {
          title: ET('mps2::gnb::plus'),
          title_link: '/@/creator/plus',
          menus:
            ET.lang === 'kr'
              ? [
                  { name: ET('mps2::gnb::plus_all'), link: '/@/creator/plus' },
                  { name: '마플샵 플러스 신청하기', link: '/@/plus', target: '_blank' },
                ]
              : [{ name: ET('mps2::gnb::plus_all'), link: '/@/creator/plus' }],
        },
        { menu_size: 'medium' },
      )}
      ${new GnbPcMenuGroup({
        title: ET('mps2::gnb::creator_view'),
        title_link: `/${ET.lang}/@/creator_views`,
      })}
    </div>
    <div class="${klass.creator_middle}">
      ${new GnbPcMenuGroup({
        title: ET('mps2::gnb::creator_all'),
        title_link: '/@/creator/all',
      })}
      <div class="${klass.creator_menus}">
        ${creator_types.map((creator_type) => {
          return html`
            <a href="${makeMainUrl(`/@/creator/all?st_id=${creator_type.id}`)}" class="${klass.menu_item}">
              ${creator_type.name}
            </a>
          `;
        })}
      </div>
    </div>
    <div class="${klass.creator_right}">
      ${!popular_creator
        ? ''
        : html`<div class="${klass.creator_banner}">
            ${month_top_creator_thumbnail_img}
            <p class="${klass.creator_banner_title} ${typo('16_bold')}">
              ${ET('mps2::gnb::monthly_top_one', { month: new Date().getMonth() + 1 })}
            </p>
            <a href="${top_creator_link}" class="${klass.creator_banner_link} ${typo('12_medium')}">
              ${ET.lang == 'kr' ? `${popular_creator.name} 샵 바로가기` : ET('mps2::gnb::more')}
              <span class="${klass.creator_banner_link_icon}">${MShopDefaultIcon()}</span>
            </a>
          </div>`}

      <div class="${klass.creator_banner}">
        <a href="${recent_plus_link}"
          ><div class="${klass.creator_banner_plus}">
            <img class="${klass.creator_banner_plus_logo}" src="${new_plus.logo}" alt="new plus logo" /></div
        ></a>
        <p class="${klass.creator_banner_title} ${typo('16_bold')}">${ET('mps2::gnb::creator_new')}</p>
        <a href="${recent_plus_link}" class="${klass.creator_banner_link} ${typo('12_medium')}">
          ${ET.lang == 'kr' ? `${new_plus.name} 바로가기` : ET('mps2::gnb::more')}
          <span class="${klass.creator_banner_link_icon}">${MShopDefaultIcon()}</span>
        </a>
      </div>
    </div>
  </div>`;
};
