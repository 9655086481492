import { minMaxResidue } from './math.js';

/**
 *
 * @param {number} min
 * @param {number} value
 * @param {number} max
 * @return {number}
 */
export const reflectIntoRange = (min, value, max) => {
  const distance = max - min;
  const optimized_value = ((value - min) % (distance * 2)) + min;
  const [adjusted_value, residual] = minMaxResidue(min, optimized_value, max);

  return adjusted_value - residual;
};
