import { $addClass, $delegate, $hasClass, $removeClass } from 'fxdom/es';
import { go, map } from 'fxjs/es';
import './style.styl';

/* [example template]
<div is="collapse" class="collapse">
  <a class="menu collapse-anchor">
    <i class="d-block lnr lnr-database"></i>
    ${T('정산')}
  </a>
  <div class='collapse-content'>

  </div>
</div>
*/

export const init = (_host_el) => {
  const $collapses = _host_el ? [_host_el] : document.querySelectorAll('[is=collapse]');

  go(
    $collapses,
    map((el) => {
      $delegate('click', '.collapse-anchor', (e) => {
        const is_opened = $hasClass('opened', el);
        !is_opened ? $addClass('opened', el) : $removeClass('opened', el);
      })(el);
    }),
  );
};
