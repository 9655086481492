import { html, View } from 'rune-ts';
import klass from './ButtonKeyword.module.scss';
import { typo } from '../../../../shared/typography/typo';

export interface ButtonKeywordProps {
  name: string;
  theme?: 'dark' | 'light';
}

export class ButtonKeyword extends View<ButtonKeywordProps> {
  override template() {
    return html`
      <button
        class="${typo('14_medium')} ${klass.button_keyword} ${klass[
          this.data.theme ?? ButtonKeyword.Theme.light
        ]}"
        type="button"
      >
        ${this.data.name}
      </button>
    `;
  }

  static Theme = {
    dark: 'dark',
    light: 'light',
  } as const;
}
