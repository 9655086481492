import { CustomEventWithDetail, html, on, View } from 'rune-ts';
import klass from './SubHeader.module.scss';
import { PlusCreatorLogo, PlusCreatorLogoProps } from '../PlusCreatorLogo/PlusCreatorLogo';
import { htmlIf } from '../../../../shared/util';
import { typo } from '../../../../shared/typography/typo';
import {
  cleanActiveEls,
  focusSelected,
  getLongestOverflow,
  init,
} from '../../../../../../modules/Components/SlideSelector/F/Function/slideselector';
import { MakeOptional } from '../../../../shared/type/types';
import { getTransformValue } from '../../../../../../modules/Creator/Home/F/utils/transform';
import { removeCssUnit } from '../../../../../../modules/Creator/Home/F/utils/style';
import { DOCUMENT_EVENT, listenDocumentEvent, sendDocumentEvent } from '../../../../shared/util/event';
import { ButtonText } from '../../atoms/ButtonText/ButtonText';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { loading, loadingEnd } from '../../../../shared/client/global';
import { getGnbMenu } from '../../../../shared/constant';
import { isApp, mpsPostMessage } from '../../../../shared/app/util';
import { makeUtilScreenNavigate } from '../../../../shared/app/navigate';
import { makePlusUrl } from '../../../../shared/util/url';

interface SubMenuData {
  label: string;
  menu_key: string;
  sub_menu_key: string;
}

interface MobileMenu {
  name: string;
  key: string;
  url: string;
  sub_menus?: { name: string; key: string; url: string }[];
}

export type SubHeaderMobileData = Omit<PlusCreatorLogoProps, 'is_mobile'> & {
  menus: MobileMenu[];
};

export interface SubHeaderMobileState {
  selected_menu_key?: string;
  selected_sub_menu_key?: string;
  set_focus_on_selected: boolean;
  reset_on_selected?: boolean;
}

export class SubHeaderMenuChangedEvent extends CustomEventWithDetail<MobileMenu> {}

export type SubHeaderMobileOptions = MakeOptional<SubHeaderMobileState, 'set_focus_on_selected'>;

export class SubHeaderMobile extends View<SubHeaderMobileData> {
  state: SubHeaderMobileState;

  private active_sub_menu_row_el: Element | null = null;
  private active_sub_menu_view: ButtonText | undefined;

  private SUB_MENU_BTN = 'sub_menu_btn';

  constructor(data: SubHeaderMobileData, options: SubHeaderMobileOptions) {
    super(data);

    this.state = {
      ...options,
      set_focus_on_selected: options.set_focus_on_selected ?? false,
    };
  }

  /** 중 카테고리 컨테이너가 있는지 여부, 있는 경우 페이지에서 그만큼 padding-top 밀어줄 때 사용 */
  static hasSubMenuContainer(
    prop: Pick<SubHeaderMobileState, 'selected_menu_key'> & { menus: MobileMenu[] },
  ) {
    return !!prop.menus.find((menu) => menu.key == prop.selected_menu_key && menu.sub_menus?.length);
  }

  static handleChangeMenuOutside({ enable_post_message = false }: { enable_post_message?: boolean } = {}) {
    if (MShopUtilF.isMobile()) {
      listenDocumentEvent(DOCUMENT_EVENT.sub_header_menu_change, (event) => {
        const { menu, cate_list_id } = event.detail;

        const store_domain_name = box.sel('domain_name');
        const crew_domain_name = box.sel('crew_domain_name');

        let url;

        if (menu.url) {
          url = `/${ET.lang}${menu.url}`;
        } else if (store_domain_name) {
          url = `/${ET.lang}/${box.sel('domain_name')}/products?cate_list_id=${cate_list_id}`;
        } else {
          url = `/${ET.lang}/@/products?cate_list_id=${cate_list_id}`;
        }

        if (isApp() && enable_post_message) {
          mpsPostMessage(makeUtilScreenNavigate(crew_domain_name ? makePlusUrl(crew_domain_name, url) : url));
        } else {
          loading();
          setTimeout(() => {
            loadingEnd();
          }, 1000);
          location.href = url;
        }
      });
    }
  }

  @on('change', `.slide-selector`)
  handleChangeMenu(event) {
    const mobile_menu = this.data.menus.find((menu) => menu.key === event.detail.value);

    if (this.active_sub_menu_row_el) {
      this.active_sub_menu_row_el.setAttribute('data-selected', 'false');
      if (this.active_sub_menu_view) {
        this.active_sub_menu_view.deactivate();
      }
    }

    const row_el_selector = `.${klass.sub_menu__row}[data-value="${event.detail.value}"]`;
    const changed_sub_menu_row_el = this.element().querySelector(row_el_selector);
    if (changed_sub_menu_row_el) {
      changed_sub_menu_row_el.setAttribute('data-selected', 'true');
      this.active_sub_menu_row_el = changed_sub_menu_row_el;
      this.active_sub_menu_view = this.subViewIn(row_el_selector, ButtonText) ?? undefined;
      if (this.active_sub_menu_view) {
        this.active_sub_menu_view.activate();
      }
    }

    sendDocumentEvent(DOCUMENT_EVENT.sub_header_menu_change, {
      cate_list_id: mobile_menu?.key === getGnbMenu('all_products').key ? undefined : mobile_menu?.key,
      cate_item_id: undefined,
      menu: mobile_menu,
    });

    if (isApp() && this.state.reset_on_selected) {
      const slide_selector_el = this.element().querySelector('.slide-selector');
      cleanActiveEls(slide_selector_el);
      if (changed_sub_menu_row_el) {
        changed_sub_menu_row_el.setAttribute('data-selected', 'false');
      }
    }
  }

  override onMount() {
    super.onMount();
    const slide_selector_el = this.element().querySelector('.slide-selector');

    const right_padding = 16;

    const background_filters = [
      this.element().querySelector(`.${klass.background_filter_left}`),
      this.element().querySelector(`.${klass.background_filter_right}`),
    ];

    const toggleBackgroundFilter = (direction: 'left' | 'right', on: boolean) => {
      const filter_el = (direction == 'left' ? background_filters[0] : background_filters[1]) as HTMLElement;
      if (on) {
        filter_el.style.opacity = '1';
      } else {
        filter_el.style.opacity = '0';
      }
    };

    // 나중에 opacity 관련 transition 끝나고 element 날려주는 것 까지 붙여야 쓸 만할듯, 계속 가리고 있어서 클릭 영역 방해
    const handleBackgroundFilter = (row_el: HTMLElement) => {
      // row 의 translateX 가 -3 이하면 왼쪽 켜기
      // row 의 translateX 가 longest 보다 3이상 크면 켜기

      const translate_x = +removeCssUnit(getTransformValue('translateX', row_el));
      if (translate_x < -3) {
        toggleBackgroundFilter('left', true);
      } else {
        toggleBackgroundFilter('left', false);
      }

      if (translate_x + getLongestOverflow(slide_selector_el) > right_padding + 3) {
        toggleBackgroundFilter('right', true);
      } else {
        toggleBackgroundFilter('right', false);
      }
    };

    init(slide_selector_el, {
      hooks: {
        // onMove: handleBackgroundFilter,
      },
      right_padding,
    });

    // const row_el = this.element().querySelector('.slide-selector__row') as HTMLElement;

    // handleBackgroundFilter(row_el);

    if (this.state.set_focus_on_selected) {
      focusSelected(slide_selector_el);
    }
  }

  override onRender() {
    this.active_sub_menu_row_el = this.element().querySelector(
      `.${klass.sub_menu__row}[data-value="${this.state.selected_menu_key}"]`,
    );

    this.subViews(ButtonText).forEach((view) => {
      if (view.isActive()) {
        this.active_sub_menu_view = view;
      }
    });

    this.delegate('click', ButtonText<SubMenuData>, (e, view) => {
      if (this.active_sub_menu_view) {
        this.active_sub_menu_view.deactivate();
      }
      view.activate();
      this.active_sub_menu_view = view;
      const mobile_menu = this.data.menus.find((menu) => menu.key === view.data.menu_key);
      sendDocumentEvent('sub_header_menu_change', {
        cate_list_id: view.data.menu_key,
        cate_item_id: view.data.sub_menu_key,
        menu: mobile_menu,
      });
    });
  }

  override template() {
    const { creator, plus, menus } = this.data;
    const { selected_menu_key, selected_sub_menu_key } = this.state;

    const plus_creator_logo_props = {
      plus,
      creator,
      is_mobile: true,
    };

    const is_plus_logo_surrounded = PlusCreatorLogo.isSurrounded({ plus: !!plus, creator: !!creator });
    const is_plus_logo_empty = PlusCreatorLogo.isEmpty({ plus: !!plus, creator: !!creator });

    return html`<div
      class="${klass.sub_header}
${htmlIf(klass.large_logo, is_plus_logo_surrounded)}
${htmlIf(klass.empty_logo, is_plus_logo_empty)}
"
    >
      <div class="${klass.first_container}">
        ${new PlusCreatorLogo(plus_creator_logo_props)}

        <div
          class="${klass.menu_container} ${typo('14_medium')} slide-selector"
          data-sensitivity="1"
          data-min="1"
          data-selector-lazy="true"
        >
          <div class="slide-selector__wrapper ${klass.menu_wrapper}">
            <div class="slide-selector__row ${klass.menu_row}">
              ${menus.map((menu, idx) => {
                const selected = selected_menu_key == menu.key;

                return html`<button
                  class="${klass.menu_btn} slide-selector__item"
                  data-selected="${selected}"
                  data-idx="${idx}"
                  data-value="${menu.key}"
                  data-url="${menu.url}"
                >
                  ${menu.name}
                </button>`;
              })}
            </div>
          </div>
          <!-- <div class="${klass.background_filter} ${klass.background_filter_left}"></div> -->
          <!-- <div class="${klass.background_filter} ${klass.background_filter_right}"></div> -->
        </div>
      </div>
      <div class="${klass.second_container}">
        <div class="${klass.sub_menu_container}">
          ${menus.map((menu) => {
            if (!menu.sub_menus?.length) {
              return '';
            }
            const menu_selected = selected_menu_key === menu.key;
            return html`
              <div class="${klass.sub_menu__row}" data-selected="${menu_selected}" data-value="${menu.key}">
                ${menu.sub_menus.map((sub_menu) => {
                  const sub_menu_selected =
                    selected_sub_menu_key === sub_menu.key || (menu.sub_menus && menu.sub_menus.length < 2);
                  return new ButtonText<SubMenuData>(
                    { label: sub_menu.name, sub_menu_key: sub_menu.key, menu_key: menu.key },
                    {
                      container_klass: this.SUB_MENU_BTN,
                      active_klass: klass.sub_menu_btn_active,
                      font_normal: '14_medium',
                      font_bold: '14_bold',
                      is_active: menu_selected && sub_menu_selected,
                      data_id: sub_menu.key,
                    },
                  );
                })}
              </div>
            `;
          })}
        </div>
      </div>
    </div> `;
  }
}
