import { OrderCustomerOrderS } from '../../../../Order/CustomerOrder/S/Function/module/OrderCustomerOrderS.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { flatMap, go, map, reduce, sel } from 'fxjs/es';
export const ga = () => {};

export const signUpMpCreator = (user) => {
  try {
    const email = user?.email || box.sel('is_user->email') || '';
    window.email_address = email;
    window.phone_number = '';

    if (typeof gtag == 'function') {
      gtag('event', 'sign_up');
      if (G.collabo_type == '') {
        if (T.lang == 'kr') {
          gtag('set', 'user_data', {
            email,
          });
          gtag('event', 'conversion', {
            send_to: 'AW-881715956/cDuKCL252GYQ9NW3pAM',
          });
          gtag('event', 'conversion', { send_to: 'AW-881715956/kz_HCO776J8YEPTVt6QD' });
        } else if (T.lang == 'jp') {
          gtag('event', 'conversion', { send_to: 'AW-828951906/_vH5CPGf7cIBEOKao4sD' });
        } else {
          gtag('event', 'conversion', {
            send_to: 'AW-828951906/BZPzCKSE5o8BEOKao4sD',
          });
        }
      }
    }
  } catch (err) {}
};

export const bizGoodsTalk = async (collabo_type) => {
  return new Promise((rs) => {
    if (typeof gtag == 'function' && collabo_type == '') {
      const callback = function () {
        rs(true);
      };
      gtag('event', 'conversion', {
        send_to: 'AW-881715956/Ow-xCL-k9pcYEPTVt6QD',
        event_callback: callback,
      });
    } else {
      rs(true);
    }
  });
};

export function youtubeLinking() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    gtag('event', 'conversion', {
      send_to: 'AW-881715956/vfO1CMeclJkZEPTVt6QD',
    });
    gtag('event', '유튜브 x 마플샵 연결하기', { event_category: '유튜브 x 마플샵 연결하기' });
  }
}

export function offlinePopupStoreApp() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    gtag('event', 'conversion', {
      send_to: 'AW-881715956/ZnXcCKiX36cZEPTVt6QD',
    });
    gtag('event', '오프라인 팝업 스토어 신청', { event_category: '오프라인 팝업 스토어 신청' });
  }
}

// TODO 병진
export function brandCommerceRequest() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    //   gtag('event', 'conversion', {
    //     send_to: 'AW-881715956/ZnXcCKiX36cZEPTVt6QD',
    //   });
    gtag('event', '브랜드커머스 신청', { event_category: '브랜드커머스 신청' });
  }
}

export function applyCreator() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    gtag('event', '크리에이터 신청', { event_category: '크리에이터 신청' });
  }
}

export function applyPlus() {
  if (typeof gtag == 'function' && G.collabo_type == 'creator') {
    gtag('event', 'conversion', { send_to: 'AW-881715956/VRuBCIrgo9oBEPTVt6QD' });
    gtag('event', '마플샵플러스 입점신청', { event_category: '마플샵플러스 입점신청' });
  }
}

export function addToCartMPS({ quantity }) {
  try {
    // 일단 상품 상세에서만 쓰이는 용도
    // gtag
    if (typeof gtag == 'function') {
      const product_price = (() => {
        return PriceS.add(box.sel('product->price' + _en), box.sel('product->profit' + _en));
      })();

      const ga_add_to_cart = {
        dynx_itemid: [box.sel('product->id')], // 장바구니 담은 상품의 아이디 배열
        dynx_itemid2: [box.sel('product->id')], // 장바구니 담은 상품의 아이디 배열
        dynx_pagetype: 'conversionintent',
        dynx_totalvalue: PriceS.mult(parseInt(quantity), product_price), // 장바구니 담은 상품 총 금액
        ecomm_prodid: [box.sel('product->id')],
        ecomm_pagetype: 'conversionintent',
        event_label: 'conversionintent',
        items: [
          {
            id: box.sel('product->id'),
            name: box.sel('product->name' + _en) || box.sel('product->_->base_product->name' + _en),
            list_name: '장바구니',
            item_brand: box.sel('store->name'),
            quantity: parseInt(quantity),
            price: product_price,
          },
        ],
      };

      gtag('event', 'add_to_cart', ga_add_to_cart);
    }
    // end gtag
  } catch (err) {}
}

export function beginCheckoutMPS() {
  try {
    // gtag

    if (typeof gtag == 'function') {
      const is_far = box.sel('creator__address_is_far');
      const selected_country_shipping_cost = box.sel('selected_country_shipping_cost');
      const marpple_shop_total_price_info = OrderCustomerOrderS.makeTotalPriceInfoWithUps(
        { ups: box.sel('user_products'), _en: G._en },
        {
          is_far,
          selected_country_shipping_cost,
        },
      );
      const ups = box.sel('user_products');
      const up_cs = go(
        ups,
        flatMap((up) => up._.up_cs),
      );

      const up_items = go(
        up_cs,
        flatMap(function (up_c) {
          return go(
            up_c._.up_c_ss,
            map((up_c_s) => {
              // original_product_id 마플일땐 base_product_id를 그려야 할수도 있다
              return {
                original_product_id: sel('_.product_color._.stores_product.original_product_id', up_c),
                // original_product_id: up_c._.product_color._.stores_product.original_product_id,
                name:
                  up_c._.product_color['name' + G._en] || up_c._.product_color._.base_product['name' + G._en],
                quantity: up_c_s.quantity,
                price: up_c_s['discounted_price' + G._en],
              };
            }),
          );
        }),
      );
      const product_price = go(
        up_items,
        map(({ price, quantity }) => PriceS.mult(price, quantity)),
        reduce(PriceS.add),
      );

      const gtag_begin_checkout = {
        affiliation: 'MARPPLESHOP',
        value: product_price,
        currency: ET.lang == 'kr' ? 'KRW' : ET.lang == 'jp' ? 'JPY' : 'USD',
        dynx_itemid: map(function (up) {
          return up._.product_color.product_id;
        }, ups), // 구해한 상품의 아이디 배열
        dynx_itemid2: map(function (up) {
          return up._.product_color.product_id;
        }, ups), // 구해한 상품의 아이디 배열
        ecomm_prodid: map(function (up) {
          return up._.product_color.product_id;
        }, ups),
        dynx_pagetype: 'checkout',
        dynx_totalvalue: product_price, // 총 결제금액
        ecomm_pagetype: 'checkout',
        event_label: 'checkout',
        shipping: marpple_shop_total_price_info.shippings_price,
        items: map(function (up) {
          return {
            id: up._.product_color.product_id,
            name: up._.product_color['name' + _en] || up._.product_color._.base_product['name' + _en],
            quantity: up.quantity,
            item_brand: sel('_.product_color._.stores_product._.store.name', up),
            price: up['discounted_price' + _en],
            list_name: '결제 시작',
          };
        }, up_cs),
      };

      gtag('event', 'begin_checkout', gtag_begin_checkout);
    }

    // end gtag
  } catch (err) {}
}
