import { html, omitBy } from 'fxjs/es';
import querystring from 'querystring';
import { UtilS } from '../../../S/Function/module/UtilS.js';

const RESIZE_IMAGE_URL = 'image1.marpple.co';
const ORIGIN_IMAGE_URL = 's3.marpple.co';

const is_gif = (url) => /.+\.gif$/.test(url);

const addProtocol = (url) => ([/^http/, /^https/].some((re) => re.test(url)) ? url : 'https:' + url);

/**
 * Constructs a URL with specified resizing options.
 *
 * @param {Object} options - The options for resizing the image.
 * @param {string} options.url - The URL of the image to resize.
 * @param {number} [options.quality] - The quality of the resized image (1-100).
 * @param {number} [options.height] - The desired height of the image.
 * @param {number} options.width - The desired width of the image.
 * @param {string} options.format - The desired format of the output image (e.g., 'jpg', 'png').
 * @param {string} [options.bg] - The background color, applicable if the image has transparency.
 * @param {number} [options.r] - ???
 * @return {string} The constructed URL that incorporates all resizing parameters.
 */
export const getResizedUrl = ({ url, quality, height, width, format, bg, r }) => {
  if (!url || is_gif(url) || !url.includes('s3.marpple.co')) return url || '';
  const url_obj = new URL(addProtocol(url));
  const params = { q: quality, h: height, w: width, f: format, bg, r };
  const qs = UtilS.makeQueryString(params) || '';
  return UtilS.escape(`https://${RESIZE_IMAGE_URL}${url_obj.pathname}?${qs}`);
};

export const getResizedSrcSet = ({ url, baseWidth, quality, height, format, bg, r }) => {
  return [1, 2]
    .map(
      (num) =>
        `${getResizedUrl({
          width: baseWidth * num,
          url,
          format,
          quality,
          height,
          bg,
          r,
        })} ${num}x`,
    )
    .join(',');
};

export const updateResizeOption = ({ url, quality, height, width, format, bg, is_ie }) => {
  if (!url || is_gif(url)) return url;
  const url_obj = new URL(addProtocol(url));
  const prev_option = querystring.parse(url_obj.search.slice(1));
  const f = is_ie && format == 'webp' ? '' : format;
  const params = { ...prev_option, ...omitBy(([k, v]) => !v, { q: quality, h: height, w: width, f, bg }) };
  const qs = UtilS.makeQueryString(params) || '';

  return UtilS.escape(`https://${RESIZE_IMAGE_URL}${url_obj.pathname}?${qs}`);
};

export const getResized = ({ klass, ...resize_option }) => {
  return !resize_option.url.includes(ORIGIN_IMAGE_URL)
    ? html`<img src="${UtilS.escape(resize_option.url)}" class="${klass}" alt="" />`
    : html`<img src="${getResizedUrl(resize_option)}" class="${klass}" alt="" />`;
};

export const getResized70Webp = (url, width) => {
  return getResizedUrl({
    url,
    width: width * 2,
    quality: 70,
    format: 'webp',
  });
};

export const getResized70WebpImages = (urls, width) => {
  return urls.map((url) =>
    getResizedUrl({
      url,
      width: width * 2,
      quality: 70,
      format: 'webp',
    }),
  );
};

export const getResizedWidthX2Webp = (url, width, quality) => {
  return getResizedUrl({
    url,
    width: width * 2,
    quality: quality || 80,
    format: 'webp',
  });
};

export const getResizedWidthX2WebpImages = (urls, width, quality) => {
  return urls.map((url) =>
    getResizedUrl({
      url,
      width: width * 2,
      quality: quality || 80,
      format: 'webp',
    }),
  );
};
