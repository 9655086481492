import { $delegate, $qs } from 'fxdom/es';
import { go } from 'fxjs/es';

/**
 *
 * @param {HTMLElement=} target_el
 * @param {function(Event & { delegateTarget: Element }, boolean, string): void=} onChange
 * @return void
 */

export const checkbox_component = 'mshop-core__checkbox';

export const initCheckbox = ({ target_el, hooks: { onChange } = {} }) => {
  const component = checkbox_component;

  const checkbox_container_el = target_el || $qs(`.${component}`);
  if (!checkbox_container_el) return;

  go(
    checkbox_container_el,
    $delegate('change', `.${component}__input`, function toggleCheckbox(e) {
      const { checked: is_checked, name } = e.currentTarget;
      checkbox_container_el.dataset.checked = is_checked;
      onChange(e, is_checked, name);
    }),
  );
};
