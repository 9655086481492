import { equals2, go, html, not, sel } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { MShopUtilS } from '../../../MShop/Util/S/Function/module/MShopUtilS.js';

const makeFooterCsHtml = ({ domain_name }) => html`
  <div class="store-footer-cs">
    <div class="store-footer-cs-major">
      <a href="/${T.lang}/${UtilS.escape(domain_name) || '@'}/help" class="store-footer-cs-major-faq_link">
        <p>
          ${domain_name
            ? T('store_footer::faq')
            : T(equals2(T.lang, 'kr') ? '고객센터 바로가기' : 'crew_mall::고객센터')}
        </p>
        <img
          src="//s3.marpple.co/files/u_1187077/2020/1/original/1418987cf09feb9af3025d9634884981d5edaf67eada1a7.svg"
          alt=""
        />
      </a>
      <ul class="store-footer-cs-major-lang">
        <li>
          <a
            class="mp-shop-lang"
            href="/kr/${UtilS.escape(domain_name)}"
            lang="kr"
            data-active="${equals2(T.lang, 'kr')}"
            >한국어</a
          >
        </li>
        <li>
          <a
            class="mp-shop-lang"
            href="/en/${UtilS.escape(domain_name)}"
            lang="en"
            data-active="${equals2(T.lang, 'en')}"
            >English</a
          >
        </li>
        <li>
          <a
            class="mp-shop-lang"
            href="/jp/${UtilS.escape(domain_name)}"
            lang="jp"
            data-active="${equals2(T.lang, 'jp')}"
            >日本語</a
          >
        </li>
      </ul>
      <span class="store-footer-cs-major-tel">${MShopUtilS.getCustomerServiceInfo().tel}</span>
    </div>
    <div class="store-footer-cs-minor">
      <a href="mailto:${MShopUtilS.getCustomerServiceInfo().email}" class="store-footer-cs-minor-email"
        >${MShopUtilS.getCustomerServiceInfo().email}</a
      >
      <span class="store-footer-cs-minor-time">
        <span data-pc-only>고객 상담 가능 시간</span>
        <span>평일 10:00 ~ 18:00</span>
      </span>
    </div>
  </div>
`;

const makeFooterInfoHtml = ({ domain_name }, login_user) => html`
  <div class="store-footer-info">
    <nav class="store-footer-info-nav">
      <ul>
        <!-- li><a href="#">${T('store_footer::about_us')}</a></li-->
        ${UtilS.htmlIfElse(
          domain_name,
          html`<li class="seller_studio">
            <a href="/${T.lang}/${UtilS.escape(domain_name)}/settings">${T('store_footer::seller_studio')}</a>
          </li>`,
          '',
        )}
        ${go(login_user, (user) => {
          return !sel('_.store.id', user) || not(equals2(sel('_.store.user_id', user), user.id))
            ? ''
            : html`
                <li class="my_store">
                  <a href="/${T.lang}/${UtilS.escape(sel('_.store.domain_name', user))}"
                    >${T('store_footer::my_store')}</a
                  >
                </li>
              `;
        })}
        ${login_user && login_user.type != 'TEMP'
          ? html`<li class="logout">
              <a class="mp-shop-logout" href="/${T.lang}/${UtilS.escape(domain_name) || '@'}/logout"
                >${T('store_footer::logout')}</a
              >
            </li>`
          : ''}
        ${!login_user || login_user.type == 'TEMP'
          ? html`<li class="login">
              <a
                href="/${T.lang}/${UtilS.escape(domain_name) || '@'}/login?url=/${T.lang}/${UtilS.escape(
                  domain_name,
                )}"
              >
                ${T('store_footer::login')}
              </a>
            </li>`
          : ''}
        <li>
          <a href="/@/terms" target="_blank"> ${T('store_footer::terms_and_conditions')} </a>
        </li>
        <li>
          <a href="/@/privacy" target="_blank"> ${T('store_footer::privacy_policy')} </a>
        </li>
        ${T.lang == 'kr' ? `<li><a href="/@/solution">신고접수</a></li>` : ''}
        <li class="company_info">
          <span>${T('store_footer::company_name')}</span>
        </li>
      </ul>
    </nav>
    <div class="store-footer-info-detail active">${T('store_footer::detail_info')}</div>
    <div class="store-footer-info-escrow">
      <img
        class="don_lazy"
        data-src="//s3.marpple.co/files/u_218933/2020/9/original/eda8624a623144cad19653d99b5535176452cadd1.png"
        alt=""
      />
      <a
        href="#"
        class="escrow"
        onclick="javascript:window.open('https://mark.inicis.com/mark/escrow_popup.php?mid=marketpre1','mark','scrollbars=no,resizable=no,width=565,height=683');"
      ></a>
    </div>
    <ul class="store-footer-info-lang">
      <li>
        <a
          class="mp-shop-lang"
          href="/kr/${UtilS.escape(domain_name)}"
          lang="kr"
          data-active="${equals2(T.lang, 'kr')}"
          >한국어</a
        >
      </li>
      <li>
        <a
          class="mp-shop-lang"
          href="/en/${UtilS.escape(domain_name)}"
          lang="en"
          data-active="${equals2(T.lang, 'en')}"
          >English</a
        >
      </li>
      <li>
        <a
          class="mp-shop-lang"
          href="/jp/${UtilS.escape(domain_name)}"
          lang="jp"
          data-active="${equals2(T.lang, 'jp')}"
          >日本語</a
        >
      </li>
    </ul>
    <span class="store-footer-info-copy"> &copy; ${new Date().getFullYear()}&nbsp;Marpple Corp. </span>
  </div>
`;

export const makeFooterHtml = ({ domain_name }, login_user) => legacyHtml`
  <footer class="store-footer">
    <div class="store-footer-body">
      ${makeFooterCsHtml({ domain_name })} ${makeFooterInfoHtml({ domain_name }, login_user)}
    </div>
  </footer>
`;
