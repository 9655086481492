import { UtilS } from '../../../../modules/Util/S/Function/module/UtilS';

const RESIZE_IMAGE_URL = 'image1.marpple.co';
const is_gif = (url) => /.+\.gif$/.test(url);
const addProtocol = (url) => ([/^http/, /^https/].some((re) => re.test(url)) ? url : 'https:' + url);

type GetResizedImageUrlParams = {
  url: string;
  quality?: number;
  height?: number;
  width?: number;
  format?: string;
  bg?: string;
  r?: string;
};

export const getResizedImageUrl = ({
  url,
  quality,
  height,
  width,
  format,
  bg,
  r,
}: GetResizedImageUrlParams) => {
  if (!url || is_gif(url)) return url || '';
  const url_obj = new URL(addProtocol(url));
  const params = { q: quality, h: height, w: width, f: format, bg, r };
  const qs = UtilS.makeQueryString(params) || '';
  return `https://${RESIZE_IMAGE_URL}${url_obj.pathname}?${qs}`;
};

export const getResizedImage70WebpUrl = (url: string, width: number) => {
  return getResizedImageUrl({
    url,
    width: width * 2,
    quality: 70,
    format: 'webp',
  });
};

export const getResizedImage100WebpUrl = (url: string, width: number) => {
  return getResizedImageUrl({
    url,
    width,
    quality: 100,
    format: 'webp',
  });
};
