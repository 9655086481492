import { PRODUCT_LIST_SORT } from '../constant';
import { AddKeysToObject } from '../../../shared/type/types';
import { ProductThumbnailOption } from './thumbnail';
import { associateProductsDbtNotExistInVariablesDbt } from '../repository/associateProductsDbt';

/**
 * 타입 임의로 수정 금지, 쿼리도 고려하여 수정 필요합니다.
 */
export type ProductListFetchOption = {
  limit: number;
  page?: number;
  cate_list_id?: number;
  cate_item_id?: number;
};

/**
 * associate 로 상품 목록을 조회했을 때 리턴되는 타입입니다.
 */
export type AssociatedProduct = Awaited<ReturnType<typeof associateProductsDbtNotExistInVariablesDbt>>[0];

type addKeyProduct = AddKeysToObject<
  Omit<Omit<AssociatedProduct, 'name_intl'>, 'base_product_name_intl'>,
  [
    ['store_name', string],
    // ['thumbnails', ProductThumbnails],
    ['thumbnail_ratio', number | undefined],
    ['review_score', number],
    ['review_count', number],
    ['quantity', number],
    ['order_count', number],
    ['store_id', number],
  ]
>;
/**
 * `ProductCard` 컴포넌트를 그리는데 필요한 데이터 타입입니다.
 */
export interface ProductCardData extends Omit<addKeyProduct, 'user_id'> {
  name: string;
  price: number;
  cate_item_name: string; // TODO: 실제 데이터로 변경
  user_id?: number | undefined;
  is_liked?: boolean;
  ranking?: number;
  out_of_stock: boolean;

  /**
   * `실시간` 뱃지 그릴 때 사용
   */
  ordered_at?: Date;
}

export const PRODUCT_CARD_THEME = {
  /**
   * 라이트 테마, 글자가 검정색
   */
  light: 'light',

  /**
   * 다크 테마, 글자가 흰색
   */
  dark: 'dark',

  /**
   * 배경색이 있는 경우
   */
  color: 'color',
} as const;

export type ProductCardTheme = keyof typeof PRODUCT_CARD_THEME;

/**
 * `ProductCard` 컴포넌트를 그리는데 필요한 옵션 타입입니다.
 */
export type ProductCardOption = ProductThumbnailOption & {
  is_mobile: boolean;
  type: 'normal' | 'compact';

  theme?: ProductCardTheme;

  hide_badges?: boolean;
  hide_tag?: boolean;

  /**
   * 스토어명, 상품명, 가격 등 하단 정보 숨기는 옵션
   *
   * 이 옵션을 켜면 좋아요 버튼이 우측 상단에 보여집니다.
   * @default false
   */
  hide_info?: boolean;

  is_horizontal?: boolean;
};

/**
 * `ProductCardList` 컴포넌트를 그리는데 필요한 데이터 타입입니다.
 */
export type ProductCardListData = {
  products: ProductCardData[];
};

/**
 * paginated 상품 목록을 조회했을 때 응답 데이터입니다.
 */
export type PaginatedProductListResponse = {
  products: ProductCardData[];
  total_count: number;
};

export type ProductResponse = {
  product: ProductCardData | null;
};

export type DefaultProductListSort = keyof typeof PRODUCT_LIST_SORT;

export interface SortOption {
  key: keyof typeof PRODUCT_LIST_SORT;
  value: string;
  name: string;
}

export type UserLikedProductListFetchOption = {
  limit: number;
  offset?: number;
};

export type UserLikedProductListReqData = {
  limit?: string;
  offset?: string;
};

export const bestProductTypeMap = {
  weekly: 'weekly',
  monthly: 'monthly',
} as const;

export type BestProductType = (typeof bestProductTypeMap)[keyof typeof bestProductTypeMap];
export interface ProductSalesCount {
  id: number;
  count: number;
}
