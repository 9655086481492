import axios, { CancelTokenSource } from 'axios';

export const initCancelTokenHelper = () => {
  let cancel_token: CancelTokenSource | undefined;
  const getCancelToken = () => {
    return cancel_token?.token;
  };
  const setCancelToken = () => {
    cancel_token = axios.CancelToken.source();
  };
  const cancelPrevRequest = () => {
    if (cancel_token) {
      cancel_token.cancel();
    }
  };
  return {
    getCancelToken,
    setCancelToken,
    cancelPrevRequest,
  };
};
