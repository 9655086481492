import { html } from 'fxjs/es';
import { PRODUCT_BADGE_TYPE } from '../Constant/item.js';
import { UtilNumberS } from '../../../../../../Util/Number/S/Function/module/UtilNumberS.js';

/**
 *
 * @param {{ type: string, name: string }} badge
 * @param {string} component
 * @return {string}
 */
export const makeProductBadge = (badge, component) => {
  if (!badge) return '';
  if (badge.type == PRODUCT_BADGE_TYPE.sold_out) return '';
  return `<div class="${component}__badge ${component}__badge--${badge.type}">${badge.name}</div>`;
};

/**
 *
 * @param {{ type: string, name: string }} badge
 * @param {string} component
 * @return {string}
 */
export const makeMps2ProductBadge = (badge) => {
  return `<div class="badge badge__${badge.type}">${badge.name}</div>`;
};

/**
 * @param {string} component
 * @param {object} badge
 * @return {string}
 */
export const makeProductBottomBadge = (badge, component) => {
  const getBadgeInner = () => {
    if (!badge) return 'none';
    if (badge.type == PRODUCT_BADGE_TYPE.limit && badge.reference.show_rest_quantity) {
      return T('mshop::ProductBadge::quantity_alert', { quantity: badge.reference.rest_quantity });
    }

    if (badge.type == PRODUCT_BADGE_TYPE.sell_at && badge.reference.is_close_imminent) {
      if (badge.reference.time_diff.day > 0) {
        return T('mshop::ProductBadge::date_alert', { diff: badge.reference.time_diff.day });
      }

      const to2Digit = UtilNumberS.addZeroInteger(2);
      const { hour, minute, second } = badge.reference.time_diff;
      const time_phrase = `${to2Digit(hour)}:${to2Digit(minute)}:${to2Digit(second)}`;
      return html`<span>
        <span data-end_at="${badge.reference.end_at}" class="remain_time">${time_phrase}</span> ${T(
          'mshop::ProductBadge::time_alert',
        )}
      </span>`;
    }

    if (badge.type == PRODUCT_BADGE_TYPE.sold_out) {
      return T('mshop::ProductBadge::soldout_alert');
    }

    return 'none';
  };

  const inner = getBadgeInner();

  const klass_variant =
    inner == 'none' ? 'hidden' : badge?.type == PRODUCT_BADGE_TYPE.sold_out ? 'black' : 'blue';

  return html`<div class="${component}__bottom-badge ${component}__bottom-badge--${klass_variant}">
    ${inner}
  </div>`;
};
