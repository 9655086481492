import addMonths from 'date-fns/fp/addMonths/index.js';
import format from 'date-fns/fp/format/index.js';
import parse from 'date-fns/fp/parse/index.js';
import getDate from 'date-fns/getDate/index.js';
import { add, evolve, filter, go, identity, ifElse, pipe, pluck, reduce } from 'fxjs/es';
import { CurrencyConstantS } from '../../../Currency/S/Constant/module/CurrencyConstantS.js';
import { _getBusinessDayAfter } from '../../../Holiday/S/fs.js';
import { MShopUtilConstantS } from '../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { addDecimal, divDecimal, mulDecimal, subDecimal } from '../../../Util/Number/S/Function/decimal.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { BANK_TYPES } from '../../BankAccount/S/constant.js';
import {
  INCOME_TAX_RATE,
  PG_COMMISSION_RATE,
  RESIDENT_TAX_RATE,
  VAT_RATE,
} from '../../SettingAdjustments/S/constant.js';

export const getVat = (amount) => Math.round(subDecimal(amount, divDecimal(amount, addDecimal(1, VAT_RATE)))); // amount - (amount / (1 + vat_rate))

export const getPreTax = (amount) => {
  /*
   * 1 의 자리 내림
   * */
  const round10 = UtilS.floorTo(1);
  const income_tax = round10(mulDecimal(amount, INCOME_TAX_RATE)); // 소득세
  const resident_tax = round10(mulDecimal(income_tax, RESIDENT_TAX_RATE)); // 주민세
  return { income_tax, resident_tax };
};

export const getPersonTaxDetail = (amount) => {
  // 부가세
  const vat = getVat(amount);
  // 원천징수액
  const { income_tax, resident_tax } = getPreTax(amount - vat);
  return { vat, income_tax, resident_tax };
};

export const getWithdrawLimitDate = (withdraw_date, holidays) =>
  go(
    new Date(withdraw_date),
    ifElse((date) => getDate(date) >= 11, addMonths(1), identity),
    format('yyyyMM10 000000'),
    parse(new Date())('yyyyMMdd HHmmss'),
    (date) => _getBusinessDayAfter(9, date, holidays),
  );

export const calcTotalWithdraw = pipe(
  filter((w) => w.approved_at),
  pluck('real_amount'),
  reduce(add),
);

export const getWithdrawTaxAmount = (amount, bank_account_type) => {
  const is_company = bank_account_type == BANK_TYPES.COMPANY;
  const is_foreign = bank_account_type == BANK_TYPES.FOREIGN;
  return go(
    amount,
    (amount) => {
      if (is_company) {
        return { vat: 0, income_tax: 0, resident_tax: 0 };
      } else if (is_foreign) {
        return { vat: getVat(amount), income_tax: 0, resident_tax: 0 };
      } else {
        return getPersonTaxDetail(amount);
      }
    },
    ({ vat, income_tax, resident_tax }) => {
      return { vat, income_tax, resident_tax, amount: amount - vat - income_tax - resident_tax };
    },
  );
};

const sumProfit = ({ profit, bonus_profit, crew_margin }) => {
  return (profit || 0) + (bonus_profit || 0) + (crew_margin || 0);
};

export const getWithoutFeeAmount = (currency) => (adj) => {
  return go(
    sumProfit(adj),
    (profit) => {
      return profit - (adj.fee_amount || 0) - getPgCommissionAmount(currency)(adj);
    },
    (settlement) => {
      if (!currency || currency == CurrencyConstantS.CURRENCY.KRW) {
        return Math.round(settlement);
      }
      return UtilS.floorTo(-2, divDecimal(settlement, adj.exchange_rate));
    },
  );
};

export const getPgCommissionAmount = (currency) => (adj) => {
  return go(
    sumProfit(adj),
    (profit) => (adj.no_commission ? 0 : mulDecimal(profit - (adj.fee_amount || 0), PG_COMMISSION_RATE)),
    (settlement) => {
      if (!currency || currency == CurrencyConstantS.CURRENCY.KRW) {
        return Math.round(settlement);
      }
      return UtilS.floorTo(-2, divDecimal(settlement, adj.exchange_rate));
    },
  );
};

export const changeStringColumnToNumber = evolve({
  amount: Number,
  dtg_amount: Number,
  pb_amount: Number,
  pod_amount: Number,
  real_amount: Number,
  tpl_amount: Number,
});

export const setAbstractNoPgCommissionByAdjustment = (adjs) => {
  return adjs.map((adj) => {
    /* TODO 일단 SOOPTORE만 no_commission 인데 앞으로 어떻게할지 기획이 나오면 그때 */
    const no_commission = adj.crew_id == MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID;
    return { ...adj, no_commission };
  });
};
