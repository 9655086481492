import { View, html } from 'rune-ts';
import klass from './AdultVerificationThumbnail.module.scss';
import { AdultVerificationIcon18Plus, AdultVerificationIcon19 } from '../../atoms/Icon';

export class AdultVerificationThumbnail extends View {
  private klass = '';

  constructor(option: { klass?: string } = {}) {
    super({});
    this.klass += option.klass ?? '';
  }

  override template() {
    return html`<div class="${klass.adult_verification} ${this.klass}">
      <span class="${klass.icon}">
        ${ET.lang === 'kr' ? AdultVerificationIcon19 : AdultVerificationIcon18Plus}
      </span>
    </div>`;
  }
}
