import { html, each, go } from 'fxjs/es';
import { selectOutOfStockBpcs } from '../../../Creator/Setting/S/fs.js';
import { MShopShareFramePopUpF } from '../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { getBaseProductInMaker, getCurrentBpcInMaker, getRealFcanvass } from '../getSth.js';
import { changeProductColorByBpc, renderFacesByCurrentBpcfsInMakerA } from '../mp_maker.js';
import { isNeedPreview, isWatchBand } from '../../S/categorize.js';
import { renderMakerPageShade } from '../CvPreview/Shade/render.js';
import { getCurrentFcanvas, getCvDesigns } from '../Fcanvas/cv_object.js';
import { marpplizerProxy } from '../Marpplizer/marpplizer.js';
import { NewMakerCallConditionPopupF } from '../../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { renderVisibleWhenSelected } from '../select_faces.js';

function makeColorName(bpc) {
  return bpc ? ($1('#dream_factory') ? bpc['name2' + _en] || bpc['name' + _en] : bpc['name' + _en]) : '';
}

export function getBpcTitle() {
  const bp = getBaseProductInMaker();
  if (isWatchBand(bp)) return T('Buckle Color');
  if (isNeedPreview(bp)) return T('maker::Preview');
  return T('Color');
}

const stockColorSizeListToString = (base_product_colors) => {
  return base_product_colors
    .filter((base_product_color) => {
      return base_product_color.out_of_stock_base_product_sizes?.length;
    })
    .map((base_product_color) => {
      return html` <div class="product-out-of-stock-list__item">
        ${base_product_color['name' + G._en]}&nbsp;${base_product_color.out_of_stock_base_product_sizes
          .map((bps) => bps['name' + G._en])
          .join(', ')}<span class="product-out-of-stock-list__slash">/</span>
      </div>`;
    })
    .join('');
};

export const alertOutOfStockBpsList = (_base_product_colors) => {
  const { base_product_colors, has_out_of_stock } = selectOutOfStockBpcs(_base_product_colors);
  if (has_out_of_stock) {
    return MShopShareFramePopUpF.alert({
      title: ET('mps2::product_detail::out_of_stock::title2'),
      body:
        ET('mps2::product_detail::out_of_stock::description2') +
        html`
          <div class="product-out-of-stock-list">${stockColorSizeListToString(base_product_colors)}</div>
        `,
    });
  }
};

export const alertOutOfStockBpsListWithPossibleCount = async (_base_product_colors) => {
  const { base_product_colors, possible_stock_bps_length, has_out_of_stock } =
    selectOutOfStockBpcs(_base_product_colors);
  if (has_out_of_stock) {
    return MShopShareFramePopUpF.confirm({
      title: ET('mps2::product_detail::out_of_stock::title3'),
      body:
        (possible_stock_bps_length > 0
          ? ET('mps2::product_detail::out_of_stock::description3', { len: possible_stock_bps_length })
          : ET('mps2::product_detail::out_of_stock::description1')) +
        html`
          <div class="product-out-of-stock-list">${stockColorSizeListToString(base_product_colors)}</div>
        `,
      ok: ET('mps2::product_detail::out_of_stock::submit::ok'),
      cancel: ET('mps2::product_detail::out_of_stock::submit::cancel'),
    });
  }
  return true;
};

const alertSelectedCheckBpcOutOfStock = (base_product_color) => {
  if (G.collabo_type !== 'creator') return;

  const is_out_of_stock_in_bpc = !base_product_color._.base_product_sizes2.find(
    (bps2) => !bps2._is_not_stock,
  );
  if (is_out_of_stock_in_bpc) {
    return MShopShareFramePopUpF.alert({
      title: ET('mps2::product_detail::out_of_stock::title1'),
      body: ET('mps2::product_detail::out_of_stock::description1'),
    });
  }
  alertOutOfStockBpsList([base_product_color]);
};

export async function changeProductBpcA(bpc) {
  changeProductColorByBpc(bpc);
  await renderFacesByCurrentBpcfsInMakerA();
  if (G.mp.maker.editing_canvas().preview) {
    await go(
      getRealFcanvass(),
      each((fcanvas) => renderMakerPageShade(fcanvas, getCvDesigns(fcanvas._objects))),
    );
  }
  await G.mp.maker.reset_layer();
  // NewMakerCallConditionPopupF.uiRender();
}
export async function eventProductBpcA(ct) {
  $.selectable3(ct);
  const bpc = box.sel(ct);
  G.mp.maker.unselect_all();
  if ($1('.color.section .bp_color_name')) $.text($1('.color.section .bp_color_name'), makeColorName(bpc));
  if ($1('.color_picker .bp_color_name')) $.text($1('.color_picker .bp_color_name'), makeColorName(bpc));
  await changeProductBpcA(bpc);
  await marpplizerProxy();

  if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
    renderVisibleWhenSelected(getCurrentFcanvas());
  }

  alertSelectedCheckBpcOutOfStock(bpc);
}

export function getCurrentBpcName() {
  return go(getCurrentBpcInMaker(), makeColorName);
}
