import { go, map, reduce, add } from 'fxjs/es';
import { $css } from 'fxdom/es';
import { document_wrapper } from '../../../../Util/S/constant.js';

const htmlEl = document_wrapper.querySelector?.('html');

export const removeCssUnit = (str = '') =>
  typeof str == 'number' ? str : str.replace(/rem|em|px|vw|vh/, '');

export const getRemToPx = (rem) => {
  const rootSize = window.getComputedStyle(htmlEl).fontSize;
  return parseFloat(rootSize) * rem;
};

export const getTotalHeight = (el) =>
  go(
    [el.offsetHeight, $css('margin-top', el), $css('margin-bottom', el)],
    map(removeCssUnit),
    map((n) => +n),
    reduce(add),
  );
