import { html } from 'fxjs/es';
import { MShopUtilS } from '../../../../MShop/Util/S/Function/module/MShopUtilS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const kr_studio_privacy_20230125 = ({ store_name }) => {
  return html`
    <div class="body terms_body">
      <div class="title">
        <h2>셀러스튜디오 개인정보처리방침</h2>
      </div>
      <div class="paragraph inner_hidden">
        <p>
          (주)마플코퍼레이션(이하 "회사"라고 함)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및
          정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을
          준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
          마플코퍼레이션의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.
        </p>
      </div>
      <div class="agree_header">
        <div class="table_of_contents">
          <ul>
            <li><a href="#privacy1">1. 셀러스튜디오 개인정보 처리방침이란</a></li>
            <li><a href="#privacy2">2. 수집하는 개인정보의 항목 및 수집방법</a></li>
            <li><a href="#privacy3">3. 개인정보 수집 및 이용목적</a></li>
            <li><a href="#privacy4">4. 개인정보 공유 및 제공</a></li>
            <li><a href="#privacy5">5. 개인정보 파기</a></li>
            <li><a href="#privacy6">6. 고객의 권리와 의무</a></li>
            <li><a href="#privacy7">7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</a></li>
            <li><a href="#privacy8">8. 고지의 의무</a></li>
            <li><a href="#privacy9">9. 개인정보관리책임자 및 담당자의 연락처</a></li>
          </ul>
        </div>
      </div>
      <div class="body text_body">
        <div class="paragraph">
          <h3 id="privacy1">1. 셀러스튜디오 개인정보 처리방침이란</h3>
          <p>
            (주)마플코퍼레이션(이하”회사”)은 이용자의 ‘동의를 기반으로 개인정보를 수집·이용 및 제공’하고
            있으며, ‘이용자의 권리 (개인정보 자기결정권)를 적극적으로 보장’합니다. 회사는
            정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을
            준수하고 있습니다. “개인정보처리방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가
            안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다. 본 개인정보 처리방침은
            개인 또는 사업자의 서비스를 위해 회사가 제공하는 서비스에 적용됩니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="privacy2">2. 수집하는 개인정보의 항목 및 수집 방법</h3>
          <p>
            A. 회사는 비지니스 기능별 필요 최소한의 개인정보 및 서비스이용 내역 등의 개인정보를 다음과 같이
            수집하고 있습니다.
          </p>
          <p>
            <strong>&lt;개인정보 항목&gt;</strong>
          </p>
          <div class="privacy2_list">
            <div class="title">- 판매자 회원계정</div>
            <ul>
              <li>
                [샵 개설 시 개인정보] 마플샵계정(이메일 또는 전화번호), 이용자 이름(실명), 생년월일, 성별,
                이메일, 전화번호, ci(연계정보),di(중복가입정보)
              </li>
              <li>[사업자로 샵 개설 시] 사업자등록증</li>
              <li>
                [법정대리인 동의 시] 법정대리인 정보(이름, 성별, 생년월일, 휴대폰번호, 통신사업자, 내·외국인
                여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI)
              </li>
            </ul>
          </div>
          <p>
            B. 정산대금 지급, 계좌인증 및 원천징수 처리를 위해 「소득세법」제 145조, 제164조에 따라 아래와
            같은 개인정보를 수집하고 있습니다.
          </p>
          <div class="privacy2_list">
            <div class="title">- 정산대금 및 계좌인증</div>
            <ul>
              <li>[필수] 예금주명, 계좌번호, 은행명</li>
              <li>[선택] 계좌사본, 신분증 사본(마스킹)</li>
            </ul>
          </div>
          <div class="privacy2_list">
            <div class="title">
              - 원천징수처리, 지급명세서 제출, 개인사업자 사업소득 신고, 휴·폐업자 소득신고 및 처리결과 회신
            </div>
            <ul>
              <li>[필수] 이름, 주민등록번호, 주소, 이메일</li>
            </ul>
          </div>
          <p>C. 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.</p>
          <div class="privacy2_list">
            <div class="title">- 신용카드결제 시: 카드사명, 카드번호(일부) 등</div>
            <div class="title">- 휴대전화결제 시: 휴대전화번호, 결제승인번호 등</div>
            <div class="title">- 계좌이체 시: 예금주명, 계좌번호, 은행명</div>
          </div>
          <p>D. 서비스 이용과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.</p>
          <p>
            PC웹, 모바일 웹/앱 이용 과정에서 단말기정보(OS, 화면사이즈, 디바이스 아이디, 폰기종, 단말기
            모델명), IP주소, 쿠키, 방문일시, 부정이용기록, 서비스 이용 기록 등의 정보가 자동으로 생성되어
            수집될 수 있습니다.
          </p>
          <p>E. 개인정보 수집방법</p>
          <div class="privacy2_list">
            <div class="title">회사는 아래와 같은 방법으로 개인정보를 수집합니다.</div>
            <ul>
              <li>
                회원가입 및 서비스 이용과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는
                경우
              </li>
              <li>제휴 서비스 또는 단체 등으로부터 개인정보를 제공받는 경우</li>
              <li>고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등</li>
              <li>온·오프라인에서 진행되는 이벤트/행사 등 참여</li>
            </ul>
          </div>
        </div>
        <div class="paragraph">
          <h3 id="privacy3">3. 개인정보의 수집 및 이용목적</h3>
          <p>회사는 다음과 같은 목적을 위하여 필요한 최소한의 개인정보를 수집하고 있습니다.</p>
          <div class="privacy2_list">
            <ol>
              <li>1. 회원 식별/가입의사 확인, 본인/연령 확인, 부정이용 방지</li>
              <li>
                2. 14세 미만 아동 개인정보 수집 시 법정대리인 동의여부 확인, 법정대리인 권리행사 시 본인 확인
              </li>
              <li>3. 신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항 전달</li>
              <li>4. 유료서비스 이용 시 컨텐츠 등의 전송이나 배송·요금 정산</li>
              <li>
                5. 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및
                제재
              </li>
              <li>
                6. 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 프라이버시 보호 측면의 서비스 환경
                구축, 서비스 개선에 활용
              </li>
            </ol>
          </div>
        </div>
        <div class="paragraph">
          <h3 id="privacy4">4. 개인정보 공유 및 제공</h3>
          <p>
            회사는 원활한 서비스를 제공하기 위해 제휴를 맺은 회사들에게 서비스를 위탁하고 있으며, 판매자회원의
            정보는 안전하고 원활한 결제 및 배송 서비스를 위해 제공될 수 있습니다. 개인정보를 제공할 경우에는
            사전에 고객에게 개인정보를 제공받는 제3자, 제공하는 개인정보항목명, 개인정보 제공의 목적, 제공하는
            개인정보가 언제까지 어떻게 보호·관리되는지, 고객이 종전에 동의한 사항, 고객의 동의하지 않을 권리와
            의사표시 방법, 기타 고객의 동의를 얻기 위하여 필요한 사항에 대해 개별적으로 서면,
            전자우편(e-mail), 전화 등을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 고객이 동의하지 않는
            경우에는 제3자에게 정보를 제공하지 않습니다.
          </p>
          <table>
            <thead>
              <tr>
                <th>제공받는자</th>
                <th>개인정보 이용목적</th>
                <th>개인정보의 항목</th>
                <th>보유 및 이용기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(주)KG이니시스</td>
                <td>전담 PG사의 요청에 의함 이용자의 송금정보를 이용하여 적극적인 정산을 하기위함</td>
                <td>이름, 전화번호, 생년월일, 주소, 계좌번호, 카드정보</td>
                <td>수집,이용 동의일로부터 거래종료 후 5년까지</td>
              </tr>
            </tbody>
          </table>
          <div class="privacy2_list">
            <ul>
              <li>이용자들이 사전 제공에 동의한 경우</li>
              <li>법령의 규정에 의거하거나, 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
            </ul>
          </div>
          <p>
            개인정보를 제공할 경우에는 사전에 고객에게 개인정보를 제공받는 제3자, 제공하는 개인정보항목명,
            개인정보 제공의 목적, 제공하는 개인정보가 언제까지 어떻게 보호·관리되는지, 고객이 종전에 동의한
            사항, 고객의 동의하지 않을 권리와 의사표시 방법, 기타 고객의 동의를 얻기 위하여 필요한 사항에 대해
            개별적으로 서면, 전자우편(e-mail), 전화 등을 통해 고지하여 동의를 구하는 절차를 거치게 되며,
            고객이 동의하지 않는 경우에는 제3자에게 정보를 제공하지 않습니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="privacy5">5. 개인정보 파기</h3>
          <p>
            전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면
            등의 경우 분쇄하거나 소각하여 파기합니다.
          </p>
          <p>다만, 내부 방침에 따라 일정 기간 보관 후 파기하는 정보는 아래와 같습니다.</p>
          <div class="privacy2_list">
            <div class="title">- 회사 내부 방침에 의한 정보보유 사유</div>
            <table>
              <thead>
                <tr>
                  <th>보관 정보</th>
                  <th>보존 이유</th>
                  <th>보존 기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>부정 이용 기록</td>
                  <td>부정 이용 방지</td>
                  <td>1년</td>
                </tr>
                <tr>
                  <td>회원 탈퇴 기록</td>
                  <td>고객 문의 응대</td>
                  <td>탈퇴일로부터 30일</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            마플샵을 최종 이용 후 1년 동안 이용 기록이 없는 고객(장기미이용회원)의 개인정보는 별도로 분리하여
            안전하게 관리하게 되며, 대상자에게는 분리 보관 처리일을 기준으로 하여 최소 30일 이전에 이메일
            주소를 통해 안내를 합니다. 단,통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등의
            관계법령의 규정에 의하여 보존할 필요가 있는 경우 규정된 기간 동안 고객의 개인정보를 보관합니다.
          </p>
          <div class="privacy2_list">
            <table>
              <thead>
                <tr>
                  <th>보관 정보</th>
                  <th>보존 이유</th>
                  <th>보존 기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>계약 또는 쳥약철회 등에 관한 기록</td>
                  <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                  <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                  <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                  <td>3년</td>
                </tr>
                <tr>
                  <td>표시/광고에 관한 기록</td>
                  <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                  <td>6개월</td>
                </tr>
                <tr>
                  <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
                  <td>국세기본법, 법인세법</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>전자금융 거래에 관한 기록</td>
                  <td>전자금융거래법</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>웹사이트 방문에 관한 기록</td>
                  <td>통신비밀보호법</td>
                  <td>3개월</td>
                </tr>
              </tbody>
            </table>
            <ol>
              <li>
                1. 등록되어 있는 본인의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을
                하고자 할 경우에는 홈페이지내 마이페이지를 클릭하여 직접 열람 또는 정정하거나,
                개인정보관리책임자에게 서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를
                거쳐 지체없이 조치하겠습니다. 다만, 아이디(전자우편주소)는 변경이 불가능하며 부득이한 사유로
                변경하고자 할 경우에는 해당 아이디를 해지한 후 재가입하셔야 합니다. 재가입시에는 기존의
                아이디를 사용하실 수 없습니다.
              </li>
              <li>
                2. 회사는 이용자로부터 전항의 요구가 있는 경우 법정 기한 내에 그 요구에 응답하고 정정을
                완료하기 전까지 당해 개인정보를 이용하지 않습니다. 단, 이 경우 해당 기간 내에 응답할 수 없는
                정당한 사유가 있을 때에는 이용자에게 그 사유를 알리고 연기할 수 있으며, 그 사유가 소멸하면
                지체없이 응답합니다.
              </li>
              <li>
                3. 이용자는 동의하신 개인정보의 수집, 이용, 제공에 대해 언제든지 철회하실 수 있습니다.
                동의철회는 홈페이지내 마이페이지를 클릭하여 직접 삭제하거나 개인정보관리책임자에게
                서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를 거쳐 지체없이 개인정보의
                삭제 등 필요한 조치를 하겠습니다. 단, 온라인사이트의 경우 탈퇴시 통계자료 작성상 필요한
                구매이력을 제외하고 모두 삭제됩니다.
              </li>
              <li>4. 만 14세 미만 이용자의 법정대리인은 위 모든 권리를 행사할 수 있습니다.</li>
            </ol>
          </div>
        </div>
        <div class="paragraph">
          <h3 id="privacy6">6. 고객의 권리와 의무</h3>
          <ol>
            <li>
              1. 고객의 권리
              <ol>
                <li>
                  ① 이용자는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나 정정하실 수 있습니다.
                  개인정보 열람 및 정정을 하고자 할 경우에는 홈페이지내 마이페이지를 클릭하여 직접 열람 또는
                  정정하거나, 개인정보관리책임자에게 서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면
                  본인확인 절차를 거쳐 지체없이 조치하겠습니다.
                </li>
                <li>
                  ② 회사는 이용자로부터 전항의 요구가 있는 경우 법정 기한 내에 그 요구에 응답하고 정정을
                  완료하기 전까지 당해 개인정보를 이용하지 않습니다. 단, 이 경우 해당 기간 내에 응답할 수 없는
                  정당한 사유가 있을 때에는 이용자에게 그 사유를 알리고 연기할 수 있으며, 그 사유가 소멸하면
                  지체없이 응답합니다.
                </li>
                <li>
                  ③ 이용자는 동의하신 개인정보의 수집, 이용, 제공에 대해 언제든지 철회하실 수 있습니다.
                  동의철회는 홈페이지내 마이페이지를 클릭하여 직접 삭제하거나 개인정보관리책임자에게
                  서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를 거쳐 지체없이 개인정보의
                  삭제 등 필요한 조치를 하겠습니다. 단, 온라인사이트의 경우 탈퇴시 통계자료 작성상 필요한
                  구매이력을 제외하고 모두 삭제됩니다.
                </li>
                <li>④ 만 14세 미만 이용자의 법정대리인은 위 모든 권리를 행사할 수 있습니다.</li>
              </ol>
            </li>
            <li>
              2. 고객의 의무
              <p style="margin: 0;">
                고객은 자신의 개인정보를 보호할 의무가 있으며, 회사의 귀책사유가 없이 ID(이메일 주소),
                비밀번호, 접근매체 등의 양도·대여·분실이나 로그인 상태에서 이석 등 고객 본인의 부주의나
                관계법령에 의한 보안조치로 차단할 수 없는 방법이나 기술을 사용한 해킹 등 회사가 상당한
                주의에도 불구하고 통제할 수 없는 인터넷상의 문제 등으로 개인정보가 유출되어 발생한 문제에 대해
                회사는 책임을 지지 않습니다. 고객은 자신의 개인정보를 최신의 상태로 유지해야 하며, 고객의
                부정확한 정보 입력으로 발생하는 문제의 책임은 고객 자신에게 있습니다. 타인의 개인정보를 도용한
                회원가입 또는 ID등을 도용하여 결제 처리 시 고객 자격 상실과 함께 관계법령에 의거하여 처벌될 수
                있습니다. 고객은 아이디, 비밀번호 등에 대해 보안을 유지할 책임이 있으며 제3자에게 이를
                양도하거나 대여할 수 없습니다. 고객은 회사의 개인정보보호정책에 따라 보안을 위한 주기적인
                활동에 협조할 의무가 있습니다.
              </p>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="privacy7">7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h3>
          <ol>
            <li>
              1. 쿠키
              <ol>
                <li>
                  ① 회사는 이용자에 대한 정보를 저장하고 검색할 수 있는 '쿠키(cookie)'를 사용합니다. 쿠키는
                  웹사이트가 이용자의 컴퓨터 브라우저로 전송하는 소량의 정보입니다. 이용자께서 웹사이트에
                  접속을 하면 회사의 컴퓨터는 이용자의 브라우저에 있는 쿠키의 내용을 읽고, 이용자의 추가정보를
                  이용자의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
                  쿠키는 이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다.
                </li>
                <li>
                  ② 회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 제공하고 수시로
                  불러오는 쿠키(cookie)를 통하여 수집된 이용자의 개인정보를 사용합니다. 방문한 서비스 정보,
                  장바구니 이력, 서비스 접속 시간 및 빈도, 서비스 이용 과정에서 생성된 또는 제공(입력)한
                  정보등을 분석하여 고객의 취향과 관심에 특화된 서비스(광고 포함)를 제공할 수 있습니다.
                </li>
                <li>
                  ③ 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저 옵션에서
                  모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을
                  거부할 수 있습니다.
                  <ol>
                    <li>
                      1. Internet Explorer의 경우
                      <ul>
                        <li>
                          - [도구]메뉴에서 [인터넷 옵션]을 선택 → [개인정보]를 클릭 → [고급]을 클릭 → 쿠키
                          허용여부를 선택
                        </li>
                      </ul>
                    </li>
                    <li>
                      2. Safari의 경우
                      <ul>
                        <li>
                          - MacOS 상단 좌측 메뉴바에서 [Safari]에서 [환경설정]선택 → [환경설정]창에서
                          [보안]으로 이동하여 쿠키 허용여부 선택
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              2. 맞춤형 광고
              <p style="margin: 0;">
                고객에게 맞춤형 광고를 제공하기 위하여 마플샵은 웹 브라우저에서는 ‘쿠키', 모바일 앱에서는
                광고식별자(ADID)를 수집하여 사용합니다. 마플샵은 쿠키 및 광고식별자를 통해 고객의 서비스 사용
                이력을 자동으로 수집하여 페이스북, 구글에 제공합니다. 페이스북 및 구글은 이를 활용하여 고객
                맞춤 광고를 진행합니다. 마플샵에서 수집하는 쿠키 및 광고식별자는 다른 개인정보와 연계되지
                않으며 개인을 식별하지 않습니다. 또한, 페이스북 및 구글은 마플샵에서 제공하는 정보를 활용하여
                개인을 식별하지 않습니다.
              </p>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="privacy8">8. 고지의 의무</h3>
          <p>
            개인정보 처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의
            ‘공지사항’을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자
            권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
          </p>
          <div class="privacy2_list">
            <ul>
              <li>공고일자: 2021년 7월 21일</li>
              <li>시행일자: 2021년 7월 28일</li>
            </ul>
          </div>
        </div>
        <div class="paragraph">
          <h3 id="privacy9">9. 개인정보관리책임자 및 담당자의 연락처</h3>
          <ol>
            <li>
              ① 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만사항 및 문의를 처리하기 위하여
              아래와 같이 개인정보 관리 책임자를 지정하고 있습니다.<br />
              <b>[개인정보 관리 책임자]</b>
              <ul>
                <li>1. 성명 : 유인동 팀장</li>
                <li>2. 전화번호 : ${MShopUtilS.getCustomerServiceInfo().tel} / 02-6486-7700</li>
                <li>3. 전자우편주소(e-mail) : dev@marpple.com</li>
              </ul>
            </li>
            <li>
              ② 정보주체는 개인정보 보호법 제 35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수
              있습니다.<br />
              마플샵은 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br />
              <b>[개인정보 열람청구 접수 및 처리 부서]</b>
              <ul>
                <li>1. 성명 : 유인동 팀장</li>
                <li>2. 전화번호 : ${MShopUtilS.getCustomerServiceInfo().tel} / 02-6486-7700</li>
                <li>3. 전자우편주소(e-mail) : dev@marpple.com</li>
              </ul>
            </li>
            <li>
              ③ 기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.
              <ul class="no_padding">
                <li>
                  - 개인정보침해신고센터 (<a href="https://privacy.kisa.or.kr/" target="_blank"
                    >privacy.kisa.or.kr</a
                  >
                  / 국번없이 118)
                </li>
                <li>
                  - 정보보호마크인증위원회 (<a href="http://www.eprivacy.or.kr" target="_blank"
                    >www.eprivacy.or.kr</a
                  >
                  / 02-580-0534)
                </li>
                <li>
                  - 대검찰청 첨단범죄수사과 (<a href="http://www.spo.go.kr" target="_blank">www.spo.go.kr</a>
                  / 02-3480-2000)
                </li>
                <li>
                  - 경찰청 사이버안전국 (<a href="https://cyberbureau.police.go.kr" target="_blank"
                    >cyberbureau.police.go.kr</a
                  >
                  / 국번없이 182)
                </li>
              </ul>
            </li>
          </ol>
        </div>

        <div class="paragraph">
          <h3>&lt;부칙&gt;</h3>
          <p>
            본 약관은 2023년 1월 25일부터 시행됩니다.<br />
            2020년 7월 28일부터 시행해 온 약관은 본 약관으로 대체됩니다.
          </p>
          <ul class="prev_link">
            <li>
              ${store_name
                ? html`<a href="/${T.lang}/${UtilS.escape(store_name)}/settings/privacy/20210728"
                    >- 이전 개인정보보호 취급방침 보기</a
                  >`
                : html`<a href="/${T.lang}/@/studio/privacy/20210728">- 이전 셀러스튜디오 이용약관 보기</a>`}
            </li>
          </ul>
        </div>
      </div>
      <div class="option">
        <button class="done">동의</button>
      </div>
    </div>
  `;
};
