import KeyringHook from '@marpple/keyring_hook/src/index.js';
// import acrylStandFactory from '@marpple/acryl-stand';

import { difference, every, filter, find, go, map, omit, extend, mapObject, delay } from 'fxjs/es';
import { parse as svgParser, stringify as svgBuilder } from 'svgson';
import { UtilArrayS } from '../../../Util/Array/S/Function/module/UtilArrayS.js';
import { VectorEditorConstantS } from '../../S/Constant/module/VectorEditorConstantS.js';
import { UtilStringS } from '../../../Util/String/S/Function/module/UtilStringS.js';
import { UtilObjS } from '../../../Util/Object/S/Function/module/UtilObjS.js';
import { VectorEditorF } from './module/VectorEditorF.js';
import { changeDpiDataUrl } from 'changedpi';
import { UtilNumberS } from '../../../Util/Number/S/Function/module/UtilNumberS.js';
import { DfImageEditorF } from '../../../Df/ImageEditor/F/Function/module/DfImageEditorF.js';

const INNER_RING_TRUE_RADIUS = 1.5;
const OUTER_RING_TRUE_RADIUS = 4.5;
const MM_TO_DPI72_PX = 72 / 25.4;

export const svg = {
  parser: {
    fromFile: async (svg_file) => {
      if (svg_file == null) {
        VectorEditorF.throwProEditorErr.dev({
          msg: `File not exist`,
        });
      }

      try {
        return go(await fileToText(svg_file), svgParser, processSvgData);
      } catch (err) {
        console.error(err);
        VectorEditorF.throwProEditorErr.user({ title: 'Svg parsing error', msg: `Error with parsing svg` });
      }
    },
    getPathBboxFromSvg: (svg_el) => {
      document.body.appendChild(svg_el);
      const path_el = svg_el.querySelector('path');
      const bbox = path_el.getBBox();
      if (bbox.width === 0 || bbox.height === 0)
        VectorEditorF.throwProEditorErr.dev({ msg: `Empty bounding box` });
      document.body.removeChild(svg_el);
      return bbox;
    },
  },
  validator: {
    svgFile: ({ file, max_mb_size }) => {
      if (file == null) VectorEditorF.throwProEditorErr.dev({ msg: `Svg file not exist` });

      if (!UtilNumberS.isNumber(max_mb_size))
        VectorEditorF.throwProEditorErr.dev({ msg: `max_mb_size is not a number (${max_mb_size})` });

      const is_svg = file.type === 'image/svg+xml';
      if (!is_svg)
        VectorEditorF.throwProEditorErr.user({ title: 'File extension error', msg: `File must be svg` });

      const file_mb_size = file.size / 1024 / 1024;

      const is_allowed_file_size = file_mb_size <= max_mb_size;

      if (!is_allowed_file_size)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::file_size'),
          msg: `${max_mb_size} MB${TT('pro_editor::alert::text::file_size')}`,
        });
    },
    importedSvg: ({ svg_parsed, maker_type }) => {
      svg_parsed = UtilObjS.deepClone(svg_parsed);
      const root_attribute = svg_parsed.attributes;

      // 1. svg root 프라퍼티 검사 (width, height)
      if (root_attribute?.width == null || root_attribute?.height == null)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::file_save'),
          msg: TT('pro_editor::alert::text::file_save'),
        });

      // 2. svg root 프라퍼티 검사 (viewBox)
      if (root_attribute?.viewBox == null)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::template_use'),
          msg: TT('pro_editor::alert::text::template_use'),
        });

      const layers = svg_parsed.children;

      // 3. 레이어 검사
      if (UtilArrayS.isEmNil(layers)) {
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::layer'),
          msg: TT('pro_editor::alert::text::layer'),
        });
      }
      checkAllowedLayerIds({ layers, maker_type });
      checkKeyItem({ layers, maker_type });
      checkCuttingLine({ layers });
    },
  },
  uploader: {
    canvas: async ({ canvas, dpi, is_only_original }) => {
      const formData = svg.converter.canvasToFormData({ canvas, dpi });
      return await $.upload(formData, {
        url: is_only_original ? '/@fileUpload/file_only_original' : '/@fileUpload/file',
      });
    },
    svg: async ({ svg_el, dpi }) => {
      const file = new Blob([new XMLSerializer().serializeToString(svg_el)], { type: 'image/svg+xml' });
      const formData = new FormData();
      formData.append('file', file, 'file.svg');
      return await $.upload(formData, {
        url: '/@api/svg_file',
        data: { dpi },
      });
    },
  },
  converter: {
    svgToCanvas: async ({ svg_el, width, delay_time = 100 }) => {
      if (width == null) VectorEditorF.throwProEditorErr.dev({ msg: `Width is required` });
      if (!UtilNumberS.isNumber(width)) VectorEditorF.throwProEditorErr.dev({ msg: `Width must be number` });

      const width_asis = svg_el.getAttribute('width');
      if (width_asis == null)
        VectorEditorF.throwProEditorErr.dev({ msg: `Width must be defined in svg element` });

      const scale = width / width_asis;
      const scaled_svg_el = VectorEditorF.svg.converter.svgToScale({ svg_el, scale });

      return go(
        await VectorEditorF.svg.converter.svgToImg(scaled_svg_el),
        delay(delay_time),
        DfImageEditorF.imageToCanvas,
      );
    },
    canvasToFormData: ({ canvas, dpi }) => {
      /* DPI setting */
      const dataURI = changeDpiDataUrl(canvas.toDataURL(), dpi);

      /* MIME 추출 */
      const splitDataURI = dataURI.split(',');
      const mimeType = splitDataURI[0].split(':')[1].split(';')[0];

      /* 이미지 데이터 추출 */
      const data = splitDataURI[1];

      /* 바이트 배열 추출 */
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      /* 파일 객체 생성 */
      const file = new Blob([byteArray], { type: mimeType });

      /* 폼 데이터 생성 */
      const formData = new FormData();
      formData.append('file', file, `image.png`);

      return formData;
    },
    svgToBboxLayout: ({ svg_el, bbox }) => {
      const cloned_svg = svg_el.cloneNode(true);

      cloned_svg.setAttribute('width', bbox.width);
      cloned_svg.setAttribute('height', bbox.height);

      const viewBox_value = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
      cloned_svg.setAttribute('viewBox', viewBox_value);

      return cloned_svg;
    },
    svgToScale: ({ svg_el, scale }) => {
      const cloned_svg = svg_el.cloneNode(true);
      cloned_svg.setAttribute('width', cloned_svg.getAttribute('width') * scale);
      cloned_svg.setAttribute('height', cloned_svg.getAttribute('height') * scale);

      return cloned_svg;
    },
    svgToImg: async (svg_el) => {
      const svgXml = new XMLSerializer().serializeToString(svg_el);
      const blob = new Blob([svgXml], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);

      const image = new Image();
      image.src = url;

      return new Promise((res, rej) => {
        image.onload = () => {
          res(image);
        };
        image.onerror = (e) => {
          rej(e);
        };
      });
    },
    coordToCoordRatioRefToBbox: ({ coord, bbox }) => {
      if (bbox == null) VectorEditorF.throwProEditorErr.dev({ msg: `Not exist bbox` });
      const { x, y, width, height } = bbox;

      if (width === 0 || height === 0)
        VectorEditorF.throwProEditorErr.dev({ msg: `Width or height cannot be zero` });

      if (!UtilStringS.isNumericString(x) || !UtilStringS.isNumericString(y))
        VectorEditorF.throwProEditorErr.dev({ msg: `Coord x or coord y must be a number` });
      return { x: (coord.x - x) / width, y: (coord.y - y) / height };
    },
  },
  builder: {
    getMergedCutSvg: async ({ svg_parsed, maker_type, options }) => {
      svg_parsed = UtilObjS.deepClone(svg_parsed);
      const layers = svg_parsed.children;

      const { width, height } = getArtBoardPxSize({ attributes: svg_parsed.attributes });

      const cutting_item = getCuttingItemsFromLayers({ layers })?.[0];
      if (cutting_item == null)
        VectorEditorF.throwProEditorErr.user({
          title: 'Cut layer error',
          msg: `Cannot find cutting path item`,
        });

      const path_data = cutting_item.attributes?.d;
      if (path_data == null)
        VectorEditorF.throwProEditorErr.user({
          title: 'Cut layer error',
          msg: `Cannot find path data from cutting line item`,
        });

      let merged_cut_pathdata;
      let key_item_coord;

      switch (maker_type) {
        case VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR: {
          // 그라운드 아이템 파싱 (유저 제공) (72 DPI px unit)
          const stand_ground_items = getStandItemsFromLayers({ layers });
          const ground_user_input = stand_ground_items[0].attributes;

          const cut_bbox = svg.parser.getPathBboxFromSvg(
            svg.builder.fromParsed({ svg_parsed, pick_ids: ['cut'] }),
          );

          // (유저 제공) cut path 폭보다
          // - ground 폭이 큰 경우 최대 cut path 폭으로 강제 변경
          // - ground 폭이 notch 보다 작은 경우 최소값으로 강제 변경
          const ground_user_input_width = Math.max(
            Math.min(Number(ground_user_input.width), cut_bbox.width),
            options.stand_leg.ground.min_width * MM_TO_DPI72_PX,
          );

          // (유저 제공) ground 중심점은 유저가 잡은 중심점을 최대한 그대로 사용
          const ground_user_input_x_center =
            Number(ground_user_input.x) + Number(ground_user_input.width) / 2;

          const acrylStandFactory = (await import('@marpple/acryl-stand')).default;
          const result = acrylStandFactory({
            svg_artboard_rect: { x: 0, y: 0, width, height },
            pathdata: path_data,
            size: {
              // 그라운드 가로폭 -> 유저의 뜻을 따름
              // 그라운드 높이 -> 고정 값
              ground: {
                width: ground_user_input_width,
                height: options.stand_leg.ground.height * MM_TO_DPI72_PX,
              },
              // 노치 레이아웃 -> 고정 값
              notch: mapObject((v) => v * MM_TO_DPI72_PX, options.stand_leg.notch),
            },
            initial_position: `CENTER`,
          });

          if (!result.ok) {
            const code = result.value.code;
            const message = result.value.message;
            VectorEditorF.throwProEditorErr.dev({ msg: `${message} (cod: ${code})` });
          }
          const acryl_stand = result.value;

          const united_pathdata = acryl_stand.getUnitedPathdata({ x: ground_user_input_x_center });
          if (!united_pathdata.ok) {
            const code = united_pathdata.value.code;
            const message = united_pathdata.value.message;
            VectorEditorF.throwProEditorErr.dev({ msg: `${message} (cod: ${code})` });
          }

          merged_cut_pathdata = united_pathdata.value;

          const x = acryl_stand.getStandCenterX({ x: ground_user_input_x_center });
          const y = acryl_stand.getGroundBottomY();
          key_item_coord = { x, y };

          break;
        }
        case VectorEditorConstantS.KEYRING_EDITOR: {
          const hook = new KeyringHook({
            art_board_size: { width, height },
            path_data,
            radius: {
              inner: INNER_RING_TRUE_RADIUS * MM_TO_DPI72_PX,
              outer: OUTER_RING_TRUE_RADIUS * MM_TO_DPI72_PX,
            },
          });

          const ring_items = getRingItemsFromLayers({ layers });

          const { cx, cy } = ring_items[0].attributes;
          const x = Number(cx);
          const y = Number(cy);

          merged_cut_pathdata = hook.getPathDataHookUnited({ x, y });

          const modified_hook_center = hook.last_center_position;

          const hook_center_x = modified_hook_center?.x;
          const hook_center_y = modified_hook_center?.y;

          if (hook_center_x == null || hook_center_y == null)
            VectorEditorF.throwProEditorErr.dev({ msg: `Cannot define the ring center coordination` });

          key_item_coord = { x: hook_center_x, y: hook_center_y };
          break;
        }
        default:
          throw VectorEditorF.throwProEditorErr.dev({ msg: `Unsupported maker type(${maker_type})` });
      }

      const merged_cut_svg_original_layout = svg.builder.toCutPathSvg({
        svg_parsed,
        cut_patadata: merged_cut_pathdata,
        to_els: true,
      });

      const cut_bbox = svg.parser.getPathBboxFromSvg(merged_cut_svg_original_layout);
      const merged_cut_svg_trim_layout = svg.converter.svgToBboxLayout({
        svg_el: merged_cut_svg_original_layout,
        bbox: cut_bbox,
      });

      const key_item_coord_ratio = svg.converter.coordToCoordRatioRefToBbox({
        coord: key_item_coord,
        bbox: cut_bbox,
      });

      return {
        svg_el: merged_cut_svg_original_layout,
        svg_el_trimmed: merged_cut_svg_trim_layout,
        bbox: cut_bbox,
        key_item_coord_ratio,
      };
    },
    toCutPathSvg: ({ svg_parsed, cut_patadata, to_els = true }) => {
      svg_parsed = UtilObjS.deepClone(svg_parsed);

      const item = {
        name: 'path',
        type: 'element',
        attributes: {
          d: cut_patadata,
          style: 'fill: none; stroke: #FF00C0; stroke-miterlimit: 10; stroke-width: .5px;',
        },
      };
      const ast = extend(svg_parsed, { children: [item] });
      const svg_str = svgBuilder(ast);

      return to_els ? svg.builder.stringToEl({ svg_str }) : svg_str;
    },
    fromParsed: ({ svg_parsed, remove_ids, pick_ids, bbox, to_els = true }) => {
      svg_parsed = UtilObjS.deepClone(svg_parsed);

      let svg_ast_tobe_build;

      if (bbox != null) {
        const width = bbox.width;
        const height = bbox.height;
        const viewBox = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
        extend(svg_parsed.attributes, { width, height, viewBox });
      }

      if (pick_ids == null && remove_ids == null) {
        svg_ast_tobe_build = svg_parsed;
      } else {
        const selected_children_layers = filter((item) => {
          if (pick_ids != null) {
            return pick_ids.includes(item.attributes?.id);
          }
          if (remove_ids != null) {
            return !remove_ids.includes(item.attributes?.id);
          }
        }, svg_parsed.children);
        svg_ast_tobe_build = { ...omit('children', svg_parsed), children: selected_children_layers };
      }

      try {
        const svg_str = svgBuilder(svg_ast_tobe_build);
        return to_els ? svg.builder.stringToEl({ svg_str }) : svg_str;
      } catch (err) {
        console.error(err);
        VectorEditorF.throwProEditorErr.dev({ msg: `Cannot build svg from parsed_structure` });
      }
    },
    stringToEl: ({ svg_str }) => {
      const parser = new DOMParser();
      const svg_doc = parser.parseFromString(svg_str, 'application/xml');
      return svg_doc.documentElement;
    },
  },
};

// @description file 객체 내용을 text 로 decoding
async function fileToText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.onerror = function (event) {
      reject(new Error('Error reading file: ' + event.target.error));
    };

    reader.readAsText(file);
  });
}

function processSvgData(parsed_svg) {
  return parsed_svg;
}

function getArtBoardPxSize({ attributes, dpi = 72 }) {
  if (attributes?.width == null || attributes?.height == null)
    VectorEditorF.throwProEditorErr.dev({ msg: `Svg root attribute does not have width or height` });

  const { width: svg_width, height: svg_height } = attributes;

  const cmToPx = (cm) => (cm * dpi) / 2.54;
  const mmToPx = (mm) => (mm * dpi) / 25.4;
  const inchToPx = (inch) => inch * dpi;

  const [width, height] = go(
    [svg_width, svg_height],
    map((measure) => {
      if (measure.includes('cm')) {
        return cmToPx(parseFloat(measure));
      } else if (measure.includes('mm')) {
        return mmToPx(parseFloat(measure));
      } else if (measure.includes('in')) {
        return inchToPx(parseFloat(measure));
      } else {
        return Math.round(measure);
      }
    }),
  );

  return { width, height };
}

// @description svg 레이어에 필수로 존재해야하는 레이어 이름 정합성 체크
function checkAllowedLayerIds({ layers, maker_type }) {
  const input_layer_ids = layers.map((l) => l.attributes.id);
  const BYPASS_LAYER_IDS = VectorEditorConstantS.PRO_EDITOR_BYPASS_LAYER_IDS;
  const allowed_layer_ids = VectorEditorConstantS.PRO_EDITOR_LAYER_IDS[maker_type];

  const diff_remaining_allowed_layer_ids = UtilArrayS.removeValueFromArrayInPlace(
    difference(input_layer_ids, allowed_layer_ids),
    BYPASS_LAYER_IDS,
  );

  const diff_input_layer_ids = UtilArrayS.removeValueFromArrayInPlace(
    difference(allowed_layer_ids, input_layer_ids),
    BYPASS_LAYER_IDS,
  );

  if (diff_remaining_allowed_layer_ids.length || diff_input_layer_ids.length) {
    VectorEditorF.throwProEditorErr.user({
      title: TT('pro_editor::alert::title::layer'),
      msg: TT('pro_editor::alert::text::layer'),
    });
  }
}

function checkKeyItem({ layers, maker_type }) {
  switch (maker_type) {
    case VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR: {
      /* 스탠드 조건
       *  - children 1 개
       *  - rectangle
       *
       * */
      const stand_items = getStandItemsFromLayers({ layers });

      // 스탠드 아이템 1개
      const has_one_children = stand_items.length === 1;
      if (!has_one_children)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::layer_stand'),
          msg: TT('pro_editor::alert::text::layer_stand'),
        });

      // 스탠드 아이템은 rect 만 허용
      const is_item_rectangle = stand_items[0].name === 'rect';
      if (!is_item_rectangle)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::layer_stand'),
          msg: TT('pro_editor::alert::text::layer_stand'),
        });
      break;
    }
    case VectorEditorConstantS.KEYRING_EDITOR: {
      const ring_items = getRingItemsFromLayers({ layers });

      // 링 아이템 2개
      const has_two_children = ring_items?.length === 2;
      if (!has_two_children)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::layer_ring'),
          msg: TT('pro_editor::alert::text::layer_ring'),
        });

      // 링 아이템은 모두 circle
      const is_children_all_circle = every((c) => c.name === 'circle', ring_items);
      if (!is_children_all_circle)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::layer_ring'),
          msg: TT('pro_editor::alert::text::layer_ring'),
        });

      // 링 아이템 아이디는 inner | outer
      const is_children_are_inner_and_outer = UtilArrayS.arraysAreEqual(
        map(
          (c) => (UtilStringS.isString(c.attributes.id) ? c.attributes.id.toLowerCase() : c.attributes.id),
          ring_items,
        ),
        ['inner', 'outer'],
      );
      if (!is_children_are_inner_and_outer)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::layer_ring'),
          msg: TT('pro_editor::alert::text::layer_ring'),
        });

      // inner ring radius: 1.5
      const is_inner_ring_1_5_mm = checkRadius({
        items: ring_items,
        layer_id: 'inner',
        true_radius: INNER_RING_TRUE_RADIUS,
      });
      if (!is_inner_ring_1_5_mm)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::layer_ring'),
          msg: TT('pro_editor::alert::text::layer_ring'),
        });

      // outer ring radius: 1.5
      const is_outer_ring_4_5_mm = checkRadius({
        items: ring_items,
        layer_id: 'outer',
        true_radius: OUTER_RING_TRUE_RADIUS,
      });
      if (!is_outer_ring_4_5_mm)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::layer_ring'),
          msg: TT('pro_editor::alert::text::layer_ring'),
        });

      break;
    }
  }
}

function explodeGroupItem(item) {
  const exploded_items = [];
  // 현재 item "g"가 아니고, children 배열의 길이가 0일 경우에만 배열에 추가
  if (item.name !== 'g' && (!item.children || item.children.length === 0)) {
    exploded_items.push(item);
  }
  if (item.children && item.children.length > 0) {
    for (const child_item of item.children) {
      exploded_items.push(...explodeGroupItem(child_item));
    }
  }
  return exploded_items;
}

function checkCuttingLine({ layers }) {
  const cutting_items = getCuttingItemsFromLayers({ layers });

  // 단일 패스 검사
  const is_single_path_item = cutting_items.length === 1;
  if (!is_single_path_item)
    VectorEditorF.throwProEditorErr.user({
      title: TT('pro_editor::alert::title::layer_cut_not_single'),
      msg: TT('pro_editor::alert::text::layer_cut_not_single'),
    });

  // "path" 타입만 허용 (ellipse, rectangle, circle, arc 지원 안함)
  const path_item = cutting_items[0];
  const is_path_not_polygon = path_item.name === 'path';
  if (!is_path_not_polygon)
    VectorEditorF.throwProEditorErr.user({
      title: TT('pro_editor::alert::title::layer_cut_not_path_type'),
      msg: TT('pro_editor::alert::text::layer_cut_not_path_type'),
    });

  // 닫힌 패스 검사
  const is_closed_path = isClosedPath({ path_item });
  if (!is_closed_path)
    VectorEditorF.throwProEditorErr.user({
      title: TT('pro_editor::alert::title::layer_cut_not_closed'),
      msg: TT('pro_editor::alert::text::layer_cut_not_closed'),
    });
}

function getItemsFromLayers({ layers, id }) {
  const layer = findItemFromLayers({ layers, id });

  if (layer == null)
    VectorEditorF.throwProEditorErr.user({
      title: TT('pro_editor::alert::title::layer'),
      msg: TT('pro_editor::alert::text::layer'),
    });

  return explodeGroupItem(layer);
}

function getRingItemsFromLayers({ layers }) {
  return getItemsFromLayers({
    layers,
    id: VectorEditorConstantS.PRO_EDITOR_KEY_ITEM[VectorEditorConstantS.KEYRING_EDITOR],
  });
}

function getStandItemsFromLayers({ layers }) {
  return getItemsFromLayers({
    layers,
    id: VectorEditorConstantS.PRO_EDITOR_KEY_ITEM[VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR],
  });
}

function getCuttingItemsFromLayers({ layers }) {
  const CUT_LAYER_ID = 'cut';

  const cut_layer = findItemFromLayers({ layers, id: CUT_LAYER_ID });
  if (cut_layer == null)
    VectorEditorF.throwProEditorErr.user({
      title: 'Layer error',
      msg: `Layer(${CUT_LAYER_ID}) is missing`,
    });

  return explodeGroupItem(cut_layer);
}

function findItemFromLayers({ layers, id }) {
  const item = find((l) => l.attributes?.id === id, layers);
  if (item == null)
    VectorEditorF.throwProEditorErr.user({
      title: 'Layer error',
      msg: `Cannot find item in layer(${id})`,
    });
  return item;
}

function isClosedPath({ path_item }) {
  const pathdata = path_item.attributes?.d;

  if (pathdata == null)
    VectorEditorF.throwProEditorErr.user({
      title: 'Cut layer error',
      msg: `Cannot find path data from cutting line item`,
    });

  return pathdata.endsWith('Z') || pathdata.endsWith('z');
}

function checkRadius({ items, layer_id, true_radius }) {
  const item = find((item) => item.attributes.id === layer_id, items);
  if (item == null)
    VectorEditorF.throwProEditorErr.dev({
      msg: `Not exist ${item} in layer(${layer_id})`,
    });

  const radius = item.attributes?.r;
  if (!UtilStringS.isNumericString(radius))
    VectorEditorF.throwProEditorErr.user({
      title: 'Ring layer error',
      msg: `Radius(${radius}) is not number`,
    });

  const radius_mm = Number(radius) / MM_TO_DPI72_PX;
  const RESOLUTION = 0.01;
  return Math.abs(radius_mm - true_radius) < RESOLUTION;
}
