import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { html } from 'fxjs/es';
import { MShopUtilS } from '../../../MShop/Util/S/Function/module/MShopUtilS.js';

export const makeStudioTermsHtml = ({ store_name }) => legacyHtml`
  <div class="body terms_body">
    <div class="title">
      <h2>셀러스튜디오 이용약관</h2>
    </div>
    <div class="body text_body">
      <div class="paragraph">
        <h3 id="service1">제1조(목적)</h3>
        <p>
          본 약관은 주식회사 마플코퍼레이션(이하 ‘회사’)가 운영하는 ‘마플샵’(이하 ‘몰’)과 셀러스튜디오(이하
          ‘판매자센터’)에서 전자상거래 관련 서비스 및 기타 서비스(이하 ’서비스’라 함)를 이용하기 위하여
          ‘마플샵’에 ‘셀러’(이하 ‘판매자회원’) 자격으로 가입한 자와 회사 간의 권리, 의무 및 제반
          절차를 확정하고 이를 이행함으로써 상호 발전을 도모하는 것을 그 목적으로 합니다.
        </p>
        <p>※ PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 현 약관을 준용합니다.</p>
      </div>
      <div class="paragraph">
        <h3 id="service2">제2조(정의)</h3>
        <p>본 "약관"에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <ol>
          <li>
            ①. ‘판매자회원’이란 만 14세 이상의 개인(개인 사업자 포함) 또는 법인으로서 현 약관에 동의하고
            ‘회사’가 제공하는 ‘몰’, ‘판매자센터’, ‘서비스’를 사용하며, ‘회사’에서 제공하는 서비스를 통해
            자신의 ‘아이디어, 콘텐츠, 디자인’을 제작, 게재 및 홍보하고, 그 ‘아이디어, 콘텐츠, 디자인’을
            이용하여 ‘회사’가 ‘상품’을 제작 및 판매할 수 있도록 허락하는 이용자를 의미합니다.
          </li>
          <li>
            ②. 판매자회원의 ‘아이디어, 콘텐츠, 디자인’이라 함은 이미지, 영상, 그림, 음성, 음향, 음원, 폰트,
            문자, 문서, 부호, 성명, 예명, 초상 등으로 표현된 ‘판매자회원’의 저작물 또는 정보로서 그 이용에
            있어서 사용자의 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다. ‘판매자회원’은
            ‘아이디어, 콘텐츠, 디자인’ 및 이를 이용하여 ‘회사’가 제작할 ‘상품’을 게재하여 자신만의 ‘샵’을
            꾸미고, 자신의 ‘아이디어, 콘텐츠, 디자인’을 홍보할 수 있습니다.
          </li>
          <li>
            ③. ‘샵’이란 ‘판매자회원’이 자신의 ‘아이디어, 콘텐츠, 디자인’을 ‘구매자회원’에게 소개 및 홍보할 수
            있는 회사가 운영하는 ‘몰’ 내 존재하는 공간 또는 전시장을 의미합니다.
          </li>
          <li>
            ④. ‘판매자센터’ 란 ‘회사’가 ‘판매자회원’이 자신의 ‘상품’을 디자인하여 국내 및 국외에서
            ‘구매자회원’에게 홍보할 수 있도록 돕고 통계 확인, 정산 등의 다양한 ‘서비스’를 지원하는 플랫폼을
            의미합니다.
          </li>
          <li>
            ⑤. ‘상품’이란 ‘회사’가 ‘판매자회원’으로부터 ‘아이디어, 콘텐츠, 디자인’의 이용을 허락받아 제작한
            의류, 핸드폰케이스, 악세사리 등으로, ‘회사’는 ‘구매자회원’과 ‘상품’에 대한 매매계약을 직접
            체결함으로써 이를 ‘몰’을 통해 판매합니다.
          </li>
          <li>⑥. ‘구매자회원’이란 ‘몰’에서 ‘상품’을 구매하는 이용자를 의미합니다.</li>
          <li>
            ⑦. ‘서비스’라 함은 회사가 ‘몰’을 통해 ‘판매자회원’이 자신의 ‘아이디어, 컨텐츠, 디자인’ 및 ‘상품’을
            제작∙홍보할 수 있도록 지원하는 것과 주문 확인, 상품 제작, 상품 배송 등 ‘구매자회원’의 구매행위와
            관련된 과정들을 지원하는 것을 총칭합니다.
          </li>
          <li>
            ⑧. ‘판매 대금’이란 ‘구매자회원’이 ‘상품’을 구매한 대가로 지불하는 금액을 말합니다. ‘판매 대금’은
            ‘회사’가 책정한 ‘기본가격’에 ‘판매자회원’이 책정한 ‘디자인가격’을 더하여 산정됩니다.
          </li>
          <li>
            ⑨. ‘기본가격’이란 ‘상품’ 제작·제조 비용, 금융결제수수료, ‘몰’의 개발, 유지, 관리 등 상품 공급과
            관련된 제반 서비스 비용 등에 대한 금액을 총칭한 것을 의미합니다.
          </li>
          <li>
            ⑩. ‘디자인가격’이란 ‘판매 대금’ 중 ‘판매자회원’이 자신의 디자인에 대하여 책정한 금액을 의미합니다.
          </li>
          <li>
            ⑪. ‘정산 대금’이란 ‘판매 대금’ 중 ‘판매자회원’이 책정한 자신의 디자인가격으로부터, 카드 결제
            수수료 등 공제가 필요한 부분에 대한 금액을 차감한 후 ‘회사’로부터 지급 받아야하는 금액을
            의미합니다.
          </li>
          <li>
            ⑫. 신고센터제도’란 구매자회원 또는 제3자가 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인한
            법률상 이익 침해에 대한 불만을 제기하기 위한 몰 내 게시판을 의미합니다.
          </li>
          <li>
            ⑬. 이외에 이 약관에서 사용하는 용어의 정의는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service3">제 3 조 (약관의 게시 및 개정)</h3>
        <ol>
          <li>
            ①. 회사는 이 약관을 판매자회원이 쉽게 알 수 있도록 몰 초기화면 또는 연결화면을 통하여 게시합니다.
          </li>
          <li>
            ②. 회사는 필요한 경우 관련 법령을 위반하지 않는 범위 내에서 이 약관을 개정할 수 있으며, 이 경우
            개정내용과 적용일자를 명시하여 몰을 통해 그 적용일자 7일 전부터 적용일자 전일까지 공지합니다.
            다만, 변경 내용이 판매자회원에게 불리하거나 판매자회원의 권리 또는 의무에 관한 중요 사항의 변경은
            개정약관의 적용일자 30일 전부터 적용일자까지 공지하고, 전자우편 등을 통하여 판매자회원에게 개별
            통지합니다(판매자회원의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 때에는 몰 초기화면을
            통하여 공지함으로써 개별 통지한 것으로 간주합니다.)
          </li>
          <li>
            ③. 판매자회원이 개정약관에 동의하지 않는 경우에는 개정약관의 적용일 이전에 거부의사를 표시하고 이
            약관에 의한 이용계약을 해지할 수 있습니다.
          </li>
          <li>
            ④. 회사가 본 조 제 2항에 따라 개정약관을 공지 또는 통지하면서 판매자회원에게 적용일전까지
            의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
            판매자회원이 명시적으로 거부의사를 표명하지 아니한 경우 개정약관에 동의한 것으로 봅니다.
          </li>
          <li>
            ⑤. 본 약관에 동의하는 것은 정기적으로 회사가 운영하는 웹사이트를 방문하여 약관의 변경사항을
            확인하는 것에 동의함을 의미하며, 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에
            대하여 회사는 책임을 부담하지 않습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service4">제4조 (약관의 효력)</h3>
        <ol>
          <li>
            ①. 회사는 본 약관에 규정되지 않은 세부적인 내용에 대해 개별 운영 정책 등(이하 ‘운영정책‘이라 함)을
            제정하여 운영할 수 있으며, 해당 내용을 몰을 통하여 게시합니다. 운영정책은 이 약관과 더불어 서비스
            이용계약(이하 ‘이용계약‘이라 함)의 일부를 구성합니다.
          </li>
          <li>
            ②. 회사는 서비스 중 특정 서비스에 관한 약관(이하 ‘개별약관‘이라 함)을 별도로 제정할 수 있으며,
            판매자회원이 개별약관에 동의한 경우 개별약관은 이용계약의 일부를 구성하고 개별약관에 이 약관과
            상충하는 내용이 있을 경우 개별 약관이 우선적으로 적용됩니다.
          </li>
          <li>
            ③. 본 약관에 의해 판매자회원으로 가입하고자 하는 자는 구매회원 이용약관의 내용을 숙지하고
            구매회원과 회사간의 권리∙의무관계에 대해 동의함을 확인합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service5">제5조 (이용계약의 성립)</h3>
        <ol>
          <li>
            ①. 이용약관은 서비스를 이용하고자 하는 자(이하 ‘이용신청자‘라 함)가 본 약관에 동의하고 회사가 정한
            절차에 따라 판매자회원 가입을 신청하며, 이에 대해 회사가 심사를 거쳐 승낙함으로써 성립합니다.
            회사는 이용승낙의 의사표시를 해당 서비스 화면에 게시하거나 전자우편 또는 기타 방법으로 할 수
            있습니다.
          </li>
          <li>
            ②. 이용신청자는 회사가 별도로 요청하는 증빙서류가 있는 경우 신속히 제출하여야 하며, 회사는 해당
            서류를 징구하는 시점까지 가입신청에 대한 승낙을 보류하거나 거부할 수 있습니다.
          </li>
          <li>
            ③. 판매자회원 가입은 만 14세 이상의 개인(개인 사업자 포함) 또는 법인사업자가 할 수 있으며, 만 19세
            미만의 판매자회원은 가입시 법정대리인의 동의서 및 회사가 요구하는 입증자료(가족관계증명서,
            법정대리인 인감증명서 등)를 제출하여야 합니다.
          </li>
          <li>
            ④. 회사는 본 조 제1항에 따라 판매자 회원가입을 신청한 자 중에서 아래 각 호에 해당되는 경우에는
            승낙을 거부할 수 있습니다. 이용신청자가 아래 각호의 행위를 하였을 경우, 회사는 이에 대한 어떠한
            책임도 부담하지 않으며 이용 신청자의 아래 각호 행위로 인해 회사에 손해가 발생하거나 제3자와 법적
            분쟁이 발생할 시 이용 신청자는 자신의 비용(변호사 비용, 소송 비용 등을 포함하나 이에 한정하지
            않음)과 책임으로 회사의 손해를 배상하여야 합니다. 다만, 회사의 고의 또는 중대한 과실로 인한 경우
            본 항이 적용되지 않습니다.
            <ol>
              <li>
                1. 가입을 신청한 자가 이 약관 및 회사의 다른 서비스와 관련하여 이전에 회원 자격을 상실한 적이
                있는 경우
              </li>
              <li>
                2. 제출 정보(본인확인절차 포함)의 내용에 허위, 기재 누락, 오기, 제3자 정보도용이 있는 경우
              </li>
              <li>
                3. 실명이 아니거나 타인의 정보 등(예시: 주민등록번호, 사업자번호, I-PIN 등)을 이용한 경우
              </li>
              <li>
                4. 회사로부터 자격 정지 조치 등을 받은 판매자회원이 그 조치기간 중에 이용계약을 임의 해지하고
                재이용신청을 하는 경우
              </li>
              <li>
                5. 이용신청자의 귀책사유로 인하여 승인이 불가능하거나 필수 서류 또는 회사가 요청하는 서류를
                제출하지 않는 등 기타 제반 사항을 위반하여 신청하는 경우
              </li>
              <li>
                6. 이용신청자와 서비스 정산 금액을 지급받는 자의 정보가 다를 경우(단, 제5조 제3항에 따라
                법정대리인이 정산을 받는 경우는 예외로 한다)
              </li>
              <li>
                7. 부정한 용도 또는 회사의 서비스 제공 목적에 반하여 영리를 추구할 목적으로 서비스를
                이용하고자 하는 경우
              </li>
              <li>
                8. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청 등 회사가 합리적인 판단에 의하여
                필요하다고 인정하는 경우
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service6">제6조 (판매자회원의 책임 및 관리 의무)</h3>
        <ol>
          <li>
            ①. 판매자회원은 본 약관에 동의하여 이용계약을 체결하고 자신의 샵에 아이디어, 컨텐츠, 디자인을
            게재함으로써, 회사가 해당 아이디어, 컨텐츠, 디자인을 이용하여 상품을 제작∙판매하는 것을
            허락합니다.
          </li>
          <li>
            ②. 판매자회원은 회원가입정보와 관련하여 주소지 또는 대금결제를 위한 판매자회원 명의의 통장계좌
            등의 변경이 있을 경우, 즉시 회사에 이를 통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한
            손해에 대하여 책임을 지지 않습니다.
          </li>
          <li>
            ③. 판매자회원은 관련 법령, 약관, 운영정책, 이용안내 및 회사가 공지하거나 통지한 사항 등을
            준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
          </li>
          <li>
            ④. 판매자회원은 다음 각 호에 해당하는 행위를 하여서는 아니되며, 이를 위반한 경우 회사는 서비스
            이용정지 및 이용계약해지 등의 조치를 취할 수 있습니다. 서비스 이용정지의 구체적인 기준 등에
            대해서는 운영정책에서 정합니다. 다만, 회사가 이용계약해지의 조치를 취하는 경우 회사는
            이용계약해지의 사유를 명시하여 판매자회원에게 전자우편, 전화 등으로 개별 통지합니다.
            판매자회원에게 항변의 사유가 있는 경우, 판매자회원은 이에 대하여 항변할 수 있습니다.
            <ol>
              <li>
                1. 일반 사항
                <ol>
                  <li>1. 서비스를 통해 음란정보, 거짓정보 등 유해한 정보를 게재하거나 링크하는 행위</li>
                  <li>2. 범죄행위와 결부되는 모든 행위 및 기타 관계법령에 해당하는 행위</li>
                  <li>3. 해킹, 컴퓨터 바이러스 유포, 서버 공격 등으로 타인과 회사에 해가 되는 경우</li>
                  <li>
                    4. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 수신자의 의사에 반하여
                    광고성 정보를 지속적으로 전송하는 행위
                  </li>
                  <li>5. 통신판매 이외의 목적으로 서비스를 이용하는 행위</li>
                  <li>6. 회사의 서비스를 방해하거나 장애를 발생시킬 수 있는 모든 행위</li>
                  <li>
                    7. 이 약관에 따른 권리와 의무를 회사의 사전 서면 동의 없이 타인에게 양도, 증여하거나 이를
                    담보제공하는 행위
                  </li>
                </ol>
              </li>
              <li>
                2. 상품등록 및 판매 관련 금지행위
                <ol>
                  <li>
                    1. 가족 또는 지인 등 타인의 계정을 이용하여 판매자 본인의 상품을 구매하는 등의 부정 거래
                    행위
                  </li>
                  <li>
                    2. 회사의 결제대금보호서비스를 통하지 않는 직거래 유도행위 및 구매회원의 직거래 요청을
                    수락하는 행위
                  </li>
                  <li>
                    3. 타인의 콘텐츠, 디자인, 상표, 로고 등을 허락없이 사용하는 등 타인의 지식재산권을
                    침해하는 행위
                  </li>
                  <li>4. 상품 등록 시 상품과 관련없는 상표명을 사용하거나 중복해서 사용하는 행위</li>
                  <li>
                    5. 상품 등록 시 유해하거나 타인에게 불쾌감을 줄 수 있는 부적절한 콘텐츠, 디자인을 사용하는
                    행위
                  </li>
                  <li>
                    6. 타인이 창작한 이미지나 문구 또는 타인이 촬영하여 제작한 사진 등 타인의 창작물을
                    무단으로 사용하는 행위
                  </li>
                  <li>
                    7. 공적인물의 사진이나 성명 등을 해당 공적인물의 허락없이 사용하여 타인의 초상권, 성명권
                    등을 침해하는 행위
                  </li>
                  <li>8. 관련 법령에 위반되는 아이디어, 디자인, 컨텐츠가 담긴 상품을 판매하는 행위</li>
                  <li>9. 상품 부정등록 등 기타 비정상적인 방법으로 상품을 등록하는 행위</li>
                  <li>10. 기타 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            ⑤. 기타 관련 법령 및 회사에서 제공한 이용약관 및 개별 약관의 변경, 판매자회원 공지사항 등의 확인
            의무를 게을리해 발생한 판매자회원의 피해에 대해서는 회사는 책임을 지지 않습니다.
          </li>
          <li>
            ⑥. 회사에서 제공하는 마플 서비스에 가입된 판매자회원의 경우, 현 약관을 별도로 확인하고 동의한 자에
            한해서만 마플 서비스 계정으로 몰 이용이 가능합니다.
          </li>
          <li>
            ⑦. 판매자회원은 회사에 대하여 외부(회사의 서비스와 유사한 온라인 및 오프라인 플랫폼 모두를
            포함하나 이에 한정하지 않음)에 제공하는 디자인가격 또는 주요 조건보다 불리하지 않다는 사실을 진술,
            보증합니다. 판매자회원이 외부에 아이디어, 컨텐츠, 디자인등을 판매 또는 이용을 허락하면서 회사에게
            부여된 주요조건보다 더 유리한 조건으로 거래한 경우, 판매자회원은 이를 회사에 통보하여야 하며,
            회사는 외부에게 부여한 주요조건으로 판매자회원의 아이디어, 컨텐츠, 디자인을 제공받을 권리를 가짐과
            아울러 기존 거래 중 불리한 조건으로 거래한 부분에 대하여 정산할 권리를 가질 수 있습니다.
          </li>
          <li>
            ⑧. 상품 및 인쇄방식의 특성상 판매자회원의 아이디어, 컨텐츠, 디자인에 대하여 수정이 필요한 경우,
            회사는 판매자회원에게 수정을 요청할 수 있습니다. 판매자회원은 회사의 요청에 따라 수정된 디자인을
            회사에 전달하고, 수정된 디자인을 자신의 샵에 게재하여, 구매자회원이 수정된 디자인을 알 수 있도록
            해야 합니다.
          </li>
          <li>
            ⑨. 판매자회원의 로그인 정보(계정 및 비밀번호)에 대한 관리 책임은 판매자회원에게 있으며, 회사는
            판매자회원의 귀책사유에 따른 로그인 정보의 분실 및 제3자 사용 등과 관련하여 발생하는 손해에
            책임지지 않습니다.
          </li>
          <li>
            ⑩. 회사가 몰 서비스 운영에 필요한 정보 및 증빙자료의 제공을 요청하는 경우 판매자회원은 이에 성실히
            응해야 합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service7">제7조 (회사의 권리와 의무)</h3>
        <ol>
          <li>
            ①. 회사는 판매자회원으로부터 제기되는 불편사항 및 서비스의 문제점에 대해 합리적이라고 판단되는
            경우 우선적으로 해당 문제를 해결하며, 신속한 해결이 곤란한 경우에는 판매자회원에게 그 사유와
            처리절차를 안내합니다.
          </li>
          <li>
            ②. 회사는 판매자회원이 몰에 등록한 상품정보, 회사와의 인터뷰 내용 등 회사를 통하거나 회사와
            협업하여 생성된 콘텐츠 등을 몰 내의 콘텐츠 및 몰과 관련된 서비스의 마케팅 목적을 위해 몰의 공식
            SNS 계정 포스팅 등 몰의 콘텐츠로서 사용할 수 있습니다. 회사가 필요하다고 판단한 경우 회사는 본
            항에 언급된 콘텐츠를 수정/편집하여 활용할 수 있습니다. 인터뷰의 경우, 셀러가 되고자 하는 이유,
            목표, 홍보 방안, 회사가 제공하는 서비스에 대한 평가 등에 대해 인터뷰를 진행하고 콘텐츠 활용에
            도움을 주신 판매자회원에게 그에 대한 소정의 원고료를 지불합니다.
          </li>
          <li>
            ③. 회사는 판매자회원의 서비스이용과 관련한 자료를 수사기관의 수사목적의 요청 및 기타 공공기관이
            관련 법률에 따른 절차를 통해 요청하는 경우 판매자회원의 동의없이 해당 기관에 제공할 수 있습니다.
          </li>
          <li>
            ④. 회사는 판매자회원이 몰에 등록한 상품 및 그 정보 등이 불법정보에 해당한다고 판단되거나, 회사의
            저작권, 상표권, 윤리 필수 지침 가이드를 준수하지 않았거나 방송통신심의위원회 등 관련기관으로부터
            요청이 있는 경우 또는 판매자회원이 몰을 이 약관 이외의 목적으로 사용한다고 판단되는 경우
            판매자회원에 대하여 특정 서비스의 이용을 제한하거나, 해당 상품 또는 정보를 삭제하고 샵 운영을
            중지시키는 등의 조치를 취할 수 있습니다.
          </li>
          <li>
            ⑤. 회사는 안정적인 서비스 제공을 위해 거래액, 상품의 가격 등을 기준으로 구매자고객에게 제공될
            상품(판매중 및 판매대기 상품을 모두 포함)의 디자인가격에 최대 한도를 정할 수 있습니다.
          </li>
          <li>
            ⑥. 회사는 판매자회원의 회원가입, 정산, 대금지급 등을 위해 필요한 자료를 판매자회원에게 요청할 수
            있으며, 그 정보를 외부로부터 보호하고 해당 목적 외 다른 목적으로 사용하지 않습니다.
          </li>
          <li>
            ⑦. 회사는 판매자회원의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 적절한 모니터링을 실시할
            수 있고, 판매자회원은 회사의 모니터링 업무에 대하여 협조하여야 하며, 회사가 요청하는 필요 최소한의
            증빙자료를 제출할 의무가 있습니다.
          </li>
          <li>
            ⑧. 회사는 본 약관의 위반 행위를 확인되면 해당 판매자회원에게 부가적으로 제공한 혜택의 일부 또는
            전부를 회수하거나, 특정 서비스의 이용을 제한하고, 이용계약을 해지하는 등의 조치를 할 수 있습니다.
          </li>
          <li>
            ⑨. 판매자회원이 본 약관에 따른 의무를 위반하여 회사가 손해를 입은 경우, 판매자회원은 손해배상의
            책임을 부담합니다. 다만, 해당 판매자회원이 자신의 고의 또는 과실 없음을 증명한 때에는 그러하지
            않습니다.
          </li>
          <li>
            ⑩ 회사는 재화의 생산 중단 혹은 기타 사정에 의한 재화의 공급이 어려운 경우 고지 없이 재화의 제공을
            중단할 수 있으며 이에 대하여 회사는 책임을 부담하지 않습니다.
          </li>
          <li>
            ⑪ 판매자 회원은 상품 판매등록 이후에도 상품에 대한 정보 수정이 가능합니다. 다만 주문 발생 후 해당
            상품에 대한 수정 및 삭제는 불가 하며 회사는 이를 회사의 서비스 이용과 관련한 내부자료로 간주하여
            상품에 대한 정보를 보관합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service8">제8조 (개인정보의 보호)</h3>
        <ol>
          <li>
            ①. 판매자회원은 『전자상거래 등에서의 소비자보호에 관한 법률』에 따른 통신판매사업자 신고자에
            해당하지 않으며, 구매자회원의 개인정보는 판매자회원에게 제공되지 않습니다.
          </li>
          <li>
            ②. 판매자회원은 이용신청, 정산 시 허위의 정보를 제공하여서는 아니되며, 회원의 정보를 허위로
            수정하여 회사 또는 제3자에게 손해를 입힌 경우 발생한 모든 손해를 배상하여야 합니다.
          </li>
          <li>
            ③. 회사는 서비스 이용계약을 위하여 회원이 제공한 정보 외에도 수집 및 이용목적 등을 밝히고, 판매자
            회원의 동의를 받아 회원으로부터 필요한 정보를 수집, 이용할 수 있습니다. 회원이 동 정보의 수집 및
            이용에 관한 동의를 거절하는 경우, 회사는 제한되는 서비스 내용을 구체적으로 명시하여야 하며,
            필수수집항목이 아닌 개인정보에 대한 동의 거절을 이유로 서비스 제공을 제한하거나 거절하지 않습니다.
            회원은 본 항에 따라 동의하였더라도 언제든지 그 동의를 철회할 수 있습니다.
          </li>
          <li>
            ④. 회사는 서비스의 제공과 관련하여 알게 된 판매자회원의 개인정보를 이 약관에서 정한 목적 이외의
            용도로 사용하거나 본인의 승낙 없이 제 3자에 누설, 배포하지 않고, 이를 보호하기 위하여 노력하여야
            하며 회원의 개인정보 처리에 관한 사항은 『개인정보 보호법』 및 『정보통신망 이용촉진 및 정보보호
            등에 관한 법률』 등 개인정보의 보호에 관한 관계 법령 및 회사의 개인정보 처리방침에 따릅니다.
          </li>
          <li>
            ⑤. 회사는 관련 법령에 따른 적법한 절차 및 방법에 의거하여 수사기관 등의 요청이 있을 경우, 그 범위
            내의 판매자회원에 관한 개인정보를 제공할 수 있습니다.
          </li>
          <li>
            ⑥. 판매자 회원이 본 조를 위반하거나 회사에 대한 통지를 지연하여 회사 또는 제3자에게 발생한 모든
            손해에 대한 민·형사상의 책임은 판매자회원에게 있으며, 이로 인하여 발생한 모든 불이익에 대하여
            회사에 중대한 귀책사유가 없는 한 회사에게 어떠한 책임도 물을 수 없습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service9">제9조 (서비스의 제공 및 이용료)</h3>
        <ol>
          <li>
            ①. 회사는 판매자회원이 자신의 아이디어, 콘텐츠, 디자인 등을 이용하여 자신만의 상품을 디자인할 수
            있도록 제반 서비스를 제공하며, 상품 제작, 주문 확인, 상품 배송, 고객 응대 등의 전 과정을 진행 및
            관리합니다.
          </li>
          <li>
            ②. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 서비스를 제공하는 것을
            원칙으로 합니다. 그러나, 정기점검이나 시스템상 업그레이드가 요구되는 등 서비스 중단이 불가피할
            경우 회사는 서비스 제공을 일시적으로 중단할 수 있으며, 이 경우 회사는 판매자 센터 등을 통해 서비스
            이용 중단 일정을 사전에 공지할 수 있습니다.
          </li>
          <li>
            ③. 회사에서 지원하는 주문 확인, 상품 제작, 상품 배송, 고객 응대 등의 서비스에 대한 서비스 이용료는
            판매 대금 중 기본가격에 포함됩니다.
          </li>
          <li>
            ④. 회사는 카드수수료 등 공제가 필요한 금액을 판매자회원에게 지급해야 하는 정산 대금에서의 공제
            등으로 징수할 수 있으며, 회사와 판매자회원간의 협의 또는 회사의 내부적인 정책 등에 따라 수수료 및
            징수 방법을 다르게 정할 수 있습니다.
          </li>
          <li>
            ⑤. 회사는 필요한 경우 서비스수수료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 서면(전자우편
            포함) 또는 몰을 통하여 통지하고 협의해야 합니다.
          </li>
          <li>
            ⑥. 본 조 제5항에 의하여 서비스수수료의 변경이 통지된 경우, 이에 동의하지 않는 판매자회원은 본
            계약을 해지할 수 있습니다.
          </li>
          <li>
            ⑦. 회사는 판매자회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항, 서비스
            화면, 전자우편 등의 방법으로 판매자회원에게 제공할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service10">제10조 (서비스 이용의 제한 및 중단)</h3>
        <ol>
          <li>
            ①. 회사는 다음 각 호에 해당하는 경우 특정 서비스의 일부 또는 전부를 일시적 또는 영구적으로 수정,
            중단하거나 변경할 수 있으며 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지
            않습니다.
            <ol>
              <li>1. 기술적 장애, 천재지변, 국가비상사태 등의 사유로 인하여 서비스제공이 불가능한 경우</li>
              <li>
                2. 서비스 이용 설비의 보수 등 공사로 인한 부득이한 경우이거나 기술 진보에 따른 차세대
                서비스로의 전환 필요성이 있는 경우
              </li>
              <li>3. 『전기통신사업법』에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</li>
              <li>
                4. 원활한 서비스 제공의 곤란 또는 수익성 악화되거나 서비스 제공과 관련한 회사 정책의 변경 등
                상당한 이유가 있는 경우
              </li>
              <li>
                5. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스
                이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
              </li>
              <li>6. 기타 불가항력적 사유가 있는 경우</li>
            </ol>
          </li>
          <li>
            ②. 회사는 판매자회원의 서비스 이용이 다음 각 호 중 어느 하나 이상에 해당하는 경우 서비스 제공을
            제한, 중지, 또는 이용계약을 해지할 수 있습니다.
            <ol>
              <li>1. 제5조 제4항의 승낙거부사유가 있음이 확인된 경우</li>
              <li>2. 이 약관에서 규정하는 회원의 의무사항을 위반하는 행위를 하는 경우</li>
              <li>
                3. 수사기관으로부터 수사목적의 요청이 있거나, 방송통신심의위원회, 서울시 전자상거래센터 등의
                기관으로부터 심의결정 또는 서비스 제한 요청이 있는 경우
              </li>
              <li>4. 판매자회원의 서비스 이용이 전체 서비스 시스템 과부하의 원인이 되는 경우</li>
              <li>
                5. 기타 본 약관 또는 회사의 다른 서비스 약관 및 정책 등을 위반하여 회사 또는 다른 회원에게
                손해가 발생하거나 발생할 우려가 있다고 판단되는 경우
              </li>
            </ol>
          </li>
          <li>
            ③. 회사는 서비스의 원활한 운영을 위하여 판매자회원이 등록한 상품이 6개월 이상 판매 이력이 없거나
            또는 판매자회원의 정보관리 이력이 없는 경우 몰에 공지 또는 개별 통지한 후 판매자회원이 등록한
            상품정보를 삭제할 수 있습니다.
          </li>
          <li>
            ④. 회사가 판매자회원 자격을 정지하거나 판매/구매서비스의 이용 제한, 기타 필요한 조치를 취할 경우
            판매자회원에게 유선 또는 메일(e-mail), 기타의 방법을 통하여 통보합니다 다만, 회원의 연락이
            두절되거나 긴급하다 판단되는 경우 선 조치 후 통보할 수 있습니다. 이에 대하여 판매자 회원은 회사의
            조치에 대해 이의를 제기할 수 있으며 회사는 회원의 이의제기가 타당하다고 판단되는 경우 조치를
            해제할 수 있습니다.
          </li>
          <li>
            ⑤. 본 조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우, 판매자회원은 이에 대하여 항변할 수
            있습니다.
          </li>
          <li>
            ⑥ 판매자 회원이 회사의 이용규정을 위반하거나 이로 인한 클레임이 발생하였을 경우 회사는 운영정책에
            따른 조치(회사 내부 회원등급의 하락, 벌점부여, 포인트 회수, 서비스의 이용 제한 등)를 할 수 있으며,
            판매자 회원은 회사에서 제공하는 서비스의 이용이 제한될 수 있습니다
          </li>
          <li>
            ⑦ 판매자 회원은 서비스 이용 중에 다음 각호의 행위를 할 수 없습니다.
            <ol>
              <li>
                1. 거래·결제 부정행위
                <ol>
                  <li>1-1. 회사가 제공하는 혜택(쿠폰,이벤트, 포인트 등)을 부당하게 이용하는 행위.</li>
                  <li>1-2. 결제 수단의 명의도용.</li>
                  <li>1-3. 그 밖의 각호의 행위에 준하는 일체의 거래 및 결제 부정행위.</li>
                </ol>
              </li>
              <li>
                2. 서비스 이용 부적합 행위
                <ol>
                  <li>2-1. 회사가 정하지 않은 방식으로 시스템에 접속 및 이벤트 참여하는 행위.</li>
                  <li>
                    2-2. 회원정보를 거짓으로 등록하거나 회사의 정당한 연락을 회피하는 행위 및 불성실, 부정확한
                    답변을 하는 행위.
                  </li>
                  <li>
                    2-3. 게시물의 내용과 무관한 키워드 반복적으로 작성하는 경우 혹은 특정 키워드 반복적으로
                    작성하는 경우.
                  </li>
                  <li>2-4. 그 밖의 각호의 행위에 준하는 일체의 서비스이용 부적합 행위.</li>
                </ol>
              </li>
              <li>
                3. 매매 부적합 서비스 등록 행위
                <ol>
                  <li>3-1. 회사의 시스템에 매매부적합 서비스를 판매 등록하는 행위.</li>
                  <li>3-2. 기타 법령과 공공질서 및 미풍양속에 어긋나는 서비스 거래 행위.</li>
                  <li>3-3. 그 밖의 각호의 행위에 준하는 매매부적합 서비스의 문의·거래 행위.</li>
                </ol>
              </li>
              <li>
                4. 비 매너 행위
                <ol>
                  <li>4-1. 회원에게 협박·욕설·비방·모욕하는 행위.</li>
                  <li>
                    4-2. 회사 및 임직원, 회사에 의하여 고용된 자에게 협박·욕설·비방·모욕을 줄 수 있는 행위.
                  </li>
                  <li>4-3. 회사의 서비스 제공 및 이용에 대하여 일방적인 요구를 지속하는 행위.</li>
                  <li>4-4. 대한민국 법령 또는 공서양속에 위배되는 행위.</li>
                  <li>4-5. 그 밖의 각호의 행위에 준하는 일체의 비매너 및 업무방해 행위.</li>
                </ol>
              </li>
              <li>
                5. 권리침해
                <ol>
                  <li>5-1. 타인의 정보를 통해 서비스를 이용하는 행위.</li>
                  <li>5-2. 회사의 정보 및 데이터를 동의 없이 회원 또는 제3자가 상업적으로 이용하는 행위.</li>
                  <li>5-3. 거래의 제반 행위를 통해 저작권·초상권 등의 제 3자의 권리를 침해하는 경우.</li>
                  <li>
                    5-4. 회사의 개인정보처리방침 또는 관련 법령을 위배하여 다른 회원의 개인정보를 침해하는
                    행위.
                  </li>
                  <li>5-5. 타인 및 회사의 명예 또는 신용, 정당한 이익을 훼손하는 행위.</li>
                  <li>5-6. 그 밖의 각호의 행위에 준하는 일체의 권리침해 행위.</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service11">제11조 (지식재산권보호 및 권리침해신고 등)</h3>
        <ol>
          <li>
            ①. 회사는 서비스를 이용함에 있어 저작권자의 저작권 보호를 위한 정책을 수립하여 운영하며
            판매자회원은 회사의 저작권 정책을 준수하여야 합니다.
          </li>
          <li>
            ②. 판매자회원은 제3자의 지식재산권을 사용하게 되는 경우 당해 권리자로부터 사용에 대한 허락을
            취득한 후에 사용해야 하며 상품의 등록 및 판매 등과 관련하여 제3자의 상표권, 특허권, 저작권,
            성명권, 초상권 등 제반 지식재산권을 침해하지 않아야 합니다.
          </li>
          <li>
            ③. 회사는 판매자회원이 서비스에 등록∙사용한 아이디어, 디자인, 컨텐츠 또는 상품정보 및 제반 정보에
            대하여 제3자로부터 권리침해의 주장이 있을 경우, 회사는 내부 운영정책에 따른 조치(특정 서비스의
            이용을 제한하거나, 해당 상품 또는 정보를 삭제하고 샵 운영을 중지시키는 등의 조치)를 취할 수
            있습니다.
          </li>
          <li>
            ④. 전항과 관련하여 회사는 판매자회원에게 소명자료를 요청할 수 있으며, 판매자회원은 이에 적극
            협조하여야 합니다. 단, 판매자회원이 제출한 자료가 법원의 확정판결 등 회사가 인정하는 자료가 아닌
            경우 회사는 상품 삭제 및 등록 제한 상태를 유지할 수 있습니다.
          </li>
          <li>
            ⑤. 판매자회원이 몰에 등록한 아이디어, 컨텐츠, 디자인, 상품 등에 대한 저작권은 판매자회원에게
            귀속됩니다. 이와 관련하여 회사가 판매자회원의 저작권 등 제3자의 권리침해 행위로 민사, 형사 또는
            행정상 분쟁이 발생하는 경우, 판매자회원은 회사를 면책시키기 위하여 적극적으로 할 수 있는 모든
            조치를 취하여야 하고, 만약 회사가 이로 인하여 민사, 형사 또는 행정상 책임을 부담하게 되는 경우,
            회사는 판매자회원에게 그로 인한 모든 손해의 배상을 청구하고 관련 비용을 구상할 수 있습니다.
          </li>
          <li>⑥. 회사가 작성한 저작물에 대한 저작권 기타 지식재산권은 회사에 귀속합니다.</li>
          <li>
            ⑦. 판매자회원은 회사의 서비스를 이용함으로써 얻은 정보를 회사의 사전 서면 승낙 없이 복제, 송신,
            출판, 배포, 방송 기타 방법에 의하여 이용하거나 제3자에게 이용하게 하는 경우 그에 대한 책임은
            판매자회원 본인에게 있습니다.
          </li>
          <li>
            ⑧. 판매자회원은 유무상을 불문하고 회사가 제공하는 저작물(회사가 본 서비스를 통하여 제공하는
            이미지, 영상, 로고, 템플릿, 슬로건 등을 포함하나 이에 한정하지 않음)을 제3자에게 배포하거나 그
            소유권을 이전하여 줄 수 없으며 이를 재판매하거나 공유하는 행위 역시 모두 금지됩니다. 다만
            판매자회원이 회사로부터 사전에 서면 허가를 받은 경우, 해당 판매자회원은 제3자에게 회사가 제공한
            저작물을 배포할 수 있습니다.
          </li>
          <li>
            ⑨. 판매자회원의 아이디어, 컨텐츠, 디자인을 회사가 상품으로 제작하는 과정에서 회사가 디자인을 수정,
            개선 또는 변형하거나 그 외 창작에 기여를 하였다고 인정될만한 행위를 하여 새로운 저작물이 창작된
            경우, 해당 저작물에 대한 지식재산권, 상표권 등 기타 권리는 판매자회원과 회사의 각 기여도에 따라
            귀속 주체가 달라질 수 있고, 회사와 판매자회원은 이와 관련한 구체적인 조건을 별도의 약정을 통하여
            정할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service12">제12조 (신고센터제도)</h3>
        <ol>
          <li>
            ①. 구매자회원 또는 제3자가 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인한 법률상 이익 침해를
            근거로 회사에 대하여 불만을 제기할 경우, 회사는 이를 원활하게 해결하기 위하여 신고센터제도를
            운영합니다.
          </li>
          <li>
            ②. 구매자회원 또는 제3자는 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인하여 침해된 법률상
            이익을 구체적으로 작성하여 신고센터제도에 불만을 접수할 수 있으며, 해당 불만은 접수 즉시
            판매자회원에게 공유됩니다. 판매자회원은 구매자회원 또는 제3자의 불만을 공유받은 날로부터 2 영업일
            이내에 이에 대하여 소명하여야 합니다.
          </li>
          <li>
            ③. 회사는 불만이 접수된 날로부터 [●] 영업일 이내에 해당 불만의 구체성, 합리성, 판매자회원의 소명
            내용 등을 종합적으로 판단하여 해당 아이디어, 컨텐츠, 디자인 등이 사용된 상품의 판매를 중단하거나
            이를 샵으로부터 삭제하는 등의 조치를 취할 수 있습니다.
          </li>
          <li>
            ④. 판매자회원은 전항의 회사의 조치가 있은 날로부터 14일 이내에 회사에 대하여 이의를 제기할 수
            있습니다. 판매자회원이 14일 동안 이의를 제기하지 않는 경우 회사는 전항에 따른 조치를 확정하며,
            해당 판매자회원은 이로 인하여 발생하는 손해에 대하여 회사에게 그 어떠한 책임도 물을 수 없습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service13">제13조 (정산)</h3>
        <ol>
          <li>
            ①. 정산은 월 1회 실시를 원칙으로 하며, 해당월1일부터 10일까지의 신청건에 한하여 당월 지급합니다.
          </li>
          <li>②. 판매자회원은 몰에서 제공하는 페이지에서 판매 현황을 확인할 수 있습니다.</li>
          <li>
            ③. 회사는 판매자회원에게 당월 정산 대금에 대한 정산 자료를 익월 영업일 기준 5일 이내로 몰의 정산
            관련 페이지를 통해 제공합니다.
          </li>
          <li>④. 본 조 제3항의 당월 정산 대금은 당월에 출고된 주문을 기준으로 계산합니다.</li>
          <li>
            ⑤. 몰을 통해 판매된 상품이 구매확정되는 경우, 전체 판매 대금 중 상품의 기본가격을 제외한
            판매자회원이 산정한 디자인가격에 대한 금액에서 카드 수수료, 사업자가 없는 회원에 한하여
            사업소득세에 대한 원천징수액 등 공제가 필요한 금액을 제한 나머지 금액을 판매자회원에게 지급합니다.
          </li>
          <li>
            ⑥. 세금계산서 발행이 가능한 사업자를 보유한 판매자회원은 회사에게 회사로부터 지급받을 금액에 대한
            세금계산서를 익월 10일까지 발행합니다.
          </li>
          <li>
            ⑦. 회사는 당월 정산 금액과 판매자회원이 발급한 세금계산서 금액이 일치할 경우, 판매자회원이 본 약관
            7조 6항에 따라 회사에 제공한 결제 계좌로 출금 신청이 마감된 10일 이후 7 영업일 이내에 그 금액을
            송금합니다. 단, 판매자회원이 지정한 입금계좌의 예금주는 판매자회원과 동일인이어야 하며(단, 제5조
            제3항에 따라 법정대리인이 정산을 받는 경우 예외로 합니다), 해당 일이 회사의 영업일이 아닌 경우
            이후 최초 영업일에 송금합니다.
          </li>
          <li>
            ⑧. 회사는 판매자회원이 정산받을 금액이 [1]만원 미만일 경우, 정산을 유예하고 다음 정산 금액에
            합산하여 정산할 수 있습니다.
          </li>
          <li>⑨. 판매자회원이 병역 근무 중인 경우, 정산이 제한될 수 있습니다.</li>
          <li>
            ⑩. 판매자회원 가입 후 처음 정산요청 가능일자로부터 [60]개월내 출금요청을 하지 아니하였을 경우,
            출금 신청 가능한 수익은 자동으로 소멸합니다.
          </li>
          <li>
            ⑪. 정산요청 관련 서류 누락, 계좌번호 오기입 등 판매자 회원의 귀책사유로 정산대금의 지급이 실패한
            경우, 그 대금에 대한 당월 지급은 불가하며, 판매자회원은 익월 재신청해야합니다.
          </li>
          <li>
            ⑫. 판매자회원은 원활한 정산 처리를 위해 회사가 별도의 공지사항 혹은 이용안내로 고지한 방법을 통해
            계좌정보 등 정산금 지급에 필요한 정보 기재 후 일정한 방법을 통해 계좌정보등록을 하여야 합니다.
            해당 절차를 모두 완료한 경우 판매자회원과 회사는 정산계약을 체결한 것으로 간주합니다.
          </li>
          <li>
            ⑬. 판매자회원이 미성년자인 경우 법정대리인(부모 등)의 동의를 받아 정산계약을 체결할 수 있으며,
            회사가 지정 및 안내하는 방법에 따라 이용 동의를 하여야 합니다.
          </li>
          <li>
            ⑭. 구매자회원으로부터 주문취소/환불 건이 발생하는 경우, 발생 시기에 따라 아래와 같이 반영합니다.
            <ol>
              <li>1. 당월 정산 금액 마감 전 발생하는 경우, 당월 정산에 반영하여 정산합니다.</li>
              <li>
                2. 당월 정산 금액 마감 후 발생하는 경우, 당월 정산에는 반영하지 아니하며 익월 정산에 반영하여
                정산합니다.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service14">제14조 (정산의 보류)</h3>
        <ol>
          <li>
            ①. 회사는 판매자회원의 귀책사유로 인해 발생한 비용을 정산 대금 정산 시 상계한 뒤 지급할 수
            있습니다.
          </li>
          <li>
            ②. 판매자회원의 서비스 중지 또는 이용계약 해지 후 당월 판매자회원의 판매대금의 일정 비율에
            해당하는 금액을 일정기간 동안 예치하여 동 기간 동안 구매한 구매자회원으로부터 오류 수정, 환불 등의
            청약 철회 요구가 발생하는 경우 관련 비용의 지급에 사용할 수 있습니다.
          </li>
          <li>
            ③. 판매자회원의 채권자의 신청에 의한 판매대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을
            경우, 회사는 판매자회원과 채권자 간의 합의 또는 채무액의 변제 등으로 동 결정이 해제될 때까지
            판매대금의 ‘정산’을 중지하거나 채권자에게 변제 또는 공탁할 수 있습니다.
          </li>
          <li>
            ④. 본 조에서 정한 사항 외에도 법률의 규정에 의하거나 합리적인 사유가 있는 경우에는 회사는
            판매자회원에게 이를 통지하고 판매대금의 전부 또는 일부에 대한 정산을 일정 기간 유보할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service15">제15조 (결제대금보호서비스)</h3>
        <ol>
          <li>
            ①. 회사는 몰을 통해 서비스를 제공하는 과정에서 대금의 수령, 보관 및 송금업무로 이루어지는
            결제대금보호 서비스(이하 구매안전서비스)를 제공합니다.
          </li>
          <li>
            ②. 회사가 제공하는 구매안전 서비스는 몰과 서비스를 통한 모든 거래에 적용됩니다. 판매자회원이
            구매안전 서비스를 통하지 않는 직거래를 유도하는 경우, 회사는 해당 거래를 취소하거나, 샵 운영을
            중지시키는 등 기타 필요한 조치를 취할 수 있습니다.
          </li>
          <li>
            ③. 회사가 제공하는 구매안전 서비스를 이용하지 않은 거래 및 물품 또는 구매안전 서비스가 종결된
            이후의 거래와 관련하여 발생한 모든 사항에 대하여는 회사는 그 어떠한 책임도 부담하지 않습니다.
          </li>
          <li>
            ④. 판매자회원은 회사가 제공하는 서비스를 이용함에 있어서 구매안전 서비스의 이용과 그 규칙에
            동의하여야 합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service16">제16조 (양도금지)</h3>
        <ol>
          <li>
            ①. 판매자회원은 회사의 사전 서면 동의 없이 이 약관에 따른 일체의 권리와 의무를 제3자에게
            양도하거나 담보의 목적으로 제공할 수 없습니다.
          </li>
          <li>
            ②. 회사는 판매자회원이 본 조를 위반하는 경우 서비스 제공을 거부할 권한을 가지며, 기존 판매자회원에
            대하여 이용계약을 해지할 수 있습니다. 다만, 회사가 이용계약해지의 조치를 취하는 경우 회사는
            이용계약해지의 사유를 명시하여 판매자회원에게 전자우편, 전화 등으로 개별 통지합니다.
            판매자회원에게 항변의 사유가 있는 경우, 판매자회원은 이에 대하여 항변할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service17">제17조 (비밀유지)</h3>
        <ol>
          <li>
            ①. 판매자회원은 구매자회원의 정보 등 몰의 이용과 관련해 취득한 일체의 정보를 회사의 서면 동의 없이
            외부에 유출하거나 이 약관 이 외의 목적으로 사용할 수 없습니다.
          </li>
          <li>②. 전항의 의무는 본 이용계약의 종료 후에도 존속합니다.</li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service18">제18조(준거법 및 관할법원)</h3>
        <ol>
          <li>
            이 약관의 해석 및 분쟁의 해결은 대한민국의 법령에 따르며, 소송이 제기되는 경우에는 민사소송법에
            따라 관할 법원을 정합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3>&lt;부칙&gt;</h3>
        <p>
          본 약관은 2020년 11월 6일부터 시행됩니다.<br />
          2020년 7월 7일부터 시행해 온 약관은 본 약관으로 대체됩니다
        </p>
      </div>
      <div class="paragraph btn_prev">
        ${
          store_name
            ? html`<a href="/${T.lang}/${UtilS.escape(store_name)}/settings/terms/20200707"
                >- 이전 셀러스튜디오 이용약관 보기</a
              >`
            : html`<a href="/${T.lang}/@/studio/terms/20200707">- 이전 셀러스튜디오 이용약관 보기</a>`
        }
      </div>
    </div>
    <div class="option">
      <button class="done">동의</button>
    </div>
  </div>
`;

export const makeStudioTerms20201106Html = ({ store_name }) => legacyHtml`
  <div class="body terms_body">
    <div class="title">
      <h2>셀러스튜디오 이용약관</h2>
    </div>
    <div class="text_body">
      <div class="paragraph">
        <h3 id="service1">제1조(목적)</h3>
        <p>
          본 약관은 주식회사 마플코퍼레이션(이하 ‘회사’)가 운영하는 ‘마플샵’(이하 ‘몰’)과 셀러스튜디오(이하
          ‘판매자센터’)에서 전자상거래 관련 서비스 및 기타 서비스(이하 ’서비스’라 함)를 이용하기 위하여
          ‘마플샵’에 ‘셀러’(이하 ‘판매자회원’) 자격으로 가입한 자와 회사 간의 권리, 의무 및 제반
          절차를 확정하고 이를 이행함으로써 상호 발전을 도모하는 것을 그 목적으로 합니다.
        </p>
        <p>※ PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 현 약관을 준용합니다.</p>
      </div>
      <div class="paragraph">
        <h3 id="service2">제2조(정의)</h3>
        <p>본 "약관"에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <ol>
          <li>
            ①. ‘판매자회원’이란 만 14세 이상의 개인(개인 사업자 포함) 또는 법인으로서 현 약관에 동의하고
            ‘회사’가 제공하는 ‘몰’, ‘판매자센터’, ‘서비스’를 사용하며, ‘회사’에서 제공하는 서비스를 통해
            자신의 ‘아이디어, 콘텐츠, 디자인’을 제작, 게재 및 홍보하고, 그 ‘아이디어, 콘텐츠, 디자인’을
            이용하여 ‘회사’가 ‘상품’을 제작 및 판매할 수 있도록 허락하는 이용자를 의미합니다.
          </li>
          <li>
            ②. 판매자회원의 ‘아이디어, 콘텐츠, 디자인’이라 함은 이미지, 영상, 그림, 음성, 음향, 음원, 폰트,
            문자, 문서, 부호, 성명, 예명, 초상 등으로 표현된 ‘판매자회원’의 저작물 또는 정보로서 그 이용에
            있어서 사용자의 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다. ‘판매자회원’은
            ‘아이디어, 콘텐츠, 디자인’ 및 이를 이용하여 ‘회사’가 제작할 ‘상품’을 게재하여 자신만의 ‘샵’을
            꾸미고, 자신의 ‘아이디어, 콘텐츠, 디자인’을 홍보할 수 있습니다.
          </li>
          <li>
            ③. ‘샵’이란 ‘판매자회원’이 자신의 ‘아이디어, 콘텐츠, 디자인’을 ‘구매자회원’에게 소개 및 홍보할 수
            있는 회사가 운영하는 ‘몰’ 내 존재하는 공간 또는 전시장을 의미합니다.
          </li>
          <li>
            ④. ‘판매자센터’ 란 ‘회사’가 ‘판매자회원’이 자신의 ‘상품’을 디자인하여 국내 및 국외에서
            ‘구매자회원’에게 홍보할 수 있도록 돕고 통계 확인, 정산 등의 다양한 ‘서비스’를 지원하는 플랫폼을
            의미합니다.
          </li>
          <li>
            ⑤. ‘상품’이란 ‘회사’가 ‘판매자회원’으로부터 ‘아이디어, 콘텐츠, 디자인’의 이용을 허락받아 제작한
            의류, 핸드폰케이스, 악세사리 등으로, ‘회사’는 ‘구매자회원’과 ‘상품’에 대한 매매계약을 직접
            체결함으로써 이를 ‘몰’을 통해 판매합니다.
          </li>
          <li>⑥. ‘구매자회원’이란 ‘몰’에서 ‘상품’을 구매하는 이용자를 의미합니다.</li>
          <li>
            ⑦. ‘서비스’라 함은 회사가 ‘몰’을 통해 ‘판매자회원’이 자신의 ‘아이디어, 컨텐츠, 디자인’ 및 ‘상품’을
            제작∙홍보할 수 있도록 지원하는 것과 주문 확인, 상품 제작, 상품 배송 등 ‘구매자회원’의 구매행위와
            관련된 과정들을 지원하는 것을 총칭합니다.
          </li>
          <li>
            ⑧. ‘판매 대금’이란 ‘구매자회원’이 ‘상품’을 구매한 대가로 지불하는 금액을 말합니다. ‘판매 대금’은
            ‘회사’가 책정한 ‘기본가격’에 ‘판매자회원’이 책정한 ‘디자인가격’을 더하여 산정됩니다.
          </li>
          <li>
            ⑨. ‘기본가격’이란 ‘상품’ 제작·제조 비용, 금융결제수수료, ‘몰’의 개발, 유지, 관리 등 상품 공급과
            관련된 제반 서비스 비용 등에 대한 금액을 총칭한 것을 의미합니다.
          </li>
          <li>
            ⑩. ‘디자인가격’이란 ‘판매 대금’ 중 ‘판매자회원’이 자신의 디자인에 대하여 책정한 금액을 의미합니다.
          </li>
          <li>
            ⑪. ‘정산 대금’이란 ‘판매 대금’ 중 ‘판매자회원’이 책정한 자신의 디자인가격으로부터, 카드 결제
            수수료 등 공제가 필요한 부분에 대한 금액을 차감한 후 ‘회사’로부터 지급 받아야하는 금액을
            의미합니다.
          </li>
          <li>
            ⑫. 신고센터제도’란 구매자회원 또는 제3자가 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인한
            법률상 이익 침해에 대한 불만을 제기하기 위한 몰 내 게시판을 의미합니다.
          </li>
          <li>
            ⑬. 이외에 이 약관에서 사용하는 용어의 정의는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service3">제 3 조 (약관의 게시 및 개정)</h3>
        <ol>
          <li>
            ①. 회사는 이 약관을 판매자회원이 쉽게 알 수 있도록 몰 초기화면 또는 연결화면을 통하여 게시합니다.
          </li>
          <li>
            ②. 회사는 필요한 경우 관련 법령을 위반하지 않는 범위 내에서 이 약관을 개정할 수 있으며, 이 경우
            개정내용과 적용일자를 명시하여 몰을 통해 그 적용일자 7일 전부터 적용일자 전일까지 공지합니다.
            다만, 변경 내용이 판매자회원에게 불리하거나 판매자회원의 권리 또는 의무에 관한 중요 사항의 변경은
            개정약관의 적용일자 30일 전부터 적용일자까지 공지하고, 전자우편 등을 통하여 판매자회원에게 개별
            통지합니다(판매자회원의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 때에는 몰 초기화면을
            통하여 공지함으로써 개별 통지한 것으로 간주합니다.)
          </li>
          <li>
            ③. 판매자회원이 개정약관에 동의하지 않는 경우에는 개정약관의 적용일 이전에 거부의사를 표시하고 이
            약관에 의한 이용계약을 해지할 수 있습니다.
          </li>
          <li>
            ④. 회사가 본 조 제 2항에 따라 개정약관을 공지 또는 통지하면서 판매자회원에게 적용일전까지
            의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
            판매자회원이 명시적으로 거부의사를 표명하지 아니한 경우 개정약관에 동의한 것으로 봅니다.
          </li>
          <li>
            ⑤. 본 약관에 동의하는 것은 정기적으로 회사가 운영하는 웹사이트를 방문하여 약관의 변경사항을
            확인하는 것에 동의함을 의미하며, 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에
            대하여 회사는 책임을 부담하지 않습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service4">제4조 (약관의 효력)</h3>
        <ol>
          <li>
            ①. 회사는 본 약관에 규정되지 않은 세부적인 내용에 대해 개별 운영 정책 등(이하 ‘운영정책‘이라 함)을
            제정하여 운영할 수 있으며, 해당 내용을 몰을 통하여 게시합니다. 운영정책은 이 약관과 더불어 서비스
            이용계약(이하 ‘이용계약‘이라 함)의 일부를 구성합니다.
          </li>
          <li>
            ②. 회사는 서비스 중 특정 서비스에 관한 약관(이하 ‘개별약관‘이라 함)을 별도로 제정할 수 있으며,
            판매자회원이 개별약관에 동의한 경우 개별약관은 이용계약의 일부를 구성하고 개별약관에 이 약관과
            상충하는 내용이 있을 경우 개별 약관이 우선적으로 적용됩니다.
          </li>
          <li>
            ③. 본 약관에 의해 판매자회원으로 가입하고자 하는 자는 구매회원 이용약관의 내용을 숙지하고
            구매회원과 회사간의 권리∙의무관계에 대해 동의함을 확인합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service5">제5조 (이용계약의 성립)</h3>
        <ol>
          <li>
            ①. 이용약관은 서비스를 이용하고자 하는 자(이하 ‘이용신청자‘라 함)가 본 약관에 동의하고 회사가 정한
            절차에 따라 판매자회원 가입을 신청하며, 이에 대해 회사가 심사를 거쳐 승낙함으로써 성립합니다.
            회사는 이용승낙의 의사표시를 해당 서비스 화면에 게시하거나 전자우편 또는 기타 방법으로 할 수
            있습니다.
          </li>
          <li>
            ②. 이용신청자는 회사가 별도로 요청하는 증빙서류가 있는 경우 신속히 제출하여야 하며, 회사는 해당
            서류를 징구하는 시점까지 가입신청에 대한 승낙을 보류하거나 거부할 수 있습니다.
          </li>
          <li>
            ③. 판매자회원 가입은 만 14세 이상의 개인(개인 사업자 포함) 또는 법인사업자가 할 수 있으며, 만 19세
            미만의 판매자회원은 가입시 법정대리인의 동의서 및 회사가 요구하는 입증자료(가족관계증명서,
            법정대리인 인감증명서 등)를 제출하여야 합니다.
          </li>
          <li>
            ④. 회사는 본 조 제1항에 따라 판매자 회원가입을 신청한 자 중에서 아래 각 호에 해당되는 경우에는
            승낙을 거부할 수 있습니다. 이용신청자가 아래 각호의 행위를 하였을 경우, 회사는 이에 대한 어떠한
            책임도 부담하지 않으며 이용 신청자의 아래 각호 행위로 인해 회사에 손해가 발생하거나 제3자와 법적
            분쟁이 발생할 시 이용 신청자는 자신의 비용(변호사 비용, 소송 비용 등을 포함하나 이에 한정하지
            않음)과 책임으로 회사의 손해를 배상하여야 합니다. 다만, 회사의 고의 또는 중대한 과실로 인한 경우
            본 항이 적용되지 않습니다.
            <ol>
              <li>
                1. 가입을 신청한 자가 이 약관 및 회사의 다른 서비스와 관련하여 이전에 회원 자격을 상실한 적이
                있는 경우
              </li>
              <li>
                2. 제출 정보(본인확인절차 포함)의 내용에 허위, 기재 누락, 오기, 제3자 정보도용이 있는 경우
              </li>
              <li>
                3. 실명이 아니거나 타인의 정보 등(예시: 주민등록번호, 사업자번호, I-PIN 등)을 이용한 경우
              </li>
              <li>
                4. 회사로부터 자격 정지 조치 등을 받은 판매자회원이 그 조치기간 중에 이용계약을 임의 해지하고
                재이용신청을 하는 경우
              </li>
              <li>
                5. 이용신청자의 귀책사유로 인하여 승인이 불가능하거나 필수 서류 또는 회사가 요청하는 서류를
                제출하지 않는 등 기타 제반 사항을 위반하여 신청하는 경우
              </li>
              <li>
                6. 이용신청자와 서비스 정산 금액을 지급받는 자의 정보가 다를 경우(단, 제5조 제3항에 따라
                법정대리인이 정산을 받는 경우는 예외로 한다)
              </li>
              <li>
                7. 부정한 용도 또는 회사의 서비스 제공 목적에 반하여 영리를 추구할 목적으로 서비스를
                이용하고자 하는 경우
              </li>
              <li>
                8. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청 등 회사가 합리적인 판단에 의하여
                필요하다고 인정하는 경우
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service6">제6조 (판매자회원의 책임 및 관리 의무)</h3>
        <ol>
          <li>
            ①. 판매자회원은 본 약관에 동의하여 이용계약을 체결하고 자신의 샵에 아이디어, 컨텐츠, 디자인을
            게재함으로써, 회사가 해당 아이디어, 컨텐츠, 디자인을 이용하여 상품을 제작∙판매하는 것을
            허락합니다.
          </li>
          <li>
            ②. 판매자회원은 회원가입정보와 관련하여 주소지 또는 대금결제를 위한 판매자회원 명의의 통장계좌
            등의 변경이 있을 경우, 즉시 회사에 이를 통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한
            손해에 대하여 책임을 지지 않습니다.
          </li>
          <li>
            ③. 판매자회원은 관련 법령, 약관, 운영정책, 이용안내 및 회사가 공지하거나 통지한 사항 등을
            준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
          </li>
          <li>
            ④. 판매자회원은 다음 각 호에 해당하는 행위를 하여서는 아니되며, 이를 위반한 경우 회사는 서비스
            이용정지 및 이용계약해지 등의 조치를 취할 수 있습니다. 서비스 이용정지의 구체적인 기준 등에
            대해서는 운영정책에서 정합니다. 다만, 회사가 이용계약해지의 조치를 취하는 경우 회사는
            이용계약해지의 사유를 명시하여 판매자회원에게 전자우편, 전화 등으로 개별 통지합니다.
            판매자회원에게 항변의 사유가 있는 경우, 판매자회원은 회사의 통지를 받은 날로부터 30일 이내에 이에
            대하여 항변할 수 있습니다.
            <ol>
              <li>
                1. 일반 사항
                <ol>
                  <li>1. 서비스를 통해 음란정보, 거짓정보 등 유해한 정보를 게재하거나 링크하는 행위</li>
                  <li>2. 범죄행위와 결부되는 모든 행위 및 기타 관계법령에 해당하는 행위</li>
                  <li>3. 해킹, 컴퓨터 바이러스 유포, 서버 공격 등으로 타인과 회사에 해가 되는 경우</li>
                  <li>
                    4. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 수신자의 의사에 반하여
                    광고성 정보를 지속적으로 전송하는 행위
                  </li>
                  <li>5. 통신판매 이외의 목적으로 서비스를 이용하는 행위</li>
                  <li>6. 회사의 서비스를 방해하거나 장애를 발생시킬 수 있는 모든 행위</li>
                  <li>
                    7. 이 약관에 따른 권리와 의무를 회사의 사전 서면 동의 없이 타인에게 양도, 증여하거나 이를
                    담보제공하는 행위
                  </li>
                </ol>
              </li>
              <li>
                2. 상품등록 및 판매 관련 금지행위
                <ol>
                  <li>
                    1. 가족 또는 지인 등 타인의 계정을 이용하여 판매자 본인의 상품을 구매하는 등의 부정 거래
                    행위
                  </li>
                  <li>
                    2. 회사의 결제대금보호서비스를 통하지 않는 직거래 유도행위 및 구매회원의 직거래 요청을
                    수락하는 행위
                  </li>
                  <li>
                    3. 타인의 콘텐츠, 디자인, 상표, 로고 등을 허락없이 사용하는 등 타인의 지식재산권을
                    침해하는 행위
                  </li>
                  <li>4. 상품 등록 시 상품과 관련없는 상표명을 사용하거나 중복해서 사용하는 행위</li>
                  <li>
                    5. 상품 등록 시 유해하거나 타인에게 불쾌감을 줄 수 있는 부적절한 콘텐츠, 디자인을 사용하는
                    행위
                  </li>
                  <li>
                    6. 타인이 창작한 이미지나 문구 또는 타인이 촬영하여 제작한 사진 등 타인의 창작물을
                    무단으로 사용하는 행위
                  </li>
                  <li>
                    7. 공적인물의 사진이나 성명 등을 해당 공적인물의 허락없이 사용하여 타인의 초상권, 성명권
                    등을 침해하는 행위
                  </li>
                  <li>8. 관련 법령에 위반되는 아이디어, 디자인, 컨텐츠가 담긴 상품을 판매하는 행위</li>
                  <li>9. 상품 부정등록 등 기타 비정상적인 방법으로 상품을 등록하는 행위</li>
                  <li>10. 기타 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            ⑤. 기타 관련 법령 및 회사에서 제공한 이용약관 및 개별 약관의 변경, 판매자회원 공지사항 등의 확인
            의무를 게을리해 발생한 판매자회원의 피해에 대해서는 회사는 책임을 지지 않습니다.
          </li>
          <li>
            ⑥. 회사에서 제공하는 마플 서비스에 가입된 판매자회원의 경우, 현 약관을 별도로 확인하고 동의한 자에
            한해서만 마플 서비스 계정으로 몰 이용이 가능합니다.
          </li>
          <li>
            ⑦. 판매자회원은 회사에 대하여 외부(회사의 서비스와 유사한 온라인 및 오프라인 플랫폼 모두를
            포함하나 이에 한정하지 않음)에 제공하는 디자인가격 또는 주요 조건보다 불리하지 않다는 사실을 진술,
            보증합니다. 판매자회원이 외부에 아이디어, 컨텐츠, 디자인등을 판매 또는 이용을 허락하면서 회사에게
            부여된 주요조건보다 더 유리한 조건으로 거래한 경우, 판매자회원은 이를 회사에 통보하여야 하며,
            회사는 외부에게 부여한 주요조건으로 판매자회원의 아이디어, 컨텐츠, 디자인을 제공받을 권리를 가짐과
            아울러 기존 거래 중 불리한 조건으로 거래한 부분에 대하여 정산할 권리를 가질 수 있습니다.
          </li>
          <li>
            ⑧. 상품 및 인쇄방식의 특성상 판매자회원의 아이디어, 컨텐츠, 디자인에 대하여 수정이 필요한 경우,
            회사는 판매자회원에게 수정을 요청할 수 있습니다. 판매자회원은 회사의 요청에 따라 수정된 디자인을
            회사에 전달하고, 수정된 디자인을 자신의 샵에 게재하여, 구매자회원이 수정된 디자인을 알 수 있도록
            해야 합니다.
          </li>
          <li>
            ⑨. 판매자회원의 로그인 정보(계정 및 비밀번호)에 대한 관리 책임은 판매자회원에게 있으며, 회사는
            판매자회원의 귀책사유에 따른 로그인 정보의 분실 및 제3자 사용 등과 관련하여 발생하는 손해에
            책임지지 않습니다.
          </li>
          <li>
            ⑩. 회사가 몰 서비스 운영에 필요한 정보 및 증빙자료의 제공을 요청하는 경우 판매자회원은 이에 성실히
            응해야 합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service7">제7조 (회사의 권리와 의무)</h3>
        <ol>
          <li>
            ①. 회사는 판매자회원으로부터 제기되는 불편사항 및 서비스의 문제점에 대해 합리적이라고 판단되는
            경우 우선적으로 해당 문제를 해결하며, 신속한 해결이 곤란한 경우에는 판매자회원에게 그 사유와
            처리절차를 안내합니다.
          </li>
          <li>
            ②. 회사는 판매자회원이 몰에 등록한 상품정보, 회사와의 인터뷰 내용 등 회사를 통하거나 회사와
            협업하여 생성된 콘텐츠 등을 몰 내의 콘텐츠 및 몰과 관련된 서비스의 마케팅 목적을 위해 몰의 공식
            SNS 계정 포스팅 등 몰의 콘텐츠로서 사용할 수 있습니다. 회사가 필요하다고 판단한 경우 회사는 본
            항에 언급된 콘텐츠를 수정/편집하여 활용할 수 있습니다. 인터뷰의 경우, 셀러가 되고자 하는 이유,
            목표, 홍보 방안, 회사가 제공하는 서비스에 대한 평가 등에 대해 인터뷰를 진행하고 콘텐츠 활용에
            도움을 주신 판매자회원에게 그에 대한 소정의 원고료를 지불합니다.
          </li>
          <li>
            ③. 회사는 판매자회원의 서비스이용과 관련한 자료를 수사기관의 수사목적의 요청 및 기타 공공기관이
            관련 법률에 따른 절차를 통해 요청하는 경우 판매자회원의 동의없이 해당 기관에 제공할 수 있습니다.
          </li>
          <li>
            ④. 회사는 판매자회원이 몰에 등록한 상품 및 그 정보 등이 불법정보에 해당한다고 판단되거나, 회사의
            저작권, 상표권, 윤리 필수 지침 가이드를 준수하지 않았거나 방송통신심의위원회 등 관련기관으로부터
            요청이 있는 경우 또는 판매자회원이 몰을 이 약관 이외의 목적으로 사용한다고 판단되는 경우
            판매자회원에 대하여 특정 서비스의 이용을 제한하거나, 해당 상품 또는 정보를 삭제하고 샵 운영을
            중지시키는 등의 조치를 취할 수 있습니다.
          </li>
          <li>
            ⑤. 회사는 안정적인 서비스 제공을 위해 거래액, 상품의 가격 등을 기준으로 구매자고객에게 제공될
            상품(판매중 및 판매대기 상품을 모두 포함)의 디자인가격에 최대 한도를 정할 수 있습니다.
          </li>
          <li>
            ⑥. 회사는 판매자회원의 회원가입, 정산, 대금지급 등을 위해 필요한 자료를 판매자회원에게 요청할 수
            있으며, 그 정보를 외부로부터 보호하고 해당 목적 외 다른 목적으로 사용하지 않습니다.
          </li>
          <li>
            ⑦. 회사는 판매자회원의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 적절한 모니터링을 실시할
            수 있고, 판매자회원은 회사의 모니터링 업무에 대하여 협조하여야 하며, 회사가 요청하는 필요 최소한의
            증빙자료를 제출할 의무가 있습니다.
          </li>
          <li>
            ⑧. 회사는 본 약관의 위반 행위를 확인되면 해당 판매자회원에게 부가적으로 제공한 혜택의 일부 또는
            전부를 회수하거나, 특정 서비스의 이용을 제한하고, 이용계약을 해지하는 등의 조치를 할 수 있습니다.
          </li>
          <li>
            ⑨. 판매자회원이 본 약관에 따른 의무를 위반하여 회사가 손해를 입은 경우, 판매자회원은 손해배상의
            책임을 부담합니다. 다만, 해당 판매자회원이 자신의 고의 또는 과실 없음을 증명한 때에는 그러하지
            않습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service8">제8조 (개인정보의 보호)</h3>
        <ol>
          <li>
            ①. 판매자회원은 『전자상거래 등에서의 소비자보호에 관한 법률』에 따른 통신판매사업자 신고자에
            해당하지 않으며, 구매자회원의 개인정보는 판매자회원에게 제공되지 않습니다.
          </li>
          <li>
            ②. 판매자회원은 이용신청, 정산 시 허위의 정보를 제공하여서는 아니되며, 회원의 정보를 허위로
            수정하여 회사 또는 제3자에게 손해를 입힌 경우 발생한 모든 손해를 배상하여야 합니다.
          </li>
          <li>
            ③. 회사는 서비스 이용계약을 위하여 회원이 제공한 정보 외에도 수집 및 이용목적 등을 밝히고, 판매자
            회원의 동의를 받아 회원으로부터 필요한 정보를 수집, 이용할 수 있습니다. 회원이 동 정보의 수집 및
            이용에 관한 동의를 거절하는 경우, 회사는 제한되는 서비스 내용을 구체적으로 명시하여야 하며,
            필수수집항목이 아닌 개인정보에 대한 동의 거절을 이유로 서비스 제공을 제한하거나 거절하지 않습니다.
            회원은 본 항에 따라 동의하였더라도 언제든지 그 동의를 철회할 수 있습니다.
          </li>
          <li>
            ④. 회사는 서비스의 제공과 관련하여 알게 된 판매자회원의 개인정보를 이 약관에서 정한 목적 이외의
            용도로 사용하거나 본인의 승낙 없이 제 3자에 누설, 배포하지 않고, 이를 보호하기 위하여 노력하여야
            하며 회원의 개인정보 처리에 관한 사항은 『개인정보 보호법』 및 『정보통신망 이용촉진 및 정보보호
            등에 관한 법률』 등 개인정보의 보호에 관한 관계 법령 및 회사의 개인정보 처리방침에 따릅니다.
          </li>
          <li>
            ⑤. 회사는 관련 법령에 따른 적법한 절차 및 방법에 의거하여 수사기관 등의 요청이 있을 경우, 그 범위
            내의 판매자회원에 관한 개인정보를 제공할 수 있습니다.
          </li>
          <li>
            ⑥. 판매자 회원이 본 조를 위반하거나 회사에 대한 통지를 지연하여 회사 또는 제3자에게 발생한 모든
            손해에 대한 민·형사상의 책임은 판매자회원에게 있으며, 이로 인하여 발생한 모든 불이익에 대하여
            회사에 중대한 귀책사유가 없는 한 회사에게 어떠한 책임도 물을 수 없습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service9">제9조 (서비스의 제공 및 이용료)</h3>
        <ol>
          <li>
            ①. 회사는 판매자회원이 자신의 아이디어, 콘텐츠, 디자인 등을 이용하여 자신만의 상품을 디자인할 수
            있도록 제반 서비스를 제공하며, 상품 제작, 주문 확인, 상품 배송, 고객 응대 등의 전 과정을 진행 및
            관리합니다.
          </li>
          <li>
            ②. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 서비스를 제공하는 것을
            원칙으로 합니다. 그러나, 정기점검이나 시스템상 업그레이드가 요구되는 등 서비스 중단이 불가피할
            경우 회사는 서비스 제공을 일시적으로 중단할 수 있으며, 이 경우 회사는 판매자 센터 등을 통해 서비스
            이용 중단 일정을 사전에 공지할 수 있습니다.
          </li>
          <li>
            ③. 회사에서 지원하는 주문 확인, 상품 제작, 상품 배송, 고객 응대 등의 서비스에 대한 서비스 이용료는
            판매 대금 중 기본가격에 포함됩니다.
          </li>
          <li>
            ④. 회사는 카드수수료 등 공제가 필요한 금액을 판매자회원에게 지급해야 하는 정산 대금에서의 공제
            등으로 징수할 수 있으며, 회사와 판매자회원간의 협의 또는 회사의 내부적인 정책 등에 따라 수수료 및
            징수 방법을 다르게 정할 수 있습니다.
          </li>
          <li>
            ⑤. 회사는 필요한 경우 서비스수수료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 최소 30일 전에
            서면(전자우편 포함) 또는 몰을 통하여 통지하고 협의해야 합니다.
          </li>
          <li>
            ⑥. 본 조 제5항에 의하여 서비스수수료의 변경이 통지된 경우, 이에 동의하지 않는 판매자회원은 본
            계약을 해지할 수 있습니다.
          </li>
          <li>
            ⑦. 회사는 판매자회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항, 서비스
            화면, 전자우편 등의 방법으로 판매자회원에게 제공할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service10">제10조 (서비스 이용의 제한 및 중단)</h3>
        <ol>
          <li>
            ①. 회사는 다음 각 호에 해당하는 경우 특정 서비스의 일부 또는 전부를 일시적 또는 영구적으로 수정,
            중단하거나 변경할 수 있으며 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지
            않습니다.
            <ol>
              <li>1. 기술적 장애, 천재지변, 국가비상사태 등의 사유로 인하여 서비스제공이 불가능한 경우</li>
              <li>
                2. 서비스 이용 설비의 보수 등 공사로 인한 부득이한 경우이거나 기술 진보에 따른 차세대
                서비스로의 전환 필요성이 있는 경우
              </li>
              <li>3. 『전기통신사업법』에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</li>
              <li>
                4. 원활한 서비스 제공의 곤란 또는 수익성 악화되거나 서비스 제공과 관련한 회사 정책의 변경 등
                상당한 이유가 있는 경우
              </li>
              <li>
                5. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스
                이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
              </li>
              <li>6. 기타 불가항력적 사유가 있는 경우</li>
            </ol>
          </li>
          <li>
            ②. 회사는 판매자회원의 서비스 이용이 다음 각 호 중 어느 하나 이상에 해당하는 경우 서비스 제공을
            제한, 중지, 또는 이용계약을 해지할 수 있습니다.
            <ol>
              <li>1. 제5조 제4항의 승낙거부사유가 있음이 확인된 경우</li>
              <li>2. 이 약관에서 규정하는 회원의 의무사항을 위반하는 행위를 하는 경우</li>
              <li>
                3. 수사기관으로부터 수사목적의 요청이 있거나, 방송통신심의위원회, 서울시 전자상거래센터 등의
                기관으로부터 심의결정 또는 서비스 제한 요청이 있는 경우
              </li>
              <li>4. 판매자회원의 서비스 이용이 전체 서비스 시스템 과부하의 원인이 되는 경우</li>
              <li>
                5. 기타 본 약관 또는 회사의 다른 서비스 약관 및 정책 등을 위반하여 회사 또는 다른 회원에게
                손해가 발생하거나 발생할 우려가 있다고 판단되는 경우
              </li>
            </ol>
          </li>
          <li>
            ③. 회사는 서비스의 원활한 운영을 위하여 판매자회원이 등록한 상품이 6개월 이상 판매 이력이 없거나
            또는 판매자회원의 정보관리 이력이 없는 경우 몰에 공지 또는 개별 통지한 후 판매자회원이 등록한
            상품정보를 삭제할 수 있습니다.
          </li>
          <li>
            ④. 회사가 본 조에 따라 서비스를 제한 또는 중단하는 조치를 취하는 경우, 해당 서비스의 내용 및
            사유와 일자 등은 그 중단 전에 몰 초기화면 또는 전자우편 등 판매자회원이 충분히 인지할 수 있는
            방법으로 공지 또는 통지합니다. 단, 판매자회원과의 연락이 불가능하거나 긴급을 요하는 등 부득이한
            경우, 회사는 사후적으로 통보할 수 있습니다.
          </li>
          <li>
            ⑤. 본 조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우, 판매자회원은 회사의 통지를 받은
            날로부터 30일 이내에 이에 대하여 항변할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service11">제11조 (지식재산권보호 및 권리침해신고 등)</h3>
        <ol>
          <li>
            ①. 회사는 서비스를 이용함에 있어 저작권자의 저작권 보호를 위한 정책을 수립하여 운영하며
            판매자회원은 회사의 저작권 정책을 준수하여야 합니다.
          </li>
          <li>
            ②. 판매자회원은 제3자의 지식재산권을 사용하게 되는 경우 당해 권리자로부터 사용에 대한 허락을
            취득한 후에 사용해야 하며 상품의 등록 및 판매 등과 관련하여 제3자의 상표권, 특허권, 저작권,
            성명권, 초상권 등 제반 지식재산권을 침해하지 않아야 합니다.
          </li>
          <li>
            ③. 회사는 판매자회원이 서비스에 등록∙사용한 아이디어, 디자인, 컨텐츠 또는 상품정보 및 제반 정보에
            대하여 제3자로부터 권리침해의 주장이 있을 경우, 회사는 내부 운영정책에 따른 조치(특정 서비스의
            이용을 제한하거나, 해당 상품 또는 정보를 삭제하고 샵 운영을 중지시키는 등의 조치)를 취할 수
            있습니다.
          </li>
          <li>
            ④. 전항과 관련하여 회사는 판매자회원에게 소명자료를 요청할 수 있으며, 판매자회원은 이에 적극
            협조하여야 합니다. 단, 판매자회원이 제출한 자료가 법원의 확정판결 등 회사가 인정하는 자료가 아닌
            경우 회사는 상품 삭제 및 등록 제한 상태를 유지할 수 있습니다.
          </li>
          <li>
            ⑤. 판매자회원이 몰에 등록한 아이디어, 컨텐츠, 디자인, 상품 등에 대한 저작권은 판매자회원에게
            귀속됩니다. 이와 관련하여 회사가 판매자회원의 저작권 등 제3자의 권리침해 행위로 민사, 형사 또는
            행정상 분쟁이 발생하는 경우, 판매자회원은 회사를 면책시키기 위하여 적극적으로 할 수 있는 모든
            조치를 취하여야 하고, 만약 회사가 이로 인하여 민사, 형사 또는 행정상 책임을 부담하게 되는 경우,
            회사는 판매자회원에게 그로 인한 모든 손해의 배상을 청구하고 관련 비용을 구상할 수 있습니다.
          </li>
          <li>⑥. 회사가 작성한 저작물에 대한 저작권 기타 지식재산권은 회사에 귀속합니다.</li>
          <li>
            ⑦. 판매자회원은 회사의 서비스를 이용함으로써 얻은 정보를 회사의 사전 서면 승낙 없이 복제, 송신,
            출판, 배포, 방송 기타 방법에 의하여 이용하거나 제3자에게 이용하게 하는 경우 그에 대한 책임은
            판매자회원 본인에게 있습니다.
          </li>
          <li>
            ⑧. 판매자회원은 유무상을 불문하고 회사가 제공하는 저작물(회사가 본 서비스를 통하여 제공하는
            이미지, 영상, 로고, 템플릿, 슬로건 등을 포함하나 이에 한정하지 않음)을 제3자에게 배포하거나 그
            소유권을 이전하여 줄 수 없으며 이를 재판매하거나 공유하는 행위 역시 모두 금지됩니다. 다만
            판매자회원이 회사로부터 사전에 서면 허가를 받은 경우, 해당 판매자회원은 제3자에게 회사가 제공한
            저작물을 배포할 수 있습니다.
          </li>
          <li>
            ⑨. 판매자회원의 아이디어, 컨텐츠, 디자인을 회사가 상품으로 제작하는 과정에서 회사가 디자인을 수정,
            개선 또는 변형하거나 그 외 창작에 기여를 하였다고 인정될만한 행위를 하여 새로운 저작물이 창작된
            경우, 해당 저작물에 대한 지식재산권, 상표권 등 기타 권리는 판매자회원과 회사의 각 기여도에 따라
            귀속 주체가 달라질 수 있고, 회사와 판매자회원은 이와 관련한 구체적인 조건을 별도의 약정을 통하여
            정할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service12">제12조 (신고센터제도)</h3>
        <ol>
          <li>
            ①. 구매자회원 또는 제3자가 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인한 법률상 이익 침해를
            근거로 회사에 대하여 불만을 제기할 경우, 회사는 이를 원활하게 해결하기 위하여 신고센터제도를
            운영합니다.
          </li>
          <li>
            ②. 구매자회원 또는 제3자는 판매자회원의 아이디어, 컨텐츠, 디자인 등으로 인하여 침해된 법률상
            이익을 구체적으로 작성하여 신고센터제도에 불만을 접수할 수 있으며, 해당 불만은 접수 즉시
            판매자회원에게 공유됩니다. 판매자회원은 구매자회원 또는 제3자의 불만을 공유받은 날로부터 2 영업일
            이내에 이에 대하여 소명하여야 합니다.
          </li>
          <li>
            ③. 회사는 불만이 접수된 날로부터 [●] 영업일 이내에 해당 불만의 구체성, 합리성, 판매자회원의 소명
            내용 등을 종합적으로 판단하여 해당 아이디어, 컨텐츠, 디자인 등이 사용된 상품의 판매를 중단하거나
            이를 샵으로부터 삭제하는 등의 조치를 취할 수 있습니다.
          </li>
          <li>
            ④. 판매자회원은 전항의 회사의 조치가 있은 날로부터 14일 이내에 회사에 대하여 이의를 제기할 수
            있습니다. 판매자회원이 14일 동안 이의를 제기하지 않는 경우 회사는 전항에 따른 조치를 확정하며,
            해당 판매자회원은 이로 인하여 발생하는 손해에 대하여 회사에게 그 어떠한 책임도 물을 수 없습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service13">제13조 (정산)</h3>
        <ol>
          <li>
            ①. 정산은 월 1회 실시를 원칙으로 하며, 해당월1일부터 10일까지의 신청건에 한하여 당월 지급합니다.
          </li>
          <li>②. 판매자회원은 몰에서 제공하는 페이지에서 판매 현황을 확인할 수 있습니다.</li>
          <li>
            ③. 회사는 판매자회원에게 당월 정산 대금에 대한 정산 자료를 익월 영업일 기준 5일 이내로 몰의 정산
            관련 페이지를 통해 제공합니다.
          </li>
          <li>④. 본 조 제3항의 당월 정산 대금은 당월에 출고된 주문을 기준으로 계산합니다.</li>
          <li>
            ⑤. 몰을 통해 판매된 상품이 구매확정되는 경우, 전체 판매 대금 중 상품의 기본가격을 제외한
            판매자회원이 산정한 디자인가격에 대한 금액에서 카드 수수료, 사업자가 없는 회원에 한하여
            사업소득세에 대한 원천징수액 등 공제가 필요한 금액을 제한 나머지 금액을 판매자회원에게 지급합니다.
          </li>
          <li>
            ⑥. 세금계산서 발행이 가능한 사업자를 보유한 판매자회원은 회사에게 회사로부터 지급받을 금액에 대한
            세금계산서를 익월 10일까지 발행합니다.
          </li>
          <li>
            ⑦. 회사는 당월 정산 금액과 판매자회원이 발급한 세금계산서 금액이 일치할 경우, 판매자회원이 본 약관
            7조 6항에 따라 회사에 제공한 결제 계좌로 출금 신청이 마감된 10일 이후 7 영업일 이내에 그 금액을
            송금합니다. 단, 판매자회원이 지정한 입금계좌의 예금주는 판매자회원과 동일인이어야 하며(단, 제5조
            제3항에 따라 법정대리인이 정산을 받는 경우 예외로 합니다), 해당 일이 회사의 영업일이 아닌 경우
            이후 최초 영업일에 송금합니다.
          </li>
          <li>
            ⑧. 회사는 판매자회원이 정산받을 금액이 [1]만원 미만일 경우, 정산을 유예하고 다음 정산 금액에
            합산하여 정산할 수 있습니다
          </li>
          <li>⑨. 판매자회원이 병역 근무 중인 경우, 정산이 제한될 수 있습니다.</li>
          <li>
            ⑩. 판매자회원 가입 후 처음 정산요청 가능일자로부터 [60]개월내 출금요청을 하지 아니하였을 경우,
            출금 신청 가능한 수익은 자동으로 소멸합니다.
          </li>
          <li>
            ⑪. 정산요청 관련 서류 누락, 계좌번호 오기입 등 판매자 회원의 귀책사유로 정산대금의 지급이 실패한
            경우, 그 대금에 대한 당월 지급은 불가하며, 판매자회원은 익월 재신청해야합니다.
          </li>
          <li>
            ⑫. 판매자회원은 원활한 정산 처리를 위해 회사가 별도의 공지사항 혹은 이용안내로 고지한 방법을 통해
            계좌정보 등 정산금 지급에 필요한 정보 기재 후 일정한 방법을 통해 계좌정보등록을 하여야 합니다.
            해당 절차를 모두 완료한 경우 판매자회원과 회사는 정산계약을 체결한 것으로 간주합니다.
          </li>
          <li>
            ⑬. 판매자회원이 미성년자인 경우 법정대리인(부모 등)의 동의를 받아 정산계약을 체결할 수 있으며,
            회사가 지정 및 안내하는 방법에 따라 이용 동의를 하여야 합니다.
          </li>
          <li>
            ⑭. 구매자회원으로부터 주문취소/환불 건이 발생하는 경우, 발생 시기에 따라 아래와 같이 반영합니다.
            <ol>
              <li>1. 당월 정산 금액 마감 전 발생하는 경우, 당월 정산에 반영하여 정산합니다.</li>
              <li>
                2. 당월 정산 금액 마감 후 발생하는 경우, 당월 정산에는 반영하지 아니하며 익월 정산에 반영하여
                정산합니다.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service14">제14조 (정산의 보류)</h3>
        <ol>
          <li>
            ①. 회사는 판매자회원의 귀책사유로 인해 발생한 비용을 정산 대금 정산 시 상계한 뒤 지급할 수
            있습니다.
          </li>
          <li>
            ②. 판매자회원의 서비스 중지 또는 이용계약 해지 후 당월 판매자회원의 판매대금의 일정 비율에
            해당하는 금액을 일정기간 동안 예치하여 동 기간 동안 구매한 구매자회원으로부터 오류 수정, 환불 등의
            청약 철회 요구가 발생하는 경우 관련 비용의 지급에 사용할 수 있습니다.
          </li>
          <li>
            ③. 판매자회원의 채권자의 신청에 의한 판매대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을
            경우, 회사는 판매자회원과 채권자 간의 합의 또는 채무액의 변제 등으로 동 결정이 해제될 때까지
            판매대금의 ‘정산’을 중지하거나 채권자에게 변제 또는 공탁할 수 있습니다.
          </li>
          <li>
            ④. 본 조에서 정한 사항 외에도 법률의 규정에 의하거나 합리적인 사유가 있는 경우에는 회사는
            판매자회원에게 이를 통지하고 판매대금의 전부 또는 일부에 대한 정산을 일정 기간 유보할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service15">제15조 (결제대금보호서비스)</h3>
        <ol>
          <li>
            ①. 회사는 몰을 통해 서비스를 제공하는 과정에서 대금의 수령, 보관 및 송금업무로 이루어지는
            결제대금보호 서비스(이하 구매안전서비스)를 제공합니다.
          </li>
          <li>
            ②. 회사가 제공하는 구매안전 서비스는 몰과 서비스를 통한 모든 거래에 적용됩니다. 판매자회원이
            구매안전 서비스를 통하지 않는 직거래를 유도하는 경우, 회사는 해당 거래를 취소하거나, 샵 운영을
            중지시키는 등 기타 필요한 조치를 취할 수 있습니다.
          </li>
          <li>
            ③. 회사가 제공하는 구매안전 서비스를 이용하지 않은 거래 및 물품 또는 구매안전 서비스가 종결된
            이후의 거래와 관련하여 발생한 모든 사항에 대하여는 회사는 그 어떠한 책임도 부담하지 않습니다.
          </li>
          <li>
            ④. 판매자회원은 회사가 제공하는 서비스를 이용함에 있어서 구매안전 서비스의 이용과 그 규칙에
            동의하여야 합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service16">제16조 (양도금지)</h3>
        <ol>
          <li>
            ①. 판매자회원은 회사의 사전 서면 동의 없이 이 약관에 따른 일체의 권리와 의무를 제3자에게
            양도하거나 담보의 목적으로 제공할 수 없습니다.
          </li>
          <li>
            ②. 회사는 판매자회원이 본 조를 위반하는 경우 서비스 제공을 거부할 권한을 가지며, 기존 판매자회원에
            대하여 이용계약을 해지할 수 있습니다. 다만, 회사가 이용계약해지의 조치를 취하는 경우 회사는
            이용계약해지의 사유를 명시하여 판매자회원에게 전자우편, 전화 등으로 개별 통지합니다.
            판매자회원에게 항변의 사유가 있는 경우, 판매자회원은 회사의 통지를 받은 날로부터 30일 이내에 이에
            대하여 항변할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service17">제17조 (비밀유지)</h3>
        <ol>
          <li>
            ①. 판매자회원은 구매자회원의 정보 등 몰의 이용과 관련해 취득한 일체의 정보를 회사의 서면 동의 없이
            외부에 유출하거나 이 약관 이 외의 목적으로 사용할 수 없습니다.
          </li>
          <li>②. 전항의 의무는 본 이용계약의 종료 후에도 존속합니다.</li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service18">제18조(준거법 및 관할법원)</h3>
        <ol>
          <li>
            이 약관의 해석 및 분쟁의 해결은 대한민국의 법령에 따르며, 소송이 제기되는 경우에는 민사소송법에
            따라 관할 법원을 정합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph btn_prev">
        ${
          store_name
            ? html`<a href="/${T.lang}/${UtilS.escape(store_name)}/settings/terms/20200113"
                >- 이전 셀러스튜디오 이용약관 보기</a
              >`
            : html`<a href="/${T.lang}/@/studio/terms/20200113">- 이전 셀러스튜디오 이용약관 보기</a>`
        }
      </div>
    </div>
  </div>
`;

export const makeStudioTerms20200707Html = () => legacyHtml`
  <div class="body terms_body">
    <div class="title">
      <h2>셀러스튜디오 이용약관</h2>
    </div>
    <div class="text_body">
      <div class="paragraph">
        <h3 id="service1">제1조(목적)</h3>
        <p>
          본 약관은 (주)마플코퍼레이션 사(이하 ‘회사’)가 운영하는 ‘마플샵’(이하 ‘몰’)에서 전자상거래 관련
          서비스 및 기타 서비스(이하’서비스’라 함)를 이용하기 위하여 몰에 ‘셀러’(이하 ‘판매자회원’) 자격으로 가입한 자와 회사 간의 권리, 의무 및 제반 절차를 확정하고 이를 이행함으로써
          상호 발전을 도모하는 것을 그 목적으로 합니다.
        </p>
      </div>
      <div class="paragraph">
        <h3 id="service2">제2조(정의)</h3>
        <p>본 "약관"에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <ol>
          <li>
            ①. ‘판매자회원’이란 본 계약에 따라 ‘회사’와 ‘몰’, ‘서비스’ 이용계약을 체결하고, ‘몰’을 통해 디자인
            한 자신만의 상품을 판매하는 자를 의미합니다.
          </li>
          <li>
            ②. 판매자회원의 ‘아이디어, 콘텐츠, 디자인’ 이라 함은 이미지, 영상, 그림, 음성, 음향, 음원, 폰트,
            문자, 문서, 부호, 성명, 예명, 초상 등으로 표현된 ‘판매자회원’의 저작물 또는 정보로서 그 이용에
            있어서 사용자의 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다. ‘판매자회원’은
            자신의 아이디어, 콘텐츠, 디자인을 통해 자신이 판매할 ‘상품’을 ‘몰’을 통해 디자인하거나 이를 ‘몰’
            안에서 자신의 ‘샵’을 꾸미고 ‘상품’을 홍보하는데 사용할 수 있습니다.
          </li>
          <li>
            ③. ‘샵’이란 ‘판매자회원’이 자신의 ‘상품’을 ‘구매자회원’에게 소개하고 판매할 수 있도록 하는
            전자상거래 플랫폼을 의미합니다.
          </li>
          <li>
            ④. ‘상품’이란 의류, 핸드폰케이스, 악세사리 등 ‘회사’에서 제공해주는 ‘제품’에 ‘판매자회원’이 보유한
            ‘아이디어, 콘텐츠, 디자인’을 조합하여 자신만의 굿즈로 만들어 ‘몰’을 통해 판매하는 목적물을
            의미합니다.
          </li>
          <li>
            ⑤. ‘몰’이란 ‘판매자회원’이 자신만의 ‘상품’을 ‘몰’을 통해 디자인하여 국내외에서 ‘구매자회원’에게
            판매 할 수 있도록 돕고 정산 등의 전자상거래를 지원하는 플랫폼을 의미합니다.
          </li>
          <li>
            ⑥. ‘시스템’이라 함은 ‘몰’을 위하여 ‘회사’, ‘판매자회원’ 또는 ‘회사’와 ‘판매자회원’, 또는 ‘회사’ 가
            ‘회사’의 제휴 파트너와 공동으로 개발 구축하여 운영하는 모바일 어플리케이션, 웹 및 이와 동일한
            목적을 가진 매체 혹은 서버의 등의 장치를 말 합니다.
          </li>
          <li>⑦. ‘구매자회원’이란 ‘몰’을 통해 ‘상품’을 구매하는 이용자를 의미합니다.</li>
          <li>
            ⑧. ‘회사’에서 제공하는 ‘서비스’라 함은 ‘몰’을 통해 ‘판매자회원’이 자신의 ‘상품’을 판매할 수 있도록
            지원하는 것과 ‘상품’의 디자인을 제외한 주문 확인, 상품 제작, 상품 배송, 등 ‘구매자회원’의
            구매행위와 관련된 과정들을 지원하는 것을 의미합니다.
          </li>
          <li>
            ⑨. ‘판매 대금’이란 ‘구매자회원’이 ‘상품’을 구매한 대가로 지불하는 금액을 말합니다. ‘판매 대금’은
            ‘회사’가 책정한 ‘기본가격’에 ‘판매자회원’이 책정한 ‘디자인가격’을 더하여 산정됩니다.
          </li>
          <li>
            ⑩. ‘기본가격’이란 ‘구매자회원’에게 제공하는 ‘판매 대금’ 중 회사, ‘몰’에서 제작한 ‘상품’ 및 ‘몰’의
            개발, 유지, 관리 등 상품 공급과 관련된 제반 서비스에 대한 가격을 의미 합니다.
          </li>
          <li>
            ⑪. ‘디자인가격’이란 ‘판매 대금’ 중 ‘판매자회원’이 책정한 자신의 디자인에 대한 가격을 의미합니다.
          </li>
          <li>
            ⑫. ‘정산 대금’이란 ‘판매 대금’ 중 ‘구매자회원이’ 책정한 자신의 디자인가격에 대한 금액에, 카드 결제
            수수료 등 공제가 필요한 부분대한 금액을 차감한 후 ‘회사’로부터 지급 받아야하는 금액을 의미합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service3">제 3 조 (약관의 게시 및 개정)</h3>
        <ol>
          <li>
            ①. 회사는 이 약관을 판매자회원이 쉽게 알 수 있도록 몰 초기화면 또는 연결화면을 통하여 게시합니다.
          </li>
          <li>
            ②. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관을 개정할 수 있으며, 이 경우
            개정내용과 적용일자를 명시하여 몰을 통해 그 적용일자 7일 전부터 적용일자 전일까지 공지합니다.
            다만, 변경 내용이 판매자에게 불리한 변경의 경우에는 개정약관의 적용일자 30일전부터 적용일자까지
            공지합니다.
          </li>
          <li>
            ③. 판매자회원이 개정약관에 동의하지 않는 경우에는 개정약관의 적용일 이전에 거부의사를 표시하고 이
            약관에 의한 이용계약을 해지할 수 있습니다.
          </li>
          <li>
            ④. 회사가 본 조 제 2항에 따라 개정약관을 공지 또는 통지하면서 판매자회원에게 적용일전까지
            의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
            판매자회원이 명시적으로 거부의사를 표명하지 아니한 경우 개정약관에 동의한 것으로 봅니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service4">제4조 (약관의 효력)</h3>
        <ol>
          <li>
            ①. 회사는 이 약관에 규정되지 않은 세부적인 내용에 대해 개별 운영 정책 등(이하 ‘운영정책‘이라 함)을
            제정하여 운영할 수 있으며, 해당 내용을 몰을 통하여 게시합니다. 운영정책은 이 약관과 더불어 서비스
            이용계약(이하 ‘이용계약‘이라 함)의 일부를 구성합니다.
          </li>
          <li>
            ②. 회사는 서비스 중 특정 서비스에 관한 약관(이하 ‘개별약관‘이라 함)을 별도로 제정할 수 있으며,
            판매자회원이 개별약관에 동의한 경우 개별약관은 이용계약의 일부를 구성하고 개별약관에 이 약관과
            상충하는 내용이 있을 경우 개별 약관이 우선적으로 적용됩니다.
          </li>
          <li>
            ③. 본 약관에 의해 판매자회원으로 가입하고자 하는 자는 구매회원이용약관의 내용을 숙지하고
            구매회원과 회사간의 권리∙의무관계에 대해 동의함을 확인합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service5">제5조 (이용계약의 성립)</h3>
        <ol>
          <li>
            ①. 이용약관은 서비스를 이용하고자 하는 자(이하 ‘이용신청자‘라 함)가 이 약관에 동의하고 회사가 정한
            절차에 따라 판매자회원 가입을 신청하며, 이에 대해 회사가 심사를 거쳐 승낙함으로써 성립합니다.
            회사는 이용승낙의 의사표시를 해당 서비스 화면에 게시하거나 email 또는 기타 방법으로 할 수
            있습니다.
          </li>
          <li>
            ②. 이용신청자는 회사가 별도로 요청하는 증빙서류가 있는 경우 신속히 제출하여야 하며, 회사는 해당
            서류를 징구하는 시점까지 가입신청에 대한 승낙을 보류하거나 거부할 수 있습니다.
          </li>
          <li>
            ③. 판매자회원 가입은 만 14세 이상의 개인(개인 사업자 포함) 또는 법인사업자가 할 수 있으며, 만 19세
            미만의 판매자회원 가입신청에 대해서 회사가 법정대리인의 동의서 및 입증자료의 제출을 요구하는 경우
            이용신청자는 이에 적극 협조하여야 합니다.
          </li>
          <li>
            ④. 회사는 본 조 제1항에 따라 판매자 회원가입을 신청 한 자 중에서 아래 각호에 해당되는 경우에는
            승인을 거부할 수 있으며, 등록이 된 이후에도 아래 각호의 사유가 확인된 경우에는 승낙을 취소할 수
            있습니다.
            <ol>
              <li>
                A. 가입을 신청한 자가 이 약관 및 회사의 다른 서비스와 관련하여 이전에 회원 자격을 상실한 적이
                있는 경우
              </li>
              <li>
                B. 실명이 아니거나 타인의 정보 등(예시: 주민등록번호, 사업자번호, I-PIN 등)를 이용한 경우
              </li>
              <li>C. 허위 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
              <li>
                D. 회사로부터 자격 정지 조치 등을 받은 판매자회원이 그 조치기간 중에 이용계약을 임의 해지하고
                재이용신청을 하는 경우
              </li>
              <li>E. 기술적 문제 등으로 이용신청에 대한 승낙이 어려운 경우</li>
              <li>
                F. 가입을 신청한 자의 귀책사유로 인하여 승인이 불가능하거나 필수 서류 또는 회사가 요청하는
                서류를 제출하지 않는 등 기타 제반 사항을 위반하여 신청하는 경우
              </li>
              <li>
                G. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청 등 회사가 합리적인 판단에 의하여
                필요하다고 인정하는 경우
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service6">제6조 (판매자회원의 책임 및 관리 의무)</h3>
        <ol>
          <li>
            ①. 판매자회원은 회원가입정보와 관련하여 주소지 또는 대금결제를 위한 판매자회원 명의의 통장계좌
            등의 변경이 있을 경우, 즉시 회사에 이를 통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한
            손해에 대하여 책임을 지지 않습니다.
          </li>
          <li>
            ②. 판매자회원은 관련 법령, 약관, 운영정책, 이용안내 및 회사가 공지하거나 통지한 사항 등을
            준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
          </li>
          <li>
            ③. 판매자회원은 다음 각 호에 해당하는 행위를 하여서는 아니되며, 이를 위반한 경우 회사는 서비스
            이용정지 및 이용계약해지 등의 조치를 취할 수 있습니다. 서비스 이용정지의 구체적인 기준 등에
            대해서는 운영정책에서 정합니다.
            <ol>
              <li>
                A. 일반 사항
                <ol>
                  <li>1. 서비스를 통해 음란정보, 거짓정보 등 유해한 정보를 게재하거나 링크하는 행위</li>
                  <li>2. 범죄행위와 결부되는 모든 행위 및 기타 관계법령에 해당하는 행위</li>
                  <li>3. 해킹, 컴퓨터 바이러스 유포, 서버 공격 등으로 타인과 회사에 해가 되는 경우</li>
                  <li>
                    4. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 수신자의 의사에 반하여
                    광고성 정보를 지속적으로 전송하는 행위
                  </li>
                  <li>5. 통신판매 이외의 목적으로 서비스를 이용하는 행위</li>
                  <li>6. 회사의 서비스를 방해하거나 장애를 발생시킬 수 있는 모든 행위</li>
                  <li>
                    7. 이 약관에 따른 권리와 의무를 회사의 사전 서면 동의 없이 타인에게 양도, 증여하거나 이를
                    담보제공하는 행위
                  </li>
                </ol>
              </li>
              <li>
                B. 상품등록 및 판매 관련 금지행위
                <ol>
                  <li>
                    1. 가족 또는 지인 등 타인의 계정을 이용하여 판매자 본인의 상품을 구매하는 등의 부정 거래
                    행위
                  </li>
                  <li>
                    2. 회사의 결제대금보호서비스를 통하지 않는 직거래 유도행위 및 구매회원의 직거래 요청을
                    수락하는 행위
                  </li>
                  <li>
                    3. 허가없이 타인의 콘텐츠, 디자인, 상표, 로고를 사용하는 등 타인의 지식재산권을 침해하는
                    행위
                  </li>
                  <li>4. 상품 등록 시 상품과 상관없는 상표명을 사용하거나 중복해서 사용하는 행위</li>
                  <li>
                    5. 상품 등록 시 유해하거나 타인에게 불쾌감을 줄 수 있는 부적절한 콘텐츠, 디자인을 사용하는
                    행위
                  </li>
                  <li>
                    6. 타인이 창작한 이미지나 문구 또는 타인이 촬영하여 제작한 사진 등 타인의 창작물을
                    무단으로 사용하는 행위
                  </li>
                  <li>7. 연예인 사진 등 타인의 초상권 및 성명권을 침해하는 행위</li>
                  <li>8. 관련법령에 위반되는 디자인이나 콘텐츠가 담긴 상품을 판매하는 행위</li>
                  <li>9. 상품 부정등록 등 기타 비정상적인 방법으로 상품을 등록하는 행위</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            ④. 기타 관련 법령 및 회사에서 제공한 이용약관 및 개별 약관의 변경, 판매자회원 공지사항 등의 확인
            의무를 게을리해 발생한 판매자회원의 피해에 대해서는 회사는 책임을 지지 않습니다.
          </li>
          <li>
            ⑤.. 회사에서 제공하는 마플 서비스에 가입된 판매자회원의 경우, 현 약관을 별도로 확인하고 동의한
            자에 한해서만 마플 서비스 계정으로 몰 이용이 가능합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service7">제7조 (회사의 권리와 의무)</h3>
        <ol>
          <li>
            ①. 회사는 판매자회원으로부터 제기되는 불편사항 및 서비스의 문제점에 대해 합리적이라고 판단되는
            경우 우선적으로 해당 문제를 해결하며, 신속한 해결이 곤란한 경우에는 판매자회원에게 그 사유와
            처리절차를 안내합니다.
          </li>
          <li>
            ②. 회사는 판매자회원이 몰에 등록한 상품정보, 회사와의 인터뷰 내용 등을 포함하고 이에 한정되지
            않으며 회사를 통 하거나 회사와 협업하여 생성된 콘텐츠 등을 몰 내의 콘텐츠 및 몰과 관련된 서비스의
            마케팅 목적을 위해 몰의 공식 SNS 계정 포스팅 등 몰의 콘텐츠로서 사용할 수 있습니다. 회사가
            필요하다고 판단한 경우 회사는 본 항에 언급된 콘텐츠를 수정/편집하여 활용할 수 있습니다. 인터뷰의
            경우, 셀러가 되고자 하는 이유, 목표, 홍보 방안, 회사가 제공하는 서비스에 대한 평가 등에 대해
            인터뷰를 진행하고 콘텐츠 활용에 도움을 주신 판매자회원에게 그에 대한 소정의 원고료를 지불합니다.
          </li>
          <li>
            ③. 회사는 판매자회원의 서비스이용과 관련한 자료를 수사기관의 수사목적의 요청 및 기타 공공기관이
            관련 법률에 따른 절차를 통해 요청하는 경우 판매자회원의 동의없이 해당 기관에 제공할 수 있습니다.
          </li>
          <li>
            ④. 회사는 판매자회원이 몰에 등록한 상품 및 그 정보 등이 불법정보에 해당한다고 판단되거나,
            방송통신심의위원회 등 관련기관으로부터 요청이 있는 경우 또는 판매자회원이 몰을 이 약관 이외의
            목적으로 사용한다고 판단되는 경우 판매자회원에 대한 사전통보없이 해당 정보를 삭제할 수 있습니다.
          </li>
          <li>
            ⑤. 판매자회원은 몰에 등록된 로그인 정보(계정 및 비밀번호)를 관리할 책임이 있으며, 회사는
            판매자회원의 귀책사유에 따른 로그인 정보의 분실 및 제3자 사용 등과 관련하여 발생하는 손해에
            책임지지 않습니다.
          </li>
          <li>
            ⑥. 판매자회원은 몰 서비스 운영에 필요한 정보 및 증빙자료의 제공을 회사가 요청하는 경우 이에 성실히
            응해야 합니다.
          </li>
          <li>
            ⑦. 판매자회원은 회사가 몰 등을 통해 게시 및 안내하는 서비스 운영 정책 및 공지사항 등을 정확히
            숙지해야 하며, 이를 확인하지 않거나 오인하여 발생하는 손해에 대해서는 회사는 어떠한 책임도
            부담하지 않습니다.
          </li>
          <li>
            ⑧. 회사는 안정적인 서비스 제공을 위해 거래액, 상품의 가격 등을 기준으로 구매자고객에게 제공될
            상품(판매중 및 판매대기 상품을 모두 포함)의 디자인가격에 최대 한도를 정할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service8">제8조 (개인정보의 보호)</h3>
        <ol>
          <li>
            ①. 판매자회원은 통신판매사업자 신고자가 아니므로 「전자상거래 등에서의 소비자보호에 관한 법률」
            의거하여 판매자회원에게 구매자회원 등 타인의 개인정보가 공개되지 않습니다.
          </li>
          <li>
            ②. 판매자회원이 본 조를 위반하여 구매자회원 등과 회사 간 분쟁이 발생하는 경우 판매자 회원은 자신의
            노력과 비용으로 회사를 면책시켜야 하며, 민/형사 상 일체의 법적 책임을 부담하여야 합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service9">제9조 (서비스의 제공 및 이용료)</h3>
        <ol>
          <li>
            ①. 회사가 제공하는 몰은 판매자회원이 자신의 아이디어, 콘텐츠, 디자인 등을 통해 자신만의 굿즈를
            디자인하고 이를 구매자회원에게 판매할 수 있도록 제반 서비스를 제공합니다. 회사는 주문 확인, 상품
            제작, 상품 배송, 고객 응대 등의 과정들을 서비스하며, 판매자회원이 굿즈 디자인에 사용한 아이디어,
            콘텐츠, 디자인 등과 관련해서 회사는 일체의 책임을 지지 않습니다.
          </li>
          <li>
            ②. 판매자회원의 콘텐츠, 디자인 등으로 인하여 지식재산권 침해 등의 분쟁이 발생한 경우, 그 분쟁의
            결과로 인한 모든 책임은 판매자회원이 부담합니다. 또한 제 3자가 위와 같은 사유로 회사를 상대로
            민형사상 등의 문제를 제기하는 경우 판매자회원은 해당 문제 해결을 위해 적극 협조해야 하며, 이와
            관련해 회사에 손해가 발생한 경우 손해를 배상합니다. 회사는 분쟁의 합리적이고 원활한 조정을 위해
            회사가 운영하는 고객센터를 통해 예외적으로 당해 분쟁에 개입할 수 있으며, 판매자회원은 고객센터의
            결정을 신의원칙에 입각해 최대한 존중해야 합니다.
          </li>
          <li>
            ③. 회사는 몰에서 제공하는 서비스에 한하여, 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일
            24시간 서비스 제공을 원칙으로 하지만, 정기점검이나 시스템의 업그레이드가 필요한 날이나 시간에
            서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인해 서비스를 일시 중단하는 경우 판매자
            센터 등을 통해 사전에 공지합니다.
          </li>
          <li>
            ④. 회사에서 지원하는 주문 확인, 상품 제작, 상품 배송, 고객 응대 등의 서비스에 대한 서비스이용료는
            판매 대금 중 기본가격에 포함합니다.
          </li>
          <li>
            ⑤. 회사는 구매자회원의 구매 과정에서 발생되는 카드 결제 수수료에 대해 판매자회원에게 부과하며,
            구체적인 사항, 요율 등에 대해서는 몰을 통해 공지합니다.
          </li>
          <li>
            ⑥. 회사는 카드수수료 등 공제가 필요한 금액을 판매자회원에게 지급해야 하는 정산 대금에서의 공제
            등으로 징수할 수 있으며, 회사와 판매자회원간의 협의 또는 회사의 내부적인 정책 등에 따라 수수료 및
            징수 방법을 다르게 정할 수 있습니다.
          </li>
          <li>
            ⑦. 회사는 필요한 경우 서비스수수료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 최소 15일 전에
            서면(이메일 포함) 또는 몰을 통하여 통지하고 협의해야 합니다.
          </li>
          <li>
            ⑧. 본 조 7항에 의하여 서비스수수료의 변경이 통지 된 경우, 이에 동의하지 않는 판매자는 본 계약을
            해지 할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service10">제10조 (서비스의 중단)</h3>
        <ol>
          <li>
            ①. 회사는 통제할 수 없는 기술적 장애, 기간통신사업자 등 제 3자의 귀책사유 및 천재지변,
            국가비상사태 등의 사유로 인하여 서비스제공이 불가능한 경우 서비스 제공을 일시 중단할 수 있습니다.
          </li>
          <li>
            ②. 회사는 판매자회원의 서비스 이용이 다음 각 호 중 어느 하나 이상에 해당하는 경우 사전통지 없이
            서비스 제공을 중단하거나 이용계약을 해제하는 등의 조치를 취할 수 있습니다.
            <ol>
              <li>1. 이 약관에서 규정하는 회원의 의무사항을 위반하는 행위를 하는 경우</li>
              <li>
                2. 수사기관으로부터 수사목적의 요청이 있거나, 방송통신심의위원회, 서울시전자상거래센터 등의
                기관으로부터 심의결정 또는 서비스 제한 요청이 있는 경우
              </li>
              <li>3. 판매자회원의 서비스 이용이 전체 서비스 시스템 과부하의 원인이 되는 경우</li>
              <li>
                4. 이 약관 또는 회사의 다른 서비스 약관 및 정책 등을 위반하여 회사 또는 다른 회원에게 손해가
                발생하거나 발생할 우려가 있다고 판단되는 경우
              </li>
            </ol>
          </li>
          <li>
            ③. 회사는 서비스의 원활한 운영을 위하여 판매자회원이 등록한 상품이 6개월 이상 판매 이력이 없거나
            또는 판매자회원의 정보관리 이력이 없는 경우 몰에 공지 또는 개별 통지한 후 판매자회원이 등록한
            상품정보를 삭제 할 수 있습니다.
          </li>
          <li>
            ④. 회사는 본 조에 따라 서비스를 중단하는 경우 이러한 사실을 몰 또는 전자우편 등의 방법으로 공지
            또는 통지합니다. 단, 기술적 장애 사유 등의 경우에는 사후에 통지할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service11">제11조 (지식재산권보호 및 권리침해신고 등)</h3>
        <ol>
          <li>
            ①. 판매자회원은 제3자의 지식재산권을 사용하게 되는 경우 당해 권리자로부터 사용에 대한 허가를
            취득한 후에 사용해야 하며 상품의 등록 및 판매 등과 관련하여 제3자의 상표권, 특허권, 저작권,
            성명권, 초상권 등 제반 지식재산권을 침해하지 않아야 합니다.
          </li>
          <li>
            ②. 회사는 판매자회원이 서비스에 등록∙사용한 상품정보 및 제반 정보에 대하여 제3자로부터 권리침해의
            주장이 있을 경우 해당 정보를 삭제하고 재등록을 제한하는 등의 조치를 취할 수 있습니다.
          </li>
          <li>
            ③. 전항과 관련하여 회사는 판매자회원에게 소명자료를 요청할 수 있으며, 판매자회원은 이에 적극
            협조하여야 합니다. 단, 판매자회원이 제출한 자료가 법원의 확정판결 등 회사가 인정하는 자료가 아닌
            경우 회사는 상품 삭제 및 등록 제한 상태를 유지할 수 있습니다.
          </li>
          <li>
            ④. 판매자회원은 몰에 등록한 상품 및 정보와 관련하여 제3자로부터 분쟁이 발생하는 경우 회사를
            면책시키고 그로 인해 발생한 손해를 배상하여야 합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service12">제12조 (정산)</h3>
        <ol>
          <li>①. 정산은 월 1회 실시를 원칙으로 합니다.</li>
          <li>②. 판매자회원은 몰에서 제공하는 페이지에서 판매 현황을 확인 할 수 있습니다.</li>
          <li>
            ③. 회사는 판매자회원에게 당월 정산 대금에 대한 정산 자료를 익월 영업일 기준 5일 이내로 몰의 정산
            관련 페이지를 통해 제공합니다.
          </li>
          <li>④. 본 조 3항의 당월 정산 대금은 당월에 출고된 주문을 기준으로 계산합니다.</li>
          <li>
            ⑤. 몰을 통해 판매된 상품이 구매확정되는 경우, 전체 판매 대금 중 상품의 기본가격을 제외한
            판매자회원이 산정한 디자인가격에 대한 금액에서 카드 수수료, 사업자가 없는 회원에 한하여
            사업소득세에 대한 원천징수액 등 공제가 필요한 금액을 제한 나머지 금액을 판매자회원에게 지급합니다.
          </li>
          <li>
            ⑥. 사업자를 보유한 판매자회원은 회사에게 회사로부터 지급 받을 금액에 대한 세금계산서를 익월
            10일까지 발행합니다.
          </li>
          <li>
            ⑦. 회사는 당월 정산 금액과 판매자회원이 발급한 세금계산서 금액이 일치할 경우, 판매자회원이 본 약관
            7조 6항에 따라 회사에 제공한 결제 계좌로 15일에 그 금액을 송금합니다. 단, 판매자회원이 지정한
            입금계좌의 예금주는 판매자회원과 동일인이어야 하며, 해당 일이 회사의 영업일이 아닌 경우 이후 최초
            영업일에 송금합니다.
          </li>
          <li>
            ⑧. 구매자회원으로부터 주문취소/환불 건이 발생하는 경우, 발생 시기에 따라 아래와 같이 반영합니다.
            <ol>
              <li>1. 당월 정산 금액 마감 전 발생하는 경우, 당월 정산에 반영하여 정산합니다.</li>
              <li>
                2. 당월 정산 금액 마감 후 발생하는 경우, 당월 정산에는 반영하지 아니하며 익월 정산에 반영하여
                정산합니다.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service13">제13조 (정산의 보류)</h3>
        <ol>
          <li>
            ①. 회사는 판매자회원의 귀책사유로 인해 발생한 비용을 정산 대금 정산 시 상계한 뒤 지급할 수
            있습니다.
          </li>
          <li>
            ②. 판매자회원의 서비스 중지 또는 이용계약 해지 후 당월 판매자회원의 판매대금의 일정 비율에
            해당하는 금액을 일정기간 동안 예치하여 동 기간 동안 구매한 구매자회원으로부터 오류 수정, 환불 등의
            청약 철회 요구가 발생하는 경우 관련 비용의 지급에 사용할 수 있습니다.
          </li>
          <li>
            ③. 판매자회원의 채권자의 신청에 의한 판매대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을
            경우, 회사는 판매자회원과 채권자 간의 합의 또는 채무액의 변제 등으로 동 결정이 해제될 때까지
            판매대금의 ‘정산’을 중지하거나 채권자에게 변제 또는 공탁할 수 있습니다.
          </li>
          <li>
            ④. 본 조에서 정한 사항 외에도 법률의 규정에 의하거나 합리적인 사유가 있는 경우에는 회사는
            판매자회원에게 이를 통지하고 판매대금의 전부 또는 일부에 대한 정산을 일정 기간 유보할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service14">제14조 (결제대금보호서비스)</h3>
        <ol>
          <li>
            ①. 회사는 몰을 통해 서비스를 제공하는 과정에서 대금의 수령, 보관 및 송금업무로 이루어지는
            결제대금보호 서비스(이하 구매안전서비스)를 제공합니다.
          </li>
          <li>
            ②. 회사가 제공하는 구매안전 서비스는 몰과 서비스를 통한 모든 거래에 적용됩니다. 판매자회원이
            구매안전 서비스를 통하지 않는 직거래를 유도하는 경우, 회사는 해당 거래를 취소하거나, 판매를
            중지하는 등 기타 필요한 조치를 취할 수 있습니다.
          </li>
          <li>
            ③. 회사가 제공하는 구매안전 서비스를 이용하지 않은 거래 및 물품 또는 구매안전 서비스가 종결 이후의
            거래와 관련하여 발생한 모든 사항은 판매자회원과 구매자회원이 상호협의를 통해 해결하여야 합니다.
          </li>
          <li>
            ④. 판매자회원은 회사가 제공하는 서비스를 이용함에 있어서 구매안전 서비스의 이용과 그 규칙에
            동의하여야 합니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service15">제15조 (양도금지)</h3>
        <ol>
          <li>
            ①. 판매자회원은 회사의 사전 서면 동의 없이 이 약관에 따른 일체의 권리와 의무를 제3자에게
            양도하거나 담보의 목적으로 제공할 수 없습니다.
          </li>
          <li>
            ②. 회사는 판매회원이 본 조를 위반하는 경우 서비스 제공을 거부할 권한을 가지며, 기존 판매회원에
            대하여 이용계약을 해지할 수 있습니다.
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service16">제16조 (비밀유지)</h3>
        <ol>
          <li>
            ①. 판매자회원은 구매자회원의 정보 등 몰의 이용과 관련해 취득한 일체의 정보를 회사의 서면 동의 없이
            외부에 유출하거나 이 약관 이 외의 목적으로 사용할 수 없습니다.
          </li>
          <li>②. 전항의 의무는 이용계약의 종료 후에도 존속합니다.</li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="service17">제17조(관할법원)</h3>
        <ol>
          <li>
            회사와 판매자회원 간 발생한 분쟁에 관한 소송은 회사의 본사 소재지를 관할하는 법원을
            합의관할법원으로 정합니다.
          </li>
        </ol>
      </div>
    </div>
  </div>
`;

export const makeStudioPrivacyHtml = () => legacyHtml`
  <div class="body terms_body">
    <div class="title">
      <h2>셀러스튜디오 개인정보처리방침</h2>
    </div>
    <div class="agree_header">
      <div class="table_of_contents">
        <ul>
          <li><a href="#privacy1">1. 셀러스튜디오 개인정보 처리방침이란 </a></li>
          <li><a href="#privacy2">2. 개인정보 수집 </a></li>
          <li><a href="#privacy3">3. 개인정보 이용 </a></li>
          <li><a href="#privacy4">4. 개인정보 제공 </a></li>
          <li><a href="#privacy5">5. 개인정보 파기 </a></li>
          <li><a href="#privacy6">6. 이용자 및 법정대리인의 권리와 행사 방법 </a></li>
          <li><a href="#privacy7">7. 기타사항 </a></li>
          <li><a href="#privacy8">8. 고지의 의무 </a></li>
        </ul>
      </div>
    </div>
    <div class="text_body">
      <div class="paragraph">
        <h3 id="privacy1">1. 셀러스튜디오 개인정보 처리방침이란</h3>
        <p>
          (주)마플코퍼레이션(이하”회사”)은 이용자의 ‘동의를 기반으로 개인정보를 수집·이용 및 제공’하고 있으며,
          ‘이용자의 권리 (개인정보 자기결정권)를 적극적으로 보장’합니다. 회사는 정보통신서비스제공자가
          준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 준수하고 있습니다.
          “개인정보처리방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수
          있도록 회사가 준수해야 할 지침을 의미합니다. 본 개인정보 처리방침은 개인 또는 사업자의 서비스를 위해
          회사가 제공하는 서비스에 적용됩니다.
        </p>
      </div>
      <div class="paragraph">
        <h3 id="privacy2">2. 개인정보 수집</h3>
        <p>
          A. 회사는 비지니스 기능별 필요 최소한의 개인정보 및 서비스이용 내역 등의 개인정보를 다음과 같이
          수집하고 있습니다.
        </p>
        <p>
          <strong>&lt;개인정보 항목&gt;</strong>
        </p>
        <div class="privacy2_list">
          <div class="title">- 판매자 회원계정</div>
          <ul>
            <li>
              [샵 개설 시 개인정보] 마플샵계정(이메일 또는 전화번호), 이용자 이름(실명), 생년월일, 성별,
              이메일, 전화번호
            </li>
            <li>[사업자로 샵 개설 시] 사업자등록증</li>
            <li>
              [법정대리인 동의 시 / 필수] 법정대리인 정보(이름, 성별, 생년월일, 휴대폰번호, 통신사업자,
              내·외국인 여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI)
            </li>
          </ul>
        </div>
        <p>
          B. 정산대금 지급, 계좌인증 및 원천징수 처리를 위해 「소득세법」제 145조, 제164조에 따라 아래와 같은
          개인정보를 수집하고 있습니다.
        </p>
        <div class="privacy2_list">
          <div class="title">- 정산대금 및 계좌인증</div>
          <ul>
            <li>[필수] 예금주명, 계좌번호, 은행명</li>
            <li>[선택] 계좌사본, 신분증 사본(마스킹)</li>
          </ul>
        </div>
        <div class="privacy2_list">
          <div class="title">
            - 원천징수처리, 지급명세서 제출, 개인사업자 사업소득 신고, 휴·폐업자 소득신고 및 처리결과 회신
          </div>
          <ul>
            <li>[필수] 이름, 주민등록번호, 주소, 이메일</li>
          </ul>
        </div>
        <p>C. 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.</p>
        <div class="privacy2_list">
          <div class="title">- 신용카드결제 시: 카드사명, 카드번호(일부) 등</div>
          <div class="title">- 휴대전화결제 시: 휴대전화번호, 결제승인번호 등</div>
          <div class="title">- 계좌이체 시: 예금주명, 계좌번호, 은행명</div>
        </div>
        <p>D. 서비스 이용과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.</p>
        <p>
          PC웹, 모바일 웹/앱 이용 과정에서 단말기정보(OS, 화면사이즈, 디바이스 아이디, 폰기종, 단말기 모델명),
          IP주소, 쿠키, 방문일시, 부정이용기록, 서비스 이용 기록 등의 정보가 자동으로 생성되어 수집될 수
          있습니다.
        </p>
        <p>E. 개인정보 수집방법</p>
        <div class="privacy2_list">
          <div class="title">회사는 아래와 같은 방법으로 개인정보를 수집합니다.</div>
          <ul>
            <li>
              회원가입 및 서비스 이용과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는
              경우
            </li>
            <li>제휴 서비스 또는 단체 등으로부터 개인정보를 제공받는 경우</li>
            <li>고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등</li>
            <li>온·오프라인에서 진행되는 이벤트/행사 등 참여</li>
          </ul>
        </div>
      </div>
      <div class="paragraph">
        <h3 id="privacy3">3. 개인정보 이용</h3>
        <p>
          회원관리, 서비스 제공·개선, 신규 서비스 개발 등을 위해 이용합니다. 회원 가입 시 또는 서비스 이용
          과정에서 홈페이지 또는 개별 어플리케이션이나 프로그램 등을 통해 아래와 같이 서비스 제공을 위해
          필요한 최소한의 개인정보를 수집하고 있습니다.
        </p>
        <div class="privacy2_list">
          <ul>
            <li>회원 식별/가입의사 확인, 본인/연령 확인, 부정이용 방지</li>
            <li>
              14세 미만 아동 개인정보 수집 시 법정대리인 동의여부 확인, 법정대리인 권리행사 시 본인 확인
            </li>
            <li>신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항 전달</li>
            <li>유료서비스 이용 시 컨텐츠 등의 전송이나 배송·요금 정산</li>
            <li>
              서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및 제재
            </li>
            <li>
              서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 프라이버시 보호 측면의 서비스 환경 구축,
              서비스 개선에 활용
            </li>
          </ul>
        </div>
      </div>
      <div class="paragraph">
        <h3 id="privacy4">4. 개인정보 제공</h3>
        <p>
          회사는 이용자들의 개인정보를 “03. 개인정보 이용”에 고지한 범위 내에서 사용하며, 이용자의 사전 동의
          없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만,
          아래의 경우에는 예외로 합니다.
        </p>
        <div class="privacy2_list">
          <ul>
            <li>이용자들이 사전 제공에 동의한 경우</li>
            <li>법령의 규정에 의거하거나, 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
          </ul>
        </div>
      </div>
      <div class="paragraph">
        <h3 id="privacy5">5. 개인정보 파기</h3>
        <p>
          전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의
          경우 분쇄하거나 소각하여 파기합니다.
        </p>
        <p>다만, 내부 방침에 따라 일정 기간 보관 후 파기하는 정보는 아래와 같습니다.</p>
        <div class="privacy2_list">
          <div class="title">- 회사 내부 방침에 의한 정보보유 사유</div>
          <table>
            <thead>
              <tr>
                <th>보관 정보</th>
                <th>보존 이유</th>
                <th>보존 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>부정 이용 기록</td>
                <td>부정 이용 방지</td>
                <td>1년</td>
              </tr>
              <tr>
                <td>회원 탈퇴 기록</td>
                <td>고객 문의 응대</td>
                <td>탈퇴일로부터 30일</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          또한, 회사는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리
          보관 또는 삭제하고 있으며, 분리 보관된 개인정보는 4년간 보관 후 지체없이 파기합니다.
        </p>
        <p>이 외에 법령에 따라 일정기간 보관해야 하는 개인정보 및 해당 법령은 아래 표와 같습니다.</p>
        <div class="privacy2_list">
          <table>
            <thead>
              <tr>
                <th>보관 정보</th>
                <th>보존 이유</th>
                <th>보존 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>계약 또는 쳥약철회 등에 관한 기록</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                <td>3년</td>
              </tr>
              <tr>
                <td>표시/광고에 관한 기록</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                <td>6개월</td>
              </tr>
              <tr>
                <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
                <td>국세기본법, 법인세법</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>전자금융 거래에 관한 기록</td>
                <td>전자금융거래법</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>웹사이트 방문에 관한 기록</td>
                <td>통신비밀보호법</td>
                <td>3개월</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="paragraph">
        <h3 id="privacy6">6. 이용자 및 법정대리인의 권리와 행사방법</h3>
        <p>
          이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 수집・이용에 대한 동의 철회 또는
          가입 해지를 요청할 수 있습니다.
        </p>
        <p>
          보다 구체적으로는 서비스 내 설정을 통한 회원정보 수정 기능이나 회원탈퇴 기능을 이용할 수 있고,
          고객센터를 통해 서면, 전화 또는 이메일로 요청하시면 지체 없이 조치하겠습니다.
        </p>
        <p>
          개인정보의 오류에 대한 정정을 요청하신 경우 정정을 완료하기 전까지 해당 개인정보를 이용 또는
          제공하지 않습니다.
        </p>
        <p>또한, 만 14세 미만 이용자의 법정대리인은 위의 모든 권리를 요청할 수 있습니다.</p>
      </div>
      <div class="paragraph">
        <h3 id="privacy7">7. 기타사항</h3>
        <p>웹기반 서비스의 제공을 위하여 쿠키를 이용하는 경우가 있습니다.</p>
        <p>쿠키는 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.</p>
        <strong>쿠키란? </strong>
        <p>
          쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서
          이용자의 컴퓨터에 저장됩니다.
        </p>
        <strong>사용목적</strong>
        <p>
          회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 쿠키를
          사용합니다. 이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는
          쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다. 쿠키는 이용자가
          웹 사이트를 방문할 때, 웹 사이트 사용을 설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다.
          또한, 이용자의 웹사이트 방문 기록, 이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해
          활용됩니다.
        </p>
        <strong>쿠키수집거부</strong>
        <p>
          쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 쿠키 설치에 대한 선택권을
          가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
          저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만 쿠키 설치를 거부할 경우
          웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
        </p>
        <div class="privacy2_list">
          <div class="title">&lt;설정 방법의 예&gt;</div>
          <ul>
            <li>
              Internet Explorer의 경우 : 웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정
            </li>
            <li>
              Chrome의 경우 : 웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급 설정 표시 &gt; 개인정보의
              콘텐츠 설정 버튼 &gt; 쿠키
            </li>
          </ul>
        </div>
        <strong>개인정보보호와 관련해서 궁금하신 사항은? </strong>
        <p>
          서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 개인정보
          보호책임자 및 담당부서로 연락해 주시기 바랍니다. 마플샵은 여러분의 목소리에 귀 기울이고 신속하고
          충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.
        </p>
        <p>문의:개인정보보호관련 문의/ ${MShopUtilS.getCustomerServiceInfo().tel}</p>
        <p>
          또한, 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을
          받으실 수 있습니다.
        </p>
        <p>
          개인정보침해신고센터 (<a href="https://privacy.kisa.or.kr">https://privacy.kisa.or.kr</a> / 국번없이
          118 )
        </p>
        <p>대검찰청 사이버수사과 ( cid@spo.go.kr / 국번없이 1301 )</p>
        <p>
          경찰청 사이버안전국 (
          <a href="https://cyberbureau.police.go.kr">https://cyberbureau.police.go.kr</a> / 국번없이 182 )
        </p>
      </div>
      <div class="paragraph">
        <h3 id="privacy8">8. 고지의 의무</h3>
        <p>
          개인정보 처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 ‘공지사항’을
          통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한
          변경이 있을 경우에는 최소 30일 전에 고지합니다.
        </p>
        <div class="privacy2_list">
          <ul>
            <li>공고일자: 2020년 1월 13일</li>
            <li>시행일자: 2020년 1월 13일</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
`;

export const makeStudioPrivacy20210727Html = ({ store_name }) => html`
  <div class="body terms_body">
    <div class="title">
      <h2>셀러스튜디오 개인정보처리방침</h2>
    </div>
    <div class="paragraph inner_hidden">
      <p>
        (주)마플코퍼레이션(이하 "회사"라고 함)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및
        정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을
        준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
        마플코퍼레이션의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.
      </p>
    </div>
    <div class="agree_header">
      <div class="table_of_contents">
        <ul>
          <li><a href="#privacy1">1. 셀러스튜디오 개인정보 처리방침이란</a></li>
          <li><a href="#privacy2">2. 수집하는 개인정보의 항목 및 수집방법</a></li>
          <li><a href="#privacy3">3. 개인정보 수집 및 이용목적</a></li>
          <li><a href="#privacy4">4. 개인정보 공유 및 제공</a></li>
          <li><a href="#privacy5">5. 개인정보 파기</a></li>
          <li><a href="#privacy6">6. 고객의 권리와 의무</a></li>
          <li><a href="#privacy7">7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</a></li>
          <li><a href="#privacy8">8. 고지의 의무</a></li>
          <li><a href="#privacy9">9. 개인정보관리책임자 및 담당자의 연락처</a></li>
        </ul>
      </div>
    </div>
    <div class="body text_body">
      <div class="paragraph">
        <h3 id="privacy1">1. 셀러스튜디오 개인정보 처리방침이란</h3>
        <p>
          (주)마플코퍼레이션(이하”회사”)은 이용자의 ‘동의를 기반으로 개인정보를 수집·이용 및 제공’하고 있으며,
          ‘이용자의 권리 (개인정보 자기결정권)를 적극적으로 보장’합니다. 회사는 정보통신서비스제공자가
          준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 준수하고 있습니다.
          “개인정보처리방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수
          있도록 회사가 준수해야 할 지침을 의미합니다. 본 개인정보 처리방침은 개인 또는 사업자의 서비스를 위해
          회사가 제공하는 서비스에 적용됩니다.
        </p>
      </div>
      <div class="paragraph">
        <h3 id="privacy2">2. 수집하는 개인정보의 항목 및 수집 방법</h3>
        <p>
          A. 회사는 비지니스 기능별 필요 최소한의 개인정보 및 서비스이용 내역 등의 개인정보를 다음과 같이
          수집하고 있습니다.
        </p>
        <p>
          <strong>&lt;개인정보 항목&gt;</strong>
        </p>
        <div class="privacy2_list">
          <div class="title">- 판매자 회원계정</div>
          <ul>
            <li>
              [샵 개설 시 개인정보] 마플샵계정(이메일 또는 전화번호), 이용자 이름(실명), 생년월일, 성별,
              이메일, 전화번호, ci(연계정보),di(중복가입정보)
            </li>
            <li>[사업자로 샵 개설 시] 사업자등록증</li>
            <li>
              [법정대리인 동의 시] 법정대리인 정보(이름, 성별, 생년월일, 휴대폰번호, 통신사업자, 내·외국인
              여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI)
            </li>
          </ul>
        </div>
        <p>
          B. 정산대금 지급, 계좌인증 및 원천징수 처리를 위해 「소득세법」제 145조, 제164조에 따라 아래와 같은
          개인정보를 수집하고 있습니다.
        </p>
        <div class="privacy2_list">
          <div class="title">- 정산대금 및 계좌인증</div>
          <ul>
            <li>[필수] 예금주명, 계좌번호, 은행명</li>
            <li>[선택] 계좌사본, 신분증 사본(마스킹)</li>
          </ul>
        </div>
        <div class="privacy2_list">
          <div class="title">
            - 원천징수처리, 지급명세서 제출, 개인사업자 사업소득 신고, 휴·폐업자 소득신고 및 처리결과 회신
          </div>
          <ul>
            <li>[필수] 이름, 주민등록번호, 주소, 이메일</li>
          </ul>
        </div>
        <p>C. 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.</p>
        <div class="privacy2_list">
          <div class="title">- 신용카드결제 시: 카드사명, 카드번호(일부) 등</div>
          <div class="title">- 휴대전화결제 시: 휴대전화번호, 결제승인번호 등</div>
          <div class="title">- 계좌이체 시: 예금주명, 계좌번호, 은행명</div>
        </div>
        <p>D. 서비스 이용과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.</p>
        <p>
          PC웹, 모바일 웹/앱 이용 과정에서 단말기정보(OS, 화면사이즈, 디바이스 아이디, 폰기종, 단말기 모델명),
          IP주소, 쿠키, 방문일시, 부정이용기록, 서비스 이용 기록 등의 정보가 자동으로 생성되어 수집될 수
          있습니다.
        </p>
        <p>E. 개인정보 수집방법</p>
        <div class="privacy2_list">
          <div class="title">회사는 아래와 같은 방법으로 개인정보를 수집합니다.</div>
          <ul>
            <li>
              회원가입 및 서비스 이용과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는
              경우
            </li>
            <li>제휴 서비스 또는 단체 등으로부터 개인정보를 제공받는 경우</li>
            <li>고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등</li>
            <li>온·오프라인에서 진행되는 이벤트/행사 등 참여</li>
          </ul>
        </div>
      </div>
      <div class="paragraph">
        <h3 id="privacy3">3. 개인정보의 수집 및 이용목적</h3>
        <p>회사는 다음과 같은 목적을 위하여 필요한 최소한의 개인정보를 수집하고 있습니다.</p>
        <div class="privacy2_list">
          <ol>
            <li>1. 회원 식별/가입의사 확인, 본인/연령 확인, 부정이용 방지</li>
            <li>
              2. 14세 미만 아동 개인정보 수집 시 법정대리인 동의여부 확인, 법정대리인 권리행사 시 본인 확인
            </li>
            <li>3. 신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항 전달</li>
            <li>4. 유료서비스 이용 시 컨텐츠 등의 전송이나 배송·요금 정산</li>
            <li>
              5. 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및
              제재
            </li>
            <li>
              6. 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 프라이버시 보호 측면의 서비스 환경
              구축, 서비스 개선에 활용
            </li>
          </ol>
        </div>
      </div>
      <div class="paragraph">
        <h3 id="privacy4">4. 개인정보 공유 및 제공</h3>
        <p>
          회사는 이용자들의 개인정보를 『3. 개인정보의 수집 및 이용목적』에 고지한 범위 내에서 사용하며,
          이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에
          공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
        </p>
        <div class="privacy2_list">
          <ul>
            <li>이용자들이 사전 제공에 동의한 경우</li>
            <li>법령의 규정에 의거하거나, 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
          </ul>
        </div>
        <p>
          개인정보를 제공할 경우에는 사전에 고객에게 개인정보를 제공받는 제3자, 제공하는 개인정보항목명,
          개인정보 제공의 목적, 제공하는 개인정보가 언제까지 어떻게 보호·관리되는지, 고객이 종전에 동의한
          사항, 고객의 동의하지 않을 권리와 의사표시 방법, 기타 고객의 동의를 얻기 위하여 필요한 사항에 대해
          개별적으로 서면, 전자우편(e-mail), 전화 등을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 고객이
          동의하지 않는 경우에는 제3자에게 정보를 제공하지 않습니다.
        </p>
      </div>
      <div class="paragraph">
        <h3 id="privacy5">5. 개인정보 파기</h3>
        <p>
          전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의
          경우 분쇄하거나 소각하여 파기합니다.
        </p>
        <p>다만, 내부 방침에 따라 일정 기간 보관 후 파기하는 정보는 아래와 같습니다.</p>
        <div class="privacy2_list">
          <div class="title">- 회사 내부 방침에 의한 정보보유 사유</div>
          <table>
            <thead>
              <tr>
                <th>보관 정보</th>
                <th>보존 이유</th>
                <th>보존 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>부정 이용 기록</td>
                <td>부정 이용 방지</td>
                <td>1년</td>
              </tr>
              <tr>
                <td>회원 탈퇴 기록</td>
                <td>고객 문의 응대</td>
                <td>탈퇴일로부터 30일</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          마플샵을 최종 이용 후 1년 동안 이용 기록이 없는 고객(장기미이용회원)의 개인정보는 별도로 분리하여
          안전하게 관리하게 되며, 대상자에게는 분리 보관 처리일을 기준으로 하여 최소 30일 이전에 이메일 주소를
          통해 안내를 합니다. 단,통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등의 관계법령의
          규정에 의하여 보존할 필요가 있는 경우 규정된 기간 동안 고객의 개인정보를 보관합니다.
        </p>
        <div class="privacy2_list">
          <table>
            <thead>
              <tr>
                <th>보관 정보</th>
                <th>보존 이유</th>
                <th>보존 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>계약 또는 쳥약철회 등에 관한 기록</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                <td>3년</td>
              </tr>
              <tr>
                <td>표시/광고에 관한 기록</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                <td>6개월</td>
              </tr>
              <tr>
                <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
                <td>국세기본법, 법인세법</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>전자금융 거래에 관한 기록</td>
                <td>전자금융거래법</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>웹사이트 방문에 관한 기록</td>
                <td>통신비밀보호법</td>
                <td>3개월</td>
              </tr>
            </tbody>
          </table>
          <ol>
            <li>
              1. 등록되어 있는 본인의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을
              하고자 할 경우에는 홈페이지내 마이페이지를 클릭하여 직접 열람 또는 정정하거나,
              개인정보관리책임자에게 서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를 거쳐
              지체없이 조치하겠습니다. 다만, 아이디(전자우편주소)는 변경이 불가능하며 부득이한 사유로
              변경하고자 할 경우에는 해당 아이디를 해지한 후 재가입하셔야 합니다. 재가입시에는 기존의 아이디를
              사용하실 수 없습니다.
            </li>
            <li>
              2. 회사는 이용자로부터 전항의 요구가 있는 경우 법정 기한 내에 그 요구에 응답하고 정정을 완료하기
              전까지 당해 개인정보를 이용하지 않습니다. 단, 이 경우 해당 기간 내에 응답할 수 없는 정당한
              사유가 있을 때에는 이용자에게 그 사유를 알리고 연기할 수 있으며, 그 사유가 소멸하면 지체없이
              응답합니다.
            </li>
            <li>
              3. 이용자는 동의하신 개인정보의 수집, 이용, 제공에 대해 언제든지 철회하실 수 있습니다.
              동의철회는 홈페이지내 마이페이지를 클릭하여 직접 삭제하거나 개인정보관리책임자에게
              서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를 거쳐 지체없이 개인정보의
              삭제 등 필요한 조치를 하겠습니다. 단, 온라인사이트의 경우 탈퇴시 통계자료 작성상 필요한
              구매이력을 제외하고 모두 삭제됩니다.
            </li>
            <li>4. 만 14세 미만 이용자의 법정대리인은 위 모든 권리를 행사할 수 있습니다.</li>
          </ol>
        </div>
      </div>
      <div class="paragraph">
        <h3 id="privacy6">6. 고객의 권리와 의무</h3>
        <ol>
          <li>
            1. 고객의 권리
            <ol>
              <li>
                ① 이용자는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보
                열람 및 정정을 하고자 할 경우에는 홈페이지내 마이페이지를 클릭하여 직접 열람 또는 정정하거나,
                개인정보관리책임자에게 서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를
                거쳐 지체없이 조치하겠습니다.
              </li>
              <li>
                ② 회사는 이용자로부터 전항의 요구가 있는 경우 법정 기한 내에 그 요구에 응답하고 정정을
                완료하기 전까지 당해 개인정보를 이용하지 않습니다. 단, 이 경우 해당 기간 내에 응답할 수 없는
                정당한 사유가 있을 때에는 이용자에게 그 사유를 알리고 연기할 수 있으며, 그 사유가 소멸하면
                지체없이 응답합니다.
              </li>
              <li>
                ③ 이용자는 동의하신 개인정보의 수집, 이용, 제공에 대해 언제든지 철회하실 수 있습니다.
                동의철회는 홈페이지내 마이페이지를 클릭하여 직접 삭제하거나 개인정보관리책임자에게
                서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를 거쳐 지체없이 개인정보의
                삭제 등 필요한 조치를 하겠습니다. 단, 온라인사이트의 경우 탈퇴시 통계자료 작성상 필요한
                구매이력을 제외하고 모두 삭제됩니다.
              </li>
              <li>④ 만 14세 미만 이용자의 법정대리인은 위 모든 권리를 행사할 수 있습니다.</li>
            </ol>
          </li>
          <li>
            2. 고객의 의무
            <p style="margin: 0;">
              고객은 자신의 개인정보를 보호할 의무가 있으며, 회사의 귀책사유가 없이 ID(이메일 주소), 비밀번호,
              접근매체 등의 양도·대여·분실이나 로그인 상태에서 이석 등 고객 본인의 부주의나 관계법령에 의한
              보안조치로 차단할 수 없는 방법이나 기술을 사용한 해킹 등 회사가 상당한 주의에도 불구하고 통제할
              수 없는 인터넷상의 문제 등으로 개인정보가 유출되어 발생한 문제에 대해 회사는 책임을 지지
              않습니다. 고객은 자신의 개인정보를 최신의 상태로 유지해야 하며, 고객의 부정확한 정보 입력으로
              발생하는 문제의 책임은 고객 자신에게 있습니다. 타인의 개인정보를 도용한 회원가입 또는 ID등을
              도용하여 결제 처리 시 고객 자격 상실과 함께 관계법령에 의거하여 처벌될 수 있습니다. 고객은
              아이디, 비밀번호 등에 대해 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수
              없습니다. 고객은 회사의 개인정보보호정책에 따라 보안을 위한 주기적인 활동에 협조할 의무가
              있습니다.
            </p>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="privacy7">7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h3>
        <ol>
          <li>
            1. 쿠키
            <ol>
              <li>
                ① 회사는 이용자에 대한 정보를 저장하고 검색할 수 있는 '쿠키(cookie)'를 사용합니다. 쿠키는
                웹사이트가 이용자의 컴퓨터 브라우저로 전송하는 소량의 정보입니다. 이용자께서 웹사이트에 접속을
                하면 회사의 컴퓨터는 이용자의 브라우저에 있는 쿠키의 내용을 읽고, 이용자의 추가정보를 이용자의
                컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는
                이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다.
              </li>
              <li>
                ② 회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 제공하고 수시로
                불러오는 쿠키(cookie)를 통하여 수집된 이용자의 개인정보를 사용합니다. 방문한 서비스 정보,
                장바구니 이력, 서비스 접속 시간 및 빈도, 서비스 이용 과정에서 생성된 또는 제공(입력)한
                정보등을 분석하여 고객의 취향과 관심에 특화된 서비스(광고 포함)를 제공할 수 있습니다.
              </li>
              <li>
                ③ 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저 옵션에서 모든
                쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수
                있습니다.
                <ol>
                  <li>
                    1. Internet Explorer의 경우
                    <ul>
                      <li>
                        - [도구]메뉴에서 [인터넷 옵션]을 선택 → [개인정보]를 클릭 → [고급]을 클릭 → 쿠키
                        허용여부를 선택
                      </li>
                    </ul>
                  </li>
                  <li>
                    2. Safari의 경우
                    <ul>
                      <li>
                        - MacOS 상단 좌측 메뉴바에서 [Safari]에서 [환경설정]선택 → [환경설정]창에서 [보안]으로
                        이동하여 쿠키 허용여부 선택
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            2. 맞춤형 광고
            <p style="margin: 0;">
              고객에게 맞춤형 광고를 제공하기 위하여 마플샵은 웹 브라우저에서는 ‘쿠키', 모바일 앱에서는
              광고식별자(ADID)를 수집하여 사용합니다. 마플샵은 쿠키 및 광고식별자를 통해 고객의 서비스 사용
              이력을 자동으로 수집하여 페이스북, 구글에 제공합니다. 페이스북 및 구글은 이를 활용하여 고객 맞춤
              광고를 진행합니다. 마플샵에서 수집하는 쿠키 및 광고식별자는 다른 개인정보와 연계되지 않으며
              개인을 식별하지 않습니다. 또한, 페이스북 및 구글은 마플샵에서 제공하는 정보를 활용하여 개인을
              식별하지 않습니다.
            </p>
          </li>
        </ol>
      </div>
      <div class="paragraph">
        <h3 id="privacy8">8. 고지의 의무</h3>
        <p>
          개인정보 처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 ‘공지사항’을
          통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한
          변경이 있을 경우에는 최소 30일 전에 고지합니다.
        </p>
        <div class="privacy2_list">
          <ul>
            <li>공고일자: 2021년 7월 21일</li>
            <li>시행일자: 2021년 7월 28일</li>
          </ul>
        </div>
      </div>
      <div class="paragraph">
        <h3 id="privacy9">9. 개인정보관리책임자 및 담당자의 연락처</h3>
        <ol>
          <li>
            ① 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만사항 및 문의를 처리하기 위하여 아래와
            같이 개인정보 관리 책임자를 지정하고 있습니다.<br />
            <b>[개인정보 관리 책임자]</b>
            <ul>
              <li>1. 성명 : 유인동 팀장</li>
              <li>2. 전화번호 : ${MShopUtilS.getCustomerServiceInfo().tel} / 02-6486-7700</li>
              <li>3. 전자우편주소(e-mail) : dev@marpple.com</li>
            </ul>
          </li>
          <li>
            ② 정보주체는 개인정보 보호법 제 35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수
            있습니다.<br />
            마플샵은 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br />
            <b>[개인정보 열람청구 접수 및 처리 부서]</b>
            <ul>
              <li>1. 성명 : 유인동 팀장</li>
              <li>2. 전화번호 : ${MShopUtilS.getCustomerServiceInfo().tel} / 02-6486-7700</li>
              <li>3. 전자우편주소(e-mail) : dev@marpple.com</li>
            </ul>
          </li>
          <li>
            ③ 기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.
            <ul class="no_padding">
              <li>
                - 개인정보침해신고센터 (<a href="https://privacy.kisa.or.kr/" target="_blank"
                  >privacy.kisa.or.kr</a
                >
                / 국번없이 118)
              </li>
              <li>
                - 정보보호마크인증위원회 (<a href="http://www.eprivacy.or.kr" target="_blank"
                  >www.eprivacy.or.kr</a
                >
                / 02-580-0534)
              </li>
              <li>
                - 대검찰청 첨단범죄수사과 (<a href="http://www.spo.go.kr" target="_blank">www.spo.go.kr</a>
                / 02-3480-2000)
              </li>
              <li>
                - 경찰청 사이버안전국 (<a href="https://cyberbureau.police.go.kr" target="_blank"
                  >cyberbureau.police.go.kr</a
                >
                / 국번없이 182)
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div class="paragraph">
        <h3>&lt;부칙&gt;</h3>
        <p>
          본 약관은 2021년 7월 28일부터 시행됩니다.<br />
          2020년 1월 13일부터 시행해 온 약관은 본 약관으로 대체됩니다.
        </p>
        <ul class="prev_link">
          <li>
            ${store_name
              ? html`<a href="/${T.lang}/${UtilS.escape(store_name)}/settings/privacy/20200113"
                  >- 이전 개인정보보호 취급방침 보기</a
                >`
              : html`<a href="/${T.lang}/@/studio/privacy/20200113">- 이전 셀러스튜디오 이용약관 보기</a>`}
          </li>
        </ul>
      </div>
    </div>
    <div class="option">
      <button class="done">동의</button>
    </div>
  </div>
`;

export const makeSettingsStoreTermsHtml = ({ store_id, store_name }) => legacyHtml`
  ${makeStudioTermsHtml({ store_name })}
  <div class="agree_checkbox">
    <label>
      <input type="checkbox" />
      <span>이용약관에 동의함</span>
    </label>
  </div>
  ${makeStudioPrivacy20210727Html({ store_name })}
  <div class="agree_checkbox">
    <label>
      <input type="checkbox" />
      <span>개인정보처리방침에 동의함</span>
    </label>
  </div>
  <div class="agree_checkbox">
    <label>
      <input type="checkbox" />
      <span>만 14세 이상입니다.</span>
    </label>
  </div>
  <div class="options">
    <form data-store_id="${store_id}">
      <button id="agree" class="btn btn-blue" type="submit">동의하고 마플샵 시작하기</button>
    </form>
  </div>
`;
