import { $append, $closest, $delegate, $el, $find, $is, $on, $qs, $qsa, $remove, $setCss } from 'fxdom/es';
import { each, go, tap } from 'fxjs/es';
import { MarketplaceS } from '../../../../Marketplace/S/Function/module/MarketplaceS.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { MShopUtilF } from '../../../Util/F/Function/module/MShopUtilF.js';

const component = 'mshop-comp-dropdown';

const getSelectedOptionEl = (dropdown_el) =>
  $find(`.${component}__option[data-selected="true"]`, dropdown_el);

export const unselectDropdown = (dropdown_el) => {
  const selected_option_el = getSelectedOptionEl(dropdown_el);
  if (!selected_option_el) return;

  dropdown_el.dataset.selected = 'false';
  const button_el = $find(`.${component}__button-text`, dropdown_el);
  button_el.innerText = dropdown_el.dataset.title;

  selected_option_el.dataset.selected = 'false';
};

/**
 *
 * @param {HTMLElement=} target_el
 * @param {function(Event & { delegateTarget: Element }, option): void=} onChange
 * @param {function(Event & { delegateTarget: Element }, option): void=} onOpenDropdown
 * @param {function(Event & { delegateTarget: Element }, option): void=} onCloseDropdown
 * @return void
 */
export const initDropdown = function initDropdown({
  target_el,
  hooks: { onChange, onOpenDropdown, onCloseDropdown } = {},
}) {
  const component = 'mshop-comp-dropdown';

  const dropdown_container_el = target_el || $qs(`.${component}`);

  if (!dropdown_container_el || dropdown_container_el.initialize) return;

  const open_input_el = $find(`.${component}__button input`, dropdown_container_el);

  const is_safari = UtilF.is_safari();

  const dropdown_backdrop_klass = 'mshop-comp-dropdown__backdrop';

  go(
    dropdown_container_el,
    $delegate('change', `.${component}__button input`, function toggleDropdown(e) {
      dropdown_container_el.dataset.open = e.target.checked;

      // 열릴 때 다른 드랍다운들 다 닫기 - safari 만 focusout 이 제대로 동작 안해서 수동으로 꺼야 함
      if (e.target.checked == true && is_safari) {
        go(
          $qsa(`.${component}`),
          each(function closeOtherDropdowns(el) {
            if (el == dropdown_container_el) return;
            closeDropdown(el);
          }),
        );
      }
      if (onOpenDropdown && typeof onOpenDropdown === 'function') onOpenDropdown(e);
    }),
    $delegate('click', `.${component}__option--group`, function toggleSubOptionGroup(e) {
      MShopUtilF.allowScroll();
      const option_group_el = e.currentTarget;
      e.originalEvent.preventDefault();

      const { idx } = option_group_el.dataset;

      const is_open = !(option_group_el.dataset.open === 'true');

      const sub_container = $find(
        `.${component}__option-sub-container[data-idx="${idx}"]`,
        dropdown_container_el,
      );

      option_group_el.dataset.open = is_open;
      sub_container.dataset.open = is_open;

      /* backdrop 있으면 제거 */
      go(dropdown_container_el, $find(`.${dropdown_backdrop_klass}`), (backdrop_el) => {
        backdrop_el && $remove(backdrop_el);
      });
    }),
    $on('focusout', function closeDropdown(e) {
      if (is_safari) return;

      const close_component_el = e.relatedTarget ? $closest(`.${component}`, e.relatedTarget) : null;
      if (close_component_el == dropdown_container_el) return;

      open_input_el.checked = false;
      dropdown_container_el.dataset.open = false;
    }),
    $delegate('click', `.${component}__option`, function selectOption(e) {
      if (onCloseDropdown && typeof onCloseDropdown === 'function') onCloseDropdown(e);
      MShopUtilF.allowScroll();
      if ($is(`.${component}__option--group`, e.currentTarget)) return;
      const { value, name, selected_name } = e.currentTarget.dataset;

      // change selected option
      const selected_option_el = getSelectedOptionEl(dropdown_container_el);

      // selected 가 없을 수도 있음
      if (selected_option_el) {
        selected_option_el.dataset.selected = 'false';
      } else {
        dropdown_container_el.dataset.selected = 'true';
      }
      e.currentTarget.dataset.selected = 'true';

      const button_text_el = $find(`.${component}__button-text`, dropdown_container_el);
      button_text_el.innerText = selected_name || name;

      open_input_el.checked = !open_input_el.checked;
      dropdown_container_el.dataset.open = open_input_el.checked;

      if (onChange && typeof onChange === 'function') onChange(e, { value, name });

      /* backdrop 있으면 제거 */
      go(dropdown_container_el, $find(`.${dropdown_backdrop_klass}`), (backdrop_el) => {
        backdrop_el && $remove(backdrop_el);
      });
    }),
  );

  dropdown_container_el.initialize = true;
};

export const closeDropdown = (dropdown_el) => {
  dropdown_el.dataset.open = 'false';
  const status_input_el = $find(`.${component}__button input`, dropdown_el);
  status_input_el.checked = false;
};

export const findDropdownEl = function findDropdownEl(container_el) {
  return $find(`.${component}`, container_el);
};

const backdrop_style = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  opacity: 0.2,
  zIndex: 10,
};

/**
 * marketplace별로 다른 dropdown bottom sheet menu style 적용
 * @param marketplace_name
 * @returns {*}
 */
const getBottomSheetMenuStyle = (marketplace_name) => {
  const style_obj = {
    ness: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      borderRadius: '0.75rem 0.75rem 0 0',
      zIndex: 20,
    },
  };

  return style_obj[marketplace_name.toLowerCase()];
};

/**
 * 모바일 dropdown 메뉴를 bottomSheet로 바꿔준다.
 * initDropdown hook:onOpenDropdown에 넣어주면 적용된다.
 * @param {HTMLElement=} dropdown_el
 * @return void
 */
export const convertMenuToBottomSheetMo = (dropdown_el) => {
  const marketplace_name = MarketplaceS.getMarketPlaceName();
  const dropdown_backdrop_klass = 'mshop-comp-dropdown__backdrop';

  const is_closed_menu = !$find(`.${dropdown_backdrop_klass}`, dropdown_el);

  /* dropdown menu를 open할 때만 menu style 재설정, backdrop 생성 */
  if (is_closed_menu) {
    const dropdown_backdrop_el = go(
      `<div class="${dropdown_backdrop_klass}"></div>`,
      $el,
      $setCss(backdrop_style),
    );

    go(
      dropdown_el,
      tap($find('.mshop-comp-dropdown__menu'), $setCss(getBottomSheetMenuStyle(marketplace_name))),
      tap($append(dropdown_backdrop_el)),
    );

    go(
      dropdown_backdrop_el,
      $on('click', () => {
        MShopUtilF.allowScroll();
        $remove(dropdown_backdrop_el);
        closeDropdown(dropdown_el);
      }),
    );

    MShopUtilF.preventScroll();
  }
};
