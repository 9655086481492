import { html, View } from 'rune-ts';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import { ProductCardData } from '../../../../features/ProductList/type';

type ProductCardPriceData = Pick<ProductCardData, 'price'>;

export class ProductCardPrice extends View<ProductCardPriceData> {
  lang: 'kr' | 'en' | 'jp';

  constructor(
    data: ProductCardPriceData,
    private option: {
      price_klass?: string;
      currency_klass?: string;
    } = {},
  ) {
    super(data, option);
    this.lang = ET.lang;
  }

  override template() {
    return html`<span>${this.lang === 'kr' ? this.makeKrPriceTmpl() : this.makeEnJpPriceTmpl()}</span> `;
  }

  private makeKrPriceTmpl() {
    return html`<span class="price-number ${this.option.price_klass}">${UtilS.commify(this.data.price)}</span
      ><span class="${this.option.currency_klass}">${UtilS.getCurrency()}</span>`;
  }

  private makeEnJpPriceTmpl() {
    return html`<span class="${this.option.currency_klass}">${UtilS.getCurrency()}</span
      ><span class="price-number ${this.option.price_klass}">${UtilS.commify(this.data.price)}</span>`;
  }
}
