import anime from 'animejs';
import { $attr, $data, $el, $find, $setAttr, $setHTML, $siblings } from 'fxdom/es';
import { each, go, strMap, tap, zipWithIndexL } from 'fxjs/es';
import UAParser from 'ua-parser-js';
import { ConfigSentryF } from '../../../modules/Config/Sentry/F/Function/module/ConfigSentryF.js';
import { MShopUtilF } from '../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';
import { legacyHtml } from '../../../modules/Util/S/Function/util.js';

!(function () {
  const history = window.history;
  let index = _p.val(history, 'state.don_frame.index') || 0;
  let is_ing = false;
  let is_animation = true;
  let is_popstate_ing = false;
  const frame_stack = [];
  const frames = {};
  const pages = {};
  const popstate = {};
  const parser = new UAParser();
  const IS_IOS = !MShopUtilF.isCreatorApp() && /IOS/i.test(parser.getOS()?.name);
  const IS_MOBILE = !!parser.getDevice().type;
  const el_don_frame_screen = $1('#don_frame_screen') || $.el('<div id="don_frame_screen"></div>');

  const makeFrameHeight = (h) => h || window.innerHeight + (MShopUtilF.isCreatorApp() ? 60 : 0);

  let ready_f = _p.idtt;
  $.frame = function (f_opt, p_opt) {
    $.appendTo($.show(el_don_frame_screen), 'body');
    return _p.go(_p.mr(f_opt, p_opt), _p.wait(0), check_ing(start), ready_f);
  };
  $.frame.__frames = frames;
  $.frame.__pages = pages;
  $.frame.ready = function (f) {
    ready_f = f;
  };
  $.frame.body = $1('#body');
  $.frame.open = check_ing(open);
  $.frame.add_page = check_ing(add_page);
  $.frame.close = check_ing(frame_close_or_remove(close_frame1));
  $.frame.remove = check_ing(frame_close_or_remove(close_remove_frame1));
  $.frame.back_page = check_ing(function (val) {
    if ($.find(frame_stack[_p.val(history, 'state.don_frame.frame_index') || 0], '.don_page').length > 1)
      return _p.go(void 0, sync_history_go(-1), _p.a(val), back_page1);
  });
  $.frame.extend_state = function (state, title, url, is_replace) {
    const history_state = _p.extend({}, history.state, state);
    history[is_replace ? 'replaceState' : 'pushState'](history_state, title || null, url || null);
  };

  $.frame.replace_tab = replace_tab;

  $.frame.infi = function (el_infi_container, _opt) {
    el_infi_container = $.add_class($1(el_infi_container), 'infi_container');
    $.css(el_infi_container, 'position', 'relative');
    const infinite_event_name = _p.unique_id('scroll.ui_infinite_scroll_');
    const el_infi_items = $.find(el_infi_container, '.infi_item');
    let offset = el_infi_items.length;
    const opt = _p.extend(
      {
        limit: 24,
        col: 1,
        off: false,
        data_func: _p.noop,
        appending: _p.noop,
        appended: _p.noop,
        template: _p.noop,
      },
      _opt,
    );
    const off = opt.off;
    const el_frame = $.closest(el_infi_container, '.don_frame');
    opt.scroll_target = $1(
      el_frame && el_frame.frame_opt.is_modal ? $.find1(el_frame, '>.don_wrapper >.body') : window,
    );
    const is_inner = window != opt.scroll_target;
    const first_scroll_top = $.scroll_top(opt.scroll_target);
    /* 프레임 인피니티의 준비! */
    opt.infi_wrapper_items = make_infi_items(opt);
    $.remove(el_infi_items);
    $.append(el_infi_container, _p.pluck(opt.infi_wrapper_items, 'el'));
    let is_over = false;
    return _p.go(
      el_infi_items,
      _p.tap(function () {
        return $.scroll_top(opt.scroll_target, first_scroll_top);
      }),
      function (el_infi_items) {
        if (el_infi_items.length) return append_items(el_infi_items);
        return get_new_items();
      },
      function () {
        setOffButton();
        let is_loading = false;
        let _before_is_down = false;
        let _before_scroll_top =
          $.scroll_top(opt.scroll_target) - (is_inner ? 0 : $.offset(el_infi_container).top);
        const is_inner_container_top = $.offset(el_infi_container).top;
        return function () {
          const container_top = is_inner ? is_inner_container_top : $.offset(el_infi_container).top;
          const now_scroll_top = $.scroll_top(opt.scroll_target) - container_top;
          if (now_scroll_top < -container_top) return;
          const is_down = _before_scroll_top <= now_scroll_top;
          if (_before_is_down == is_down && is_loading) return;
          _before_is_down = is_down;
          _before_scroll_top = now_scroll_top;
          is_loading = true;
          $.don_loader_start(el_infi_container, {
            position: 'relative',
            height: 150,
          });
          const threshold_scroll_top1 = Math.max(
            now_scroll_top - ($.innerHeight(opt.scroll_target) || window.innerHeight),
            1,
          );
          const threshold_scroll_top2 = Math.min.apply(
            Math,
            [now_scroll_top + ($.innerHeight(opt.scroll_target) || window.innerHeight) * 2].concat(
              _p.filter(_p.pluck(opt.infi_wrapper_items, 'height'), _p.idtt),
            ),
          );
          if (el_infi_container.offsetHeight == 0) return;

          return _p.go(
            opt.infi_wrapper_items,
            _p.each(function (wrapper_item) {
              if (is_down) {
                toggle_scrolling(
                  is_down,
                  _p.find_last_i(wrapper_item.hide_items2, function (infi_items) {
                    return threshold_scroll_top2 > $.position(infi_items.el).top;
                  }),
                  wrapper_item,
                  'hide_items2',
                  'el_items',
                );
                toggle_scrolling(
                  is_down,
                  _p.find_last_i(wrapper_item.el_items, function (infi_items) {
                    return (
                      threshold_scroll_top1 >
                      $.position(infi_items.el).top + $.outerHeight(infi_items.el, true)
                    );
                  }),
                  wrapper_item,
                  'el_items',
                  'hide_items1',
                );
              } else {
                toggle_scrolling(
                  is_down,
                  _p.find_i(wrapper_item.hide_items1, function (infi_items) {
                    return (
                      threshold_scroll_top1 <
                      $.position(infi_items.el).top + $.outerHeight(infi_items.el, true)
                    );
                  }),
                  wrapper_item,
                  'hide_items1',
                  'el_items',
                );
                toggle_scrolling(
                  is_down,
                  _p.find_i(wrapper_item.el_items, function (infi_items) {
                    return threshold_scroll_top2 <= $.position(infi_items.el).top;
                  }),
                  wrapper_item,
                  'el_items',
                  'hide_items2',
                );
              }
            }),
            function () {
              if (
                !is_over &&
                is_down &&
                threshold_scroll_top2 >= Math.min.apply(Math, _p.pluck(opt.infi_wrapper_items, 'height'))
              )
                return get_new_items();
            },
            function () {
              is_loading = false;
              $.don_loader_end(el_infi_container);
            },
          );
        };
      },
      function (handler) {
        var infi = {
          event_name: infinite_event_name,
          handler: handler,
          scroll_target: opt.scroll_target,
          opt: opt,
          go: __(_p.wait(100), function (view_more_button) {
            infi.opt.off = false;
            $.on(opt.scroll_target, infinite_event_name, infi.handler);
            return _p.go(infi.handler(), function () {
              if (view_more_button) $.remove(view_more_button);
            });
          }),
          stop: function () {
            $.off(opt.scroll_target, infinite_event_name);
          },
          change: function (datas, col) {
            opt.col = col;
            $.off(opt.scroll_target, infinite_event_name, infi.handler);
            offset = _p.val(datas, 'length') || 0;
            is_over = false;
            opt.off = off;
            $.html(
              $.css(el_infi_container, { height: '', overflow: '' }),
              _p.pluck((opt.infi_wrapper_items = make_infi_items(opt)), 'el'),
            );
            return _p.go(
              void 0,
              function () {
                if (datas && datas.length)
                  return _p.go(
                    datas || [],
                    _p.sum(opt.template),
                    $.el,
                    _p.wrap_arr,
                    _p.tap(opt.appending),
                    append_item_func,
                    _p.tap(opt.appended),
                  );
                else return get_new_items();
              },
              function () {
                setOffButton();
                if (!infi.opt.off) infi.go();
                else if (infi.opt.off) infi_view_more(infi);
              },
            );
          },
          destroy: function () {
            $.off(infi.opt.scroll_target, infi.event_name, infi.handler);
            infi.handler = null;
            infi.scroll_target = null;
            infi.opt = null;
          },
          prepend: function (items, arr) {
            items = _p.wrap_arr(items);
            if (!items.length) return;
            return _p.go(
              opt.scroll_target,
              _p.tap(_p($.scroll_top2, _p, 0)),
              $.off(infinite_event_name, infi.handler),
              _p.tap(
                _p.a(items),
                _p.sum(opt.template),
                $.el,
                _p.wrap_arr,
                _p.tap(opt.appending),
                _p.tap(function (el) {
                  const el_infi_wrapper = _p.first(opt.infi_wrapper_items).el;
                  const first_el_infi_items = $.find1(el_infi_wrapper, '.infi_items');
                  if (arr) arr.unshift.apply(arr, items);
                  if (!first_el_infi_items) return append_item_func(el);
                  $.prepend($.find1(first_el_infi_items, '>div'), el);
                  recur(first_el_infi_items);
                  if ($.find(el_infi_wrapper, '.infi_items:last-child .infi_item').length <= opt.limit)
                    return;
                  if (arr)
                    _p.each(items, function () {
                      arr.pop();
                    });
                  $.remove(
                    _p.last($.find(el_infi_wrapper, '.infi_items:last-child .infi_item'), items.length),
                  );
                  is_over = false;
                }),
                _p.tap(opt.appended),
              ),
              $.on(infinite_event_name, infi.handler),
            );

            function recur(el_infi_items) {
              const next_el_infi_items = $.next(el_infi_items, '.infi_items');
              if (!next_el_infi_items) return;
              $.prepend(
                $.find1(next_el_infi_items, '>div'),
                _p.last($.find(el_infi_items, '.infi_item'), items.length),
              );
              return recur(next_el_infi_items);
            }
          },
          remove: function (el_item, data_func, arr) {
            const first_el_infi_items = $.closest(el_item, '.infi_items');
            const el_infi_wrapper = $.closest(first_el_infi_items, '.infi_wrapper');
            $.remove(el_item);
            return _p.go(
              opt.scroll_target,
              $.off(infinite_event_name, infi.handler),
              _p.tap(
                function () {
                  return data_func(Math.max(offset - 1, 0), 1, el_infi_container);
                },
                _p.wrap_arr,
                _p.first,
                function (item) {
                  if (!item) return recur(first_el_infi_items);
                  if (arr) arr.push(item);
                  return _p.go(
                    item,
                    opt.template,
                    $.el,
                    _p.wrap_arr,
                    _p.tap(opt.appending),
                    function (el) {
                      recur(first_el_infi_items);
                      $.append($.find1(el_infi_wrapper, '.infi_items:last-child > div'), el);
                    },
                    _p.tap(opt.appended),
                  );
                },
              ),
              $.on(infinite_event_name, infi.handler),
            );

            function recur(el_infi_items) {
              const next_el_infi_items = $.next(el_infi_items, '.infi_items');
              if (!next_el_infi_items) return;
              $.append($.find1(el_infi_items, '>div'), $.find1(next_el_infi_items, '.infi_item:first-child'));
              return recur(next_el_infi_items);
            }
          },
        };
        infi.stop();
        if (!infi.opt.off) infi.go();
        else if (infi.opt.off) infi_view_more(infi);
        return infi;
      },
    );

    function infi_view_more(infi) {
      if (infi.opt.limit <= $.find(el_infi_container, '.infi_item').length)
        return _p.go(
          '<div class="infi_view_more"><button type="button">' + T('View more') + '</button></div>',
          $.appendTo(el_infi_container),
          $.on('click', function (e) {
            if (e.currentTarget.disabled) return;
            e.currentTarget.disabled = true;
            _p.go(infi.go(e.currentTarget), function () {
              $.css(el_infi_container, {
                height: '',
                overflow: '',
              });
            });
          }),
        );
    }

    function setOffButton() {
      $.add_class(el_infi_container, 'completed');
      if (off && opt.limit <= $.find(el_infi_container, '.infi_item').length)
        $.css(el_infi_container, {
          height: _p.min(_p.pluck(opt.infi_wrapper_items, 'height')),
          overflow: 'hidden',
        });
    }

    function get_new_items() {
      return _p.go(_p.mr(offset, opt.limit), opt.data_func, function (r) {
        if (!_p.val(r, 'length')) return (is_over = true);
        offset += opt.limit;
        return _p.go(r, _p.sum(opt.template), $.el, _p.wrap_arr, append_items);
      });
    }

    function append_item_func(el_infi_items) {
      _p.each(opt.infi_wrapper_items, function (item) {
        const el = $.el('<div class="infi_items"><div></div></div>');
        item.el_items.push({ el: el, div: $.find1(el, '>div') });
        $.append(item.el, _p.last(item.el_items).el);
      });
      return _p.each(
        el_infi_items,
        opt.col == 1
          ? function (el_infi_item, i) {
              const item = opt.infi_wrapper_items[i % opt.col];
              $.append(_p.last(item.el_items).div, el_infi_item);
              item.height = $.outerHeight(item.el);
            }
          : function (el_infi_item, i) {
              const item = _p.min(opt.infi_wrapper_items, 'height');
              $.append(_p.last(item.el_items).div, $.css(el_infi_item, { width: '100%' }));
              return new Promise(function (rs, rj) {
                const $img = $.find1(el_infi_item, 'img');
                if (!$img) {
                  item.height = $.outerHeight(item.el);
                  return rs(el_infi_item);
                }
                const img = new Image();
                img.onload = function () {
                  item.height = $.outerHeight(item.el);
                  rs(el_infi_item);
                };
                img.onerror = function (_err) {
                  rj({
                    error: Error('infi img load error'),
                    img: $img,
                  });
                };
                img.src = $img.src;
              });
            },
      );
    }

    function append_items(el_infi_items) {
      return _p.go(el_infi_items, _p.tap(opt.appending), append_item_func, _p.tap(opt.appended));
    }

    function toggle_scrolling(is_down, index, wrapper_item, splice_item, add_item) {
      if (index < 0) return;
      const arrs = wrapper_item[splice_item].splice.apply(
        wrapper_item[splice_item],
        is_down ? [0, index + 1] : [index],
      );
      wrapper_item[add_item][is_down ? 'push' : 'unshift'].apply(wrapper_item[add_item], arrs);
      _p.each(arrs, function (infi_items) {
        const is_show = $.has_class(infi_items.div, 'is_hide');
        $.css(infi_items.el, { height: is_show ? '' : $.outerHeight(infi_items.el, true) });
        $.toggle_class(infi_items.div, 'is_hide');
        // if (is_show) $.append(infi_items.el, infi_items.div);
        // else $.remove(infi_items.div);
      });
    }

    function make_infi_items(opt) {
      opt.width = opt.width || $.outerWidth(el_infi_items[0]) || 0;
      return _p.map(_p.range(opt.col), function (i) {
        return {
          el: $.css(
            $.el('<div class="infi_wrapper"></div>'),
            _p.extend(
              { position: 'relative' },
              opt.col == 1
                ? {}
                : {
                    'vertical-align': 'top',
                    display: 'inline-block',
                    width: 100 / opt.col + '%',
                  },
            ),
          ),
          el_items: [],
          hide_items1: [],
          hide_items2: [],
          height: 0,
        };
      });
    }
  };

  $.frame.defn_frame = function (opts) {
    _p.each(_p.wrap_arr(opts), function (opt) {
      if (frames[opt.frame_name]) return console.error('frame_name: ' + opt.frame_name + ' 중복 확인.');
      frames[opt.frame_name] = _p.extend(
        {
          page_name: opt.page_name,
          frame_tag: '',
          el_id: '',
          el_class: '',
          title: '',
          hide_frame_button_type: '', // ('', 'V', 'X', '그외 아무 문자'),
          custom_header: _p.noop, // (void 0, f)

          prev_frame_show: false,
          always_remove: false,

          animation: !IS_IOS,
          height: void 0,
          header_height: void 0,

          attrs: {},

          appending: _p.noop,
          appended: _p.noop,
          showing: _p.noop,
          showed: _p.noop,

          removing: _p.noop,
          removed: _p.noop,
          hiding: _p.noop,
          hided: _p.noop,
          closing: _p.noop,
          closed: _p.noop,
        },
        opt,
      );
    });
  };
  $.frame.defn_page = function (opts) {
    _p.each(_p.wrap_arr(opts), function (opt) {
      if (pages[opt.page_name]) return console.error('page_name: ' + opt.page_name + ' 중복 확인.');
      pages[opt.page_name] = _p.extend(
        {
          animation: !IS_IOS,
          el_id: '',
          el_class: '',
          title: '',
          hide_frame_button_type: '', // ('', 'V', 'X', '그외 아무 문자'),
          custom_header: _p.noop, // (void 0, f)
          is_header_fixed: false,
          attrs: {},
          tabs: [],
          appending: _p.noop,
          appended: _p.noop,
          showing: _p.noop,
          showed: _p.noop,
          removing: _p.noop,
          removed: _p.noop,
          hiding: _p.noop,
          hided: _p.noop,
        },
        opt,
      );
    });
  };

  function html_index() {
    $1('html').setAttribute('f_index', index);
  }

  function check_ing(fn, fn2) {
    return async function () {
      try {
        if (is_ing) return await _p.go(_p.to_mr(arguments), _p.fn_execute(fn2));
        is_ing = true;
        $.show(el_don_frame_screen);
        return await _p.go(
          _p.to_mr(arguments),
          fn,
          _p.tap(function () {
            is_ing = false;
            is_animation = true;
            is_popstate_ing = false;
            $.hide(el_don_frame_screen);
          }),
        );
      } catch (e) {
        ConfigSentryF.capture(e);
        $.don_loader_end();
        is_ing = false;
        is_animation = true;
        is_popstate_ing = false;
        $.hide(el_don_frame_screen);
        throw e;
      }
    };
  }

  let popstate_func = () => {};
  popstate.func = popstate_func = check_ing(
    function () {
      const back_length = index - (_p.val(history, 'state.don_frame.index') || 0);
      if (!back_length) return;
      is_popstate_ing = true;
      if (back_length < 0) return skip_popstate(back_length);
      index -= 1;
      html_index();
      const current_el_frame = _p.last($('.don_frame.is_show'));
      const el_don_pages = $.find(current_el_frame, '.don_page');
      return _p.go(skip_popstate(back_length - 1), el_don_pages.length > 1 ? back_page1 : close_frame1);
    },
    function () {
      return _p.go(void 0, sync_history_go(index - (_p.val(history, 'state.don_frame.index') || 0)));
    },
  );

  function skip_popstate(i) {
    return !i
      ? void 0
      : _p.go(
          void 0,
          _p.cb(function (next) {
            popstate.func = function () {
              popstate.func = popstate_func;
              next();
            };
            history.go(i);
          }),
        );
  }

  function start(frame_opt, page_opt) {
    frame_opt = make_frame_opt(frame_opt);
    const show_frame = $.find1($.frame.body, '.don_frame');
    if (show_frame && $.attr(show_frame, 'frame_name') != frame_opt.frame_name)
      return console.error('프레임 이름을 확인하세요.');
    return _p.go.call(
      {
        frame_opt: frame_opt,
        page_opt: make_page_opt(page_opt, frame_opt.page_name),
        ssr_frame: !!show_frame,
      },
      window,
      $.on('popstate.don_frame', function () {
        popstate.func();
      }),
      swipe_history,
      html_index,
      _p.if2(_p.a(index))(sync_history_go(-index, true)),
      function () {
        $.frame.extend_state(null, null, null, true);
      },
      _p
        .if2(
          _p.this,
          _p.val('ssr_frame'),
        )(function () {
          frame_stack.push(show_frame);
          const show_page = (this.el_don_page = $.find1(show_frame, '.don_page.is_show'));
          const show_tab = $.find1(show_page, '.don_tab.is_show');
          show_frame.frame_opt = this.frame_opt;
          show_page.page_opt = this.page_opt;
          show_tab.tab_opt = this.page_opt.tab_opt = _p.find(this.page_opt.tabs, function (tab_opt) {
            return tab_opt.tab_name == $.attr(show_tab, 'tab_name');
          });
          try {
            go(show_frame, $setAttr(show_frame.frame_opt.attrs), $data);
          } catch (e) {}
          try {
            go(show_page, $setAttr(show_page.page_opt.attrs), $data);
          } catch (e) {}
          try {
            go(show_tab, $setAttr(show_tab.tab_opt.attrs), $data);
          } catch (e) {}

          return _p.go(
            void 0,
            add_arg_val(show_frame, void 0, show_frame.frame_opt.appending),
            add_arg_val(show_frame, void 0, frame_header_height),
            add_arg_val(show_page, void 0, show_page.page_opt.appending),
            add_arg_val(show_page, show_frame, page_header_height),
            page_event.call(this, show_page.page_opt),
            add_arg_val(show_frame, void 0, show_frame.frame_opt.appended),
            add_arg_val(show_page, void 0, show_page.page_opt.appended),

            add_arg_val(show_frame, void 0, show_frame.frame_opt.showing),
            $.add_class('is_show'),
            add_arg_val(show_page, void 0, show_page.page_opt.showing),

            _p.a(show_tab),
            _p.tap(function () {
              show_tab.tab_opt.tab_top = show_frame.frame_opt.is_modal ? 0 : $.scroll_top(window);
              show_tab.tab_opt.tab_top_handler = function () {
                show_tab.tab_opt.tab_top = $.scroll_top(show_frame.frame_opt.scroll_target);
              };
            }),
            add_arg_val(show_tab, void 0, show_tab.tab_opt.appending),
            add_arg_val(show_tab, void 0, show_tab.tab_opt.appended),

            add_arg_val(show_tab, void 0, show_tab.tab_opt.showing),
            add_arg_val(show_tab, void 0, show_tab.tab_opt.showed),
            _p.a(show_tab),
            show_tab.tab_opt.infinite,
            function (infi) {
              show_tab.tab_opt.infi = infi;
            },
            add_arg_val(show_tab, void 0, show_tab.tab_opt.rendered),
            _p(tab_activate, _p, true),
            add_arg_val(show_page, void 0, show_page.page_opt.showed),
            add_arg_val(show_frame, void 0, show_frame.frame_opt.showed),
          );
        })
        .else(
          make_frame1,
          make_frame2,
          function () {
            return $.find1(this.el_don_page, '.don_tab.is_show');
          },
          _p(tab_activate, _p, true),
          function () {
            return this.el_don_frame;
          },
        ),
      _p.tap(function () {
        $.hide(el_don_frame_screen);
        $.on(document, 'keydown', function (e) {
          if ($1('.don_dialog_msg, .don_frame.is_not_esc')) return;
          if (index && e.keyCode == 27) $.frame.close();
        });
      }),
    );
  }

  function replace_tab(tab$, data_func) {
    const don_page$ = $.closest(tab$, '.don_page');
    tab_deactivate(tab$);
    $.remove(tab$);
    tab$.tab_opt = null;
    don_page$.page_opt.tab_opt.data_func = data_func;
    return _p.go(don_page$, tab_evaluator, _p('eval'), tab_activate);
  }

  function open(frame_opt, page_opt) {
    const frame_name = _p.val(frame_opt, 'frame_name');
    if (!frame_name) return console.error('프레임 이름이 없습니다.');
    const hide_frame_index = _p.val(history, 'state.don_frame.frame_index') || 0;
    frame_opt.frame_index = hide_frame_index + 1;
    frame_opt = make_frame_opt(frame_opt);
    page_opt = make_page_opt(page_opt, frame_opt.page_name);
    if ($1('.don_frame[frame_name="' + frame_name + '"][frame_tag="' + frame_opt.frame_tag + '"]')) return;
    const show_frame = frame_stack[frame_opt.frame_index];
    const is_forward_frame = _p.every(['frame_name', 'frame_index', 'frame_tag'], function (attr) {
      return $.attr(show_frame, attr) == frame_opt[attr];
    });
    const hide_frame = frame_stack[hide_frame_index];
    if (!hide_frame) return;
    const hide_tab = $.find1(hide_frame, '.don_page.is_show .don_tab.is_show');
    if (frame_opt.is_modal) {
      history.scrollRestoration = 'manual';
      if (!hide_frame.frame_opt.is_modal) $.body_fixed(true);
      frame_opt.prev_frame_show = true;
      frame_opt.animation = false;
    }
    return (frame_opt.opened =
      show_frame && is_forward_frame
        ? forward_frame(show_frame, hide_frame_index, hide_frame, hide_tab, frame_opt, page_opt)
        : _p.go.call(
            {
              frame_opt: frame_opt,
              page_opt: page_opt,
            },
            $.frame.extend_state({
              don_frame: { index: ++index, frame_name: frame_name, frame_index: frame_opt.frame_index },
            }),
            html_index,
            show_frame && !is_forward_frame ? remove_tap_page_frame() : _p.noop,
            _p.a(hide_tab),
            tab_deactivate,
            _p.a(hide_frame),
            make_frame1,
            _p.a(_p.mr(frame_opt, hide_frame)),
            hide_frames_and_animation,

            make_frame2,
            function () {
              return $.find1(this.el_don_page, '.don_tab.is_show');
            },
            tab_activate,
            function () {
              if (IS_MOBILE && !frame_opt.height) $.hide($1('#header'));
            },
            _p.this,
            _p.val('el_don_frame'),
          ));
  }

  const picks = ['showing', 'showed', 'hiding', 'hided', 'removed', 'removing', 'closing', 'closed'];
  function forward_frame(show_frame, hide_frame_index, hide_frame, hide_tab, frame_opt, page_opt) {
    show_frame = show_frame || frame_stack[hide_frame_index + 1];
    const show_page = $.find1(show_frame, '.don_page:last-child');
    const show_tab = $.find1(show_page, '.don_tab[tab_name="' + show_page.page_opt.tab_opt.tab_name + '"]');
    _p.extend(show_frame.frame_opt, _p.pick(frame_opt, picks));
    _p.extend(show_frame.page_opt, _p.pick(page_opt, picks));
    _p.each(show_page.page_opt.tabs, function (tab) {
      _p.extend(tab, _p.pick(_p.findWhere(page_opt.tabs, { tab_name: tab.tab_name }), picks));
    });
    return _p.go(
      hide_tab,
      tab_deactivate,
      sync_history_go($.find(show_frame, '.don_page').length),
      function () {
        if (show_frame.frame_opt.frame_index && show_frame.frame_opt.animation)
          $.css(show_frame, {
            transform: 'translateY(' + makeFrameHeight(show_frame.frame_opt.height) + 'px)',
          });
      },
      add_arg_val(show_frame, void 0, show_frame.frame_opt.showing),
      $.add_class('is_show'),
      add_arg_val(show_page, void 0, show_page.page_opt.showing),
      $.add_class('is_show'),
      add_arg_val(show_tab, void 0, show_tab.tab_opt.showing),
      $.add_class('is_show'),
      _p.a(show_frame),
      $.appendTo($.frame.body),

      _p.a(_p.mr(show_frame.frame_opt, hide_frame, show_frame, show_tab)),
      hide_frames_and_animation,
      _p.a(show_tab),
      tab_activate,

      add_arg_val(show_tab, void 0, show_tab.tab_opt.showed),
      add_arg_val(show_page, void 0, show_page.page_opt.showed),
      add_arg_val(show_frame, void 0, show_frame.frame_opt.showed),
      function () {
        if (IS_MOBILE && !show_frame.height) $.hide($1('#header'));
      },
      _p.a(show_frame),
    );
  }

  function hide_frames_and_animation(frame_opt, hide_frame, show_frame) {
    let i = $.attr(hide_frame, 'frame_index');
    const hide_frames = [hide_frame];
    show_frame = show_frame || this.el_don_frame;
    while (frame_stack[i].frame_opt.prev_frame_show) hide_frames.push(frame_stack[--i]);

    return frame_opt.prev_frame_show
      ? animation_open(hide_frame, void 0, show_frame)
      : _p.each(hide_frames, function (hide_frame, i) {
          const hide_page = $.find1(hide_frame, '.don_page.is_show');
          const hide_tab = $.find1(hide_page, '.don_tab.is_show');
          return _p.go(
            void 0,
            _p.a(hide_tab),
            hide_tab.tab_opt.hiding,
            _p.a(hide_page),
            hide_page.page_opt.hiding,
            _p.a(hide_frame),
            hide_frame.frame_opt.hiding,
            _p.a(_p.mr(hide_frame, hide_tab, show_frame)),
            i == 0 ? animation_open : _p.noop,
            function () {
              $.remove_class([hide_tab, hide_page, hide_frame], 'is_show');
            },
            _p.a(hide_tab),
            hide_tab.tab_opt.hided,
            _p.a(hide_page),
            hide_page.page_opt.hided,
            _p.a(hide_frame),
            hide_frame.frame_opt.hided,
            _p.a(hide_frame),
            $.remove,
          );
        });
  }

  function base_close_remove_frame1(val, is_remove) {
    const show_frame_index = _p.val(history, 'state.don_frame.frame_index') || 0;
    const close_frame = frame_stack[show_frame_index + 1];
    is_remove = is_remove || close_frame.frame_opt.always_remove;
    let i = show_frame_index;
    const show_frames = [frame_stack[i]];
    if (IS_MOBILE)
      (frame_stack[show_frame_index].frame_opt.prev_frame_show || !show_frame_index ? $.show : $.hide)(
        $1('#header'),
      );
    while (frame_stack[i].frame_opt.prev_frame_show) show_frames.unshift(frame_stack[--i]);
    return _p.go(
      show_frame_index,
      remove_check_frame1,
      _p.a(_p.mr(show_frames, close_frame, val, is_remove)),
      show_frames_close_frame_and_animation,
    );
  }
  function close_frame1(val) {
    return base_close_remove_frame1(val);
  }
  function close_remove_frame1(val) {
    return base_close_remove_frame1(val, true);
  }
  function frame_close_or_remove(func) {
    return function (val) {
      const state_frame_index = _p.val(history, 'state.don_frame.frame_index') || 0;
      const len = $.find(frame_stack[state_frame_index], '.don_page').length;
      if (state_frame_index > 0) return _p.go(void 0, sync_history_go(-len), _p.a(val), func);
      // var close_frame = frame_stack[state_frame_index];
      // if (state_frame_index > 0) return _.go(
      //   !close_frame.frame_opt.close_confirm || close_frame.frame_opt.close_confirm(),
      //   function(bool) {
      //     if (!bool) return;
      //     return _.go(void 0, sync_history_go(-len), _.a(val), func);
      //   }
      // );
    };
  }

  // frame close 나 close_remove 할때 show_frame_index보다 2개 이상 떨어진 프레임 지우기
  function remove_check_frame1(show_frame_index) {
    if (_p.rest(frame_stack, show_frame_index + 1).length < 2) return;
    return remove_tap_page_frame()();
  }

  const other_tabs_removing = base_other_tabs_remove_event('removing');
  const other_tabs_removed = base_other_tabs_remove_event('removed');

  function add_page(page_opt) {
    const page_name = _p.val(page_opt, 'page_name');
    if (!page_name) return console.error('페이지 이름이 없습니다.');
    const state = _p.val(history, 'state');
    const frame_index = _p.val(state, 'don_frame.frame_index') || 0;
    const el_don_frame = frame_stack[frame_index];
    const hide_page = $.find1(el_don_frame, '.don_page.is_show');
    const hide_tab = $.find1(hide_page, '.don_tab.is_show');
    if (el_don_frame.frame_opt.is_modal) page_opt.animation = false;
    return _p.go.call(
      {
        el_don_frame: el_don_frame,
        page_opt: make_page_opt(
          _p.extend(page_opt, { page_index: $.find(el_don_frame, '.don_page').length }),
        ),
      },
      $.frame.extend_state({
        don_frame: {
          index: ++index,
          frame_name: $.attr(el_don_frame, 'frame_name'),
          frame_index: frame_index,
        },
      }),
      html_index,
      _p.a(hide_tab),
      tab_deactivate,
      frame_index < frame_stack.length - 1 ? remove_tap_page_frame() : _p.noop,
      make_page1,
      function () {
        if (this.el_don_page.page_opt.animation)
          $.css(this.el_don_page, { transform: 'translateX(' + window.innerWidth + 'px)' });
      },
      _p.a(hide_page),
      make_page2,
      make_page3,
      _p.a(hide_tab),
      hide_tab.tab_opt.hiding,
      _p.a(hide_page),
      hide_page.page_opt.hiding,
      function () {
        return animation_add_page(el_don_frame, this.el_don_page, hide_page, hide_tab);
      },
      function () {
        $.remove_class([hide_tab, hide_page], 'is_show');
      },
      _p.a(hide_tab),
      hide_tab.tab_opt.hided,
      _p.a(hide_page),
      hide_page.page_opt.hided,
      make_page4,
      function () {
        return $.find1(this.el_don_page, '.don_tab.is_show');
      },
      tab_activate,
      function () {
        return this.el_don_page;
      },
    );
  }

  function back_page1(val) {
    history.scrollRestoration = 'manual';
    const state_frame_index = _p.val(history, 'state.don_frame.frame_index') || 0;
    const el_don_frame = frame_stack[state_frame_index];
    const hide_page = $.find1(el_don_frame, '.don_page.is_show:last-child');
    const hide_tab = $.find1(hide_page, '.don_tab.is_show');
    const show_page = $.prev(hide_page);
    const show_tab = $.find1(show_page, '.don_tab[tab_name="' + show_page.page_opt.tab_opt.tab_name + '"]');
    return _p.go(
      void 0,
      _p.tap(function () {
        if (!hide_page.page_opt.animation) return;
        const page_height =
          (el_don_frame.frame_opt.height || window.innerHeight) -
          el_don_frame.frame_opt.header_height -
          show_page.page_opt.header_height;
        _p.go(
          hide_page,
          _p.tap(
            $.find1('>.don_wrapper'),
            $.css({ top: -_p.val(hide_tab || $.find1(hide_page, '.don_tab.is_show'), 'tab_opt.tab_top') }),
          ),
          $.add_class('animating_front'),
          $.css({ height: page_height }),
        );
      }),
      state_frame_index < frame_stack.length - 1 ? remove_tap_page_frame() : _p.noop,
      _p.a(hide_tab),
      tab_deactivate,
      add_arg_val(show_page, void 0, show_page.page_opt.showing),
      $.add_class('is_show'),
      add_arg_val(show_tab, void 0, show_tab.tab_opt.showing),
      $.add_class('is_show'),
      add_arg_val(hide_tab, val, hide_tab.tab_opt.hiding),
      add_arg_val(hide_page, val, hide_page.page_opt.hiding),
      _p.a(_p.mr(el_don_frame, show_page, hide_page, hide_tab)),
      animation_back_page,
      _p.a(show_tab),
      tab_activate,
      function () {
        $.remove_class([hide_tab, hide_page], 'is_show');
      },
      add_arg_val(hide_tab, val, hide_tab.tab_opt.hided),
      add_arg_val(hide_page, val, hide_page.page_opt.hided),
      add_arg_val(hide_tab, val, hide_tab.tab_opt.removing),
      other_tabs_removing(val),
      add_arg_val(hide_page, val, hide_page.page_opt.removing),
      _p.a(hide_page),
      $.remove,
      add_arg_val(hide_tab, val, hide_tab.tab_opt.removed),
      other_tabs_removed(val),
      add_arg_val(hide_page, val, hide_page.page_opt.removed),
      add_arg_val(show_tab, void 0, show_tab.tab_opt.showed),
      add_arg_val(show_page, void 0, show_page.page_opt.showed),
      function () {
        setTimeout(function () {
          history.scrollRestoration = 'auto';
        });
      },
      _p.a(show_page),
    );
  }

  function make_page1() {
    return _p.go.call(
      this,
      void 0,
      function () {
        return this.page_opt;
      },
      (page_opt) => legacyHtml`
        <div
          class="don_page"
          page_name="${page_opt.page_name}"
          page_index="${page_opt.page_index}"
          tab_length="${page_opt.tabs.length}"
          hide_frame_button_type="${page_opt.hide_frame_button_type}"
        >
          <div class="don_wrapper">
            <div class="header">
              ${
                (page_opt.custom_header && page_opt.custom_header(page_opt)) ||
                legacyHtml`
                <button type="button" class="don_back_page">
                  <span></span>
                </button>
                <div class="title">${page_opt.title}</div>
                <div class="tab_buttons">
                  ${go(
                    page_opt.tabs,
                    zipWithIndexL,
                    strMap(
                      ([index, tab]) => legacyHtml`
                      <button class="tab_button" selected="${!!tab.selected}" tab_name="${
                        tab.tab_name
                      }" tab_index="${index}">
                        ${tab.title}
                      </button>
                    `,
                    ),
                  )}
                </div>
                <button type="button" class="don_hide_frame"></button>
              `
              }
            </div>
            <div class="body"></div>
          </div>
        </div>
      `,
      $.el,
      function (el_don_page) {
        el_don_page.id = this.page_opt.el_id;
        $.add_class(el_don_page, this.page_opt.el_class);
        $.attr(el_don_page, this.page_opt.attrs);
        return (this.el_don_page = _p.extend(el_don_page, { page_opt: this.page_opt }));
      },
      function () {
        return this.page_opt.appending(this.el_don_page);
      },
    );
  }

  function page_event(page_opt) {
    return __(
      $.on('click', '>.don_wrapper >.header .tab_buttons .tab_button[selected="false"]', async (e) => {
        try {
          await go(
            e.currentTarget,
            tap($attr('tab_name'), function (tab_name) {
              page_opt.tab_opt = _p.first(
                _p.filter(page_opt.tabs, function (tab) {
                  return (tab.selected = tab.tab_name == tab_name);
                }),
              );
            }),
            tap($setAttr({ selected: true }), $siblings, each($setAttr({ selected: false }))),
            () => {
              $.show(el_don_frame_screen);
            },
            () => tab_evaluator(this.el_don_page),
            _p('eval'),
            tab_activate,
            () => {
              $.hide(el_don_frame_screen);
            },
          );
        } catch (e) {
          console.error(e);
        } finally {
          $.hide(el_don_frame_screen);
          $.don_loader_end();
        }
      }),
      $.on('click', '>.don_wrapper >.header .don_hide_frame', function () {
        $.frame.close();
      }),
    );
  }

  function make_page2(hide_page) {
    const page_opt = this.page_opt;
    return _p.go.call(
      this,
      this.el_don_page,
      page_event.call(this, page_opt),
      function () {
        const is_modal = this.el_don_frame.frame_opt.is_modal;
        if (hide_page && !page_opt.animation)
          return _p.go($.remove_class(hide_page, 'is_show'), _p.wait(0), function () {
            if (!is_modal) return $.scroll_top2(window, 0);
          });
      },
      function () {
        return _p.mr(
          $.appendTo(this.el_don_page, $.find1(this.el_don_frame, '>.don_wrapper >.body')),
          this.el_don_frame,
        );
      },
      _p.tap(page_header_height),
      $.find1('>.don_wrapper >.header .don_back_page'),
      $.on('click', function () {
        $.frame.back_page();
      }),
      function () {
        return this.page_opt.appended(this.el_don_page);
      },
    );
  }

  function make_page3() {
    return _p.go.call(
      this,
      void 0,
      function () {
        return this.page_opt.showing(this.el_don_page);
      },
      function () {
        $.add_class(this.el_don_page, 'is_show');
      },
      function () {
        this.make_tab_result = tab_evaluator(this.el_don_page);
        if (this.make_tab_result.status == 'DONE') return this.make_tab_result.eval();
      },
    );
  }

  function make_page4() {
    return _p.go.call(
      this,
      void 0,
      function () {
        if (this.make_tab_result.status == 'ING') return this.make_tab_result.eval();
      },
      function () {
        return this.page_opt.showed(this.el_don_page);
      },
    );
  }

  function make_frame1(hide_frame) {
    return _p.go.call(
      this,
      void 0,
      _p.this,
      _p.val('frame_opt'),
      (frame_opt) => legacyHtml`
        <div
          class="don_frame"
          frame_name="${frame_opt.frame_name}"
          frame_index="${frame_opt.frame_index}"
          frame_tag="${frame_opt.frame_tag}"
          hide_frame_button_type="${frame_opt.hide_frame_button_type}"
          is_height=${!!frame_opt.height}
          is_modal=${!!frame_opt.is_modal}
        >
          <div class="don_wrapper">
            <div class="body"></div>
            <div class="header">
              ${_p.go(
                frame_opt,
                (frame_opt) =>
                  (frame_opt.custom_header && frame_opt.custom_header(frame_opt)) ||
                  legacyHtml`
                    <div class="title">${frame_opt.title}</div>
                    <button type="button" class="don_hide_frame"><span></span></button>
                  `,
              )}
            </div>
          </div>
        </div>
      `,
      $.el,
      function (el_don_frame) {
        if (this.frame_opt.is_modal)
          this.frame_opt.scroll_target = $.find1(el_don_frame, '>.don_wrapper >.body');
        el_don_frame.id = this.frame_opt.el_id;
        $.add_class(el_don_frame, this.frame_opt.el_class);
        $.attr(el_don_frame, this.frame_opt.attrs);
        this.el_don_frame = _p.extend(el_don_frame, { frame_opt: this.frame_opt });
      },
      function () {
        return this.frame_opt.appending(this.el_don_frame);
      },
      make_page1,
      function () {
        frame_stack.push(this.el_don_frame);
        if (this.frame_opt.frame_index && this.frame_opt.animation)
          $.add_class(
            $.css(this.el_don_frame, {
              transform: 'translateY(' + makeFrameHeight(this.frame_opt.height) + 'px)',
            }),
            'animating_front',
          );
        if (!this.frame_opt.animation && !this.frame_opt.prev_frame_show && hide_frame)
          return _p.go($.remove_class(hide_frame, 'is_show'), _p.wait(0), function () {
            return $.scroll_top2(window, 0);
          });
      },
      function () {
        return _p.go($.appendTo(this.el_don_frame, $.frame.body), _p.wait(6), _p.tap(frame_header_height));
      },
      $.find1('>.don_wrapper >.header .don_hide_frame'),
      $.on('click', function () {
        $.frame.close();
      }),
      function () {
        return this.frame_opt.appended(this.el_don_frame);
      },
      _p.noop,
      make_page2,
      function () {
        return this.frame_opt.showing(this.el_don_frame);
      },
      function () {
        $.add_class(this.el_don_frame, 'is_show');
      },
      make_page3,
    );
  }

  function make_frame2() {
    return _p.go.call(this, void 0, make_page4, function () {
      return this.frame_opt.showed(this.el_don_frame);
    });
  }

  function show_frames_close_frame_and_animation(show_frames, close_frame, val, is_remove) {
    history.scrollRestoration = 'manual';
    const close_page = $.find1(close_frame, '.don_page.is_show');
    const close_tab = $.find1(close_page, '.don_tab.is_show');
    const show_frame = _p.last(show_frames);
    const show_page = $.find1(show_frame, '.don_page:last-child');
    const show_tab = $.find1(show_page, '.don_tab[tab_name="' + show_page.page_opt.tab_opt.tab_name + '"]');
    const frame_height = makeFrameHeight(close_frame.frame_opt.height);
    return _p.go(
      close_tab,
      tab_deactivate,
      _p.tap(function () {
        if (close_frame.frame_opt.animation)
          return _p.go(
            close_frame,
            _p.tap($.find1('>.don_wrapper'), $.css({ top: -close_tab.tab_opt.tab_top })),
            $.add_class('animating_front'),
            $.css({ height: frame_height }),
          );
      }),
      _p.a(_p.initial(show_frames)),
      _p.each(function (show_frame) {
        if (close_frame.frame_opt.prev_frame_show) return;
        const show_page = $.find1(show_frame, '.don_page:last-child');
        const show_tab = $.find1(
          show_page,
          '.don_tab[tab_name="' + show_page.page_opt.tab_opt.tab_name + '"]',
        );
        return _p.go(
          void 0,
          add_arg_val(show_frame, void 0, show_frame.frame_opt.showing),
          $.add_class('is_show'),
          add_arg_val(show_page, void 0, show_page.page_opt.showing),
          $.add_class('is_show'),
          add_arg_val(show_tab, void 0, show_tab.tab_opt.showing),
          $.add_class('is_show'),
          _p.a(show_frame),
          $.insert_before(close_frame),
          add_arg_val(show_tab, void 0, show_tab.tab_opt.showed),
          add_arg_val(show_page, void 0, show_page.page_opt.showed),
          add_arg_val(show_frame, void 0, show_frame.frame_opt.showed),
        );
      }),

      close_frame.frame_opt.prev_frame_show
        ? _p.noop
        : _p.pipe(
            add_arg_val(show_frame, void 0, show_frame.frame_opt.showing),
            $.add_class('is_show'),
            add_arg_val(show_page, void 0, show_page.page_opt.showing),
            $.add_class('is_show'),
            add_arg_val(show_tab, void 0, show_tab.tab_opt.showing),
            $.add_class('is_show'),
            _p.a(show_frame),
            $.insert_before(close_frame),
          ),

      add_arg_val(close_frame, val, close_frame.frame_opt.closing),
      add_arg_val(close_tab, val, close_tab.tab_opt.hiding),
      add_arg_val(close_page, val, close_page.page_opt.hiding),
      add_arg_val(close_frame, val, close_frame.frame_opt.hiding),
      _p.a(_p.mr(close_frame, frame_height)),
      animation_close,
      _p.a(show_tab),
      tab_activate,
      function () {
        $.remove_class([close_tab, close_page, close_frame], 'is_show');
      },
      add_arg_val(close_tab, val, close_tab.tab_opt.hided),
      add_arg_val(close_page, val, close_page.page_opt.hided),
      add_arg_val(close_frame, val, close_frame.frame_opt.hided),
      is_remove ? remove_tap_page_frame(close_frame, val) : _p.pipe(_p.a(close_frame), $.remove),
      add_arg_val(close_frame, val, close_frame.frame_opt.closed),
      close_frame.frame_opt.is_modal
        ? function () {
            if (!show_frame.frame_opt.is_modal) $.body_fixed();
          }
        : _p.noop,
      close_frame.frame_opt.prev_frame_show
        ? _p.noop
        : _p.pipe(
            add_arg_val(show_tab, void 0, show_tab.tab_opt.showed),
            add_arg_val(show_page, void 0, show_page.page_opt.showed),
            add_arg_val(show_frame, void 0, show_frame.frame_opt.showed),
          ),
      function () {
        setTimeout(function () {
          history.scrollRestoration = 'auto';
        });
      },
      _p.a(show_frame),
    );
  }

  /* 이미 hided 된 프레임을 삭제해야 하는 상황에서 사용됨. */
  function remove_tap_page_frame(rf, val) {
    const _rf = frame_stack.pop();
    rf = rf || _rf;
    return _p.pipe(
      _p.a($.find(rf, '.don_page').reverse()),
      _p.each(function (rp) {
        const rt = $.find1(rp, '.don_tab[tab_name="' + rp.page_opt.tab_opt.tab_name + '"]');
        return _p.go(
          void 0,
          add_arg_val(rt, val, rt.tab_opt.removing),
          other_tabs_removing(val),
          add_arg_val(rp, val, rp.page_opt.removing),
          _p.a(rp),
          $.remove,
          add_arg_val(rt, val, rt.tab_opt.removed),
          other_tabs_removed(val),
          add_arg_val(rp, val, rp.page_opt.removed),
        );
      }),
      add_arg_val(rf, val, rf.frame_opt.removing),
      _p.a(rf),
      $.remove,
      add_arg_val(rf, val, rf.frame_opt.removed),
    );
  }

  function add_arg_val(el, val, func) {
    return func == _p.noop ? _p.a(el) : _p.pipe(_p.a(_p.mr(el, val)), _p.tap(func), _p.a(el));
  }

  function base_other_tabs_remove_event(name) {
    return function (val) {
      return _p.pipe(
        $.siblings,
        _p.each(function (ct) {
          return add_arg_val(ct, val, ct.tab_opt[name])();
        }),
      );
    };
  }

  function sync_history_go(i, is_start) {
    return _p.tap(
      !i
        ? _p.noop
        : _p.cb(function () {
            const next = _p.last(arguments);
            popstate.func = function () {
              popstate.func = popstate_func;
              next();
            };
            history.go(i);
          }),
      function () {
        index = _p.val(history, 'state.don_frame.index') || 0;
        html_index();
      },
    );
  }

  function make_frame_opt(frame_opt) {
    frame_opt = _p.extend({}, frames[frame_opt.frame_name], frame_opt, {
      frame_index: frame_opt.frame_index || 0,
      scroll_target: window,
    });
    frame_opt.prev_frame_show = frame_opt.prev_frame_show || !!frame_opt.height;
    return frame_opt;
  }

  function make_page_opt(page_opt, page_name) {
    page_opt = page_opt || {};
    page_name = page_name || page_opt.page_name;
    if (page_opt.tabs && page_opt.tabs.length == 1 && !_p.first(page_opt.tabs).tab_name)
      _p.first(page_opt.tabs).tab_name = _p.first(pages[page_name].tabs).tab_name;
    const is_change_selected = _p.some(_p.pluck(page_opt.tabs, 'selected'));
    return _p.extend(
      { page_index: 0 },
      pages[page_name],
      _p.omit(page_opt, 'page_name'),
      _p.go(
        pages[page_name].tabs,
        _p.map(function (defn_tab) {
          return _p.extend(
            {
              tab_name: '',
              title: '',
              inner_scroll_target: '',
              attrs: {},
              data_func: _p.noop,
              appending: _p.noop,
              appended: _p.noop,
              showing: _p.noop,
              showed: _p.noop,
              infinite: _p.noop,
              rendered: _p.noop,
              hiding: _p.noop,
              hided: _p.noop,
              removing: _p.noop,
              removed: _p.noop,
              template: _p.noop,
            },
            defn_tab,
            is_change_selected ? { selected: false } : void 0,
            _p.find(page_opt.tabs, function (opt_tab) {
              return opt_tab.tab_name == defn_tab.tab_name;
            }),
          );
        }),
        function (tabs) {
          const tab_opt =
            _p.find_where(tabs, { selected: true }) || _p.extend(_p.first(tabs), { selected: true });
          _p.each(tabs, function (tab) {
            tab.selected = tab == tab_opt;
          });
          return { tab_opt: tab_opt, tabs: tabs };
        },
      ),
    );
  }

  function tab_deactivate(hide_tab) {
    const tab_opt = _p.val(hide_tab, 'tab_opt');
    if (!tab_opt) return;
    const el_frame = $.closest(hide_tab, '.don_frame');
    tab_opt.tab_top = $.scroll_top(el_frame.frame_opt.scroll_target);
    // if (el_frame.frame_opt.is_modal) {
    // const scroll_body = $.find1(el_frame, '>.don_wrapper >.body');
    // $.isolatedScroll2_off(scroll_body);
    // _each($.find(hide_tab, tab_opt.inner_scroll_target), $.isolatedScroll2_off);
    // }
    if (tab_opt.infi) tab_opt.infi.stop();
    $.off(el_frame.frame_opt.scroll_target, 'scroll.tab_top_' + tab_opt.tab_name, tab_opt.tab_top_handler);
  }

  function tab_activate(el_don_tab, is_start) {
    return _p.go(
      el_don_tab,
      _p.tap(_p.val('tab_opt'), function (tab_opt) {
        const don_tab_scroll_top = tab_opt.tab_top;
        const show_frame = $.closest(el_don_tab, '.don_frame');
        const show_page = $.closest(el_don_tab, '.don_page');
        _p.go(
          show_frame,
          $.remove_class('animating_front animating_back'),
          $.css({ height: show_frame.frame_opt.height || '', transform: '' }),
          $.find1('>.don_wrapper'),
          $.css({ top: '' }),
        );

        _p.go(
          show_page,
          $.remove_class('animating_front animating_back'),
          $.css({ height: '', transform: '' }),
          $.find1('>.don_wrapper'),
          $.css({ top: '' }),
        );
        if (show_frame.frame_opt.is_modal) {
          // $.isolatedScroll2(show_frame.frame_opt.scroll_target);
          // _each($.find(el_don_tab, tab_opt.inner_scroll_target), $.isolatedScroll2);
          $.scroll_top2(show_frame.frame_opt.scroll_target, don_tab_scroll_top);
        } else if (!show_frame.frame_opt.height) {
          if (!is_start) $.scroll_top2(show_frame.frame_opt.scroll_target, don_tab_scroll_top);
        }
        if (tab_opt.infi && !tab_opt.infi.opt.off) tab_opt.infi.go();
        _p.defer(function () {
          $.on(
            show_frame.frame_opt.scroll_target,
            'scroll.tab_top_' + tab_opt.tab_name,
            tab_opt.tab_top_handler,
          );
        });
      }),
    );
  }

  function tab_evaluator(el_don_page) {
    const tab_opt = el_don_page.page_opt.tab_opt;
    const el_frame = $.closest(el_don_page, '.don_frame');
    let el_don_tab = $.find1(
      el_don_page,
      '>.don_wrapper >.body >.don_tab[tab_name="' + tab_opt.tab_name + '"]',
    );
    $.don_loader_start(el_don_page);
    const after = _p.pipe(
      _p.tap(tab_opt.showing),
      $.add_class('is_show'),
      _p.tap(
        $.siblings('.is_show'),
        _p.each(function (hide_tab) {
          return _p.go(
            hide_tab,
            _p.tap(hide_tab.tab_opt.hiding),
            _p.tap(tab_deactivate),
            _p.tap(function () {
              $.remove_class(hide_tab, 'is_show');
            }),
            hide_tab.tab_opt.hided,
          );
        }),
      ),
      _p.tap(tab_opt.showed, () => $.don_loader_end(el_don_page)),
    );

    if (el_don_tab) {
      return {
        status: 'DONE',
        eval: function () {
          return _p.go(el_don_tab, after);
        },
      };
    } else {
      const tab$ = $el(legacyHtml`
        <div class="don_tab ${tab_opt.el_class || ''}" id="${tab_opt.el_id}" tab_name="${tab_opt.tab_name}">
          <div class="don_wrapper"></div>
        </div>
      `);
      el_don_tab = _p.go(
        tab_opt.data_func(tab_opt),
        tab_opt.template,
        (html) => $setHTML(html, $find('>.don_wrapper', tab$)),
        () => tab$,
      );
      return {
        status: el_don_tab instanceof Promise ? 'ING' : 'DONE',
        eval: function () {
          return _p.go(
            el_don_tab,
            _p.tap(function (el_don_tab) {
              $.attr(el_don_tab, tab_opt.attrs);
              tab_opt.tab_top = 0;
              tab_opt.tab_top_handler = function () {
                tab_opt.tab_top = $.scroll_top(el_frame.frame_opt.scroll_target);
              };
              el_don_tab.tab_opt = tab_opt;
            }),
            _p.tap(tab_opt.appending),
            $.appendTo($.find1(el_don_page, '>.don_wrapper >.body')),
            _p.tap(tab_opt.appended),
            after,
            _p.tap(tab_opt.infinite, function (infi) {
              tab_opt.infi = infi;
            }),
            _p.tap(tab_opt.rendered),
          );
        },
      };
    }
  }

  function animation_open(hide_frame, hide_tab, show_frame) {
    const top = -_p.val(hide_tab || $.find1(hide_frame, '.don_tab.is_show'), 'tab_opt.tab_top');
    if (!show_frame.frame_opt.animation) return show_frame;
    return _p.go(
      hide_frame,
      $.css({
        height: makeFrameHeight(hide_frame.frame_opt.height),
      }),
      _p.if2(_p.a(!show_frame.frame_opt.height))(
        _p.tap($.find1('>.don_wrapper'), $.css({ top: top })),
        $.add_class('animating_back'),
      ),
      _p.a(show_frame),
      $.add_class('animating_front'),
      $.css({
        height: makeFrameHeight(show_frame.frame_opt.height),
        top: show_frame.frame_opt.height ? 'auto' : '',
      }),
      animate(show_frame, { translateY: 0 }),
    );
  }

  function animation_close(hide_frame, frame_height) {
    if (!is_animation || !hide_frame.frame_opt.animation) return hide_frame;
    return _p.go(hide_frame, animate(hide_frame, { translateY: frame_height }));
  }

  function animation_add_page(el_don_frame, show_page, hide_page, hide_tab) {
    if (!show_page.page_opt.animation) return show_page;
    const page_height =
      (el_don_frame.frame_opt.height || window.innerHeight) -
      el_don_frame.frame_opt.header_height -
      show_page.page_opt.header_height;
    return _p.go(
      hide_page,
      _p.tap(
        $.find1('>.don_wrapper'),
        $.css({ top: -_p.val(hide_tab || $.find1(hide_page, '.don_tab.is_show'), 'tab_opt.tab_top') }),
      ),
      $.add_class('animating_back'),
      $.css({ height: page_height }),
      _p.a(show_page),
      $.add_class('animating_front'),
      $.css({ height: page_height, transform: 'translateX(' + window.innerWidth + 'px)' }),
      _p.all(
        animate(show_page, { translateX: 0 }),
        animate(hide_page, { translateX: -window.innerWidth / 3 }),
      ),
    );
  }

  function animation_back_page(el_don_frame, show_page, hide_page) {
    if (!is_animation || !hide_page.page_opt.animation) return hide_page;
    return _p.go(
      hide_page,
      _p.all(animate(hide_page, { translateX: window.innerWidth }), animate(show_page, { translateX: 0 })),
    );
  }

  function animate(target, translate) {
    const is_down = (translate.translateX || translate.translateY) > 0;
    return _p.tap(function () {
      return anime(
        _p.extend(
          {
            targets: target,
            duration: is_down ? 300 : 500,
            easing: is_down ? 'easeInQuad' : 'easeOutQuart',
          },
          translate,
        ),
      ).finished;
    });
  }

  function swipe_history(window) {
    if (!window.navigator.userAgent.indexOf('iPhone') < 0) return;
    const animation_true = _p.debounce(function () {
      if (!is_popstate_ing)
        _p.defer(function () {
          is_animation = true;
        });
    }, 500);
    return _p.go(
      window,
      $.on('touchend', function (e) {
        if (e.changedTouches[0].clientX > 0) return;
        e.preventDefault();
        is_animation = false;
        animation_true();
      }),
    );
  }

  function frame_header_height(el_don_frame) {
    const frame_header = $.find1(el_don_frame, '>.don_wrapper >.header');
    el_don_frame.frame_opt.header_height =
      el_don_frame.frame_opt.header_height ||
      $.attr(el_don_frame, 'header_height') ||
      (el_don_frame.frame_opt.frame_index ? $.outerHeight(frame_header, true) : void 0);
    if (!el_don_frame.frame_opt.is_modal)
      $.css(el_don_frame, { 'padding-top': el_don_frame.frame_opt.header_height });
    $.css(frame_header, { height: el_don_frame.frame_opt.header_height });
    if (el_don_frame.frame_opt.height) $.css(frame_header, 'top', -el_don_frame.frame_opt.header_height);
  }

  function page_header_height(el_don_page, el_don_frame) {
    if (el_don_frame.frame_opt.height) return;
    const page_header = $.find1(el_don_page, '>.don_wrapper >.header');
    el_don_page.page_opt.header_height = el_don_page.page_opt.is_header_fixed
      ? el_don_page.page_opt.header_height ||
        $.attr(el_don_page, 'header_height') ||
        $.outerHeight(page_header, true)
      : '';
    $.css(el_don_page, {
      'padding-top': el_don_page.page_opt.header_height,
      // 'min-height': el_don_frame.frame_opt.is_modal ? '' : (el_don_frame.frame_opt.height || window.innerHeight) - el_don_frame.frame_opt.header_height - el_don_page.page_opt.header_height
    });
    if (el_don_page.page_opt.is_header_fixed)
      $.css(page_header, {
        height: el_don_page.page_opt.header_height,
        position: 'fixed',
      });
  }
})();
