import { delay } from 'fxjs/es';

/**
 *
 * @param {function(DOMHighResTimeStamp, DOMHighResTimeStamp)} fn
 * @return {{ cancel: function, pause: function, play: function }}
 */
export const onEveryAnimationFrame = (fn) => {
  const start = performance.now();
  let current_raf;
  let paused = false;

  const loop = () => {
    current_raf = requestAnimationFrame((timestamp) => {
      const require_cancel = fn(timestamp, performance.now() - start);

      if (require_cancel === true) return;
      loop();
    });
  };

  loop();

  return {
    cancel: () => {
      cancelAnimationFrame(current_raf);
    },
    pause: () => {
      cancelAnimationFrame(current_raf);
      paused = true;
    },
    play: () => {
      if (!paused) return;
      loop();
    },
  };
};

/**
 *
 * @param {function(DOMHighResTimeStamp, DOMHighResTimeStamp)} fn
 * @param {number} time
 * @return {{ cancel: function}}
 */
export const onThrottleAnimationFrame = (fn, time) => {
  const start = performance.now();
  let current_raf;

  const loop = async () => {
    await delay(time || 1000)();

    current_raf = requestAnimationFrame((timestamp) => {
      const require_cancel = fn(timestamp, performance.now() - start);

      if (require_cancel === true) return;
      loop();
    });
  };

  loop();

  return {
    cancel: () => {
      cancelAnimationFrame(current_raf);
    },
  };
};

/**
 * @dev 여러번 호출될만한 로직을 aF 단위로 스로틀링 해서 실행
 * @param {function(...[*]): void} fn
 * @return {(function(...[*]): void)}
 */
export const throttleAnimationFrameLevel = (fn) => {
  let raf;
  return function throttled(...args) {
    if (raf) return;
    raf = requestAnimationFrame(function fnAtRaf() {
      raf = null;
      fn(...args);
    });
  };
};

/**
 * @param {number} duration
 * @param {boolean} loop
 * @param {(number) => void} updateFn
 * @return {{ play: function, cancel: function, pause: function }}
 */
export const animateSimple = ({ updateFn, duration, loop }) => {
  const start = Date.now();

  const state = {
    paused_at: undefined,
    paused_time: 0,
  };

  const animation = onEveryAnimationFrame(() => {
    const total_elapsed = Date.now() - start - state.paused_time;

    updateFn(total_elapsed % duration);

    if (!loop && total_elapsed > duration) return true;
  });

  return {
    ...animation,
    pause: () => {
      if (state.paused_at) return;
      state.paused_at = Date.now();
      animation.pause();
    },
    play: () => {
      state.paused_time += Date.now() - state.paused_at;
      state.paused_at = undefined;
      animation.play();
    },
  };
};

export const runFunctionUntilTrueRaf = (fn, timeout) => {
  const startTime = performance.now();

  let isRunning = false;

  function loop() {
    if (isRunning) {
      return;
    }
    isRunning = true;

    requestAnimationFrame(() => {
      if (fn() === true) {
        isRunning = false;
        return; // Stop the loop if fn returns true
      }

      if (timeout && performance.now() - startTime >= timeout) {
        isRunning = false;
        return;
      }

      isRunning = false;
      loop();
    });
  }

  loop();
};
