export const formatMobile = (mobile?: string) => {
  if (!mobile) return mobile;
  return mobile.length <= 8
    ? mobile.replace(/(\d{4})(\d)/, '$1-$2')
    : mobile.length === 9
    ? mobile.replace(/(\d{2})(\d{3})(\d)/, '$1-$2-$3')
    : mobile.length === 10
    ? mobile.replace(/(\d{3})(\d{3})(\d)/, '$1-$2-$3')
    : mobile.length <= 11
    ? mobile.replace(/(\d{3})(\d{4})(\d)/, '$1-$2-$3')
    : mobile.replace(/(\d{4})(\d{4})(\d)/, '$1-$2-$3');
};

export const removeFormatMobile = (mobile?: string) => {
  if (!mobile) return mobile;
  return mobile.replace(/-/g, '');
};
