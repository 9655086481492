import { html } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

import { makeCrewHeaderHtml } from '../Header/S/tmpl.js';
import { makeCrewMenuNavHtml } from '../MenuNav/S/tmpl.js';

export const makeCrewLayoutHtml = ({ active_menu, active_sub_menu_path, user, crew, base_url }, body) =>
  html`
    <div class="crew">
      ${makeCrewHeaderHtml({ user, crew, base_url })}
      ${makeCrewMenuNavHtml({ active_menu, active_sub_menu_path, crew, base_url, user })}
      <section class="crew-body">${body}</section>
    </div>
  `;

export const makeUserHtml = ({ name, email, nick_name }) => html`
  <section class="user">
    ${html` <span class="user_name">${UtilS.escape(name || nick_name || '이름 없음')}</span> `}
    ${email ? html` <span class="user_email">(${UtilS.escape(email)})</span> ` : ''}
  </section>
`;
