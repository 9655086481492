import { $addClass, $attr, $closest, $el, $find, $on, $qs, $remove } from 'fxdom/es';
import { noop, go, join, map, some, reduce } from 'fxjs/es';

export function checkAdsBlockTwitter() {
  const request = new Request('https://static.ads-twitter.com/uwt.js', {
    method: 'HEAD',
    mode: 'no-cors',
  });
  return fetch(request).then(
    () => false,
    () => true,
  );
}

export const openModal = (content, { type, removeOnOut = true }) => {
  const $modal = $el(`
    <div class="don_dialog_msg modal ${type}" tabindex="1">
      <div class="content">
        <div class="body">
          <div class="msg">
            ${content}
          </div>
        </div>
      </div>
    </div>
  `);

  document.body.appendChild($modal);

  if (removeOnOut) {
    $on('click', (e) => {
      const is_content = $closest('.don_dialog_msg > .content', e.target);
      if (!is_content) $remove($modal);
    })($modal);
  }

  return $modal;
};

export async function isMimeTypeCorrect(file, pass_jpeg, pass_png, pass_bmp, pass_gif) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const signature_filter = [];
    if (pass_jpeg) signature_filter.push('ffd8');
    if (pass_png) signature_filter.push('8950');
    if (pass_bmp) signature_filter.push('424d');
    if (pass_gif) signature_filter.push('4749');
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = (e) => {
      const header = go(
        new Uint8Array(e.target.result).subarray(0, 2),
        map((x) => x.toString(16)),
        join(''),
      );
      resolve(some((signature) => signature === header, signature_filter));
    };
    fileReader.onerror = (e) => reject(e);
  });
}

export function copyText(text) {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 9999);
  document.execCommand('copy');
  document.body.removeChild(textarea);
}

export const makeKeyvisual = (el$) => {
  const swiper_container$ = $find(`.swiper-container`, el$);
  new Swiper(swiper_container$, {
    loop: true,
    pagination: {
      el: '.swiper-pagination',
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    on: { slideChange: G.don_lazy || noop },
  });
  // if (G.is_pc_size()) $addClass('swiper-no-swiping', swiper_container$);
};

export const makeSwiper = (el$) => {
  const swiper_container$ = $find(`.swiper-container`, el$);
  new Swiper(swiper_container$, {
    slidesPerView: 'auto',
    navigation: {
      nextEl: $find(`.swiper-button-next`, el$),
      prevEl: $find(`.swiper-button-prev`, el$),
    },
    on: { slideChange: G.don_lazy || noop },
  });
  if (G.is_pc_size()) $addClass('swiper-no-swiping', swiper_container$);
};

export const makeFragment = (nodes) =>
  reduce(
    (frag, node) => {
      frag.appendChild(node);
      return frag;
    },
    document.createDocumentFragment(),
    nodes,
  );

export const isPlusHtml = () => {
  return $attr('is_plus', $qs('html')) == 'true';
};

export const isMpApp = () =>
  !!window.ReactNativeWebView && window.navigator.userAgent.indexOf('MarppleApp') > -1;

export const legacy_don = typeof $ !== 'undefined' ? $ : {};
