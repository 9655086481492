import { AutoCompleteListRetrieveResponse } from '../type/autocomplete';
import axios from 'axios';
import { unEscaper } from '../../../../../modules/Util/S/Function/util';

export const getAutoCompleteListApi = async (option: {
  search?: string;
}): Promise<AutoCompleteListRetrieveResponse> => {
  const search = unEscaper(option.search ?? '').trim();

  // 2글자 미만일 때는 자동완성 미제공
  if (search.length < 2) {
    return { auto_completes: [] };
  }

  const { data } = await axios.get<AutoCompleteListRetrieveResponse>(`/${ET.lang}/@api/search/autocomplete`, {
    params: { search },
  });
  return data;
};
