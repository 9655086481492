import typo_classes from '../style/typography.scss';
import { UtilS } from '../../../../modules/Util/S/Function/module/UtilS';
import { compact, join, pipe } from '@fxts/core';

export const typography_map = {
  // kr
  '48_bold_kr': typo_classes.font_48_bold_kr,
  '40_bold_kr': typo_classes.font_40_bold_kr,
  '40_medium_kr': typo_classes.font_40_medium_kr,
  '36_bold_kr': typo_classes.font_36_bold_kr,
  '36_medium_kr': typo_classes.font_36_medium_kr,
  '32_bold_kr': typo_classes.font_32_bold_kr,
  '32_medium_kr': typo_classes.font_32_medium_kr,
  '28_bold_kr': typo_classes.font_28_bold_kr,
  '28_medium_kr': typo_classes.font_28_medium_kr,
  '28_regular_kr': typo_classes.font_28_regular_kr,
  '24_bold_kr': typo_classes.font_24_bold_kr,
  '24_medium_kr': typo_classes.font_24_medium_kr,
  '24_regular_kr': typo_classes.font_24_regular_kr,
  '22_bold_kr': typo_classes.font_22_bold_kr,
  '22_medium_kr': typo_classes.font_22_medium_kr,
  '22_regular_kr': typo_classes.font_22_regular_kr,
  '20_bold_kr': typo_classes.font_20_bold_kr,
  '20_medium_kr': typo_classes.font_20_medium_kr,
  '20_regular_kr': typo_classes.font_20_regular_kr,
  '16_bold_kr': typo_classes.font_16_bold_kr,
  '16_medium_kr': typo_classes.font_16_medium_kr,
  '16_regular_kr': typo_classes.font_16_regular_kr,
  '14_bold_kr': typo_classes.font_14_bold_kr,
  '14_medium_kr': typo_classes.font_14_medium_kr,
  '14_regular_kr': typo_classes.font_14_regular_kr,
  '12_bold_kr': typo_classes.font_12_bold_kr,
  '12_medium_kr': typo_classes.font_12_medium_kr,
  '12_regular_kr': typo_classes.font_12_regular_kr,
  '10_medium_kr': typo_classes.font_10_medium_kr,
  '10_regular_kr': typo_classes.font_10_regular_kr,
  // bebas
  bebas_96_bold: typo_classes.font_bebas_96_bold,
  bebas_90_bold: typo_classes.font_bebas_90_bold,
  bebas_60_bold: typo_classes.font_bebas_60_bold,
  bebas_48_bold: typo_classes.font_bebas_48_bold,
  bebas_40_bold: typo_classes.font_bebas_40_bold,
  bebas_32_bold: typo_classes.font_bebas_32_bold,
  bebas_24_bold: typo_classes.font_bebas_24_bold,
  bebas_20_bold: typo_classes.font_bebas_20_bold,
  bebas_16_bold: typo_classes.font_bebas_16_bold,
  bebas_14_bold: typo_classes.font_bebas_14_bold,
  // unica
  unica_32_bold: typo_classes.font_32_unica_bold,
  unica_24_bold: typo_classes.font_24_unica_bold,
  unica_24_medium: typo_classes.font_24_unica_medium,
  unica_24_regular: typo_classes.font_24_unica_regular,
  unica_20_bold: typo_classes.font_20_unica_bold,
  unica_20_medium: typo_classes.font_20_unica_medium,
  unica_20_regular: typo_classes.font_20_unica_regular,
  unica_16_bold: typo_classes.font_16_unica_bold,
  unica_16_medium: typo_classes.font_16_unica_medium,
  unica_16_regular: typo_classes.font_16_unica_regular,
  unica_14_bold: typo_classes.font_14_unica_bold,
  unica_14_medium: typo_classes.font_14_unica_medium,
  unica_14_regular: typo_classes.font_14_unica_regular,
  unica_12_bold: typo_classes.font_12_unica_bold,
  unica_12_medium: typo_classes.font_12_unica_medium,
  unica_12_regular: typo_classes.font_12_unica_regular,
  unica_10_regular: typo_classes.font_10_unica_regular,
};

export const typography_langs = {
  '48_bold': {
    kr: typography_map['48_bold_kr'],
    en: typography_map['48_bold_kr'],
    jp: typography_map['48_bold_kr'],
  },
  '40_bold': {
    kr: typography_map['40_bold_kr'],
    en: typography_map['40_bold_kr'],
    jp: typography_map['40_bold_kr'],
  },
  '40_medium': {
    kr: typography_map['40_medium_kr'],
    en: typography_map['40_medium_kr'],
    jp: typography_map['40_medium_kr'],
  },
  '36_bold': {
    kr: typography_map['36_bold_kr'],
    en: typography_map['36_bold_kr'],
    jp: typography_map['36_bold_kr'],
  },
  '36_medium': {
    kr: typography_map['36_medium_kr'],
    en: typography_map['36_medium_kr'],
    jp: typography_map['36_medium_kr'],
  },
  '32_bold': {
    kr: typography_map['32_bold_kr'],
    en: typography_map['32_bold_kr'],
    jp: typography_map['32_bold_kr'],
  },
  '32_medium': {
    kr: typography_map['32_medium_kr'],
    en: typography_map['32_medium_kr'],
    jp: typography_map['32_medium_kr'],
  },
  '28_bold': {
    kr: typography_map['28_bold_kr'],
    en: typography_map['28_bold_kr'],
    jp: typography_map['28_bold_kr'],
  },

  '28_medium': {
    kr: typography_map['28_medium_kr'],
    en: typography_map['28_medium_kr'],
    jp: typography_map['28_medium_kr'],
  },
  '28_regular': {
    kr: typography_map['28_regular_kr'],
    en: typography_map['28_regular_kr'],
    jp: typography_map['28_regular_kr'],
  },
  '24_bold': {
    kr: typography_map['24_bold_kr'],
    en: typography_map['24_bold_kr'],
    jp: typography_map['24_bold_kr'],
  },
  '24_medium': {
    kr: typography_map['24_medium_kr'],
    en: typography_map['24_medium_kr'],
    jp: typography_map['24_medium_kr'],
  },
  '24_regular': {
    kr: typography_map['24_regular_kr'],
    en: typography_map['24_regular_kr'],
    jp: typography_map['24_regular_kr'],
  },
  '22_bold': {
    kr: typography_map['22_bold_kr'],
    en: typography_map['22_bold_kr'],
    jp: typography_map['22_bold_kr'],
  },
  '22_medium': {
    kr: typography_map['22_medium_kr'],
    en: typography_map['22_medium_kr'],
    jp: typography_map['22_medium_kr'],
  },
  '22_regular': {
    kr: typography_map['22_regular_kr'],
    en: typography_map['22_regular_kr'],
    jp: typography_map['22_regular_kr'],
  },
  '20_bold': {
    kr: typography_map['20_bold_kr'],
    en: typography_map['20_bold_kr'],
    jp: typography_map['20_bold_kr'],
  },
  '20_medium': {
    kr: typography_map['20_medium_kr'],
    en: typography_map['20_medium_kr'],
    jp: typography_map['20_medium_kr'],
  },
  '20_regular': {
    kr: typography_map['20_regular_kr'],
    en: typography_map['20_regular_kr'],
    jp: typography_map['20_regular_kr'],
  },
  '16_bold': {
    kr: typography_map['16_bold_kr'],
    en: typography_map['16_bold_kr'],
    jp: typography_map['16_bold_kr'],
  },
  '16_medium': {
    kr: typography_map['16_medium_kr'],
    en: typography_map['16_medium_kr'],
    jp: typography_map['16_medium_kr'],
  },
  '16_regular': {
    kr: typography_map['16_regular_kr'],
    en: typography_map['16_regular_kr'],
    jp: typography_map['16_regular_kr'],
  },
  '14_bold': {
    kr: typography_map['14_bold_kr'],
    en: typography_map['14_bold_kr'],
    jp: typography_map['14_bold_kr'],
  },
  '14_medium': {
    kr: typography_map['14_medium_kr'],
    en: typography_map['14_medium_kr'],
    jp: typography_map['14_medium_kr'],
  },
  '14_regular': {
    kr: typography_map['14_regular_kr'],
    en: typography_map['14_regular_kr'],
    jp: typography_map['14_regular_kr'],
  },
  '12_bold': {
    kr: typography_map['12_bold_kr'],
    en: typography_map['12_bold_kr'],
    jp: typography_map['12_bold_kr'],
  },
  '12_medium': {
    kr: typography_map['12_medium_kr'],
    en: typography_map['12_medium_kr'],
    jp: typography_map['12_medium_kr'],
  },
  '12_regular': {
    kr: typography_map['12_regular_kr'],
    en: typography_map['12_regular_kr'],
    jp: typography_map['12_regular_kr'],
  },
  '10_medium': {
    kr: typography_map['10_medium_kr'],
    en: typography_map['10_medium_kr'],
    jp: typography_map['10_medium_kr'],
  },
  '10_regular': {
    kr: typography_map['10_regular_kr'],
    en: typography_map['10_regular_kr'],
    jp: typography_map['10_regular_kr'],
  },
};

export type Device = 'pc' | 'mo';
export type Lang = 'kr' | 'en' | 'jp';
export type typo_names = keyof typeof typography_langs;
export type typo_raws = keyof typeof typography_map;

export const getTypo = (name: typo_names, lang?: Lang): string => {
  if (UtilS.isNessApp()) return '';

  const i18n_lang = ET.lang;
  if (!i18n_lang || typeof i18n_lang !== 'string') {
    throw new Error('getTypo: i18next.language 가 없거나 문제가 발생했습니다.');
  }
  return typography_langs[name][lang || i18n_lang];
};

export const getTypoRaw = (name: typo_raws): string => {
  if (UtilS.isNessApp()) return '';

  return typography_map[name];
};

interface TypoPcMo {
  is_mobile: boolean;
  pc: typo_names;
  mo: typo_names;
}
export const getTypoPcMo = ({ is_mobile, pc, mo }: TypoPcMo) => {
  if (UtilS.isNessApp()) return '';

  if (is_mobile) {
    return getTypo(mo);
  }

  return getTypo(pc);
};

interface StaticTypoPcMo {
  is_mobile: boolean;
  pc: typo_raws;
  mo: typo_raws;
}

export const getStaticTypoPcMo = ({ is_mobile, pc, mo }: StaticTypoPcMo) => {
  if (UtilS.isNessApp()) return '';

  if (is_mobile) {
    return getTypoRaw(mo);
  }

  return getTypoRaw(pc);
};

function makeDeviceFontClassName(font: typo_raws, device: Device): string;
function makeDeviceFontClassName(font: typo_names, device: Device, i18n_lang: Lang): string;
function makeDeviceFontClassName(font: typo_names | typo_raws, device: Device, i18n_lang?: Lang) {
  const font_class = pipe(['font', font, i18n_lang, device], compact, join('_'));
  if (!(font_class in typo_classes)) {
    console.log(`${font_class} 가 정의되어 있지 않습니다.`);
    return '';
  }

  return typo_classes[font_class];
}

export function font(typo_name: typo_names, lang?: Lang): string;
export function font(pc_typo_name: typo_names, mo_typo_name: typo_names): string;
/**
 * MarppleShop 2.0 Foundation 의 typography 기반 font scss 클래스명을 리턴하는 함수입니다.
 * - 디바이스 상관없이 단일 폰트 스타일을 가져오려는 경우: `font(typo_name, lang?)`
 *   - 하나의 타이포그래피 스타일(`typo_name`)을 반환하며, 언어(`lang`)를 선택적으로 지정할 수 있습니다.
 *   ```typescript
 * font('48_bold'): string
 * font('48_bold', 'kr'): string
 * // 리턴 예시) 'font_48_bold_kr'
 * ```
 *
 * - pc, mo 디바이스 각각 마다 다른 폰트 스타일을 적용하려는 경우: `font(pc_typo_name, mo_typo_name)`
 *   - 데스크톱용(`pc_typo_name`)과 모바일용(`mo_typo_name`) 타이포그래피 스타일을 각각 지정하여 반환합니다.
 *   ```typescript
 * font('48_bold', '12_bold')
 * // 리턴 예시) 'font_48_bold_kr_pc font_12_bold_kr_mo'
 * ```
 * @param typo_name - 사용할 타이포그래피 스타일의 이름입니다.
 * @param pc_typo_name - 데스크톱용 타이포그래피 스타일입니다.
 * @param mo_typo_name - (선택 사항) 모바일용 타이포그래피 스타일이거나 언어 설정입니다.
 * @param lang - (선택 사항) 언어 설정에 따라 타이포그래피 스타일을 조정하려는 경우 지정합니다.
 * @returns 지정된 타이포그래피 스타일에 해당하는 CSS 클래스 이름 문자열입니다.
 */
export function font(pc_typo_name: typo_names, mo_typo_name: typo_names | (Lang | undefined)): string {
  if (UtilS.isNessApp()) return '';

  const i18n_lang = ET.lang;
  if (!i18n_lang || typeof i18n_lang !== 'string') {
    throw new Error('font: i18next.language 가 없거나 문제가 발생했습니다.');
  }
  if (!mo_typo_name || mo_typo_name === 'en' || mo_typo_name === 'jp' || mo_typo_name === 'kr') {
    return typography_langs[pc_typo_name][mo_typo_name ?? i18n_lang];
  } else {
    return `${makeDeviceFontClassName(pc_typo_name, 'pc', i18n_lang)} ${makeDeviceFontClassName(
      mo_typo_name,
      'mo',
      i18n_lang,
    )}`;
  }
}

export function staticFont(typo_raw: typo_raws): string;
export function staticFont(pc_typo_raw: typo_raws, mo_typo_raw: typo_raws): string;

/**
 * MarppleShop 2.0 Foundation 의 typography 기반 static 한 font scss 클래스명을 리턴하는 함수입니다.
 * 특정 font family 를 직접 지정하여 사용하고 싶을때 사용하시면 됩니다.
 *
 * - 디바이스 상관없이 단일 폰트 스타일을 가져오려는 경우: `staticFont(typo_raw)`
 *   - 하나의 타이포그래피 스타일(`typo_raw`)을 반환합니다.
 *   ```typescript
 * staticFont('48_bold_kr')
 * // 리턴 예시) 'font_48_bold_kr'
 * ```
 *
 * - pc, mo 디바이스 각각 마다 다른 폰트 스타일을 적용하려는 경우: `staticFont(pc_typo_raw, mo_typo_raw)`
 *   - 데스크톱용(`pc_typo_raw`)과 모바일용(`mo_typo_raw`) 타이포그래피 스타일을 각각 지정하여 반환합니다.
 *   ```typescript
 * staticFont('48_bold_kr', '12_bold_kr'): string
 * // 리턴 예시) 'font_48_bold_kr_pc font_12_bold_kr_mo'
 * ```
 * @param typo_raw - 사용할 타이포그래피 스타일의 이름입니다.
 * @param pc_typo_raw - 데스크톱용 타이포그래피 스타일입니다.
 * @param mo_typo_raw - (선택 사항) 모바일용 타이포그래피 스타일이거나 언어 설정입니다.
 * @returns 지정된 타이포그래피 스타일에 해당하는 CSS 클래스 이름 문자열입니다.
 */
export function staticFont(pc_typo_raw: typo_raws, mo_typo_raw?: typo_raws): string {
  if (UtilS.isNessApp()) return '';

  if (!mo_typo_raw) {
    return typography_map[pc_typo_raw];
  } else {
    return `${makeDeviceFontClassName(pc_typo_raw, 'pc')} ${makeDeviceFontClassName(mo_typo_raw, 'mo')}`;
  }
}
