// drag 관련 핸들링을 위한 헬퍼 함수
// draggable 이 아닌 엘리먼트 위에서 drag

import { $qs } from 'fxdom/es';
import UAParser from 'ua-parser-js';
import { pick } from 'fxjs/es';

// 임시 모바일 판단 로직, 확인되면 UtilF 로 빼기, MShopUtilF 에 있는 로직
const parser = new UAParser();
const is_mobile = ['mobile', 'tablet'].includes(parser.getDevice().type);

/**
 * @dev 일반 drag event 와는 다르게, draggable 이 아닌 대상에 대해서 drag 여부 판단
 * @param {string | HTMLElement} target selector or element
 * @param {function({ startX: number, startY: number, currentX: number, currentY: number, movementX: number, movementY: number, status: 'start' | 'move' | 'end' })} onDrag
 * @param {boolean} debug
 * @param {boolean} skip_click_event click event 를 cancel 할 것인지 여부 (drag drop 을 하면 필수적으로 click event 울림)
 */
export const onDragAction = ({ target, onDrag, skip_click_event, debug }) => {
  const target_el = typeof target == 'string' ? $qs(target) : target;

  const draft_state = {
    startX: undefined,
    startY: undefined,
    currentX: undefined,
    currentY: undefined,
    movementX: undefined,
    movementY: undefined,
    should_cancel_click: false,
    status: 'start',
  };

  let state = { ...draft_state };

  const outbound_state_keys = [
    'startX',
    'startY',
    'currentX',
    'currentY',
    'movementX',
    'movementY',
    'status',
  ];

  const event_list = is_mobile
    ? {
        start: 'touchstart',
        move: 'touchmove',
        end: 'touchend',
      }
    : {
        start: 'mousedown',
        move: 'mousemove',
        end: 'mouseup',
      };

  const cancelClick = (e) => {
    if (!state.should_cancel_click) return;
    e.preventDefault();
  };

  const parseCoordianate = (event) => {
    if (is_mobile) {
      const touch = event.changedTouches[0];
      return [touch.pageX, touch.pageY, touch.movementX, touch.movementY];
    }

    if (debug) console.log(event);
    return [event.pageX, event.pageY, event.movementX, event.movementY];
  };

  const onMove = function onMove(event) {
    if (debug) console.log(`move_drag!!`);

    [state.currentX, state.currentY, state.movementX, state.movementY] = parseCoordianate(event);

    const require_cancel = onDrag(pick(outbound_state_keys, state));

    // start 로 한번 보내고 변경, onDrag 다음에 위치해야 함
    if (state.status != 'move') {
      state.status = 'move';
    }

    if (require_cancel) {
      state = { ...draft_state };
      document.removeEventListener(event_list.move, onMove);
    }
  };

  // start move
  target_el.addEventListener(event_list.start, (e) => {
    [state.startX, state.startY] = parseCoordianate(event);
    if (debug) console.log('start drag!!');

    document.addEventListener(event_list.move, onMove);
    if (skip_click_event) {
      target_el.addEventListener('click', cancelClick);
    }
  });

  // end move
  document.addEventListener(event_list.end, (e) => {
    if (debug) console.log('end drag!!');
    // if canceled, return
    if (state.startX === undefined) return;

    state.status = 'end';

    onDrag(pick(outbound_state_keys, state));

    // remove click listener
    if (skip_click_event) {
      target_el.addEventListener('click', cancelClick);
    }

    // reset state
    state = { ...draft_state };
    document.removeEventListener(event_list.move, onMove);
  });
};
