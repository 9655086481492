import { APP_NAME, ENVIRONMENT } from '../../../S/Constant/environment.js';

export const getEnvironmentLabel = ({ is_prod = false, is_stg = false }) => {
  if (is_stg) return ENVIRONMENT.STAGING;
  if (is_prod) return ENVIRONMENT.PRODUCTION;
  return ENVIRONMENT.DEVELOPMENT;
};

export const getAppName = (app) => {
  const UNKNOWN = 'unknown';

  if (!app) return UNKNOWN;
  if (app.includes('dream_factory')) return APP_NAME.DF;
  if (app === APP_NAME.MARPPLE) return APP_NAME.MARPPLE;
  if (app === APP_NAME.MARPPLE_SHOP) return APP_NAME.MARPPLE_SHOP;
  if (app === APP_NAME.SCHEDULER) return APP_NAME.SCHEDULER;
  if (app === APP_NAME.NESS) return APP_NAME.NESS;
  return UNKNOWN;
};

export const isSentryLoggingEnabled = (environment, is_dev_enable) => {
  if (environment === ENVIRONMENT.STAGING) return true;
  if (environment === ENVIRONMENT.PRODUCTION) return true;
  if (ENVIRONMENT.DEVELOPMENT) return is_dev_enable;
};

/**
 * @param {'error'|'warning'|'info'|'debug'} level
 * @param {Error|Object} error
 */
export const getSentryLogMessage = (level, error) => {
  const message = error instanceof Error || typeof error === 'string' ? error : JSON.stringify(error);
  return `\n[Sentry Message Post] [Level: ${level}] [Message:${message}]\n`;
};
