import { html, View } from 'rune-ts';
import klass from './FeedShowInfobox.module.scss';
import format from 'date-fns/format/index.js';
import { typo } from '../../../../shared/typography/typo';

export interface FeedShowInfoBoxData {
  show_start_at?: Date;
  show_end_at?: Date;
  is_public: boolean;
  public_at?: Date;
}

export class FeedShowInfoBoxView extends View<FeedShowInfoBoxData> {
  static parseFeedStatus(feed: FeedShowInfoBoxData) {
    const is_ended = feed.show_end_at ? +new Date(feed.show_end_at) < Date.now() : false;
    const is_before = feed.show_start_at ? +new Date(feed.show_start_at) > Date.now() : false;
    const is_public =
      feed.is_public ||
      (feed.show_start_at ? +new Date(feed.show_start_at) < Date.now() && !is_ended : false);

    return {
      is_before,
      is_public,
      is_ended,
    };
  }

  override template() {
    const feed = this.data;

    const toStringDate = (date: Date) => format(date, 'yyyy.MM.dd HH:mm');

    let current_status: string;
    let period: string;

    if (feed.show_start_at && feed.show_end_at) {
      const is_ended = +new Date(feed.show_end_at) < Date.now();
      current_status = is_ended ? T('plus_admin::feed::reserve_end') : T('plus_admin::feed::reserved');
      period = `${toStringDate(feed.show_start_at)} ~ ${toStringDate(feed.show_end_at)}`;
    } else if (feed.show_start_at) {
      const is_before = +new Date(feed.show_start_at) > Date.now();
      current_status = T('plus_admin::feed::reserved');
      period = `${toStringDate(feed.show_start_at)} ${
        is_before ? T('plus_admin::feed::reserve_date_will') : T('plus_admin::feed::reserve_date_ing')
      }`;
    } else {
      current_status = feed.is_public ? T('plus_admin::feed::public') : T('plus_admin::feed::private');
      period = feed.public_at
        ? `${toStringDate(feed.public_at)} ${T('plus_admin::feed::initial_publication')}`
        : '';
    }

    return html`<div class="${klass.feed_show_info_box} ${typo('12_regular')}">
      ${current_status} ${period ? html`<span>|</span> <span>${period}</span>` : ''}
    </div> `;
  }
}
