import { type Html, html, View } from 'rune-ts';
import $dataStr from 'fxdom/es/dataStr.js';
import { makeUtilScreenNavigate } from '../../../../../shared/app/navigate';
import klass from '../ProductCard.module.scss';

export class ProductStoreName extends View<{
  store_name: string;
  domain_name: string;
  profile_image_url?: string | null;
}> {
  post_message = this.makePostMessage();

  constructor(data, private option: { klass?: string; suffix?: Html }) {
    if (!option.klass) {
      option.klass = '';
    }
    super(data, option);
  }

  protected override template() {
    return html`
      <a
        href="${this.makeLink()}"
        data-post-message="${$dataStr(this.post_message)}"
        class="${this.option.klass} ${klass.name}"
      >
        ${html.preventEscape(this.data.store_name)}${this.option.suffix || ''}
      </a>
    `;
  }

  private makeLink() {
    return `/${T.lang}/${this.data.domain_name}`;
  }

  private makePostMessage() {
    return makeUtilScreenNavigate(this.makeLink());
  }
}
