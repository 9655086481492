import { $, type Html, html, rune, View } from 'rune-ts';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { makeCartNavigate, makeHomeScreenNavigate } from '../../../../shared/app/navigate';
import { mpsPostMessage } from '../../../../shared/app/util';
import { Locals } from '../../../../shared/type/locals';
import { dataStr } from '../../../../shared/util/dataStr';
import { makeMainUrl } from '../../../../shared/util/url';
import klass from './Header.module.scss';
import modal_klass from '../../atoms/Modal/Modal.module.scss';
import Headroom from 'headroom.js';
import { htmlIf } from '../../../../shared/util';
import { AnchorTarget } from '../../../../shared/type/global';
import { getTypoRaw } from '../../../../shared/typography';
import {
  ArrowLeftIcon,
  CartFillIcon,
  CloseIcon,
  HeartFillIcon,
  MarppleShopLogo,
  MarppleShopTextLogo,
  SearchIcon,
  UserFillIcon,
} from '../../atoms/Icon';
import { typo } from '../../../../shared/typography/typo';
import { HeaderHamburgerClickEvent, HeaderLeft_MshopLogoWithMenu } from './HeaderLeft';
import { isNil } from '@fxts/core';
import { MakeOptional } from '../../../../shared/type/types';
import { HeaderWrapperProps } from './HeaderWrapper';
import { listenDocumentEvent, sendDocumentEvent } from '../../../../shared/util/event';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import { InputSearch } from '../../atoms/InputSearch/InputSearch';
import { SearchModule, SearchModuleCloseEvent } from '../SearchModule/SearchModule';
import { GnbCateList, GnbPc, GnbPcState } from '../GnbPc/GnbPc';
import { CreatorGnbProps } from '../GnbPc/template';
import { throttleAnimationFrameLevel } from '../../../../../../modules/Util/F/Function/animation';
import { Modal } from '../../atoms/Modal/Modal';
import {
  ShopProductCategory,
  ShopProductCategoryChangeEvent,
  ShopProductCategoryCloseClicked,
} from '../ShopProductCategory/ShopProductCategory';
import { CartCountView } from '../../atoms/Icon/Icons';
import { generateProductListUrl } from '../../../../features/ProductList/util';
import { SearchModuleData } from '../../../../features/Search/type';

export interface Menu {
  name: string;
  url: string;
  target?: AnchorTarget;
  is_selected?: boolean;
}

export interface HeaderData {
  menus: Menu[];
  left?: {
    whole?: Html | string;
    logo?: Html | string;
    full_width?: boolean;
    on_category?: boolean;
  };
  center?: {
    whole?: Html | string;
  };
  right?: {
    whole?: Html | string;
    off_search?: boolean;
    off_cart?: boolean;
  };
  sub_header?: View;
  cart_count: number;
  creator_template_props: CreatorGnbProps;
  cate_lists: GnbCateList[];

  // 카테고리 슬라이드 메뉴에서 활성화 할 대카테고리
  cate_list_id?: number;

  // 카테고리 슬라이드 메뉴에서 활성화 할 중카테고리
  cate_item_id?: number;

  webviewapp?: Locals['webviewapp'];
  search: SearchModuleData;
}

export class HeaderThemeChangedEvent extends CustomEvent<{
  transparent: boolean;
  instant: boolean;
}> {}

export interface HeaderState {
  transparent: boolean;
  // transparent 보다 우선순위가 높으면서 동일한 동작을 함, 임시적으로 transparent 를 조정할 때 원복을 위해서 두개로 구분
  transparent_override?: boolean;
  // todo :: @khb 경훈님 모바일 팝업스토어에서 헤더에 transparent 가 유지 되어야 하는 이슈가 있어서 조건 추가해놨습니다.
  keep_transparent?: boolean;
  is_mobile?: boolean;
  has_bottom_tab_bar?: boolean;
  has_back_btn: boolean;
  has_close_btn: boolean;
  is_fixed: boolean;
  headroom: {
    use: boolean;
    offset?: {
      up: number;
      down: number;
    };
    initial?: string;
    pinned?: string;
    unpinned?: string;
    top?: string;
    notTop?: string;
    frozen?: string;
  };
}

export type HeaderOptions = MakeOptional<
  HeaderState,
  'transparent' | 'is_mobile' | 'has_back_btn' | 'has_close_btn' | 'headroom' | 'is_fixed'
>;

type makeHtml = (args: HeaderData & HeaderState) => Html;

type PcSubViews = { gnb_pc: GnbPc; search_module?: SearchModule; input_search: InputSearch };

const makeHeaderPcHtml = (args: HeaderData & HeaderState, sub_views: PcSubViews) => {
  const { menus, transparent } = args;

  const container_classes = `${klass.header} ${htmlIf(klass.transparent, transparent)} ${htmlIf(
    klass.fixed,
    args.is_fixed,
  )}`;

  const left_part =
    args.left?.whole ??
    html`<a href="${makeMainUrl(`/${ET.lang}`)}" class="${klass.left_logo_wrapper}"
      >${args.left?.logo ?? MarppleShopTextLogo}</a
    >`;

  return html`<div class="${klass.header_outer_container}">
    <div class="${container_classes}">
      <div class="${klass.core}">
        <div class="${klass.content}">
          <div class="${klass.left_part}">${left_part}</div>

          <div class="${klass.menu_container}">
            ${menus.map(
              (menu) =>
                html`<a
                  data-name="${menu.name.toLowerCase()}"
                  href="${makeMainUrl(menu.url)}"
                  target="${menu.target || '_self'}"
                  class="${klass.menu} ${htmlIf(klass.current, !!menu.is_selected)} ${getTypoRaw(
                    'unica_16_medium',
                  )}"
                >
                  ${menu.name}
                </a>`,
            )}
          </div>
          <div class="${klass.right_part}">
            <div class="${klass.search_bar_container}">${sub_views.input_search}</div>
            <div class="${klass.icons}">
              <a href="/${ET.lang}/@/my_like" class="${klass.icon}">${HeartFillIcon}</a>
              <a href="/${ET.lang}/@/cart" class="${klass.icon}"
                >${CartFillIcon({ count: args.cart_count })}</a
              >
              <a href="/${ET.lang}/@/mypage" class="${klass.icon}">${UserFillIcon}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="${klass.gnb}">${sub_views.gnb_pc}</div>
      <div class="${klass.sub_header_container}">${args.sub_header ?? ''}</div>
    </div>
    ${
      // search banner 는 header 밖에 있어야 함 (header 가 overflow hidden)
      sub_views.search_module
    }
  </div>`;
};

const makeHeaderMoHtml: makeHtml = (args) => {
  const { transparent, has_back_btn, has_close_btn } = args;

  const container_classes = `${klass.header} ${htmlIf(klass.transparent, transparent)} ${htmlIf(
    klass.fixed,
    args.is_fixed,
  )}`;

  // 메인 파트
  const left_part =
    args.left?.whole ??
    html`<a
      href="${makeMainUrl(`/${ET.lang}`)}"
      data-post-message="${dataStr(makeHomeScreenNavigate())}"
      class="${klass.left_logo_wrapper}"
      >${args.left?.logo ?? MarppleShopLogo}</a
    >`;

  const center_part = args.center?.whole ?? '';

  const right_part =
    args.right?.whole ??
    html`<div class="${klass.icons}">
      ${args.right?.off_search ? '' : html`<span class="${klass.search_icon}">${SearchIcon}</span>`}
      ${args.right?.off_cart
        ? ''
        : html`<a
            class="${klass.icon}"
            data-post-message="${dataStr(makeCartNavigate())}"
            href="/${ET.lang}/@/cart"
            >${CartFillIcon({ count: args.cart_count })}</a
          >`}
    </div>`;

  // 부분적인 추가
  const back_btn_html = htmlIf(
    html`<span class="${klass.back_arrow_btn}">${ArrowLeftIcon}</span>`,
    has_back_btn,
  );

  const close_btn_html = htmlIf(html`<span class="${klass.close_btn}">${CloseIcon()}</span>`, has_close_btn);

  // TODO 경훈님 모바일 카테 아이템 없는 페이지에서 에러나서 우선 대강 처리해놓았습니다.
  const cate_items = [
    { id: undefined, cate_list_id: undefined, label: ET('mps2::product::all_products') },
    ...(args.cate_lists ?? []).flatMap((cate_list) => {
      const cate_item_all = {
        id: undefined,
        label: `${cate_list.name} ${ET('mps2::product::all')}`,
        cate_list_id: cate_list.id,
      };
      const cate_items = cate_list.cate_items.map((cate_item) => ({
        id: cate_item.id,
        label: cate_item.name,
        cate_list_id: cate_list.id,
      }));
      return [cate_item_all, ...cate_items];
    }),
  ];

  const cate_lists = [
    { id: undefined, label: 'ALL' },
    ...(args.cate_lists ?? []).map((cate_list) => ({ id: cate_list.id, label: cate_list.name })),
  ];

  return html`<div class="${klass.header_outer_container}">
    <div class="${container_classes}">
      <div class="${klass.core}">
        <div class="${klass.content}">
          <div class="${klass.left_part} ${htmlIf(klass.full, !!args.left?.full_width)}">
            ${back_btn_html} ${left_part}
          </div>
          <div class="${klass.center_part} ${typo('16_bold')}">${center_part}</div>
          <div class="${klass.right_part}">${right_part} ${close_btn_html}</div>
        </div>
      </div>
      <div class="${klass.sub_header_container}">${args.sub_header ?? ''}</div>
    </div>
    ${args.right?.off_search
      ? ''
      : new Modal({
          size: 'large',
          body: new SearchModule(args.search, { without_visible_interaction: true, is_mobile: true }),
          isOpen: false,
          full_width: true,
        })}
    ${args.left?.on_category && args.cate_lists.length
      ? html`<div class="${klass.mobile_category_modal}">
          ${new ShopProductCategory(
            { cate_lists, cate_items, placeholder: args.search.placeholder },
            { webviewapp: args.webviewapp },
          )}
        </div>`
      : ''}
  </div> `;
};

export class Header extends View<HeaderData> {
  state: HeaderState;
  gnb_pc: GnbPc;
  search_module?: SearchModule;
  input_search: InputSearch;
  headroom_instance: typeof Headroom;

  INPUT_SEARCH_KLASS: Readonly<string> = 'header_input_search';

  constructor(data: HeaderData, options: HeaderOptions = {}) {
    super(data, options);

    this.state = {
      ...options,
      has_back_btn: options.has_back_btn ?? false,
      has_close_btn: options.has_close_btn ?? false,
      transparent: options.transparent ?? false,
      headroom:
        !options.headroom || options.headroom.use
          ? {
              use: true,
              offset: options.headroom?.offset,
              initial: `${klass.initial} ${options.headroom?.initial ?? ''}`,
              pinned: `${klass.pinned} ${options.headroom?.pinned ?? ''}`,
              unpinned: `${klass.unpinned} ${options.headroom?.unpinned ?? ''}`,
              top: `${klass.top} ${options.headroom?.top ?? ''}`,
              notTop: `${klass.notTop} ${options.headroom?.notTop ?? ''}`,
              frozen: `${klass.frozen} ${options.headroom?.frozen ?? ''}`,
            }
          : {
              use: false,
            },
      is_fixed: options.is_fixed ?? true,
    };

    this.gnb_pc = new GnbPc({
      creator_template_props: data.creator_template_props,
      cate_lists: data.cate_lists,
    });
    this.search_module = this.data.right?.off_search
      ? undefined
      : new SearchModule(data.search, {
          klass: klass.search_module,
          is_mobile: this.getIsMobile(),
        });
    this.input_search = new InputSearch({
      ...data.search,
      transparent: options.transparent,
      backdrop_filter: options.transparent,
      off_reset: true,
      klass: this.INPUT_SEARCH_KLASS,
    });
  }

  override onMount() {
    const { headroom } = this.state;
    const is_mobile = this.getIsMobile();

    const header_outer_container_element = this.element();
    const header_element = this.element().querySelector(`.${klass.header}`) as HTMLDivElement;

    this.delegate(HeaderHamburgerClickEvent, HeaderLeft_MshopLogoWithMenu, () => {
      const mobile_category_modal_element = this.element().querySelector(`.${klass.mobile_category_modal}`);
      if (!mobile_category_modal_element) return;
      MShopUtilF.bodyFixed$(true);
      document.body.classList.add(modal_klass.modal_fixed);
      mobile_category_modal_element.classList.add(klass.on);
    });

    this.delegate(ShopProductCategoryCloseClicked, ShopProductCategory, () => {
      const mobile_category_modal_element = this.element().querySelector(`.${klass.mobile_category_modal}`);
      if (!mobile_category_modal_element) return;
      MShopUtilF.bodyFixed$(false);
      document.body.classList.remove(modal_klass.modal_fixed);
      mobile_category_modal_element.classList.remove(klass.on);
    });

    this.delegate(ShopProductCategoryChangeEvent, ShopProductCategory, (e) => {
      const { cate_list_id, cate_item_id } = e.detail;
      location.href = generateProductListUrl({ cate_list_id, cate_item_id });
    });

    this.delegate('click', `.${klass.back_arrow_btn}`, () => {
      if (MShopUtilF.isApp()) {
        mpsPostMessage({ goBack: true });
      } else {
        this.legacyHistoryBack();
      }
    });

    // 디폹트는 헤드룸
    if (headroom.use) {
      // transparent 인 경우에, headroom 이 스크롤 하자마자 동작하면 bg change 와 headroom 이 동시에 일어나기 때문에 별로임, 그래서 의도적으로 headroom 을 미룸
      this.headroom_instance = Header.initHeadroom(header_element, {
        ...this.state.headroom,
        offset:
          this.state.headroom.offset ?? this.state.transparent
            ? {
                up: 300,
                down: 300,
              }
            : undefined,
      });
    }

    // 검색 헤더
    if (is_mobile) {
      const modalView = this.subView(Modal);
      this.delegate('click', `.${klass.search_icon}`, () => {
        if (modalView) {
          modalView.open();
        }
      });
      this.delegate(SearchModuleCloseEvent, SearchModule, () => {
        if (modalView) {
          modalView.close();
        }
      });
    } else {
      const SearchModuleView = this.subView(SearchModule);
      const openSearchModuleView = () => {
        if (SearchModuleView) {
          SearchModuleView.open();
        }
      };
      const closeSearchModuleView = () => {
        if (SearchModuleView) {
          const value = SearchModuleView.close();
          const input_search_view = this.subView(InputSearch);
          input_search_view?.setValue(value);
        }
      };
      listenDocumentEvent('header_unpinned', closeSearchModuleView);
      this.delegate('focusin', InputSearch, openSearchModuleView);
      document.addEventListener('click', (e) => {
        if (!this.element().contains(e.target as HTMLElement)) {
          closeSearchModuleView();
        }
      });
    }

    const gnb_types: GnbPcState['type'][] = ['creator', 'shop', 'pop-up store'];
    this.delegate('mouseover', `.${klass.menu}`, (e) => {
      const menu_name = ((e.target as HTMLElement).dataset?.name || '') as GnbPcState['type']; // 타입 넘어가기 위함
      if (!gnb_types.includes(menu_name)) return;

      this.state.transparent_override = false;
      this.redrawBgColor(true);

      this.gnb_pc.state.is_open = true;
      this.gnb_pc.state.type = menu_name;
      this.gnb_pc.redraw();

      if (this.headroom_instance) {
        this.headroom_instance.freeze();
      }
    });

    if (!is_mobile) {
      const header_outer_container_element = this.element();
      if (!header_outer_container_element) return;

      header_outer_container_element.addEventListener('mouseleave', () => {
        this.gnb_pc.state.is_open = false;

        this.state.transparent_override = undefined;
        this.redrawBgColor(true);
        this.gnb_pc.redraw();

        if (this.headroom_instance) {
          this.headroom_instance.unfreeze();
        }
      });

      // position fixed 이어서 body 랑 가로 스크롤 js 로 엮어줘야 자연스럽게 동작
      window.addEventListener(
        'scroll',
        throttleAnimationFrameLevel(() => {
          header_element.style.left = -window.scrollX + 'px';
        }) as any,
      );
    }

    // 최초 렌더링이 투명인 경우만, 아래로 내려갔을 때 background white 로 바꿔줌
    // todo :: @khb 경훈님 모바일 팝업스토어에서 헤더에 transparent 가 유지 되어야 하는 이슈가 있어서 조건 추가해놨습니다.
    if (this.state.transparent && !this.state.keep_transparent) {
      const bg_change_observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry) return;
          this.state.transparent = entry.isIntersecting;
          this.redrawBgColor();
        },
        {
          rootMargin: '0px 0px 0px 0px',
        },
      );

      bg_change_observer.observe(header_outer_container_element);
    }
  }

  static initHeadroom(
    headroom_el: HTMLElement,
    headroom_options: HeaderWrapperProps['header']['options']['headroom'],
  ) {
    const headroom = new Headroom(headroom_el, {
      offset: headroom_options?.offset,
      tolerance: {
        up: 10,
        down: 10,
      },
      classes: {
        initial: `${klass.initial} ${headroom_options?.initial ?? ''}`.trim(),
        pinned: `${klass.pinned} ${headroom_options?.pinned ?? ''}`.trim(),
        unpinned: `${klass.unpinned} ${headroom_options?.unpinned ?? ''}`.trim(),
        top: `${klass.top} ${headroom_options?.top ?? 'headroom--top'}`.trim(),
        notTop: `${klass.notTop} ${headroom_options?.notTop ?? 'headroom--not-top'}`.trim(),
        frozen: `${klass.frozen} ${headroom_options?.frozen ?? ''}`.trim(),
      },
      onPin: () => sendDocumentEvent('header_pinned'),
      onUnpin: () => sendDocumentEvent('header_unpinned'),
      onTop: () => sendDocumentEvent('header_top'),
      onNotTop: () => sendDocumentEvent('header_nottop'),
      onBottom: () => sendDocumentEvent('header_bottom'),
      onNotBottom: () => sendDocumentEvent('header_notbottom'),
    });

    headroom.init();

    return headroom;
  }

  getIsMobile(): boolean {
    const is_mobile = rune.getSharedData(this)?.is_mobile ?? this.state.is_mobile;
    if (isNil(is_mobile)) throw new Error('is_mobile is not set');
    return is_mobile;
  }

  redrawBgColor(instant = false) {
    // override 없으면
    const transparent_value = this.state.transparent_override ?? this.state.transparent;

    const header_element = this.element().querySelector(`.${klass.header}`) as HTMLDivElement;
    const search_input_el = this.element().querySelector(`.${this.INPUT_SEARCH_KLASS}`) as HTMLDivElement;

    this.dispatchEvent(HeaderThemeChangedEvent, {
      bubbles: true,
      detail: {
        instant,
        transparent: transparent_value,
      },
    });

    if (instant) {
      header_element.classList.add(klass.instant_bg_change);
    }

    if (transparent_value) {
      header_element.classList.add(klass.transparent);
      if (search_input_el) {
        search_input_el.classList.add(InputSearch.TRANSPARENT_KLASS);
      }
    } else {
      header_element.classList.remove(klass.transparent);
      if (search_input_el) {
        search_input_el.classList.remove(InputSearch.TRANSPARENT_KLASS);
      }
    }

    if (instant) {
      header_element.offsetHeight; // force reflow
      header_element.classList.remove(klass.instant_bg_change);
    }
  }

  // render 이후에 실행할 것
  redrawBgToWhiteWhenScroll() {
    const transparent = !(window.scrollY > 10);
    this.state.transparent = transparent;
    this.redrawBgColor();

    window.addEventListener(
      'scroll',
      throttleAnimationFrameLevel(() => {
        const transparent = !(window.scrollY > 10);
        if (this.state.transparent == transparent) return;
        this.state.transparent = transparent;

        this.redrawBgColor();
      }) as any,
    );
  }

  override template() {
    const args = { ...this.data, ...this.state };
    return this.getIsMobile() ? makeHeaderMoHtml(args) : makeHeaderPcHtml(args, this);
  }

  public redrawCartCount = (cart_count: number): this => {
    this.data.cart_count = cart_count;
    const cartCountView = this.subView(CartCountView);

    if (!this.data.cart_count) {
      cartCountView?.element().remove();
      return this;
    }

    if (cartCountView) {
      cartCountView.setCount(this.data.cart_count).redraw();
    } else {
      this.element()
        .querySelector('.cart-fill-icon')
        ?.append(new CartCountView({ count: this.data.cart_count }).render());
    }

    return this;
  };

  static FROM_MAIN_REGEXP = {
    production: /marpple\.shop/,
    staging: /marpple\.(cc|tw)/,
    development: /(:9077|:9078|:9079|:11001|:11002|:11003)/,
  };

  private legacyHistoryBack() {
    const noBack = () => {
      if (box.sel('store_url'))
        window.location.href = box.sel('store_url') + (box.sel('is_studio') ? '/settings/styles' : '');
      else window.location.href = `/${T.lang}`;
    };

    if (UtilS.isNboxApp()) {
      window.history.back();
    } else {
      if (
        Header.FROM_MAIN_REGEXP[
          (process.env.NODE_ENV as 'staging' | 'production' | 'development') || 'development'
        ].test(document.referrer)
      ) {
        window.history.back();
      } else noBack();
    }
  }

  public setForceUnpinned() {
    const header_el = this.element().querySelector(`.${klass.header}`) as HTMLDivElement;

    if (!$(header_el).hasClass(klass.unpinned)) {
      $(header_el).addClass(klass.unpinned);
      return true;
    }

    return false;
  }

  public checkPinned() {
    return $(this.element().querySelector(`.${klass.header}`))!.hasClass(klass.pinned);
  }
}
