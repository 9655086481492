import { html, strMap } from 'fxjs/es';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { typo } from '../../../../../services/MarppleShop/shared/typography/typo.js';
import { enFirst } from '../../../Main/S/Tmpl/enFrist.js';
import { jpFirst } from '../../../Main/S/Tmpl/jpFirst.js';
import { MpShopPlusPrivacyTmplS } from '../../../../MpShopPlus/Privacy/S/Tmpl/module/MpShopPlusPrivacyTmplS.js';

export const privacy = (tds, privacy_href) => html`
  <div class="privacy-popup">
    <div class="privacy-popup__body">
      <table class="privacy-popup__table">
        <thead>
          <tr>
            <th class="${typo('12_bold')}">수집 목적</th>
            <th class="${typo('12_bold')}">수집 항목</th>
            <th class="${typo('12_bold')}">보유 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            ${strMap((td) => html` <td class="${typo('12_medium')}">${td}</td> `, tds)}
          </tr>
        </tbody>
      </table>
      <p class="privacy-popup__p ${typo('12_medium')}">
        개인정보 제공에 동의하지 않으실 수 있으며, 동의하지 않으실 경우 서비스 이용이 제한될 수 있습니다.<br />
        그 밖의 사항은 (주)마플코퍼레이션의
        <a
          href="${privacy_href || `/${T.lang}/${G.collabo_type == 'creator' ? '@/' : ''}privacy`}"
          target="_blank"
          >개인정보처리방침</a
        >에 따릅니다.
      </p>
      <div class="option">
        <button class="done">확인</button>
      </div>
    </div>
  </div>
`;

const privacyNexon = (tds, privacy_href) => html`
  <div class="privacy-popup">
    <div class="privacy-popup__body">
      <p class="privacy-popup__sub-title">㈜넥슨코리아는 다음과 같이 개인정보를 수집 및 이용하고 있습니다.</p>
      <table class="privacy-popup__table">
        <thead>
          <tr>
            <th>수집 및 이용 목적</th>
            <th>항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            ${strMap((td) => html` <td>${td}</td> `, tds)}
          </tr>
        </tbody>
      </table>
      <p class="privacy-popup__p">
        동의를 거부할 권리가 있으며, 거부 시 상품 구매가 제한됩니다.<br />
        ※ 그 외의 사항 및 자동 수집 정보와 관련된 사항은
        <a
          href="${privacy_href || `/${T.lang}/${G.collabo_type == 'creator' ? '@/' : ''}privacy`}"
          target="_blank"
          >개인정보처리방침</a
        >을 따릅니다.
      </p>
      <div class="option">
        <button class="done">확인</button>
      </div>
    </div>
  </div>
`;

const privacyNexonRowSpan = (trs, privacy_href) => html`
  <div class="privacy-popup">
    <div class="privacy-popup__body">
      <p class="privacy-popup__sub-title">㈜넥슨코리아는 다음과 같이 개인정보를 수집 및 이용하고 있습니다.</p>
      <table class="privacy-popup__table">
        <thead>
          <tr>
            <th>수집 및 이용 목적</th>
            <th>항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
        </thead>
        <tbody>
          ${strMap(
            (tds) =>
              html`
                <tr>
                  ${strMap((td) => html`<td rowspan="${td.rowspan}">${td.text}</td>`, tds)}
                </tr>
              `,
            trs,
          )}
        </tbody>
      </table>
      <p class="privacy-popup__p">
        동의를 거부할 권리가 있으며, 거부 시 상품 구매가 제한됩니다.<br />
        ※ 그 외의 사항 및 자동 수집 정보와 관련된 사항은
        <a
          href="${privacy_href || `/${T.lang}/${G.collabo_type == 'creator' ? '@/' : ''}privacy`}"
          target="_blank"
          >개인정보처리방침</a
        >을 따릅니다.
      </p>
      <div class="option">
        <button class="done">확인</button>
      </div>
    </div>
  </div>
`;

const loginKr = () =>
  privacy([
    '회원제 서비스 이용에 따른 본인 식별',
    '이름, 이메일, 비밀번호',
    '회원 탈퇴하거나 회원에 제명된 때 즉시 파기',
  ]);

const LOGIN_PRIVACY_HTML = {
  kr: loginKr,
  en: enFirst,
  jp: jpFirst,
};

export const login = () => LOGIN_PRIVACY_HTML[T.lang]() || '';

const loginCheckoutKr = () =>
  privacy([
    '상품/경품 배송 및 계약이행,<br>환불 및 상담 회신',
    '주문자 정보: 이름, 연락처, 이메일<br>수령자 정보: 이름, 연락처, 배송지',
    '개인정보 수집 및 이용목적 달성 시 및<br>관련 법령에 따른 기간까지 보관',
  ]);

const loginCheckoutNBoxKr = () =>
  privacyNexon(
    [
      '주문자 식별, 주문 접수 및 관련<br>안내, 물품 배송, 문의 대응',
      '이름, 휴대전화번호, 이메일 주소,<br>배송지 주소',
      `<b>구매일로부터 5년<br>(법령에 특별한 규정이 있을 경우<br>관련 법령에 따라 보관)</b>`,
    ],
    `/${T.lang}/@/privacy-nbox`,
  );

const loginCheckoutNessKr = () =>
  privacyNexon(
    [
      '주문, 구매, 배송, 교환, 환불, 반품, 회수, 고객 상담 및 서비스 제공, 공지사항 전달',
      '이름, 휴대전화번호, 이메일주소, 주소',
      `<b>전자상거래법에 따른 의무 보유기간까지</b>`,
    ],
    `https://member.nexon.com/policy/privacy.aspx`,
  );

const legalGuardianNessKr = () =>
  privacyNexonRowSpan(
    [
      [
        { rowspan: 2, text: '본인확인, 동의 의사 확인, 공지사항 전달, 동의 이력 관리' },
        { rowspan: 1, text: '이름, 생년월일, 성별, 연계정보(CI)' },
        { rowspan: 1, text: '<b>본인 인증 및 동의 의사 확인 후 파기</b>' },
      ],
      [
        { rowspan: 1, text: '중복가입정보(DI)' },
        { rowspan: 1, text: '<b>아동이 성인이 된 후 3년까지</b>' },
      ],
    ],
    `https://member.nexon.com/policy/privacy.aspx`,
  );

const legalGuardianChildNessKr = () =>
  privacyNexon(
    [
      '주문, 구매, 배송, 교환, 환불, 반품, 회수, 고객 상담 및 서비스 제공, 공지사항 전달',
      '이름, 휴대전화번호, 이메일주소, 주소',
      `<b>전자상거래법에 따른 의무 보유기간까지</b>`,
    ],
    `/${T.lang}/@/privacy-nbox`,
  );

const LOGIN_CHECKOUT_PRIVACY_HTML = {
  kr: loginCheckoutKr,
  en: enFirst,
  jp: jpFirst,
};
const LOGIN_CHECKOUT_NBOX_PRIVACY_HTML = {
  kr: loginCheckoutNBoxKr,
  en: MpShopPlusPrivacyTmplS.nbox_privacy_popup,
  jp: MpShopPlusPrivacyTmplS.nbox_privacy_popup,
};

export const loginCheckout = () => LOGIN_CHECKOUT_PRIVACY_HTML[T.lang]() || '';
export const loginCheckoutNBox = () => LOGIN_CHECKOUT_NBOX_PRIVACY_HTML[T.lang]() || '';
export const loginCheckoutNess = loginCheckoutNessKr;
export const legalGuardianNess = {
  privacy_child: legalGuardianChildNessKr,
  privacy_guardian: legalGuardianNessKr,
};

const signUpCheckoutKr = () =>
  privacy([
    `주문자 정보(회원 가입 정보) : 회원제 서비스 이용에 따른 본인 식별<br>수령자 정보 : 상품/경품 배송 및 계약이행, 환불 및 상담 회신`,
    `주문자 정보(회원 가입 정보) : 이름, 연락처, 이메일, 비밀번호<br>수령자 정보: 이름, 연락처, 배송지 `,
    `주문자 정보(회원 가입 정보) : 회원 탈퇴하거나 회원에 제명된 때 즉시 파기<br>수령자 정보 : 개인정보 수집 및 이용목적 달성 시 및 관련 법령에 따른 기간까지 보관`,
  ]);

const SIGN_UP_CHECKOUT_PRIVACY_HTML = {
  kr: signUpCheckoutKr,
  en: enFirst,
  jp: jpFirst,
};

export const signUpCheckout = () => SIGN_UP_CHECKOUT_PRIVACY_HTML[T.lang]() || '';

export const checkoutThirdParties = () => {
  const is_ness = UtilS.isNessApp();
  if (is_ness) {
    return html` <div class="privacy-popup">
      <div class="privacy-popup__body">
        <p class="privacy-popup__p">
          ㈜넥슨코리아는 마비노기 굿즈 상품 구매에 따른 주문, 고객상담, 배송 목적으로
          ㈜비에이치쓰리디조형학원에 개인정보를 제공합니다.
        </p>
        <div class="option">
          <button class="done">확인</button>
        </div>
      </div>
    </div>`;
  }

  return html`
    <div class="privacy-popup">
      <div class="privacy-popup__body">
        <p class="privacy-popup__p">
          ㈜마플코퍼레이션의 회원계정으로 셀러배송상품 및 셀러의 독자적인 서비스를 구매하고자 할 경우,
          ㈜마플코퍼레이션은 개인정보보호법 제 17조 (개인정보의 제공)에 따라 거래 당사자간 원활한 의사소통 및
          배송, 상담 등 거래이행을 위하여 필요한 최소한의 개인정보만을 마플샵 입점 셀러 및 배송사에 아래와
          같이 공유하고 있습니다.
          <br /><br />

          ① 개인정보를 공유받는자 : 주문한 상품을 발송할 마플샵 셀러<br />
          ② 공유하는 개인정보 항목 - 구매자 정보: 성명, 전화번호, ID, 휴대전화 번호, 메일주소, 상품 구매정보 -
          수령자 정보: 성명, 전화번호, 휴대전화 번호, 배송지 주소<br />
          ③ 개인정보를 공유받는 자의 이용 목적 : 크리에이터와 구매자의 거래의 원활한 진행, 본인의사의 확인,
          고객 상담 및 불만처리, 상품과 경품 배송을 위한 배송지 확인 등<br />
          ④ 개인정보를 공유받는 자의 개인정보 보유 및 이용 기간 : 상품 구매/배송/반품 등 서비스 처리 완료 후
          180일간 보관 후 파기<br />
          단, 관계 법령의 규정에 의하여 일정 기간 보존이 필요한 경우에는 해당 기간만큼 보관 후 삭제합니다.<br />
          ➄동의 거부 시 불이익 : 본 개인정보 공유에 동의하지 않으시는 경우, 동의를 거부할 수 있으며, 이 경우
          거래가 제한됩니다.
        </p>
        <div class="option">
          <button class="done">확인</button>
        </div>
      </div>
    </div>
  `;
};
