import { curry2, filter, go, html, sel, strMap, isUndefined } from 'fxjs/es';
import { NessOutboundS } from '../../../../Ness/Outbound/S/Function/module/NessOutboundS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { checkActions } from '../../../Authority/S/util.js';
import { checkIf } from '../../../Setting/S/tmpl.js';
import { isAllCrew } from '../../S/util.js';
import {
  CONFIRMATIONS_MENU,
  CREW_ADJUSTMENT_MENU,
  CREW_BANK_ACCOUNT_MENU,
  CREW_PB_ADJUSTMENT_MENU,
  CREW_WITHDRAW_MENU,
  CREW_YOUTUBE_MENU,
  DISAPPROVAL_STORE_LIST_MENU,
  INACTIVE_SELLER_LIST_MENU,
  INVITED_STORE_LIST_MENU,
  MANAGER_MANAGEMENT_MENU,
  MENU_SPLITER,
  PB_ME_MENU,
  PB_SELLER_MANAGEMENT_MENU,
  PRODUCT_CHECK_MENU,
  PRODUCT_LIST_MENU,
  PROFIT_MENU,
  REJECTED_STORE_LIST_MENU,
  SAMPLE_CHART_MENU,
  SELLER_MANAGEMENT_MENU,
  SELLER_WITHDRAW_MENU,
  SITE_MANAGE_MENU,
  SOLUTION_MENU,
  UNREGISTERED_STORE_LIST_MENU,
} from './constant.js';

const MENUS = [
  SELLER_MANAGEMENT_MENU,
  PB_SELLER_MANAGEMENT_MENU,
  INACTIVE_SELLER_LIST_MENU,
  DISAPPROVAL_STORE_LIST_MENU,
  INVITED_STORE_LIST_MENU,
  UNREGISTERED_STORE_LIST_MENU,
  REJECTED_STORE_LIST_MENU,
  PROFIT_MENU,
  CREW_ADJUSTMENT_MENU,
  CREW_PB_ADJUSTMENT_MENU,
  SELLER_WITHDRAW_MENU,
  CREW_WITHDRAW_MENU,
  CREW_BANK_ACCOUNT_MENU,
  CONFIRMATIONS_MENU,
  SAMPLE_CHART_MENU,
  PRODUCT_LIST_MENU,
  PRODUCT_CHECK_MENU,
  PB_ME_MENU,
  CREW_YOUTUBE_MENU,
  // ARCHIVE_MENU,
  // SELLER_TIP_MENU,
  SOLUTION_MENU,
  MENU_SPLITER,
  SITE_MANAGE_MENU,
  MANAGER_MANAGEMENT_MENU,
];

const makeMenuUrl = curry2((base_url, crew, path) =>
  !crew ? '#' : `${base_url}/${sel('domain_name', crew)}/${path}`,
);

const predicateNessSpecific = (menu, crew) => {
  if (!UtilS.isNessApp()) return true;

  // ness crew => ghost 이고 임시, editorial 작성 용으로만 존재
  if (NessOutboundS.isNessCrew(crew)) {
    // ness 일때는 사이트 관리, 매니저 관리만 남기기
    return menu.path == 'website' || menu.path == 'managers' || menu.path == 'events';
  }

  // ghost 를 제외한 일반 플러스들 (ex, maplestory, 던파 등) 에서는 컨텐츠 아직 안씀
  return menu.path !== 'events';
};

const makeSubmenus = ({ is_active_menu, active_sub_menu_path, makePath, menu, crew }) => html`
  <li data-selected="${is_active_menu}">
    <input id="menu_dropdown-${menu.path}" class="menu_dropdown" type="checkbox" ${checkIf(is_active_menu)} />
    <label class="menu" for="menu_dropdown-${menu.path}">
      <div class="icon-container">${menu.icon_html}</div>

      <span>${menu.name}</span>
    </label>
    <ul class="sub_menus">
      ${go(
        menu.sub_menus,
        filter((sub_menu) => predicateNessSpecific(sub_menu, crew)),
        strMap((sub_menu) => {
          if (
            isUndefined(sub_menu.is_all_crew) ||
            (sub_menu.is_all_crew && isAllCrew(crew)) ||
            (!sub_menu.is_all_crew && !isAllCrew(crew))
          ) {
            return html`
              <li data-selected="${active_sub_menu_path == sub_menu.path}">
                <a class="menu sub_menu" href="${makePath(`${menu.path}/${sub_menu.path}`)}">
                  ${sub_menu.name}
                </a>
              </li>
            `;
          }

          return '';
        }),
      )}
    </ul>
  </li>
`;

export const makeCrewMenuNavHtml = ({ base_url, active_menu, active_sub_menu_path, crew, user }) => {
  const active_menu_path = active_menu ? sel('path', active_menu) : null;

  return html`
    <nav class="crew-menu_nav">
      <ul class="menus">
        ${go(
          MENUS,
          filter((menu) => predicateNessSpecific(menu, crew)),
          filter(({ action_option = {} }) => checkActions(action_option, { user })),
          filter((menu) => (menu.filter ? menu.filter(crew, user) : true)),
          strMap((menu) => {
            if (menu.html) return menu.html;
            return !menu.sub_menus
              ? html`<li data-selected="${active_menu_path === menu.path}">
                  <a class="menu" href="${makeMenuUrl(base_url, crew, menu.path)}">
                    <div class="icon-container">${menu.icon_html}</div>
                    <span>${menu.name}</span>
                  </a>
                </li>`
              : makeSubmenus({
                  is_active_menu: active_menu_path === menu.path,
                  active_sub_menu_path,
                  makePath: makeMenuUrl(base_url, crew),
                  menu,
                  crew,
                });
          }),
        )}
      </ul>
    </nav>
  `;
};
