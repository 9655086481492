import { html } from 'fxjs/es';
import { NessCoreConstantS } from '../Constant/module/NessCoreConstantS.js';
import { getInicisIcon1, getInicisIcon2, getInstagramIcon, getNessSimpleIcon, getNexonIcon } from './icon.js';

/**
 *
 * @param {boolean} is_mobile
 * @return {string}
 */
export const createDefaultFooter = ({ is_mobile }) => {
  const component = NessCoreConstantS.layout_components.footer;

  const texts = {
    company: ET('ness::footer::company'),
    company_name: ET('ness::footer::company_name'),
    customer_center: ET('ness::footer::customer_center'),
    ceo: ET('ness::footer::ceo'),
    ceo_name: ET('ness::footer::ceo_name'),
    address: ET('ness::footer::address'),
    address_info: ET('ness::footer::address_info'),
    address_info_part_1: ET('ness::footer::address_info_part1'),
    address_info_part_2: ET('ness::footer::address_info_part2'),
    company_registration_info: ET('ness::footer::company_registration_info'),
    company_registration_number: ET('ness::footer::company_registration_number'),
    footer1: ET('ness::footer::footer1'),
    footer2: ET('ness::footer::footer2'),
    footer4: ET('ness::footer::footer4'),
    footer5: ET('ness::footer::footer5'),
    footer6: ET('ness::footer::footer6'),
    footer7: ET('ness::footer::footer7'),
    footer8: ET('ness::footer::footer8'),
    footer9: ET('ness::footer::footer9'),
    footer10: ET('ness::footer::footer10'),
    footer11: ET('ness::footer::footer11'),
    footer12: ET('ness::footer::footer12'),
    footer13: ET('ness::footer::footer13'),
    footer14: ET('ness::footer::footer14'),
    footer15: ET('ness::footer::footer15'),
  };

  if (is_mobile) {
    return html`
      <div class="${component}">
        <div class="${component}__logo-container">
          <div class="${component}__ness-icon">${getNessSimpleIcon({ height_dependent: true })}</div>

          <div class="${component}__sns-container">
            <a href="https://www.nexon.com/Home/Game" target="_blank" class="${component}__sns">
              ${getNexonIcon()}
            </a>
            <a href="https://www.instagram.com/nexon_essential/" target="_blank" class="${component}__sns">
              ${getInstagramIcon()}
            </a>
          </div>
        </div>

        <div class="${component}__block-group">
          <div class="${component}__block">
            <div class="${component}__block-title">
              <span
                ><a href="https://member.nexon.com/policy/privacy.aspx"><b>${texts.footer2}</b></a></span
              >
              <span>
                <a href="/@/terms">${texts.footer1}</a>
              </span>
            </div>

            <div class="${component}__row">
              <span class="${component}__row-title">${texts.company}</span>
              <span>${texts.company_name}</span>
            </div>
            <div class="${component}__row">
              <span class="${component}__row-title">${texts.ceo}</span>
              <span>${texts.ceo_name}</span>
            </div>
            <div class="${component}__row">
              <span class="${component}__row-title">${texts.address}</span>
              <span>${texts.address_info}</span>
            </div>
            <div class="${component}__row">
              <span class="${component}__row-title"> ${texts.company_registration_info} </span>
              <span>${texts.company_registration_number}</span>
              <a
                href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208717483&apv_perm_no="
                target="_blank"
                class="${component}__row-link"
              >
                <u>${texts.footer8}</u>
              </a>
            </div>
            <div data-show="${ET.lang !== 'kr'}" class="${component}__row">
              <div class="${component}__row-title">통신판매업 신고번호</div>
              <div>제2013-경기성남-1659호</div>
            </div>
            <div class="${component}__row">
              <div class="${component}__row-title">${texts.footer12}</div>
              <div>${texts.footer13}</div>
            </div>
          </div>

          <div class="${component}__block">
            <div class="${component}__block-title"><b>${texts.customer_center}</b></div>

            <div class="${component}__row">
              <span class="${component}__row-title">${texts.footer4}</span>
              <span>1566-7960</span>
            </div>

            <div class="${component}__row">
              <span class="${component}__row-title">${texts.footer10}</span>
              <span>nexon@marpple.com</span>
            </div>

            <div class="${component}__row">
              <span class="${component}__row-title">${texts.address}</span>
              <span>${texts.footer14}</span>
            </div>

            <div class="${component}__row">
              <div class="${component}__row-title">${texts.footer9}</div>
              <div class="${component}__row-value">
                <span>${texts.footer5} 10:00 - 18:00</span>
                <span>${texts.footer6} 12:00 - 13:00</span>
                <span>${texts.footer7}</span>
              </div>
            </div>
          </div>

          <div class="${component}__instructions">
            <div class="${component}__inicis-icons">
              <span class="${component}__inicis-icon">${getInicisIcon1()}</span>
              <span class="${component}__inicis-icon">${getInicisIcon2()}</span>
            </div>
            <span class="${component}__inicis-instruction">${texts.footer15}</span>
            <span class="${component}__legal-instruction"> ${texts.footer11} </span>
            <span class="${component}__right-instruction">
              ⓒ NEXON Korea Corporation All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    `;
  }

  return html`<div class="${component}">
    <div class="${component}__left">
      <span class="${component}__ness-icon">${getNessSimpleIcon({ height_dependent: true })}</span>
      <div class="${component}__terms-and-privacy">
        <span
          ><a href="https://member.nexon.com/policy/privacy.aspx"><b>${texts.footer2}</b></a></span
        >
        <span>
          <a href="/@/terms">${texts.footer1}</a>
        </span>
      </div>
      <div class="${component}__right-block">
        <div class="${component}__row">
          <span class="${component}__row-title">${texts.company}</span>
          <span>${texts.company_name}</span>
        </div>
        <div class="${component}__row">
          <span class="${component}__row-title">${texts.ceo}</span>
          <span>${texts.ceo_name}</span>
        </div>
        <div class="${component}__row">
          <span class="${component}__row-title ${component}__row-title--address">${texts.address}</span>
          <span class="${component}__row-flex"
            ><span>${texts.address_info_part_1}</span><span>${texts.address_info_part_2}</span></span
          >
        </div>
        <div class="${component}__row">
          <span class="${component}__row-title">${texts.company_registration_info}</span>
          <span class="${component}__row-flex">
            <span class="${component}__mr16"> ${texts.company_registration_number}</span>
            <a
              href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208717483&apv_perm_no="
              target="_blank"
              class="${component}__row-link"
            >
              <u>${texts.footer8}</u>
            </a>
          </span>
        </div>
        <div data-show="${ET.lang !== 'kr'}" class="${component}__row">
          <div class="${component}__row-title">통신판매업 신고번호</div>
          <div>제2013-경기성남-1659호</div>
        </div>
        <div class="${component}__row">
          <div class="${component}__row-title">${texts.footer12}</div>
          <div>${texts.footer13}</div>
        </div>
      </div>
      <div class="${component}__rights">ⓒ NEXON Korea Corporation All Rights Reserved.</div>
    </div>
    <div class="${component}__right">
      <div class="${component}__sns-container">
        <a href="https://www.nexon.com/Home/Game" target="_blank" class="${component}__sns">
          ${getNexonIcon()}
        </a>
        <a href="https://www.instagram.com/nexon_essential/" target="_blank" class="${component}__sns">
          ${getInstagramIcon()}
        </a>
      </div>
      <div class="${component}__right-block">
        <div class="${component}__right-title">${texts.customer_center}</div>
        <div class="${component}__right-middle ${component}__row-flex">
          <div class="${component}__right-sub-block">
            <span class="${component}__call-number">1566-7960</span>
            <span class="${component}__email">nexon@marpple.com</span>
            <span class="${component}__address">${texts.footer14}</span>
          </div>
          <div class="${component}__right-sub-block ${component}__right-sub-block--time">
            <span>${texts.footer5} 10:00 - 18:00</span>
            <span>${texts.footer6} 12:00 - 13:00</span>
            <span>${texts.footer7}</span>
          </div>
        </div>

        <div class="${component}__instructions">
          <div class="${component}__inicis-icons">
            <span class="${component}__inicis-icon">${getInicisIcon1()}</span>
            <span class="${component}__inicis-icon">${getInicisIcon2()}</span>
          </div>
          <span class="${component}__inicis-instruction">${texts.footer15}</span>
          <span class="${component}__legal-instruction"> ${texts.footer11} </span>
        </div>
      </div>
    </div>
  </div>`;
};
