export const loading = () => {
  return $.don_loader_start();
};

export const loadingEnd = () => {
  return $.don_loader_end();
};

export const lazyLoad = () => {
  return G.don_lazy();
};

export const upload = (input_element, opt) => {
  return $.upload(input_element, opt);
};

export const canvasThumbnailLoadingEnd = (container) => {
  return $.remove($.find1(container, '.canvas_thumbnail_loader_wrap'));
};
