import klass from '../../F/Style/pcinfo.module.scss';
import { html } from 'fxjs/es';
import { typo } from '../../../../../../services/MarppleShop/shared/typography/typo.js';

export const popup = () => html`
  <div class="studio-pc-info ${klass.studio_welcome}">
    <div class="${klass.background}">
      <div class="${klass.content}">
        <button type="button" class="studio-pc-info__close ${klass.close}">
          <img
            src="//s3.marpple.co/files/u_29089/2021/8/original/94cbccebe3ec7f81fad86999d97d26d2c187eddc1.webp"
            alt=""
          />
        </button>
        <div class="${klass.image}">
          <img
            src="//s3.marpple.co/files/u_2283830/2024/10/original/57ed5b094b272d74029aa9169b378569af82ddf41.webp"
            alt=""
          />
        </div>
        <div class="${klass.text}">
          <div class="${typo('24_bold')}">${ET('mps2::studio::welcome_title')}</div>
          <div class="${typo('14_medium')}">${ET('mps2::studio::welcome_guide')}</div>
        </div>
        <button type="button" class="studio-pc-info__close ${klass.start}">
          <div class="${typo('16_bold')}">${ET('mps2::common::start')}</div>
        </button>
      </div>
    </div>
  </div>
`;

export const unsupportedMobile = (title) => html`
  <div class="studio-pc-info">
    <div class="studio-pc-info__content">
      <div class="studio-pc-info__title">${title}</div>
      <div class="studio-pc-info__img">
        <img
          src="//s3.marpple.co/files/u_29089/2021/8/original/fda0f0db93513dbae83d113dd4cc012750e998ac1.webp"
          alt=""
        />
      </div>
      <div class="studio-pc-info__description">
        ${T('studio::pc_info::셀러 스튜디오를 PC에서 이용해 보세요')}
      </div>
    </div>
  </div>
`;
