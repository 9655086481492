import { omit } from '@fxts/core';
import { ShopProductListUrlOption } from '../type';
import { updateUrlParams } from '../../../shared/util';
import {
  PROFIT_EXCHANGE_RATE_EN,
  PROFIT_EXCHANGE_RATE_JP,
} from '../../../../../modules/Price/S/Constant/exchange_rate';

/**
 * 상품 목록의 url을 만드는 함수입니다.
 */
export const generateProductListUrl = (option: ShopProductListUrlOption = {}) => {
  const base_url = option.base_url ?? `/${ET.lang}/@/products`;
  const {
    is_limited,
    without_sold_out,
    creator_filter_ids = [],
    style_filter_ids = [],
    is_realtime,
  } = option;

  if (is_realtime) {
    return option.base_url ?? `/${ET.lang}/@/realtime`;
  }

  return updateUrlParams(base_url, {
    ...omit(['base_url', 'is_realtime'], option),
    style_filter_ids: style_filter_ids.length ? style_filter_ids.join(',') : undefined,
    creator_filter_ids: creator_filter_ids.length ? creator_filter_ids.join(',') : undefined,
    is_limited: is_limited ? 'true' : undefined,
    without_sold_out: without_sold_out ? 'true' : undefined,
  });
};

/**
 * "플러스샵" 상품 목록의 url을 만드는 함수입니다.
 */
export const generatePlusShopProductListUrl = (
  option: {
    is_limited?: boolean;
    without_sold_out?: boolean;
    is_best?: boolean;
    type?: 'best' | 'limited';
  } = {},
) => {
  const BASE_URL = `/${ET.lang}/@/products`;
  const { is_limited, without_sold_out, type } = option;

  // 베스트
  if (type === 'best') {
    return updateUrlParams(BASE_URL, { type: 'best' }, false);
  }

  // 한정판
  if (type === 'limited') {
    return updateUrlParams(BASE_URL, { type: 'limited' }, false);
  }

  return updateUrlParams(BASE_URL, {
    is_limited: is_limited ? 'true' : undefined,
    without_sold_out: without_sold_out ? 'true' : undefined,
  });
};

export const getMaxPriceByLanguage = (lang: 'kr' | 'en' | 'jp'): number => {
  const MAX_PRICE_KR = 1_000_000;
  if (lang === 'en') return Math.ceil(MAX_PRICE_KR * PROFIT_EXCHANGE_RATE_EN);
  if (lang === 'jp') return Math.ceil(MAX_PRICE_KR * PROFIT_EXCHANGE_RATE_JP);
  return MAX_PRICE_KR;
};
