export const EXCHANGE_RATE_OPEN_API_ENDPOINT =
  'https://www.koreaexim.go.kr/site/program/financial/exchangeJSON';

export const EXCHANGE_RATE_OPEN_API_AUTHKEY = '3Wbm65PuS5eSm3odvOg3SHAo8O0to8bu';

export const CURRENCY = {
  KRW: 'KRW', // 한국 원화
  USD: 'USD', // 달러
  EUR: 'EUR', // 유로
  JPY: 'JPY', // 일본 옌
};

export const SIGN = {
  KRW: '₩',
  USD: '$',
  EUR: '€',
};
