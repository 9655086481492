import { UtilStringS } from '../../../Util/String/S/Function/module/UtilStringS.js';
import { VectorEditorConstantS } from '../../S/Constant/module/VectorEditorConstantS.js';
import { flatten, go, mapObject, unique, values, filter } from 'fxjs/es';
import { svg } from './pro_editor.svg.js';
import { VectorEditorF } from './module/VectorEditorF.js';
import { PRO_EDITOR_LAYER_IDS } from '../../S/Constant/fn.js';

export const pro = {
  /*
   * @description 프로 에디터 svg 파일 처리
   *  @params maker_type 'KEYRING' | 'ACRYLIC_FIGURE'
   *  @returns cut_svg_el 커팅패스만 들어있는 트림된 svg
   *  @returns image_svg_el 이미지만 들어있는 svg (커팅패스 대지와 동일)
   *  @returns printable_image_canvas 인쇄용 300 DPI 이미지 캔버스
   *  @returns toPrintableImageCanvas 원하는 가로 사이즈로 리사이즈 된 이미지 캔버스 추출 함수
   *  @returns key_item_coord_ratio: 합성 시 필요한 주요 아이템의 x, y 위치 비율
   * */
  processSvgUploadFile: async ({ file, maker_type, options }) => {
    if (UtilStringS.isEmNil(maker_type))
      VectorEditorF.throwProEditorErr.dev({ msg: `maker_type is required` });

    /* [svg 파일 검증] */
    svg.validator.svgFile({ file, max_mb_size: 10 });

    /* [옵션 검증] */
    validateOptionsByMakerType({ maker_type, options });

    /* [svg 파싱] */
    const svg_parsed = await svg.parser.fromFile(file);

    if (!window.ENV.is_prod) {
      console.log('svg_parsed', svg_parsed);
    }

    /* [svg 레이어, 요소 검증] */
    svg.validator.importedSvg({ svg_parsed, maker_type });

    /* [칼선, 이미지 생성] */

    // ↓ 병합 칼선 추출 프로세싱 ↓ //
    const {
      svg_el_trimmed: cut_path_svg_el_trim,
      bbox,
      key_item_coord_ratio,
    } = await svg.builder.getMergedCutSvg({
      svg_parsed,
      maker_type,
      options,
    });

    // 제작 사이즈 validation
    validateSizeByMakerType({ maker_type, bbox, options });

    // ↓ 이미지 추출 프로세싱 ↓ //
    const image_svg_el = svg.builder.fromParsed({
      svg_parsed,
      remove_ids: go(
        PRO_EDITOR_LAYER_IDS,
        values,
        flatten,
        unique,
        filter((id) => id !== 'image'),
      ),
      bbox,
    });

    const DPI_72_to_300 = 300 / 72;

    return {
      cut_svg_el: cut_path_svg_el_trim,
      image_svg_el,
      printable_image_canvas: await svg.converter.svgToCanvas({
        svg_el: image_svg_el,
        width: bbox.width * DPI_72_to_300,
        delay_time: 2000,
      }),
      key_item_coord_ratio,
      toPrintableImageCanvas: async (width) =>
        await svg.converter.svgToCanvas({ svg_el: image_svg_el, width, delay_time: 1000 }),
    };
  },
};

function validateOptionsByMakerType({ maker_type, options }) {
  switch (maker_type) {
    case VectorEditorConstantS.KEYRING_EDITOR: {
      // 키링에서는 옵션 공급 필요 없음
      break;
    }
    case VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR: {
      // 아크릴 피규어 - 사이즈에 따라 스탠드의 크기를 결정해야 하므로 옵션 공급 필요함.
      if (options == null) VectorEditorF.throwProEditorErr.dev({ msg: `Options must be provided` });

      const width = options?.width;
      const height = options?.height;
      if (width == null || height == null)
        VectorEditorF.throwProEditorErr.dev({ msg: `Width and height are required in options` });

      const stand_leg = options?.stand_leg;
      if (stand_leg == null)
        VectorEditorF.throwProEditorErr.dev({ msg: `Stand leg info is required in options` });

      const notch = stand_leg?.notch;
      const ground = stand_leg?.ground;
      if (notch == null || ground == null)
        VectorEditorF.throwProEditorErr.dev({ msg: `Notch and ground info are required in options` });
      break;
    }
  }
}

function validateSizeByMakerType({ maker_type, bbox, options }) {
  const PX_TO_MM_72DPI = 25.4 / 72;
  const mm_bbox = mapObject((v) => v * PX_TO_MM_72DPI, bbox);

  switch (maker_type) {
    case VectorEditorConstantS.KEYRING_EDITOR: {
      const KEYRING_MAX_LEN = 100; // mm
      const { width, height } = mm_bbox; // px (72 dpi)
      if (width > KEYRING_MAX_LEN)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::art_board'),
          msg: TT('pro_editor::alert::text::art_board'),
        });
      if (height > KEYRING_MAX_LEN)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::art_board'),
          msg: TT('pro_editor::alert::text::art_board'),
        });
      break;
    }
    case VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR: {
      const { width: ACRYL_STAND_MAX_WIDTH, height: ACRYL_STAND_MAX_HEIGHT } = options; // mm
      const { width, height } = mm_bbox; // px (72 dpi)
      if (width > ACRYL_STAND_MAX_WIDTH)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::art_board'),
          msg: TT('pro_editor::alert::text::art_board'),
        });

      if (height > ACRYL_STAND_MAX_HEIGHT)
        VectorEditorF.throwProEditorErr.user({
          title: TT('pro_editor::alert::title::art_board'),
          msg: TT('pro_editor::alert::text::art_board'),
        });
      break;
    }
  }
}
