export const STYLE_SETTINGS_FRAME = '@FRAME/Creator/Setting/style_settings';
export const STYLE_SETTINGS_PAGE = '@PAGE/Creator/Setting/style_settings';
export const STYLE_SETTINGS_TAB = '@TAB/Creator/Setting/style_settings';

export const STYLE_EDITING_FRAME = '@FRAME/Creator/Setting/style_editing';
export const STYLE_EDITING_PAGE = '@PAGE/Creator/Setting/style_editing';
export const STYLE_EDITING_TAB = '@TAB/Creator/Setting/style_editing';

export const PRODUCT_SETTINGS_FRAME = '@FRAME/Creator/Setting/product_settings';
export const PRODUCT_SETTINGS_PAGE = '@PAGE/Creator/Setting/product_settings';
export const PRODUCT_SETTINGS_TAB = '@TAB/Creator/Setting/product_settings';

export const PRODUCT_EDITING_FRAME = '@FRAME/Creator/Setting/product_editing';
export const PRODUCT_EDITING_PAGE = '@PAGE/Creator/Setting/product_editing';
export const PRODUCT_EDITING_TAB = '@TAB/Creator/Setting/product_editing';

export const PRODUCT_EDITING_FRAME_WITH_PRE_DISCOUNT = '@FRAME/Creator/Setting/product_editing_pre_discount';
export const PRODUCT_EDITING_PAGE_WITH_PRE_DISCOUNT = '@PAGE/Creator/Setting/product_editing_pre_discount';

export const PRODUCT_CREATION_FRAME = '@FRAME/Creator/Setting/product_creation';
export const PRODUCT_CREATION_PAGE = '@PAGE/Creator/Setting/product_creation';
export const PRODUCT_CREATION_TAB = '@TAB/Creator/Setting/product_creation';

export const CATEGORY_SELECTION_PAGE = '@PAGE/Creator/Setting/category_selection';
export const CATEGORY_SELECTION_TAB = '@TAB/Creator/Setting/category_selection';

export const BASE_PRODUCT_SELECTION_PAGE = '@PAGE/Creator/Setting/base_product_selection';
export const BASE_PRODUCT_SELECTION_TAB = '@TAB/Creator/Setting/base_product_selection';

export const ACCOUNT_SETTINGS_FRAME = '@FRAME/Creator/Setting/account_settings';
export const ACCOUNT_SETTINGS_PAGE = '@PAGE/Creator/Setting/account_settings';
export const ACCOUNT_SETTINGS_TAB = '@TAB/Creator/Setting/account_settings';

export const MARKETING_SETTINGS_FRAME = '@FRAME/Creator/Setting/marketing_settings';
export const MARKETING_SETTINGS_PAGE = '@PAGE/Creator/Setting/marketing_settings';
export const MARKETING_SETTINGS_TAB = '@TAB/Creator/Setting/marketing_settings';

export const INVITE_SETTINGS_FRAME = '@FRAME/Creator/Setting/invite_settings';
export const INVITE_SETTINGS_PAGE = '@PAGE/Creator/Setting/invite_settings';
export const INVITE_SETTINGS_TAB = '@TAB/Creator/Setting/invite_settings';

export const BANK_SETTINGS_FRAME = '@FRAME/Creator/Setting/bank_settings';
export const BANK_SETTINGS_PAGE = '@PAGE/Creator/Setting/bank_settings';
export const BANK_SETTINGS_TAB = '@TAB/Creator/Setting/bank_settings';

export const TERMS_SETTINGS_FRAME = '@FRAME/Creator/Setting/terms_settings';
export const TERMS_SETTINGS_PAGE = '@PAGE/Creator/Setting/terms_settings';
export const TERMS_SETTINGS_TAB = '@TAB/Creator/Setting/terms_settings';

export const INVITE_INFO_SETTINGS_FRAME = '@FRAME/Creator/Setting/invite_info_settings';
export const INVITE_INFO_SETTINGS_PAGE = '@PAGE/Creator/Setting/invite_info_settings';
export const INVITE_INFO_SETTINGS_TAB = '@TAB/Creator/Setting/invite_info_settings';

export const REDESIGN_PRODUCT_COLUMNS = [
  'thumbnails',
  'price',
  'price_en',
  'price_jp',
  'price_info',
  'og_image_url',
  'base_product_id',
  'product_faces2',
  'product_faces',
  'overflow_sizes',
  'base_product_color_id',
  'base_product_size_id',
  'svg_product_id',
  'selected_option_group_id',
];

export const STUDIO_UPDATE_NOTE_URL =
  'https://updatenote.marpplecorp.com/04610790-7f94-4d72-91bb-db2b84b61e6a';
