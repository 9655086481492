export const RESERVE_PRODUCTS = [
  /* 한진으로 입고 완료*/
  // {
  //   id: 20081805,
  //   name: '실리콘 무드등 - 다이버 빈',
  //   reserve_date: new Date('2024-11-11 00:00:00 GMT+0900'),
  // },
  {
    id: 20081792,
    name: '인형 키링 - 다이버 빈',
    reserve_date: new Date('2024-12-02 00:00:00 GMT+0900'),
  },
  {
    id: 20081743,
    name: '스트레스 볼 - 다이버 빈',
    reserve_date: new Date('2024-12-02 00:00:00 GMT+0900'),
  },
];
