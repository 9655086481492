import { add, go, reduce } from 'fxjs/es';

/**
 *
 * @param {number} numerator
 * @param {number} denominator
 * @return {number[]}
 */
export function reduceFraction(numerator, denominator) {
  const gcd = findGCD(numerator, denominator);

  // 약분된 분자와 분모를 계산
  const reducedNumerator = numerator / gcd;
  const reducedDenominator = denominator / gcd;

  return [reducedNumerator, reducedDenominator];
}

/**
 * @desc 최대공약수를 구하는 함수
 * @param {number} a
 * @param {number} b
 * @return {number}
 */
export function findGCD(a, b) {
  return b === 0 ? a : findGCD(b, a % b);
}

/**
 *
 */
export const getDistance = ([x1, y1], [x2, y2]) => {
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
};

/**
 * @param {number[]} numbers
 * @return {number}
 */
export const avg = (numbers) => go(numbers, reduce(add), (sum) => sum / numbers.length);

/**
 * @param {number[]} numbers
 * @return {number}
 */
export const min = (numbers) => Math.min(...numbers);

/**
 * @param {number[]} numbers
 * @return {number}
 */
export const max = (numbers) => Math.max(...numbers);

export const minMaxResidue = (min, v, max) => {
  // prettier-ignore
  return v > max ? [max, v - max]
    : v < min ? [min, v - min]
      : [v, 0];
};
