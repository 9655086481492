import { html, View } from 'rune-ts';
import { throttle } from 'fxjs/es';
import { indexBy, identity } from '@fxts/core';
import klass from './GnbPc.module.scss';
import { typo } from '../../../../shared/typography/typo';
import { MakeOptional } from '../../../../shared/type/types';
import { htmlIf } from '../../../../shared/util';
import { CreatorGnbProps, makeCreatorTemplate, makeShopTemplate } from './template';
import { getProductCategoryOrderMap, GnbProductCategory, product_category_layout } from './constant';

export type GnbCateList = {
  id: number;
  name: string;
  cate_items: { id: number; name: string; name_en: string; cate_list_id: number }[];
};

export interface GnbPcData {
  creator_template_props: CreatorGnbProps;
  cate_lists: GnbCateList[];
}

export interface GnbPcState {
  klass?: string;
  is_open: boolean;
  type: 'shop' | 'creator' | 'pop-up store';
  product_category_groups: GnbProductCategory[][];
}

export type GnbPcOption = MakeOptional<GnbPcState, 'is_open' | 'type' | 'product_category_groups'>;

export class GnbPc extends View<GnbPcData> {
  state: GnbPcState;

  constructor(data: GnbPcData, option: GnbPcOption = {}) {
    super(data);

    this.state = {
      klass: option.klass,
      is_open: option.is_open ?? false,
      type: option.type ?? 'shop',
      product_category_groups: [],
    };
  }

  refreshProductCategoryRedraw() {
    const viewport_width = window.innerWidth;
    this.state.product_category_groups = getProductCategoryOrderMap(viewport_width).map((order_group) =>
      order_group.map((category_id) => {
        const layout = product_category_layout.find((category) => category.id == category_id)!;
        const cate_list = this.data.cate_lists?.find((cate_list) => cate_list.id == category_id);
        if (!cate_list) {
          return {
            id: category_id,
            title: '-',
          };
        }

        const indexed_cate_items = indexBy((a) => a.id, cate_list.cate_items);

        return {
          id: category_id,
          title: cate_list.name,
          menus: layout.menus.map((menu_id) => indexed_cate_items[menu_id]).filter(identity),
        };
      }),
    );
    this.redraw();
  }

  override onMount() {
    this.refreshProductCategoryRedraw();

    window.addEventListener(
      'resize',
      throttle(() => {
        this.refreshProductCategoryRedraw();
      }, 100),
    );
  }

  override template() {
    const { creator_template_props } = this.data;
    const { is_open, type, product_category_groups } = this.state;

    return html`<div
      class="${this.state.klass ?? ''} ${klass.gnb_pc} ${typo('14_medium')} ${htmlIf(
        klass.opened,
        is_open && type !== 'pop-up store',
      )}"
    >
      <div class="${klass.width_container}">
        ${makeShopTemplate(product_category_groups, type == 'shop')}
        ${makeCreatorTemplate(creator_template_props, type == 'creator')}
      </div>
    </div> `;
  }
}
