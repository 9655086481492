import * as NessAppPlusHomeCostantS from '../../../App/Plus/Home/S/Constant/home.js';
import { NessProductListType } from '../../../App/Plus/Product/S/Constant/product.js';

/**
 * 상품 카테고리 헤더의 "신상품", "한정판", "인기 상품" 메뉴
 */
export const getSpecialMenusForCateListHeader = (visibility_by_special_menu = {}) => {
  const new_arrivals_menu = {
    type: NessProductListType.recent,
    value: NessProductListType.recent,
    name: ET('ness::product::product_03'),
  };

  const limited_edition_menu = {
    type: NessProductListType.limit,
    value: NessProductListType.limit,
    name: ET('ness::product::product_05'),
  };

  const popular_menu = {
    type: NessProductListType.popular,
    value: NessProductListType.popular,
    name: ET('ness::product::product_07'),
  };

  const special_mens = [];
  if (visibility_by_special_menu[NessProductListType.recent]) {
    special_mens.push(new_arrivals_menu);
  }
  if (visibility_by_special_menu[NessProductListType.limit]) {
    special_mens.push(limited_edition_menu);
  }
  if (NessAppPlusHomeCostantS.isBestSellerShow && visibility_by_special_menu[NessProductListType.popular]) {
    special_mens.push(popular_menu);
  }
  return special_mens;
};
