import { SkuS } from '../../../Sku/S/Function/module/SkuS.js';
import { ShippingCostConstantS } from '../../Cost/S/Constant/module/ShippingCostConstantS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { UtilStringS } from '../../../Util/String/S/Function/module/UtilStringS.js';

/**
 *  @description 부피 무게 산출
 *  @type sku { width: number, height: number, length: number, weight: number }
 *  @type denominator number - 환산 분모
 *  @returns number - gram
 */
export function getVolumetricWeightBySku({
  sku,
  denominator = ShippingCostConstantS.VOLUMETRIC_WEIGHT_DENOMINATOR,
}) {
  SkuS.guards.skuKeys({ sku });

  const { width, height, length } = sku;
  if (width == null || height == null || length == null) {
    return 0;
  }

  return parseInt((Number(width) * Number(height) * Number(length)) / denominator);
}

export function getVolumetricWeight({
  volume,
  denominator = ShippingCostConstantS.VOLUMETRIC_WEIGHT_DENOMINATOR,
}) {
  if (volume == null) throw new Error(`volume is required`);

  return parseInt(Number(volume) / denominator);
}

/**
 * @define 배송비 산정을 위해 무게 계산
 * @param {sku}
 * @param {number} volume_margin_percent 포장시 부피 마진
 * @return {number} 배송 무게
 * */
export function calculateShippingWeight({ sku, volume_margin_percent = 0 }) {
  const { weight } = sku;
  const volumetric_weight = getVolumetricWeightBySku({ sku }) * (1 + volume_margin_percent / 100);
  return Math.max(Number(weight), Number(volumetric_weight));
}

/**
 * @define SKU 에서 체적정보가 가능한지 여부
 * @param {object} sku
 * @return {boolean}
 * */
export function isWeightCalculationAvailableInSku({ sku }) {
  if (UtilS.isEmpty(sku)) return false;
  const { width, height, length, weight } = sku;

  try {
    [width, height, length, weight].forEach((measurement) => {
      if (UtilS.isEmpty(measurement)) throw new Error();
      if (!UtilStringS.isNumericString(measurement)) throw new Error();
    });
  } catch (err) {
    return false;
  }

  return true;
}

const makeDhlLangLocale = () => {
  if (TT.lang === 'en') return 'us-en';
  if (TT.lang === 'jp') return 'jp-ja';
  return 'kr-ko';
};

/**
 * @define 해외 배송일 때 운송사의 tracking url 를 반환하는 함수
 * @param {string} country_code
 * @param {string} waybill_number
 * @param {string} shipping_company_id
 * @param {string} shipping_company_service_name
 * @return {string | undefined} express_tracking_url
 */
export function getOverseaTrackingUrl({
  country_code,
  waybill_number,
  shipping_company_id,
  shipping_company_service_name,
  invoice_number,
}) {
  const track_number = waybill_number || invoice_number;
  switch (shipping_company_id) {
    case 'ups':
      return `https://www.ups.com/track?requester=QUIC&tracknum=${track_number}/trackdetails`;
    case 'sf_express':
      return `https://htm.sf-express.com/hk/en/dynamic_function/waybill/#search/bill-number/${track_number}`;
    case 'k_packet':
      return `https://ems.epost.go.kr/`;
    case 'lotte_economy_us':
      return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${track_number}`;
    case 'lotte_economy_sea':
      switch (country_code) {
        case 'HK':
          return `https://cxc.com.hk/en/track/`;
        case 'MY':
          return 'https://www.skynet.com.my/track';
        case 'SG':
          return `https://www.cjlogistics.com/ko/tool/international/tracking`;
        case 'TW':
          return `https://www.hct.com.tw/Default_en.aspx`;
        case 'PH':
          return 'http://www.topwell-logistics.com/?_l=en';
        case 'VN':
          return 'https://www.vietnampost.vn/';
      }
      break;
    case 'lotte_economy_jp':
      switch (shipping_company_service_name) {
        case 'yamato':
          return 'http://track.kuronekoyamato.co.jp/english/tracking';
        case 'nekopos':
          return 'http://track.kuronekoyamato.co.jp/english/tracking';
        case 'sagawa':
          return `https://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijosearcheng.jsp`;
      }
      break;
    case 'dhl':
    case 'dhl-direct':
      return `https://www.dhl.com/${makeDhlLangLocale()}/home/tracking/tracking-express.html?submit=1&tracking-id=${track_number}`;
    case 'ems':
      return `https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=${track_number}`;
    case 'doora':
      return 'https://www.doora.co.kr/?l=en#';
  }
}

const EN_NUM_SP_REGEX = /[^A-Za-z0-9,._ -]/g;
const EN_NUM_SP_JP_REGEX = /[^A-Za-z0-9 \u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF.,-_。]/g;

/**
 * @define 해외 배송에서 허용 가능한 문자 검사
 * @param {string} input_str
 * @param {boolean} is_jp_allowed 일본어 허용 여부
 * @return {{ has_invalid_characters: boolean, invalid_characters: string[] }}
 * */
export function checkInvalidShippingCharsForOversea(input_str, is_jp_allowed) {
  const pattern = is_jp_allowed ? EN_NUM_SP_JP_REGEX : EN_NUM_SP_REGEX;
  const invalid_characters = input_str.match(pattern);

  if (invalid_characters) {
    return {
      has_invalid_characters: true,
      invalid_characters: Array.from(new Set(invalid_characters)), // 중복된 문자를 제거하기 위해 Set을 사용합니다.
      cleaned_characters: input_str.replace(pattern, ''),
    };
  } else {
    return {
      has_invalid_characters: false,
      invalid_characters: [],
      cleaned_characters: input_str,
    };
  }
}
