import { go } from 'fxjs/es';
import { GoodsTypeConstantS } from '../Constant/module/GoodsTypeConstantS.js';

export const isMps = (goods_type_id) => {
  return goods_type_id == GoodsTypeConstantS.MPS.id;
};
export const isPbg = (goods_type_id) => {
  return goods_type_id == GoodsTypeConstantS.PBG.id;
};
export const isTpl = (goods_type_id) => {
  return goods_type_id == GoodsTypeConstantS.TPL.id;
};
export const isDtg = (goods_type_id) => {
  return goods_type_id == GoodsTypeConstantS.DTG.id;
};
export const isPod = (goods_type_id) => {
  return isMps(goods_type_id) || !goods_type_id;
};
export const isNotPod = (goods_type_id) => {
  return !isPod(goods_type_id);
};

export const name = (goods_type_id) => {
  return go(
    goods_type_id,
    () => {
      if (isMps(goods_type_id)) {
        return `studio::menus::design_adjustments`;
      }
      if (isPbg(goods_type_id)) {
        return `studio::menus::seller_adjustments`;
      }
      if (isTpl(goods_type_id)) {
        return `studio::menus::tpl_adjustments`;
      }
      if (isDtg(goods_type_id)) {
        return `studio::menus::dtg_adjustments`;
      }
    },
    T,
  );
};
