import { compact, defaults, find, go, head, html, last, map, pluck, sel, strMap } from 'fxjs/es';
import { BpOptionConstantS } from '../Constant/module/BpOptionConstantS.js';
import { UtilArrayS } from '../../../Util/Array/S/Function/module/UtilArrayS.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

import { BpDiscountSetConstantS } from '../../../BpDiscountSet/S/Constant/module/BpDiscountSetConstantS.js';
import { BpOptionTmplS } from './module/BpOptionTmplS.js';
import { BpDiscountSetS } from '../../../BpDiscountSet/S/Function/module/BpDiscountSetS.js';

// @description 비즈 상품 마플라이저 옵션 메뉴 HTML
export const biz = {
  get: {
    html: {
      adapter: ({ is_render, biz_option_values, bp_discount_set }) => {
        const bp_discount_ranges = bp_discount_set?._?.bp_discount_ranges;
        if (!is_render) return '';
        if (G.collabo_type !== '') return '';

        if (UtilArrayS.isEmNil(bp_discount_ranges)) {
          return '';
        }

        const default_qty = go(
          bp_discount_ranges,
          find(sel('is_default')),
          (bp_discount_range) => bp_discount_range?.start || bp_discount_ranges[0].start,
        );
        const min_qty = head(bp_discount_ranges).start;
        const max_qty = last(bp_discount_ranges).end;
        const is_mp_wow = bp_discount_set?.type === BpDiscountSetConstantS.TYPE.MP_WOW;
        /* 비즈상품 메모 여부 */
        return go(
          [
            {
              key: BpOptionConstantS.BIZ_OPTION_MENU_KEY.QTY,
              params: {
                initial_value: biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.QTY] ?? default_qty,
                default_value: default_qty,
                step: 1,
                max: max_qty,
                min: min_qty,
                bp_discount_ranges,
                is_show_discount_guide:
                  bp_discount_set?.type !== BpDiscountSetConstantS.TYPE.MP_WOW &&
                  /* 가격표 보여주는 부분 */
                  bp_discount_ranges.length > 1,
              },
            },
            // { //기획에서 잠정 보류됨
            //   key: BpOptionConstantS.BIZ_OPTION_MENU_KEY.FILE_REF,
            //   params: {
            //     accept_extensions: ['application/pdf', 'application/postscript'],
            //     url: biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_URL],
            //     filename: biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_NAME],
            //   },
            // },
            default_qty === 1 || is_mp_wow
              ? null
              : {
                  key: BpOptionConstantS.BIZ_OPTION_MENU_KEY.REQ_MEMO,
                  params: { memo: biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.REQ_MEMO] },
                },
          ],
          compact,
          strMap(({ key, params }) => biz.get.html[key](params)),
        );
      },
      [BpOptionConstantS.BIZ_OPTION_MENU_KEY.QTY]: (params = {}) => {
        const { default_value, initial_value, step, max, min, bp_discount_ranges, is_show_discount_guide } =
          params;

        return optionSectionCommonHtml({
          section_classname: BpOptionConstantS.BIZ_OPTION_MENU_KEY.QTY,
          title: TT('biz::opt::title::qty'),
          html: {
            body: html`
              <div class="qty_wrap">
                ${BpDiscountSetS.util.isStepTypeBpDiscountRanges(bp_discount_ranges)
                  ? BpOptionTmplS.makeSelectQuantityHtml({
                      quantities: go(bp_discount_ranges, pluck('start')),
                      quantity: initial_value,
                      min,
                      max,
                    })
                  : html`
                      <div class="input_wrap">
                        <button name="minus"></button>
                        <input
                          type="number"
                          class="quantity"
                          value="${Number(initial_value)}"
                          min="${min}"
                          max="${max}"
                          step="${step}"
                          inputmode="numeric"
                          pattern="[0-9]*"
                        />
                        <button name="plus"></button>
                      </div>
                    `}
              </div>
              ${is_show_discount_guide
                ? html`<div class="group_discount_guide">
                    <button
                      class="group_discount_guide"
                      data-fx-json="${$dataStr(
                        go(
                          bp_discount_ranges,
                          map((d) => ({
                            qty: d.start,
                            price: parseFloat(d['price' + G._en]) + parseFloat(d['min_option_price' + G._en]),
                          })),
                        ),
                      )}"
                    >
                      ${TT('biz::opt::group_discount')}
                    </button>
                  </div>`
                : html``}
            `,
            footer: html` <span>${default_value} ${TT('biz::opt::ea')} ${TT('biz::opt::order')}</span> `,
          },
        });
      },
      [BpOptionConstantS.BIZ_OPTION_MENU_KEY.FILE_REF]: (params = {}) => {
        defaults(params, { accept_extensions: ['application/pdf'], url: '', filename: '' });
        const { accept_extensions, url, filename } = params;

        return optionSectionCommonHtml({
          section_classname: BpOptionConstantS.BIZ_OPTION_MENU_KEY.FILE_REF,
          title: TT('biz::opt::title::ref_file'),
          html: {
            body: html`
              <div class="input_wrap">
                <button name="download" url="${UtilS.escape(url)}" filename="${UtilS.escape(filename)}">
                  ${filename === '' ? TT('biz::opt::ref_file::placeholder') : filename}
                </button>
                <input type="file" style="display:none;" accept="${accept_extensions.join(', ')}" />
                <button name="upload"></button>
              </div>
            `,
            footer: html` <span>${TT('biz::opt::ref_file::limit')}</span> `,
          },
        });
      },
      [BpOptionConstantS.BIZ_OPTION_MENU_KEY.REQ_MEMO]: (params = {}) => {
        defaults(params, { memo: '' });
        const { memo } = params;

        return optionSectionCommonHtml({
          section_classname: BpOptionConstantS.BIZ_OPTION_MENU_KEY.REQ_MEMO,
          title: TT('biz::opt::title::req_memo'),
          html: {
            body: html`
              <input
                type="text"
                placeholder="${TT('biz::opt::req_memo::placeholder')}"
                value="${UtilS.escape(memo)}"
              />
            `,
          },
        });
      },
    },
  },
};

function optionSectionCommonHtml({ section_classname, title, html: { body, footer } }) {
  return html`
    <div class="section ${section_classname} ${BpOptionConstantS.BIZ_OPTION_MENU_TITLE}">
      <div class="head">${title}</div>
      <div class="body">${body ?? ''}</div>
      <div class="footer">${footer ?? ''}</div>
    </div>
  `;
}
