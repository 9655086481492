import { html, on, View } from 'rune-ts';
import klass from './SubHeader.module.scss';
import { PlusCreatorLogo, PlusCreatorLogoProps } from '../PlusCreatorLogo/PlusCreatorLogo';
import { htmlIf } from '../../../../shared/util';
import { MakeOptional } from '../../../../shared/type/types';
import { MinusIcon, PlusIcon } from '../../atoms/Icon';
import { typo } from '../../../../shared/typography/typo';

interface SubMenu {
  name: string;
  key: string;
  url: string;
}

export type PcMenu = {
  name: string;
  url: string;
  key: string;
  sub_menus?: SubMenu[];
};

export type SubHeaderPcData = Omit<PlusCreatorLogoProps, 'is_mobile'> & {
  menus: PcMenu[];
};

export interface SubHeaderPcState {
  selected_menu_key?: string;
  transparent_background: boolean;
}

export type SubHeaderPcOptions = MakeOptional<SubHeaderPcState, 'transparent_background'>;

export class SubHeaderPc extends View<SubHeaderPcData> {
  state: SubHeaderPcState;
  plusCreatorLogo: PlusCreatorLogo;
  constructor(data: SubHeaderPcData, options: SubHeaderPcOptions) {
    super(data);

    this.state = {
      ...options,
      transparent_background: options.transparent_background ?? false,
    };

    const plus_creator_logo_props: PlusCreatorLogoProps = {
      plus: data.plus,
      creator: data.creator,
      theme: this.state.transparent_background ? 'dark' : undefined,
      is_mobile: false,
    };

    this.plusCreatorLogo = new PlusCreatorLogo(plus_creator_logo_props);
  }

  protected override onRender() {
    setTimeout(() => {
      // 메뉴 scrollHeight 재서, 현재 clientHeight 보다 크면 더보기 보이게
      const menu_container_el = this.element().querySelector(`.${klass.menu_container}`);

      if (!menu_container_el) throw new Error('menu container not exists');

      if (menu_container_el.scrollHeight > this.element().clientHeight) {
        this.element().classList.add(klass.overflow_menu);
      }
    }, 1000);
  }

  @on('click', `.${klass.see_more_btn}`)
  handleClickSeeMore() {
    this.element().classList.toggle(klass.opened);
  }

  redrawBgColor(instant: boolean) {
    // override 없으면
    const transparent_value = this.state.transparent_background;

    const element = this.element() as HTMLDivElement;

    if (instant) {
      element.classList.add(klass.instant_bg_change);
    }

    if (transparent_value) {
      element.classList.add(klass.transparent);
    } else {
      element.classList.remove(klass.transparent);
    }

    this.plusCreatorLogo.changeTheme(transparent_value ? 'dark' : 'white', instant);

    if (instant) {
      element.offsetHeight; // force reflow
      element.classList.remove(klass.instant_bg_change);
    }
  }

  override template() {
    const { creator, plus, menus } = this.data;
    const { selected_menu_key, transparent_background } = this.state;

    const is_plus_logo_surrounded = PlusCreatorLogo.isSurrounded({ plus: !!plus, creator: !!creator });
    const is_plus_logo_empty = PlusCreatorLogo.isEmpty({ plus: !!plus, creator: !!creator });

    return html`<div
      class="${klass.sub_header}
      ${typo('16_medium')}
      ${htmlIf(klass.large_logo, is_plus_logo_surrounded)}
      ${htmlIf(klass.empty_logo, is_plus_logo_empty)}
      ${htmlIf(klass.transparent, transparent_background)}"
    >
      <div class="${klass.sub_header_core}">
        <span class="${klass.logo_container}"> ${this.plusCreatorLogo} </span>

        <div class="${klass.menu_container}">
          ${menus.map((menu) => {
            const sub_header_selected_klass =
              menu.key === this.state.selected_menu_key
                ? `${klass.selected_menu_item} ${typo('16_bold')}`
                : '';

            return html`<a
              href="${menu.url}"
              class="${klass.menu_item} ${sub_header_selected_klass}"
              data-key="${menu.key}"
              >${menu.name}</a
            >`;

            // return menu.sub_menus
            //   ? new Dropdown(
            //       {
            //         options: [
            //           { value: menu.url, key: menu.key, name: ET('전체') },
            //           ...menu.sub_menus.map((sub_menu) => ({
            //             value: sub_menu.url,
            //             name: sub_menu.name,
            //             key: sub_menu.key,
            //           })),
            //         ],
            //       },
            //       {
            //         without_container: true,
            //         klass: klass.menu_item,
            //         button_klass: klass.selected_menu_item,
            //         horizontal: 'left',
            //         vertical: 'bottom',
            //         has_arrow: true,
            //         close_on_focus_out: true,
            //         default_name: menu.name,
            //       },
            //     )
            //   : html`<a
            //       href="${menu.url}"
            //       class="${klass.menu_item} ${sub_header_selected_klass}"
            //       data-key="${menu.key}"
            //       >${menu.name}</a
            //     >`;
          })}
        </div>

        <button class="${klass.see_more_btn}">
          ${ET('mps2::gnb::more')}
          <span class="${klass.see_more_btn_icon} ${klass.plus}">${PlusIcon}</span>
          <span class="${klass.see_more_btn_icon} ${klass.minus}">${MinusIcon}</span>
        </button>
      </div>
    </div> `;
  }
}
