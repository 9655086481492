const screenPathMap = {
  Home: '',
  Popup: '/@/popup-store',
  Creator: '/@/sellers',
  Shop: '/@/products',
} as const;

export type PostMessageNavigate = {
  navigate: {
    name:
      | 'Home'
      | 'Popup'
      | 'Creator'
      | 'Shop'
      | 'EmptyScreen'
      | 'SubScreen'
      | 'UtilScreen'
      | 'SignUpSeller'
      | 'SettingScreen'
      | 'Cart'
      | 'SellerStudioScreen';
    params?: {
      uri?: string;
      // 해당 키를 추가하면 앱 상에서 해당 키를 가진 스크린으로 이동합니다.
      override_key?: string;
    };
  };
};

export const makeHomeScreenNavigate = (): PostMessageNavigate => {
  return {
    navigate: {
      name: 'Home',
    },
  };
};

export const makeScreenResetNavigate = (screen: 'Home' | 'Popup' | 'Creator' | 'Shop') => {
  return {
    resetNavigate: {
      sel: `TabNavigation.HomeStack.${screen}`,
      params: {
        uri: `/${T.lang}${screenPathMap[screen]}`,
      },
    },
  };
};

export const makePopupScreenNavigate = (uri?: string): PostMessageNavigate => {
  return {
    navigate: {
      name: 'Popup',
      params: uri
        ? {
            uri,
          }
        : undefined,
    },
  };
};

export const makeCreatorScreenNavigate = (uri?: string): PostMessageNavigate => {
  return {
    navigate: {
      name: 'Creator',
      params: uri
        ? {
            uri,
          }
        : undefined,
    },
  };
};

export const makeShopScreenNavigate = (uri?: string): PostMessageNavigate => {
  return {
    navigate: {
      name: 'Shop',
      params: uri
        ? {
            uri,
          }
        : undefined,
    },
  };
};

export const makeEmptyScreenNavigate = (uri: string, override_key?: string): PostMessageNavigate => {
  return {
    navigate: {
      name: 'EmptyScreen',
      params: {
        uri,
        override_key,
      },
    },
  };
};

export const makeSubScreenNavigate = (uri: string): PostMessageNavigate => {
  return {
    navigate: {
      name: 'SubScreen',
      params: {
        uri,
      },
    },
  };
};

export const makeUtilScreenNavigate = (uri: string): PostMessageNavigate => {
  return {
    navigate: {
      name: 'UtilScreen',
      params: {
        uri,
      },
    },
  };
};

export const makeSignUpSellerNavigate = (uri: string): PostMessageNavigate => {
  return {
    navigate: {
      name: 'SignUpSeller',
      params: {
        uri,
      },
    },
  };
};

export const makeSettingScreenNavigate = (): PostMessageNavigate => {
  return {
    navigate: {
      name: 'SettingScreen',
    },
  };
};

export const makeCartNavigate = (): PostMessageNavigate => {
  return {
    navigate: {
      name: 'Cart',
    },
  };
};

export const makeSellerStudioScreenNavigate = (uri: string): PostMessageNavigate => {
  return {
    navigate: {
      name: 'SellerStudioScreen',
      params: {
        uri,
      },
    },
  };
};

export const makeSearchScreenNavigate = (uri: string): PostMessageNavigate => {
  return makeEmptyScreenNavigate(uri, 'SearchResult');
};
