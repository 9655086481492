import { html } from 'fxjs/es';
import { typo } from '../../../../../../services/MarppleShop/shared/typography/typo.js';

export const changeLang = (href, lang) => {
  lang = `/${lang}`;
  return html` <div class="adult-lang">
    ${T.lang != 'kr'
      ? html`<a
          href="${href.replace(lang, '/kr')}"
          class="mp-shop-lang"
          lang="kr"
          style="text-decoration: none;"
        >
          <span class="adult-lang__text ${typo('14_medium')}">한국어</span>
        </a>`
      : ''}
    ${T.lang != 'en'
      ? html`<a
          href="${href.replace(lang, '/en')}"
          class="mp-shop-lang"
          lang="en"
          style="text-decoration: none;"
        >
          <span class="adult-lang__text ${typo('14_medium')}">English</span>
        </a>`
      : ''}
    ${T.lang != 'jp'
      ? html`<a
          href="${href.replace(lang, '/jp')}"
          class="mp-shop-lang"
          lang="jp"
          style="text-decoration: none;"
        >
          <span class="adult-lang__text ${typo('14_medium')}">日本語</span>
        </a>`
      : ''}
  </div>`;
};

export const adultItem = () => {
  const age = T.lang == 'kr' ? 19 : 18;
  return html`
    <div class="adult-item adult-item--${age}">
      <div class="adult-item__age">${age}</div>
      <div class="adult-item__plus">+</div>
    </div>
  `;
};
