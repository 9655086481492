import { html } from 'fxjs/es';
import { NessCoreConstantS } from '../Constant/module/NessCoreConstantS.js';
import {
  getDefaultHeaderCenter,
  getDefaultHeaderLogo,
  getDefaultHeaderRight,
  makeMobileGlobalMenuPopup,
} from './header.helper.js';

/**
 * @param {string} [left_header] override 용 left_header
 * @param {string} [center_header] override 용 center_header
 * @param {string} [right_header] override 용 right_header
 * @param {string} [bottom_header] override 용 bottom_header
 * @param {'fixed' | 'relative'} [position] fixed 일지 가만히 있을 지
 * @param {boolean} headroom headroom 적용 여부
 * @param {boolean} is_mobile
 * @param {boolean} [is_layered] 페이지 컨텐츠 위에 그릴 건지, 별도로 헤더 영역을 가지고 있을 건지
 * @param {boolean} [is_simple_logo]
 * @param {"white" | "black"} [content_color] 색상 설정
 * @param {"white" | "black" | "transparent"} [background_color] 색상 설정
 * @param {number} [active_menu_idx]
 * @param {number} [cart_count]
 * @param {boolean} is_logged_in
 * @return {string}
 */
export const createDefaultHeader = ({
  left_header,
  center_header,
  right_header,
  bottom_header,
  position = 'fixed',
  headroom = true,
  is_mobile,
  is_layered = false,
  style: { is_simple_logo = false, content_color = 'black', background_color = 'white' } = {},
  data: { active_menu_idx, cart_count, is_logged_in } = {},
} = {}) => {
  const component = NessCoreConstantS.layout_components.header;

  return html`
    <header
      class="${component}"
      data-position="${position}"
      data-layered="${is_layered}"
      data-content-color="${content_color}"
      data-background-color="${background_color}"
      data-headroom="${headroom}"
    >
      <div class="${component}__position-wrapper">
        <div class="${component}__core">
          <div class="${component}__left">
            ${left_header === undefined
              ? getDefaultHeaderLogo(is_simple_logo, content_color)
              : left_header || ''}
          </div>
          <div class="${component}__center">
            ${center_header === undefined ? getDefaultHeaderCenter(active_menu_idx) : center_header || ''}
          </div>
          <div class="${component}__right">
            ${right_header === undefined
              ? getDefaultHeaderRight(cart_count, is_mobile, is_logged_in)
              : right_header || ''}
          </div>
        </div>
        ${bottom_header ? `<div class="${component}__bottom-header">${bottom_header}</div>` : ''}
      </div>
      ${is_mobile ? makeMobileGlobalMenuPopup(is_logged_in) : ''}
    </header>
  `;
};
