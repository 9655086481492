import { entries, equals2, html, strMap } from 'fxjs/es';

import querystring from 'querystring';
import { MShopAppSolutionItemTmplS } from '../../../../MShop/App/Solution/Item/S/Tmpl/module/MShopAppSolutionItemTmplS.js';
import { MShopAppSolutionListTmplS } from '../../../../MShop/App/Solution/List/S/Tmpl/module/MShopAppSolutionListTmplS.js';
import { MShopStudioPcInfoTmplS } from '../../../../MShop/Studio/PcInfo/S/Tmpl/module/MShopStudioPcInfoTmplS.js';
import { makePaginationHtml } from '../../../../Pagination/S/tmpl.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { messages } from '../../../Setting/S/messages_id.js';
import { STATUS_TITLE2, TYPE_TO_TITLE } from '../../../Solution/Home/S/constant.js';

const makeNavHtml = ({ status, pathname }) =>
  legacyHtml`
    <nav class="nav nav-tabs solution-nav">
      <a href="${pathname}" class="nav-item nav-link" data-active="${equals2(status, void 0)}">
        ${T(`전체`)}
      </a>
      ${strMap(
        ([status2, title]) => legacyHtml`
          <a
            href="${pathname}?status=${status2}"
            class="nav-item nav-link"
            data-active="${equals2(status, status2)}"
          >
            ${T(`${title}`)}
          </a>
        `,
        entries(STATUS_TITLE2()),
      )}
    </nav>
  `;

export const makeCrewSolutionHtml = ({
  is_selector,
  query,
  solutions,
  pagination_state,
  pathname,
  is_mobile,
}) =>
  is_mobile
    ? html`<div class="no_mobile">
        ${MShopStudioPcInfoTmplS.unsupportedMobile(T('신고 접수는 PC에서만 가능합니다.'))}
      </div>`
    : html`
        <section class="crew-solution">
          <h2>${T(messages.menus.solution)}</h2>
          ${makeNavHtml({ status: query.status, pathname })}
          <div class="body white nav-tab-body">
            <div class="request-of-solution">
              <div class="request-of-solution-body">
                ${is_selector
                  ? legacyHtml`
                <section class="header">
                  <select onchange="if(this.value) window.location.href=this.value;">
                    <option value="${pathname}?${querystring.stringify({ status: query.status })}">
                      ${T('all')}
                    </option>
                    ${strMap(
                      ([key, val]) => legacyHtml`
                        <option
                          value="${pathname}?${querystring.stringify({ status: query.status, type: key })}"
                          ${query.type == key ? 'selected' : ''}
                        >
                          ${val}
                        </option>
                      `,
                      entries(TYPE_TO_TITLE()),
                    )}
                  </select>
                </section>
              `
                  : ''}
                <div class="request-of-solution-list">
                  <div class="solution-list">
                    ${solutions.length
                      ? strMap(MShopAppSolutionListTmplS.item(pathname), solutions)
                      : legacyHtml`
                    <div class="no-solution-list">
                      <h2>${T('접수된 신고가 없습니다.')}</h2>
                    </div>
                  `}
                  </div>
                  ${makePaginationHtml(pagination_state)}
                </div>
              </div>
            </div>
          </div>
        </section>
      `;

export const makeCrewSolutionItemHtml = ({ solution, page_name, is_mobile }) => legacyHtml`
  <section class="crew-solution">
    <h2>${T(messages.menus.solution)}</h2>
    <div class="body white">
      <div class="request-of-solution">
        <div class="request-of-solution-body">${MShopAppSolutionItemTmplS.info(solution, page_name)}</div>
      </div>
    </div>
  </section>
`;
