import { isBefore } from 'date-fns';
import { html } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';

export const makeComingSoonHtmlInProductThumbnail = (sell_start_at) => {
  const component = 'app-product-item';

  if (!sell_start_at) return '';

  // 이미 출시함
  if (isBefore(new Date(sell_start_at), new Date())) return '';

  const remain_time_infos = UtilS.differenceTimeInUnits(
    ['day', 'hour', 'minute', 'second'],
    new Date(sell_start_at),
    new Date(),
    true,
  );
  const { day, hour, minute, second } = remain_time_infos;

  return html`
    <div class="${component}__coming-soon">
      <span>${ET('ness::product::product_11')}</span>
      <span class="${component}__remaining-time" data-sell_start_at="${sell_start_at}"
        >${day}D ${hour}H ${minute}M ${second}S</span
      >
    </div>
  `;
};
