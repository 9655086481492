import { html, View } from 'rune-ts';
import klass from './ProductCardListEmptyScreen.module.scss';
import { typo } from '../../../../shared/typography/typo';
import { ButtonAction } from '../../../../shared/components/atoms/ButtonAction/ButtonAction';
import { generateProductListUrl } from '../../../../features/ProductList/util';

interface ProductCardListEmptyScreenProps {
  klass?: string;
}

/**
 * 상품목록 기본 empty screen
 */
export class DefaultProductListEmptyScreen extends View<ProductCardListEmptyScreenProps> {
  image_src: string;
  title: string;
  body: string;

  constructor(data: ProductCardListEmptyScreenProps = {}) {
    super(data);
    this.image_src =
      '//s3.marpple.co/files/u_2232571/2024/6/original/a57e2be49b0b2c16047dc905043fa2e5c9fbff141.png';
    this.title = ET('mps2::product::empty::shop_title');
    this.body = ET('mps2::product::empty::shop_body');
  }

  override template() {
    return html`<div class="${klass.shop_empty} ${this.data.klass}">
      <img class=${klass.image} src="${this.image_src}" alt="" />
      <span class="${klass.title} ${typo('20_bold')}">${this.title}</span>
      <span class="${klass.body} ${typo('16_medium')}">${this.body}</span>
    </div>`;
  }
}

/**
 * 크리에이터샵의 상품 목록에서 사용하는 empty screen
 */
export class CreatorShopProductListEmptyScreen extends View<ProductCardListEmptyScreenProps> {
  image_src: string;
  title: string;
  body: string;

  constructor(data: ProductCardListEmptyScreenProps = {}) {
    super(data);
    this.image_src =
      '//s3.marpple.co/files/u_218933/2024/5/original/b1a00d53c82603359c6aa95ee43f940c832a4dbc1.png';
    this.title = ET('mps2::product::empty::creator_title');
    this.body = ET('mps2::product::empty::creator_body');
  }

  override template() {
    return html`<div class="${klass.creator_shop_empty} ${this.data.klass}">
      <img class=${klass.image} src="${this.image_src}" alt="" />
      <span class="${klass.title} ${typo('20_bold')}">${this.title}</span>
      <span class="${klass.body} ${typo('16_medium')}">${html.preventEscape(this.body)}</span>
      <a href="${generateProductListUrl()}" class="${klass.footer}">
        ${new ButtonAction({
          label: ET('mps2::product::empty::creator_footer'),
          type: 'blue',
          size: 'large',
        })}
      </a>
    </div>`;
  }
}

/**
 * 플러스샵의 상품 목록에서 사용하는 empty screen
 */
export class PlusShopProductListEmptyScreen extends View<ProductCardListEmptyScreenProps> {
  image_src: string;
  title: string;
  body: string;

  constructor(data: ProductCardListEmptyScreenProps & { plus_name: string }) {
    super(data);
    this.image_src =
      '//s3.marpple.co/files/u_218933/2024/5/original/b1a00d53c82603359c6aa95ee43f940c832a4dbc1.png';
    this.title = ET('mps2::product::empty::creator_title');
    this.body = ET('mps2::product::empty::plus_shop_body', { name: data.plus_name });
  }

  override template() {
    return html`<div class="${klass.plus_shop_empty} ${this.data.klass}">
      <img class=${klass.image} src="${this.image_src}" alt="" />
      <span class="${klass.title} ${typo('20_bold')}">${this.title}</span>
      <span class="${klass.body} ${typo('16_medium')}">${html.preventEscape(this.body)}</span>
    </div>`;
  }
}
