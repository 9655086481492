import { $addClass, $attr, $closest, $delegate, $find, $removeClass, $setAttr, $setHTML } from 'fxdom/es';
import { delay, go, map } from 'fxjs/es';
import './style.styl';

/* [example template]
<div className='user dropdown' is='dropdown'>
  <div className='dropdown-anchor'>
    <div className='user'><span className='initial'>D</span></div>
  </div>
  <ul className='dropdown-list'>
    <li className='dropdown-list-item'><a href="${base_url}">${T('내 스토어')}</a></li>
    <li className='dropdown-list-item'><a href="https://marpple.shop/kr">${T('마플샵')}</a></li>
    <li className='dropdown-list-item'><a href="${logout_path}">${T('로그아웃')}</a></li>
  </ul>
</div>
*/

export const init = (_host_el) => {
  const host_els = _host_el ? [_host_el] : document.querySelectorAll('[is=dropdown]');

  go(
    host_els,
    map(async (el) => {
      await delay(1, 1);

      const $list = $find('.dropdown-list', el);
      const elem_id = `dd-${Date.now()}`;
      const type = $attr('type', el);

      $setAttr({ id: elem_id }, el);

      $delegate('click', '.dropdown-anchor', (e) => {
        const is_data_show = $attr('data-show', $list) === 'true';
        $setAttr({ 'data-show': !is_data_show }, $list);
        !is_data_show ? $addClass('opened', el) : $removeClass('opened', el);
      })(el);

      $delegate('click', `> :not(#${elem_id})`, (e) => {
        const child = $closest('[is=dropdown]', e.target);
        if (child === el) return;
        $setAttr({ 'data-show': false }, $list);
        $removeClass('opened', el);
      })(document.body);

      if (type === 'select') {
        $delegate('click', '.dropdown-list-item', (e) => {
          const $anchor = $find('.dropdown-label', el);
          const label = $attr('data-label', $closest('.dropdown-list-item', e.target));
          const value = $attr('data-value', e.target);
          const eventName = $attr('dispatch', el);

          $setHTML(label || e.target.innerHTML, $anchor);
          $setAttr({ 'data-show': false, 'data-value': value }, $list);
          $removeClass('opened', el);
          eventName && el.dispatchEvent(new CustomEvent(eventName, { bubbles: true, detail: { value } }));
        })(el);
      }
    }),
  );
};
