/**
 * @dev lang 부분만 바꿔주는 util
 * @param {string} url
 * @param {string} lang
 * @returns {string}
 */
export const changeUrlLang = (url, lang) => {
  const received_url = new URL(url);
  // slash(/) 로 시작하고 en kr jp 중에 하나를 가지고 / 가 붙거나 끝나거나
  const lang_regex = /^(\/(en|kr|jp))(?=\/|$)/;
  received_url.pathname = received_url.pathname.replace(lang_regex, `/${lang}`);
  return received_url.toString();
};
