import { CustomEventWithDetail, html, rune, View } from 'rune-ts';
import { makeSearchScreenNavigate } from '../../../../../shared/app/navigate';
import { dataStr } from '../../../../../shared/util/dataStr';
import klass from '../SearchModule.module.scss';
import { typo } from '../../../../../shared/typography/typo';
import { htmlIf } from '../../../../../shared/util';
import axios from 'axios';
import { ButtonClose } from '../../../atoms/ButtonClose/ButtonClose';
import { SearchData } from '../SearchModule';
import { generateSearchUrl } from '../../../../../features/Search/const/url';

export class RecentSearchRemoveEvent extends CustomEventWithDetail<string> {}

interface RecentSearchOption {
  klass?: string;
  theme?: 'light' | 'dark';
  is_mobile?: boolean;
}

class RecentSearch extends View<SearchData> {
  is_open: boolean;

  static Theme = {
    light: 'light',
    dark: 'dark',
  } as const;

  constructor(data: SearchData, private option: RecentSearchOption) {
    super(data, option);
    this.is_open = false;
  }

  override template() {
    const search_url = generateSearchUrl(this.data.name);
    return html`<span
      class="${typo('14_medium')} ${this.option.klass} ${klass.search_recent} ${klass[this.theme]} "
    >
      <a
        data-post-message="${dataStr(makeSearchScreenNavigate(search_url))}"
        href="${search_url}"
        class="${klass.label}"
      >
        ${html.preventEscape(this.data.name)}
      </a>
      ${new ButtonClose({
        // klass: klass.recent_search,
        theme: this.theme,
        transparent: !this.is_mobile,
        hide: false,
      })}
    </span>`;
  }

  override onRender() {
    this.delegate('click', ButtonClose, () => {
      this.dispatchEvent(RecentSearchRemoveEvent, {
        bubbles: true,
        detail: this.data.name,
      });
    });
  }

  private get theme(): keyof typeof RecentSearch.Theme {
    return this.option.theme ?? RecentSearch.Theme.light;
  }

  private get is_mobile(): boolean {
    return !!rune.getSharedData(this)?.is_mobile;
  }
}

interface RecentSearchesSectionData {
  recent_searches: SearchData[];
  is_mobile?: boolean;
}

export class RecentSearchesSection extends View<RecentSearchesSectionData> {
  constructor(data: RecentSearchesSectionData = { recent_searches: [] }) {
    super(data);
  }

  override template() {
    return html`
      <div class="${klass.recent_section}">
        <div class="${klass.header} ${typo('12_medium')}">
          <span class="${klass.title}">${ET('mps2::search::recent_search')}</span>
          <button class="${klass.remove_all} ${typo('12_medium')} ${htmlIf(klass.hidden, this.isEmpty())}">
            ${ET('mps2::common::remove_all')}
          </button>
        </div>
        <div class="${klass.searches_container}">
          ${this.isEmpty()
            ? html`<span class="${typo('14_bold')} ${klass.empty}">${ET('mps2::search::recent_empty')}</span>`
            : this.data.recent_searches.map(
                (recent_search) =>
                  new RecentSearch(recent_search, {
                    klass: klass.recent_search,
                    theme: this.is_mobile ? RecentSearch.Theme.light : RecentSearch.Theme.dark,
                  }),
              )}
        </div>
      </div>
    `;
  }

  override onRender() {
    this.delegate('click', `.${klass.remove_all}`, async (e) => {
      // TODO: @kjj feature로 이동
      await axios.delete(`/${T.lang}/@api/keywords/history/all`);
      this.data.recent_searches = [];
      this.redraw();
    });

    this.delegate(RecentSearchRemoveEvent, RecentSearch, async (e, view) => {
      // TODO: @kjj feature로 이동
      try {
        const { data: recents } = await axios.delete<{ queries: string[] }>(
          `/${T.lang}/@api/keywords/history`,
          { params: { q: e.detail } },
        );
        const recent_searches: SearchData[] = recents.queries.map((name) => ({ name }));
        this.setData({ recent_searches });
        this.redraw();
      } catch (e) {
        // FIXME: @kjj 에러 핸들링
      }
    });
  }

  show() {
    this.element().classList.remove(klass.hidden);
  }

  hide() {
    this.element().classList.add(klass.hidden);
  }

  setData(data: RecentSearchesSectionData) {
    this.data.recent_searches = data.recent_searches;
  }

  private isEmpty() {
    return !this.data.recent_searches.length;
  }

  private get is_mobile(): boolean {
    return !!(this.data.is_mobile ?? rune.getSharedData(this)?.is_mobile);
  }
}
