import { html } from 'fxjs/es';

export const kr_afreeca_20240315 = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>SOOP x (주)마플코퍼레이션 이용약관</h1>
    </div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <p>
            주식회사 숲(이하 “회사”)은 BJ굿즈 제작 서비스 및 굿즈 제작을 통한 “BJ”의 수익금 발생 지급 서비스
            일체를 주식회사 마플코퍼레이션 (이하 “협력사”)에 위탁하며 위와 관련한 서비스 이용 세부사항은
            “협력사” 서비스 이용 약관을 따르는 것으로 합니다.
          </p>
          <p>전화: <a href="tel:1566-9437">1566-9437</a> (상담가능시간: 평일 10:00~18:00)</p>
          <p>홈페이지: <a href="https://www.marpple.com/">https://www.marpple.com/</a></p>
        </div>
        <div class="paragraph">
          <p>
            “BJ”는 본 서비스 이용을 위해 “협력사” 서비스 내 회원가입이 필요하며, “회사”의 샵프리카 약관에 따라
            서비스 이용에 필요한 필수 개인정보가 “협력사”에 제3자 제공됨에 동의합니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            “회사”는 ”BJ”가 제작한 본인의 굿즈 판매를 통해 발생된 “BJ수익금”을 ”협력사”를 통해 ”BJ”에게 정산
            및 지급하며, “회사”는 “협력사”에게 대금을 지급하면서 “수익금”의 지급 의무를 다하는 것으로 합니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            ”BJ수익금”은 ”BJ”가 설정한 굿즈의 판매가액과 제작원가의 차액(이하 “판매이익”)에서 “플랫폼
            수수료”를 제외한 금액을 의미하며, “플랫폼 수수료”는 굿즈의 판매에 따른 결제수수료 및 BJ의 라이브,
            VOD, 방송국의 링크 기능 사용료 등을 의미하며, “회사” 및 “협력사”가 수취합니다. 그 금액은
            “판매이익”의 30%로 책정됩니다.
          </p>
        </div>
        <div class="paragraph">
          <p>
            ”BJ”는 타 콘텐츠의 지적재산권 침해, 음란성, 사회적 이슈를 일으킬 수 있는 내용이 포함된 굿즈 제작이
            불가합니다. 또한, 제작 당시에는 제작 및 판매에 문제가 없는 굿즈라 하더라도 사회적으로 이슈가
            발생되어 “회사” 또는 “협력사”의 대외적 이미지 등의 타격이 있다고 보일 수 있는 경우 ”회사”는 ”BJ”에
            통보 없이 판매를 중지할 수 있습니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            본 내용은 굿즈 제작 의뢰 및 판매 종료시까지 유효하며, 특별한 의사표시가 없는 이상 새로운 굿즈
            제작을 의뢰하시더라도 본 내용이 계속 적용되는 것으로 간주합니다.
          </p>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;

export const en_afreeca_20240315 = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>SOOP x (주)마플코퍼레이션 이용약관</h1>
    </div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <p>
            주식회사 숲(이하 “회사”)은 BJ굿즈 제작 서비스 및 굿즈 제작을 통한 “BJ”의 수익금 발생 지급 서비스
            일체를 주식회사 마플코퍼레이션 (이하 “협력사”)에 위탁하며 위와 관련한 서비스 이용 세부사항은
            “협력사” 서비스 이용 약관을 따르는 것으로 합니다.
          </p>
          <p>전화: <a href="tel:1566-9437">1566-9437</a> (상담가능시간: 평일 10:00~18:00)</p>
          <p>홈페이지: <a href="https://www.marpple.com/">https://www.marpple.com/</a></p>
        </div>
        <div class="paragraph">
          <p>
            “BJ”는 본 서비스 이용을 위해 “협력사” 서비스 내 회원가입이 필요하며, “회사”의 샵프리카 약관에 따라
            서비스 이용에 필요한 필수 개인정보가 “협력사”에 제3자 제공됨에 동의합니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            “회사”는 ”BJ”가 제작한 본인의 굿즈 판매를 통해 발생된 “BJ수익금”을 ”협력사”를 통해 ”BJ”에게 정산
            및 지급하며, “회사”는 “협력사”에게 대금을 지급하면서 “수익금”의 지급 의무를 다하는 것으로 합니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            ”BJ수익금”은 ”BJ”가 설정한 굿즈의 판매가액과 제작원가의 차액(이하 “판매이익”)에서 “플랫폼
            수수료”를 제외한 금액을 의미하며, “플랫폼 수수료”는 굿즈의 판매에 따른 결제수수료 및 BJ의 라이브,
            VOD, 방송국의 링크 기능 사용료 등을 의미하며, “회사” 및 “협력사”가 수취합니다. 그 금액은
            “판매이익”의 30%로 책정됩니다.
          </p>
        </div>
        <div class="paragraph">
          <p>
            ”BJ”는 타 콘텐츠의 지적재산권 침해, 음란성, 사회적 이슈를 일으킬 수 있는 내용이 포함된 굿즈 제작이
            불가합니다. 또한, 제작 당시에는 제작 및 판매에 문제가 없는 굿즈라 하더라도 사회적으로 이슈가
            발생되어 “회사” 또는 “협력사”의 대외적 이미지 등의 타격이 있다고 보일 수 있는 경우 ”회사”는 ”BJ”에
            통보 없이 판매를 중지할 수 있습니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            본 내용은 굿즈 제작 의뢰 및 판매 종료시까지 유효하며, 특별한 의사표시가 없는 이상 새로운 굿즈
            제작을 의뢰하시더라도 본 내용이 계속 적용되는 것으로 간주합니다.
          </p>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;

export const jp_afreeca_20240315 = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>SOOP x (주)마플코퍼레이션 이용약관</h1>
    </div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <p>
            주식회사 숲(이하 “회사”)은 BJ굿즈 제작 서비스 및 굿즈 제작을 통한 “BJ”의 수익금 발생 지급 서비스
            일체를 주식회사 마플코퍼레이션 (이하 “협력사”)에 위탁하며 위와 관련한 서비스 이용 세부사항은
            “협력사” 서비스 이용 약관을 따르는 것으로 합니다.
          </p>
          <p>전화: <a href="tel:1566-9437">1566-9437</a> (상담가능시간: 평일 10:00~18:00)</p>
          <p>홈페이지: <a href="https://www.marpple.com/">https://www.marpple.com/</a></p>
        </div>
        <div class="paragraph">
          <p>
            “BJ”는 본 서비스 이용을 위해 “협력사” 서비스 내 회원가입이 필요하며, “회사”의 샵프리카 약관에 따라
            서비스 이용에 필요한 필수 개인정보가 “협력사”에 제3자 제공됨에 동의합니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            “회사”는 ”BJ”가 제작한 본인의 굿즈 판매를 통해 발생된 “BJ수익금”을 ”협력사”를 통해 ”BJ”에게 정산
            및 지급하며, “회사”는 “협력사”에게 대금을 지급하면서 “수익금”의 지급 의무를 다하는 것으로 합니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            ”BJ수익금”은 ”BJ”가 설정한 굿즈의 판매가액과 제작원가의 차액(이하 “판매이익”)에서 “플랫폼
            수수료”를 제외한 금액을 의미하며, “플랫폼 수수료”는 굿즈의 판매에 따른 결제수수료 및 BJ의 라이브,
            VOD, 방송국의 링크 기능 사용료 등을 의미하며, “회사” 및 “협력사”가 수취합니다. 그 금액은
            “판매이익”의 30%로 책정됩니다.
          </p>
        </div>
        <div class="paragraph">
          <p>
            ”BJ”는 타 콘텐츠의 지적재산권 침해, 음란성, 사회적 이슈를 일으킬 수 있는 내용이 포함된 굿즈 제작이
            불가합니다. 또한, 제작 당시에는 제작 및 판매에 문제가 없는 굿즈라 하더라도 사회적으로 이슈가
            발생되어 “회사” 또는 “협력사”의 대외적 이미지 등의 타격이 있다고 보일 수 있는 경우 ”회사”는 ”BJ”에
            통보 없이 판매를 중지할 수 있습니다.
          </p>
        </div>

        <div class="paragraph">
          <p>
            본 내용은 굿즈 제작 의뢰 및 판매 종료시까지 유효하며, 특별한 의사표시가 없는 이상 새로운 굿즈
            제작을 의뢰하시더라도 본 내용이 계속 적용되는 것으로 간주합니다.
          </p>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;
