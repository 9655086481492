import { html, rune, View } from 'rune-ts';
import { makeEmptyScreenNavigate, makeUtilScreenNavigate } from '../../../../shared/app/navigate';
import { dataStr } from '../../../../shared/util/dataStr';
import klass from './CurationListItem.module.scss';
import { map, pipe, take, toArray } from '@fxts/core';
import { ProductThumbnail } from '../ProductThumbnail/ProductThumbnail';
import $dataStr from 'fxdom/es/dataStr.js';
import { staticTypo, typo, typoPcMo } from '../../../../shared/typography/typo';
import { isNil } from 'fxjs/es';
import { classes, htmlIf } from '../../../../shared/util';
import { MakeOptional } from '../../../../shared/type/types';
import { FeedShowInfoBoxView } from './FeedShowInfoBox';
import { CurationListItem } from '../../../../features/Curation/type';
import { makeMainUrl } from '../../../../shared/util/url';
import { getResizedUrl } from '../../../../shared/util/image';

export interface CurationListItemData {
  curation: CurationListItem;
}

export interface CurationListItemState {
  klass?: string;
  is_mobile?: boolean;
  thumbnail_type: 'short' | 'long';
  without_products: boolean;
  without_tags: boolean;
  without_category?: boolean;
  is_admin: boolean;
  outlink?: string;
  theme?: 'dark' | 'light';
  link_with_category: boolean;
}

export type CurationListItemOption = MakeOptional<
  CurationListItemState,
  'without_products' | 'without_tags' | 'is_admin' | 'link_with_category'
>;

export class CurationListItemView extends View<CurationListItemData> {
  state: CurationListItemOption;

  static Theme = {
    dark: 'dark',
    light: 'light',
  } as const;

  static ThumbnailType = {
    long: 'long',
    short: 'short',
  } as const;

  constructor(data: CurationListItemData, option: CurationListItemOption) {
    super(data, option);

    this.state = {
      klass: option.klass,
      is_mobile: option.is_mobile,
      thumbnail_type: option.thumbnail_type,
      without_products: option.without_products ?? false,
      without_tags: option.without_tags ?? false,
      without_category: option.without_category ?? false,
      is_admin: option.is_admin ?? false,
      outlink: option.outlink,
      theme: option.theme ?? CurationListItemView.Theme.light,
      link_with_category: option.link_with_category ?? false,
    };
  }

  getIsMobile(): boolean {
    const is_mobile = rune.getSharedData(this)?.is_mobile ?? this.state.is_mobile;
    if (isNil(is_mobile)) throw new Error('is_mobile is not set');
    return is_mobile;
  }

  replaceThumbnailDefault(thumbnail_element: HTMLImageElement) {
    const is_short = this.state.thumbnail_type === CurationListItemView.ThumbnailType.short;
    thumbnail_element.src = is_short
      ? '//s3.marpple.co/files/u_1165777/2024/6/original/aa18764c517c17f281b4d364276e93767ed23df01.png'
      : '//s3.marpple.co/files/u_1165777/2024/6/original/01c5bcc84c3d344db369f973cd0d9812a22ec6fb1.png';
  }

  override onMount() {
    this.delegate('error', `.${klass.thumbnail}`, (e) => {
      const element = e.target as HTMLImageElement;
      this.replaceThumbnailDefault(element);
    });

    const image_element = this.element().querySelector(`.${klass.thumbnail}`) as HTMLImageElement;

    if (image_element.complete && !(image_element.naturalWidth > 0)) {
      // loading 은 끝났으나, 응답이 제대로 되지 않았거나 이미지가 고장난 경우
      this.replaceThumbnailDefault(image_element);
    }
  }

  override template() {
    const { curation } = this.data;
    const { thumbnail_type, without_products, without_tags, outlink, is_admin, theme, link_with_category } =
      this.state;
    const is_mobile = this.getIsMobile();
    const is_short = thumbnail_type === CurationListItemView.ThumbnailType.short;
    const link = makeMainUrl(
      outlink ??
        `/@/curations/${curation.id}${link_with_category ? `?category=${curation.category_id}` : ''}`,
    );

    return html`<div
      class="${this.state.klass} ${klass.curation_item} ${classes({
        [klass.short]: is_short,
        [klass.dark]: theme === CurationListItemView.Theme.dark,
      })}"
    >
      <a
        class="${klass.img_container}"
        data-post-message="${dataStr(makeEmptyScreenNavigate(link))}"
        href="${link}"
      >
        <img
          class="${klass.thumbnail}"
          src="${getResizedUrl({
            url: is_short ? curation.mobile_thumbnail : curation.thumbnail,
            format: 'webp',
          })}"
          alt=""
        />
        ${htmlIf(
          html`<span class="${klass.category} ${staticTypo('unica_12_regular')}">${curation.category}</span>`,
          !this.state.without_category,
        )}
      </a>

      <a
        href="${link}"
        data-post-message="${dataStr(makeEmptyScreenNavigate(link))}"
        class="${klass.title} ${typoPcMo({ is_mobile, pc: is_short ? '14_bold' : '20_bold', mo: '16_bold' })}"
        >${html.preventEscape(curation.title)}</a
      >
      <a
        href="${link}"
        data-post-message="${dataStr(makeEmptyScreenNavigate(link))}"
        class="${klass.subtitle} ${typo(is_short ? '12_medium' : '14_medium')}"
        >${html.preventEscape(curation.sub_title)}</a
      >
      ${htmlIf(
        html`<div class="${klass.tags} ${typo(is_mobile ? '12_medium' : '14_medium')}">
          ${curation.tags.map((tag) => html.preventEscape(`<span class="${klass.tag}">#${tag}</span>`))}
        </div>`,
        !without_tags,
      )}
      ${without_products
        ? ''
        : html`<div class="${klass.products}">
            ${pipe(
              curation.products,
              take(4),
              map((product) => {
                const product_link = `/${ET.lang}/${product.domain_name}/products/${product?.id}`;

                return html`
                  <a
                    class="${klass.product_item} ${!product ? klass.empty : ''}"
                    ${product ? html`href="${product_link}"` : ''}
                    data-post-message="${$dataStr(makeUtilScreenNavigate(product_link))}"
                  >
                    ${product
                      ? new ProductThumbnail(product, { resize_width: 305 * 2, img_alt: product.name })
                      : html`<div class="${klass.content_empty}"></div>`}
                  </a>
                `;
              }),
              toArray,
            )}
          </div>`}
      ${is_admin ? html`<div class="${klass.show_info_box}">${new FeedShowInfoBoxView(curation)}</div>` : ''}
    </div> `;
  }
}
