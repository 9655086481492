export const WITHDRAW_SETTINGS_FRAME = '@FRAME/Creator/Withdraw/withdraw_settings';
export const WITHDRAW_SETTINGS_PAGE = '@PAGE/Creator/Withdraw/withdraw_settings';
export const WITHDRAW_SETTINGS_TAB = '@TAB/Creator/Withdraw/withdraw_settings';

export const WITHDRAW_CREWS_FRAME = '@FRAME/Creator/Withdraw/withdraw_crews';
export const WITHDRAW_CREWS_PAGE = '@PAGE/Creator/Withdraw/withdraw_crews';
export const WITHDRAW_CREWS_TAB = '@TAB/Creator/Withdraw/withdraw_crews';

export const WITHDRAW_APPROVE_FRAME = '@FRAME/Creator/Withdraw/withdraw_approve';
export const WITHDRAW_APPROVE_PAGE = '@PAGE/Creator/Withdraw/withdraw_approve';
export const WITHDRAW_APPROVE_TAB = '@TAB/Creator/Withdraw/withdraw_approve';

export const CREW_WITHDRAW_FRAME = '@FRAME/Creator/Withdraw/crew_withdraws';
export const CREW_WITHDRAW_PAGE = '@PAGE/Creator/Withdraw/crew_withdraws';
export const CREW_WITHDRAW_TAB = '@TAB/Creator/Withdraw/crew_withdraws';

export const account_history_fields = [
  'type',
  'number',
  'owner',
  'bank',
  'user_name',
  'phone',
  'company_name',
  'business_license_number',
  'parent_name',
  'parent_birth',
  'nationality',
];

export const WITHDRAW_EXCEL_HEADER = [
  'No.',
  '상태',
  '검증여부',
  '유형',
  '상점이름',
  '디자인 상품 정산액',
  '디지털 상품 정산액',
  '정산액',
  '부가세',
  '소득세',
  '주민세',
  '총세금',
  '원천징수 반영',
  '셀러 상품 정산액',
  '입고 상품 정산액',
  '총 예상 입금액',
  '소득자',
  '주민번호',
  '은행명',
  '계좌번호',
  '예금주',
  '승인/취소',
  '취소사유',
];
export const WITHDRAW_DATE = '11';

export const MSG = {
  check_bank_account_filled_message:
    '<h3>출금 불가</h3>필요한 계좌정보 내용이 모두 기입되지 않았습니다. 계좌정보 페이지에서 내용을 기입해 주세요.',
  check_tax_paper_message_title: '세금계산서 발행',
  check_tax_paper_message: `사업자 크리에이터는 출금 신청 완료 후<br />[굿즈 + 디지털 + 입고 상품 정산 금액]에 대해 당월 10일까지 세금계산서를 발행해주셔야 합니다.
<br />세금계산서가 기간 내 발행되지 않거나 오류가 있는 경우, 이번 달 출금 신청은 자동 취소됩니다.
<br /><br /><a target="_blank" rel="noopener noreferrer" href="https://www.marppleshop.help/5cd83306-749f-4c98-af61-b24589d53152#30daf451-f7e0-4c09-8085-11c4fcaf5f6a:~:text=%EC%8B%A0%EA%B3%A0%EB%A5%BC%20%EC%A7%84%ED%96%89%ED%95%B4%EC%95%BC%20%ED%95%98%EB%82%98%EC%9A%94%3F-,%EC%82%AC%EC%97%85%EC%9E%90%20%EC%84%B8%EA%B8%88%EA%B3%84%EC%82%B0%EC%84%9C%20%EB%B0%9C%ED%96%89%ED%95%98%EA%B8%B0,-%EC%84%B8%EA%B8%88%EA%B3%84%EC%82%B0%EC%84%9C%20%EB%B0%9C%ED%96%89%20%ED%95%9C">사업자 세금계산서 발행 가이드</a>`,
  check_bank_account_accuracy_message:
    '잠깐! 계좌 정보는 정확히 입력하셨나요?<br/>계좌 정보가 잘못된 경우 출금 신청은 취소되며<br/>재신청하는 출금 신청은 다음 달에 정산 진행됩니다.',
  check_amount_over: '입력한 입금액이 정산 금액보다 많습니다. 계속 진행하려면 확인을 눌러주세요.',
};

export const WITHDRAW_TYPE = {
  integrated: 'integrated',
  separate: 'separate',
};

export const STORE_TYPE = {
  seller: 'seller',
  crew: 'crew',
};

export const MINIMUM_WITHDRAW_AMOUNT = 10000;
