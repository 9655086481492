import { go, html, strMap } from 'fxjs/es';
import { ProductBadge } from '../../../../../MShop/App/Product/Item/S/Function/nessProductBadge.js';

/**
 * @param {{ type: string, name: string }[] } badges
 * @param {string} component
 * @return {string}
 */
export const makeNessProductBadgeTmpl = (badges, component) => {
  if (!badges?.length) return '';

  const getBadgeNameSuffix = (badge_type) => {
    if (badge_type === ProductBadge.Type.limited) {
      return ET('ness::product_badge::offer', { lng: 'en' });
    }
    if (badge_type === ProductBadge.Type.period_limited) {
      return ET('ness::product_badge::left', { lng: 'en' });
    }
    return '';
  };

  return html`<div class="${component}__badges">
    ${go(
      badges,
      strMap((badge) => {
        const suffix = getBadgeNameSuffix(badge.type);
        return html`<div class="${component}__badge" data-type="${badge.type}">
          <span>${badge.name}</span>
          ${suffix ? html`<span class="${component}__badge-suffix">${suffix}</span>` : ''}
        </div>`;
      }),
    )}
  </div>`;
};
