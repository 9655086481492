import { html } from 'fxjs/es';

export const nbox_privacy_content_kr = html`
  <h1>개인정보처리방침</h1>
  <p>
    ㈜넥슨코리아(이하 &#39;회사&#39;)는 임직원의 소중한 개인정보를 보호하기 위해 개인정보보호 관련 법령을
    준수합니다. 개인정보처리방침을 통해 회사가 어떤 개인정보를 수집하고, 어떻게 이용하며, 누구에게 제공하는지,
    언제 어떻게 파기하는 지 등에 대해 이해할 수 있으며 본 개인정보처리방침은 임직원 서비스에 적용됩니다. 변경
    사항이 발생하는 경우 개인정보처리방침을 통해 안내하겠습니다.
  </p>
  <h2 id="1-">1. 개인정보의 수집</h2>
  <p>
    회사는 서비스 이용 시 필요한 개인정보만을 수집하며, 수집 시에는 임직원에게 상세히 안내하고 동의를 받아
    처리하고 있습니다.
  </p>
  <ul>
    <li>필수정보란? 기본적인 서비스를 제공하기 위해 수집하는 개인정보</li>
    <li>
      선택정보란? 기본적인 서비스 이외에 부가적인 서비스를 제공하기 위해 수집하는 개인정보 웹페이지, 서면 등의
      방법을 통해 아래와 같은 개인정보를 수집합니다
      <ul>
        <li>
          인사관리: [필수] 이름, 사진, 주민등록번호(내국인인 경우 해당), 외국인 등록번호(외국인인 경우 해당),
          여권번호(외국인 및 출장자인 경우 해당), WEBO 아이디, WEBO 비밀번호, 사외 이메일주소, 휴대전화번호,
          주소, 가족정보, 경력사항, 학력사항, 계좌번호, 병역사항, 전화번호, 자격사항, 국적, 급여정보,
          보훈사항, 배우자 공직여부, 영문이름, 비상연락망, 한자이름, 입사일자, 부서, 사번, 사내 전화번호,
          재직구분, 직급, 사내 이메일주소, 장애사항 [선택] 차량정보(주차신청자), 넥슨통합아이디
        </li>
      </ul>
    </li>
  </ul>
  <h2 id="2-">2. 개인정보의 이용</h2>
  <p>
    회사는 아래의 목적으로 개인정보를 이용하며, 목적이 변경될 경우에는 임직원에게 상세히 안내하고 동의를 받아
    처리하고 있습니다.
  </p>
  <ul>
    <li>임직원을 식별하고, 본인임을 확인하는 등 임직원 관리를 위해 개인정보를 이용합니다.</li>
    <li>입퇴사 관리, 복지서비스 제공, 사내 이벤트 진행 등 인사 관리 및 운영을 위해 개인정보를 이용합니다.</li>
    <li>안전하게 서비스를 이용할 수 있는 환경을 만들기 위해 개인정보를 이용합니다.</li>
  </ul>
  <h2 id="3-">3. 개인정보의 제공</h2>
  <p>
    임직원이 보다 편리하고 신속하게 서비스를 이용할 수 있도록 다른 회사에게 일부 업무를 위탁하고 있습니다.
    회사는 회사의 업무를 처리위탁 받은 회사가 임직원의 소중한 개인정보를 안전하게 처리하도록 관리·감독하며,
    회사의 사전 승인 없이 또 다른 회사에게 재위탁하지 않도록 제한합니다. 회사는 아래와 같은 업무를 위탁하고
    있습니다.
  </p>
  <ul>
    <li>
      대상: ㈜넥슨스페이스(재위탁: ㈜프로에스콤, 도토리소풍 해/달/별/대치/강남/제주 어린이집, ㈜SHP코퍼레이션,
      풀무원푸드앤컬처)
      <ul>
        <li>
          목적: 사내 어린이집 보육 서비스 운영, CCTV 운영, 출입카드 관리, 피트니스 이용자 관리, 식음/식수
          서비스 제공 및 이용 내역 관리
        </li>
      </ul>
    </li>
    <li>
      대상: ㈜베어베터
      <ul>
        <li>목적: 명함제작</li>
      </ul>
    </li>
    <li>
      대상: ㈜베어베터, ㈜베어베터서비스(재위탁: 지역 별 협력업체)
      <ul>
        <li>목적: 경조사 지원</li>
      </ul>
    </li>
    <li>
      대상: ㈜마쉬코리아보험중개(재위탁: DB 손해보험㈜, 에이비엘생명보험㈜, 현대해상화재보험㈜)
      <ul>
        <li>목적: 상해보험 지원</li>
      </ul>
    </li>
    <li>
      대상: 삼성생명보험㈜, 미래에셋증권㈜, ㈜하나은행
      <ul>
        <li>목적: 퇴직연금 지원</li>
      </ul>
    </li>
    <li>
      대상: (주)GC케어(재위탁:CJ올리브네트웍스), 삼성서울병원(재위탁: 트랜스코스모스코리아)
      <ul>
        <li>목적: 건강검진 서비스 제공</li>
      </ul>
    </li>
    <li>
      대상: ㈜엠트웰브
      <ul>
        <li>목적: 모바일상품권 제공</li>
      </ul>
    </li>
    <li>
      대상: 부업하는 오빠들
      <ul>
        <li>목적: 사원증 사진 촬영 지원</li>
      </ul>
    </li>
    <li>
      대상: 심리상담연구소 마음on마음, ㈜이지앤웰니스
      <ul>
        <li>목적: 심리 상담 운영, 예약 안내</li>
      </ul>
    </li>
    <li>
      대상: 그라운더
      <ul>
        <li>목적: 리더십코칭 운영</li>
      </ul>
    </li>
    <li>
      대상: ㈜텔코인(재위탁: ㈜케이티), ㈜태영디에스
      <ul>
        <li>목적: 경품 배송</li>
      </ul>
    </li>
    <li>
      대상:㈜월급날(재위탁: 인포뱅크㈜, ㈜넥서스커뮤니티)
      <ul>
        <li>목적: 연말정산 업무 지원</li>
      </ul>
    </li>
    <li>
      대상: 해피엔딩㈜(재위탁: 지역 별 협력업체)
      <ul>
        <li>목적: 장례서비스 지원</li>
      </ul>
    </li>
    <li>
      대상: ㈜리바스트레이딩
      <ul>
        <li>목적: 임직원 선물 제작, 민원 처리</li>
      </ul>
    </li>
    <li>
      대상: 엔에이치엔페이코㈜
      <ul>
        <li>목적: 복지포인트 제공</li>
      </ul>
    </li>
    <li>
      대상: 한국오피스컴퓨터㈜
      <ul>
        <li>목적: ASAP 운영</li>
      </ul>
    </li>
    <li>
      대상: 비컴비
      <ul>
        <li>목적: 사원증 제작</li>
      </ul>
    </li>
    <li>
      대상: 삼성물산
      <ul>
        <li>목적: 에버랜드 복지서비스 운영</li>
      </ul>
    </li>
    <li>
      대상: 위피크 주식회사
      <ul>
        <li>목적: 스포츠몬스터 복지서비스 운영</li>
      </ul>
    </li>
    <li>
      대상: 쿠콘
      <ul>
        <li>목적: 임직원 예금주 조회</li>
      </ul>
    </li>
    <li>
      <p>대상: ㈜엔젤플러스</p>
      <ul>
        <li>목적: 운전대행 서비스 제공</li>
      </ul>
      <p>원활하고 안정적인 인사 서비스 제공을 위해 아래와 같이 개인정보를 국외에 보관합니다.</p>
    </li>
    <li>
      항목: 사내 이메일주소, 이름, 부서, 휴대전화번호, 사내 전화번호, 사진
      <ul>
        <li>국가: 일본, 미국, 대만, 태국, 베트남</li>
        <li>이전 일시 및 방법: AD 이용 시점에 네트워크를 통한 전송</li>
        <li>
          이전 받는 자: 넥슨재팬, 넥슨아메리카, 넥슨타이완, 넥슨타일랜드, 넥슨네트웍스VINA, NEXON DEV Vietnam
          Limited Company
        </li>
        <li>목적: Microsoft 365 그룹웨어 및 Active Directory 서비스 제공</li>
        <li>보유 및 이용 기간 :근로계약 기간까지</li>
      </ul>
    </li>
    <li>
      <p>항목: 사내 이메일주소, 이름</p>
      <ul>
        <li>국가: 미국</li>
        <li>이전 일시 및 방법: 교육과정 등록 시점에 네트워크를 통한 전송</li>
        <li>이전 받는 자: iSprings Solutions, Inc.,</li>
        <li>목적: 사내 교육 시스템 사용</li>
        <li>보유 및 이용 기간: 교육 시스템 이용기간까지</li>
      </ul>
      <p>
        임직원이 다양한 서비스를 이용할 수 있도록 다른 회사에게 개인정보를 제공하고 있습니다. 회사는 관계
        법령에 규정된 경우를 제외하고 개인정보를 제공하기 이전에 임직원에게 상세히 안내하고 동의를 받아
        처리하고 있습니다. 회사는 아래와 같이 개인정보를 제공하고 있습니다.
      </p>
    </li>
    <li>
      대상: ㈜소노호텔앤리조트, ㈜호텔롯데, ㈜곤지암리조트, ㈜리솜리조트, ㈜용평리조트, ㈜휘닉스중앙, ㈜승산
      <ul>
        <li>목적: 숙박시설 예약 및 이용</li>
        <li>항목: 이름, 휴대전화번호, 차량번호(곤지암리조트)</li>
        <li>보유 및 이용 기간: 숙박시설 이용기간까지</li>
      </ul>
    </li>
    <li>
      대상: ㈜엔진스튜디오, ㈜넥슨네트웍스, ㈜넥슨커뮤니케이션즈, ㈜네오플, ㈜엔미디어플랫폼, ㈜NXC,
      ㈜넥슨스페이스, 넥슨재팬, 넥슨아메리카, 넥슨타이완, 넥슨타일랜드, 넥슨네트웍스VINA, ㈜데브캣, ㈜니트로
      스튜디오, ㈜넥슨게임즈, ㈜미띵스, NEXON DEV Vietnam Limited Company
      <ul>
        <li>목적: 업무 협업 위한 정보 공유, Microsoft 365 그룹웨어 및 Active Directory 서비스 제공</li>
        <li>항목: 사내 이메일주소, 이름, 부서, 휴대전화번호, 사내 전화번호, 사진</li>
        <li>보유 및 이용 기간: 근로계약기간까지</li>
      </ul>
    </li>
    <li>
      대상: ㈜KB손해보험
      <ul>
        <li>목적: 출장 여행보험 가입</li>
        <li>항목: 이름, 영문이름, 여권번호, 주민등록번호</li>
        <li>보유 및 이용 기간: 근로계약기간까지</li>
      </ul>
    </li>
    <li>
      대상: ㈜온라인투어
      <ul>
        <li>목적: 해외출장 항공, 숙박시설 등 예약 및 이용</li>
        <li>
          항목: 이름, 영문이름, 여권번호, 성별, 생년월일, 여권만료일, 휴대전화번호, [중국 비자 발급 시] 주소,
          가족정보
        </li>
        <li>보유 및 이용 기간: 예약 및 발권 대행 업무 종료 시까지</li>
      </ul>
    </li>
    <li>
      대상: ㈜엔에스쇼핑, ㈜리엔피엠씨, ㈜하이파킹
      <ul>
        <li>목적: 외부 주차장 출입 등록</li>
        <li>항목: 차량번호, 차종</li>
        <li>보유 및 이용 기간: 주차장 이용 종료 시까지</li>
      </ul>
    </li>
    <li>
      대상: 서울보증보험㈜
      <ul>
        <li>목적: 대출 지원</li>
        <li>항목: 이름, 생년월일, 휴대전화번호, 성별, 대출신청금액, 대출 목적</li>
        <li>보유 및 이용 기간: 거래 종료 후 5년</li>
      </ul>
    </li>
    <li>
      대상:㈜신한은행
      <ul>
        <li>목적: 대출 지원</li>
        <li>항목: 이름, 주민등록번호, 휴대전화번호, 대출신청금액, 대출 목적</li>
        <li>보유 및 이용 기간: 거래 종료 후 5년</li>
      </ul>
    </li>
  </ul>
  <h2 id="4-">4. 개인정보의 추가적인 이용 또는 제공의 기준</h2>
  <p>
    회사가 관계 법령에 따라 이용자의 동의 없이 개인정보를 추가적으로 이용 또는 제공할 때 판단 기준은 다음과
    같습니다.
  </p>
  <ul>
    <li>당초 수집 목적과 관련성이 있는지 여부</li>
    <li>
      개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측
      가능성이 있는지 여부
    </li>
    <li>이용자의 이익을 부당하게 침해하는지 여부</li>
    <li>가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</li>
  </ul>
  <h2 id="5-">5. 개인정보의 파기</h2>
  <p>
    회사는 개인정보 수집 및 이용 목적이 달성되면 해당 정보를 지체 없이 파기합니다. 다만, 관계 법령에
    규정되거나 임직원에게 동의를 얻은 경우는 예외로 하고 있습니다.
  </p>
  <ul>
    <li>근로기준법 근로자 경력증명에 관한 기록: 3년</li>
    <li>
      소득세법, 국세기본법 급여 지급에 관한 기록: 5년 전자적 파일 형태의 개인정보는 다시 이용할 수 없도록
      기술적인 방법을 통해 삭제하며, 그 밖에 종이에 출력된 개인정보는 분쇄합니다.
    </li>
  </ul>
  <h2 id="6-">6. 임직원 권리와 그 행사 방법</h2>
  <p>
    회사는 임직원 개인정보의 열람, 제공, 정정 요청에 대해 적극적으로 필요한 조치를 합니다.
    &#39;WEBO&gt;인사&gt;인사정보&#39;에서 자신의 개인정보를 열람하거나 정정할 수 있으며, 개인정보보호책임자
    또는 담당부서에게 전화, 이메일 등을 통해 개인정보 열람, 제공, 정정, 동의철회 등을 요구할 수 있습니다.
  </p>
  <h2 id="7-">7. 개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항</h2>
  <p>
    회사는 임직원에게 편리한 웹사이트 사용 환경을 제공하기 위해 쿠키를 사용하며 이를 거부할 수 있는 권리를
    가집니다.
  </p>
  <ul>
    <li>
      쿠키란? 특정 웹사이트에서 웹서버가 웹브라우저에게 전달하는 작은 텍스트 파일 서비스 개선을 위해 쿠키를
      이용하여 임직원의 접속 기록, 이용 형태 등을 파악합니다 임직원은 브라우저에서 옵션 설정을 통해 쿠키가
      수집되지 않도록 거부할 수 있습니다.
      <ul>
        <li>Microsoft Edge: 설정 표시 &gt; 쿠키 및 사이트 권한 &gt; 쿠키 및 사이트 데이터</li>
        <li>Chrome : 설정 &gt; 개인정보 및 보안 &gt; 쿠키 및 기타 사이트 데이터</li>
        <li>NAVER Whale: 설정 &gt; 개인정보 보호 &gt; 콘텐츠 설정 &gt; 쿠키</li>
        <li>그 외 브라우저의 경우 브라우저 별 설정 방식에 따릅니다.</li>
      </ul>
    </li>
  </ul>
  <h2 id="8-">8. 개인정보보호를 위한 노력</h2>
  <p>회사는 임직원의 소중한 개인정보를 보호하기 위해 적극적으로 노력하고 있습니다.</p>
  <ul>
    <li>
      개인정보를 암호화하고 있습니다. 비밀번호, 고유식별정보 등 중요 개인정보를 암호화하여 저장하며, 이렇게
      암호화된 개인정보는 암호화 통신을 이용하여 안전하게 전송하고 있습니다.
    </li>
    <li>
      개인정보를 보호하기 위한 기술에 지속적으로 관심을 가지고 있습니다. 해커 등의 침입을 24시간 탐지하고
      차단하고 있습니다. 또한, 악성코드나 바이러스에 감염되지 않도록 백신프로그램을 설치 및 운영하고 있습니다.
    </li>
    <li>
      개인정보를 다루는 직원을 최소화하고 있습니다. 최소한의 직원만 개인정보를 다루게 되며, 개인정보를
      처리하는 PC에서는 외부 인터넷을 사용할 수 없도록 차단하거나 그에 준하는 보호조치를 적용하여
      개인정보유출에 대한 위험을 줄이고 있습니다.
    </li>
    <li>
      교육, 캠페인을 실시하고 있습니다. 개인정보보호를 위한 정기적인 교육, 캠페인을 통해 개인정보의 중요성에
      대해 강조하고 있습니다.
    </li>
    <li>
      국내외 인증기관으로부터 검증을 받고 있습니다. 정보보호 인증기관으로부터 매년 정보보호활동에 대한 인증을
      취득하고 있습니다. 개인정보 관련 문의 및 불만 처리 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고
      있습니다.
    </li>
    <li>
      개인정보보호책임자 이름: 이재인 직위: 개인정보보호실장 전화번호: 02-538-1500 이메일주소:
      privacy@nexon.co.kr 기타 개인정보침해에 대한 피해구제, 상담은 아래의 기관에 문의하실 수 있습니다.
    </li>
    <li>
      개인정보침해 신고센터 전화번호: 국번없이 118 웹사이트:
      <a href="https://privacy.kisa.or.kr">https://privacy.kisa.or.kr</a>
    </li>
    <li>
      개인정보 분쟁조정위원회 전화번호: 국번없이 1833-6972 웹사이트:
      <a href="https://www.kopico.go.kr">https://www.kopico.go.kr</a>
    </li>
    <li>
      대검찰청 사이버수사과 전화번호: 국번없이 1301 웹사이트:
      <a href="https://www.spo.go.kr">https://www.spo.go.kr</a>
    </li>
    <li>
      경찰청 사이버수사국 전화번호: 국번없이 182 웹사이트:
      <a href="https://ecrm.cyber.go.kr">https://ecrm.cyber.go.kr</a>
    </li>
  </ul>
  <h2 id="9-">9. 기타</h2>
  <p>
    개인정보처리방침 내용에 변경이 있을 경우 개정 최소 7일전에 알려드리나 임직원의 권리에 중대한 변경이 발생할
    때에는 최소 30일전에 알려드립니다.
  </p>
  <p>공고일: 2023년 9월 7일</p>
  <p>시행일: 2023년 9월 14일</p>
`;
export const nbox_privacy_content_en = html`
  <h1>Privacy Policy</h1>

  <p>
    Nexon Korea Corporation (hereinafter referred to as 'the Company') complies with personal information
    protection laws to protect the valuable personal information of its employees.
  </p>

  <p>
    Through this Privacy Policy, you can understand what personal information the Company collects, how it is
    used, to whom it is provided, when and how it is destroyed, etc. This Privacy Policy applies to employee
    services.
  </p>

  <p>If there are changes, we will inform you through the Privacy Policy.</p>

  <h2>1. Collection of Personal Information</h2>
  <p>
    The company collects only the necessary personal information for the use of the service. When collecting,
    we provide detailed information to the employee and obtain consent.
  </p>
  <ul>
    <li>What is essential information?</li>
    <p>Personal information collected to provide basic services</p>
    <li>What is optional information?</li>
    <p>Personal information collected to provide additional services outside of basic services</p>
  </ul>

  <p>Through web pages, documents, etc., we collect the following personal information:</p>

  <p><strong>For Human Resources Management</strong>:</p>
  <ul>
    <li>
      [Essential] Name, Photo, Resident Registration Number (for nationals), Alien Registration Number (for
      foreigners), Passport Number (for foreigners and business travelers), WEBO ID, WEBO Password, External
      Email Address, Mobile Phone Number, Address, Family Information, Career, Education, Bank Account Number,
      Military Service, Phone Number, Qualifications, Nationality, Salary, Veteran Information, Spouse's
      Public Service Status, English Name, Emergency Contacts, Chinese Character Name, Entry Date, Department,
      Employee Number, Internal Phone Number, Job Type, Position, Internal Email Address, Disability.
    </li>
    <li>[Optional] Vehicle Information (for parking applicants), Nexon Integrated ID.</li>
  </ul>

  <h2>2. Use of Personal Information</h2>
  <p>
    The Company uses personal information for the following purposes and obtains detailed consent from the
    employee if the purpose changes.
  </p>
  <ul>
    <li>To identify employees and verify their identity.</li>
    <li>
      For human resources management and operations such as entry and exit management, welfare service
      provision, and internal event management.
    </li>
    <li>To ensure a safe service environment.</li>
  </ul>

  <h2>3. Provision of Personal Information</h2>

  <p>
    To enable our employees to use services more conveniently and quickly, we have outsourced certain tasks to
    other companies.
  </p>
  <p>
    Our company manages and supervises the companies that have been entrusted with our tasks to ensure the
    safe processing of our employees' valuable personal information, and we prohibit them from re-outsourcing
    to other companies without our prior approval.
  </p>

  <p>We have outsourced the following tasks:</p>

  <p>
    Recipient: Nexon Space Co., Ltd. (Re-outsourcing: Proescom Co., Ltd., Dotori Picnic
    Sea/Moon/Star/Daechi/Gangnam/Jeju Kindergarten, SHP Corporation, Pulmuone Food & Culture)
    <br />
    Purpose: Operation of in-house childcare services, CCTV, access card management, fitness user management,
    food/beverage services, and usage history management.
  </p>

  <p>
    Recipient: Nexon Space Co., Ltd. (Re-outsourcing: Proescom Co., Ltd., Dotori Picnic
    Sea/Moon/Star/Daechi/Gangnam/Jeju Kindergarten, SHP Corporation, Pulmuone Food & Culture)<br />
    Purpose: Operation of in-house childcare services, CCTV, access card management, fitness user management,
    food/beverage services, and usage history management.
  </p>
  <p>
    Recipient: Bear Better Co., Ltd.<br />
    Purpose: Business card production.
  </p>
  <p>
    Recipient: Bear Better Co., Ltd., Bear Better Services Co., Ltd. (Re-outsourcing: Local partners)<br />
    Purpose: Support for family events.
  </p>
  <p>
    Recipient: Marsh Korea Insurance Brokers Ltd. (Re-outsourcing: DB Insurance Co., Ltd., ABL Life Insurance
    Co., Ltd., Hyundai Marine & Fire Insurance Co., Ltd.)<br />
    Purpose: Accident insurance support.
  </p>
  <p>
    Recipient: Samsung Life Insurance Co., Ltd., Mirae Asset Securities Co., Ltd., Hana Bank Co., Ltd.
    <br />
    Purpose: Retirement pension support.
  </p>
  <p>
    Recipient: GC Care Co., Ltd. (Re-outsourcing: CJ OliveNetworks), Samsung Seoul Hospital (Re-outsourcing:
    Transcosmos Korea)<br />
    Purpose: Provision of health screening services.
  </p>
  <p>
    Recipient: M Twellv Co., Ltd.<br />
    Purpose: Mobile gift voucher provision.
  </p>
  <p>
    Recipient: Part-Time Oppas<br />
    Purpose: Support for employee ID photo shoots.
  </p>
  <p>
    Recipient: Mind On Mind Psychological Counseling Institute, Easy & Wellness Co., Ltd.<br />
    Purpose: Operation of psychological counseling, reservation guidance.
  </p>
  <p>
    Recipient: Grounder Co., Ltd.<br />
    Purpose: Leadership coaching operation.
  </p>
  <p>
    Recipient: Telcoin Co., Ltd. (Re-outsourcing: KT Corporation), Taeyoung DS Co., Ltd.<br />
    Purpose: Delivery of prizes.
  </p>
  <p>
    Recipient: Payday Co., Ltd. (Re-outsourcing: Infobank Co., Ltd., Nexus Community Co., Ltd.)<br />
    Purpose: Support for year-end tax adjustment.
  </p>
  <p>
    Recipient: Happy Ending Co., Ltd. (Re-outsourcing: Local partners)<br />
    Purpose: Funeral service support.
  </p>
  <p>
    Recipient: Reebast Trading Co., Ltd.<br />
    Purpose: Production of employee gifts, handling of complaints.
  </p>
  <p>
    Recipient: NHN Payco Co., Ltd.<br />
    Purpose: Welfare points provision.
  </p>
  <p>
    Recipient: Korea Office Computer Co., Ltd.<br />
    Purpose: ASAP operation.
  </p>
  <p>
    Recipient: BeComBe Co., Ltd.<br />
    Purpose: Employee ID production.
  </p>
  <p>
    Recipient: Samsung C&T Corporation<br />
    Purpose: Operation of Everland welfare services.
  </p>
  <p>
    Recipient: WePeak Inc.<br />
    Purpose: Operation of Sports Monster welfare services.
  </p>
  <p>
    Recipient: Kukon Co., Ltd.<br />
    Purpose: Inquiry of employee depositors.
  </p>
  <p>
    Recipient: AngelPlus Co., Ltd.<br />
    Purpose: Provision of designated driver services.
  </p>

  <p>
    <strong>For smooth and stable HR services, we store the following personal information abroad:</strong>
  </p>

  <p>
    Data: In-house email address, name, department, mobile phone number, in-house phone number, photo.
    <br />Countries: Japan, USA, Taiwan, Thailand, Vietnam.Transfer Date and Method: Transmission via network
    at the point of AD usage. <br />Recipients: Nexon Japan, Nexon America, Nexon Taiwan, Nexon Thailand,
    Nexon Networks VINA, NEXON DEV Vietnam Limited Company. <br />Purpose: Provision of Microsoft 365
    groupware and Active Directory services. <br />Retention and Use Period: Until the end of the employment
    contract.
  </p>

  <p>
    Data: In-house email address, name.
    <br />Country: USA <br />Transfer Date and Method: Transmission via network at the point of course
    registration. <br />Recipient: iSprings Solutions, Inc. <br />Purpose: Use of in-house education system.
    <br />Retention and Use Period: Until the end of the education system usage period.
  </p>

  <p>
    This translation aims to preserve the original document's formal tone and detailed nature, ensuring
    clarity and thoroughness.
  </p>

  <p>
    The text you've provided seems to be a detailed list from a company's privacy policy or personal
    information handling policy, explaining how employee personal information is shared with third-party
    service providers for various operational purposes. Here’s a brief summary and analysis of the
    information: The company shares employees' personal data with different third-party service providers to
    enable various services. These services include but are not limited to:
  </p>

  <p>
    Childcare Services, Health and Fitness, Food Services, etc.: Information is shared with several entities
    like ㈜넥슨스페이스 and its subsequent service providers for purposes including operating childcare
    services, managing CCTV and access controls, providing fitness services, and managing food services.
  </p>

  <p>
    Various Employee Support Services: This includes sharing information with various companies for services
    such as business card creation, condolence support, accident insurance support, retirement pension
    support, health screening services, gift vouchers, employee ID creation, psychological counseling,
    leadership coaching, prize delivery, end-of-year settlement support, funeral services, employee gift
    creation, welfare points, and more.
  </p>

  <p>
    Information Storage Overseas: Employee personal information is stored overseas in countries like Japan,
    the USA, Taiwan, Thailand, and Vietnam for seamless integration with services like Microsoft 365 and
    Active Directory.
  </p>

  <p>
    Hotel and Resort Accommodations: Information shared with various resorts and hotels for booking and
    utilization of accommodation facilities.
  </p>

  <p>
    Collaboration and Communication: Data shared with various Nexon subsidiaries and related companies for
    business collaboration, information sharing, and providing services like Microsoft 365.
  </p>

  <p>Travel Insurance: Information provided to ㈜KB손해보험 for travel insurance enrollment.</p>

  <p>
    Travel Arrangements: Data shared with ㈜온라인투어 for arranging overseas business trips, including flight
    and accommodation bookings.
  </p>

  <p>
    External Parking Registration: Details shared with companies like ㈜엔에스쇼핑, ㈜리엔피엠씨, and
    ㈜하이파킹 for external parking lot access.
  </p>

  <p>
    Loan Assistance: Personal data provided to financial institutions like 서울보증보험㈜ and ㈜신한은행 for
    loan assistance.
  </p>

  <h2>4. Criteria for Additional Use or Provision of Personal Information</h2>
  <p>
    The company's judgment criteria for additional use or provision of personal information without user
    consent under related laws are:
  </p>
  <ul>
    <li>Whether it is related to the original collection purpose.</li>
    <li>
      Whether additional use or provision of personal information is predictable based on the circumstances of
      collection or processing practices.
    </li>
    <li>Whether it unfairly infringes on the user's interests.</li>
    <li>Whether necessary measures such as pseudonymization or encryption have been taken.</li>
  </ul>

  <h2>5. Destruction of Personal Information</h2>
  <p>
    The company destroys the information without delay once the purpose of personal information collection and
    use is achieved. However, exceptions are made if specified in related laws or with the consent of the
    employee.
  </p>

  <p>
    For example, records related to employee career certification are retained for 3 years under the Labor
    Standards Act, and records related to salary payment are retained for 5 years under the Income Tax Law and
    National Tax Basic Law.
  </p>

  <h2>6. Employee Rights and How to Exercise Them</h2>
  <p>
    The company takes active measures regarding employees' requests to access, receive, or amend their
    personal information.
  </p>
  <p>
    Employees can view or correct their personal information in 'WEBO>Human Resources>Personal Information.'
    They can also request access, provision, correction, or withdrawal of consent for their personal
    information by contacting the personal information protection officer or the responsible department via
    phone, email, etc.
  </p>

  <p>
    <strong
      >Details on the Installation, Operation, and Rejection of Automatic Personal Information Collection
      Devices</strong
    >
  </p>
  <p>
    The company uses cookies to provide a convenient website experience for its employees and acknowledges
    their right to refuse these cookies.
  </p>

  <p><strong>What are cookies?</strong></p>
  <p>Small text files sent from a web server to a web browser when you visit certain websites.</p>
  <p>
    To improve our services, we use cookies to understand employees' connection history and usage patterns.
  </p>
  <p>Employees can refuse cookie collection by changing the options in their web browsers.</p>

  <p>- Microsoft Edge: Go to Settings > Cookies and site permissions > Cookies and site data.</p>
  <p>- Chrome: Go to Settings > Privacy and security > Cookies and other site data.</p>
  <p>- NAVER Whale: Go to Settings > Privacy > Content settings > Cookies.</p>

  <h2>7. Our Commitment to Personal Information Protection</h2>
  <p>The company actively strives to protect the valuable personal information of its employees.</p>
  <ul>
    <li>We encrypt personal information.</li>
    <li>We continuously focus on technology for personal information protection.</li>
    <li>We keep the number of employees handling personal information to a minimum.</li>
    <li>We conduct training and campaigns.</li>
    <li>We are verified by domestic and international certification authorities.</li>
  </ul>

  <p>
    For inquiries, complaints, or any other concerns related to personal information, we have designated a
    personal information protection officer as below.
  </p>

  <p><strong>Personal Information Protection Officer</strong></p>
  <p>Name: Jae-In Lee</p>
  <p>Position: Head of Personal Information Protection</p>
  <p>Phone Number: 02-538-1500</p>
  <p>Email: <a target="_blank" href="mailto:privacy@nexon.co.kr">privacy@nexon.co.kr</a></p>

  <p>
    For remedies or consultations regarding personal information infringement, you can contact the following
    organizations:
  </p>

  <p><strong>Personal Information Infringement Report Center</strong></p>
  <p>Phone Number: Dial 118 (no area code required)</p>
  <p>Website: <a target="_blank" href="https://privacy.kisa.or.kr">https://privacy.kisa.or.kr</a></p>

  <p><strong>Personal Information Dispute Mediation Committee</strong></p>
  <p>Phone Number: Dial 1833-6972 (no area code required)</p>
  <p>Website: <a target="_blank" href="https://www.kopico.go.kr">https://www.kopico.go.kr</a></p>

  <p><strong>Supreme Prosecutors' Office Cyber Investigation Department</strong></p>
  <p>Phone Number: Dial 1301 (no area code required)</p>
  <p>Website: <a target="_blank" href="https://www.spo.go.kr">https://www.spo.go.kr</a></p>

  <p><strong>National Police Agency Cyber Investigation Department</strong></p>
  <p>Phone Number: Dial 182 (no area code required)</p>
  <p>Website: <a target="_blank" href="https://ecrm.cyber.go.kr">https://ecrm.cyber.go.kr</a></p>

  <h2>8. Miscellaneous</h2>
  <p>
    If there are changes to the personal information processing policy, we will notify you at least 7 days
    before the revisions. However, if there are significant changes affecting employees' rights, we will
    inform you at least 30 days in advance.
  </p>

  <p><strong>Announcement Date:</strong> September 7, 2023</p>
  <p><strong>Effective Date:</strong> September 14, 2023</p>
`;
export const nbox_privacy_content_jp = html`
  <h1>Privacy Policy</h1>

  <h2>Nexon Korea Corporation Privacy Policy</h2>
  <p>
    Nexon Korea Corporation (hereinafter referred to as 'the Company') complies with personal information
    protection laws to protect the valuable personal information of its employees.
  </p>

  <p>
    Through this Privacy Policy, you can understand what personal information the Company collects, how it is
    used, to whom it is provided, when and how it is destroyed, etc. This Privacy Policy applies to employee
    services.
  </p>

  <p>If there are changes, we will inform you through the Privacy Policy.</p>

  <h2>1. Collection of Personal Information</h2>
  <p>
    The company collects only the necessary personal information for the use of the service. When collecting,
    we provide detailed information to the employee and obtain consent.
  </p>
  <ul>
    <li>What is essential information?</li>
    <p>Personal information collected to provide basic services</p>
    <li>What is optional information?</li>
    <p>Personal information collected to provide additional services outside of basic services</p>
  </ul>

  <p>Through web pages, documents, etc., we collect the following personal information:</p>

  <p><strong>For Human Resources Management</strong>:</p>
  <ul>
    <li>
      [Essential] Name, Photo, Resident Registration Number (for nationals), Alien Registration Number (for
      foreigners), Passport Number (for foreigners and business travelers), WEBO ID, WEBO Password, External
      Email Address, Mobile Phone Number, Address, Family Information, Career, Education, Bank Account Number,
      Military Service, Phone Number, Qualifications, Nationality, Salary, Veteran Information, Spouse's
      Public Service Status, English Name, Emergency Contacts, Chinese Character Name, Entry Date, Department,
      Employee Number, Internal Phone Number, Job Type, Position, Internal Email Address, Disability.
    </li>
    <li>[Optional] Vehicle Information (for parking applicants), Nexon Integrated ID.</li>
  </ul>

  <h2>2. Use of Personal Information</h2>
  <p>
    The Company uses personal information for the following purposes and obtains detailed consent from the
    employee if the purpose changes.
  </p>
  <ul>
    <li>To identify employees and verify their identity.</li>
    <li>
      For human resources management and operations such as entry and exit management, welfare service
      provision, and internal event management.
    </li>
    <li>To ensure a safe service environment.</li>
  </ul>

  <h2>3. Provision of Personal Information</h2>

  <p>
    To enable our employees to use services more conveniently and quickly, we have outsourced certain tasks to
    other companies.
  </p>
  <p>
    Our company manages and supervises the companies that have been entrusted with our tasks to ensure the
    safe processing of our employees' valuable personal information, and we prohibit them from re-outsourcing
    to other companies without our prior approval.
  </p>

  <p>We have outsourced the following tasks:</p>

  <p>
    Recipient: Nexon Space Co., Ltd. (Re-outsourcing: Proescom Co., Ltd., Dotori Picnic
    Sea/Moon/Star/Daechi/Gangnam/Jeju Kindergarten, SHP Corporation, Pulmuone Food & Culture)
    <br />
    Purpose: Operation of in-house childcare services, CCTV, access card management, fitness user management,
    food/beverage services, and usage history management.
  </p>

  <p>
    Recipient: Nexon Space Co., Ltd. (Re-outsourcing: Proescom Co., Ltd., Dotori Picnic
    Sea/Moon/Star/Daechi/Gangnam/Jeju Kindergarten, SHP Corporation, Pulmuone Food & Culture)<br />
    Purpose: Operation of in-house childcare services, CCTV, access card management, fitness user management,
    food/beverage services, and usage history management.
  </p>
  <p>
    Recipient: Bear Better Co., Ltd.<br />
    Purpose: Business card production.
  </p>
  <p>
    Recipient: Bear Better Co., Ltd., Bear Better Services Co., Ltd. (Re-outsourcing: Local partners)<br />
    Purpose: Support for family events.
  </p>
  <p>
    Recipient: Marsh Korea Insurance Brokers Ltd. (Re-outsourcing: DB Insurance Co., Ltd., ABL Life Insurance
    Co., Ltd., Hyundai Marine & Fire Insurance Co., Ltd.)<br />
    Purpose: Accident insurance support.
  </p>
  <p>
    Recipient: Samsung Life Insurance Co., Ltd., Mirae Asset Securities Co., Ltd., Hana Bank Co., Ltd.
    <br />
    Purpose: Retirement pension support.
  </p>
  <p>
    Recipient: GC Care Co., Ltd. (Re-outsourcing: CJ OliveNetworks), Samsung Seoul Hospital (Re-outsourcing:
    Transcosmos Korea)<br />
    Purpose: Provision of health screening services.
  </p>
  <p>
    Recipient: M Twellv Co., Ltd.<br />
    Purpose: Mobile gift voucher provision.
  </p>
  <p>
    Recipient: Part-Time Oppas<br />
    Purpose: Support for employee ID photo shoots.
  </p>
  <p>
    Recipient: Mind On Mind Psychological Counseling Institute, Easy & Wellness Co., Ltd.<br />
    Purpose: Operation of psychological counseling, reservation guidance.
  </p>
  <p>
    Recipient: Grounder Co., Ltd.<br />
    Purpose: Leadership coaching operation.
  </p>
  <p>
    Recipient: Telcoin Co., Ltd. (Re-outsourcing: KT Corporation), Taeyoung DS Co., Ltd.<br />
    Purpose: Delivery of prizes.
  </p>
  <p>
    Recipient: Payday Co., Ltd. (Re-outsourcing: Infobank Co., Ltd., Nexus Community Co., Ltd.)<br />
    Purpose: Support for year-end tax adjustment.
  </p>
  <p>
    Recipient: Happy Ending Co., Ltd. (Re-outsourcing: Local partners)<br />
    Purpose: Funeral service support.
  </p>
  <p>
    Recipient: Reebast Trading Co., Ltd.<br />
    Purpose: Production of employee gifts, handling of complaints.
  </p>
  <p>
    Recipient: NHN Payco Co., Ltd.<br />
    Purpose: Welfare points provision.
  </p>
  <p>
    Recipient: Korea Office Computer Co., Ltd.<br />
    Purpose: ASAP operation.
  </p>
  <p>
    Recipient: BeComBe Co., Ltd.<br />
    Purpose: Employee ID production.
  </p>
  <p>
    Recipient: Samsung C&T Corporation<br />
    Purpose: Operation of Everland welfare services.
  </p>
  <p>
    Recipient: WePeak Inc.<br />
    Purpose: Operation of Sports Monster welfare services.
  </p>
  <p>
    Recipient: Kukon Co., Ltd.<br />
    Purpose: Inquiry of employee depositors.
  </p>
  <p>
    Recipient: AngelPlus Co., Ltd.<br />
    Purpose: Provision of designated driver services.
  </p>

  <p>
    <strong>For smooth and stable HR services, we store the following personal information abroad:</strong>
  </p>

  <p>
    Data: In-house email address, name, department, mobile phone number, in-house phone number, photo.
    <br />Countries: Japan, USA, Taiwan, Thailand, Vietnam.Transfer Date and Method: Transmission via network
    at the point of AD usage. <br />Recipients: Nexon Japan, Nexon America, Nexon Taiwan, Nexon Thailand,
    Nexon Networks VINA, NEXON DEV Vietnam Limited Company. <br />Purpose: Provision of Microsoft 365
    groupware and Active Directory services. <br />Retention and Use Period: Until the end of the employment
    contract.
  </p>

  <p>
    Data: In-house email address, name.
    <br />Country: USA <br />Transfer Date and Method: Transmission via network at the point of course
    registration. <br />Recipient: iSprings Solutions, Inc. <br />Purpose: Use of in-house education system.
    <br />Retention and Use Period: Until the end of the education system usage period.
  </p>

  <p>
    This translation aims to preserve the original document's formal tone and detailed nature, ensuring
    clarity and thoroughness.
  </p>

  <p>
    The text you've provided seems to be a detailed list from a company's privacy policy or personal
    information handling policy, explaining how employee personal information is shared with third-party
    service providers for various operational purposes. Here’s a brief summary and analysis of the
    information: The company shares employees' personal data with different third-party service providers to
    enable various services. These services include but are not limited to:
  </p>

  <p>
    Childcare Services, Health and Fitness, Food Services, etc.: Information is shared with several entities
    like ㈜넥슨스페이스 and its subsequent service providers for purposes including operating childcare
    services, managing CCTV and access controls, providing fitness services, and managing food services.
  </p>

  <p>
    Various Employee Support Services: This includes sharing information with various companies for services
    such as business card creation, condolence support, accident insurance support, retirement pension
    support, health screening services, gift vouchers, employee ID creation, psychological counseling,
    leadership coaching, prize delivery, end-of-year settlement support, funeral services, employee gift
    creation, welfare points, and more.
  </p>

  <p>
    Information Storage Overseas: Employee personal information is stored overseas in countries like Japan,
    the USA, Taiwan, Thailand, and Vietnam for seamless integration with services like Microsoft 365 and
    Active Directory.
  </p>

  <p>
    Hotel and Resort Accommodations: Information shared with various resorts and hotels for booking and
    utilization of accommodation facilities.
  </p>

  <p>
    Collaboration and Communication: Data shared with various Nexon subsidiaries and related companies for
    business collaboration, information sharing, and providing services like Microsoft 365.
  </p>

  <p>Travel Insurance: Information provided to ㈜KB손해보험 for travel insurance enrollment.</p>

  <p>
    Travel Arrangements: Data shared with ㈜온라인투어 for arranging overseas business trips, including flight
    and accommodation bookings.
  </p>

  <p>
    External Parking Registration: Details shared with companies like ㈜엔에스쇼핑, ㈜리엔피엠씨, and
    ㈜하이파킹 for external parking lot access.
  </p>

  <p>
    Loan Assistance: Personal data provided to financial institutions like 서울보증보험㈜ and ㈜신한은행 for
    loan assistance.
  </p>

  <h2>4. Criteria for Additional Use or Provision of Personal Information</h2>
  <p>
    The company's judgment criteria for additional use or provision of personal information without user
    consent under related laws are:
  </p>
  <ul>
    <li>Whether it is related to the original collection purpose.</li>
    <li>
      Whether additional use or provision of personal information is predictable based on the circumstances of
      collection or processing practices.
    </li>
    <li>Whether it unfairly infringes on the user's interests.</li>
    <li>Whether necessary measures such as pseudonymization or encryption have been taken.</li>
  </ul>

  <h2>5. Destruction of Personal Information</h2>
  <p>
    The company destroys the information without delay once the purpose of personal information collection and
    use is achieved. However, exceptions are made if specified in related laws or with the consent of the
    employee.
  </p>

  <p>
    For example, records related to employee career certification are retained for 3 years under the Labor
    Standards Act, and records related to salary payment are retained for 5 years under the Income Tax Law and
    National Tax Basic Law.
  </p>

  <h2>6. Employee Rights and How to Exercise Them</h2>
  <p>
    The company takes active measures regarding employees' requests to access, receive, or amend their
    personal information.
  </p>
  <p>
    Employees can view or correct their personal information in 'WEBO>Human Resources>Personal Information.'
    They can also request access, provision, correction, or withdrawal of consent for their personal
    information by contacting the personal information protection officer or the responsible department via
    phone, email, etc.
  </p>

  <p>
    <strong
      >Details on the Installation, Operation, and Rejection of Automatic Personal Information Collection
      Devices</strong
    >
  </p>
  <p>
    The company uses cookies to provide a convenient website experience for its employees and acknowledges
    their right to refuse these cookies.
  </p>

  <p><strong>What are cookies?</strong></p>
  <p>Small text files sent from a web server to a web browser when you visit certain websites.</p>
  <p>
    To improve our services, we use cookies to understand employees' connection history and usage patterns.
  </p>
  <p>Employees can refuse cookie collection by changing the options in their web browsers.</p>

  <p>- Microsoft Edge: Go to Settings > Cookies and site permissions > Cookies and site data.</p>
  <p>- Chrome: Go to Settings > Privacy and security > Cookies and other site data.</p>
  <p>- NAVER Whale: Go to Settings > Privacy > Content settings > Cookies.</p>

  <h2>7. Our Commitment to Personal Information Protection</h2>
  <p>The company actively strives to protect the valuable personal information of its employees.</p>
  <ul>
    <li>We encrypt personal information.</li>
    <li>We continuously focus on technology for personal information protection.</li>
    <li>We keep the number of employees handling personal information to a minimum.</li>
    <li>We conduct training and campaigns.</li>
    <li>We are verified by domestic and international certification authorities.</li>
  </ul>

  <p>
    For inquiries, complaints, or any other concerns related to personal information, we have designated a
    personal information protection officer as below.
  </p>

  <p><strong>Personal Information Protection Officer</strong></p>
  <p>Name: Jae-In Lee</p>
  <p>Position: Head of Personal Information Protection</p>
  <p>Phone Number: 02-538-1500</p>
  <p>Email: <a target="_blank" href="mailto:privacy@nexon.co.kr">privacy@nexon.co.kr</a></p>

  <p>
    For remedies or consultations regarding personal information infringement, you can contact the following
    organizations:
  </p>

  <p><strong>Personal Information Infringement Report Center</strong></p>
  <p>Phone Number: Dial 118 (no area code required)</p>
  <p>Website: <a target="_blank" href="https://privacy.kisa.or.kr">https://privacy.kisa.or.kr</a></p>

  <p><strong>Personal Information Dispute Mediation Committee</strong></p>
  <p>Phone Number: Dial 1833-6972 (no area code required)</p>
  <p>Website: <a target="_blank" href="https://www.kopico.go.kr">https://www.kopico.go.kr</a></p>

  <p><strong>Supreme Prosecutors' Office Cyber Investigation Department</strong></p>
  <p>Phone Number: Dial 1301 (no area code required)</p>
  <p>Website: <a target="_blank" href="https://www.spo.go.kr">https://www.spo.go.kr</a></p>

  <p><strong>National Police Agency Cyber Investigation Department</strong></p>
  <p>Phone Number: Dial 182 (no area code required)</p>
  <p>Website: <a target="_blank" href="https://ecrm.cyber.go.kr">https://ecrm.cyber.go.kr</a></p>

  <h2>8. Miscellaneous</h2>
  <p>
    If there are changes to the personal information processing policy, we will notify you at least 7 days
    before the revisions. However, if there are significant changes affecting employees' rights, we will
    inform you at least 30 days in advance.
  </p>

  <p><strong>Announcement Date:</strong> September 7, 2023</p>
  <p><strong>Effective Date:</strong> September 14, 2023</p>
`;

export const nbox_privacy_content = {
  kr: nbox_privacy_content_kr,
  en: nbox_privacy_content_en,
  jp: nbox_privacy_content_jp,
};

export const nbox_privacy = (lang) => html`
  <div class="nbox-privacy-page">${nbox_privacy_content?.[lang || ET.lang]}</div>
  <br />
`;

export const nbox_privacy_popup = (lang) => html`
  <div class="terms_body">
    <div class="body">
      <div class="nbox-privacy-page">${nbox_privacy_content?.[lang || ET.lang]}</div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;
