export const CREW_STORE_MENU_TYPE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PROFIT: 'profit',
  DISAPPROVAL: 'disapproval',
  UNREGISTERED: 'unregistered',
  REJECTED: 'rejected',
  INVITED: 'invited',
  PB: 'pb',
};
