export const color_pallete = {
  black: '#141414',
  purple: '#7A44A0',
  blue: '#1C75FF',
  green: '#009444',
  // yellow: '#FFD802',
  // orange: '#FF7200',
  red: '#D92A0F',
  // pink: '#EE19A4',
  // white: '#FFFFFF',
  brown: '#704C00',
  burgundy: '#990000',
};

export const color_pallete_list = [
  color_pallete.black,
  color_pallete.blue,
  color_pallete.purple,
  color_pallete.brown,
  color_pallete.green,
  color_pallete.red,
  color_pallete.burgundy,
];

export const black_pallete_list = [
  '#111111',
  '#4E4E4E',
  '#828282',
  '#A2A2A2',
  '#DDDDDD',
  '#F8F8F8',
  '#FFFFFF',
];
