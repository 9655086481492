import { curry3, mapL, sel } from 'fxjs/es';
import { CREW_DOMAIN_NAME, CREW_SELLER_SHARE_TYPE } from './constant.js';
import { checkActions } from '../../Authority/S/util.js';
import { SERVICE } from '../../Authority/S/constant.js';

export const isCrew = (user) => !!sel('_.crew.id', user);

export const isSeller = (user, { store_name, store_id } = {}) => {
  const store = sel('_.store', user);
  return store && (store.id == store_id || store.domain_name == store_name) && store.user_id == user.id;
};

export const isAdmin = (user) =>
  checkActions({
    service: SERVICE.ADMIN,
    pass_no_service: false,
  })({ user });

export const getCrewFromUser = sel('_.crew');
export const isAllCrewManager = (user) => sel('_.crew.domain_name', user) == CREW_DOMAIN_NAME.ALL;
export const isAllCrew = (crew) => crew.domain_name == CREW_DOMAIN_NAME.ALL;
export const isAllCrewById = (crew_id) => crew_id == 1;
export const isMarppleCrew = (crew) => crew.domain_name == CREW_DOMAIN_NAME.MARPPLE;

export const mergeL = curry3((f_merge, selector, indexed, iter) =>
  mapL((a) => f_merge(a, indexed[selector(a)]), iter),
);

export const computeCrewProductProfit = (profit, seller_profit_share, customer_price, seller_share_type) => {
  let seller_profit =
    seller_share_type == CREW_SELLER_SHARE_TYPE.CUSTOMER_PRICE
      ? customer_price * seller_profit_share
      : profit * seller_profit_share;

  if (profit == 0 && seller_share_type == CREW_SELLER_SHARE_TYPE.CUSTOMER_PRICE) {
    seller_profit = 0;
  }

  const company_profit = profit - seller_profit;
  return {
    seller_profit: Math.ceil(seller_profit),
    company_profit: Math.floor(company_profit),
  };
};
