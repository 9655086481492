import StickySidebar from 'sticky-sidebar';

export const useStickySidebar = () => {
  let stickySidebarInstance: typeof StickySidebar | null;

  const init = () => {
    if (!stickySidebarInstance) {
      stickySidebarInstance = new StickySidebar('#creator_settings_menu', {
        containerSelector: '#creator_settings',
        innerWrapperSelector: '.inner_menu_wrapper',
        resizeSensor: true,
        bottomSpacing: 20,
      });
    }
  };

  const destroy = () => {
    if (stickySidebarInstance) {
      const container_el: HTMLElement | null = stickySidebarInstance.container;
      if (container_el) {
        container_el.style.position = '';
      }
      stickySidebarInstance.destroy();
      stickySidebarInstance = null;
    }
  };

  return {
    init,
    destroy,
  };
};
