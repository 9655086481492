import { html, View } from 'rune-ts';
import { ProductCard, ProductLikeToggleEvent } from '../ProductCard/ProductCard';
import { htmlIf } from '../../../../shared/util';
import klass from './ProductCardList.module.scss';
import {
  ProductCardData,
  ProductCardListData,
  ProductCardOption,
} from '../../../../features/ProductList/type';
import { DefaultProductListEmptyScreen } from './ProductCardListEmptyScreen';

export type ProductCardListOption = ProductCardOption & {
  container_klass?: string;
  prevent_default_style?: boolean;
};

type EmptyViewOption = { empty_view?: View; empty_klass?: string };

export class ProductCardList extends View<ProductCardListData> {
  emptyListView: View;

  constructor(
    data: ProductCardListData,
    private option: ProductCardListOption,
    empty_option?: EmptyViewOption,
  ) {
    super(data, option);
    if (empty_option?.empty_view) {
      this.emptyListView = empty_option.empty_view;
    } else {
      this.emptyListView = new DefaultProductListEmptyScreen({ klass: empty_option?.empty_klass });
    }
  }

  override onRender() {
    this.delegate(ProductLikeToggleEvent, ProductCard, async (_, view) => {
      await view.toggleLikeButton();
    });
  }

  override template() {
    const { products } = this.data;
    const is_empty = !products.length;

    return html`
      <div
        class="${htmlIf(klass.product_list, !this.option.prevent_default_style)} ${this.option
          .container_klass ?? ''} ${htmlIf(klass.empty_list, is_empty)}"
      >
        ${is_empty
          ? this.emptyListView
          : products.map((product) => {
              return new ProductCard(product, this.option);
            })}
      </div>
    `;
  }

  setProductsData(products: ProductCardData[]) {
    this.data.products = products;
    return this;
  }
}
