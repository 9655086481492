export const makePlusUrl = (domain_name?: string, path = '') => {
  if (!domain_name) {
    return path;
  }

  const FALLBACK_SERVICE_URL = `http://dev.localhost:11001`;
  const url = new URL(`${process.env.SERVICE_URL || FALLBACK_SERVICE_URL}${path}`);
  url.hostname = `${domain_name}.${url.hostname}`;

  return url.toString();
};

export const makeMainUrl = (path = '') => {
  return `${process.env.SERVICE_URL}${path}`;
};

/**
 * 마플샵 도메인 검사 정규식
 */
export const marppleShopUrlRegex = new RegExp(
  `^${(process.env.SERVICE_URL || 'http://dev.localhost:11001').replace(
    /[-/\\^$*+?.()|[\]{}]/g,
    '\\$&',
  )}/.+/products/(\\d+)$`,
);
