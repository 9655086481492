import { $attr, $contains, $delegate, $findAll, $on, $setAttr } from 'fxdom/es';
import {
  find,
  ifElse,
  identity,
  difference,
  each,
  go,
  head,
  isArray,
  map,
  object,
  pluck,
  reject,
} from 'fxjs/es';
import './SlideSelector.styl';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

const hook_map = new WeakMap();

const minMaxResidue = (min, v, max) => {
  // prettier-ignore
  return v > max ? [max, v - max]
    : v < min ? [min, v - min]
      : [v];
};

const makeComponentOptions = (props, el) => {
  return go(
    props,
    map((prop) => {
      const attr_name = `data-${prop.name}`;
      const has_attr = el.hasAttribute(attr_name);
      const value = !prop.value
        ? has_attr
        : has_attr
        ? go(el, $attr(attr_name), prop.hook || identity)
        : prop.initial;
      return [prop.name, value];
    }),
    object,
  );
};

const component_options = [
  {
    name: 'sensitivity',
    value: true,
  },
  {
    name: 'multiple',
    value: false,
  },
  {
    name: 'min',
    value: true,
    initial: 0,
  },
  {
    name: 'max',
    value: true,
    initial: Infinity,
  },
  {
    name: 'theme-color',
    value: true,
  },
  {
    name: 'theme-targets',
    value: true,
    hook: (targets_string) => (targets_string ? targets_string.split(',') : []),
  },
];

const activateItem = (host_el, item_el) => {
  $setAttr({ 'data-selected': true }, item_el);

  const theme_color = host_el.options['theme-color'];
  if (theme_color) {
    go(
      host_el.options['theme-targets'],
      map(UtilS.toCamel),
      map((css) => {
        item_el.style[css] = theme_color;
      }),
    );
  }

  host_el.active_els = !host_el.options.multiple ? [item_el] : [...host_el.active_els, item_el];
};

const deactivateItem = (host_el, item_el) => {
  $setAttr({ 'data-selected': false }, item_el);

  const theme_color = host_el.options['theme-color'];
  if (theme_color) {
    go(
      host_el.options['theme-targets'],
      map((css) => {
        item_el.style.removeProperty(css);
      }),
    );
  }

  host_el.active_els = !host_el.options.multiple ? [] : reject((el) => el == item_el, host_el.active_els);
};

const calcRelativeMove = (x, overflow, longest_overflow) => x * (overflow / longest_overflow);
const inverseCalcRelativeMove = (x, overflow, longest_overflow) => x * (longest_overflow / overflow);

const moveRows = (x, host_el) => {
  const { longest_overflow, slide_wrapper_el, rows, no_overflow } = host_el;

  go(
    rows,
    map((row) => {
      const [moved, residual] = minMaxResidue(
        -row.overflow_x,
        calcRelativeMove(x, row.overflow_x, longest_overflow),
        0,
      );
      row.el.style.transform = `translateX(${moved}px)`;
      // TODO: 나중에 어디까지 움직였는지도 계산해서 넣어주기

      const hooks = hook_map.get(host_el);
      if (hooks?.onMove) {
        hooks.onMove(row.el);
      }

      return residual;
    }),
    head,
    (residual) => (no_overflow ? '' : (slide_wrapper_el.style.transform = `translateX(${residual}px)`)),
  );
};

export const init = (_host_el, { hooks, right_padding = 0 } = {}) => {
  const host_els = _host_el
    ? [_host_el]
    : document.querySelectorAll('.slide-selector:not([data-selector-lazy])');

  go(
    host_els,
    reject($attr('data-selector-init')),
    each((host_el) => {
      const slide_wrapper_el = host_el.querySelector('.slide-selector__wrapper');

      host_el.options = makeComponentOptions(component_options, host_el);
      host_el.value = host_el.options.multiple ? [] : undefined;
      host_el.on_touch = false;
      host_el.slide_wrapper_el = slide_wrapper_el;
      host_el.right_padding = right_padding;

      hook_map.set(host_el, hooks);

      const container_width = slide_wrapper_el.clientWidth;
      const rows = go(
        [...slide_wrapper_el.querySelectorAll('.slide-selector__row')],
        map((row_el) => ({
          el: row_el,
          overflow_x: Math.max(0, right_padding + row_el.scrollWidth - container_width), // row 의 이동 가능 공간
          moved_x: 0,
        })),
      );

      host_el.rows = rows;

      host_el.longest_overflow = Math.max(...pluck('overflow_x', rows)) + right_padding; // right padding 이 scroll_width 에서 반영되지 않음

      let lastUpdatedTime = 0;

      if (host_el.longest_overflow) {
        host_el.impetus = new Impetus({
          source: slide_wrapper_el,
          update: (x) => {
            lastUpdatedTime = new Date();
            host_el.on_touch = true;
            moveRows(x, host_el);
          },
          multiplier: host_el.options.sensitivity,
          boundX: [-host_el.longest_overflow, 0],
        });
      }

      // 이벤트 전파
      host_el.active_els = [...$findAll('.slide-selector__item[data-selected="true"]', host_el)];

      // 시작 등록
      go(
        host_el,
        $on('touchend', () => {
          // touchend 가 click 이벤트보다 우선순위가 높아서 억지로 미룸
          setTimeout(() => (host_el.on_touch = false));
        }),
        $on('mouseup', () => {
          setTimeout(() => (host_el.on_touch = false));
        }),
      );

      $delegate('click', '.slide-selector__item', async (e) => {
        if (new Date() - lastUpdatedTime < 50 || host_el.on_touch) return; // 슬라이드에는 클릭 방지
        const { min, max } = host_el.options;
        const target_el = e.currentTarget;

        const activate = !host_el.active_els.includes(target_el);

        if (!host_el.options.multiple && activate) {
          const prev_el = head(host_el.active_els);
          if (prev_el && target_el != prev_el) deactivateItem(host_el, prev_el);
        }

        const count = host_el.active_els.length;

        if (activate && count + 1 > max) return;
        if (!activate && count - 1 < min) return;

        (!host_el.active_els.includes(target_el) ? activateItem : deactivateItem)(host_el, target_el);

        dispatchChangeEvent(host_el);
      })(slide_wrapper_el);
    }),
    each((el) => {
      $setAttr({ 'data-selector-init': true }, el);
    }),
  );
};

const dispatchChangeEvent = (host_el) => {
  const change_event = new CustomEvent('change', {
    bubbles: true,
    detail: {
      value: getValue(host_el),
    },
  });

  host_el.dispatchEvent(change_event);
};

export const cleanActiveEls = (host_el) => {
  host_el.active_els = [];
};

export const getValue = (host_el) => {
  return go(
    host_el.active_els,
    map($attr('data-value')),
    ifElse(() => host_el.options.multiple, identity, head),
  );
};

export const setValue = (host_el, v) => {
  const makeDataSelector = (value) => `.slide-selector__item[data-value="${value}"]`;
  const makeSelector = isArray(v) ? map(makeDataSelector, v).join(',') : makeDataSelector(v);
  const will_active_els = v ? $findAll(makeSelector, host_el) : [];

  const will_deactive_els = difference(will_active_els, host_el.active_els);
  each((item_el) => deactivateItem(host_el, item_el), will_deactive_els);
  each((item_el) => activateItem(host_el, item_el), difference(host_el.active_els, will_active_els));

  dispatchChangeEvent(host_el);

  return v;
};

export const focusSelected = (host_el) => {
  if (!host_el.active_els.length) return;
  const selected_el = host_el.active_els[0];
  const row = find((r) => $contains(selected_el, r.el), host_el.rows);
  const { right: container_right } = host_el.getBoundingClientRect();
  const { right: selected_right } = selected_el.getBoundingClientRect();
  const extra_space = 30;

  if (container_right - selected_right > 0) return;

  const will_move = inverseCalcRelativeMove(
    container_right - selected_right - extra_space,
    row.overflow_x,
    host_el.longest_overflow,
  );

  if (!host_el.impetus) return;
  host_el.impetus.setValues(will_move, 0);

  moveRows(will_move, { ...host_el, no_overflow: true });
};

export const getLongestOverflow = (host_el) => {
  return host_el.longest_overflow;
};
