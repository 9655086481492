import { UtilF } from '../../../../../../modules/Util/F/Function/module/UtilF.js';

window._en = ENV.lang == 'kr' ? '' : `_${ENV.lang}`;
window._collabo = collabo_type == '' ? '' : `_${collabo_type}`;

let _T = null;

const _TPromise = new Promise(async function (resolve) {
  const { translator } = await import('../../../../sh/app/all/i18next.config.js');
  translator.changeLanguage(ENV.lang).then(function (T) {
    _T = T;
    window.lang = translator.language;
    resolve();
  });
});
window.T = function (a) {
  const args = arguments;
  if (_T) return _T.apply(null, args);
  return _TPromise.then(function () {
    return _T.apply(null, args);
  });
};
window.T.lang = ENV.lang;

let _TT = null;
const _TTPromise = new Promise(async function (resolve) {
  const omp_translator = (
    await import('../../../../../../modules/OMP/Core/Translation/omp_i18next.config.js')
  ).default;
  omp_translator.changeLanguage(ENV.lang).then(function (TT) {
    _TT = TT;
    window.lang = omp_translator.language;
    resolve();
  });
});
window.TT = function (a) {
  const args = arguments;
  if (_TT) return _TT.apply(null, args);
  return _TTPromise.then(function () {
    return _TT.apply(null, args);
  });
};
window.TT.lang = ENV.lang;

let _ET = null;
const _ETPromise = new Promise(async function (resolve) {
  const mpe_translator = (await import('../../../../../../modules/MShop/Translation/i18n.config.js')).default;
  mpe_translator.changeLanguage(ENV.lang).then(function (ET) {
    _ET = ET;
    window.lang = mpe_translator.language;
    resolve();
  });
});
window.ET = function (a) {
  const args = arguments;
  if (_ET) return _ET.apply(null, args);
  return _ETPromise.then(function () {
    return _ET.apply(null, args);
  });
};
window.ET.lang = ENV.lang;

if (!window.box) {
  window.box = _p.box(ENV.box);
  delete ENV.box;
}
const marpple_blazy = new Blazy({
  selector: '.don_lazy',
  offset: $.innerHeight(window),
});
G.don_lazy = function () {
  marpple_blazy.revalidate();
};

UtilF.initBackForward(() => {
  G.don_lazy();
});

const lang_regex = new RegExp(
  '^javascript:|^/$|^http:|^https:|^mailto:|^tel:|^#|^//|' +
    _p
      .map(window.box.sel('langs'), function (lang) {
        return '^/' + lang;
      })
      .join('|'),
);
G.add_lang = function (el) {
  _p.each($.find(el, 'a[href]:not(.external-url)'), function (anchor) {
    const href = anchor.getAttribute('href');
    if (!lang_regex.test(href)) anchor.setAttribute('href', '/' + ENV.lang + href);
  });
};
G.add_lang($('body'));

window.fbAsyncInit = function () {
  FB.init({
    appId: ENV.appId,
    xfbml: true,
    version: 'v8.0',
  });
  FB.AppEvents.logPageView();
};

(function (d, s, id) {
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  const js = d.createElement(s);
  js.id = id;
  js.src = 'https://connect.facebook.net/en_US/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'facebook-jssdk');
