import { $val } from 'fxdom/es';
import { go, mapObject, object, pipe } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const strValTrim = pipe($val, UtilS.trim);

export const strValTrimParseInt = pipe(strValTrim, parseInt);
export const strValTrimParseFloat = pipe(strValTrim, parseFloat);

/**
 * form_el 또는 FormData를 object로 변환
 * @param {HTMLFormElement|FormData} form
 * @return {object}
 */
export const formDataToObject = (form) => {
  let form_data;
  if (form instanceof HTMLFormElement) {
    form_data = new FormData(form);
  } else if (form instanceof FormData) {
    form_data = form;
  } else {
    throw new Error('form is not valid.');
  }

  return go(
    form_data.entries(),
    object,
    mapObject((elem) => {
      if (typeof elem === 'string') return elem.trim();
      return elem;
    }),
  );
};

export const formDataToObjectIncludeDisabled = (form_el) => {
  if (!(form_el instanceof HTMLFormElement)) {
    throw new Error('form_el is not HTMLFormElement.');
  }

  const form_data = new FormData(form_el);
  form_el.querySelectorAll('[disabled]').forEach((disabled_el) => {
    form_data.append(disabled_el.name, disabled_el.value);
  });

  return formDataToObject(form_data);
};

/**
 * form의 입력 변화를 감지해서 변경이 있으면 페이지 이탈 전 confirm 창을 띄워줌
 * @param {HTMLFormElement} form_el
 * @param {function(HTMLFormElement): T} formParser
 * @param {function(T, T): boolean?} diffChecker
 *
 * @example
 * const formWatcher = initFormChangeDetector(my_form_el, my_form_parser);
 */
export const initFormChangeWatcher = (form_el, formParser, diffChecker) => {
  let origin_form_data = formParser(form_el);

  const showConfirmIfFormChanged = (event) => {
    const current_form_data = formParser(form_el);

    let is_diff;
    if (diffChecker) {
      is_diff = diffChecker(origin_form_data, current_form_data);
    } else {
      is_diff = JSON.stringify(origin_form_data) !== JSON.stringify(current_form_data);
    }

    if (is_diff) {
      event.preventDefault();
    }
  };

  // init
  window.addEventListener('beforeunload', showConfirmIfFormChanged);

  return {
    update() {
      origin_form_data = formParser(form_el);
    },
    clear() {
      window.removeEventListener('beforeunload', showConfirmIfFormChanged);
    },
  };
};
