import { $css } from 'fxdom/es';
import { go, filter, head, map, curry, split, juxt } from 'fxjs/es';

const parseValue = (transform, match) => ((match = /.+\((.+)\)/.exec(transform) || []), match[1]);

const parseProperty = (transform) => /[\d\w]+/.exec(transform)[0];

export const getTransform = (transformProperty, el) =>
  go(
    el,
    $css('transform'),
    (transform) => transform.split(' '),
    filter((s) => s.includes(transformProperty)),
    head,
  );

export const removeTransformProperty = curry((property, transform) =>
  go(
    transform.split(' '),
    filter((t) => parseProperty(t) != property),
    (ts) => ts.join(' '),
  ),
);

export const getUpdatedTransform = (transform, el) => {
  const originTransform = $css('transform', el);
  if (originTransform == 'none') return transform;

  return go(originTransform, removeTransformProperty(parseProperty(transform)), (t) => `${t} ${transform}`);
};

export const getTransformValue = (property, el) =>
  go(
    el,
    $css('transform'),
    (t) => t.split(' '),
    filter((t) => t.includes(property)),
    map(parseValue),
    head,
  );

// translate(x, y), translate3d, matrix 와 같은 2 이상의 인자를 받는 transform 은 지원 x
export const getTransformObj = (el) =>
  go($css('transform', el), split(' '), map(juxt(parseProperty, parseValue)));
