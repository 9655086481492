import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { PriceConstantS } from '../Constant/module/PriceConstantS.js';
import { price } from './price.js';

export const pricify_by = (value, _en) =>
  UtilS.commify(_en == '_en' ? parseFloat(value || 0).toFixed(PriceConstantS.DIGIT) : value);

export const pricify_en = (value) => pricify_by(value, '_en');
export const pricify_jp = (value) => pricify_by(value, '_jp');
export const pricify_kr = (value) => pricify_by(value);
export const pricify_ko = pricify_kr;

export const pricify = (value, lang) => pricify_by(value, lang || G._en);

export const round_down10 = (value) => value - (value % 10);

export const to_profit_en = (profit) => price(profit * PriceConstantS.PROFIT_EXCHANGE_RATE_EN);
export const to_profit_jp = (profit) => parseInt(profit * PriceConstantS.PROFIT_EXCHANGE_RATE_JP);

export const exchangeFromKr = (profit, lang) => {
  if (lang == 'en') {
    return to_profit_en(profit);
  }
  if (lang == 'jp') {
    return to_profit_jp(profit);
  }
  return profit;
};
