import { sel, html } from 'fxjs/es';
import { staticTypo, typo } from '../../../../../../../services/MarppleShop/shared/typography/typo.js';
import { creatorPriceOfProduct } from '../../../../../../ProductColorPrice/S/fs.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopStudioPreDiscountProductS } from '../../../../../Studio/PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { MShopUtilConstantS } from '../../../../../Util/S/Constant/module/MShopUtilConstantS.js';

export const makeProductPriceHtml = (product, component) => {
  if (!product.id) {
    return '';
  }

  const pre_discount_price = sel('_.pre_discount_product.pre_discount_price', product);
  const origin_price = creatorPriceOfProduct(product);
  const pre_discount_rate = pre_discount_price
    ? MShopStudioPreDiscountProductS.calcDiscountedRateByPercent(pre_discount_price, origin_price)
    : null;

  // 할인률 없을 때
  if (!pre_discount_rate) {
    if (MShopStudioPreDiscountProductS.isPreProductDiscountSupported()) {
      return html`<div class="${component}__price">
        <span class="${component}__price-number mp_currency">${UtilS.commify(origin_price)}</span>
      </div>`;
    }

    return html`<div class="${component}__price mp_currency ${typo('20_bold')}">
      <span
        class="${component}__price-number ${MShopUtilConstantS.UNICA_KLASS} ${staticTypo('unica_20_bold')}"
        >${UtilS.commify(origin_price)}</span
      >
    </div>`;
  }

  // 할인률 있을 때
  return html`<div class="${component}__price">
    <span class="${component}__discount-rate">${pre_discount_rate}%</span>
    <span class="${component}__price-number mp_currency">${UtilS.commify(origin_price)}</span>
    <span class="${component}__price-number--deprecated mp_currency"
      ><span>${UtilS.commify(pre_discount_price)}</span></span
    >
  </div>`;
};
