import { html } from 'fxjs/es';

export const nessFullPageLoading = () => {
  return html`
    <div class="ness-loading">
      <div class="ness-loading__image-container">
        <img alt="loading" class="ness-loading__image" src="/temp/ness-loading/loading.png" />
      </div>
    </div>
  `;
};
