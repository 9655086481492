import { html } from 'fxjs/es';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

import { ACTION, ACTION_TARGET, SERVICE } from '../../../Authority/S/constant.js';
import { CreatorWithdrawConstantS } from '../../../Withdraw/S/Constant/module/CreatorWithdrawConstantS.js';
import { CREW_STORE_MENU_TYPE } from '../../StoreManagement/S/constant.js';

export const SELLER_MANAGEMENT_MENU = {
  path: `stores/list?type=${encodeURIComponent(CREW_STORE_MENU_TYPE.ACTIVE)}`,
  name: T('crew_menu::seller_management'),
  icon_html: html` <i class="d-block lnr lnr-user small"></i> `,
};

export const PB_SELLER_MANAGEMENT_MENU = {
  path: `stores/list?type=${encodeURIComponent(CREW_STORE_MENU_TYPE.PB)}`,
  name: T('crew_menu::pb_seller_management'),
  icon_html: html` <i class="d-block lnr lnr-user small"></i> `,
};

export const INACTIVE_SELLER_LIST_MENU = {
  path: `stores/list?type=${encodeURIComponent(CREW_STORE_MENU_TYPE.INACTIVE)}`,
  name: T('crew_menu::inactive_seller_list'),
  icon_html: html` <i class="d-block lnr lnr-warning big"></i> `,
};
export const DISAPPROVAL_STORE_LIST_MENU = {
  path: `stores/list?type=${encodeURIComponent(CREW_STORE_MENU_TYPE.DISAPPROVAL)}`,
  name: T('crew_menu::disapproval_store_list'),
  icon_html: html` <i class="d-block lnr lnr-construction"></i> `,
  action_option: {
    service: SERVICE.ADMIN,
    pass_no_service: false,
  },
};
export const UNREGISTERED_STORE_LIST_MENU = {
  path: `stores/list?type=${encodeURIComponent(CREW_STORE_MENU_TYPE.UNREGISTERED)}`,
  name: T('crew_menu::unregistered_store_list'),
  /** @todo 병진님 여기에요
   * 왼쪽 아이콘 html 입니다. 다른 아이콘을 쓰려면 lnr-construction 클래스명을 바꾸면 됩니다.
   * 우리가 직접 이미지 올리는 게 아니라 뭔가 라이브러리같은 거였어요. 지원되는 클래스명 중에 넣어야 합니다. */
  icon_html: html` <i class="d-block lnr lnr-construction"></i> `,
  action_option: {
    service: SERVICE.ADMIN,
    pass_no_service: false,
  },
};
export const REJECTED_STORE_LIST_MENU = {
  path: `stores/list?type=${encodeURIComponent(CREW_STORE_MENU_TYPE.REJECTED)}`,
  name: T('crew_menu::rejected_store_list'),
  /** @todo 병진님 여기에요
   * 왼쪽 아이콘 html 입니다. 다른 아이콘을 쓰려면 lnr-construction 클래스명을 바꾸면 됩니다.
   * 우리가 직접 이미지 올리는 게 아니라 뭔가 라이브러리같은 거였어요. 지원되는 클래스명 중에 넣어야 합니다. */
  icon_html: html` <i class="d-block lnr lnr-construction"></i> `,
  action_option: {
    service: SERVICE.ADMIN,
    pass_no_service: false,
  },
};
export const INVITED_STORE_LIST_MENU = {
  path: `stores/list?type=${encodeURIComponent(CREW_STORE_MENU_TYPE.INVITED)}`,
  name: T('crew_menu::invited_store_list'),
  /** @todo 병진님 여기에요
   * 왼쪽 아이콘 html 입니다. 다른 아이콘을 쓰려면 lnr-construction 클래스명을 바꾸면 됩니다.
   * 우리가 직접 이미지 올리는 게 아니라 뭔가 라이브러리같은 거였어요. 지원되는 클래스명 중에 넣어야 합니다. */
  icon_html: html` <i class="d-block lnr lnr-construction"></i> `,
  action_option: {
    service: SERVICE.ADMIN,
    pass_no_service: false,
  },
};
export const MANAGER_MANAGEMENT_MENU = {
  path: 'managers',
  name: T('crew_menu::manager_management'),
  icon_html: html` <i class="d-block lnr lnr-users big"></i> `,
  action_option: {
    actions: ACTION.UPDATE_MANAGER_LEVEL,
    target: ACTION_TARGET.CREW,
  },
};
export const SELLER_WITHDRAW_MENU = {
  path: 'withdraw',
  name: T('crew_menu::seller_adjustment'),
  icon_html: html` <i class="d-block lnr lnr-database"></i> `,
  action_option: {
    actions: ACTION.READ_WITHDRAWS,
    target: ACTION_TARGET.CREW,
    service: SERVICE.WITHDRAW,
    pass_no_service: false,
  },
  filter: (crew) => crew.withdraw_type == CreatorWithdrawConstantS.WITHDRAW_TYPE.integrated,
};

export const PROFIT_MENU = {
  path: 'profit',
  name: T('plus_admin::pa97'),
  icon_html: html` <i class="d-block lnr lnr-chart-bars"></i> `,
  action_option: {
    actions: ACTION.READ_SALES_AMOUNT,
    target: ACTION_TARGET.CREW,
  },
  sub_menus: [
    {
      path: 'mps/daily',
      name: T('crew_menu::store_profit'),
      icon_html: html` <i class="d-block lnr lnr-chart-bars"></i> `,
    },
    {
      path: 'pbg/daily',
      name: T('crew_menu::store_seller_profit'),
      icon_html: html` <i class="d-block lnr lnr-chart-bars"></i> `,
    },
    {
      path: 'tpl/daily',
      name: T('crew_menu::store_tpl_profit'),
      icon_html: html` <i class="d-block lnr lnr-chart-bars"></i> `,
    },
    {
      path: 'dtg/daily',
      name: T('crew_menu::store_dtg_profit'),
      icon_html: html` <i class="d-block lnr lnr-chart-bars"></i> `,
    },
  ],
};

export const CONFIRMATIONS_MENU = {
  path: 'confirmations',
  name: T('crew_menu::confirmations'),
  icon_html: html` <i class="d-block lnr lnr-spell-check"></i> `,
  action_option: {
    actions: ACTION.UPDATE_PRODUCT_CONFIRM,
    target: ACTION_TARGET.CREW,
    service: SERVICE.CONFIRMATION,
    pass_no_service: false,
  },
};

export const SAMPLE_CHART_MENU = {
  path: 'chart',
  name: T('plus_admin::pa195'),
  icon_html: html` <i class="d-block lnr lnr-pie-chart"></i> `,
  action_option: {
    actions: ACTION.READ_MARPPLESHOP_ANALYTICS,
    target: ACTION_TARGET.CREW,
    service: SERVICE.ADMIN,
    pass_no_service: false,
  },
};

export const PRODUCT_LIST_MENU = {
  path: 'products/public?category=9',
  name: T('crew_menu::products'),
  icon_html: html` <i class="d-block lnr lnr-shirt"></i> `,
  action_option: {
    service: SERVICE.ADMIN,
    pass_no_service: false,
  },
};

export const PRODUCT_CHECK_MENU = {
  path: 'product/check',
  name: T('crew_menu::product_check'),
  icon_html: html` <i class="d-block lnr lnr-eye"></i> `,
  action_option: {
    service: SERVICE.ADMIN,
    actions: ACTION.CAN_CHECK_DESIGN,
    target: ACTION_TARGET.CREW,
    pass_no_service: false,
  },
};

export const PB_ME_MENU = {
  path: 'pb-me',
  name: T('상품 승급'),
  icon_html: html` <i class="d-block lnr lnr-gift"></i> `,
  action_option: {
    service: SERVICE.PB,
    pass_no_service: false,
    target: ACTION_TARGET.CREW,
    actions: ACTION.CAN_CHECK_DESIGN,
  },
  sub_menus: [
    {
      path: 'list',
      name: T('승급 관리'),
      icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
    },
    {
      path: 'inspect',
      name: T('승급 검수'),
      icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
    },
  ],
};

// export const SELLER_TIP_MENU = {
//   path: 'feeds',
//   name: T('crew_menu::feeds'),
//   icon_html: html`<i class="d-block lnr lnr-bubble"></i>`,
//   action_option: {
//     actions: ACTION.CREATE_FEED,
//     target: ACTION_TARGET.CREW,
//   },
// };

export const SOLUTION_MENU = {
  path: 'solution/list?status=ing2',
  name: T('crew_menu::solution'),
  icon_html: html` <i class="d-block lnr lnr-heart"></i> `,
  action_option: {
    service: SERVICE.ADMIN,
    pass_no_service: false,
  },
};

export const CREW_WITHDRAW_MENU = {
  path: 'crew_withdraws',
  name: T('crew_menu::crew_withdraw'),
  icon_html: html` <i class="d-block lnr lnr-diamond big"></i> `,
  action_option: {
    service: SERVICE.WITHDRAW,
    pass_no_service: false,
    actions: ACTION.READ_CREW_WITHDRAWS,
    target: ACTION_TARGET.CREW,
  },
};

export const CREW_BANK_ACCOUNT_MENU = {
  path: 'crew_bank_account',
  name: T('crew_menu::crew_bank_account'),
  icon_html: html` <i class="d-block lnr lnr-license"></i> `,
  action_option: {
    service: SERVICE.WITHDRAW,
    pass_no_service: false,
    actions: ACTION.REGISTER_CREW_BANK_ACCOUNT,
    target: ACTION_TARGET.CREW,
  },
};

export const CREW_ADJUSTMENT_MENU = {
  path: 'crew_adjustments',
  name: `굿즈 정산`,
  icon_html: html` <i class="d-block lnr lnr-calendar-full"></i> `,
  action_option: {
    service: SERVICE.WITHDRAW,
    pass_no_service: false,
    actions: ACTION.REQUEST_CREW_WITHDRAWS,
    target: ACTION_TARGET.CREW,
  },
};

export const CREW_PB_ADJUSTMENT_MENU = {
  path: 'pb-crew_adjustments',
  name: `상품/입고/디지털 정산`,
  icon_html: html` <i class="d-block lnr lnr-calendar-full"></i> `,
  action_option: {
    service: SERVICE.WITHDRAW,
    pass_no_service: false,
    actions: ACTION.REQUEST_CREW_WITHDRAWS,
    target: ACTION_TARGET.CREW,
  },
};

export const CREW_YOUTUBE_MENU = {
  path: 'youtube_list',
  name: T('crew_menu::crew_youtube'),
  icon_html: html` <i class="d-block youtube-icon"></i> `,
  action_option: {
    service: SERVICE.ADMIN,
    pass_no_service: false,
  },
};

const is_ness = UtilS.isNessApp();
export const SITE_MANAGE_MENU = {
  path: 'website',
  name: T('crew_menu::website'),
  icon_html: html` <i class="d-block lnr lnr-store big" style="margin-top: -4px;"></i> `,
  action_option: {
    actions: ACTION.MANAGE_SITE,
    target: ACTION_TARGET.CREW,
  },
  sub_menus: [
    {
      path: 'brand',
      name: is_ness ? ET('ness::plus_admin::pa_001') : T('plus_admin::pa402'),
      icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
    },
    /*{
      path: 'title',
      name: '타이틀 관리',
      icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
    },*/
    {
      path: 'banner',
      name: T('plus_admin::pa422'),
      icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
      is_all_crew: true,
    },
    {
      path: 'banner',
      name: ET('mps2::plus_studio::banner::title'),
      icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
      is_all_crew: false,
    },
    {
      path: 'live',
      name: T('plus_admin::pa515'),
      icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
      is_all_crew: true,
    },
    // {
    //   path: 'seller',
    //   name: '메인 셀러 관리',
    //   icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
    // },
    // {
    //   path: 'styles',
    //   name: T('plus_admin::pas690'),
    //   icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
    //   is_all_crew: true,
    // },

    ...(is_ness
      ? [
          {
            path: 'brandedcontents',
            name: '브랜디드 컨텐츠',
          },
          {
            path: 'events',
            name: T('plus_admin::pa470-1'),
            icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
          },
        ]
      : [
          {
            path: 'creator_views',
            name: T('plus_admin::pa470-3'),
            icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
            is_all_crew: true,
          },
          {
            path: 'popup-stores',
            name: T('plus_admin::pa712'),
            icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
            is_all_crew: true,
          },
          {
            path: 'curations',
            name: T('plus_admin::pa470-2'),
            icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
            is_all_crew: true,
          },
          {
            path: 'events',
            name: T('plus_admin::pa470'),
            icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
            is_all_crew: false,
          },
          {
            path: 'popup',
            name: T('plus_admin::pa470-4'),
            icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
            is_all_crew: true,
          },
        ]),
    // {
    //   path: 'product',
    //   name: T('plus_admin::pa501'),
    //   icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
    // },
    {
      path: 'live',
      name: is_ness ? ET('ness::plus_admin::pa_068') : ET('ness::plus_admin::pa_068'),
      icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
      is_all_crew: false,
    },
    ...(is_ness
      ? []
      : [
          {
            path: 'popup',
            name: T('plus_admin::pa470-4'),
            icon_html: html` <i class="d-block lnr lnr-screen"></i> `,
            is_all_crew: false,
          },
        ]),
  ],
};

export const MENU_SPLITER = {
  html: '<div class="menu_spliter"></div>',
};
