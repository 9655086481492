import { html } from 'fxjs/es';

export const youtubeLogoButton = () => {
  return html`
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 36 36">
      <g filter="url(#a)">
        <rect width="36" height="36" fill="#fff" fill-opacity=".2" rx="18" />
        <path
          fill="#fff"
          fill-rule="evenodd"
          d="M24.251 12.734c.688.184 1.23.727 1.415 1.415.334 1.248.334 3.85.334 3.85s0 2.604-.334 3.852a2.005 2.005 0 0 1-1.415 1.415C23.003 23.6 18 23.6 18 23.6s-5.003 0-6.251-.335a2.005 2.005 0 0 1-1.415-1.414C10 20.603 10 18 10 18s0-2.603.334-3.851a2.005 2.005 0 0 1 1.415-1.415C12.997 12.4 18 12.4 18 12.4s5.003 0 6.251.334ZM20.4 18l-4-2.4v4.8l4-2.4Z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="116"
          height="116"
          x="-40"
          y="-40"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4104_70061" />
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_4104_70061" result="shape" />
        </filter>
      </defs>
    </svg>
  `;
};

export const fbLogoButton = () => {
  return html`
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 36 37">
      <g filter="url(#a)">
        <rect width="36" height="36" y=".5" fill="#fff" fill-opacity=".2" rx="18" />
        <path
          fill="#fff"
          d="M20.05 14.137c-.79 0-1.018.35-1.018 1.123v1.281h2.107l-.21 2.073h-1.897V24.9h-2.53v-6.286H14.8v-2.073h1.703v-1.246c0-2.088.843-3.195 3.196-3.195.51 0 1.107.035 1.475.087v1.95"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="116"
          height="116"
          x="-40"
          y="-39.5"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4137_40422" />
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_4137_40422" result="shape" />
        </filter>
      </defs>
    </svg>
  `;
};

export const xLogoButton = () => {
  return html`
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 36 37">
      <g filter="url(#a)">
        <rect width="36" height="36" y=".5" fill="#fff" fill-opacity=".2" rx="18" />
        <mask id="b" fill="#fff">
          <path
            d="m11.532 12.5 5.019 6.692-5.051 5.441h1.137l4.422-4.763 3.573 4.763H24.5l-5.302-7.068L23.9 12.5h-1.137l-4.073 4.387L15.4 12.5h-3.868Zm1.671.835h1.777l7.848 10.463h-1.777l-7.848-10.463Z"
          />
        </mask>
        <path
          fill="#fff"
          d="m11.532 12.5 5.019 6.692-5.051 5.441h1.137l4.422-4.763 3.573 4.763H24.5l-5.302-7.068L23.9 12.5h-1.137l-4.073 4.387L15.4 12.5h-3.868Zm1.671.835h1.777l7.848 10.463h-1.777l-7.848-10.463Z"
        />
        <path
          fill="#fff"
          d="M11.532 12.5v-1h-2l1.2 1.6.8-.6Zm5.019 6.692.733.68.568-.612-.501-.668-.8.6ZM11.5 24.633l-.733-.68-1.56 1.68H11.5v-1Zm1.137 0v1h.436l.297-.32-.733-.68Zm4.422-4.763.8-.6-.719-.958-.814.877.733.68Zm3.573 4.763-.8.6.3.4h.5v-1Zm3.868 0v1h2l-1.2-1.6-.8.6Zm-5.302-7.068-.733-.68-.568.611.501.669.8-.6ZM23.9 12.5l.733.68 1.56-1.68H23.9v1Zm-1.137 0v-1h-.436l-.297.32.733.68Zm-4.073 4.387-.8.6.719.958.814-.877-.732-.68ZM15.4 12.5l.8-.6-.3-.4h-.5v1Zm-2.197.835v-1h-2l1.2 1.6.8-.6Zm1.777 0 .8-.6-.3-.4h-.5v1Zm7.848 10.463v1h2l-1.2-1.6-.8.6Zm-1.777 0-.8.6.3.4h.5v-1ZM10.73 13.1l5.02 6.692 1.6-1.2-5.02-6.692-1.6 1.2Zm5.087 5.412-5.05 5.441 1.465 1.36 5.05-5.44-1.465-1.361ZM11.5 25.633h1.137v-2H11.5v2Zm1.87-.32 4.422-4.763-1.466-1.36-4.422 4.763 1.466 1.36Zm2.889-4.843 3.573 4.763 1.6-1.2-3.573-4.763-1.6 1.2Zm4.373 5.163H24.5v-2h-3.868v2Zm4.668-1.6-5.302-7.068-1.6 1.2 5.302 7.068 1.6-1.2Zm-5.369-5.788 4.702-5.065-1.466-1.36-4.702 5.064 1.466 1.361ZM23.9 11.5h-1.137v2H23.9v-2Zm-1.87.32-4.072 4.387 1.466 1.36 4.072-4.387-1.466-1.36Zm-2.54 4.467L16.2 11.9l-1.6 1.2 3.29 4.387 1.6-1.2ZM15.4 11.5h-3.868v2H15.4v-2Zm-2.197 2.835h1.777v-2h-1.777v2Zm.978-.4 7.847 10.463 1.6-1.2-7.848-10.463-1.6 1.2Zm8.647 8.863h-1.777v2h1.777v-2Zm-.977.4-7.848-10.463-1.6 1.2 7.848 10.463 1.6-1.2Z"
          mask="url(#b)"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="116"
          height="116"
          x="-40"
          y="-39.5"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4137_40428" />
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_4137_40428" result="shape" />
        </filter>
      </defs>
    </svg>
  `;
};

export const instagramLogoButton = () => {
  return html`
    <svg width="100%" height="100%" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_4137_40426)">
        <rect y="0.5" width="36" height="36" rx="18" fill="white" fill-opacity="0.2" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.9334 11.2998H20.7998C23.0822 11.2998 24.9334 13.1502 24.9334 15.4334V21.2998C24.9334 23.5822 23.0822 25.4334 20.7998 25.4334H14.9334C12.6502 25.4334 10.7998 23.5822 10.7998 21.2998V15.4334C10.7998 13.1502 12.6502 11.2998 14.9334 11.2998ZM20.7998 12.6334H14.9334C13.387 12.6334 12.1334 13.887 12.1334 15.4334V21.2998C12.1334 22.8462 13.387 24.0998 14.9334 24.0998H20.7998C22.3462 24.0998 23.5998 22.8462 23.5998 21.2998V15.4334C23.5998 13.887 22.3462 12.6334 20.7998 12.6334Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.4667 18.3398C21.4667 16.3374 19.8435 14.7134 17.8403 14.7134C15.8379 14.7134 14.2139 16.3366 14.2139 18.3398C14.2139 20.3422 15.8371 21.9662 17.8403 21.9662C19.8427 21.9662 21.4667 20.343 21.4667 18.3398ZM15.5467 18.3398C15.5467 17.0734 16.5739 16.0462 17.8403 16.0462C19.1067 16.0462 20.1339 17.0734 20.1339 18.3398C20.1339 19.6062 19.1067 20.6334 17.8403 20.6334C16.5739 20.6334 15.5467 19.6062 15.5467 18.3398Z"
          fill="white"
        />
        <circle cx="21.6261" cy="14.606" r="0.8536" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_b_4137_40426"
          x="-40"
          y="-39.5"
          width="116"
          height="116"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4137_40426" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4137_40426" result="shape" />
        </filter>
      </defs>
    </svg>
  `;
};

export const linkLogoButton = () => {
  return html`
    <svg width="100%" height="100%" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_4137_40430)">
        <rect y="0.5" width="36" height="36" rx="18" fill="white" fill-opacity="0.2" />
        <path
          d="M21.7071 20.086L23.2981 18.495C24.7626 17.0305 24.7626 14.6562 23.2981 13.1917V13.1917C21.8337 11.7272 19.4593 11.7272 17.9948 13.1917L16.4038 14.7827"
          stroke="white"
          stroke-width="1.3"
          stroke-linecap="round"
        />
        <path
          d="M14.2831 16.9038L12.6921 18.4948C11.2276 19.9592 11.2276 22.3336 12.6921 23.7981V23.7981C14.1566 25.2625 16.5309 25.2625 17.9954 23.7981L19.5864 22.2071"
          stroke="white"
          stroke-width="1.3"
          stroke-linecap="round"
        />
        <rect
          x="19.3096"
          y="16.7296"
          width="0.65"
          height="4.35"
          rx="0.325"
          transform="rotate(45 19.3096 16.7296)"
          fill="white"
          stroke="white"
          stroke-width="0.65"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_4137_40430"
          x="-40"
          y="-39.5"
          width="116"
          height="116"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4137_40430" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4137_40430" result="shape" />
        </filter>
      </defs>
    </svg>
  `;
};
