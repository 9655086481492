import { html, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppSolutionAddTmplS } from '../../../Add/S/Tmpl/module/MShopAppSolutionAddTmplS.js';
import { MShopAppSolutionHomeConstantS } from '../../../Home/S/Constant/module/MShopAppSolutionHomeConstantS.js';
import { MShopAppSolutionHomeTmplS } from '../../../Home/S/Tmpl/module/MShopAppSolutionHomeTmplS.js';
import { MShopAppSolutionListS } from '../../../List/S/Function/module/MShopAppSolutionListS.js';
import { MShopAppSolutionItemTmplS } from './module/MShopAppSolutionItemTmplS.js';

const makeSolutionItemReplyHtml = (opt) => {
  try {
    /*
     * ing
     * ing2
     * confirm
     * close
     *
     * crew
     * studio
     * client
     * */
    return MShopAppSolutionItemTmplS.status[opt.status][opt.page_name](opt);
  } catch (e) {
    return '';
  }
};

export const info = (
  {
    name2,
    product_id,
    is_answer,
    user_type,
    type,
    status,
    last_request_at,
    last_answer_at,
    reason,
    is_close,
    _: { solution_threads, store },
  },
  page_name,
) => {
  const dday_last_request_at = MShopAppSolutionListS.make14Dday(last_request_at || new Date());
  const dday_last_answer_at = MShopAppSolutionListS.make14Dday(last_answer_at || new Date());
  const IS_INTERNAL_REGULATION = type === 'internal-regulation';

  return html`
    <div class="request-of-solution-item">
      <h2>신고 접수 내역</h2>
      <div class="solution-info info">
        <div class="top">
          <div class="store-name">${UtilS.escape(store.name)}</div>
        </div>
        <div class="bottom">
          <div class="flex shop-url">
            <div class="title">크리에이터샵 URL</div>
            <div class="content">
              <a href="/${T.lang}/${UtilS.escape(store.domain_name)}" target="_blank"
                >https://marpple.shop/${UtilS.escape(store.domain_name)}</a
              >
            </div>
          </div>
          <div class="flex type">
            <div class="title">유형</div>
            <div class="content">
              ${MShopAppSolutionHomeConstantS.TYPE_TO_TITLE()[type]}${type === 'internal-regulation'
                ? ''
                : `/${MShopAppSolutionHomeConstantS.USER_TYPE_TO_TITLE()[user_type]}`}
            </div>
          </div>
          ${IS_INTERNAL_REGULATION
            ? ''
            : html`
                <div class="flex name2">
                  <div class="title">상표권·디자인권 보유자</div>
                  <div class="content">${UtilS.escape(name2)}</div>
                </div>
                <div class="flex product-url">
                  <div class="title">상품 주소</div>
                  <div class="content">
                    <a
                      href="/${T.lang}/${UtilS.escape(store.domain_name)}/products/${product_id}"
                      target="_blank"
                      >https://marpple.shop/${UtilS.escape(store.domain_name)}/products/${product_id}</a
                    >
                  </div>
                </div>
              `}
        </div>
      </div>
      <h2>신고 접수 스레드</h2>
      <div class="solution-thread-info">
        <div class="solution-threads">
          ${strMap(
            (solution_thread) => html`
              <div class="solution-thread-item" is_seller="${solution_thread.is_seller}">
                <div class="content">
                  <div class="left">
                    <div class="title small-title">${UtilS.escape(solution_thread.small_title)}</div>
                    <div class="created_at">
                      ${!solution_thread.created_at
                        ? ''
                        : moment(solution_thread.created_at).format('YYYY-MM-DD')}
                    </div>
                  </div>
                  <div class="right">
                    <div class="content-wrapper">
                      <div class="title profile">
                        ${solution_thread.is_seller
                          ? solution_thread.is_seller === 'marppleshop'
                            ? '마플샵 고객센터'
                            : UtilS.escape(store.name)
                          : '신고 접수자'}
                      </div>
                      <div class="comment">${UtilS.linkify(solution_thread.comment)}</div>
                      <div class="title file-title">첨부파일</div>
                      <div class="files">
                        <div class="file-list">
                          ${solution_thread._.solution_thread_files.length
                            ? strMap(
                                MShopAppSolutionAddTmplS.makeFileItemHtml(false),
                                solution_thread._.solution_thread_files,
                              )
                            : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            `,
            solution_threads,
          )}
        </div>
        <div class="system-reply">
          ${makeSolutionItemReplyHtml({
            IS_INTERNAL_REGULATION,
            page_name,
            is_close,
            status,
            dday_last_request_at,
            dday_last_answer_at,
            reason,
          })}
        </div>
      </div>
      <div class="agree-box">
        <h5>신고접수 스레드 유의사항</h5>
        <div class="note">
          <ul>
            <li>크리에이터가 답변을 미 등록 시 상품 판매 중지처리 되오니 참고 부탁드립니다.</li>
            <li>
              신고 접수 스레드는 신고접수자와 크리에이터간의 합의를 위한 공간이며, 마플샵은 저작권을 보유하지
              않고 있기에 스레드 생성 외에 어떤 관여도 하지 않습니다.
            </li>
            <li>
              마플샵을 통한 신고 접수 결과는 법적 효력이 없으며, 이후의 절차는 당사자 간 합의 또는 관계 행정
              기관 심의 판단이나 법원 판결을 통해 해결하실 수 있습니다.
            </li>
            <li>
              판매 재개 된 상품의 경우, 스레드 내에서 답변 보강 요청 외에 재 신고를 하실 수 없으며 이후 당사자
              간 합의 또는 관계 행정 기관 심의 판단이나 법원 판결을 통해 해결 하실 수 있습니다.
            </li>
          </ul>
        </div>
      </div>
    </div>
  `;
};

export const fn = (solution_item) => MShopAppSolutionHomeTmplS.wrapper('list', info(solution_item, 'client'));
