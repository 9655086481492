import { html, match } from 'fxjs/es';
import { typo } from '../../../../../services/MarppleShop/shared/typography/typo.js';
import {
  makeCartNavigate,
  makeEmptyScreenNavigate,
} from '../../../../../services/MarppleShop/shared/app/navigate.ts';
import { dataStr } from '../../../../../services/MarppleShop/shared/util/dataStr.ts';

export const createToastContainer = (layout_options = {}) =>
  html`
    <div
      class="
        toast-message-container
        ${layout_options.position || ''}
        ${layout_options.has_bottom_tab_bar ? 'with_bottom_tab_bar' : ''}
        ${layout_options.has_bottom_margin ? 'with_bottom_tab_bar' : ''}
      "
    ></div>
  `;

export const getToastTypeIcon = (type) =>
  match(type)
    .case('error')(() => {
      return '//s3.marpple.co/files/u_2308668/2023/11/original/9d3c49f7a3fa95d7853b902dc06a55009ce6486e1.png';
    })
    .case('info')(() => {
      return '//s3.marpple.co/files/u_2308668/2023/11/original/9d3c49f7a3fa95d7853b902dc06a55009ce6486e1.png';
    })
    .case('confirm')(() => {
      return '//s3.marpple.co/files/u_1165777/2021/5/original/7a9e7d3d98c13e890e87cd6590b9f744ffc042261.png';
    })
    .case('cart')(() => {
      return '//s3.marpple.co/files/u_2308668/2023/11/original/2ec325df5ea995909e5924d6cd586352ef8466bd1.png';
    })
    .case('like')(() => {
      return '//s3.marpple.co/files/u_2308668/2023/11/original/4ca551503bbfac3b800e0c2a6febe0f1f483733c1.png';
    })
    .case('image')(() => {
      return '//s3.marpple.co/files/u_2308668/2023/11/original/e818569f90b539105fafbe3222364068d0680ae21.png';
    })
    .else(() => '');

export const getToastTextButtonByType = (type) => {
  const texts = {
    close: ET('mps2::toast::close'),
    goto_cart: ET('mps2::toast::goto_cart'),
    goto_product: ET('mps2::toast::goto_product'),
  };
  const close_html = html`<div class="toast-message__close toast-message__text-button ${typo('14_medium')}">
    ${texts.close}
  </div>`;

  return match(type)
    .case('error')(() => {
      return close_html;
    })
    .case('info')(() => {
      return close_html;
    })
    .case('confirm')(() => {
      return close_html;
    })
    .case('cart')(() => {
      return html`<a data-post-message="${dataStr(makeCartNavigate())}" href="/${ET.lang}/@/cart"
        ><span class="toast-message__text-button ${typo('14_medium')}">${texts.goto_cart}</span></a
      >`;
    })
    .case('like')(() => {
      return html`<a
        data-post-message="${dataStr(makeEmptyScreenNavigate(`/${ET.lang}/@/my_like`))}"
        href="/${ET.lang}/@/my_like"
        ><span class="toast-message__text-button ${typo('14_medium')}">${texts.goto_product}</span></a
      >`;
    })
    .case('store_like')(() => {
      return html`<a
        data-post-message="${dataStr(makeEmptyScreenNavigate(`/${ET.lang}/@/my_like?seller=true`))}"
        href="/${ET.lang}/@/my_like?seller=true"
        ><span class="toast-message__text-button ${typo('14_medium')}">${texts.goto_product}</span></a
      >`;
    })
    .case('image')(() => {
      return close_html;
    })
    .else(() => close_html);
};
export const createToastItem = (inner, { type }) => html`
  <div class="toast-message">
    <div class="toast-message__inner">${inner}</div>
    ${getToastTextButtonByType(type)}
  </div>
`;

export const createNessToastItem = (inner, { type }) => html`
  <div class="toast-message">
    <img class="toast-message__icon" alt="toast message icon" src="${getToastTypeIcon(type)}" />
    <div class="toast-message__inner">${inner}</div>
    <img
      class="toast-message__close"
      alt="close button"
      src="//s3.marpple.co/files/u_1165777/2021/5/original/ebd0d0ac607378cc2c8047b91f20933cd695d4cb1.png"
    />
  </div>
`;
