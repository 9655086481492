import { $attr, $qs } from 'fxdom/es';
import { go, not, ifElse, selEq, slice, split } from 'fxjs/es';
import { MuiF } from '../../../../../../modules/Mui/F/Function/module/MuiF.js';
import { startFrame } from '../../base_start_frame.js';

const loadFrameModuleCreator = (path) =>
  import(
    /*
      webpackInclude: /F\/root_frame/,
      webpackChunkName: "[request]",
    */
    `../../../../../../modules/Creator/${path}.js`
  );

const loadFrameService = (module_path) =>
  import(
    /*
      webpackExclude: /apiApp|Storybook/,
      webpackInclude: /F\/Mui\/module/,
      webpackChunkName: "[request]",
    */
    `../../../../../../services/${module_path.join('/')}/F/Mui/module/index.js`
  )
    .catch(
      ifElse(
        selEq('code', 'MODULE_NOT_FOUND'),
        (e) => console.error('Failed to load frame.') || Promise.reject(e),
      ),
    )
    .then((module) => (typeof module.start === 'function' ? module.start() : MuiF.startFrame(module.frame)))
    .catch((e) => console.error('Failed to start frame.') || Promise.reject(e));

const loadFrameModules = (module_path) =>
  import(
    /*
      webpackExclude: /Df|Ness|OMP|\/B\//,
      webpackInclude: /F\/Mui\/module/,
      webpackChunkName: "[request]",
    */
    `../../../../../../modules/${module_path.join('/')}/F/Mui/module/index.js`
  )
    .catch(
      ifElse(
        selEq('code', 'MODULE_NOT_FOUND'),
        (e) => console.error('Failed to load frame.') || Promise.reject(e),
      ),
    )
    .then((module) => (typeof module.start === 'function' ? module.start() : MuiF.startFrame(module.frame)))
    .catch((e) => console.error('Failed to start frame.') || Promise.reject(e));

(function () {
  const $don_frame = $qs('.don_frame');
  if (not($don_frame)) return;

  const full_path = go($don_frame, $attr('frame_name'), split('/'));

  if (full_path?.[1] === 'services') {
    return loadFrameService(slice(2, full_path.indexOf('S'), full_path));
  }

  if ($attr('is-mui', $don_frame) === 'true') {
    return loadFrameModules(slice(2, full_path.indexOf('S'), full_path));
  } else {
    return startFrame(loadFrameModuleCreator, $don_frame);
  }
})();
