import { html } from 'fxjs/es';

const nbox_terms_content_kr = html`
  <h1>이용약관</h1>

  <h2>제1조 (목적)</h2>
  <p>
    이 약관은 ㈜넥슨코리아(이하 ‘회사’)에서 운영하는 ‘NBOX’(이하 ‘몰’이라 한다)에서 제공하는 인터넷 관련
    서비스(이하 ‘서비스’라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리∙의무 및 책임사항을 규정함을
    목적으로 합니다.
  </p>

  <h2>제2조 (정의)</h2>
  <p>
    ① ‘몰’이란 회사가 재화 또는 용역(이하 ‘재화 등’ 이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등
    정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을
    운영하는 사업자의 의미로도 사용합니다.
  </p>
  <p>
    ② ‘회원’이라 함은 회사의 임직원으로서 몰에서 제공하는 모든 서비스 등을 이용할 수 있는 권한을 획득한 자를
    의미합니다.
  </p>

  <h2>제3조 (회사정보 등의 제공)</h2>
  <p>
    회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 홈페이지나 제공 서비스 등 내에 표시합니다. 다만, 제6호는
    회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
  </p>
  <ol>
    <li>상호 및 대표자의 성명</li>
    <li>영업소 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다.)</li>
    <li>전화번호, 전자우편주소</li>
    <li>사업자 등록번호</li>
    <li>통신판매업 신고번호</li>
    <li>서비스 이용약관</li>
  </ol>

  <h2>제4조 (약관의 효력 및 적용과 변경)</h2>
  <p>
    ① 회사는 이 약관의 내용을 회원이 알 수 있도록 회사에서 운영하는 홈페이지에 게시하거나 연결화면을 제공하는
    방법으로 회원에게 공지합니다.
  </p>
  <p>
    ② 회원은 몰에 최초로 접속한 시점부터 약관의 적용을 받고 약관의 변경이 있을 경우에는 변경의 효력이 발생한
    시점부터 변경된 약관의 적용을 받습니다.
  </p>
  <p>
    ③ 회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있습니다. 회사는 약관이 변경되는 경우에 변경된
    약관의 내용과 시행일을 정하여, 그 시행일로부터 7일전 홈페이지 또는 서비스 내 화면을 통해 온라인으로
    공시합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 시행일로부터 30일전 본문과 같은 방법
    또는 회원이 회원 가입 시 등록한 전자우편으로 전송하는 방법으로 회원에게 고지합니다. 변경된 약관은
    공시하거나 고지한 시행일로부터 효력이 발생합니다.
  </p>
  <p>
    ④ 변경된 이용약관의 시행일 이후에도 서비스를 계속 이용하는 경우에는 변경된 약관에 동의한 것으로 간주된다는
    내용을 제3항에 따라 함께 공시 또는 고지하고, 이용자가 시행일 이후 서비스를 이용하는 경우 개정된 약관에
    동의한 것으로 봅니다.
  </p>

  <h2>제5조 (약관 외 준칙)</h2>
  <p>
    이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 회사가 정한 개별 서비스 이용약관, 가이드라인,
    운영정책 및 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및
    전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
    「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수
    있습니다.
  </p>
  <p>
    이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 회사가 정한 개별 서비스 이용약관, 가이드라인,
    사규 및 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」 등 관련 법령을
    따릅니다.
  </p>

  <h2>제6조 (이용신청 및 방법)</h2>
  <p>
    ① 사내 도메인을 통해 몰 홈페이지에 로그인 후 회사는 계정을 통해 회원에 대한 제반 관리 업무를 수행하며,
    회원은 관련 법령, 이 약관 및 운영정책에 따라 서비스 등을 이용할 수 있습니다.
  </p>
  <p>
    ② 회사는 회원이 이용신청 이후 서비스의 제공이나 제공 중인 서비스의 범위 조정을 위하여 필요한 경우,
    회원에게 추가 정보를 요청할 수 있습니다.
  </p>
  <p>
    ③ 회원이 회사가 제3자와의 협력을 통해 제공하는 서비스를 이용할 경우, 회사나 제3자가 제공하는 별도의 서비스
    이용약관에 대한 동의가 필요할 수 있습니다. 해당 서비스의 내용, 회사와 제3자 및 회원 간의 각 권리, 의무
    등에 관한 사항은 해당 서비스에 대하여 회사나 제3자가 제공하는 별도의 약관, 운영정책 등에서 정하는 바에
    따릅니다.
  </p>

  <h2>제7조 (서비스의 제공 및 변경)</h2>
  <p>① “몰”은 다음과 같은 업무를 수행합니다.</p>
  <ol>
    <li>재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</li>
    <li>구매계약이 체결된 재화 또는 용역의 배송</li>
    <li>기타 “몰”이 정하는 업무</li>
  </ol>
  <p>
    ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화
    또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여
    현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
  </p>
  <p>
    ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의
    사유로 변경할 경우에는 그 사유를 이용자에게 통지합니다.
  </p>

  <h2>제8조 (서비스의 중단)</h2>
  <p>
    ① “몰”은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의
    제공을 일시적으로 중단할 수 있으며, 이 경우 회사는 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는
    부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
  </p>
  <p>
    ② 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은
    제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만,
    “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는
    통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
  </p>

  <h2>제9조 (회원에 대한 통지)</h2>
  <p>
    ① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
  </p>
  <p>
    ② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일 이상 “몰” 내 이용자가 잘 볼 수 있는 영역에 게시함으로서
    개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
    개별통지를 합니다.
  </p>

  <h2>제10조(구매신청 등)</h2>
  <p>
    “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매신청을
    함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
  </p>
  <ol>
    <li>재화 등의 전시 및 선택</li>
    <li>받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
    <li>약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인</li>
    <li>이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)</li>
    <li>재화등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의</li>
    <li>결제방법의 선택</li>
  </ol>

  <h2>제11조 (계약의 성립)</h2>
  <p>① “몰”은 제10조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.</p>
  <ol>
    <li>신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
    <li>기타 구매신청에 승낙하는 것이 사회통념상 또는 기술상 현저히 지장이 있다고 판단하는 경우</li>
  </ol>
  <p>
    ② “몰”의 승낙이 제13조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
  </p>
  <p>
    ③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에
    관한 정보 등을 포함하여야 합니다.
  </p>

  <h2>제12조 (대금결제 및 구매안전 서비스)</h2>
  <p>
    “몰”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
    단, “몰”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
  </p>
  <ol>
    <li>인터넷뱅킹 등의 각종 계좌이체</li>
    <li>선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
    <li>온라인무통장입금</li>
    <li>“몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제</li>
    <li>기타 전자적 지급 방법에 의한 대금 지급 등</li>
  </ol>

  <h2>제13조 (수신확인통지․구매신청 변경 및 취소)</h2>
  <p>① “몰”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</p>
  <p>
    ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청
    변경 및 취소를 요청할 수 있고 “몰”은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라
    처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제16조의 청약철회 등에 관한 규정에 따릅니다.
  </p>

  <h2>제14조(재화 등의 공급)</h2>
  <p>
    ① “몰”은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일
    이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “몰”이 이미 재화
    등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를
    취합니다. 이때 “몰”은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
    단, 주문제작 상품의 경우 상품페이지 등에서 별도로 고지하는 내용에 따릅니다.
  </p>
  <p>
    ② “몰”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다.
  </p>

  <h2>제15조 (환급)</h2>
  <p>
    “몰”은 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그
    사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에
    환급하거나 환급에 필요한 조치를 취합니다.
  </p>

  <h2>제16조 (청약철회 등)</h2>
  <p>
    ① “몰”과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조
    제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는
    재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다.
    다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법
    규정에 따릅니다.
  </p>
  <p>② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.</p>
  <ol>
    <li>
      1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여
      포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
    </li>
    <li>2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</li>
    <li>3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우</li>
    <li>4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우</li>
    <li>
      5. 주문에 따라 개별적으로 생산되는 상품 등 그에 대하여 청약철회 등을 인정할 경우 판매자에게 회복할 수
      없는 중대한 피해가 예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 회원의
      서면(전자문서를 포함)에 의한 동의를 받은 경우
    </li>
    <li>6. 그 밖에 관련 법령에 따른 반품 제한 사유에 해당되는 경우</li>
  </ol>
  <p>
    ③ 제2항제2호 내지 제4호의 경우에 “몰”이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에
    명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
  </p>
  <p>
    ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게
    이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일
    이내에 청약철회 등을 할 수 있습니다.
  </p>
  <p>
    ⑤ 회원은 제1항 또는 제4항에 따라 교환 신청을 하더라도 판매자에게 교환할 상품의 재고가 없는 경우에는 해당
    상품 등의 교환을 받을 수 없습니다. 이 경우 해당 교환 신청은 반품으로 처리 됩니다.
  </p>

  <h2>제17조 (청약철회 등의 효과)</h2>
  <p>
    ① “몰”은 이용자로부터 재화 등을 반환 받은 경우 반환 받은 날로부터 3영업일 이내에 이미 지급받은 재화 등의
    대금을 환급합니다. 이 경우 “몰”이 이용자에게 재화등의 환급을 지연한때에는 그 지연기간에 대하여
    「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한
    지연이자를 지급합니다.
  </p>
  <p>
    ② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을
    지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는
    취소하도록 요청합니다.
  </p>
  <p>
    ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “몰”은 이용자에게
    청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과
    다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “몰”이
    부담합니다.
  </p>
  <p>
    ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “몰”은 청약철회 시 그 비용을 누가 부담하는지를
    이용자가 알기 쉽도록 명확하게 표시합니다.
  </p>

  <h2>제18조 (개인정보의 보호 및 이용)</h2>
  <p>
    ① “회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및
    이용에 대해서는 관련 법령 및 회사가 별도로 공지하는 개인정보처리방침에 따릅니다.
  </p>
  <p>
    ② 회사는 사전에 고지한 목적 외로 개인정보를 이용하지 않으며, 목적 달성 시 즉시 재생이 불가능한 방법으로
    파기합니다.
  </p>
  <p>
    ③ 회사의 개인정보처리방침은 홈페이지나 개별 서비스 별 웹사이트에서 링크되어 있는 제3자 제공 서비스에
    대해서는 적용되지 않습니다.
  </p>
  <p>
    ④ 회원의 선택 또는 서비스의 특성에 따라, 회원이 입력한 별명, 상품평, 상태 정보 등 회원 스스로가 자신을
    소개하는 내용이 다른 회원에게 공개될 수 있습니다.
  </p>
  <p>
    ⑤ 회원은 서비스 등의 이용을 위하여 자신의 개인정보를 성실히 관리해야 하며 개인정보의 변동 사항이 있을 경우
    이를 변경해야 합니다. 회원의 개인정보 변경이 지연되거나 누락되어 발생하는 손해는 회원의 책임으로 합니다.
    회사는 회원의 귀책사유로 인하여 유출된 개인정보에 대해서는 일체의 책임을 지지 않습니다.
  </p>

  <h2>제19조 (“몰”의 의무)</h2>
  <p>
    ① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라
    지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
  </p>
  <p>
    ② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한
    보안 시스템을 갖추어야 합니다.
  </p>
  <p>
    ③ “몰”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를
    함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
  </p>
  <p>④ “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</p>

  <h2>제20조 (이용자의 의무)</h2>
  <p>이용자는 다음 행위를 하여서는 안 됩니다.</p>
  <ol>
    <li>신청 또는 변경시 허위 내용의 등록</li>
    <li>타인의 정보 도용</li>
    <li>“몰”에 게시된 정보의 변경</li>
    <li>“몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
    <li>“몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
    <li>“몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
    <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위</li>
  </ol>

  <h2>제21조 (연결“몰”과 피연결“몰” 간의 관계)</h2>
  <p>
    ① 상위 “몰”과 하위 “몰”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식
    등으로 연결된 경우, 전자를 연결 “몰”(웹 사이트)이라고 하고 후자를 피연결 “몰”(웹사이트)이라고 합니다.
  </p>
  <p>
    ② 연결“몰”은 피연결“몰”이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을
    지지 않는다는 뜻을 연결“몰”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한
    보증 책임을 지지 않습니다.
  </p>

  <h2>제22조 (저작권의 귀속 및 이용제한)</h2>
  <p>① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에 귀속합니다.</p>
  <p>
    ② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이 귀속된 정보를 “몰”의 사전 승낙 없이
    복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안
    됩니다.
  </p>
  <p>③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.</p>

  <h2>제23조 (회사의 면책)</h2>
  <p>
    ① “몰”은 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기술적 결함 기타 불가항력적
    사유로 서비스를 제공할 수 없는 경우에는 회사의 책임이 면제됩니다.
  </p>
  <p>
    ② “몰”은 회원의 귀책사유로 인한 서비스 이용 등의 중지, 이용장애 및 계약해지에 대하여는 회사의 책임이
    면제됩니다.
  </p>
  <p>
    ③ “몰”은 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를
    통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는
    그러하지 아니합니다.
  </p>
  <p>
    ④ “몰”은 회원 또는 제3자가 홈페이지 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성등에 대한
    내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 회사의 책임이 면제됩니다.
  </p>
  <p>
    ⑤ “몰”은 회원 상호간 또는 회원과 제3자간에 회사의 서비스 등을 매개로 발생한 분쟁에 대해 개입할 의무가
    없으며, 이로 인한 손해를 배상할 책임도 없습니다.
  </p>
  <p>
    ⑥ “몰”이 제공하는 무료서비스 등의 경우, 또는 회원이 회사가 제공하는 콘텐츠나 정보를 임의로 삭제한 경우,
    회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
  </p>

  <h2>제24조 (분쟁해결)</h2>
  <p>
    ① “몰”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
    곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
  </p>
  <p>
    ② “몰”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는
    공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
  </p>

  <h2>제25조 (재판권 및 준거법)</h2>
  <p>
    ① “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 관련 법령에 정한 절차에 따른 법원을 관할
    법원으로 합니다.
  </p>
  <p>② “몰”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</p>
`;

const nbox_terms_content_en = html`
  <h1>Terms of Service</h1>

  <h2>Article 1 (Purpose)</h2>

  <p>
    These terms and conditions aim to define the rights, obligations, and responsibilities of the cyber mall
    and its users when using the internet-related services (hereinafter referred to as "service") provided by
    'NBOX' (hereinafter referred to as "mall"), operated by Nexon Korea Corporation (hereinafter referred to
    as "the Company").
  </p>

  <h2>Article 2 (Definitions)</h2>

  <p>
    1. The "mall" refers to a virtual business place set up by the Company to provide goods or services
    (hereinafter referred to as "goods, etc.") to users through computer and other information communication
    facilities. It also means the business operator running the cyber mall.
  </p>
  <p>
    2. A "member" refers to an individual who has the authority to use all services provided by the mall as an
    employee of the Company.
  </p>

  <h2>Article 3 (Provision of Company Information)</h2>

  <p>
    The company displays the following items on its homepage or within its provided services for easy member
    access. However, item 6 can be viewed through a linked screen:
  </p>

  <ol>
    <li>Name and title of the representative</li>
    <li>Address of the business location (including an address where member complaints can be processed)</li>
    <li>Phone number and email address</li>
    <li>Business registration number</li>
    <li>Telecommunication business report number</li>
    <li>Terms of service</li>
  </ol>

  <h2>Article 4 (Effect, Application, and Change of the Terms)</h2>
  <p>
    1. The company announces these terms to members by posting them on the homepage operated by the company or
    providing a linked screen.
  </p>
  <p>
    2. Members are subject to these terms from their first connection to the mall, and if there are any
    changes to the terms, they will be subject to the revised terms from the effective date of the change.
  </p>
  <p>
    3. The company can change these terms when deemed necessary. When the terms change, the company announces
    the details and effective date online through the website or within the service 7 days before the
    implementation. However, if the changes are unfavorable to users, the announcement is made 30 days in
    advance in the same manner or by email registered during member registration. The revised terms come into
    effect from the announced or notified effective date.
  </p>
  <p>
    4. If a user continues to use the service after the effective date of the revised terms, they are
    considered to have agreed to the changed terms.
  </p>

  <h2>Article 5 (Regulations Outside the Terms)</h2>
  <p>
    Matters not specified in these terms and the interpretation of these terms are subject to individual
    service terms, guidelines, operational policies set by the company, and related laws such as the "Consumer
    Protection in Electronic Commerce Act," "Act on the Regulation of Terms and Conditions," "Electronic
    Document and Electronic Transaction Basic Act," "Electronic Financial Transactions Act," "Electronic
    Signature Act," "Act on the Promotion of Information and Communications Network Utilization and
    Information Protection," "Door-to-Door Sales Act," and "Basic Consumer Act," as long as they do not
    violate these terms.
  </p>

  <h2>Article 6 (Application for Use and Method)</h2>
  <p>
    1. After logging into the mall homepage through the internal domain, the company performs all management
    tasks for the member through the account, and the member can use the services according to the relevant
    laws, these terms, and operational policies.
  </p>
  <p>
    2. If necessary, after the member's application for use, the company can request additional information
    from the member for the provision of services or the adjustment of the scope of the provided services.
  </p>
  <p>
    3. When a member uses a service provided by the company in cooperation with a third party, they may need
    to agree to separate terms of service provided by the company or the third party. The content of the
    service, rights, obligations, etc., between the company, the third party, and the member, are determined
    by separate terms, operational policies, etc., provided by the company or the third party.
  </p>

  <h2>Article 7 (Provision and Change of Service)</h2>
  <p>1. The "mall" performs the following tasks:</p>
  <ul>
    <li>Providing information on goods or services and concluding a purchase contract.</li>
    <li>Delivering goods or services under a concluded purchase contract.</li>
    <li>Other tasks defined by the "mall".</li>
  </ul>
  <p>
    2. In cases of goods or service shortages or changes in technical specifications, the "mall" can change
    the content of the goods or services to be provided under future contracts. In such cases, the changes and
    their provision date are immediately announced where the current goods or services are listed.
  </p>
  <p>
    3. If the "mall" needs to change the content of a contracted service due to reasons like goods shortage or
    technical specification changes, the reason will be notified to the user.
  </p>

  <h2>Article 8 (Service Interruption)</h2>
  <p>
    1. "Mall" can temporarily suspend the service if there are reasons such as computer and other information
    communication equipment maintenance, replacement, malfunction, or communication disruption. In this case,
    the company will notify members. However, if there is a reason that the company cannot notify in advance,
    it can notify afterward.
  </p>
  <p>
    2. If the service cannot be provided due to reasons such as a change in business type, abandonment of
    business, or merger of companies, "Mall" will notify users according to the method specified in Article 8
    and compensate consumers according to the conditions initially set by "Mall". However, if "Mall" does not
    specify compensation standards, it will pay users in goods or cash equivalent to the currency value used
    in "Mall", such as mileage or points.
  </p>

  <h2>Article 9 (Notification to Members)</h2>
  <p>
    1. When "Mall" notifies members, it can be done via the email address previously agreed upon and
    designated between the member and "Mall".
  </p>
  <p>
    2. For notifications to an unspecified number of members, "Mall" can replace individual notifications by
    posting in a visible area within "Mall" for more than a week. However, individual notifications will be
    made for matters that significantly affect a member's transactions.
  </p>

  <h2>Article 10 (Purchase Application)</h2>
  <p>
    Users of "Mall" can apply for a purchase using the following or similar methods. "Mall" must provide the
    following information in an easily understandable way:
  </p>
  <ul>
    <li>Display and selection of goods</li>
    <li>Entry of recipient's name, address, phone number, email address (or mobile number)</li>
    <li>
      Confirmation of terms, limitation of right to cancel orders, costs such as shipping and installation
    </li>
    <li>Indication of agreement to these terms and confirmation or refusal of the information in 3.</li>
    <li>Application to purchase goods and confirmation or agreement to "Mall" confirmation</li>
    <li>Selection of payment method</li>
  </ul>

  <h2>Article 11 (Contract Establishment)</h2>
  <p>1. "Mall" may not accept a purchase application as described in Article 10 if:</p>
  <ul>
    <li>There are falsehoods, omissions, or mistakes in the application details.</li>
    <li>Accepting the purchase application is deemed significantly problematic socially or technically.</li>
  </ul>
  <p>
    2. The contract is considered established when the user receives the acceptance of "Mall" in the form of a
    confirmation of receipt as described in Article 13, Paragraph 1.
  </p>
  <p>
    3. The expression of acceptance by "Mall" must include information related to the confirmation of the
    user's purchase request, availability for sale, and correction or cancellation of the purchase request.
  </p>

  <h2>Article 12 (Payment and Purchase Safety Service)</h2>
  <p>
    Payments for goods or services purchased from "Mall" can be made using any of the following available
    methods. However, "Mall" cannot charge any fees for any reason on top of the price of goods or services:
  </p>
  <p>1. Various account transfers, including internet banking</p>
  <p>2. Various card payments, including prepaid, debit, and credit cards</p>
  <p>3. Online direct deposit without a passbook</p>
  <p>4. Payment by gift vouchers contracted with "Mall" or recognized by "Mall"</p>
  <p>5. Other electronic payment methods</p>

  <h2>Article 13 (Receipt Confirmation, Purchase Application Changes, and Cancellation)</h2>
  <p>"Mall" sends a receipt confirmation when there's a purchase application from a user.</p>
  <p>
    Users who receive a receipt confirmation can immediately request changes or cancellations if there are
    discrepancies in their intentions. If the user requests before delivery, "Mall" must process it without
    delay. However, if payment has already been made, it will follow the regulations in Article 16 regarding
    order cancellation.
  </p>

  <h2>Article 14 (Supply of Goods)</h2>
  <p>
    1. Unless there's a separate agreement with the user regarding the supply timing of goods, "Mall" must
    take necessary measures, such as order production and packaging, to deliver the goods within 7 days from
    the day the user made the order. However, if "Mall" has already received all or part of the payment for
    the goods, it must take action within 3 business days. In this case, "Mall" will take appropriate measures
    to allow the user to check the supply process and progress. But custom-made products will follow what's
    stated on their respective product pages.
  </p>
  <p>
    2. "Mall" must specify the delivery method, who bears the delivery cost for each method, and the delivery
    period for each method for the goods purchased by the user.
  </p>

  <h2>Article 15 (Refunds)</h2>
  <p>
    If the goods ordered by the user are out of stock or cannot be delivered or provided for any reason,
    "Mall" must notify the user without delay. If "Mall" has already received the payment for the goods, it
    must refund or take necessary measures for a refund within 3 business days from the day of payment.
  </p>

  <h2>Article 16 (Withdrawal of Offers, etc.)</h2>
  <p>
    1. Users who have entered into a contract with the "Mall" for the purchase of goods, etc. may withdraw
    their offer within 7 days from the date they received the written document regarding the contract content
    pursuant to Article 13 (2) of the Act on Consumer Protection in Electronic Commerce, etc. However, if
    there are different provisions in the Act on Consumer Protection in Electronic Commerce, etc. regarding
    the withdrawal of offers, the provisions of the Act shall prevail.
  </p>
  <p>
    2. Users cannot return or exchange the goods, etc. if they meet any of the following conditions after
    receiving the goods:
  </p>
  <ol>
    <li>
      a. The goods were destroyed or damaged due to a reason attributable to the user (however, the user can
      withdraw their offer if they damaged the packaging, etc. to check the contents of the goods).
    </li>
    <li>
      b. The value of the goods, etc. has significantly decreased due to the user's use or partial
      consumption.
    </li>
    <li>
      c. The value of the goods, etc. has significantly decreased to the point that resale is difficult due to
      the passage of time.
    </li>
    <li>
      d. If the original packaging of the goods, etc., which can be reproduced with the same performance, is
      damaged.
    </li>
    <li>
      e. In cases where acknowledging withdrawals or similar for products manufactured individually according
      to the order would result in significant, irreparable damage to the seller, provided this fact was
      communicated in advance for the transaction, and consent was obtained from the member in writing
      (including electronic documents).
    </li>
    <li>f. Other cases where return is restricted according to related laws.</li>
  </ol>
  <p>
    3. In the cases of numbers 2 to 4 in paragraph 2, if the “Mall” did not take measures such as specifying
    the fact that withdrawal of the offer is restricted in a place where consumers can easily be aware of it,
    or providing a trial product, restrictions on the user's withdrawal of the offer do not apply.
  </p>
  <p>
    4. Notwithstanding the provisions of paragraphs 1 and 2, if the content of the goods, etc. is different
    from the displayed or advertised content or is performed differently from the contract content, the user
    may withdraw the offer within 3 months from the date of receiving the goods, etc., or within 30 days from
    the date they knew or could have known the fact.
  </p>
  <p>
    5. If a member applies for an exchange under paragraph 1 or 4 but the seller does not have the stock to
    exchange, the member cannot receive an exchange of the goods, etc. In this case, the exchange application
    is processed as a return.
  </p>

  <h2>Article 17 (Effects of Withdrawal of Offer, etc.)</h2>
  <p>
    1. If the “Mall” receives the returned goods, etc. from the user, it shall refund the payment for the
    goods, etc. within 3 business days from the date of receipt. In this case, if the “Mall” delays the refund
    to the user, it shall pay delay interest calculated by multiplying the delay period by the delay interest
    rate specified in Article 21-2 of the Enforcement Decree of the Act on Consumer Protection in Electronic
    Commerce, etc.
  </p>
  <p>
    2. When refunding the fee, if the user has paid for the goods, etc. using a payment method such as a
    credit card or electronic currency, the “Mall” shall immediately request the business that provided the
    payment method to suspend or cancel the charge of the goods, etc.
  </p>
  <p>
    3. In the case of withdrawal of offer, etc., the user shall bear the expenses necessary for returning the
    goods, etc. The “Mall” does not charge the user any penalty or damages for the withdrawal of the offer,
    etc. However, if the content of the goods, etc. is different from the displayed or advertised content or
    is performed differently from the contract content, resulting in the withdrawal of the offer, etc., the
    “Mall” shall bear the expenses necessary for the return of the goods, etc.
  </p>
  <p>
    4. If the user has paid the shipping cost when receiving the goods, etc., the “Mall” shall clearly
    indicate who will bear the cost in the event of withdrawal of the offer so that the user can easily
    understand.
  </p>

  <h2>Article 18 (Protection and Use of Personal Information)</h2>
  <p>
    1. The “Company” strives to protect members' personal information as prescribed by related laws. The
    protection and use of members' personal information shall be governed by related laws and the company's
    separate privacy policy.
  </p>
  <p>
    2. The company shall not use personal information for purposes other than those notified in advance and
    shall immediately destroy the information in an irretrievable manner once the purposes are achieved.
  </p>
  <p>
    3. The company's privacy policy does not apply to third-party services linked from the company's website
    or individual service websites.
  </p>
  <p>
    4. Depending on the member's choice or the nature of the service, content entered by the member to
    introduce themselves, such as nicknames, product reviews, and status information, may be disclosed to
    other members.
  </p>
  <p>
    5. Members must diligently manage their personal information for the use of services and must update any
    changes. Any damage caused by the delay or omission in updating personal information is the responsibility
    of the member. The company is not responsible for the leakage of personal information due to reasons
    attributable to the member.
  </p>

  <h2>Article 19 (“Mall’s” Obligation)</h2>
  <p>
    1. The “Mall” shall not engage in any illegal or unethical activities and shall do its best to provide
    goods and services in a continuous and stable manner in accordance with these Terms and Conditions.
  </p>
  <p>
    2. The “Mall” must establish a security system to protect users' personal information (including credit
    information) so that users can use internet services safely.
  </p>
  <p>
    3. If a user suffers damage due to unfair labeling or advertising of goods or services by the “Mall”
    pursuant to Article 3 of the Fair Labeling and Advertising Act, the “Mall” is responsible for compensating
    for the damage.
  </p>
  <p>4. The “Mall” shall not send</p>

  <h2>Article 20 (User Obligations)</h2>
  <p>Users shall not engage in the following actions:</p>
  <ol>
    <li>Registering false information during application or modification.</li>
    <li>Impersonating someone else's information.</li>
    <li>Altering information posted on the "Mall."</li>
    <li>
      Sending or posting information other than the information designated by the "Mall" (such as computer
      programs).
    </li>
    <li>
      Infringing on the intellectual property rights of the "Mall" or any other third party, such as
      copyright.
    </li>
    <li>Defaming or interfering with the business of the "Mall" or any other third party.</li>
    <li>
      Posting or disclosing any obscene or violent messages, images, voices, or any other information against
      public morals on the Mall.
    </li>
  </ol>

  <h2>Article 21 (Relationship Between the Linked "Mall" and the "Mall" Being Linked)</h2>
  <p>
    1. If the upper "Mall" and lower "Mall" are connected in a hyperlink (e.g., text, pictures, and moving
    images), the former is referred to as the linked "Mall" (website) and the latter as the "Mall" being
    linked (website).
  </p>
  <p>
    2. The linked “Mall” does not guarantee the transactions between users and the products or services
    provided independently by the “Mall” being linked, provided that it explicitly states non-guarantee at the
    initial screen of the linked “Mall” or at the moment of linkage through a pop-up screen.
  </p>

  <h2>Article 22 (Ownership and Restriction of Use of Copyrights)</h2>
  <p>
    1. The "Mall" owns the copyright and other intellectual property rights for the works created by the
    "Mall."
  </p>
  <p>
    2. Users shall not use the information obtained from using the "Mall," which is copyrighted to the "Mall,"
    for any profit-making activities or provide it to third parties without prior consent from the "Mall."
  </p>
  <p>
    3. The “Mall” shall notify the user when using copyrights belonging to the user according to the
    agreement.
  </p>

  <h2>Article 23 (Company's Exemption Clause)</h2>
  <p>
    1. The "Mall" is not responsible for not being able to provide services due to reasons of display,
    accident, natural disaster, state of emergency, technical issues that are currently unsolvable, or other
    force majeure.
  </p>
  <p>
    2. The "Mall" is not responsible for service interruptions, disabilities, and contract terminations due to
    the user's fault.
  </p>
  <p>
    3. The "Mall" is not liable for the user's lost expected profits from using the service or for damages
    caused by the data obtained through the service, except in cases of the company's willful misconduct or
    gross negligence.
  </p>
  <p>
    4. The "Mall" is not responsible for the reliability and accuracy of information, materials, or facts
    posted or transmitted on the website by members or third parties, unless there is willful misconduct or
    gross negligence by the company.
  </p>
  <p>
    5. The "Mall" has no obligation to intervene in disputes arising between members or between members and
    third parties through the company's service, nor is it responsible for any damages resulting from such
    disputes.
  </p>
  <p>
    6. The "Mall" is not responsible for any free services provided or for any content or information that the
    user has arbitrarily deleted, except in cases of the company's willful misconduct or gross negligence.
  </p>

  <h2>Article 24 (Dispute Resolution)</h2>
  <p>
    1. The "Mall" will prioritize handling any complaints or suggestions submitted by users. However, if
    prompt processing is difficult, users will be immediately notified of the reason and the schedule for
    processing.
  </p>
  <p>
    2. In the event of a user's request for damage relief due to an e-commerce dispute between the "Mall" and
    the user, the adjustment by the Fair Trade Commission or the dispute settlement agency delegated by the
    metropolitan or provincial governor may be followed.
  </p>

  <h2>Article 25 (Jurisdiction and Governing Law)</h2>
  <p>
    1. Any lawsuit regarding e-commerce disputes between the "Mall" and the user shall be filed with the court
    according to the legal procedures.
  </p>
  <p>2. South Korean law shall apply to e-commerce lawsuits brought between the "Mall" and users.</p>
`;

const nbox_terms_content_jp = html`
  <h1>利用規約</h1>

  <h2>第1条（目的）</h2>
  <p>
    この規約は、(株)ネクソンコリア（以下、「会社」）が運営する「NBOX」（以下、「モール」とする）で提供するインターネット関連サービス（以下、「サービス」とする）を利用する際、サイバーモールと利用者の権利・義務および責任事項について定めることを目的とします。
  </p>

  <h2>第2条（定義）</h2>
  <p>
    ①
    「モール」とは、会社が財貨または役務（以下、「財貨等」とする）を利用者に提供するためにコンピュータ等、情報通信設備を利用して財貨等を取引できるように設定した仮想の営業所をいい、併せてサイバーモールを運営する事業者の意味でも使用します。
  </p>
  <p>
    ② 「会員」とは、会社の従業員として、モールで提供するすべてのサービス等を利用できる権利を持つ者を指します。
  </p>

  <h2>第3条（会社情報等の提供）</h2>
  <p>
    会社は、次の各号の事項を、会員が簡単に理解できるよう、ホームページや提供サービスなどに表示します。ただし、第6号は、会員が接続画面を通じて確認できるようにすることができます。
  </p>
  <ol>
    <li>商号および代表者の氏名</li>
    <li>営業所の所在地（会員のクレーム処理可能な場所の住所を含む。）</li>
    <li>電話番号、電子メールアドレス</li>
    <li>法人登録番号</li>
    <li>電子商取引業申告番号</li>
    <li>サービス利用規約</li>
  </ol>

  <h2>第4条（規約の有効性および適用と変更）</h2>
  <p>
    ①
    会社は、この規約の内容を会員が知ることができるよう、会社が運営するホームページに掲示したり、接続画面を提供する方法で会員に通知します。
  </p>
  <p>
    ②
    会員はモールに初めてアクセスした時点から規約が適用され、規約が変更される場合には変更の効力が発生した時点から変更後の規約が適用されます。
  </p>
  <p>
    ③
    会社は必要と認める場合に規約を変更することがあります。会社は規約が変更される場合、変更された規約の内容と実行日を指定して、その実行日から30日前にホームページまたはサービス内の画面を通じてオンラインで公告します。ただし、利用者に不利に規約の内容を変更する場合には実行日から30日前に本文と同じ方法または会員が会員登録時に提供した電子メールで通知します。変更された規約は公示または通知された実行日から効力を発生します。
  </p>
  <p>
    ④
    変更された利用規約の実行日以降もサービスを継続して利用する場合、変更された規約に同意したものとみなされる旨を第3項により一緒に公示または通知し、利用者が実行日以降にサービスを利用する場合は変更された規約に同意したものとみなします。
  </p>

  <h2>第5条（規約外の規則）</h2>

  <p>
    本規約で定められていない事項および規約の解釈に関しては、会社が定める個別のサービス利用規約、ガイドライン、運営ポリシーおよび「電子商取引等における消費者保護に関する法律」、「規約の規制に関する法律」、「電子文書及び電子取引基本法」、「電子金融取引法」、「電子署名法」、「情報通信網利用促進及び情報保護等に関する法律」、「訪問販売等に関する法律」、「消費者基本法」等、関連法に違反しない範囲でこの規約を改正することができます。
  </p>

  <h2>第6条（申し込みおよび方法）</h2>

  <p>
    ①
    会社は、社内ドメインを通じてモールホームページにログインした後、会社はアカウントを通じて会員に対する諸般の管理業務を遂行し、会員は関連法令、この規約および運営ポリシーに従ってサービスなどを利用できます。
  </p>

  <p>
    ②
    会社は、会員が利用申請後、サービスの提供や提供中のサービスの範囲調整のために必要な場合、会員に追加情報を要請することができます。
  </p>

  <p>
    ③
    会員が会社が第三者との協力を通じて提供するサービスを利用する場合、会社または第三者が提供する別途のサービス利用規約に対する同意が必要になる場合があります。
    当該サービスの内容、会社と第三者及び会員との間の各権利、義務などに関する事項は、当該サービスに対して会社や第三者が提供する別途の規約、運営ポリシー等で定められます。
  </p>

  <h2>第7条（サービス提供および変更）</h2>

  <p>① 「モール」は次の業務を行います。</p>

  <ol>
    <li>財貨又は役務に関する情報提供及び購買契約の締結</li>
    <li>購買契約が締結された財貨またはサービスの配送</li>
    <li>その他「モール」が定める業務</li>
  </ol>

  <p>
    ②
    「モール」は財貨またはサービスの品切れまたは技術的仕様の変更などの場合には、将来締結される契約によって提供する財貨またはサービスの内容を変更することができます。
    この場合は、変更された財貨またはサービスの内容および提供日を明示し、現在の財貨またはサービスの内容を掲示したところに直ちにお知らせします。
  </p>

  <p>
    ③
    「モール」が提供することで利用者と契約を締結したサービスの内容を財貨などの品切れまたは技術的仕様の変更などの理由で変更する場合には、その理由を利用者に通知します。
  </p>

  <h2>第8条（サービスの停止）</h2>

  <p>
    ①
    「モール」は、コンピュータなどの情報通信設備の補修点検、交換、故障、通信の途切れなどが発生した場合、サービスの提供を一時停止することができ、この場合、会社は会員に通知します。
    ただし、会社が事前に通知できない不可抗力の理由がある場合、後日通知することができます。
  </p>

  <p>
    ②
    事業種目の転換、事業の放棄、業者間の統合などの理由でサービスを提供できなくなった場合、「モール」は第8条に定める方法により利用者に通知し、当初「モール」が示した条件に従って消費者に補償します。
    ただし、「モール」が補償基準などを告知しなかった場合は、利用者のマイレージまたはポイントなどを「モール」で通用する通貨価値に相応する現物または現金で利用者に支給します。
  </p>

  <h2>第9条（会員への通知）</h2>
  <p>
    ①
    「モール」が会員に対する通知をする場合、会員が「モール」とあらかじめ約定して指定した電子メールアドレスにすることができます。
  </p>
  <p>
    ②
    「モール」は不特定多数の会員に対する通知の場合、1週間以上「モール」内の利用者がよく見られる領域に掲示することで個別通知に代わることができます。
    ただし、会員本人の取引に関して重大な影響を及ぼす事項については個別通知をします。
  </p>

  <h2>第10条（購入申込等）</h2>
  <p>
    「モール」利用者は、「モール」上で次の方法またはそれに類似する方法で購入を申し込み、「モール」は利用者が購入申し込みを行う際に次の各項目を分かりやすく提供する必要があります。
  </p>
  <ol>
    <li>1. 財貨等の展示及び選択</li>
    <li>2. 受取人の氏名、住所、電話番号、電子メールアドレス(または携帯電話番号)などの入力</li>
    <li>3. 規約内容、申込みの撤回権が制限されるサービス、配送料·設置費などの費用負担に関する内容の確認</li>
    <li>4. 本規約に同意し、上記3.号の事項を確認または拒否する表示(例、マウスクリック)</li>
    <li>5. 財貨などの購入申請及びこれに関する確認又は「モール」の確認に対する同意</li>
    <li>6. 決済方法の選択</li>
  </ol>

  <h2>第11条（契約の締結）</h2>
  <p>
    ① 「モール」は第10条と同じ購入申し込みに関して、以下の各号に該当する場合には承認しない場合があります。
  </p>
  <ol>
    <li>申請内容に虚偽、記載漏れ、誤りがある場合</li>
    <li>その他、購入申請に承諾することが社会通念上または技術的に著しく支障があると判断される場合</li>
  </ol>

  <p>
    ②
    「モール」の承認が第13条第1項の受信確認通知方式によって利用者に到達した時点で契約が成立したとみなされます。
  </p>

  <p>
    ③
    「モール」の承認の意思表示には、利用者の購入申請に対する確認および販売が可能かどうか、購入申請の訂正取り消しなどに関する情報などを含めなければなりません。
  </p>

  <h2>第12条（支払金額および購入安全サービス）</h2>
  <p>
    「モール」で購入した財貨またはサービスに対する代金の支払い方法は、次の各号の方法のうち利用可能な方法で行うことができます。
    ただし、「モール」は利用者の支払方法について財貨などの代金にいかなる名目の手数料も追加して徴収することはできません。
  </p>
  <ol>
    <li>インターネットバンキングなどの各種口座振替</li>
    <li>プリペイドカード、デビットカード、クレジットカードなどの各種カード決済</li>
    <li>オンライン振込</li>
    <li>「モール」と契約を結んだり、モールが認めた商品券による決済</li>
    <li>その他電子的支払方法による代金の支払等</li>
  </ol>

  <h2>第13条（受信確認通知、購入申し込みの変更および取り消し）</h2>
  <p>① 「モール」は利用者の購入申請がある場合、利用者に受信確認通知をします。</p>

  <p>
    ②
    受信確認通知を受けた利用者は、意思表示の不一致などがある場合には、受信確認通知を受けた後、直ちに購入申請の変更及び取り消しを要請することができ、「モール」は配送前に利用者の要請がある場合には遅滞なくその要請に応じて処理しなければなりません。
    ただし、すでに代金を支払った場合は、第16条の契約撤回などに関する規定に従います。
  </p>

  <h2>第14条（財貨等の供給）</h2>
  <p>
    ①
    「モール」は利用者と財貨などの供給時期に関して別途の規約がない以上、利用者が請約をした日から7日以内に財貨などを配送できるよう注文製作、包装などその他の必要な措置を取ります。
    ただし、「モール」がすでに財貨などの代金の全部または一部を受け取った場合には、代金の全部または一部を受け取った日から3営業日以内に措置を取ります。
    この時、「モール」は利用者が財貨などの供給手続きおよび進行事項を確認できるよう適切な措置を取ります。
    ただし、オーダーメイド商品の場合、商品ページなどで別途告知する内容に従います。
  </p>

  <p>
    ② 「モール」は利用者が購入した財貨に対して配送手段、手段別配送費用負担者、手段別配送期間などを明示します。
  </p>

  <h2>第15条（払い戻し）</h2>
  <p>
    「モール」は利用者が購入申請した財貨などが品切れなどの理由で引渡しまたは提供ができない場合には遅滞なくその理由を利用者に通知し、事前に財貨などの代金を受け取った場合には代金を受け取った日から3営業日以内に還付したり払い戻しに必要な措置を取ります。
  </p>

  <h2>第16条（契約の撤回等）</h2>
  <p>
    1.
    「モール」と財貨等の購入に関する契約を締結した利用者は、「電子商取引等における消費者保護に関する法律」第13条第2項による契約内容に関する書面を受けた日（その書面を受けた時より財貨等の供給が遅く行われた場合には財貨等の供給を受けたり財貨等の供給が始まった日をいいます）から7日以内には契約の撤回をすることができます。
    ただし、契約の申込みの撤回に関して「電子商取引等における消費者保護に関する法律」に別の定めがある場合には同法規定に従います。
  </p>

  <p>
    2. 利用者は財貨などを配送された場合、次の各号の1に該当する場合には返品及び交換をすることができません。
  </p>

  <p>
    3.
    第2項第2号から第4号の場合、「モール」が事前に契約の撤回などが制限される事実を消費者が容易に知ることができる場所に明記するか、試用商品を提供するなどの措置をしなかった場合、利用者の契約の撤回などは制限されません。
  </p>

  <p>
    4.
    利用者は第1項および第4項の規定にもかかわらず、財貨などの内容が表示·広告内容と違ったり契約内容と異なって履行された時には当該財貨などを供給された日から3か月以内、その事実を知った日または知ることができた日から30日以内に契約の撤回などができます。
  </p>

  <p>
    5.
    会員は第1項または第4項に規定により交換申請を行っても、販売者に交換する商品の在庫がない場合には、当該商品等の交換を受けることができません。
    この場合、当該交換申請は返品として処理されます。
  </p>

  <h2>第17条（契約の撤回等の効果）</h2>
  <p>
    1.
    「モール」は、利用者から財貨などを返還された場合、返還された日から3営業日以内にすでに支給された財貨などの代金を払い戻します。
    この場合、「モール」が利用者に財貨などの還付を遅延したときは、その遅延期間について「電子商取引等における消費者保護に関する法律施行令」第21条の2に定める遅延利子率を乗じて算定した遅延利子を支給します。
  </p>

  <p>
    2.
    「モール」は、上記の代金を還付するにあたり、利用者がクレジットカードまたは電子マネーなどの決済手段で財貨などの代金を支払ったときは、遅滞なく当該決済手段を提供した事業者に財貨などの代金の請求を停止または取り消すよう要請します。
  </p>

  <p>
    3. 契約の申込みの撤回などの場合、供給された財貨などの返還に必要な費用は利用者が負担します。
    「モール」は利用者に契約の申込みの撤回などを理由に違約金または損害賠償を請求しません。
    ただし財貨などの内容が表示·広告内容と違ったり契約内容と異なるように履行され契約撤回などをする場合、財貨などの返還に必要な費用は「モール」が負担します。
  </p>

  <p>
    4.
    利用者が財貨などを提供される際に発送費を負担した場合に「モール」は契約撤回時にその費用を誰が負担するのかを利用者が分かりやすいよう明確に表示します。
  </p>

  <h2>第18条（個人情報の保護および利用）</h2>
  <p>
    1.
    「会社」は関連法令に従い会員の個人情報を保護するために努力します。会員の個人情報の保護および利用については関連法令および会社が別途告知するプライバシーポリシーに従います。
  </p>
  <p>
    2. 会社は事前に告知した目的以外で個人情報を利用せず、目的達成時には直ちに再生不可能な方法で破棄します。
  </p>
  <p>
    3.
    会社のプライバシーポリシーは、ホームページや個別サービス別のウェブサイトからリンクされている第三者提供サービスには適用されません。
  </p>
  <p>
    4.
    会員の選択またはサービスの特性によって、会員が入力したニックネーム、製品レビュー、状態情報など、会員自らが自分を紹介する内容が他の会員に公開されることがあります。
  </p>
  <p>
    5.
    会員はサービスなどの利用のために自分の個人情報を誠実に管理しなければならず、個人情報の変更がある場合には変更しなければなりません。
    会員の個人情報変更が遅れたり漏れた場合に生じた損害は会員の責任とします。
    会社は会員の過失により流出した個人情報について一切の責任を負いません。
  </p>

  <h2>第19条（「モール」の義務）</h2>
  <p>
    1.
    「モール」は法令および本規約が禁じる行為または公序良俗に反する行為を行わず、本規約で定めるとおりに持続的かつ安定的に財貨·サービスを提供する最善の努力をします。
  </p>
  <p>
    2.
    「モール」は利用者がインターネットサービスを安全に利用できるように、利用者の個人情報（信用情報を含む）を保護するためのセキュリティシステムを備える必要があります。
  </p>
  <p>
    3.
    「モール」は商品またはサービスに関して「表示·広告の公正化に関する法律」第3条で定める不当な表示広告行為を行うことにより、利用者が損害を受けた場合はこれを賠償する責任を負います。
  </p>
  <p>4. 「モール」は、利用者が希望しない営利目的の広告メールを送信しません。</p>

  <h2>第20条（利用者の義務）</h2>
  <p>利用者は次の行為を行ってはなりません。</p>
  <ol>
    <li>申請または変更時に虚偽の内容の登録</li>
    <li>他人の情報盗用</li>
    <li>「モール」に掲示された情報の変更</li>
    <li>「モール」が定める情報以外の情報（コンピュータプログラム等）等の送信又は掲示</li>
    <li>「モール」その他の第三者の著作権など知的財産権に対する侵害</li>
    <li>「モール」その他の第三者の名誉を傷つけ、又は業務を妨害する行為</li>
    <li>
      わいせつ又は暴力的なメッセージ、画像、音声その他公序良俗に反する情報をモールに公開し、又は掲示する行為
    </li>
  </ol>

  <h2>第21条（接続「モール」と接続先「モール」間の関係）</h2>
  <p>
    1.
    上位「モール」と下位「モール」がハイパーリンク（例：ハイパーリンクの対象にはテキスト、イメージ、およびアニメーションなどが含まれる）方式などで接続された場合、前者を接続「モール」（ウェブサイト）とし、後者を接続先「モール」（ウェブサイト）とします。
  </p>
  <p>
    2.
    接続「モール」は接続先「モール」が独自に提供する財貨などによって利用者と行う取引に対して保証責任を負わないことを接続「モール」の初期画面または接続される時点のポップアップ画面で明示した場合、該当取引に対する保証責任を負いません。
  </p>

  <h2>第22条（著作権の帰属および使用制限）</h2>
  <p>1. 「モール」が作成した著作物に関する著作権およびその他の知的財産権は「モール」に帰属します。</p>
  <p>
    2.
    利用者は、「モール」を利用することによって得られた情報のうち、「モール」に知的財産権が帰属する情報を「モール」の事前承諾なしに複製、送信、出版、配布、放送その他の方法により営利目的で利用したり、第三者に利用させてはなりません。
  </p>
  <p>3. 「モール」は約定により利用者に帰属した著作権を使用する場合、当該利用者に通知しなければなりません。</p>

  <h2>第23条（会社の免責）</h2>
  <p>
    1.
    「モール」は展示、事変、天災地変、非常事態、現在の技術では解決できない技術的欠陥、その他不可抗力的理由でサービスを提供できない場合には会社の責任が免除されます。
  </p>
  <p>
    2.
    「モール」は会員の責に帰すべき事由によるサービス利用等の中止、利用障害及び契約解除については会社の責任が免除されます。
  </p>
  <p>
    3.
    「モール」は会員がサービスを利用して期待される収益を失ったことに対して責任を負わず、その他のサービスを通じて得た資料による損害に対して責任を負いません。
    ただし、会社の故意または重過失による場合は、この限りではありません。
  </p>
  <p>
    4.
    「モール」は会員または第三者がホームページ上に掲示または伝送した情報、資料、事実の信頼度、正確性などに対する内容に対しては会社の故意または重大な過失がない限り会社の責任が免除されます。
  </p>
  <p>
    5.
    「モール」は会員相互間または会員と第三者間で会社のサービスなどを媒介に発生した紛争に対して介入する義務がなく、これによる損害を賠償する責任もありません。
  </p>
  <p>
    6.
    「モール」が提供する無料サービスなどの場合、または会員が会社が提供するコンテンツや情報を任意に削除した場合、会社はこれに対して責任を負いません。
    ただし、会社の故意または重過失による場合は、この限りではありません。
  </p>

  <h2>第24条（紛争の解決）</h2>
  <p>
    1. 「モール」は利用者から提出される不満事項および意見は優先的にその事項を処理します。
    ただし、迅速な処理が困難な場合には、利用者にその理由と処理日程を直ちに通知します。
  </p>
  <p>
    2.
    「モール」と利用者間で発生した電子商取引紛争と関連して利用者の被害救済申請がある場合には公正取引委員会または市·道知事が依頼する紛争調停機関の調整に従うことができます。
  </p>

  <h2>第25条（裁判権及び準拠法）</h2>
  <p>
    1.
    「モール」と利用者の間で発生した電子商取引紛争に関する訴訟は、関連法令に定めた手続きに従った裁判所を管轄裁判所とします。
  </p>
  <p>2. 「モール」と利用者の間で提起された電子商取引訴訟には韓国法が適用されます。</p>
`;

export const nbox_terms_content = {
  kr: nbox_terms_content_kr,
  en: nbox_terms_content_en,
  jp: nbox_terms_content_jp,
};

export const nbox_terms = (lang) => html`
  <div class="nbox-terms-page">${nbox_terms_content?.[lang] || nbox_terms_content?.[ET.lang]}</div>
  <br />
`;

export const nbox_terms_popup = (lang) => html`
  <div class="terms_body">
    <div class="body">
      <div class="nbox-terms-page">${nbox_terms_content?.[lang] || nbox_terms_content?.[ET.lang]}</div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;
