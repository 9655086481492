import axios from 'axios';
import { CustomEventWithDetail, html, View } from 'rune-ts';
import { generateSearchUrl } from '../../../../features/Search/const/url';
import { SearchModuleData } from '../../../../features/Search/type';
import { makeSearchScreenNavigate } from '../../../../shared/app/navigate';
import { isApp, mpsPostMessage } from '../../../../shared/app/util';
import { typo } from '../../../../shared/typography/typo';
import { htmlIf } from '../../../../shared/util';
import { ButtonKeyword } from '../../atoms/ButtonKeyword/ButtonKeyword';
import { CloseIcon } from '../../atoms/Icon';
import { CurationListItemView } from '../CurationListItem/CurationListItem';
import { ProductCardList } from '../ProductCardList/ProductCardList';
import klass from './SearchModule.module.scss';
import {
  AutoCompleteListHideEvent,
  AutoCompleteListShowEvent,
  AutoCompleteSearchInput,
  EmptySubmitEvent,
  NotEmptySubmitEvent,
} from './view/AutoCompleteSearchInput';
import { RecentSearchesSection } from './view/RecentSearchesSection';
import { SearchApiS } from '../../../../features/Search/outbound/share';

export interface SearchData {
  name: string;
}

interface SearchModuleOption {
  klass?: string;
  without_visible_interaction?: boolean;
  is_mobile: boolean;
}

export class SearchModuleCloseEvent extends CustomEventWithDetail<string> {}

export class SearchModule extends View<SearchModuleData> {
  private onCloseCallback?: () => void;

  AutoCompleteSearchInputView: AutoCompleteSearchInput;
  RecentSearchesSectionView: RecentSearchesSection;

  constructor(data: SearchModuleData, private option: SearchModuleOption) {
    super(data, option);
    this.AutoCompleteSearchInputView = new AutoCompleteSearchInput({
      value: this.data.value,
      placeholder: this.data.placeholder,
      container_klass: klass.search_input,
      debounce_ms: 200,
      transparent: !option.is_mobile,
      off_reset: !option.is_mobile,
      placeholder_gray50: true,
    });
    this.RecentSearchesSectionView = new RecentSearchesSection({
      recent_searches: [],
      is_mobile: option.is_mobile,
    });
  }

  override template() {
    const _klass = this.option.without_visible_interaction
      ? klass.visible
      : `${klass.hidden} ${klass.hiding}`;
    const is_mobile = this.option.is_mobile;
    const theme = is_mobile ? 'light' : 'dark';
    const { popular_searches, recommended_products = [], curation } = this.data;

    return html`<div class="${klass.search_module} ${_klass} ${this.option.klass}">
      <div class="${klass.input_section}">
        ${this.AutoCompleteSearchInputView}
        ${htmlIf(html`<span class="${klass.close_icon}">${CloseIcon()}</span>`, is_mobile)}
      </div>

      <!-- 최근 검색어 -->
      ${this.RecentSearchesSectionView}

      <!-- 인기 검색어 -->
      <div class="${klass.popular_section}">
        <span class="${klass.title} ${typo('12_medium')}">${ET('mps2::search::popular_search')}</span>
        <div class="${klass.searches_container}">
          ${popular_searches.map(({ name }) => {
            return html`<div data-search="${name}" class="${klass.popular_search}">
              ${new ButtonKeyword({ name, theme })}
            </div>`;
          })}
        </div>
      </div>

      <!-- 가장 최근 큐레이션 -->
      <div class="${klass.curation_section}">
        <span class="${klass.title} ${typo('12_medium')}">${ET('mps2::search::curation')}</span>
        ${curation
          ? new CurationListItemView(
              { curation },
              {
                without_products: true,
                without_tags: true,
                without_category: true,
                thumbnail_type: 'short',
                is_mobile,
                theme,
              },
            )
          : ''}
      </div>

      <!-- 추천 상품 -->
      ${recommended_products.length
        ? html`<div class="${klass.recommend_section}">
            <span class="${klass.title} ${typo('12_medium')}">${ET('mps2::search::recommend')}</span>
            ${new ProductCardList(
              { products: recommended_products },
              {
                type: 'compact',
                resize_width: 500,
                container_klass: klass.products_container,
                klass: klass.product_card,
                is_mobile,
                hide_badges: is_mobile,
                hide_tag: is_mobile,
              },
            )}
          </div>`
        : ''}
    </div>`;
  }

  override async onRender() {
    // FIXME: api로 래핑
    const [{ data: recents }, { auto_completes }] = await Promise.all([
      axios.get<{ queries: string[] }>(`/${ET.lang}/@api/keywords/history`),
      SearchApiS.getAutoCompleteListApi({ search: this.data.value }),
    ]);

    this.AutoCompleteSearchInputView.setData({ auto_completes, value: this.data.value ?? '' });
    this.RecentSearchesSectionView.setData({
      recent_searches: recents.queries.map((search) => ({ name: search })),
    });
    this.redraw();

    if (auto_completes.length) {
      this.RecentSearchesSectionView.hide();
    } else {
      this.RecentSearchesSectionView.show();
    }
  }

  override onMount() {
    this.delegate(AutoCompleteListHideEvent, AutoCompleteSearchInput, () => {
      this.RecentSearchesSectionView.show();
    });

    this.delegate(AutoCompleteListShowEvent, AutoCompleteSearchInput, () => {
      this.RecentSearchesSectionView.hide();
    });

    this.delegate(EmptySubmitEvent, AutoCompleteSearchInput, () => {
      this.shake();
    });

    this.delegate('click', `.${klass.popular_search}`, (e) => {
      const el = e.currentTarget as HTMLElement;
      const search = el.dataset.search;
      if (search) {
        this.confirmSearch(search);
      }
    });

    this.delegate(NotEmptySubmitEvent, AutoCompleteSearchInput, (e) => {
      const search = e.detail;
      if (search) {
        this.confirmSearch(search);
      }
    });

    // esc key 로 닫기
    document.addEventListener('keydown', (e) => {
      if (e.isComposing) {
        return;
      }
      if (e.key === 'Escape' || e.key === 'Esc') {
        this.close();
      }
    });

    // mobile only
    if (this.option.is_mobile) {
      this.delegate('click', `.${klass.close_icon}`, () => {
        this.dispatchEvent(SearchModuleCloseEvent, {
          bubbles: true,
          detail: this.AutoCompleteSearchInputView.getInputValue(),
        });
      });
    }
  }

  open() {
    if (this.onCloseCallback) {
      this.removeEventListener('transitionend', this.onCloseCallback);
      this.onCloseCallback = undefined;
    }

    this.element().classList.remove(klass.hiding);
    this.element().classList.remove(klass.hidden);
    this.element().classList.add(klass.visible);
    this.AutoCompleteSearchInputView.focus();
  }

  close(): string {
    if (this.onCloseCallback) {
      this.removeEventListener('transitionend', this.onCloseCallback);
    }
    this.onCloseCallback = () => {
      this.element().classList.add(klass.hidden);
      if (this.onCloseCallback) {
        this.removeEventListener('transitionend', this.onCloseCallback);
        this.onCloseCallback = undefined;
      }
    };
    this.element().classList.remove(klass.visible);
    this.element().classList.add(klass.hiding);
    this.addEventListener('transitionend', this.onCloseCallback);

    return this.AutoCompleteSearchInputView.getInputValue();
  }

  shake() {
    this.element().classList.add(klass.shake);
    setTimeout(() => {
      this.element().classList.remove(klass.shake);
    }, 300);
  }

  // 검색어 최근 검색어에 추가하고, 결과로 이동
  private async confirmSearch(search: string) {
    const url = generateSearchUrl(search);
    this.AutoCompleteSearchInputView.setInputValue(search);

    // 최근 검색어 저장
    // TODO: @kjj feature로 이동
    await axios.post(`/${ET.lang}/@api/keywords/history`, null, {
      params: { q: search },
    });

    if (isApp()) {
      mpsPostMessage(makeSearchScreenNavigate(url));
    } else {
      location.href = url;
    }
  }
}
