import { html } from 'fxjs/es';

export const ness_terms = (content) => html`
  <div class="ness-terms-page__wrapper">
    <div class="ness-terms-page">${content}</div>
  </div>
`;

export const ness_terms_popup = (content) => html`
  <div class="ness-terms-popup terms_body">
    <div class="body">
      <div class="ness-terms-page">${content}</div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;

export const nessKr20231219 = () => html`
  <h1>넥슨에센셜(NESS) 사이트 이용약관</h1>
  <br />
  <h2>제1장 총칙</h2>
  <br />
  <h3>제1조 (목적)</h3>
  <p>
    이 약관은 ㈜넥슨코리아(이하 “회사”)가 제공하는 ‘넥슨에센셜(NEXON ESSENTIAL, 이하 ”NESS”)(<a
      href="https://essential.nexon.com"
      >https://essential.nexon.com</a
    >)’의 전자상거래 관련 서비스 및 기타 서비스(이하 “서비스”라 한다)를 이용함에 있어 “회사”와 “회원”의 권리,
    의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
  </p>
  <br />
  <h3>제2조 (정의)</h3>
  <p>① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
  <ol>
    <li>
      “몰”이란 “회사”가 상품 및 용역(이하 “상품 등” 이라 함)을 회원에게 제공하기 위하여 컴퓨터 등
      정보통신설비를 이용하여 “상품 등”을 거래할 수 있도록 설정한 가상의 영업장(<a
        href="https://essential.nexon.com"
        >https://essential.nexon.com</a
      >
      등 “회사”가 제공 또는 운영하는 웹사이트 및 모바일 웹, 앱 등을 모두 포함)을 의미하며, 아울러 ”몰”을
      운영하는 사업자의 의미로도 사용합니다.
    </li>
    <li>“이용자”라 함은 “몰”에 접속하여 “서비스”를 이용할 의사로 참여하는 회원 및 비회원을 의미합니다.</li>
    <li>
      “회원”이라 함은 이 약관에 동의하여 “회사”와 이용계약을 체결함으로써 회원등록을 하고, “몰”에서 제공하는
      “서비스”를 이용할 수 있는 권한을 획득한 자를 말하며, 다음 각 목의 “일반회원”과 “판매자 회원”을
      통칭합니다.
      <p>
        가. 일반회원: ‘넥슨닷컴(<a href="https://nexon.com">https://nexon.com</a>)’에 회원등록을 하고 이 약관
        제8조에 따라 회사와 “서비스” 이용계약을 체결하여 “몰”에서 제공하는 “서비스”를 이용하는 자
      </p>
      <p>
        나. 판매자(Seller) 회원: 회사가 정하는 별도의 가입절차를 통해 회원등록을 하고 판매자 이용약관에 따라
        회사와 “서비스” 이용계약을 체결한 사업자로서, “회사”가 통신판매중개를 위하여 마련한 “몰”의 “서비스”를
        이용하여 “상품 등”을 판매하는 자
      </p>
    </li>
    <li>
      “회사 IP”라 함은 “회사”가 개발 및/또는 서비스하는 게임의 명칭, 로고, 아이템, 배경화면, 캐릭터,
      캐릭터명(국문, 영문 및 기타 포함) 및 기타 일체의 콘텐츠에 대하여 “회사”가 보유하거나 라이선스를 허여
      받은 지적재산권을 의미합니다.
    </li>
    <li>
      “게시물”이라 함은 “회사” 또는 “회원”이 “몰”의 게시판 등에 등록한 부호, 문자, 음성, 음향, 화상, 동영상
      등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
    </li>
    <li>
      “할인쿠폰”이라 함은 상품 등을 구매하거나 기타 "몰"이 제공하는 "서비스"를 이용할 때 표시된 금액 또는
      비율만큼 할인 받을 수 있는 쿠폰을 의미합니다.
    </li>
  </ol>
  <p>
    ② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 개별 서비스에대한 이용약관
    또는 가이드라인, “운영정책” 및 관련 법령에 정하는 바에 따르며, 그 외의 경우는 일반적인 상관례에 따릅니다.
  </p>
  <br />
  <h3>제3조 (약관의 명시 및 개정)</h3>
  <p>
    ① “회사”는 이 약관의 내용 및 다음 각 호의 사항을 이용자가 알아보기 쉽도록 “몰”의 초기 화면, 각 “상품 등”의
    “서비스” 화면 내, 별도의 연결화면 또는 팝업화면 등으로 제공합니다.
  </p>
  <ol>
    <li>상호 및 대표자의 성명</li>
    <li>영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함)</li>
    <li>전화번호, 전자우편주소</li>
    <li>사업자등록번호</li>
    <li>통신판매업신고번호</li>
    <li>개인정보처리방침</li>
    <li>고객센터</li>
  </ol>
  <p>
    ② “회사”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「정보통신망 이용
    촉진 및 정보 보호 등에 관한 법률」, 「소비자기본법」 등 관계법령을 위배하지 않는 범위에서 이 약관을 개정할
    수 있습니다.
  </p>
  <p>
    <b
      >③ “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 개정 약관의 적용일자
      7일(다만, 회원에게 불리한 약관의 개정은 30일) 전부터 적용일자 전일까지 공지합니다.</b
    >
  </p>
  <p>
    ④ “회원”은 변경된 약관에 대해 거부할 권리가 있습니다. 이 약관의 변경에 대해 이의가 있는 이용자는 서비스
    이용을 중단하고 회원탈퇴를 할 수 있습니다. 변경된 이용약관의 시행일 이후에도 서비스를 계속 이용하는
    경우에는 변경된 약관에 동의한 것으로 간주된다는 내용을 제3항에 따라 함께 공시 또는 고지하고, 이용자가
    시행일 이후 서비스를 이용하는 경우 개정된 약관에 동의한 것으로 봅니다.
  </p>
  <br />
  <h3>제4조 (약관의 적용 및 약관 외 준칙)</h3>
  <p>
    ① “몰”의 서비스 이용과 관련하여는 이 약관이 ‘넥슨닷컴’에 제공되는 “넥슨 통합 서비스 이용약관” 등에
    우선하여 적용됩니다.
  </p>
  <p>
    ② 개별 서비스에서 별도로 적용되는 약관에 대한 동의는 회원이 해당 개별 서비스를 최초로 이용할 경우 별도의
    동의절차를 거치게 되며, 이 경우 개별 서비스에 대한 이용약관 등이 이 약관에 우선합니다.
  </p>
  <p>
    ③ 이 약관에서 제1장은 “몰”의 서비스 일반에 적용되며, 제2장 및 제3장은 “회사”가 제공하는 각
    전자상거래서비스 또는 통신판매중개서비스에 국한하여 적용됩니다. 이 중 어느 규정이 상충할 때에는 개별
    서비스를 비롯한 통신판매중개서비스 관련 규정이 보다 우선적인 효력을 갖습니다.
  </p>
  <p>
    ④ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 “몰”의 개별 서비스에 대한 별도의 이용약관,
    서비스 이용 가이드라인, “운영정책” 및 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한
    법률」 등 관련 법령 및 상관례에 따릅니다.
  </p>
  <br />
  <h3>제5조 (운영정책)</h3>
  <p>
    ① “회사”는 이 약관을 적용하기 위해 필요한 사항과 “회원”의 권익을 보호하고 “서비스” 내 질서를 유지하기
    위하여 이 약관에서 구체적인 범위를 정하여 위임한 사항을 별도 정책 또는 가이드라인 등(이하 “운영정책”)으로
    정할 수 있습니다.
  </p>
  <p>
    ② “회사”는 “운영정책”의 내용을 “회원”이 알 수 있도록 홈페이지나 “서비스” 내 화면에 게시하거나 그 연결
    화면을 제공하는 방법으로 공지하여야 합니다.
  </p>
  <p>
    <b
      >③ “회원”의 권리 또는 의무에 중대한 변경을 가져오거나 약관 내용을 변경하는 것과 동일한 효력이 발생하는
      “운영정책” 개정의 경우에는 제3조 제3항의 절차에 따릅니다. 단, “운영정책” 개정이 다음 각 호의 어느 하나에
      해당하거나, 약관의 내용을 변경하는 것과 동일한 효력이 발생하는 경우가 아닌 경우에는 본 조 제2항의
      방법으로 사전에 공지한 후 개정 “운영정책”을 적용합니다.</b
    >
  </p>
  <ol>
    <li><b>약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우</b></li>
    <li><b>“회원”의 권리, 의무와 관련 없는 사항을 개정하는 경우</b></li>
    <li>
      <b
        >“운영정책”의 내용을 약관에서 정한 내용과 근본적으로 다르지 않고 “회원”이 예측 가능한 범위 내에서
        개정하는 경우</b
      >
    </li>
  </ol>
  <br />
  <h3>제6조 (서비스의 제공 및 변경)</h3>
  <p>① “몰”은 다음 각 호의 서비스를 제공합니다.</p>
  <ol>
    <li>상품 등에 대한 정보 제공 및 구매 계약의 체결</li>
    <li>구매 계약이 체결된 상품 등의 배송</li>
    <li>상품 등의 거래를 위한 통신판매중개서비스 및 이에 수반되는 서비스</li>
    <li>“몰” 내 상품 등 맞춤형 추천 서비스</li>
    <li>기타 "몰"이 정하는 서비스 또는 업무</li>
  </ol>
  <p>
    ② “몰”은 상품 등의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 상품 등의
    내용을 변경할 수 있습니다. 이 경우에는 변경된 내용 및 제공일자를 명시하여 현재의 상품 등의 내용을 게시한
    곳에 공지합니다.
  </p>
  <p>
    ③ “몰”이 제공하기로 회원과 계약을 체결한 서비스의 내용을 상품 등의 품절 또는 기술적 사양의 변경 등의
    사유로 변경할 경우에는 그 사유를 회원에게 통지 가능한 주소로 즉시 통지합니다.
  </p>
  <br />
  <h3>제7조 (서비스의 중단)</h3>
  <p>
    <b
      >① “몰”은 천재지변 또는 이에 준하는 불가항력, 컴퓨터 등 전기통신설비의 보수점검, 교체 및 고장, 통신의
      두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있으며, 이 경우 “회사”는
      “회원”에게 통지합니다. 다만, “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
      있습니다.</b
    >
  </p>
  <p>
    <b
      >② 사업 종목의 전환, 사업의 종료 또는 포기, 업체 간의 통합, 폐업, 합병, 분할, 영업양도 등의 이유로
      서비스를 제공할 수 없게 되는 경우에 “몰”은 제10조에 정한 방법으로 회원에게 통지하고 당초 “몰”에서 제시한
      조건에 따라 회원에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 회원의 포인트 또는
      적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 회원에게 지급합니다.</b
    >
  </p>
  <br />
  <h3>제8조 (회원가입 등)</h3>
  <p>
    ① “몰”의 “회원”으로 “서비스”를 이용하고자 하는 이용자는 넥슨닷컴(<a href="https://nexon.com"
      >https://nexon.com</a
    >) 회원가입 또는 넥슨닷컴 ID를 이용한 로그인을 한 뒤 “몰”에서 정한 절차에 따라 필요한 정보를 기입한 후 이
    약관에 동의한다는 의사표시(이하 “이용신청”)를 하여야 합니다. “회사”는 “몰”의 “회원”에 대한 제반 관리
    업무를 수행하며, “회원”은 관련 법령, 이용약관 및 “운영정책”에 따라 “서비스” 등을 이용할 수 있습니다.
  </p>
  <p>
    <b
      >② “몰”은 제1항과 같이 “회원”으로 “이용신청”한 이용자 중 다음 각 호에 해당하는 경우 해당 이용신청을
      승낙하지 않거나 사후 이용계약을 해지할 수 있습니다. 만약 “몰”이 이용신청의 승인을 거부하거나 유보하는
      경우 이를 제10조에 따라 통지합니다.</b
    >
  </p>
  <ol>
    <li><b>이용신청자가 기입한 정보에 허위, 기재 누락 또는 오기가 있는 경우</b></li>
    <li><b>“몰”에서 본인확인 절차를 실시할 경우 본인 확인이 되지 않거나 본인이 아님이 확인된 경우</b></li>
    <li><b>이용신청자가 만 14세 미만의 아동으로 법정대리인의 동의를 받지 않은 경우</b></li>
    <li><b>이용신청자가 이 약관 제9조 제2항에 의하여 “회원”으로서 이용자격을 상실한 적이 있는 경우</b></li>
    <li><b>“회원”의 요청에 의하여 탈퇴한 때로부터 1개월이 지나지 아니한 경우</b></li>
    <li>
      <b
        >기타 이용신청자를 회원으로 등록하는 것이 “몰”의 기술상 불가능하거나 운영 또는 유지, 관리에 현저한
        지장을 준다고 판단되는 경우</b
      >
    </li>
  </ol>
  <p>
    <b
      >③ “몰”은 이용자의 이용신청 이후 “서비스”의 제공이나 제공 중인 “서비스”의 범위 조정을 위하여 필요한 경우
      이용자에게 추가 정보를 요청할 수 있습니다.</b
    >
  </p>
  <p>
    ④ “서비스” 이용계약의 성립시기는 “이용신청”에 대한 “회사”의 승낙이 이용신청자에게 도달한 시점으로 합니다.
  </p>
  <p>
    <b
      >⑤ “회원”은 회원정보에 변경이 있는 경우 회원정보 수정 등의 방법으로 그 변경사항을 반영하거나 “몰”에
      알려야 하며, 수정하지 않음으로써 발생하는 “회원”의 손해에 대하여 “회사” 및 “몰”은 아무런 책임을 지지
      않습니다.</b
    >
  </p>
  <p>
    <b
      >⑥ “회사”는 서비스의 안전한 제공을 위하여 “몰”의 결제 관련 서비스를 제공하는 자를 통해 본인확인이 완료된
      “회원”에 대해서만 이용신청을 승인하거나, 본인확인이 이루어지지 않은 “회원”에 대해서는 서비스 이용 또는
      상품 등의 구매를 제한할 수 있습니다.</b
    >
  </p>
  <br />
  <h3>제9조 (회원탈퇴 등)</h3>
  <p>
    <b
      >① “회원”은 “몰”에 언제든지 탈퇴를 요청할 수 있으며, “몰”은 즉시 회원탈퇴를 처리합니다. “회원”은 탈퇴
      시점에 서비스 이용 자격이 상실되며, “몰”에서 제공한 각종 할인쿠폰, 이벤트 혜택 등이 소멸됩니다. 다만,
      “몰”의 탈퇴 처리시점에 “상품 등”의 구매 절차가 완료되지 않은 경우 “몰”은 해당 “상품 등”의 구매가 완료된
      후 탈퇴를 처리하고, 소진되지 않은 포인트 등이 있는 경우에는 탈퇴를 요청한 “회원”이 이에 대한 권리를
      포기한 것으로 간주하여 탈퇴처리와 동시에 즉시 전부 소멸됩니다.</b
    >
  </p>
  <p>
    <b>② “회원”이 다음 각 호의 사유에 해당하는 경우 “몰”은 “회원”의 자격을 제한 또는 상실시킬 수 있습니다.</b>
  </p>
  <ol>
    <li>
      <b
        >회원정보 또는 기타 “몰”의 서비스 이용에 필요한 정보에 악의적으로 허위 내용을 입력하거나 타인의 정보를
        도용한 경우</b
      >
    </li>
    <li>
      <b
        >“판매자 회원”이 실제로 사업을 영위하고 있지 않거나, 사업의 영위를 중단(휴업/폐업 등 모두
        포함)하였음에도 불구하고 계속하여 “판매자 회원”으로서 이용하는 경우</b
      >
    </li>
    <li>
      <b
        >“몰”을 이용하여 구입한 “상품 등”의 대금, 기타 서비스 이용과 관련하여 “회원”이 부담하는 채무를 기일에
        지급하지 않는 경우</b
      >
    </li>
    <li>
      <b>다른 사람의 “몰” 이용을 방해하거나 정보를 탈취, 도용하는 등 전자상거래 질서를 위협하는 경우</b>
    </li>
    <li>
      <b
        >“회원”의 귀책사유로 인하여 전자상거래 등 서비스 이용에 필요한 주소, 연락처, 전자우편주소 등
        회원정보를 잘못 입력하거나 변경하지 않는 등의 경우 “몰”이 정상적인 서비스 제공을 위하여 상당한 노력을
        기울였음에도 불구하고 “회원”에게 통지 또는 연락을 할 수 없게 된 경우</b
      >
    </li>
    <li><b>이 약관 제13조에 정한 이용자의 의무를 위반하는 행위를 한 경우</b></li>
    <li>
      <b
        >이 약관의 규정 또는 “운영정책” 등을 위반하거나 법령 기타 공서양속에 반하는 행위를 하는 경우(예를 들어
        “몰”의 직원에게 폭언, 협박 또는 음란한 언행 등을 하는 행위로 이에 국한되지 않음)</b
      >
    </li>
    <li>
      <b
        >기타 “몰”의 서비스 운영을 고의로 방해하는 행위를 하는 경우(예를 들어 “상품 등”을 구매한 후 정당한
        이유 없이 상습 또는 반복적으로 취소, 반품하여 업무를 방해하는 행위로 이에 국한되지 않음)</b
      >
    </li>
  </ol>
  <p>
    <b
      >③ “몰”이 “회원”으로서 서비스 이용자격을 제한 또는 정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일
      이내에 그 사유가 시정되지 아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.</b
    >
  </p>
  <p>
    <b
      >④ “몰”이 회원자격을 상실시키는 경우에는 회원등록이 말소되며, 거래 안전을 위하여 해당 “회원”의 소유로
      확인되는 아이디의 회원 등록을 일괄 말소할 수 있습니다. 이 경우 “몰”은 이를 제10조에 따라 “회원”에게
      통지하고, 회원등록 말소 전 최소 30일의 기간을 정하여 소명할 기회를 부여합니다.</b
    >
  </p>
  <br />
  <h3>제10조(회원에 대한 통지 및 정보 제공)</h3>
  <p>
    ① “몰”이 “회원”에 대한 통지를 하는 경우, 이 약관에 별도 규정이 없는 한 “회원”이 “이용신청” 시점 또는 제8조
    제5항에 따른 정보 변경시점에 지정한 전자우편, 전자쪽지, SMS, 카카오 알림톡, 앱푸쉬 알림 등의 방법으로 할
    수 있습니다.
  </p>
  <p>
    ② “몰”은 불특정다수 “회원”에 대한 통지의 경우 1주일 이상 “몰” 내 게시판에 게시함으로써 개별 통지에 갈음할
    수 있습니다. 다만, “회원” 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
  </p>
  <p>
    ③ “몰”은 “회원”이 “서비스” 이용 중 필요하다고 인정되는 다양한 정보를 게시판에 공지하거나 전자우편,
    전자쪽지, 전화, SMS, 카카오 알림톡, 앱푸쉬 알림 등의 방법으로 “회원”에게 제공할 수 있습니다. 단, “회원”은
    법령에 따른 의무이행을 위한 절차 안내, 고객문의 등에 대한 답변, 기타 거래 관련 정보 등을 제외하고 제공
    방법 및 수신거절 여부를 지정할 수 있습니다.
  </p>
  <p>
    ④ “몰”은 “서비스” 운영과 관련한 광고를 서비스 화면 및 각종 게시판에 게재하거나 전항의 방법으로 제공할 수
    있습니다. 단, “회원”은 제공 방법 및 수신거절 여부를 지정할 수 있습니다.
  </p>
  <br />
  <h3>제11조 (“몰”의 의무)</h3>
  <p>
    ① “몰”은 관련 법령 기타 공서양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라 지속적이고,
    안정적으로 서비스를 제공하는 데 최선을 다하여야 합니다.
  </p>
  <p>
    ② “몰”은 “회원”이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위해 보안시스템을
    갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
  </p>
  <p>
    ③ “몰”이 상품 등에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시, 광고행위를 함으로써
    “회원”이 손해를 입은 때에는 이를 배상할 책임을 집니다.
  </p>
  <p>④ “몰”은 “회원”이 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</p>
  <p>
    ⑤ “몰”은 관련 법령, “몰” 내 이용약관, “운영정책” 및 개인정보처리방침에서 정한 경우를 제외하고는, “회원”의
    개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
  </p>
  <p>
    ⑥ “몰”은 설비에 장애가 생기거나 데이터 등이 멸실·훼손되는 등으로 “서비스” 제공에 장애가 발생한 때에는
    천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를
    수리 또는 복구하도록 최선의 노력을 다합니다.
  </p>
  <p>
    ⑦ “몰”은 “회원”으로부터 제기되는 의견이나 불만이 객관적으로 정당하다고 인정될 경우에는 즉시 처리하기 위해
    노력하여야 합니다. 다만, 즉시 처리가 곤란한 경우에는 “회원”이 기입한 전화번호 또는 전자우편주소로 그
    지연사유와 처리일정을 통보할 수 있습니다.
  </p>
  <br />
  <h3>제12조 (회원의 아이디 및 비밀번호에 대한 의무)</h3>
  <p>① 제15조의 경우를 제외하고 “회원”의 아이디와 비밀번호에 관한 관리책임은 “회원”에게 있습니다.</p>
  <p>
    ② “회원”은 자신의 아이디 및 비밀번호를 제3자에게 제공, 공개하거나 제3자에게 이용을 승낙함으로써 발생하는
    손해에 대한 책임은 “회원”에게 있습니다.
  </p>
  <p>
    <b
      >③ “회원”이 자신의 아이디 및 비밀번호의 유출 또는 제3자 사용에 대해 인지하고도 비밀번호를 변경하지 않은
      경우 또는 이와 같은 사정을 “몰”에 통지하지 않거나 “몰”의 조치에 응하지 않은 경우 등 “회원”이 본 조의
      의무를 준수하지 않아 발생하는 손실이나 손해 등 불이익에 대한 책임은 “회원”에게 있습니다.</b
    >
  </p>
  <p>
    ④ “몰”은 “회원”의 아이디 및 비밀번호가 외부에 누출되었다고 의심되는 경우 개인정보 유출 등을 방지하기
    위하여 “서비스” 이용을 일부 제한하는 계정 잠금 및 기타 보호조치를 취할 수 있습니다.
  </p>
  <br />
  <h3>제13조 (이용자의 의무)</h3>
  <p>
    <b
      >① 이용자는 관련 법령, “몰” 내 이용약관, “운영정책” 등 “회사”가 통지하는 사항을 준수하여야 하며, 기타
      “회사” 업무에 방해되는 행위를 하여서는 안됩니다.</b
    >
  </p>
  <p><b>② 이용자는 “서비스” 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.</b></p>
  <ol>
    <li><b>이용신청 또는 계정·이용정보 변경 시 허위 내용을 등록, 신청, 변경하는 행위</b></li>
    <li>
      <b
        >전자상거래에 필요한 본인확인(또는 법정대리인의 동의) 절차를 정상적으로 진행하지 않거나 타인의 명의
        또는 카드정보 등을 도용하여 상품 등을 구입하거나 구입 등을 가장하는 형태로 “몰”의 서비스를 이용하는
        행위</b
      >
    </li>
    <li>
      <b
        >“몰”이 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법(컴퓨터 프로그램 포함)으로 서비스를
        이용하거나 시스템에 접속 또는 정보처리시스템에 접근하는 행위</b
      >
    </li>
    <li>
      <b
        >“몰”이 제공하는 서비스를 이용하지 아니하고 판매자와 직접 거래하는 행위(직거래)또는 이를 유도하는
        행위</b
      >
    </li>
    <li>
      <b
        >“몰” 또는 기타 판매자의 판매 활동을 방해하거나 “몰”을 이용하여 부당한 이득을 취하는등 통상적인
        전자상거래 관행에 부합하지 않는 거래상 부정 행위</b
      >
    </li>
    <li>
      <b>“상품 등”의 판매 또는 제공을 가장한 현금 융통 등 관계 법령에 위배되거나 비정상적인 결제 행위</b>
    </li>
    <li>
      <b
        >"몰”의 서비스를 이용하여 대한민국 및 기타 국가 또는 국제기구가 시행 및 집행하는 제재조치, 수출입 통제
        법령이나 규칙, 제한 등을 위반하는 행위</b
      >
    </li>
    <li>
      <b
        >“몰”의 서비스를 이용하여 제공받은 상품, 소프트웨어, 기술 서비스 등을 수출입 제재 대상국가 혹은 제재
        대상자 및 조직에 재수출 혹은 재판매하는 행위</b
      >
    </li>
    <li>
      <b
        >“몰”에 게시된 정보를 임의로 변경, 조작하는 행위 또는 “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등)
        등의 송신 또는 게시하는 행위</b
      >
    </li>
    <li><b>“몰”, “회사 IP”, 기타 제3자의 지적재산권을 침해하는 행위</b></li>
    <li>
      <b
        >“몰” 기타 제3자의 명예를 손상시키거나 객관적으로 정당한 이유 없이 상습 또는 반복적으로 거래를 취소,
        반품하는 등과 같이 비합리적인 요청, 기타 공서양속에 반하는 행위 등으로 업무를 방해하는 행위</b
      >
    </li>
    <li>
      <b
        >외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “몰”에 공개 또는 게시하거나
        “몰”의 직원에게 폭언, 협박 또는 음란한 언행 등을 하는 행위</b
      >
    </li>
    <li>
      <b
        >기타 위 각호에 준하여 “몰” 기타 제3자의 권리를 침해 또는 침해할 우려가 있거나, 전자상거래 질서를
        위협하는 행위</b
      >
    </li>
    <li><b>기타 “몰” 내에서 개별 서비스 이용약관 및 “운영정책”, 가이드라인 등을 위반하는 행위</b></li>
  </ol>
  <p>
    <b
      >③ “몰”은 제2항을 위반한 이용자에 대하여 이용 및 회원자격의 제한, 정지 또는 상실, 할인쿠폰 및 포인트
      등의 환수, 교환 또는 현금 환불 보류 등의 조치 및 민·형사상 법적 조치 등을 취할 수 있습니다.</b
    >
  </p>
  <br />
  <h3>제14조 (할인쿠폰의 이용)</h3>
  <p>
    ① 할인쿠폰은 "몰"에서 발행하는 쿠폰으로 종류 및 내용과 사용처, 발급 여부는 "몰"의 서비스 정책에 따라
    변경될 수 있습니다.
  </p>
  <p>
    <b
      >② 할인쿠폰은 현금으로 출금될 수 없으며, 쿠폰에 표시된 유효기간이 만료되거나 이용계약의 종료, 회원자격이
      상실되면 소멸하고, 원칙적으로 "상품 등"의 구매 후 취소나 반품으로 환불이 이루어졌을 때에는 쿠폰을
      재사용할 수 없습니다.</b
    >
  </p>
  <p>
    ③ 할인쿠폰은 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 부정한 목적이나 용도로 사용할 수
    없습니다. 이를 위반한 경우 회사는 할인쿠폰을 소멸시키거나 회원자격을 제한 또는 상실시킬 수 있습니다.
  </p>
  <p>
    ④ 회원이 부정한 방법으로 할인쿠폰을 획득한 사실이 확인될 경우, 회사는 이를 회수하고, 회원자격 상실 및
    민∙형사 상의 법적 조치 등을 취할 수 있습니다.
  </p>
  <p>
    ⑤ 할인쿠폰은 기본적으로 구매 단위별로 중복으로 사용할 수 없으나, 할인쿠폰의 종류와 내용에 따라 "몰" 또는
    판매자가 중복사용 허용 등 사용범위를 달리 정한 경우에는 그러하지 않습니다.
  </p>
  <br />
  <h3>제15조 (개인정보 보호)</h3>
  <p>
    ① “회사”는 관련 법령이 정하는 바에 따라 “이용자”의 개인정보를 보호하기 위해 노력합니다. “이용자”
    개인정보의 보호 및 이용에 대해서는 관련 법령 및 “회사”가 별도로 공지하는 개인정보처리방침에 따릅니다.
  </p>
  <p>
    ② “회사”는 사전에 고지한 목적 외로 개인정보를 이용하지 않으며, 목적 달성 시 즉시 재생이 불가능한 방법으로
    파기합니다.
  </p>
  <p>
    ③ “회사”의 개인정보처리방침은 홈페이지나 개별 서비스 별 웹사이트에서 링크되어 있는 제3자 제공 서비스에
    대해서는 적용되지 않습니다.
  </p>
  <p>
    ④ “이용자”의 선택 또는 “서비스”의 특성에 따라, “이용자” 스스로가 자신을 소개하는 내용이 다른 “이용자”에게
    공개될 수 있습니다.
  </p>
  <p>
    <b
      >⑤ “이용자”는 “서비스” 등의 이용을 위하여 자신의 개인정보를 성실히 관리해야 하며 개인정보의 변동 사항이
      있을 경우 이를 변경해야 합니다. “이용자”의 개인정보 변경이 지연되거나 누락되어 발생하는 손해는
      “이용자”의 책임으로 합니다. “회사”는 “이용자”의 귀책사유로 인하여 유출된 개인정보에 대해서는 일체의
      책임을 지지 않습니다.</b
    >
  </p>
  <br />
  <h3>제16조 (연결 웹사이트와 피연결 웹사이트 간의 관계)</h3>
  <p>
    ① 상위 웹사이트(즉, “몰”)과 하위 웹사이트가 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이
    포함됨) 방식 등으로 연결된 경우, 전자를 연결 웹사이트라고 하고 후자를 피연결 웹사이트라고 합니다.
  </p>
  <p>② 연결 웹사이트는 피연결 웹사이트에서 발생한 거래에 대해서 보증 책임을 지지 않습니다.</p>
  <br />
  <h3>제17조 (저작권의 귀속 및 이용 제한)</h3>
  <p>① “몰”에서 작성한 저작물에 대한 저작권 기타 지적재산권은 “회사”에 귀속합니다.</p>
  <p>
    <b
      >② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이 귀속된 정보 또는 “회사IP”를 “회사”의
      사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게
      이용하게 하여서는 안 됩니다.</b
    >
  </p>
  <p>
    <b
      >③ 이용자가 “몰”의 서비스를 이용하면서 작성한 리뷰, 문의 등 게시물의 저작권은 해당 이용자 본인에게
      있으며, 해당 게시물이 타인의 저작권 및 기타 지적재산권을 침해하는 경우 그에 대한 책임은 이용자 본인이
      부담합니다.</b
    >
  </p>
  <p>
    ④ “몰”은 제3항에 따라 이용자가 작성한 게시물을 “몰” 및 “몰”에서 거래하는 상품 홍보 등을 위해 사용할 수
    있으며, “몰”은 이용자의 게시물을 사용하는 경우 해당 이용자에게 그 사실을 통보합니다.
  </p>
  <p>
    <b
      >⑤ 이용자는 자신이 서비스 내에 게시한 게시물 또는 2차 창작하거나 기획·판매한 상품 등이 타인의 저작권,
      프로그램 저작권 등을 침해하였음을 이유로 "몰"이 타인으로부터 권리 침해 관련 손해배상청구 등의 이의
      제기를 받은 경우 "몰"의 면책을 위하여 노력하여야 하며, "몰"이 면책되지 못한 경우 이용자는 그로 인해
      "몰"에 발생한 모든 손해를 부담하여야 합니다.</b
    >
  </p>
  <p>
    <b
      >⑥ "몰"은 이용자가 서비스 내에 게시한 게시물이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지
      없이 삭제, 변경할 수 있으며, 이에 대해 "몰"은 어떠한 책임도 지지 않습니다.</b
    >
  </p>
  <ol>
    <li><b>스팸(spam)성 게시물 및 상업성 게시물</b></li>
    <li><b>타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 게시물</b></li>
    <li><b>동의 없는 타인의 신상공개, 제3자의 저작권 등 권리를 침해하는 내용을 담은 게시물</b></li>
    <li><b>기타 관계 법령 및 "몰"의 지침 등에 위반된다고 판단되는 경우</b></li>
  </ol>
  <p>
    <b
      >⑦ 이용자의 "게시물" 또는 “상품 등”이 "정보통신망법" 및 "저작권법" 등 관련 법령에 위반되는 내용을
      포함하는 경우 “몰” 관리자는 관련 법령이 정한 절차에 따라 해당 게시물 또는 “상품 등”의 게시 또는 판매
      중단 및 삭제 등을 요청할 수 있으며, "몰"은 관련 법령에 따라 조치를 취할 수 있습니다.</b
    >
  </p>
  <br />
  <h2>제2장 전자상거래 서비스</h2>
  <br />
  <h3>제18조 (구매신청 등)</h3>
  <p>
    ① “회원”은 “몰” 상에서 다음 또는 이와 유사한 방법에 의하여 구매 등을 신청하며, “몰”은 “회원”이 구매신청을
    함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
  </p>
  <ol>
    <li>“상품 등”의 전시 및 선택</li>
    <li>받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
    <li>약관내용, 청약철회권이 제한되는 “서비스”, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인</li>
    <li>이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)</li>
    <li>“상품 등”의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의</li>
    <li>결제방법의 선택</li>
  </ol>
  <p>
    ② “회원”은 제22조 제2항에 따른 상품 등의 경우에는 개별 서비스 이용약관 또는 개별 서비스 안내사항에 따라서
    구매신청(특별한 설명이 없는 한 이 약관에서 “구매신청”은 주문제작 상품 등에 대한 제작신청의 의미를
    포함한다)을 할 수 있습니다.
  </p>
  <br />
  <h3>제19조 (계약의 성립)</h3>
  <p>① “몰”은 제18조와 같은 구매신청 등에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.</p>
  <ol>
    <li>신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
    <li>미성년자가 청소년보호법에서 금지하는 상품 등을 구매하는 경우</li>
    <li>
      상행위(재판매)를 목적으로 구매하는 거래이거나, 거래 정황상 상행위(재판매)를 목적으로 한 구매로 판단되는
      경우
    </li>
    <li>기타 구매신청 등에 승낙하는 것이 사회통념상 또는 기술상 현저히 지장이 있다고 판단하는 경우</li>
  </ol>
  <p>
    ② “몰”의 승낙이 제21조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
  </p>
  <p>
    ③ “몰”의 승낙의 의사표시에는 이용자의 구매신청 등에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에
    관한 정보 등을 포함하여야 합니다.
  </p>
  <br />
  <h3>제20조 (대금결제 및 구매안전 서비스)</h3>
  <p>
    ① “몰”에서 구매한 “상품 등”에 대한 대금 지급 방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
    단, “몰”은 이용자의 지급방법에 대하여 “상품 등”의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수
    없습니다.
  </p>
  <ol>
    <li>모바일 뱅킹, 인터넷 뱅킹 등의 각종 계좌이체</li>
    <li>선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
    <li>온라인무통장입금</li>
    <li>“몰”이 지급한 포인트 등에 의한 결제</li>
    <li>“몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제</li>
    <li>기타 전자적 지급 방법에 의한 대금 지급 등</li>
  </ol>
  <p>
    ② "몰"은 이용자가 현금, 카드 기타의 방법으로 대금을 결제할 수 있는 방법을 제공하며, 이용자가 대금 결제 시
    사용한 결제수단에 대해 정당한 사용권한을 가지고 있는 지의 여부를 확인할 수 있습니다.
  </p>
  <p>
    ③ "몰"은 이용자가 상품을 주문한 후 일정 기간 내에 대금을 결제하지 않은 경우 당해 주문을 이용자의 동의 없이
    취소할 수 있으며, 본 조 제 3항의 확인이 완료될 때까지 거래진행을 중지하거나, 확인이 불가능한 경우 해당
    거래를 취소할 수 있습니다.
  </p>
  <p>
    ④ "몰"은 서비스 내에서 이루어지는 선불식 통신판매에 있어서 고객이 지급하는 대금을 예치하고 배송이 완료된
    후 재화 또는 용역의 대금을 판매자에게 지급함으로써 구매과정의 안전을 도모합니다.
  </p>
  <p>
    ⑤ "몰"은 이용자와 판매자가 서비스를 이용함에 있어서 편리하게 금전 거래를 할 수 있도록 결제대금예치
    서비스(에스크로) 및 지급대행서비스를 제공합니다.
  </p>
  <p>
    ⑥ "몰"은 이용자가 상품을 받은 날로부터 7영업일이 지나도록 배송완료 사실을 통보하지 아니한 때에는 이용자가
    상품을 받은 것으로 간주하여 판매자에게 매매대금을 지급할 수 있습니다.
  </p>
  <p>
    ⑦ "몰"은 분쟁 및 그 밖의 사유 발생시 합리적인 판단에 따라 이용자와 판매자에게 이용 제한 등의 필요한 조치를
    취할 수 있으며, 진행중인 거래의 서비스 대금 지급이나 서비스 대금을 환불 또는 지급 보류할 수 있습니다.
  </p>
  <p>
    ⑧ "몰"이 제공하는 구매안전 서비스를 이용하지 않고 회원간 직접 거래하는 행위(직거래)는 거래의 안전을 위하여
    금지되고, 직거래를 통하여 발생한 제반 문제에 대한 책임은 거래 당사자에게 있으며 회사는 이에 대해 어떠한
    책임도 지지 않습니다.
  </p>
  <p>
    ⑨ “몰”의 결제대금예치서비스(에스크로)와 지급 대행 서비스는 금융위원회에 등록된 전자금융업 허가 및 등록을
    한 결제대행사를 통해 “판매자 회원”을 대행하여 이용자가 판매자에게 지급하여야 할 자금의 내역을 전자적인
    방법으로 지급인에게 고지, 수수, 정산합니다.
  </p>
  <p>
    ⑩ "몰"에서 결제한 모든 거래는 ㈜KG이니시스의 결제대금예치 서비스 및 지급대행서비스가 적용되며, "몰"의 대금
    결제 및 구매안전 서비스에 대해서는 ㈜KG이니시스의 전자금융거래약관이 적용됩니다.
  </p>
  <p>
    ⑪ “회사”는 이용자와 판매자의 거래의 안정성과 편의성 향상을 목적으로 해당 서비스를 제공하고 있으며 회사의
    고의 또는 중대한 과실이 없는 한 해당 결제 서비스를 이용하는 당사자 간의 거래에 어떠한 책임을 지지
    않습니다.
  </p>
  <p>
    ⑫ “회사”는 “회사”의 결제 서비스를 사용하는 이용자 혹은 판매자의 어느 일방을 대리, 대행하거나 그 이행을
    보조하는 위치에 있지 않습니다. “회사”는 거래 당사자 간 자금의 흐름에 직접 관여하거나 개입하지 않으며,
    “회사” 또는 “회사”가 지정한 사업자와 계약을 체결한 결제대급예치업자가 이용자가 지불한 금액을 판매자에게
    전달합니다.
  </p>
  <p>
    ⑬ “몰”의 결제서비스를 이용하는데 있어 잘못된 정보를 입력하여 잘못된 대금지급이 이루어졌을 경우, “회사”는
    이에 책임을 지지 않습니다.
  </p>
  <p>
    ⑭ “회원”, 결제대행업체, 금융기관 등과의 사이에서 발생한 분쟁은 당사자 간의 해결을 원칙으로 하며, “회사”는
    이와 관련한 어떠한 책임도 지지 않으며, 해당 사안의 결제 대행업체 또는 금융기관의 약관이 우선됩니다.
  </p>
  <br />
  <h3>제21조 (수신 확인 통지,구매신청의 변경 및 취소)</h3>
  <p>① “몰”은 “회원”의 구매신청이 있는 경우 “회원”에게 수신확인통지를 합니다.</p>
  <p>
    <b
      >② 수신확인통지를 받은 “회원”은 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시
      구매신청 변경 및 취소를 요청할 수 있고, “몰”은 주문제작 상품의 경우에는 제작 착수 전에, 입고 상품의
      경우에는 배송 전에 요청이 있는 경우에 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한
      경우에는 제24조의 청약철회 등에 관한 규정에 따릅니다.</b
    >
  </p>
  <br />
  <h3>제22조 (“상품 등”의 공급)</h3>
  <p>
    ① “몰”은 “회원”과 “상품 등”의 공급시기에 관하여 별도의 약정이 없는 이상, “회원”이 청약(구매신청)을 한
    날부터 7일 이내에 “상품 등”을 배송할 수 있도록 주문, 포장 등 기타의 필요한 조치를 취합니다. 다만, “몰”이
    이미 “상품 등”의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에
    조치를 취합니다.
  </p>
  <p>
    ② “몰”은 취급하고 있는 “상품 등”의 종류(주문제작, 펀딩 또는 기타 예약판매 유형)에 따라서 인도 또는 제공
    시기에 관하여 별도의 약정, 개별 이용약관 또는 “운영정책”이 있는 경우 해당 내용이 이 약관에 우선하며,
    “회원”은 구매(제작)신청 전 해당 약관 또는 안내사항에 대한 동의 없이는 특정 상품에 대한 구매(제작)가
    불가능할 수 있습니다. 본 항 유형의 상품에 대하여 “몰”은 “회원”이 “상품 등”의 공급 절차 및 진행 사항을
    확인할 수 있도록 적절한 조치를 합니다.
  </p>
  <p>
    ③ “몰”은 “회원”이 구매한 상품 등에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다.
    이때 상품 등의 생산 중단 및 "몰"의 기타 사정에 의하여 공급이 어려운 경우 제공이 중단될 수 있으며 이에
    대하여 회사는 책임을 부담하지 않습니다.
  </p>
  <br />
  <h3>제23조 (환급)</h3>
  <p>
    “몰”은 “회원”이 구매 신청한 “상품 등”이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그
    사유를 “회원”에게 통지하고, 사전에 “상품 등”의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에
    환급하거나 환급에 필요한 조치를 취합니다. 단, 전조 제2항에 따른 상품 등의 경우에는 개별 약관 또는 안내사항
    및 “운영정책”에 따릅니다.
  </p>
  <br />
  <h3>제24조 (청약철회 등)</h3>
  <p>
    ① “몰”과 “상품 등”의 구매에 관한 계약을 체결한 구매자는 「전자상거래 등에서의 소비자보호에 관한 법률」
    제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 “상품 등”의 공급이 늦게 이루어진
    경우에는 “상품 등”을 공급받거나 “상품 등”의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할
    수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는
    경우에는 동 법 규정에 따릅니다.
  </p>
  <p>
    <b
      >② 구매자는 “상품 등”을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수
      없습니다.</b
    >
  </p>
  <ol>
    <li>
      <b
        >구매자에게 책임 있는 사유로 “상품 등”이 멸실 또는 훼손된 경우(다만, “상품 등”의 내용을 확인하기
        위하여 포장 등을 훼손한 경우는 제외함)</b
      >
    </li>
    <li><b>구매자의 사용 또는 일부 소비에 의하여 “상품 등”의 가치가 현저히 감소한 경우</b></li>
    <li><b>시간의 경과에 의하여 재판매가 곤란할 정도로 “상품 등”의 가치가 현저히 감소한 경우</b></li>
    <li><b>같은 성능을 지닌 “상품 등”으로 복제가 가능한 경우 그 원본인 “상품 등”의 포장을 훼손한 경우</b></li>
    <li>
      <b
        >용역 또는 문화산업진흥 기본법 제2조 제5호의 디지털콘텐츠의 제공이 개시된 경우 (다만 가분적 용역 및
        가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 제외함)</b
      >
    </li>
    <li>
      <b
        >구매자의 주문에 따라 개별적으로 생산되는 “상품 등”에 대하여 청약철회 등을 인정할 경우 판매자에게
        회복할 수 없는 중대한 피해가 예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 고지하고 구매자의
        서면(전자문서를 포함) 동의를 받은 경우</b
      >
    </li>
    <li><b>그 밖에 관련 법령에 따른 제한 사유에 해당되는 경우</b></li>
  </ol>
  <p>
    <b
      >③ “몰”은 제2항 제2호 내지 제4호의 경우에 사전에 청약철회 등이 제한되는 사실을 이용자가 쉽게 알 수 있는
      곳에 명확하게 적시하거나, 시험 시용 상품을 제공하는 등의 방법으로 구매자의 청약철회 권리 행사가 방해받지
      아니하도록 조치합니다.</b
    >
  </p>
  <p>
    <b
      >④ 구매자는 제1항 및 제3항의 규정에 불구하고 “상품 등”의 내용이 표시·광고 내용과 다르거나 계약내용과
      다르게 이행된 경우에는, 당해 “상품 등”을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던
      날부터 30일 이내에 청약철회를 할 수 있습니다.</b
    >
  </p>
  <p>
    ⑤ 구매자는 제1항 또는 제4항에 따라 교환 신청을 하더라도 재고가 없는 경우에는 해당 “상품 등”의 교환을 받을
    수 없으며, 이 경우 해당 교환 신청은 반품으로 처리됩니다.
  </p>
  <br />
  <h3>제25조 (청약철회 등의 효과)</h3>
  <p>
    ① “몰”은 이용자로부터 “상품 등”을 반환 받은 경우 반환 받은 날로부터 3영업일 이내에 이미 지급받은 “상품
    등”의 대금을 환급합니다. 이 경우 "몰"이 이용자에게 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래
    등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를
    지급합니다.
  </p>
  <p>
    ② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 “상품 등”의 대금을
    지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 “상품 등”의 대금의 청구를 정지 또는
    취소하도록 요청합니다.
  </p>
  <p>
    ③ 청약철회 등의 경우 공급받은 “상품 등”의 반환에 필요한 비용은 이용자가 부담합니다. “몰”은 이용자에게
    청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 “상품 등”의 내용이 표시·광고 내용과
    다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 “상품 등”의 반환에 필요한 비용은 “몰”이
    부담합니다.
  </p>
  <p>
    ④ “판매자 회원”이 직접 판매·배송하는 상품 등의 경우, 교환/환불의 귀책사유가 판매자에게 있는 경우 공급받는
    상품 등의 반환에 필요한 비용은 해당 판매자가 직접 부담합니다.
  </p>
  <p>
    ⑤ 이용자가 “상품 등”을 제공받을 때 발송비를 부담한 경우에 “몰”은 청약철회 시 그 비용을 누가 부담하는지를
    이용자가 알기 쉽도록 명확하게 표시합니다.
  </p>
  <br />
  <h3>제26조(미성년자 법정대리인의 계약취소권)</h3>
  <p>
    <b
      >① 미성년자 등 제한능력자가 법정대리인의 동의 없이 결제한 경우, 본인 또는 법정대리인은 결제를 취소할 수
      있습니다.</b
    >
  </p>
  <p>
    <b
      >② 제한능력자의 “상품 등” 구매를 위한 결제가 법정대리인으로부터 처분을 허락 받은 범위 내인 경우 또는
      제한능력자의 속임수가 있는 경우에는 취소가 제한될 수 있습니다.</b
    >
  </p>
  <p>
    ③ “상품 등” 구매계약의 당사자가 제한능력자인지 여부는 결제가 진행된 플랫폼, 결제 실행자 정보, 결제 수단
    명의자 등을 근거로 판단합니다.
    <b
      >또한 “몰”은 정당한 취소인지를 확인하기 위해 회원에게 제한능력자 및 법정대리인 등임을 증명할 수 있는
      서류의 제출을 요청할 수 있습니다.</b
    >
  </p>
  <br />
  <h2>제3장 통신판매중개서비스</h2>
  <br />
  <h3>제27조 (통신판매중개서비스의 이용)</h3>
  <p>
    ① "회사"는 통신판매중개서비스와 관련하여 통신판매중개자로서 이용자와 판매자 간의 자유로운 상품 등의 거래를
    위한 시스템을 운영 및 관리, 제공할 뿐이므로
    <b
      >이용자는 판매자로부터 “상품 등”을 구매하기 전에 반드시 “상품 등”의 상세 내용과 거래조건을 정확하게
      확인한 후 구매를 신청하여야 합니다. 구매하려는 “상품 등”의 상세 내용과 거래조건을 확인하지 않고 구매하여
      발생한 모든 손실과 손해는 이용자 본인이 책임을 부담합니다.</b
    >
  </p>
  <p>
    <b
      >② 이용자는 구매의사 없이 판매자가 판매하는 “상품 등”의 구매의사를 표시하거나 예약해서는 아니 되고,
      판매자의 “상품 등”의 판매 기회를 방해하거나 다른 이용자의 구매기회를 방해하여서는 아니 됩니다.</b
    >
  </p>
  <p>
    <b
      >③ "회사"는 이용자가 제2항을 위반한 경우에는 통신판매중개서비스의 일부 또는 전부를 이용 정지하거나
      통신판매중개서비스 이용계약을 임의로 해지할 수 있으며, 이 경우 발생하는 손해에 대한 책임은 이용자가
      부담합니다.</b
    >
  </p>
  <p>
    <b
      >④ 이용자는 이 약관 및 "몰"의 서비스 화면에서 공지하는 내용을 준수하여야 하고, 이 약관 및 공지 내용을
      위반하거나 이행하지 않아서 발생하는 모든 손실과 손해에 대하여 책임을 집니다.</b
    >
  </p>
  <p>
    <b
      >⑤ 이용자는 판매자와의 매매 절차에서 분쟁이 발생하면 분쟁의 해결을 위하여 성실히 임해야 하며, 불성실하게
      분쟁해결 절차에 임함으로써 판매자 및 "회사"에 손실과 손해가 발생하면 그에 대한 모든 책임을 부담하여야
      합니다.</b
    >
  </p>
  <p>
    <b
      >⑥ 이용자는 “상품 등”을 구매할 경우에는 본인 명의의 결제수단을 사용하여야 하고, 타인의 결제수단을 임의로
      사용하여서는 아니 되며, 타인의 결제수단을 임의로 사용하여 "회사", 판매자 및 해당 결제수단 소유자에게
      발생하는 손실 또는 손해 등에 대한 모든 책임을 부담합니다.</b
    >
  </p>
  <p>
    <b
      >⑦ 이용자는 매매대금의 결제와 관련하여 이용자가 입력한 정보 및 그 정보와 관련하여 발생하는 제반 문제에
      대한 모든 책임을 부담합니다.</b
    >
  </p>
  <p>
    <b
      >⑧ "회사"는 이용자의 매매대금 결제 시 해당 결제수단에 대하여 정당한 사용권한이 있는지 확인할 수 있고,
      이에 대한 확인이 완료될 때까지 해당 거래의 진행을 중지하거나 해당 거래를 취소할 수 있습니다.</b
    >
  </p>
  <p>
    <b
      >⑨ "회사"는 이용자에게 서비스가 안전하게 제공될 수 있도록 각종 설비와 자료를 관리하고, 서비스가 제공
      목적에 맞게 이용되고 있는지 확인합니다. 이 경우 이용자에게 이용 목적에 위반되는 부분이 있는 것으로
      확인되면 사유의 소명을 요청할 수 있고, 예약취소 등 필요한 조치를 할 수 있습니다.</b
    >
  </p>
  <p>
    <b
      >⑩ "회사"는 이용자의 서비스 이용 편의를 높이기 위하여 판매자 등으로부터 “상품 등” 관련 정보를 제공받아
      게재하거나 제3자가 제공하는 방식으로 사이트를 통하여 참조용 상품정보나 관련 콘텐츠를 제공하는 경우에도
      해당 “상품 등”의 구매와 관련하여 자신의 판단과 책임으로 결정하여야 합니다. 이 경우 "회사"는 어떠한
      경우에도 이용자의 구매결정에 대하여 책임을 부담하지 아니 합니다.</b
    >
  </p>
  <p>
    <b
      >⑪ 미성년자인 이용자가 서비스를 이용하여 재화 또는 용역을 구매 시 법정대리인이 해당 계약에 대하여 동의를
      하여야 정상적인 구매계약이 체결될 수 있습니다. 만약, 미성년자인 이용자가 법정대리인의 동의 없이 재화
      또는 용역을 구매하는 경우 본인 또는 법정대리인이 이를 취소할 수 있습니다.</b
    >
  </p>
  <p>
    ⑫ “회사”는 이 약관에 따른 전자지급결제대행, 결제대금예치 등 관리에 관한 제반업무를 전자금융거래법상 적법한
    라이선스를 보유하고 있는 제3자를 이용하여 처리할 수 있습니다.
  </p>
  <br />
  <h3>제28조 (결제대금예치서비스 이용)</h3>
  <p>
    ① “결제대금예치서비스”라 함은 매매계약이 체결되고 “회원”이 대금을 결제하는 경우 결제대금 보호를 위하여
    “몰”이 일정 기간 동안 결제대금을 예치하는 서비스를 말합니다.
  </p>
  <p>
    ② “회사”는 “회원”과 판매자 간에 이루어지는 “상품 등” 매매의 안전성과 신뢰성을 높이고, 상품 등 인수 전
    대금을 결제해야 하는 회원을 보호하기 위하여 결제대금예치서비스를 제공합니다.
  </p>
  <p>
    ③ “회사”는 결제대금예치서비스의 제공과 관련하여 판매자 또는 “회원”을 대리하는 것이 아님은 물론 상품 등의
    매매와 관련하여 판매자 또는 “회원”의 의무를 대행하는 것도 아닙니다.
  </p>
  <p>
    ④ “회원”은 결제대금예치서비스를 통하여 구매대금을 결제하는 과정에서 발생하는 송금수수료 등을 부담하여야
    하며, “회사”가 결제대금예치서비스를 제공하는 과정에서 발생하는 이자 등에 대하여 “회사”에게 그 반환을
    청구할 수 없습니다.
  </p>
  <p>⑤ 결제대금예치서비스는 제29조에 정한 바에 따라 구매확정된 경우 종료됩니다.</p>
  <p>
    ⑥ “회원”은 결제대금예치서비스가 종료된 이후에 해당 구매 건에 대하여 청약철회, 취소, 해제, 무효 등의 사유가
    발생한 경우 판매자와 직접 그에 관한 절차를 진행해야 합니다.
  </p>
  <br />
  <h3>제29조 (배송 및 거래완료)</h3>
  <p>
    ① "회사"는 판매자와 이용자간 거래 및 배송 등과 관련하여 판매자, 이용자, 배송업체 등 관련 당사자 사이에
    분쟁 등이 발생하는 경우에는 이에 관여하지 않으며 어떠한 책임도 부담하지 아니합니다. 이 경우 해당 분쟁 등은
    관련 당사자가 직접 해결하여야 합니다.
  </p>
  <p>
    ② "회사"는 구매결정이 이루어진 이후에 “상품 등”의 하자 등 결제대금의 환불사유가 발생하는 경우에는 이에
    개입하지 아니합니다.
  </p>
  <p>
    ③ 구매결정 이후에 발생하는 판매자와 이용자간 모든 분쟁은 거래 당사자인 판매자와 이용자가 해결하여야
    합니다.
  </p>
  <p>
    ④ 이용자는 구매한 상품 등의 배송이 완료되었을 때로부터 일정한 기간 내에 “회사”에 대하여 구매확정, 교환
    또는 반품의 의사표시를 하여야 합니다. "회사"는 구매결정 기간 내에 이용자의 구매결정, 교환 또는 반품에 대한
    의사표시가 없는 경우에는 해당 거래에 대하여 구매결정 의사표시가 있는 것으로 간주하여 자동구매확정으로
    처리할 수 있습니다.
  </p>
  <p>
    ⑤ 이용자는 구매결정 등의 의사표시를 하였거나 자동구매결정이 이루어진 경우에는 "회사"에 대하여 상품 등
    미수령, 반품 등의 사유로 이의를 제기할 수 없습니다. 이 경우 상품 등 구매대금의 환불, 상품 등의 교환 등
    모든 문제는 판매자와 직접 해결해야 합니다.
  </p>
  <br />
  <h3>제30조 (청약철회 등)</h3>
  <p>
    ① 판매자와 상품 등의 구매에 관한 계약을 체결한 구매자는 『전자상거래 등에서의 소비자보호에 관한 법률』 등
    관련 법령에 따라 상품 등을 배송 받은 날로부터 7일 이내에 청약철회 등을 할 수 있습니다. 다만, 청약철회에
    관하여 『전자상거래 등에서의 소비자보호에 관한 법률』에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
  </p>
  <p>
    <b
      >② 구매자는 상품 등을 배송 받은 경우 다음 각호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.</b
    >
  </p>
  <ol>
    <li>
      <b
        >구매자에게 책임 있는 사유로 “상품 등”이 멸실 또는 훼손된 경우(다만, “상품 등”의 내용을 확인하기
        위하여 포장 등을 훼손한 경우는 제외함)</b
      >
    </li>
    <li><b>구매자의 사용 또는 일부 소비에 의하여 “상품 등”의 가치가 현저히 감소한 경우</b></li>
    <li><b>시간의 경과에 의하여 재판매가 곤란할 정도로 “상품 등”의 가치가 현저히 감소한 경우</b></li>
    <li><b>같은 성능을 지닌 “상품 등”으로 복제가 가능한 경우 그 원본인 “상품 등”의 포장을 훼손한 경우</b></li>
    <li>
      <b
        >용역 또는 문화산업진흥 기본법 제2조 제5호의 디지털콘텐츠의 제공이 개시된 경우 (다만 가분적 용역 및
        가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 제외함)</b
      >
    </li>
    <li>
      <b
        >구매자의 주문에 따라 개별적으로 생산되는 “상품 등”에 대하여 청약철회 등을 인정할 경우 판매자에게
        회복할 수 없는 중대한 피해가 예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 고지하고 구매자의
        서면(전자문서를 포함) 동의를 받은 경우</b
      >
    </li>
    <li><b>그 밖에 관련 법령에 따른 제한 사유에 해당되는 경우</b></li>
  </ol>
  <p>
    <b
      >③ 제2항 제2호 내지 제4호의 경우에는 “몰”에서 사전에 청약 철회 등이 제한되는 사실을 구매자가 쉽게 알 수
      있는 곳에 명기하거나 시용 상품을 제공하는 등의 조치를 하지 않았다면 구매자의 청약 철회 등이 제한되지
      않습니다.</b
    >
  </p>
  <p>
    <b
      >④ 구매자는 제1항 및 제2항의 규정에도 불구하고 상품 등의 내용이 표시, 광고 내용과 다르거나 계약 내용과
      다르게 이행된 때에는 당해 상품 등을 공급 받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터
      30일 이내에 청약 철회 등을 할 수 있습니다.</b
    >
  </p>
  <p>
    <b
      >⑤ "몰"은 구매자로부터 교환 또는 반품의 의사표시를 받은 경우에는 즉시 그 사실을 판매자에게
      통보합니다.</b
    >
  </p>
  <p>
    ⑥ 구매자는 제1항 또는 제3항에 따라 교환신청을 하더라도 판매자에게 교환할 상품 등의 재고가 없는 경우에는
    해당 상품 등의 교환을 받을 수 없습니다. 이 경우 해당 교환신청은 반품으로 처리됩니다.
  </p>
  <p>
    ⑦ 반품이나 교환에 필요한 배송비 및 그 밖에 필요 비용은 귀책사유가 있는 당사자가 부담합니다. 이 경우 상품
    등 하자 또는 오배송의 경우에는 판매자가 그 비용을 부담하나 구매자의 단순 변심의 경우에는 구매자가 그
    비용을 부담합니다.
  </p>
  <p>⑧ 판매자는 구매자가 배송상의 문제로 손해를 입은 경우에는 그에 대한 책임을 부담합니다.</p>
  <p>
    ⑨ "몰"은 판매자가 구매자의 결제완료에 대한 통지를 받은 이후에도 상당 기간 배송 절차 등을 이행하지 아니하여
    구매자로부터 주문취소 요청을 받거나 개별적인 정책으로 미리 정한 자동환불처리 사안(판매자가 회원의 교환
    또는 반품 신청에 대하여 적극적으로 임하지 않거나 이를 지연하는 경우 등)에 해당하는 경우에는 해당 거래를
    취소하고 결제대금을 구매자에게 환불할 수 있습니다. 다만 구매자의 교환 또는 반품신청에 정당한 사유가 없는
    경우에는 그러하지 아니합니다.
  </p>
  <p>
    ⑩ 구매자가 교환 또는 반품신청을 한 날로부터 14일이 지날 때까지 이미 수령한 상품 등을 판매자에게 반환하지
    아니 하거나 전화, 이메일 등으로 연락되지 아니하는 경우에는 해당 구매자의 교환 또는 반품신청은 효력을
    상실합니다.
  </p>
  <p>
    <b
      >⑪ “몰”은 청약철회 등으로 인하여 결제대금을 환불하는 경우에는 그 청약철회 등을 한 날(단, 상품 등이
      배송된 경우에는 판매자가 상품 등을 반환 받은 날)로부터 3영업일 이내에 환불에 필요한 조치를 합니다.</b
    >
    신용카드로 결제가 이루어진 경우 환불은 해당 신용카드 결제 취소를 통해서만 가능하고, 어떠한 경우에도
    현금으로 환불되지는 아니합니다.
  </p>
  <br />
  <h3>제31조 (금지행위)</h3>
  <p>
    ① 판매자와 구매자가 “회사”가 제공하는 서비스를 이용하지 아니하고 직접 거래하는 행위(직거래)는 거래의
    안전을 위하여 금지됩니다. 이 경우 직거래를 통하여 발생한 제반 문제에 대한 책임은 거래 당사자들에게 있으며
    “회사”는 이에 대해 어떠한 책임도 부담하지 아니 합니다.
  </p>
  <p>② 제1항 이외에 금지되는 이용자의 행위에 대하여는 이 약관 제13조의 규정에 따릅니다.</p>
  <br />
  <h3>제32조 (“회사”의 면책)</h3>
  <p>
    ① “회사”는 통신판매중개자로서 구매자와 판매자 간의 자유로운 상품 등의 거래를 위한 시스템을 운영 및 관리,
    제공할 뿐 구매자 또는 판매자를 대리하지 않으며, 구매자와 판매자 사이에 성립된 거래와 관련된 책임과
    이용자가 제공한 정보에 대한 책임은 해당 이용자가 직접 부담하여야 합니다.
  </p>
  <p>
    ② “회사”는 판매자가 등록한 상품 등의 내용과 거래조건에 대해서 어떠한 보증이나 대리를 하지 않으며, 이용자는
    본인의 위험과 책임 하에 상품 등을 구매해야 합니다.
  </p>
  <p>
    ③ “회사”는 구매자와 판매자 간의 모든 행위(거래행위 포함)와 관련하여 판매의사 또는 구매의사의 존부 및
    진정성, 등록 상품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 구매자 또는 판매자가
    입력하는 정보 및 그 정보를 통하여 연결된 웹사이트에 게재된 자료의 진실성 또는 적법성 등에 대하여 보증하지
    아니하며, 이와 관련된 위험과 책임은 해당 당사자들이 부담합니다.
  </p>
  <p>
    ④ “회사”는 구매자 및 판매자의 귀책사유로 서비스 이용에 장애가 발생하는 것에 대하여 책임을 부담하지
    아니합니다.
  </p>
  <p>
    ⑤ “회사”는 이용자 본인의 개인정보를 타인에게 유출하거나 제공하여 발생하는 피해에 대하여 책임을 부담하지
    아니합니다.
  </p>
  <p>
    ⑥ “회사”는 천재지변, 컴퓨터 등 정보통신설비의 보수, 점검, 교체, 고장 및 통신두절 등의 사유가 발생한
    경우에는 서비스 제공을 일시적으로 중단할 수 있으며, 이와 관련하여 발생하는 피해에 대하여 책임을 부담하지
    아니합니다.
  </p>
  <p>
    ⑦ 제1항 내지 제3항과 관련하여 “회사”의 고의 또는 중과실로 인한 손해에 대해서는 “회사”가 책임을 부담합니다.
    제4항 내지 제6항의 경우에는 회사의 고의 또는 과실로 인한 손해에 대해 위와 같습니다.
  </p>
  <br />
  <h2>제4장 기타 사항</h2>
  <br />
  <h3>제33조 (이용자의 게시물)</h3>
  <p>
    ① “몰”에 등록 또는 게시한 게시물에 대한 모든 책임은 게시자에게 있으며, 회사는 어느 게시물이 다음 각 항에
    해당한다고 인정되는 경우 사전 통지 없이 해당 게시물에 대한 접근을 임시적으로 차단하는 조치를 할 수 있고,
    이 경우에 회사는 이용자에게 지체없이 그 사유를 통지합니다. 게시물에 대한 임시 차단 조치에 대하여 이용자는
    이의를 제기할 수 있습니다.
  </p>
  <ol>
    <li>타인을 모욕, 비방, 비하하거나 타인의 명예를 훼손하는 게시물</li>
    <li>범죄 및 불법 행위에 악용될 수 있는 정보를 담고 있는 게시물</li>
    <li>타인의 저작권, 상표권 등 권리를 침해하는 게시물</li>
    <li>
      음란물 또는 일반인의 성적 굴욕감이나 수치심을 유발할 수 있는 내용의 게시물(그러한 내용이 담긴 웹사이트,
      앱 등으로 연결될 수 있는 게시물을 포함)
    </li>
    <li>어린이와 청소년의 정신적, 신체적 건강을 해칠 우려가 있는 선정적인 내용을 담은 게시물</li>
    <li>특정인의 개인정보가 노출된 게시물</li>
    <li>
      특정 상품 등을 소개하여 판매하거나, 이를 구매, 사용하도록 권하거나 연락을 유도하는 등 직간접적으로
      영리추구를 위한 내용을 담고 있는 게시물
    </li>
    <li>“몰”의 이용 목적에 부합하지 않거나 해당 서비스의 취지와 무관한 내용의 게시물</li>
    <li>
      악성코드나 스파이웨어 등이 실행되어 다른 회원, 회사, 판매자 기타 제3자의 시스템 성능 저하, 개인정보 유출
      등의 피해를 줄 수 있는 악의적인 코드를 담고 있는 게시물
    </li>
    <li>정당한 사유 없이 회사의 영업을 방해하는 내용의 게시물</li>
    <li>기타 이 약관 또는 법령에 위배되거나 공서양속에 반하는 내용의 게시물</li>
  </ol>
  <p>
    ② “회원”이 탈퇴하는 경우, 탈퇴 전에 작성한 게시물(댓글 포함)은 삭제되지 않습니다. 회원 탈퇴 시 회원정보가
    삭제되어 작성자 본인을 확인할 수 없게 되므로 게시물에 대한 편집 또는 삭제가 원천적으로 불가하며, 게시물의
    삭제를 원하는 “회원”은 반드시 회원 탈퇴 이전에 해당 게시물을 삭제하여야 합니다.
  </p>
  <p>
    ③ “회원”이 작성한 게시물은 상품 등의 연구개발, 판촉, 홍보 등의 목적으로 회사가 제휴한 타사에 복제, 배포,
    전송 또는 전시될 수 있으며, 본질적인 내용에 변경을 가하지 않는 범위 내에서 수정, 편집될 수 있습니다. 이
    경우 회사는 해당 게시물로부터 “회원”의 개인정보가 식별되지 않도록 필요한 조치를 합니다.
  </p>
  <br />
  <h3>제34조 (게시물 관리)</h3>
  <p>
    ① 이용자의 게시물이 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」 및
    「저작권법」 등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당
    게시물의 게시중단 및 삭제 등을 요청할 수 있으며, “몰”은 관련 법령에 따라 조치를 취하여야 합니다.
  </p>
  <p>
    ② “몰”은 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및
    관련 법령에 위반되는 경우에는 관련 법령에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
  </p>
  <p>
    ③ 이 조에 따른 세부절차는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」 및
    「저작권법」등 관련 법령이 규정한 범위 내에서 “몰”이 정한 게시중단요청서비스에 따릅니다.
  </p>
  <p>게시중단요청서비스 : “몰” 내 1:1문의 또는 고객센터</p>
  <br />
  <h3>제35조 (“회사”의 면책)</h3>
  <p>
    ① “회사”는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기술적 결함 기타 불가항력적
    사유로 “서비스”를 제공할 수 없는 경우에는 책임이 면제됩니다.
  </p>
  <p>
    ② “회사”는 이용자의 귀책사유로 인한 “서비스” 이용 등의 중지, 이용장애 및 계약해지에 대하여 책임이
    면제됩니다.
  </p>
  <p>
    ③ “회사”는 이용자가 “서비스”를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의
    “서비스”를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다. 다만, “회사”의 고의 또는 중과실에
    의한 경우에는 그러하지 아니합니다.
  </p>
  <p>
    ④ “회사”는 이용자 또는 제3자가 홈페이지 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성등에 대한
    내용에 대해서는 “회사”의 고의 또는 중대한 과실이 없는 한 “회사”의 책임이 면제됩니다.
  </p>
  <p>
    ⑤ “회사”는 이용자 상호간 또는 이용자와 제3자간에 “회사”의 “서비스” 등을 매개로 발생한 분쟁에 대해 개입할
    의무가 없으며, 이로 인한 손해를 배상할 책임이 없습니다.
  </p>
  <p>
    ⑥ “회사”가 제공하는 무료 ”서비스” 등의 경우 또는 이용자가 “회사”가 제공하는 콘텐츠나 정보를 임의로 삭제한
    경우, “회사”는 이에 대해 책임을 지지 않습니다. 다만, “회사”의 고의 또는 중과실에 의한 경우에는 그러하지
    아니합니다.
  </p>
  <br />
  <h3>제36조 (분쟁해결)</h3>
  <p>
    ① “몰”은 “회원”으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
    곤란한 경우에는 “회원”에게 그 사유와 처리일정을 즉시 통보해 드립니다.
  </p>
  <p>
    ② “몰”은 “회원”이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상 처리하기 위하여 피해 보상 처리
    기구인 고객만족센터를 설치 운영합니다.
  </p>
  <p>
    ③ "몰"과 “회원”간에 발생한 전자상거래 분쟁과 관련하여 “회원”의 피해 구제 신청이 있는 경우에는
    공정거래위원회 또는 시도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
  </p>
  <br />
  <h3>제37조 (준거법 및 관할법원)</h3>
  <p>① 이 약관의 해석 및 관련 분쟁에 대하여는 대한민국법을 준거법으로 합니다.</p>
  <p>
    ② 이 약관 및 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생하여 소송이 제기되는 경우에는
    『민사소송법』에 따라 관할법원을 정합니다.
  </p>
  <br />
  <h3>부칙</h3>
  <p>이 약관은 2023년 12월 20일부터 시행합니다.</p>
`;
