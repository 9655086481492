import $dataStr from 'fxdom/es/dataStr.js';
import { equals, every, html, identity, includes, match, omit, strMap } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { UtilTmplS } from '../../../Util/S/Tmpl/module/UtilTmplS.js';
import { BANK_TYPES, PARENT_AGREE_DOC_URL } from '../../BankAccount/S/constant.js';
import { messages, messages as msg } from './messages_id.js';
import { isPaypal } from './util.js';

export const ifInvalid = (valid) => (valid ? '' : 'invalid');
export const ifChecked = (checked) => (checked ? 'checked' : '');

const makeImageUploadFormHtml = (
  { store_id, bank_account },
  title,
  field,
  before_upload_html = '',
  download_btn,
) => html`
  <div class="form-item">
    <label class="form-label">${title}</label>
    ${before_upload_html}
    <div class="form-control">
      ${download_btn}
      <input
        type="text"
        placeholder="${T(msg.ba.file_empty)}"
        readonly
        class="holder ${ifInvalid(!!bank_account[field])}"
        value="${UtilS.escape(bank_account[field] || '')}"
      />
      <label for="${field}" class="btn upload">${T(msg.ba.file_upload_btn)}</label>
      <label name="${field}" class="delete btn" data-show="${!!bank_account[field]}">
        ${T(msg.ba.file_delete)}
      </label>
      <a data-field="${field}" target="_blank" data-show="${!!bank_account[field]}" class="view btn btn-info">
        ${T(msg.ba.file_confirm)}
      </a>
      <input id="${field}" type="file" name="${field}" accept="image/jpeg,image/png,image/pjpeg,image/gif" />
    </div>
  </div>
`;

const makeGuideListHtml = (items = [], type = '') => legacyHtml`
  <ul class='guide ${type}'>
    ${strMap((item) => html`<li class="guide-item">${item}</li>`, items)}
  </ul>
`;

export const makeCheckInvoiceHtml = ({ bank_account }, index) => legacyHtml`
  <div class='form-group'>
    <span class="title">${index}. ${T(msg.ba.invoice_hd)}</span>
    <div class='guide-wrapper'>
      <label class='form-label'>${T(msg.ba.invoice_desc)}</label>
      ${makeGuideListHtml([T(msg.ba.invoice_guide1), T(msg.ba.invoice_guide2)])}
      <div class='form-item radio-group'>
        <label class="term_agree">
          <input
            type="radio"
            name="invoice_possible"
            value="true"
            ${UtilTmplS.checkIf(bank_account.type == 'company')}
          />
          ${T(msg.ba.invoice_able)}
        </label>
        <label class="term_agree">
          <input
            type="radio"
            name="invoice_possible"
            value="false"
          />
          ${T(msg.ba.invoice_not_able)}
        </label>
      </div>
    </div>
  </div>
`;

export const makeCheckForeignerConditionHtml = (info, index) => {
  const is_prev_foreign = info.bank_account.type == BANK_TYPES.FOREIGN;
  return html`
    <div class="form-group">
      <span class="title">${index}. ${T(msg.ba.title_7)}</span>
      <div class="guide-wrapper">
        <label class="form-label">${T(msg.ba.foreigner_cond)}</label>
        ${makeGuideListHtml([T(msg.ba.foreigner_cond_guide1), T(msg.ba.foreigner_cond_guide2)])}
        <div class="form-item checkbox-group">
          <label class="term_agree">
            <input
              type="checkbox"
              name="agree_condition1"
              ${UtilTmplS.checkIf(is_prev_foreign)}
              ${UtilTmplS.disableIf(is_prev_foreign)}
            />
            &nbsp; ${T(msg.ba.foreigner_cond_agree)}
          </label>
        </div>
      </div>
      <div class="guide-wrapper">
        <label class="form-label">${T(msg.ba.foreigner_ex)}</label>
        ${makeGuideListHtml([T(msg.ba.foreigner_ex_rule1), T(msg.ba.foreigner_ex_rule2)])}
        <div class="form-item checkbox-group">
          <label class="term_agree">
            <input
              type="checkbox"
              name="agree_condition2"
              ${UtilTmplS.checkIf(is_prev_foreign)}
              ${UtilTmplS.disableIf(is_prev_foreign)}
            />
            &nbsp; ${T(msg.ba.foreigner_ex_agree)}
          </label>
        </div>
      </div>

      <div class="condition-actions">
        ${is_prev_foreign
          ? ''
          : html`<button class="btn btn-primary agree" disabled>${T(msg.ba.confirm)}</button>`}
      </div>
    </div>
  `;
};

export const makeImpossibleInvoiceModal = () => html`
  <div class="modal_content impossible-invoice">
    <div class="modal_content_header">
      <img
        src="//s3.marpple.co/files/u_1504988/2021/4/original/558a0d2986ef760d377f95c1bb4e7792de8b36851.png"
      />
      <h3>${T(msg.ba.not_invoice_hd)}</h3>
    </div>
    <div class="modal_content_body">
      <span class="modal-content_line"> ${T(msg.ba.not_invoice_desc)} </span>
      <br />
      <br />
      <span class="modal-content_line">${T(msg.ba.not_invoice_q)}</span>
      <br />
    </div>
    <div class="modal_content_actions">
      <button type="button" class="btn btn-default button_keep_company">
        ${T(msg.ba.not_invoice_btn_bn)}
      </button>
      <button type="button" class="btn btn-primary button_go_personal">
        ${T(msg.ba.not_invoice_btn_pn)}
      </button>
    </div>
  </div>
`;

const makePrivateTermAgreeHtml = ({ bank_account, store_domain_name, ...info }, type, index) => {
  const privacy_url = `/${T.lang}/${UtilS.escape(store_domain_name)}/settings/privacy/latest`;
  const terms_url = `/${T.lang}/${UtilS.escape(store_domain_name)}/settings/terms/latest`;

  const privacyGuide = [
    T(msg.ba.term_1),
    T(msg.ba.term_2, {
      link: `<a href=${privacy_url} target='_blank' style='text-decoration: underline;'>${T(
        msg.ba.rule_1,
      )}</a>`,
    }),
  ];

  const adjustGuide = [
    T(msg.ba.term_3, {
      link: `<a href=${terms_url} target='_blank' style='text-decoration: underline;'>${msg.ba.rule_2}</a>`,
    }),
    T(msg.ba.term_4),
  ];

  return legacyHtml`
    <div class="form-group">
      <span class="title">${index}. ${T(msg.ba.title_6)}</span>
      <div class='guide-wrapper'>
        ${UtilS.htmlIf(
          !includes(type, [BANK_TYPES.FOREIGN, BANK_TYPES.COMPANY]),
          `
            <div class='form-item'>
              <label class="form-label">${T(msg.ba.agree_privacy)}</label>
              ${makeGuideListHtml(privacyGuide)}
              <div class='checkbox-group'>
                <label class="term_agree">
                  <input
                    type="checkbox"
                    name="term_agree"
                    value="${bank_account.term_agree}"
                    ${bank_account.term_agree ? 'checked' : ''}
                  />
                  &nbsp; ${T(msg.ba.agree_lb)}
                </label>              
              </div>
            </div>
          `,
        )}
      </div>
      <div class='guide-wrapper'>
        <div class="form-item">
          <label class="form-label">${T(msg.ba.agree_adjust)}</label>
          ${makeGuideListHtml(adjustGuide)}
          <div class='checkbox-group'>
            <label class="term_agree">
              <input type="checkbox" name="adjustment_agree" ${ifChecked(bank_account.adjustment_agree)} />
              &nbsp; ${T(msg.ba.agree_lb)}
            </label>
          </div>
        </div>
      </div>
    </div>
  `;
};

export const makeNormalBankInfoHtml = ({ bank_account, store_id }) => legacyHtml`
  <div class="form-item">
    <label class="form-label">${T(msg.ba.account_no)}</label>
    <div class='form-control'>
      <input
        class="${ifInvalid(bank_account.number)} number account"
        type="text"
        name="number"
        placeholder="${T(msg.ba.account_no_ph)}"
        value="${bank_account.number}"
        maxlength="50"
      />
    </div>
  </div>
  ${makeImageUploadFormHtml({ bank_account, store_id }, T(msg.ba.account_pic), 'paper_url')}
`;

export const makePayPalInfoHtml = ({ bank_account, store_id }) => legacyHtml`
  <div class="form-item">
    <label class="form-label">${T(msg.ba.paypal)}</label>
    <div class='form-control'>
      <input
        class="account ${ifInvalid(bank_account.number)}"
        type="text"
        name="number"
        placeholder="${T(msg.ba.paypal_ph)}"
        value="${bank_account.number}"
        maxlength="50"
      />
    </div>
  </div>
  ${makeImageUploadFormHtml(
    { bank_account, store_id },
    T(msg.ba.paypal_img),
    'paper_url',
    `
      <div class='guide-wrapper'>
        ${makeGuideListHtml(
          [
            T(msg.ba.paypal_info_1, {
              link: `
                <a href="https://www.paypal.com" target="_blank">
                  https://www.paypal.com
                </a>`,
            }),
            T(msg.ba.paypal_info_2, {
              link: `<a data-url="//s3.marpple.co/files/u_1504988/2021/4/original/a50cba8fd0ffd2800a2c94ec28e58becea5a72ca1.jpg" target="_blank" class="view-example">${msg.ba.view_file}</a>`,
            }),
          ],
          'number',
        )}
      </div>
    `,
  )}
`;

const makeBankAccountInfoHtml = (info, type, index) => {
  const { bank_account, is_foreign } = info;
  const use_paypal = type === BANK_TYPES.FOREIGN;

  return legacyHtml`
    <div class="form-group basic">
      <span class="title">${index}. ${T(msg.ba.title_5)} </span>
      <div class="form-item fx-auto-complete">
        <label class="form-label">${T(msg.ba.bank)}</label>
        <div class='form-control'>
          <input
            class="${!is_foreign && ifInvalid(bank_account.bank)}"
            id='bank-name'
            type="text"
            name="bank"
            placeholder="${T(msg.ba.bank_ph)}"
            value="${use_paypal ? 'paypal' : UtilS.escape(bank_account.bank)}"
            maxlength="20"
            ${type === BANK_TYPES.FOREIGN ? 'disabled' : ''}
          />
        </div>
      </div>
      <div class="form-item">
        <label class="form-label">${T(msg.ba.bank_owner)}</label>
        <div class='form-control'>
          <input
            class="${ifInvalid(bank_account.owner)}"
            type="text"
            name="owner"
            placeholder="${T(msg.ba.bank_owner_ph)}"
            value="${UtilS.escape(bank_account.owner)}"
            maxlength="30"
          />
        </div>
      </div>
      <div class='form-item bank-conditional-info'>
        ${UtilS.htmlIfElse(
          use_paypal,
          () => makePayPalInfoHtml(info),
          () => makeNormalBankInfoHtml(info),
        )}
      </div>
    </div>
  `;
};

const makeNameFormHtml = (user_name, disabled) => legacyHtml`
  <div class="form-item">
    <label class="form-label">${T(msg.ba.name)}</label>
    <div class='form-control'>
      <input
        class="inline ${ifInvalid(user_name)}"
        type="text"
        name="user_name"
        placeholder="${T(msg.ba.seller_name_ph)}"
        value="${UtilS.escape(user_name)}"
        maxlength="20"
        ${disabled ? 'disabled' : ''}
      />
    </div>
  </div>
`;

const makeRegistNumFormHtml = (registration_code_parts, disabled) => legacyHtml`
  <div class="form-item">
    <label class='form-label'>${T(msg.ba.id_no)}</label>
    <div class='form-control'>
      <input
        class="inline number ${registration_code_parts[0].length !== 6 ? 'invalid' : ''}"
        type="text"
        name="registration_code"
        value="${UtilS.escape(registration_code_parts[0]) || ''}"
        maxlength="6"
        placeholder='${T(msg.ba.id_no_front)}'
        ${disabled ? 'disabled' : ''}
      />
      <span class="input-spliter">-</span>
      <input
        class="inline number ${(registration_code_parts[1] || '').length !== 7 ? 'invalid' : ''}"
        type="password"
        name="registration_code"
        value="${(registration_code_parts[1] || '').length == 7 ? '0000000' : ''}"
        maxlength="7"
        ${T(msg.ba.id_no_back)}
        ${disabled ? 'disabled' : ''}
      />
      <div class="btn edit_registration_code" data-show='${disabled}'>${T(msg.ba.edit)}</div>
    </div>
  </div>
`;

const makeCompanyInfoFormHtml = (info, index) => {
  const { bank_account, business_license_parts } = info;

  return legacyHtml`
    <div class="form-group" data-account-type="company">
      <span class="title">${index}. ${T(msg.ba.title_4)}</span>
      <div class="form-item">
        <label class="form-label">${T(msg.ba.bn_name)}</label>
        <div class='form-control'>
          <input
            class="${ifInvalid(bank_account.company_name)}"
            type="text"
            name="company_name"
            placeholder="${T(msg.ba.bn_name_ph)}"
            value="${bank_account.company_name}"
            maxlength="20"
          />
        </div>
      </div>
  
      <div class="form-item">
        <label class="form-label">${T(msg.ba.bn_ceo)}</label>
        <div class='form-control'>
          <input
            class="${ifInvalid(bank_account.company_president)}"
            type="text"
            name="company_president"
            placeholder="${T(msg.ba.bn_ceo_ph)}"
            maxlength="15"
            value="${bank_account.company_president}"
          />
        </div>
      </div>
  
      <div class="form-item">
        <label class="form-label">${T(msg.ba.bn_no)}</label>
        <div class='form-control'>
          <input
            class="inline number ${business_license_parts[0].length !== 3 ? 'invalid' : ''}"
            type="text"
            name="business_license_number"
            maxlength="3"
            placeholder='123'
            value="${business_license_parts[0]}"
          />
          <span class="input-spliter"> - </span>
          <input
            class="inline number ${(business_license_parts[1] || '').length !== 2 ? 'invalid' : ''}"
            type="text"
            name="business_license_number"
            maxlength="2"
            placeholder='45'
            value="${business_license_parts[1] || ''}"
          />
          <span class="input-spliter"> - </span>
          <input
            class="inline number ${(business_license_parts[2] || '').length !== 5 ? 'invalid' : ''}"
            type="text"
            name="business_license_number"
            maxlength="5"
            placeholder='67890'
            value="${business_license_parts[2] || ''}"
          />
        </div>
      </div>
  
      <div class="form-item">
        <label class="form-label">${T(msg.ba.bn_mng_email)}</label>
        <div class='form-control'>
          <input
            class="${ifInvalid(bank_account.company_email)}"
            type="text"
            name="company_email"
            placeholder="${T(msg.ba.bn_mng_email_ph)}"
            maxlength="40"
            value="${bank_account.company_email}"
          />
        </div>
      </div>
  
      <div class="form-item">
        <label class="form-label">${T(msg.ba.bn_addr)}</label>
        <div class='form-control'>
          <input
            class="${ifInvalid(bank_account.company_address)}"
            type="text"
            name="company_address"
            placeholder="${T(msg.ba.bn_addr_ph)}"
            maxlength="100"
            value="${bank_account.company_address}"
          />
        </div>
      </div>
  
      <div class="form-item">
        <label class="form-label">${T(msg.ba.bn_type)}</label>
        <div class='form-control'>
          <input
            class="${ifInvalid(bank_account.company_status)}"
            type="text"
            name="company_status"
            placeholder="${T(msg.ba.bn_type_ph)}"
            maxlength="15"
            value="${bank_account.company_status}"
          />
        </div>
      </div>
  
      <div class="form-item">
        <label class="form-label">${T(msg.ba.bn_type2)}</label>
        <div class='form-control'>
          <input
            class="${ifInvalid(bank_account.company_type)}"
            type="text"
            name="company_type"
            placeholder="${T(msg.ba.bn_type2_ph)}"
            value="${bank_account.company_type}"
            maxlength="15"
          />
        </div>
      </div>
      ${makeImageUploadFormHtml(info, T(msg.ba.bn_license), 'business_license_url')}
    </div>
  `;
};

const makeParentFormGroupHtml = (info, index) => {
  const { bank_account } = info;

  const parentGuide = [T(msg.ba.agree_1), T(msg.ba.agree_4), T(msg.ba.agree_2), T(msg.ba.agree_3)];

  return legacyHtml`
    <div class='form-group'>
      <span class='title'>${index}. ${T(msg.ba.title_3)}</span>
      
      <div class='guide-wrapper'>
        ${makeGuideListHtml(parentGuide)}
        <button class='btn btn-primary phone parent-cert-btn'>${T(msg.ba.parent_mo_cert)}</button>
        <a href='${PARENT_AGREE_DOC_URL}' class='btn parent-cert-btn' target='_blank'>
         <span class='lnr lnr-download parent-cert-btn__icon'></span>${'  '}${T(msg.ba.dl_parent_agree)}
       </a>
      </div>
      
      <div class='form-item'>
        <label class='form-label'>${T(msg.ba.parent_mobile)}</label>
        <div class='form-control'>
          <input
            class='inline ${ifInvalid(bank_account.phone)}'
            type='text'
            name='phone'
            placeholder='${T(msg.ba.mobile)}'
            value='${UtilS.escape(bank_account.phone) || ''}'
            maxlength='15'
            disabled
          />
        </div>
      </div>

      <div class='form-item'>
        <label class='form-label'>${T(msg.ba.parent_birthday)}</label>
        <div class='form-control'>
          <input
            class='inline ${ifInvalid(bank_account.parent_birth)}'
            type='text'
            name='parent_birth'
            placeholder='${T(msg.ba.birthday)}'
            value='${UtilS.escape(bank_account.parent_birth) || ''}'
            maxlength='15'
            disabled
          />
        </div>
      </div>

      <div class='form-item'>
        <label class='form-label'>${T(msg.ba.parent_name)}</label>
        <div class='form-control'>
          <input
            class='inline ${ifInvalid(bank_account.parent_name)}'
            type='text'
            name='parent_name'
            placeholder='${T('이름')}'
            value='${UtilS.escape(bank_account.parent_name) || ''}'
            maxlength='15'
            disabled
          />
        </div>
      </div>

      ${makeImageUploadFormHtml(info, T(msg.ba.btn_parent_agree), 'parent_agree_url')}
    </div>
  `;
};

const makePersonalInfoHtml = (info, type, index) => {
  const { bank_account, registration_code_parts } = info;
  const is_valid = every(identity, registration_code_parts);
  const is_adult = type !== BANK_TYPES.KID;
  const guide = [T(msg.ba.info_1), T(msg.ba.info_2)];

  return legacyHtml`
    <div class="form-group" data-account-type="person">
      <span class="title">${index}. ${T(msg.ba.title_2)}</span>
      ${
        is_adult
          ? html` <div class="guide-wrapper">
              ${makeGuideListHtml(guide)}
              <button class="btn btn-primary phone">${T(msg.ba.mo_cert)}</button>
              <input type="text" name="phone" data-show="false" value="${UtilS.escape(bank_account.phone)}" />
            </div>`
          : ''
      }
      ${makeNameFormHtml(bank_account.user_name, is_valid)}
      ${makeRegistNumFormHtml(registration_code_parts, is_valid)}
      ${makeImageUploadFormHtml(info, T(T(msg.ba.pic_id)), 'personal_id_url')}
    </div>
  `;
};

const makeForeignerInfoHtml = ({ bank_account }, index) => legacyHtml`
  <div class="form-group" data-account-type="foreign">
    <span class="title">${index}. ${T(msg.ba.title_2)}</span>
    ${makeNameFormHtml(bank_account.user_name)}
    <div class="form-item">
      <label class="form-label">${T(msg.ba.country)}</label>
      <div class='form-control'>
        <input
          class="${ifInvalid(bank_account.nationality)} inline"
          type="text"
          name="nationality"
          placeholder="${msg.ba.sel_country}"
          value="${UtilS.escape(bank_account.nationality) || ''}"
          maxlength="15"
        />
      </div>
    </div>
  </div>
`;

// account type form
const makeKidTypeHtml = (info, type) => legacyHtml`
  ${makeBankAccountInfoHtml(info, type, 2)}
  ${makePersonalInfoHtml(info, type, 3)}
  ${makeParentFormGroupHtml(info, 4)}
  ${makePrivateTermAgreeHtml(info, BANK_TYPES.KID, 5)}
`;

export const makePersonTypeHtml = (info, type) => legacyHtml`
  ${makeBankAccountInfoHtml(info, type, 2)}
  ${makePersonalInfoHtml(info, type, 3)}
  ${makePrivateTermAgreeHtml(info, BANK_TYPES.PERSON, 4)}
`;

export const makeCompanyTypeHtml = (info, type) => legacyHtml`
  ${makeCheckInvoiceHtml(info, 2)}
  <div class='dynamic-form-sub'>
    ${info.bank_account.type == type ? makeCompanySubHtml(info, type) : ''}
  </div>
`;

export const makeCompanySubHtml = (info, type) => legacyHtml`
  ${makeBankAccountInfoHtml(info, type, 3)}
  ${makeCompanyInfoFormHtml(info, 4)}
  ${makePrivateTermAgreeHtml(info, BANK_TYPES.COMPANY, 5)}
`;

const makeForeignTypeHtml = (info, type) => legacyHtml`
  ${makeCheckForeignerConditionHtml(info, 2)}
  <div class='dynamic-form-sub'>
  ${info.bank_account.type == type ? makeForeignTypeSubHtml(info, type) : ''}
  </div>
`;

export const makeForeignTypeSubHtml = (info, type) => legacyHtml`
  ${makeBankAccountInfoHtml(info, type, 3)}
  ${makeForeignerInfoHtml(info, 4)}
  ${makePrivateTermAgreeHtml(info, BANK_TYPES.FOREIGN, 5)}
`;

export const makeTypeFormHtml = (type, info) =>
  match(type)
    .case(equals(BANK_TYPES.KID))(() => makeKidTypeHtml(info, type))
    .case(equals(BANK_TYPES.PERSON))(() => makePersonTypeHtml(info, type))
    .case(equals(BANK_TYPES.COMPANY))(() => makeCompanyTypeHtml(info, type))
    .case(equals(BANK_TYPES.FOREIGN))(() => makeForeignTypeHtml(info, type))
    .else(() => '');

export const makeBankSettingsBodyHtml = ({ bank_account, store_id, store_domain_name } = {}) => {
  const info = {
    business_license_parts: bank_account.business_license_number.split('-'),
    registration_code_parts: bank_account.registration_code.split('-'),
    is_paypal: isPaypal(bank_account.bank),
    is_foreign: bank_account.type == BANK_TYPES.FOREIGN,
    store_id,
    bank_account: omit('registration_code', bank_account),
    store_domain_name,
  };

  return html`
    ${bank_account.is_locked
      ? html`
          <div class="warning-box">
            <p>${T(msg.ba.warning)}</p>
          </div>
        `
      : ''}
    <div class="header">
      <h2>${T(messages.menus.bank_account)}</h2>
      <p>${T(msg.ba.desc)}</p>
    </div>

    <div class="body bank_account" data-fx-json="${$dataStr(info)}">
      <div class="save spinner" data-show="false"></div>
      <div class="form bank_settings account_info">
        <div class="form-item" data-show="false">
          <input name="id" class="bank_account_id" type="text" value="${bank_account.id}" />
        </div>

        <div class="form-group">
          <span class="title">1. ${T(msg.ba.title_1)}</span>
          <div class="form-item radio-group vertical">
            <label>
              <input type="radio" name="type" value="kid" ${ifChecked(bank_account.type == BANK_TYPES.KID)} />
              ${T(msg.ba.opt_child)}
            </label>
            <label>
              <input
                type="radio"
                name="type"
                value="person"
                ${ifChecked(bank_account.type == BANK_TYPES.PERSON)}
              />
              ${T(msg.ba.opt_personal)}
            </label>
            <label>
              <input
                type="radio"
                name="type"
                value="company"
                ${ifChecked(bank_account.type == BANK_TYPES.COMPANY)}
              />
              ${T(msg.ba.opt_company)}
            </label>
            <label>
              <input type="radio" name="type" value="foreign" ${ifChecked(info.is_foreign)} />
              ${T(msg.ba.opt_foreigner)}
            </label>
          </div>
        </div>

        <div class="form-container">
          <div class="dynamic-form">${makeTypeFormHtml(bank_account.type, info)}</div>

          <div class="buttons" data-show="${!bank_account.is_locked}">
            <button class="btn btn-primary account_save_btn">${T(msg.ba.save)}</button>
          </div>
        </div>
      </div>
    </div>
  `;
};
