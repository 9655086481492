import { html } from 'fxjs/es';
import { emptyCheckBoxIcon, fullCheckBoxIcon } from './icon.js';

/**
 * @param {string} name
 * @param {boolean} is_active
 * @param {boolean} active  - Deprecated: active
 * @param {string} title
 * @param {number|string} id
 * @param color
 * @return {*}
 */
export const checkbox = ({
  name,
  is_active,
  /* @deprecated */ active,
  title,
  id = '',
  color,
  typo_klass,
} = {}) => {
  return html`
    <label class="mshop-core__checkbox" data-name="${name}">
      <input
        class="mshop-core__checkbox__input"
        type="checkbox"
        id="${id}"
        ${is_active || active ? 'checked' : ''}
        name="${name || ''}"
      />
      <span class="mshop-core__checkbox__box-wrapper">
        ${emptyCheckBoxIcon('mshop-core__checkbox__empty')}
        ${fullCheckBoxIcon('mshop-core__checkbox__full', color)}
      </span>
      <span class="mshop-core__checkbox__title ${typo_klass || ''}">${title}</span>
    </label>
  `;
};
