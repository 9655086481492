import { $attr, $delegate, $find, $hide, $setAttr, $show } from 'fxdom/es';
import { go, debounce } from 'fxjs/es';
import { MShopShareFramePopUpF } from '../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopStudioPcInfoF } from '../../../MShop/Studio/PcInfo/F/Function/module/MShopStudioPcInfoF.js';
import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { STUDIO_UPDATE_NOTE_URL } from '../S/constant.js';
import { useStickySidebar } from './sticky_sidebar.ts';

const BREAKPOINT = 1024;

const handleChangeLanguage = (evt) => {
  const { value } = evt.detail;
  const { pathname, search } = location;
  const newPath = pathname.replace(/^\/(kr|en|jp)/, `/${value}`);
  location.href = newPath + search;
};

const handleCloseMenu = () => {
  $show($find('#creator_settings_body', document.body));
  const $menu = $find('#creator_settings_menu', document.body);
  $setAttr({ 'menu-open': false }, $menu);
};

const handleClickMenuBtn = () => {
  const $body = $find('#creator_settings_body', document.body);
  const $menu = $find('#creator_settings_menu', document.body);
  const isOpen = $attr('menu-open', $menu) === 'true';
  $setAttr({ 'menu-open': !isOpen }, $menu);

  if (isOpen) {
    $show($body);
  } else {
    $hide($body);
  }
};

const handleWebviewClose = () => {
  MShopUtilF.postMessage({ goBack: true });
};

const handleUpdateNode = async (e) => {
  e.preventDefault();
  const is_first_visit = !window.localStorage.getItem('studio-first-visit');

  if (is_first_visit) {
    window.localStorage.setItem('studio-first-visit', true);
    if (
      !(await MShopShareFramePopUpF.confirm({
        title: T('✨ 업데이트 노트 ✨'),
        body: `마플샵의 업데이트와 개선사항을 알려드려요.<br />앞으로 더욱 새로워질 마플샵을 만나보세요!`,
        cancel: T('mshop::Close'),
        ok: T('보러 가기'),
      }))
    ) {
      return;
    }
  }
  window.open(STUDIO_UPDATE_NOTE_URL, '_blank');
};

const handleStickySidebar = (stickySidebar) => {
  if (window.innerWidth <= BREAKPOINT) {
    stickySidebar.destroy();
  } else {
    stickySidebar.init();
  }
};

const handleMenuVisibleOnResize = () => {
  if (window.innerWidth > BREAKPOINT) {
    handleCloseMenu();
  }
};

export const defnCommonEvents = (el) => {
  const stickySidebar = useStickySidebar();
  const resizeHandler = () => {
    handleStickySidebar(stickySidebar);
    handleMenuVisibleOnResize();
  };

  // init sticky sidebar
  handleStickySidebar(stickySidebar);

  window.addEventListener('resize', debounce(resizeHandler, 200));

  return go(
    el,
    $delegate('change.language', '[is="dropdown"].lang', handleChangeLanguage),
    $delegate('click', '#toggle_menu_btn', handleClickMenuBtn),
    $delegate('click', '#creator_settings_menu .close-btn .btn', handleCloseMenu),
    $delegate('click', '.seller-studio-closer .btn', handleWebviewClose),
    $delegate('click', '.studio-pc-info__close', MShopStudioPcInfoF.closePopup),
    $delegate('click', '.lnb-update-note', handleUpdateNode),
  );
};
