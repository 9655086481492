import { defaultTo, find, go, head, html, map, reduce, sel, strMap } from 'fxjs/es';
import { CreatorCrewPbMeConstantS } from '../../../../Creator/Crew/PbMe/S/Constant/module/CreatorCrewPbMeConstantS.js';
import { makeProductStatusHtml } from '../../../../Creator/Product/S/tmpl.js';
import { getProductStatus } from '../../../../Creator/Setting/S/fs.js';
import { GoodsTypeS } from '../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { MShopAppProductItemS } from '../../../../MShop/App/Product/Item/S/Function/module/MShopAppProductItemS.js';
import { MShopAppProductItemTmplS } from '../../../../MShop/App/Product/Item/S/Tmpl/module/MShopAppProductItemTmplS.js';
import { MShopUtilConstantS } from '../../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { creatorPriceOfProduct } from '../../../../ProductColorPrice/S/fs.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { MpShopProductS } from '../Function/module/MpShopProductS.js';

const productTag = ({ sell_start_at, sell_end_at, quantity, order_count, is_sptg }) => {
  if (is_sptg) return { id: 'limited_edition', text: T('mshop::ProductBadge::한정') };
  if (!quantity && !sell_start_at) return {};
  if (quantity && quantity <= order_count) return { id: 'sold_out', text: T('mshop::ProductBadge::매진') };
  if (quantity && !sell_end_at) return { id: 'limited_edition', text: T('mshop::ProductBadge::한정') };

  const now = Date.now();
  const sell_start = new Date(sell_start_at).getTime();
  const sell_end = new Date(sell_end_at).getTime();
  if (now < sell_start) return { id: 'coming', text: T('mshop::ProductBadge::출시예정') };
  if (now < sell_end) return { id: 'limited_edition', text: T('mshop::ProductBadge::한정') };
  return { id: 'sold_out', text: T('mshop::ProductBadge::매진') };
};

const productWrap =
  ({ product, product_type }) =>
  (...funcs) => {
    const { id, sp_id, store_id, goods_type_id } = product;
    const is_target_blank = ['plus', 'crew-mall', 'event'].includes(product_type);
    const is_setting = ['setting', 'arrangement'].includes(product_type);
    const { id: tag_id, text: tag_text } = productTag(product);
    return go(
      { tag_id, tag_text, product, is_target_blank, is_setting },
      (opt) => reduce((m, f) => `${m}${f(opt)}`, '', funcs),
      (inner_html) => legacyHtml`<div
      class="mp-shop-product-item"
      data-tag-id="${tag_id || (!product['is_use_creator' + G._en] && 'sold_out')}"
      data-sp_id="${sp_id}"
      data-id="${id}"
      data-store_id="${store_id}"
      data-goods_type_id="${goods_type_id}"
      data-product-type="${product_type}"
    >
      ${inner_html}
    </div>`,
    );
  };

const productAnchorWrap = ({ is_setting, domain_name, id, is_target_blank }, inner_html) =>
  is_setting
    ? inner_html
    : legacyHtml`<a
        href="/${T.lang}/${domain_name}/products/${id}"
        class="mp-shop-product-anchor"
        ${is_target_blank ? ' target="_blank"' : ''}
        >${inner_html}</a
      >`;

const productName = ({ name, base_product_name, bp_option_title, cate_item_name }) =>
  html`<div class="mp-shop-product-name">
      ${name || MShopAppProductItemTmplS.makeBpName(base_product_name, bp_option_title)}
    </div>
    ${cate_item_name ? html`<div class="mp-shop-product-cate-item-name">${cate_item_name}</div>` : ''}`;

const productTop = ({ product, is_target_blank, is_setting }) => {
  const {
    domain_name,
    id,
    thumbnail_ratio,
    thumbnails,
    base_product_color_face_url,
    pb_me_stores_product = {},
    goods_type_id,
    crew_id,
    all_out_of_stock,
  } = product;

  const is_afreeca = crew_id === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID;
  const product_status = getProductStatus(product, is_afreeca);
  /*
   * TODO pb_me_stores_product 디자인
   * */
  return productAnchorWrap(
    { is_setting, domain_name, id, is_target_blank },
    html`
      <div class="mp-shop-product-top">
        <div class="mp-shop-product-image">
          ${thumbnail(
            { thumbnail_ratio, thumbnails, base_product_color_face_url, id },
            {
              data_src: true,

              width: (is_target_blank ? 305 : 265) * 2,
            },
          )}
          <div class="mp-shop-product-status">
            ${all_out_of_stock ? makeProductStatusHtml('out_of_stock') : ''}
            ${is_setting ? makeProductStatusHtml(product_status, is_afreeca) : ''}
          </div>
          ${GoodsTypeS.isNotPod(goods_type_id)
            ? html`
                <div
                  class="mp-shop-product-top__pb-status"
                  data-pb-status="${pb_me_stores_product?.pb_status}"
                >
                  ${CreatorCrewPbMeConstantS.STATUS_TO_NAME[pb_me_stores_product?.pb_status]}
                </div>
              `
            : ''}
        </div>
        ${productName(product)}
      </div>
    `,
  );
};

const productMiddle = ({ product, is_target_blank }) => {
  const { base_product_name, domain_name, store_name, bp_option_title } = product;
  return legacyHtml`
    <div class="mp-shop-product-middle">
      ${
        is_target_blank
          ? `<div class="mp-shop-product-store"><a href="/${T.lang}/${domain_name}" target="_blank">#${store_name}</a></div>`
          : `<div class="mp-shop-product-store">${MShopAppProductItemTmplS.makeBpName(
              base_product_name,
              bp_option_title,
            )}</div>`
      }
    </div>
  `;
};

const productBottomHtml = ({ product, is_target_blank, is_setting }) => {
  const {
    domain_name,
    id,
    sell_start_at,
    sell_end_at,
    quantity,
    is_quantity_public,
    order_count,
    is_sptg,
    goods_type_id,
  } = product;
  const badges = MShopAppProductItemS.setBadge(
    { sell_start_at, sell_end_at, quantity, is_quantity_public, order_count, is_sptg },
    true,
  );
  return productAnchorWrap(
    { is_setting, domain_name, id, is_target_blank },
    html`
      <div class="mp-shop-product-bottom">
        <div class="mp-shop-product-price mp_currency2">${UtilS.commify(creatorPriceOfProduct(product))}</div>
        ${badges?.length
          ? html`<div class="mp-shop-product-limited">${map(({ name }) => name, badges).join(' | ')}</div>`
          : ''}
        ${GoodsTypeS.isTpl(goods_type_id) ? html`<div style="color:red;">입고 상품</div>` : ''}
      </div>
    `,
  );
};

export const thumbnailUrl = (p) =>
  go(
    p,
    sel('thumbnails.value'),
    find(sel('is_thumb')),
    sel('url'),
    defaultTo(sel('base_product_color_face_url', p)),
  );

const isOdd = (r) => r % 2 == 1;
const to = 10;
const of = 5;
const power = Math.pow(to, of);
const toFixed3 = (r) => Math.round(parseFloat((r * 100 * power).toFixed(of))) / power;

export const thumbnail = (
  { thumbnail_ratio, thumbnails, base_product_color_face_url, without_max_style = false },
  opt = {},
) => {
  const thumbnail =
    opt.thumbnail_image || go(thumbnails, sel('value'), find(sel('is_thumb')), (v) => v || head(v)) || {};
  if (thumbnail.composite_template_id) thumbnail_ratio = 1;

  const ratio_size = Number.parseFloat(thumbnail_ratio) * 100 || 84;
  const is_100 = ratio_size == 100;
  const width = is_100
    ? opt.width
    : (() => {
        const val = Math.round((opt.width * ratio_size) / 100);
        return val + (isOdd(opt.width) == isOdd(val) ? 0 : 1);
      })();
  const max_size = (is_100 ? ratio_size : toFixed3(width / opt.width)) || 100;

  const url = thumbnail.url || base_product_color_face_url;
  const thumbnail_style = `
    max-width:${max_size}%;
    max-height:${max_size}%;
    ${url?.indexOf('data:image') > -1 ? `width:${width}px;` : ''}
  `.replace(/\s/g, '');
  const padding_size = (opt.width - width) / 2 || void 0;
  const bg = 'f6f6f6';

  const url_data = {
    url,
    width: width || void 0,
    padding_size,
    quality: 92,
    bg,
  };

  if (
    /* @@thumbnail is_pb 체크@@ */
    thumbnail.is_pb &&
    thumbnail.width !== thumbnail.height &&
    // thumbnail을 만들면서 이미지가 정사각형이어도 1px정도가 틀어지는 예외 케이스가 발생했습니다.
    Math.abs(thumbnail.width - thumbnail.height) > 1
  ) {
    url_data.r = 80;
  }

  return html`
    <span class="mp-product-img-bg-wrap" style="background-color: #${bg}">
      <img
        class="mp-product-img${opt.data_src ? ' lazy-src' : ''}"
        ${opt.data_src ? 'data-src' : 'src'}="${MpShopProductS.setOnDemandJpegUrl(url_data)}"
        style="${is_100 || without_max_style ? '' : thumbnail_style}"
        ${UtilS.addAttrIf(
          thumbnail.composite_template_id,
          'data-composite_template_id',
          thumbnail.composite_template_id,
        )}
        alt="${opt?.img_alt}"
      />
    </span>
  `;
};

export const item = (product_type) => (product) =>
  productWrap({
    product,
    product_type,
  })(productTop, productMiddle, productBottomHtml);

export const itemForEvent = (product_type) => (product) =>
  productWrap({
    product,
    product_type,
  })(productTop, productMiddle);

export const itemForCrew = (product_type, width) => (product) =>
  productWrap({
    product,
    product_type,
  })(({ product }) => {
    const { thumbnails, store_id, goods_type_id, store_name, domain_name, crew_id } = product;
    const is_afreeca = crew_id === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID;
    const product_status = getProductStatus(product, is_afreeca);
    return html`
      <div class="mp-shop-product-images" data-flex-wrap="${sel('value.length', thumbnails) > 2}">
        ${strMap(
          (thumbnail_image) => html` <div class="mp-shop-product-image">
            ${thumbnail(product, { thumbnail_image, width })}
          </div>`,
          sel('value', thumbnails),
        )}
        <div class="mp-shop-product-status">${makeProductStatusHtml(product_status, is_afreeca)}</div>
      </div>
      ${productName(product)}
      <div class="mp-shop-product-store">
        <a
          href="/${T.lang}/${domain_name}/settings/${GoodsTypeS.isMps(goods_type_id)
            ? 'products'
            : 'pb_products'}"
          target="_blank"
          >#${UtilS.escape(store_name)}</a
        >
      </div>
      <div class="mp-shop-product-store">
        <a class="to-crew-store" href="/${T.lang}/_/crews/${'all'}/stores/detail/${store_id}" target="_blank"
          >스토어 편집</a
        >
      </div>
      ${GoodsTypeS.isDtg(goods_type_id)
        ? html`
            <div class="mp-shop-product-store">
              <button class="mp-shop-product-store__btn-download--digital-product">
                <img
                  class="btn-download--digital-product--icon"
                  src="//s3.marpple.co/files/u_2308668/2023/6/original/ce2914334ab6bc431ef64dba3266b7d25a512a861.png"
                  alt=""
                />다운로드
              </button>
            </div>
          `
        : ''}
    `;
  });
