import { html, View } from 'rune-ts';
import { HeartFillIcon } from '../../../../atoms/Icon';
import { ProductCardData, ProductCardTheme } from '../../../../../../features/ProductList/type';
import klass from './ProdcutLikeButton.module.scss';

type LikeButtonData = Pick<ProductCardData, 'is_liked'>;

export class ProductLikeButton extends View<LikeButtonData> {
  constructor(
    data: LikeButtonData,
    private option: {
      theme: ProductCardTheme;
      klass?: string;
    },
  ) {
    super(data, option);
  }

  protected override template() {
    const active_klass = this.getActiveKlass();
    const theme_klass = this.getThemeKlass();

    return html`
      <button class="${klass.like} ${active_klass} ${theme_klass} ${this.option.klass ?? ''}">
        ${HeartFillIcon}
      </button>
    `;
  }

  override redraw(): this {
    if (this.data.is_liked) {
      this.element().classList.add(klass.active);
    } else {
      this.element().classList.remove(klass.active);
    }
    return this;
  }

  setActivation(active: boolean) {
    this.data.is_liked = active;
  }

  private getThemeKlass() {
    const theme: Record<ProductCardTheme, string> = {
      light: klass.theme_light,
      dark: klass.theme_dark,
      color: klass.theme_color,
    };
    return theme[this.option.theme];
  }

  private getActiveKlass() {
    return this.data.is_liked ? klass.active : '';
  }
}
