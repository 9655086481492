import { html } from 'fxjs/es';
import { UtilImageS } from '../../../../Util/Image/S/Function/module/UtilImageS.js';

export const companyPickUpBadge = ({ logo_img, content, klass }) => {
  const resized_img_url = UtilImageS.getResizedUrl({
    url:
      logo_img ||
      '//s3.marpple.co/files/u_2308668/2023/7/original/d4b25ed024775902b15cef981af63b7b04f2d8f41.png',
    format: 'webp',
    width: 24,
    height: 24,
  });

  return html`
    <div class="mshop-inhouse-badge ${klass}">
      <img class="mshop-inhouse-badge__img" src="${resized_img_url}" alt="logo" />
      <span class="mshop-inhouse-badge__content">${content}</span>
    </div>
  `;
};
