import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { PreDiscountPriceInterval } from '../Constant/preDiscountConst.js';

export const isPreDiscountPriceValid = ({ profit, price, pre_discount_price: _pre_discount_price }) => {
  const pre_discount_price = PriceS.price(_pre_discount_price);

  if (!pre_discount_price) {
    return true;
  }

  // 최소 단위로 떨어지지 않음
  if (pre_discount_price % PreDiscountPriceInterval > 0) {
    return false;
  }

  return profit + price + PreDiscountPriceInterval <= pre_discount_price;
};

/**
 * 마케팅 할인가 지원 여부
 * @return {boolean}
 */
export const isPreProductDiscountSupported = () => {
  return UtilS.isNessApp() && T.lang === 'kr';
};

/**
 * @param {number} pre_discount_rate
 */
export const getPreDiscountRateDisplayValue = (pre_discount_rate) => {
  if (Number.isInteger(pre_discount_rate)) {
    if (pre_discount_rate > 1) return pre_discount_rate;
    return '-';
  }
  return '';
};

/**
 * @param {number} pre_discount_price
 */
export const getPreDiscountPriceDisplayValue = (pre_discount_price) => {
  if (!pre_discount_price) {
    return '';
  }
  return UtilS.commify(pre_discount_price);
};

/**
 * @param pre_discount_price 할인 전 가격
 * @param price 기준 가격
 * @return {null|number|NaN}
 */
export const calcDiscountedRateByPercent = (pre_discount_price, price) => {
  const discounted_rate = (pre_discount_price - price) / pre_discount_price;
  return Math.floor(discounted_rate * 100);
};
