import { each } from 'fxjs/es';
import { AdScriptTwitterF } from '../../../../../../modules/AdScript/Twitter/F/Function/module/AdScriptTwitterF.js';

if (ENV.is_prod) {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    dataLayer.push(arguments);
  };

  gtag('js', new Date());
  gtag('config', 'UA-161251038-1', { debug_mode: true });
  gtag('config', 'AW-881715956');

  try {
    // 또간집 바르닭 81819
    if (box.sel('store_id') == 81819) {
      gtag('config', 'G-56F86FZ4HF');
    }
    if (box.sel('crew_domain_name') == 'ridi') {
      gtag('config', 'G-FHK9DSL7SE');
    }

    if (box.sel('ga_services')) {
      each(({ value }) => {
        gtag('config', value);
      }, box.sel('ga_services'));
    }
  } catch (e) {}

  /* End naver log */
  if (
    window.naver_wcslog &&
    ENV.lang == 'kr' &&
    !$.has_class(document.querySelector('html'), 'mp_order_complete')
  )
    window.naver_wcslog();
  /* End naver log */

  AdScriptTwitterF.init();
  AdScriptTwitterF.pageView();

  /* facebook pixel code */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '2466900390292503');
  fbq('track', 'PageView');
  /* End facebook pixel code */

  // 틱톡
  (function () {
    const ta = document.createElement('script');
    ta.type = 'text/javascript';
    ta.async = true;
    ta.src = 'https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=BTDIEG1JRJ77LNGV1AG0';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ta, s);
  })();
}
