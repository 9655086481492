import anime from 'animejs';
import {
  $addClass,
  $closest,
  $height,
  $next,
  $on,
  $prev,
  $qs,
  $qsa,
  $removeClass,
  $scrollTop,
  $setAttr,
  $setCss,
  $setScrollTop,
} from 'fxdom/es';
import { each, every, find, go, throttle } from 'fxjs/es';
import UAParser from 'ua-parser-js';

export const util = () => {};
const parser = new UAParser();
const is_mobile_cache = ['mobile', 'tablet'].includes(parser.getDevice().type);

export const isWhale = () => parser.getBrowser()?.name?.toLowerCase() === 'whale';
export const isMobile = () => is_mobile_cache;
export const isIOS = () => /IOS/i.test(parser.getOS()?.name);
export const isAndroid = () => /Android/i.test(parser.getOS()?.name);
export const getOSVersion = () => parser.getOS()?.version;
export const isApp = () =>
  !!window.ReactNativeWebView && window.navigator.userAgent.indexOf('MarppleShopApp') > -1;

export const isCreatorApp = () => isApp() && G.collabo_type === 'creator';
export const isCreatorAndroidApp = () => isApp() && G.collabo_type === 'creator' && isAndroid();

export const shuffle = (array) => {
  const result = [...array];

  for (let index = result.length - 1; index > 0; index--) {
    const randomPosition = Math.floor(Math.random() * (index + 1));

    const temporary = result[index];
    result[index] = result[randomPosition];
    result[randomPosition] = temporary;
  }

  return result;
};

const full_uri_reg = /^(\/\/|https:\/\/|http:\/\/)/;
const makeOriginURI = (data) => {
  if (data?.screen?.uri && !full_uri_reg.test(data.screen.uri))
    data.screen.uri = `${location.origin}${data.screen.uri}`;
  else
    go(
      data,
      Object.assign,
      each(([, { params = {} }]) => {
        if (params?.uri && !full_uri_reg.test(params.uri)) params.uri = `${location.origin}${params.uri}`;
      }),
    );

  return data;
};

export const postMessage = throttle(
  (data) =>
    go(data, makeOriginURI, (msg) =>
      isCreatorApp()
        ? window.ReactNativeWebView.postMessage(JSON.stringify(msg))
        : ENV.is_prod
        ? undefined
        : console.log(msg),
    ),
  200,
);

export const getCurrentCrewDomain = () => {
  const { crew_domain_name } = box();
  return crew_domain_name || 'all';
};

export const bodyFixed$ = (be_fix) => {
  const html_element = document.querySelector('html');
  if (be_fix) {
    if (!isMobile() && $height(document.body) > window.innerHeight)
      $setCss({ 'overflow-y': 'scroll' }, html_element);
    document.body.fixed_top = $scrollTop(window);
    go(document.body, $addClass('body-fixed'), $setCss({ top: -document.body.fixed_top }));
  } else {
    $setCss({ 'overflow-y': '' }, html_element);
    go(document.body, $removeClass('body-fixed'), $setCss({ top: '' }));
    $setScrollTop(document.body.fixed_top, window);
    document.body.fixed_top = void 0;
  }
};

export const moveScrollToTop = (duration = 300) =>
  anime({ targets: 'html, body', scrollTop: 0, duration, easing: 'easeInQuad' }).finished;

export const clipboard = (text) => {
  const _clipboard = (function (window, document, navigator) {
    let textArea;

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      let range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }

    function copyToClipboard() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }

    return {
      copy: (text) => {
        createTextArea(text);
        selectText();
        copyToClipboard();
      },
    };
  })(window, document, navigator);

  _clipboard.copy(text);
};

export const loginHref = () =>
  (location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`);
export const toggleChannelTalk = (is_view) => {
  const ch_plugin = $qs('#ch-plugin');
  if (ch_plugin) {
    const style = is_view ? 'z-index: 10000000 !important' : 'display: none !important';
    $setAttr({ style }, ch_plugin);
  }
};
export const channelTalkOn = () => {
  toggleChannelTalk(true);
};

export const channelTalkOff = () => {
  toggleChannelTalk(false);
};

const _isWheelNumberBlurFn = ({ target, selector }) => {
  const scroll_el = $closest(selector, target);
  if (!scroll_el || scroll_el.____is_wheel_number_blur) return;
  scroll_el.____is_wheel_number_blur = true;
  $on('wheel', () => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  })(scroll_el);
};

export const isWheelNumberBlur = (selectors = []) => {
  document.addEventListener('wheel', () => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });

  document.addEventListener('click', ({ target }) => {
    each((selector) => _isWheelNumberBlurFn({ target, selector }), [...selectors, '.is-wheel-number-blur']);
  });
};

/**
 * 스크롤 방지
 */
export const preventScroll = () => {
  const currentScrollY = window.scrollY;
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
  document.body.style.top = `-${currentScrollY}px`; // 현재 스크롤 위치
  document.body.style.overflowY = 'scroll';
};

/**
 * 스크롤 허용
 */
export const allowScroll = () => {
  document.body.style.position = '';
  document.body.style.width = '';
  document.body.style.top = '';
  document.body.style.overflowY = '';
};

export const $toString = (el) => {
  if (el instanceof HTMLElement) {
    const div = document.createElement('div');
    div.appendChild(el.cloneNode(true));
    return div.innerHTML;
  } else {
    console.error('Invalid input: Not an HTMLElement');
    return el;
  }
};

/**
 * @deprecated runeHydration 을 사용해주세요.
 */
export const checkReadyRuneHydration = (View) => {
  const view_els = $qsa(`[data-rune="${View}"]`);
  if (!view_els.length) return false;

  return go(
    view_els,
    every((view_el) => $next(`script.__RUNE_DATA__.${View}`, view_el)),
  );
};

/**
 * hydration되지 않은 View를 찾아서 hydration 해줍니다.
 * hydrationRune(ViewClass1, ViewClass2, ViewClass3);
 * @param views
 */
export const hydrationRune = (...views) => {
  go(
    $qsa('script.__RUNE_DATA__'),
    each((rune_script_el) => {
      const rune_view_el = $prev(rune_script_el);
      const MatchedView = find((view) => rune_view_el.dataset.rune === view.name, views);
      MatchedView?.createAndHydrate(rune_view_el);
    }),
  );
};

export const $setScrollTopAnime = ({ target, top, duration }) => {
  return anime({
    targets: target,
    scrollTop: top || 0,
    duration: duration || 0,
    easing: 'easeInOutQuart',
  }).finished;
};
