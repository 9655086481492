import { html, View } from 'rune-ts';
import klass from './ProductThumbnail.module.scss';
import { htmlIf } from '../../../../shared/util';
import { ProductListTypeS } from '../../../../features/ProductList/outbound/share';
import { getResizedUrl } from '../../../../shared/util/image';

const isOdd = (r: number) => r % 2 == 1;
const to = 10;
const of = 5;
const power = Math.pow(to, of);
const toFixed3 = (r) => Math.round(parseFloat((r * 100 * power).toFixed(of))) / power;

type ResizeUrlData = {
  url: string;
  quality: number;
  bg: string;
  r?: number;
  width?: number;
  padding_size?: number;
  format: string;
};

export class ProductThumbnail extends View<ProductListTypeS.ProductThumbnailData> {
  state: ProductListTypeS.ProductThumbnailOption & {
    thumbnail: ProductListTypeS.ProductThumbnailValue;
    thumbnail_style?: string;
  };

  resize_url_data: ResizeUrlData;

  static fillData(data: ProductListTypeS.ProductThumbnailData) {
    const default_value = {
      thumbnail_ratio: 1,
    } as const;

    return {
      ...data,
      thumbnail_ratio: data.thumbnail_ratio ?? default_value.thumbnail_ratio,
    };
  }

  constructor(data: ProductListTypeS.ProductThumbnailData, option: ProductListTypeS.ProductThumbnailOption) {
    super(data, option);

    const filled_data = ProductThumbnail.fillData(this.data);
    const thumbnail = option.thumbnail || filled_data.thumbnails?.value?.find((thumb) => thumb.is_thumb);

    if (!thumbnail) {
      throw new TypeError('`thumbnail` not found');
    }

    const url = option.thumbnail_image ?? thumbnail.url;
    if (!url) {
      throw new TypeError('`thumbnail.url` not found');
    }

    if (thumbnail.composite_template_id) {
      filled_data.thumbnail_ratio = 1;
      this.data.thumbnail_ratio = 1;
    }

    const ratio_size = filled_data.thumbnail_ratio * 100 || 84;
    const is_100 = ratio_size == 100;
    const _width = is_100
      ? option.resize_width
      : (() => {
          const val = Math.round((option.resize_width * ratio_size) / 100);
          return val + (isOdd(option.resize_width) == isOdd(val) ? 0 : 1);
        })();

    const max_size = (is_100 ? ratio_size : toFixed3(_width / option.resize_width)) || 100;

    const _thumbnail_style = `
    max-width:${max_size}%;
    max-height:${max_size}%;
    ${url.indexOf('data:image') > -1 ? `width:${_width}px;` : ''}
  `.replace(/\s/g, '');
    const padding_size = (option.resize_width - _width) / 2;
    const background_color = 'f6f6f6';

    this.resize_url_data = {
      url,
      width: _width,
      padding_size,
      quality: 92,
      bg: background_color,
      format: 'webp',
    };

    if (
      /* @@thumbnail is_pb 체크@@ */
      thumbnail.is_pb &&
      thumbnail.width &&
      thumbnail.height &&
      thumbnail.width !== thumbnail.height &&
      // thumbnail을 만들면서 이미지가 정사각형이어도 1px정도가 틀어지는 예외 케이스가 발생했습니다.
      Math.abs(thumbnail.width - thumbnail.height) > 1
    ) {
      this.resize_url_data.r = 80;
    }

    this.state = {
      thumbnail,
      klass: option?.klass ?? '',
      is_lazy: option?.is_lazy,
      img_alt: option?.img_alt ?? '',
      resize_width: option.resize_width,
      thumbnail_style: is_100 || option.without_max_style ? undefined : _thumbnail_style,
    };
  }

  override template() {
    const image_src = getResizedUrl(this.resize_url_data);

    return html`
      <span
        class="${klass.product_thumbnail} ${this.state.klass}"
        style="background-color: #${this.resize_url_data.bg}"
      >
        <img
          class="${klass.image} ${htmlIf('lazy-src', !!this.state.is_lazy)}"
          ${this.state.is_lazy ? 'data-src' : 'src'}="${image_src}"
          ${this.state.thumbnail
            ? html`data-composite_template_id="${this.state.thumbnail.composite_template_id}"`
            : ''}
          alt="${this.state.img_alt}"
          style="${this.state.thumbnail_style}"
        />
      </span>
    `;
  }
}
