import { addDays, addHours, differenceInMonths, isBefore } from 'date-fns';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';

/**
 * @param public_at
 * @return {'new' | null}
 */
export const getNessOpenInfoBadge = ({ public_at }) => {
  const new_badge = new NewBadge(public_at);
  if (new_badge.name) {
    return new_badge.type;
  }
  return null;
};

export class ProductBadgeValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ProductBadgeValidationError';
  }
}

export class ProductBadge {
  constructor(type) {
    if (!ProductBadge.isValidType(type)) {
      throw new Error(`Invalid ProductBadge Type: ${type}`);
    }
    this.type = type;
  }

  get name() {
    throw new Error('name is abstract method');
  }

  static Type = Object.freeze({
    ranking: 'ranking',
    collection: 'collection',
    new: 'new',
    limited: 'limited',
    quantity_limited: 'quantity_limited',
    period_limited: 'period_limited',
    sale: 'sale',
  });

  static isValidType(type) {
    return Object.values(ProductBadge.Type).includes(type);
  }
}

class LimitedProductCommonBadge extends ProductBadge {
  constructor() {
    super(ProductBadge.Type.limited);
  }

  get name() {
    return this.getNameByLang('en');
  }

  getNameByLang(lang) {
    return ET('ness::product_badge::special', { lng: lang });
  }
}

export class LimitedProductBadge extends ProductBadge {
  #current_badge;

  constructor({
    quantity,
    order_count,
    is_quantity_public,
    max_stock,
    sell_start_at,
    sell_end_at,
    is_no_spo_item_stock,
  }) {
    let quantity_badge;
    let period_badge;

    try {
      quantity_badge = new QuantityLimitedProductBadge({
        quantity,
        order_count,
        is_quantity_public,
        max_stock,
        is_no_spo_item_stock,
      });
    } catch (e) {
      if (e instanceof ProductBadgeValidationError) {
        quantity_badge = null;
      } else {
        throw e;
      }
    }

    try {
      period_badge = new PeriodLimitedProductBadge({
        sell_start_at,
        sell_end_at,
        is_no_spo_item_stock,
      });
    } catch (e) {
      if (e instanceof ProductBadgeValidationError) {
        period_badge = null;
      } else {
        throw e;
      }
    }

    // CASE0. 수량 한정, 기간 한정 모두 없음
    if (!quantity_badge && !period_badge) {
      throw new ProductBadgeValidationError('Invalid LimitedProductBadge');
    }

    // CASE1. 수량 한정만 있음
    if (quantity_badge && !period_badge) {
      super(ProductBadge.Type.quantity_limited);
      this.#current_badge = quantity_badge;
      return;
    }

    // CASE2. 기간 한정만 있음
    if (!quantity_badge && period_badge) {
      super(ProductBadge.Type.period_limited);
      this.#current_badge = period_badge;
      return;
    }

    // CASE3. 수량 & 기간 한정이 모두 있음
    // 3-1. 기간 마감일 경우 기간 한정 우선 (기간 마감될 경우 Sold Out)
    if (!period_badge.isActive) {
      super(ProductBadge.Type.period_limited);
      this.#current_badge = period_badge;
      return;
    }

    // 3-2. 수량 마감일 경우 수량 한정 우선 (수량 다 팔렸을 경우 Sold Out)
    if (quantity_badge.isSoldOut) {
      super(ProductBadge.Type.quantity_limited);
      this.#current_badge = quantity_badge;
      return;
    }

    // 3-3. 수량 비공개일 경우 기간 한정 우선
    if (!quantity_badge.isQuantityPublic) {
      super(ProductBadge.Type.period_limited);
      this.#current_badge = period_badge;
      return;
    }

    // 3-4. 우선순위 고려하여 뱃지 선택
    if (quantity_badge.priority >= period_badge.priority) {
      super(ProductBadge.Type.quantity_limited);
      this.#current_badge = quantity_badge;
    } else {
      // 기간 한정 우선
      super(ProductBadge.Type.period_limited);
      this.#current_badge = period_badge;
    }
  }

  get priority() {
    return this.#current_badge.priority;
  }

  get name() {
    return this.getNameByLang('en');
  }

  getNameByLang(lang) {
    return this.#current_badge.getNameByLang(lang);
  }

  get badges() {
    if (!this.isActive) return [new LimitedProductCommonBadge()];
    return [new LimitedProductCommonBadge(), this.#current_badge];
  }

  get isActive() {
    return this.isPeriodLimited || this.isQuantityLimited;
  }

  get isPeriodLimited() {
    return this.#current_badge instanceof PeriodLimitedProductBadge;
  }

  get isQuantityLimited() {
    return this.#current_badge instanceof QuantityLimitedProductBadge;
  }
}

export class QuantityLimitedProductBadge extends ProductBadge {
  #quantity;
  #order_count;
  #max_stock;
  #is_quantity_public; // 한정수량 공개여부
  #is_no_spo_item_stock; // 재고 없음

  constructor({ quantity, order_count, is_quantity_public, max_stock, is_no_spo_item_stock }) {
    super(ProductBadge.Type.quantity_limited);

    if (Number.isInteger(quantity) && quantity > 0) {
      this.#quantity = quantity;
    } else {
      throw new ProductBadgeValidationError(`Invalid quantity on QuantityLimitedProductBadge: ${quantity}`);
    }

    this.#quantity = quantity;
    this.#order_count = order_count;
    this.#max_stock = max_stock;
    this.#is_no_spo_item_stock = is_no_spo_item_stock;
    this.#is_quantity_public = is_quantity_public || false;
  }

  /**
   * @return {string | null}
   */
  get name() {
    return this.getNameByLang('en');
  }

  getNameByLang(lang) {
    // 재고가 없으면 뱃지를 가린다.
    if (this.stock <= 0 || !this.#is_quantity_public) return null;

    // 재고가 max_stock을 초과하면 뱃지를 가린다.
    if (this.stock > this.#max_stock) return null;

    return ET('ness::product_badge::quantity_limited', { count: this.stock, lng: lang });
  }

  get stock() {
    const stock = this.#quantity - this.#order_count;
    if (Number.isNaN(stock)) return null;
    if (stock < 0) return 0;
    return stock;
  }

  /**
   * @return {number}
   */
  get priority() {
    // 매진이면 맨 뒤로 감
    if (this.isSoldOut) return 0;

    const stock = this.#quantity - this.#order_count;
    if (stock < 10) return 5;
    if (stock < 30) return 4;
    if (stock < 50) return 3;
    if (stock < 100) return 2;
    return 1;
  }

  get isSoldOut() {
    if (this.#is_no_spo_item_stock) return true;
    if (this.stock <= 0) return true;
    return false;
  }

  // 매진인지
  get isActive() {
    if (!this.#is_quantity_public) return false;
    if (this.#is_no_spo_item_stock) return false;
    if (this.stock <= 0) return false;
    return true;
  }

  get isQuantityPublic() {
    return this.#is_quantity_public;
  }
}

export class PeriodLimitedProductBadge extends ProductBadge {
  #sell_start_at;
  #sell_end_at;
  #is_no_spo_item_stock;

  constructor({ sell_start_at, sell_end_at, is_no_spo_item_stock }) {
    if (!sell_start_at) {
      throw new ProductBadgeValidationError(
        `Invalid sell_start_at on PeriodLimitedProductBadge: ${sell_start_at}`,
      );
    }
    if (!sell_end_at) {
      throw new ProductBadgeValidationError(
        `Invalid sell_end_at on PeriodLimitedProductBadge: ${sell_end_at}`,
      );
    }

    super(ProductBadge.Type.period_limited);
    this.#sell_start_at = new Date(sell_start_at);
    this.#sell_end_at = new Date(sell_end_at);
    this.#is_no_spo_item_stock = is_no_spo_item_stock;
  }

  /**
   * @return {string | null}
   */
  get name() {
    return this.getNameByLang('en');
  }

  getNameByLang(lang) {
    if (!this.isActive) {
      return null;
    }

    const remaining_time_util_end = UtilS.differenceTimeInUnits(
      ['day', 'hour', 'minute'],
      this.#sell_end_at,
      new Date(),
      true,
    );

    if (remaining_time_util_end.day) {
      return ET('ness::product_badge::day', { count: remaining_time_util_end.day, lng: lang });
    }
    if (remaining_time_util_end.hour) {
      return ET('ness::product_badge::hour', { count: remaining_time_util_end.hour, lng: lang });
    }
    if (remaining_time_util_end.minute) {
      return ET('ness::product_badge::minute', { count: remaining_time_util_end.minute, lng: lang });
    }
  }

  get isSellStarted() {
    return isBefore(this.#sell_start_at, new Date());
  }

  get isSellEnded() {
    return isBefore(this.#sell_end_at, new Date());
  }

  get isActive() {
    if (this.#is_no_spo_item_stock) return false;
    if (!this.isSellStarted) return false;
    if (this.isSellEnded) return false;
    return true;
  }

  /**
   * @return {number}
   */
  get priority() {
    const now = new Date();

    if (this.#is_no_spo_item_stock) return 0;
    if (this.isSellEnded) return 0;
    if (!this.isSellStarted) return 1;

    const 판매종료_2시간_미만_남음 = isBefore(this.#sell_end_at, addHours(now, 2));
    if (판매종료_2시간_미만_남음) return 5;

    const 판매종료_24시간_미만_남음 = isBefore(this.#sell_end_at, addHours(now, 24));
    if (판매종료_24시간_미만_남음) return 4;

    const 판매종료_2일_미만_남음 = isBefore(this.#sell_end_at, addDays(now, 2));
    if (판매종료_2일_미만_남음) return 3;

    const 판매종료_4일_미만_남음 = isBefore(this.#sell_end_at, addDays(now, 4));
    if (판매종료_4일_미만_남음) return 2;

    return 1;
  }
}

export class RankingBadge extends ProductBadge {
  /** @type {number|null} */
  #rank;

  constructor(rank) {
    super(ProductBadge.Type.ranking);
    if (Number.isInteger(rank) && rank > 0) {
      this.#rank = rank;
    } else {
      this.#rank = null;
    }
  }

  get name() {
    return this.#rank;
  }

  get isActive() {
    return this.#rank > 0;
  }
}

/**
 * @deprecated
 * 지금은 spec off 추후 구현 이어서 진행
 */
export class CollectionBadge extends ProductBadge {
  constructor() {
    super(ProductBadge.Type.collection);
  }

  get name() {
    return ET('ness::product_badge::collection');
  }

  get isActive() {
    return false;
  }
}

export class SaleBadge extends ProductBadge {
  #discount_rate;
  #min_rate = 1;
  #max_rate = 100;

  constructor(discount_rate) {
    super(ProductBadge.Type.sale);
    if (!Number.isFinite(discount_rate)) {
      this.#discount_rate = this.#min_rate - 1;
    } else {
      this.#discount_rate = discount_rate;
    }
  }

  get name() {
    return this.getNameByLang('en');
  }

  getNameByLang(lang) {
    if (this.isActive) {
      return ET('ness::product_badge::sale', { lng: lang });
    }
    return null;
  }

  get isActive() {
    return this.#discount_rate >= this.#min_rate && this.#discount_rate <= this.#max_rate;
  }
}

export class NewBadge extends ProductBadge {
  #public_at;

  constructor(public_at) {
    super(ProductBadge.Type.new);
    this.#public_at = public_at ? new Date(public_at) : null;
  }

  get name() {
    return this.getNameByLang('en');
  }

  getNameByLang(lang) {
    if (this.isActive) {
      return ET('ness::product_badge::new', { lng: lang });
    }
    return null;
  }

  get isActive() {
    if (!this.#public_at) return false;
    const month_difference = differenceInMonths(new Date(), this.#public_at);
    if (month_difference >= 1) return false;
    return true;
  }
}
