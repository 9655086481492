import { each, extend, go } from 'fxjs/es';
import { NewMakerWeS } from '../../../We/S/Function/module/NewMakerWeS.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';

export const OUTSRC_MOUSE_PAD_BP_IDS = [4403, 4404, 4405];
export const AROSEGIDA_BP_IDS = [4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452];
export const GALAXY_TRANSPARENT_ZFLIP_BP_IDS = [5041, 5792, 5797, 6833];

export const GALAXY_ZFLIP_BP_IDS = [5039, 5040, 6835, 6834].concat(GALAXY_TRANSPARENT_ZFLIP_BP_IDS);
export const NEED_NOT_DESIGNED_BP_IDS = GALAXY_ZFLIP_BP_IDS.concat([
  4934, 5940, 5945, 6368, 6369, 6370, 6371,
]);

export const SHOW_NOT_POSSIBLE_EMBRO_MESSAGE_BP_IDS = [5845];

export const PREVIEW_PHONE_CASE_BP_NAMES = /젤리케이스|투명범퍼케이스|에코범퍼케이스|케이스\s*\(투명\)/;

export const isCarvedPhoneCase = (base_product) => {
  return (
    [4717, 4718, 4719, 4720, 4721, 4722, 4723, 4724, 4725, 4726, 4727, 4728, 4729].indexOf(base_product?.id) >
    -1
  );
};
// 3381, 4083, 4084, 4085, 4122, 4030, 4229, 4178, 3376, 4057, 4060, 4224, 4203, 3378, 3382, 4082, 3385,
//   3962, 3384, 4038, 4201, 4215, 4121, 3371, 3372, 4032, 4269, 4202, 4179, 4036, 4177, 4123, 4027, 4097,
//   4120, 4292, 4130

export const needThumbnailBp = (base_product) => {
  return [4779, 4780, 4781, 4782, 4386, 4741, 4742].includes(base_product?.id);
};

export const isArosegida = (base_product) => {
  return AROSEGIDA_BP_IDS.indexOf(base_product?.id) > -1;
};

export const isMousePad = (base_product) => {
  return OUTSRC_MOUSE_PAD_BP_IDS.indexOf(base_product?.id) > -1;
};

export const isSlipper = (base_product) => {
  return [4741, 4742, 4735].indexOf(base_product?.id) > -1;
};

export const isWatchBand = (base_product) => {
  return [4788, 4789].indexOf(base_product?.id) > -1;
};

export const isSmallBand = (base_product) => {
  return [4788].indexOf(base_product?.id) > -1;
};
export const isBigBand = (base_product) => {
  return [4789].indexOf(base_product?.id) > -1;
};

export const isMugCup = (base_product) => {
  return [4779, 4780, 4781, 4782].indexOf(base_product?.id) > -1;
};

export const isRemovableFabric = (base_product) => {
  return [4709, 4707, 4708, 4710].indexOf(base_product?.id) > -1;
};

export const isPDLandBp = (base_product) => {
  return [4398, 4399, 4400, 4401, 4402, 4498].indexOf(base_product?.id) > -1;
};

export const needEveryFaceDesign = (base_product) => {
  return [4880].indexOf(base_product?.id) > -1;
};

export const atLeastOneFaceDesign = (base_product) => {
  return [6038, 6039, 6040, 6041, 5208, 5200, 5203, 5206, 5207, 5205].indexOf(base_product?.id) > -1;
};

export const possibleAutoPrint = (bp) => bp?.has_template;

export const isAiNotSupportedBp = (base_product) => {
  return (
    NewMakerWeS.isWeBaseProduct(base_product?.id) ||
    isSlipper(base_product) ||
    isPDLandBp(base_product) ||
    isArosegida(base_product) ||
    isMousePad(base_product) ||
    needEveryFaceDesign(base_product) ||
    base_product?.is_not_image_task
  );
};

export const makeRatioPrintArea = (print_area, default_width_cm, target_width_cm) => {
  const { top, left, width, height } = print_area;
  const ratio = default_width_cm / target_width_cm;
  return {
    top,
    // top: top + (height - height * ratio) / 2,
    left: left + (width - width * ratio) / 2,
    width: width * ratio,
    height: height * ratio,
  };
};

export const updateBpfSizeFace = async ({
  default_base_product_id,
  base_product_id,
  default_width_cm,
  target_width_cm,
}) => {
  const default_bpf = await $.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
    base_product_id: default_base_product_id,
  });
  const bpf = await $.get('/@api/prerequisite_maker/base_product_faces/size_faces', { base_product_id });
  const print_area = makeRatioPrintArea(
    default_bpf.size_faces[0].print.px,
    default_width_cm,
    target_width_cm,
  );
  extend(bpf.size_faces[0].print.px, print_area);
  await $.post('/@api/base_product_faces/size_faces_update', { id: bpf.id, size_faces: bpf.size_faces });
};
/*
 * [
 * {base_product_id, target_width_cm}
 * ]
 * */
export const updateAAAA = (arr) => {
  go(
    arr,
    each(async ({ base_product_id, target_width_cm }) => {
      const default_base_product_id = undefined;
      const default_width_cm = undefined;
      await updateBpfSizeFace({
        default_base_product_id,
        base_product_id,
        default_width_cm,
        target_width_cm,
      });
    }),
  );
};

export const isNoRequestBgRemoval = (base_product) => {
  return (
    NewMakerWeS.isWeBaseProduct(base_product?.id) ||
    base_product.is_not_image_task ||
    [6829, 6830, 6831, 6832].includes(base_product?.id) ||
    base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.NO_REQUEST_BG_REMOVAL]
  );
};
