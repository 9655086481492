import { MarketplaceS } from '../../../../Marketplace/S/Function/module/MarketplaceS.js';
import { NessInboundTmplS } from '../../../Inbound/S/Tmpl/module/NessInboundTmplS.js';

export const dropdown = ({
  selected_option,
  options,
  name,
  klass,
  direction = 'right',
  title,
  need_highlight,
}) => {
  return NessInboundTmplS.dropdown({
    selected_option,
    options,
    name,
    klass,
    direction,
    title,
    need_highlight,
    variant: MarketplaceS.getMarketPlaceName(),
  });
};
