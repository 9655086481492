interface DocumentEventDetailMap {
  header_pinned: undefined;
  header_unpinned: undefined;
  header_top: undefined;
  header_nottop: undefined;
  header_bottom: undefined;
  header_notbottom: undefined;
  sub_header_menu_change: {
    cate_list_id: string | undefined;
    cate_item_id: string | undefined;
    menu: any;
  };
  floating_box_button_click: { name: string };
  floating_box_button_toggle: { show: boolean };
  floating_bot_button_filter_active: { active: boolean };
}

export const DOCUMENT_EVENT = {
  header_pinned: 'header_pinned',
  header_unpinned: 'header_unpinned',
  header_top: 'header_top',
  header_nottop: 'header_nottop',
  header_bottom: 'header_bottom',
  header_notbottom: 'header_notbottom',
  sub_header_menu_change: 'sub_header_menu_change',
  floating_box_button_click: 'floating_box_button_click',
  floating_box_button_toggle: 'floating_box_button_toggle',
  floating_bot_button_filter_active: 'floating_bot_button_filter_active',
} as const;

export const sendDocumentEvent = <K extends keyof DocumentEventDetailMap>(
  event_name: K,
  ...detail: DocumentEventDetailMap[K] extends undefined ? [] : [DocumentEventDetailMap[K]]
) => {
  document.body.dispatchEvent(new CustomEvent(event_name, { detail: detail[0] }));
};

export const listenDocumentEvent = <K extends keyof DocumentEventDetailMap>(
  event_name: K,
  listener: (e: CustomEvent<DocumentEventDetailMap[K]>) => void,
) => {
  document.body.addEventListener(event_name, listener as EventListener);
};
