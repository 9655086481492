import { CustomEventWithoutDetail, html, View } from 'rune-ts';
import klass from './ProductBadge.module.scss';
import { staticTypoPcMo } from '../../../../shared/typography/typo';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import { ClockFillIcon, LaunchIcon, LiveIcon } from '../../atoms/Icon';
import { htmlIf } from '../../../../shared/util';
import { ProductBadgeData, ProductCardData } from '../../../../features/ProductList/type';
import { product_badge } from '../../../../features/ProductList/constant';
import { ProductTag, ProductTagExpiredEvent } from '../ProductTag/ProductTag';
import { ProductCardHelper, SoldOutData } from '../ProductCard/ProductCardHelper';

type QuantityLimitedData =
  | Pick<ProductCardData, 'quantity' | 'order_count' | 'is_quantity_public'> & SoldOutData;
type PeriodLimitedData = Pick<ProductCardData, 'sell_start_at' | 'sell_end_at'> & SoldOutData;

export class ProductBadgeExpiredEvent extends CustomEventWithoutDetail {}

export class ProductBadge extends View<ProductBadgeData> {
  label?: string;

  interval_timer_id: ReturnType<typeof setInterval> | null = null;

  constructor(data: ProductBadgeData, private option: { is_mobile: boolean; is_lazy_timer?: boolean }) {
    super(data, option);
    this.setLabel();
  }

  override template() {
    const { is_mobile } = this.option;

    const badge_type_klass_map: Readonly<Record<keyof typeof product_badge, string>> = {
      sold_out: klass.sold_out,
      limited: klass.limited,
      closed: klass.closed,
      quantity_limited: klass.quantity_limited,
      period_limited: klass.period_limited,
      upcoming: klass.upcoming,
      ranking: klass.ranking,
      nft: klass.nft,
      realtime: klass.realtime,
    };

    return html`<span
      class="${klass.product_badge} ${staticTypoPcMo({
      is_mobile,
      pc: 'unica_14_regular',
      mo: 'unica_12_regular',
    })} ${badge_type_klass_map[this.data.type]}" 
      ${this.data.type === ProductBadge.Type.ranking ? html`data-value="${this.data.value}"` : ''}"
    >
      ${htmlIf(html`<span class="${klass.icon}">${this.icon}</span>`, !!this.icon)}
      <span>${this.label}</span>
    </span>`;
  }

  protected override onRender() {
    if (!this.isTimerNeeded()) {
      return;
    }

    // init timer if not lazy
    if (!this.option.is_lazy_timer) {
      this.interval_timer_id = this.startIntervalTimer();
    }
  }

  private startIntervalTimer(externalCallback?: () => void): ReturnType<typeof setInterval> | null {
    if (!this.isTimerNeeded()) {
      this.clearIntervalTimer();
      return null;
    }

    return setInterval(() => {
      if (externalCallback) {
        externalCallback();
      }
      this.refreshBadge();
    }, 1000);
  }

  private refreshBadge() {
    this.setLabel();
    this.redraw();
    if (!this.label) {
      this.dispatchEvent(ProductTagExpiredEvent, {
        bubbles: true,
      });
      this.clearIntervalTimer();
    }
  }

  public startLazyIntervalTimer(externalCallback?: () => void): ReturnType<typeof setInterval> | null {
    if (!this.option.is_lazy_timer) {
      throw new Error('ProductBadge에 is_lazy_timer옵션이 적용되어 있지 않습니다.');
    }

    if (this.isTimerNeeded()) {
      return this.startIntervalTimer(externalCallback);
    }

    return null;
  }

  protected override onUnmount() {
    this.clearIntervalTimer();
  }

  public clearIntervalTimer(): void {
    if (this.interval_timer_id) {
      clearInterval(this.interval_timer_id);
      this.interval_timer_id = null;
    }
  }

  private isTimerNeeded(): boolean {
    return (
      this.data.type === ProductBadge.Type.period_limited ||
      this.data.type === ProductBadge.Type.upcoming ||
      this.data.type === ProductBadge.Type.realtime
    );
  }

  static Type = product_badge;

  private setLabel() {
    const type = ProductBadge.Type;
    if (this.data.type === type.closed) {
      this.label = ET('mps2::product::badge::closed');
    }
    if (this.data.type === type.limited) {
      this.label = ET('mps2::product::badge::limited');
    }
    if (this.data.type === type.sold_out) {
      this.label = ET('mps2::product::badge::sold_out');
    }
    if (this.data.type === type.nft) {
      this.label = ET('mps2::product::badge::nft');
    }
    if (this.data.type === type.upcoming) {
      this.label = this.getPeriodLimitedLabel(this.data.value) ?? ET('mps2::product::badge::upcoming');
    }
    if (this.data.type === type.ranking) {
      this.label = this.data.value.toString();
    }
    if (this.data.type === type.quantity_limited) {
      this.label = ET('mps2::product::badge::quantity_limited', { count: this.data.value });
    }
    if (this.data.type === type.period_limited) {
      this.label = this.getPeriodLimitedLabel(this.data.value);
    }
    if (this.data.type === type.realtime) {
      this.label = this.getRealTimeLabel(this.data.value);
    }
  }

  private getPeriodLimitedLabel(date: Date): string | undefined {
    const diff = this.getDiffTime(date);
    // 4일 이상이면 숨겨진다.
    if (diff.day >= 4 || diff.second < 0) {
      return undefined;
    }
    if (diff.day > 0) {
      return ET('mps2::product::badge::period_limited_day', diff);
    }
    if (diff.hour > 0) {
      return ET('mps2::product::badge::period_limited_hour', diff);
    }
    if (diff.minute > 0) {
      return ET('mps2::product::badge::period_limited_minute', diff);
    }
    return ET('mps2::product::badge::period_limited_second', diff);
  }

  private getRealTimeLabel(date: Date): string | undefined {
    const diff = this.getDiffTime2(date);
    if (diff.day > 0) {
      return ET('mps2::product::badge::period_limited_day', diff);
    }
    if (diff.hour > 0) {
      return ET('mps2::product::badge::period_limited_hour', diff);
    }
    if (diff.minute > 0) {
      return ET('mps2::product::badge::period_limited_minute', diff);
    }
    return ET('mps2::product::badge::period_limited_second', diff);
  }

  private getDiffTime(date: Date): { day: number; hour: number; minute: number; second: number } {
    const { day, hour, minute, second } = UtilS.differenceTimeInUnits(
      ['day', 'hour', 'minute', 'second'],
      new Date(date),
      new Date(),
      true,
    );
    return { day, hour, minute, second };
  }

  private getDiffTime2(date: Date): { day: number; hour: number; minute: number; second: number } {
    const { day, hour, minute, second } = UtilS.differenceTimeInUnits(
      ['day', 'hour', 'minute', 'second'],
      new Date(),
      new Date(date),
      true,
    );
    return { day, hour, minute, second };
  }

  private get icon() {
    if (this.data.type === product_badge.upcoming) {
      return LaunchIcon;
    }
    if (this.data.type === product_badge.period_limited) {
      return ClockFillIcon;
    }
    if (this.data.type === product_badge.realtime) {
      return LiveIcon;
    }
    return null;
  }

  static isBothQuantityAndPeriodLimitedBadgeVisible(data: QuantityLimitedData & PeriodLimitedData): boolean {
    return ProductBadge.isQuantityLimitedBadgeVisible(data) && ProductBadge.isPeriodLimitedBadgeVisible(data);
  }

  static isQuantityLimitedBadgeVisible(data: QuantityLimitedData): boolean {
    const VISIBLE_RANGE = {
      MIN: 1,
      MAX: 499,
    } as const;
    const { is_quantity_public, quantity, order_count } = data;
    const stock = quantity - order_count;
    return (
      !ProductCardHelper.isSoldOut(data) &&
      stock >= VISIBLE_RANGE.MIN &&
      stock <= VISIBLE_RANGE.MAX &&
      !!is_quantity_public
    );
  }

  static isPeriodLimitedBadgeVisible(data: PeriodLimitedData) {
    const now = new Date();

    // 기간 한정 아님
    if (!ProductCardHelper.isPeriodLimited(data)) {
      return false;
    }

    const diff = ProductTag.getDiffTime(data.sell_end_at);

    // 기간한정 상품 종료까지 96시간 이상 남아있으면 숨겨진다.
    if (diff.day >= 4) {
      return false;
    }

    const is_upcoming = new Date(data.sell_start_at) > now; // 출시 예정인지
    const is_sale_closed = new Date(data.sell_end_at) < now; // 판매 종료인지

    return !ProductCardHelper.isSoldOut(data) && !is_upcoming && !is_sale_closed;
  }

  /**
   * - 기간한정 뱃지의 우선순위를 구하는 함수
   * - **낮은 숫자**일수록 **우선** 노출된다.
   */
  static getPeriodLimitedBadgePriority(date: Date): number {
    const diff = ProductTag.getDiffTime(date);
    if (diff.day >= 4) return 8; // 96시간 이상
    if (diff.day >= 2) return 7; // 96시간 미만 ~ 48시간 이상
    if (diff.day >= 1) return 6; // 48시간 미만 ~ 24시간 이상
    if (diff.hour >= 12) return 5; // 24시간 미만 ~ 12시간 이상
    if (diff.hour >= 1) return 4; // 12시간 미만 ~ 1시간 이상
    if (diff.minute >= 10) return 3; // 1시간 미만 ~ 10분 이상
    if (diff.minute >= 1) return 2; // 10분 미만 ~ 1분 이상
    return 1; // 1분 미만
  }

  /**
   * - 수량한정 뱃지의 우선순위를 구하는 함수
   * - **낮은 숫자**일수록 **우선** 노출된다.
   */
  static getQuantityLimitedBadgePriority(quantity: number): number {
    if (quantity < 10) return 3;
    if (quantity < 50) return 4;
    if (quantity < 100) return 5;
    if (quantity < 300) return 6;
    if (quantity < 500) return 7;
    return 8;
  }
}
