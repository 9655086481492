import { type Html, html, View } from 'rune-ts';
import classes from './Icons.module.scss';
import * as icons from './index';
import { staticTypo } from '../../../../shared/typography/typo';

export interface IconsProps {
  size: number;
}

export class Icons extends View<IconsProps> {
  override template() {
    const keys = Object.keys(icons);
    const { size } = this.data;
    return html`
      <div class="${classes.icons}">
        ${keys.map((key) => {
          const icon = icons[key];
          return html`
            <div class="${classes.icon_wrapper}" data-key="${key}">
              <div class="${classes.icon}" style="width: ${size}px; height: ${size}px;">
                ${typeof icon === 'function' ? icon() : icon}
              </div>
              <div class="${classes.name}">${key}</div>
            </div>
          `;
        })}
      </div>
    `;
  }

  override onMount() {
    this.delegate('click', classes.icon_wrapper, (e: any) => {
      const key = e.currentTarget.dataset.key;
      const tempElem = document.createElement('textarea');
      tempElem.value = key;
      document.body.appendChild(tempElem);

      tempElem.select();
      document.execCommand('copy');
      document.body.removeChild(tempElem);
    });
  }
}

export class CartCountView extends View<{ count: number }> {
  protected override template(): Html {
    return html`
      <span class="${classes.cart_count} ${staticTypo('unica_10_regular')}">
        <span class="${classes.number} number">${Math.min(99, this.data.count ?? 0)}</span>
      </span>
    `;
  }

  setCount(count: number): this {
    this.data.count = count;
    return this;
  }
}
