import { html } from 'fxjs/es';
import { createDefaultFooter } from './footer.js';
import { nessFullPageLoading } from './loading.js';
import { NessCoreTmplS } from './module/NessCoreTmplS.js';

/**
 *
 * @param {string} body
 * @param {boolean} is_mobile
 * @param {boolean} footer_hidden
 * @param {boolean} [full_width] 기본 padding 없앰
 * @param {boolean} [no_min_width] min_width 없앰
 * @param {boolean} [is_loading]
 * @param {boolean} [require_top_arrow_button]
 * @return {string}
 */
export const createNessLayout = (
  body,
  {
    is_mobile,
    footer_hidden = false,
    full_width,
    no_min_width,
    is_loading = false,
    require_top_arrow_button = false,
  },
) => {
  const component = `ness-layout-container`;

  return html`
    ${is_loading ? nessFullPageLoading() : ''}
    <div class="${component}" data-full_width="${full_width}" data-no_min_width="${no_min_width}">
      <div class="${component}__content">${body}</div>
      ${require_top_arrow_button ? makeTopArrowButtonTmpl() : ''}
      ${footer_hidden
        ? ''
        : html`<div class="${component}__footer">${createDefaultFooter({ is_mobile })}</div>`}
    </div>
  `;
};

const makeTopArrowButtonTmpl = () => {
  const component = 'ness-layout-top-arrow';
  return html`
    <div class="${component}">
      <div class="${component}__upper-trigger"></div>
      <div class="${component}__trigger"></div>
      <span class="${component}__btn">
        <span class="${component}__btn-icon">
          ${NessCoreTmplS.getNextIcon({ color: 'white', direction: 'top' })}
        </span>
      </span>
    </div>
  `;
};
