import { html, on, rune, View } from 'rune-ts';
import Headroom from 'headroom.js';
import klass from './Header.module.scss';
import { Header, HeaderData, HeaderOptions, HeaderThemeChangedEvent } from './Header';
import { SubHeaderMobile, SubHeaderMobileData, SubHeaderMobileOptions } from '../SubHeader/SubHeaderMobile';
import { SubHeaderPc } from '../SubHeader/SubHeaderPc';
import { htmlIf } from '../../../../shared/util';
import { HeaderLeft_MshopLogoWithMenu } from './HeaderLeft';

export type HeaderWrapperProps = {
  header: {
    data: HeaderData;
    options: HeaderOptions;
  };
  with_hamburger?: boolean;
  is_mobile: boolean;
  sub_header?: {
    data: SubHeaderMobileData;
    options: SubHeaderMobileOptions;
  };
};

export class HeaderWrapper extends View<HeaderWrapperProps> {
  headroom?: typeof Headroom;

  @on(HeaderThemeChangedEvent)
  handleHeaderThemeChanged(event: HeaderThemeChangedEvent) {
    const sub_header_pc_element = this.element().querySelector(`.${SubHeaderPc}`);
    if (!sub_header_pc_element) return;

    const sub_header_pc_view = rune.getView(sub_header_pc_element, SubHeaderPc);

    if (!sub_header_pc_view) return;

    sub_header_pc_view.state.transparent_background = event.detail.transparent;
    sub_header_pc_view.redrawBgColor(event.detail.instant);
  }

  override template() {
    const { header, sub_header, is_mobile, with_hamburger } = this.data;

    const mobile_data = {
      ...header.data,
      left: {
        ...(header.data.left || {}),
        ...(with_hamburger
          ? { whole: html`${new HeaderLeft_MshopLogoWithMenu({ transparent: false })}` }
          : {}),
      },
    };

    return html`<div class="${klass.header_wrapper} ${htmlIf(klass.is_fixed, !!header.options.is_fixed)}">
      ${new Header(
        {
          ...(is_mobile ? mobile_data : header.data),
          sub_header: !sub_header
            ? undefined
            : is_mobile
            ? new SubHeaderMobile(sub_header.data, sub_header.options)
            : new SubHeaderPc(sub_header.data, sub_header.options),
        },
        {
          ...header.options,
        },
      )}
    </div> `;
  }
}
